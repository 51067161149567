import * as S from "../styles/new_styles";
import noimg from "../assets/products/banner_noimg.png";
import GlobProdItem from "../components/products/GlobProdItem";
import ReviewBoard from "../components/products/ReviewBoard";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import close from "../assets/icons/close.png";
import { useMediaQuery } from "react-responsive";
import { Box, Typography } from "@mui/material";

const ProductsPage = () => {
  const isPc = useMediaQuery({ query: "(min-width: 768px)" });

  const [products, setProducts] = useState([]);
  const [bannerImg, setBannerImg] = useState();
  const [reviewData, setReviewData] = useState([]);
  const { cateid } = useParams();

  useEffect(() => {
    initdb();
  }, [cateid]);

  const initdb = async () => {
    setProducts(
      (
        await axios.post(
          process.env.REACT_APP_DB_HOST + "/api/product/thumbnail",
          { cateid: cateid }
        )
      ).data
    );
    setBannerImg(
      (
        await axios.get(process.env.REACT_APP_DB_HOST + "/api/banner", {
          params: {
            cate: "CATE",
            code: cateid,
          },
        })
      ).data
    );
    setReviewData(
      (
        await axios.post(process.env.REACT_APP_DB_HOST + "/api/review", {
          cate_sid: cateid,
        })
      ).data
    );
  };

  return (
    <>
      {isPc ? (
        <S.MainLayout>
          <S.MainSection>
            <S.ProductBannerBox
              img={bannerImg ? bannerImg[0].BANNER_IMAGE : noimg}
            ></S.ProductBannerBox>
          </S.MainSection>
          <S.MainSection bgc="#fff">
            <S.GlobProdWrapper>
              <S.GlobProdList>
                {products.length ? (
                  products.map((item, index) => <GlobProdItem item={item} />)
                ) : (
                  <S.NoProdBox>
                    <div style={{ textAlign: "center" }}>
                      <S.Glob_Icon icon={close} width="120px" height="120px" />
                    </div>
                    <h1>상품이 준비 중입니다.</h1>
                  </S.NoProdBox>
                )}
              </S.GlobProdList>
            </S.GlobProdWrapper>
          </S.MainSection>
          <S.MainSection bgc="#f9fafc">
            <S.ProductReviewWrapper>
              <h1>고객 리뷰</h1>
              <ReviewBoard reviewData={reviewData} />
            </S.ProductReviewWrapper>
          </S.MainSection>
        </S.MainLayout>
      ) : (
        <Box>
          <Box
            sx={{
              // backgroundImage: `url(${
              //   bannerImg ? bannerImg[0].BANNER_IMAGE : noimg
              // })`,

              backgroundImage: `url(/asserts/banner/mob_banner1.jpg)`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backfaceVisibility: "hidden",
              height: "200px",
              mb: "20px",
            }}
          />
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(155px, 1fr))",
              gap: "10px",
              ml: "10px",
              mr: "10px",
            }}
          >
            {products.length &&
              products.map((item, index) => (
                <GlobProdItem item={item} key={index} />
              ))}
          </Box>
          <Box mt={3} mb={3}>
            <Typography
              sx={{
                fontFamily: "Noto Sans KR",
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bold",
              }}
            >
              고객 리뷰
            </Typography>
            <ReviewBoard reviewData={reviewData} />
          </Box>
        </Box>
      )}
    </>
  );
};

export default ProductsPage;
