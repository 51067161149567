import {
  AlignHorizontalLeft,
  ArrowLeft,
  ArrowRight,
  ArrowUpward,
  ContentCopy,
  Delete,
  DoDisturb,
  Download,
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatBoldRounded,
  FormatItalic,
  FormatLineSpacing,
  FormatUnderlined,
  GroupAdd,
  GroupRemove,
  KeyboardArrowDown,
  KeyboardArrowUp,
  LineStyle,
  Lock,
  LockOpen,
  Opacity,
  Redo,
  RedoRounded,
  Undo,
  UndoRounded,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Icon,
  IconButton,
  MenuItem,
  Select,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";
import * as S from "../../styles/new_styles";
import { SketchPicker } from "react-color";

const New_EditorToolbar = ({ editorRef, selectedNode, setSelectedNode }) => {
  const [selectedType, setSelectedType] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedFontFamily, setSelectedFontFamily] = useState(null);
  const [selectedFontSize, setSelectedFontSize] = useState(null);
  const [selectedAlign, setSelectedAlign] = useState(null);
  const [LineSpacingBoxShow, setLineSpacingBoxShow] = useState(false);
  const [selectedLineHeight, setSelectedLineHeight] = useState(null);
  const [selectedLetterSpacing, setSelectedLetterSpacing] = useState(null);
  const [opacityBoxShow, setOpacityBoxShow] = useState(false);
  const [selectedOpacity, setSelectedOpacity] = useState(null);
  const [colorPickerShow, setColorPickerShow] = useState(false);
  const [lineStyleBoxShow, setLineStyleBoxShow] = useState(false);

  const lineSpacingBoxAnimation = useSpring({
    opacity: LineSpacingBoxShow ? 1 : 0,
    transform: LineSpacingBoxShow ? "scale(1)" : "scale(0)",
    display: LineSpacingBoxShow ? "block" : "none",
    config: { duration: 150 },
  });
  const opacityBoxAnimation = useSpring({
    opacity: opacityBoxShow ? 1 : 0,
    transform: opacityBoxShow ? "scale(1)" : "scale(0)",
    display: opacityBoxShow ? "block" : "none",
    config: { duration: 150 },
  });

  const colorPickerAnimation = useSpring({
    opacity: colorPickerShow ? 1 : 0,
    transform: colorPickerShow ? "scale(1)" : "scale(0)",
    display: colorPickerShow ? "block" : "none",
    config: { duration: 150 },
  });

  const lineStyleBoxAnimation = useSpring({
    opacity: lineStyleBoxShow ? 1 : 0,
    transform: lineStyleBoxShow ? "scale(1)" : "scale(0)",
    display: lineStyleBoxShow ? "block" : "none",
    config: { duration: 150 },
  });

  useEffect(() => {
    // 팝업 닫기
    setLineSpacingBoxShow(false);
    setOpacityBoxShow(false);
    setColorPickerShow(false);
    if (selectedNode && selectedNode !== "그룹") {
      const nodeType = selectedNode.className;
      console.log("툴바의 selectedNode 타입:", nodeType);
      console.log("툴바의 selectedNode : ", selectedNode);
      setSelectedType(nodeType);
      if (nodeType === "Text") {
        console.log("텍스트 선택됨");
        setSelectedColor(selectedNode.getFill());
        setSelectedFontFamily(selectedNode.getFontFamily());
        setSelectedFontSize(selectedNode.getFontSize());
        setSelectedLineHeight(selectedNode.getLineHeight());
        setSelectedLetterSpacing(selectedNode.getLetterSpacing());
        setSelectedAlign(selectedNode.getAlign());
        setSelectedOpacity(selectedNode.getOpacity());
      } else if (["Circle", "Rect", "Star"].includes(nodeType)) {
        setSelectedColor(selectedNode.getFill());
        setSelectedOpacity(selectedNode.getOpacity());
      } else {
        setSelectedType(null);
      }
    } else {
      setSelectedType(null);
    }
  }, [selectedNode]);
  return (
    <ToolbarWrap>
      <ToolbarInner>
        <ToolbarArrowBox>
          <IconButton>
            <UndoRounded />
          </IconButton>
          <IconButton>
            <RedoRounded />
          </IconButton>
        </ToolbarArrowBox>
        <ToolbarDivider />
        <ToolbarStatusBox>
          {selectedType === "Text" ? (
            <>
              <>
                <IconButton
                  onClick={() => {
                    setColorPickerShow(!colorPickerShow);
                  }}
                >
                  <ColorBox color={selectedColor} />
                </IconButton>
                <ColorPickerBox style={colorPickerAnimation}>
                  <SketchPicker
                    color={selectedColor}
                    onChange={(color) => {
                      setSelectedColor(color.hex);
                      selectedNode.setFill(color.hex);
                    }}
                  />
                </ColorPickerBox>
              </>
              <IconButton>
                <Select
                  size="small"
                  sx={{ width: "150px", textAlign: "left" }}
                  value={selectedFontFamily}
                  onChange={(e) => {
                    setSelectedFontFamily(e.target.value);
                    selectedNode.setFontFamily(e.target.value);
                  }}
                >
                  <MenuItem sx={{ fontFamily: "Arial" }} value={"Arial"}>
                    Arial
                  </MenuItem>
                  <MenuItem
                    sx={{ fontFamily: "Noto Sans KR" }}
                    value={"Noto Sans KR"}
                  >
                    Noto Sans KR
                  </MenuItem>
                  <MenuItem sx={{ fontFamily: "궁서체" }} value={"궁서체"}>
                    궁서체
                  </MenuItem>
                  <MenuItem sx={{ fontFamily: "굴림체" }} value={"굴림체"}>
                    굴림체
                  </MenuItem>
                </Select>
              </IconButton>
              <TextField
                size="small"
                sx={{ width: "60px", textAlign: "left" }}
                value={selectedFontSize}
                onChange={(e) => {
                  setSelectedFontSize(e.target.value);
                  selectedNode.setFontSize(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "ArrowUp") {
                    setSelectedFontSize(parseInt(selectedFontSize) + 1);
                    selectedNode.setFontSize(parseInt(selectedFontSize) + 1);
                    return;
                  } else if (e.key === "ArrowDown") {
                    setSelectedFontSize(parseInt(selectedFontSize) - 1);
                    selectedNode.setFontSize(parseInt(selectedFontSize) - 1);
                    return;
                  }
                }}
              />
              <FontSizeUpDownBox>
                <button
                  onClick={() => {
                    setSelectedFontSize(parseInt(selectedFontSize) + 1);
                    selectedNode.setFontSize(parseInt(selectedFontSize) + 1);
                  }}
                >
                  <KeyboardArrowUp />
                </button>
                <button
                  onClick={() => {
                    setSelectedFontSize(parseInt(selectedFontSize) - 1);
                    selectedNode.setFontSize(parseInt(selectedFontSize) - 1);
                  }}
                >
                  <KeyboardArrowDown />
                </button>
              </FontSizeUpDownBox>
              <IconButton
                onClick={() => {
                  switch (selectedAlign) {
                    case "left":
                      setSelectedAlign("center");
                      selectedNode.setAlign("center");
                      break;
                    case "center":
                      setSelectedAlign("right");
                      selectedNode.setAlign("right");
                      break;
                    case "right":
                      setSelectedAlign("left");
                      selectedNode.setAlign("left");
                      break;
                  }
                }}
              >
                {selectedAlign === "center" ? <FormatAlignCenter /> : null}
                {selectedAlign === "left" ? <FormatAlignLeft /> : null}
                {selectedAlign === "right" ? <FormatAlignRight /> : null}
              </IconButton>
              <IconButton
                onClick={() => {
                  const style = selectedNode.getFontStyle();
                  if (style.includes("bold")) {
                    selectedNode.setFontStyle(style.replace("bold", ""));
                  } else {
                    selectedNode.setFontStyle(style + " bold");
                  }
                }}
              >
                <FormatBold />
              </IconButton>
              <IconButton
                onClick={() => {
                  const style = selectedNode.getFontStyle();
                  if (style.includes("italic")) {
                    selectedNode.setFontStyle(style.replace("italic", ""));
                  } else {
                    selectedNode.setFontStyle(style + " italic");
                  }
                }}
              >
                <FormatItalic />
              </IconButton>
              <IconButton
                onClick={() => {
                  const decoration = selectedNode.getTextDecoration();
                  if (decoration.includes("underline")) {
                    selectedNode.setTextDecoration(
                      decoration.replace("underline", "")
                    );
                  } else {
                    selectedNode.setTextDecoration(decoration + " underline");
                  }
                }}
              >
                <FormatUnderlined />
              </IconButton>
              <>
                <IconButton
                  onClick={() => {
                    setLineSpacingBoxShow(!LineSpacingBoxShow);
                  }}
                >
                  <FormatLineSpacing />
                </IconButton>
                <LineSpacingBox style={lineSpacingBoxAnimation}>
                  <div>
                    <div>
                      <p>Line Height</p>
                      <div>
                        <TextField
                          size="small"
                          sx={{ width: "75px" }}
                          value={selectedLineHeight}
                          type="number"
                          onChange={(e) => {
                            setSelectedLineHeight(parseInt(e.target.value));
                            selectedNode.setLineHeight(
                              parseInt(e.target.value)
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <p>Letter spacing</p>
                      <div>
                        <TextField
                          size="small"
                          sx={{ width: "75px" }}
                          value={selectedLetterSpacing}
                          type="number"
                          onChange={(e) => {
                            setSelectedLetterSpacing(parseInt(e.target.value));
                            selectedNode.setLetterSpacing(
                              parseInt(e.target.value)
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </LineSpacingBox>
              </>
            </>
          ) : ["Circle", "Rect", "Star"].includes(selectedType) ? (
            <>
              <>
                <IconButton
                  onClick={() => {
                    setLineStyleBoxShow(false);
                    setColorPickerShow(!colorPickerShow);
                  }}
                >
                  <ColorBox color={selectedColor} />
                </IconButton>
                <ColorPickerBox style={colorPickerAnimation}>
                  <SketchPicker
                    color={selectedColor}
                    onChange={(color) => {
                      console.log("color : ", color);
                      console.log("color.rgb : ", color.rgb);
                      console.log("color.hex : ", color.hex);
                      setSelectedColor(
                        `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
                      );
                      selectedNode.setFill(
                        `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
                      );
                    }}
                  />
                </ColorPickerBox>
              </>
            </>
          ) : (
            <></>
          )}
        </ToolbarStatusBox>
        <ToolbarUtillBox>
          <>
            <IconButton
              onClick={() => {
                setOpacityBoxShow(!opacityBoxShow);
              }}
            >
              <Opacity />
            </IconButton>
            <OpacityBox style={opacityBoxAnimation}>
              <div>
                <p>투명도</p>
                <div>
                  <Slider
                    sx={{
                      width: "150px",
                      margin: "0 auto",
                    }}
                    value={1 - selectedOpacity}
                    min={0}
                    max={1}
                    step={0.01}
                    onChange={(e) => {
                      setSelectedOpacity(1 - e.target.value);
                      selectedNode.setOpacity(1 - e.target.value);
                    }}
                  />
                </div>
              </div>
            </OpacityBox>
          </>
          <IconButton
            onClick={() => {
              editorRef.current.functions.addClone();
            }}
          >
            <ContentCopy />
          </IconButton>
          <IconButton
            onClick={() => {
              console.log(editorRef.current);
              editorRef.current.functions.groupObjects();
            }}
          >
            <GroupAdd />
          </IconButton>
          <IconButton
            onClick={() => {
              console.log(editorRef.current);
              editorRef.current.functions.unGroupObjects();
            }}
          >
            <GroupRemove />
          </IconButton>
          <IconButton
            onClick={() => {
              editorRef.current.functions.deleteObject();
            }}
          >
            <Delete />
          </IconButton>
          <ToolbarDivider />
          <IconButton>
            <Download
              sx={{
                marginRight: "5px",
              }}
            />
            <Typography>Download</Typography>
          </IconButton>
        </ToolbarUtillBox>
      </ToolbarInner>
    </ToolbarWrap>
  );
};

export default New_EditorToolbar;

const ToolbarDivider = styled.div`
  float: left;
  width: 1px;
  margin: 0 5px;
  height: 100%;
  background-color: rgba(17, 20, 24, 0.1);
`;

const ToolbarWrap = styled.div`
  white-space: nowrap;
  background-color: #ffffff;
  box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.1);
  height: 50px;
  padding: 0 15px;
  position: relative;
  width: 100%;
  z-index: 10;

  & p {
    font-size: 14px;
    font-weight: 500;
    font-family: "Noto Sans KR", sans-serif;
  }

  & svg {
    &:hover {
      color: #333333;
    }
  }
`;

const ToolbarInner = styled.div`
  width: 100%;
  height: 100%;
`;

const ToolbarArrowBox = styled.div`
  padding-right: 10px;
  float: left;
  display: flex;
  align-items: center;
  height: 50px;

  & button {
    width: 30px;
    height: 30px;
  }
`;

const ToolbarStatusBox = styled.div`
  float: left;
  display: flex;
  align-items: center;
  height: 50px;
`;

const ToolbarUtillBox = styled.div`
  float: right;
  display: flex;
  align-items: center;
  height: 50px;
  margin-right: 30px;
`;

const ColorBox = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${(props) => props.color};
`;

const FontSizeUpDownBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30px;

  & button {
    width: 20px;
    height: 20px;
    & svg {
      color: #777;
      &:hover {
        color: #333;
      }
    }
  }
`;

const LineSpacingBox = styled(animated.div)`
  position: relative;
  & > div {
    position: absolute;
    top: 30px;
    left: -120px;
    width: 200px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.1);
    flex-direction: column;
    & > div {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > p {
        padding-left: 1em;
      }
      & > div {
        padding-right: 0.5em;
      }
    }
  }
`;
const OpacityBox = styled(animated.div)`
  position: relative;
  & > div {
    position: absolute;
    top: 30px;
    left: -120px;
    width: 200px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.1);
    flex-direction: column;
    & > div {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > p {
        padding-left: 1em;
      }
      & > div {
        padding-right: 0.5em;
      }
    }
  }
`;

const ColorPickerBox = styled(animated.div)`
  position: relative;
  & > div {
    position: absolute;
    top: 30px;
    left: -120px;
    width: 200px;
    height: 300px;
    background-color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.1);
  }
`;

const LineStyleBox = styled(animated.div)`
  position: relative;
  & > div {
    position: absolute;
    top: 30px;
    left: -120px;
    width: 200px;
    height: 300px;
    background-color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.1);
  }
`;
