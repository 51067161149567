import React, { useEffect, useState } from "react";
import * as S from "../../styles/new_styles";
import { useSpring, animated } from "react-spring";
import axios from "axios";
import { useQuery } from "react-query";
import Card from "@mui/material/Card";
import { CardActionArea, CardContent } from "@mui/material";
import { formatDate } from "../../hooks/Utill";
import { useMediaQuery } from "react-responsive";

const PopDeliveryRecentForm = ({ openPopup, closePopup, popupData }) => {
  const [delis, setDelis] = useState([]);

  const { data } = useQuery("userinfo", { enabled: false });
  const USER_ID = data?.USER_ID;

  const fadeInAnimation = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    config: { tension: 200, friction: 20 },
  });

  useEffect(() => {
    initdb();
  }, []);

  const initdb = async () => {
    setDelis(
      (
        await axios.get(
          process.env.REACT_APP_DB_HOST + "/api/order/recentDelis",
          {
            params: {
              userid: USER_ID,
            },
          }
        )
      ).data.reverse()
    );
  };

  const isPc = useMediaQuery({ query: "(min-width:768px)" });

  return (
    <>
      <S.Pop_overlay>
        <animated.div style={fadeInAnimation}>
          <S.Pop_Container widthValue={isPc ? 440 : 360} heightValue="380">
            <S.Pop_Close_btn onClick={closePopup}>
              <S.Pop_Close_span>×</S.Pop_Close_span>
            </S.Pop_Close_btn>
            <S.Pop_form>
              <S.Pop_Title>최근 배송지</S.Pop_Title>
              <div
                style={{
                  paddingRight: "1em",
                  overflowY: "scroll",
                  height: "460px",
                }}
              >
                {delis?.map((el, index) => (
                  <Card variant="outlined" style={{ marginBottom: "0.5em" }}>
                    <CardActionArea
                      onClick={() => {
                        popupData.handleSetDeli(
                          el.DELI_REC,
                          el.DELI_TEL0,
                          el.DELI_POSTCODE,
                          el.DELI_ADDRESS,
                          el.DELI_ADD_ADDRESS
                        );
                      }}
                    >
                      <CardContent>
                        <S.PopDeliTable>
                          <tr>
                            <th>주소</th>
                            <td>
                              {el.DELI_ADDRESS} ({el.DELI_POSTCODE}){" "}
                              {el.DELI_ADD_ADDRESS}
                            </td>
                          </tr>
                          <tr>
                            <th>받는분</th>
                            <td>{el.DELI_REC}</td>
                          </tr>
                          <tr>
                            <th>연락처</th>
                            <td>{el.DELI_TEL0}</td>
                          </tr>
                        </S.PopDeliTable>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                ))}
              </div>
              <S.Pop_Info_Wrap>
                <S.Pop_Info_Title>INFO</S.Pop_Info_Title>
                <S.Pop_Info_Desc>
                  회원님의 배송지를 선택해주세요.
                </S.Pop_Info_Desc>
                <S.Pop_Info_Desc>
                  최근 주문한 배송지 목록입니다.
                </S.Pop_Info_Desc>
              </S.Pop_Info_Wrap>
            </S.Pop_form>
          </S.Pop_Container>
        </animated.div>
      </S.Pop_overlay>
    </>
  );
};

export default PopDeliveryRecentForm;
