import axios from "axios";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export function SuccessPage({}) {
  const [searchParams] = useSearchParams();
  const reqKey = searchParams.get("reqKey");
  const paymentKey = searchParams.get("paymentKey");
  const orderId = searchParams.get("orderId");
  const amount = searchParams.get("amount");

  async function confirmPayment() {
    // TODO: API를 호출해서 서버에게 paymentKey, orderId, amount를 넘겨주세요.
    // 서버에선 해당 데이터를 가지고 승인 API를 호출하면 결제가 완료됩니다.
    // https://docs.tosspayments.com/reference#%EA%B2%B0%EC%A0%9C-%EC%8A%B9%EC%9D%B8
    const response = await fetch("/api/tosspay/confirm", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        paymentKey,
        orderId,
        amount,
      }),
    });

    console.log(response);

    if (response.ok) {
      response.json().then(async (response_data) => {
        const req_data = await axios.get(
          process.env.REACT_APP_DB_HOST + "/api/order_req",
          {
            params: {
              reqKey: reqKey,
            },
          }
        );

        if (req_data.status === 200) {
          const res_insert = await axios.put(
            process.env.REACT_APP_DB_HOST + "/api/order",
            {
              userId: req_data.data.USER_ID,
              userTel: req_data.data.REC_TEL,
              userEmail: "",
              item_sids: req_data.data.ITEM_SIDS,
              orderAmount: req_data.data.ORDER_AMOUNT,
              orderReceiver: req_data.data.ORDER_REC,
              orderTel: req_data.data.REC_TEL,
              orderPostcode: req_data.data.ORDER_POSTCODE,
              orderAddress: req_data.data.ORDER_ADDRESS,
              orderAddAddress: req_data.data.ORDER_ADD_ADDRESS,
              orderReq: req_data.data.ORDER_REQ,
              radioValue: req_data.data.ORDER_PAYMENT_TYPE,
              order_core_prod: req_data.data.ORDER_CORE_PROD,
              order_core_option: req_data.data.ORDER_CORE_OPTION,
              orderNm: req_data.data.ORDER_NM,
              orderStatus: 2,
              ORDER_CORE_PROD_SID: req_data.data.ORDER_CORE_PROD_SID,
              ORDER_CORE_PROD_CATECODE: req_data.data.ORDER_CORE_PROD_CATECODE,
              CASH_RECEIPT_TYPE: req_data.data.CASH_RECEIPT_TYPE,
              CASH_RECEIPT_NO: req_data.data.CASH_RECEIPT_NO,
              CASH_DEPOSITOR_NAME: req_data.data.CASH_DEPOSITOR_NAME,
              USE_POINT: req_data.data.ORDER_USE_POINT,
              pgOrderId: response_data.orderId,
              pgPaymentKey: response_data.paymentKey,
              pgPaymentType: response_data.type,
              pgPaymentAmount: response_data.totalAmount,
            }
          );

          if (res_insert.status === 200) {
            // alert("주문이 완료되었습니다.");
            window.location.href = "/orderlist";
          }
        } else {
          alert("주문 오류가 발생했습니다.");
          window.location.href = "/cart";
        }
      });
    } else {
      alert("결제를 실패했습니다.");
      window.location.href = "/cart";
    }
  }

  useEffect(() => {
    confirmPayment();
  }, []);
}
