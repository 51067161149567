import usePikaso from "pikaso-react-hook";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  TransformComponent,
  TransformWrapper,
  useControls,
} from "react-zoom-pan-pinch";

const PikasoEditor = forwardRef(
  (
    {
      setPopUpdate,
      popUpdate,
      objSelection,
      setObjSelection,
      setObjx,
      setObjy,
      setIsPopVisible,
      setHistoryStep,
      historyStep,
      zoom,
      setZoom,
      editorIndex,
      selectedEditor,
      setSelectedEditor,
      setSelectedEditorNumber,
      itemData,
      mmPixel,
      setMmPixel,
      onLoadSavedData,
    },
    editorRef
  ) => {
    useImperativeHandle(editorRef, () => ({
      functions,
      editorOptionInit,
      createGuideLine,
      handleZoomIn,
      handleZoomOut,
      handleResetTransform,
    }));

    const transformWrapperRef = useRef(null);

    const handleZoomIn = () => {
      if (transformWrapperRef.current) {
        transformWrapperRef.current.zoomIn();
      }
    };

    const handleZoomOut = () => {
      if (transformWrapperRef.current) {
        transformWrapperRef.current.zoomOut();
      }
    };

    const handleResetTransform = () => {
      if (transformWrapperRef.current) {
        transformWrapperRef.current.resetTransform();
      }
    };

    const createGuideLine = () => {
      if (!editor) return false;

      //alert(mmPixel);

      let size = 2 * mmPixel;
      let stageWidth = editor?.board.stage.attrs.width;
      let stageHeight = editor?.board.stage.attrs.height;

      const guideLine0 = editor?.shapes.line.insert({
        points: [size, size, stageWidth - size, size], //x_start , y_start, x_end, y_end
        name: "guide",
        stroke: "#777",
        strokeWidth: 1,
        dash: [4, 4],
        draggable: false,
        hitStrokeWidth: 0,
      });
      const guideLine1 = editor?.shapes.line.insert({
        points: [
          size,
          stageHeight - size,
          stageWidth - size,
          stageHeight - size,
        ], //x_start , y_start, x_end, y_end
        name: "guide",
        stroke: "#777",
        strokeWidth: 1,
        dash: [4, 4],
        draggable: false,
        hitStrokeWidth: 0,
      });
      const guideLine2 = editor?.shapes.line.insert({
        points: [size, size, size, stageHeight - size], //x_start , y_start, x_end, y_end
        name: "guide",
        stroke: "#777",
        strokeWidth: 1,
        dash: [4, 4],
        draggable: false,
        hitStrokeWidth: 0,
      });
      const guideLine3 = editor?.shapes.line.insert({
        points: [
          stageWidth - size,
          size,
          stageWidth - size,
          stageHeight - size,
        ], //x_start , y_start, x_end, y_end
        name: "guide",
        stroke: "#777",
        strokeWidth: 1,
        dash: [4, 4],
        draggable: false,
        hitStrokeWidth: 0,
      });
    };

    const createGuideLine_postCard = () => {
      const guideLine0 = editor?.shapes.line.insert({
        points: [10, 10, 10 + 750, 10], //x_start , y_start, x_end, y_end
        name: "guide",
        stroke: "#777",
        strokeWidth: 1,
        dash: [4, 4],
      });
      const guideLine1 = editor?.shapes.line.insert({
        points: [10, 520 - 10, 10 + 750, 520 - 10], //x_start , y_start, x_end, y_end
        name: "guide",
        stroke: "#777",
        strokeWidth: 1,
        dash: [4, 4],
      });
      const guideLine2 = editor?.shapes.line.insert({
        points: [10, 10, 10, 520 - 10], //x_start , y_start, x_end, y_end
        name: "guide",
        stroke: "#777",
        strokeWidth: 1,
        dash: [4, 4],
      });
      const guideLine3 = editor?.shapes.line.insert({
        points: [10 + 750, 10, 10 + 750, 520 - 10], //x_start , y_start, x_end, y_end
        name: "guide",
        stroke: "#777",
        strokeWidth: 1,
        dash: [4, 4],
      });
    };

    const editorOptionInit = () => {
      editor?.on(
        ["selection:change", "selection:dragend", "selection:transformend"],
        (data, event) => {
          // 선택 예외 추가
          console.log(data);

          if (data.shapes.length === 0) {
            setIsPopVisible(false);
          }

          editor?.board.selection.list.forEach((obj) => {
            if (
              obj.node.attrs.name === "guide" ||
              obj.node.attrs.name === "bgc"
            ) {
              obj.deselect();
            } else {
            }
            setPopUpdate((popUpdate) => popUpdate + 1);

            setObjSelection(editor?.selection);

            if (editor?.selection.list.length > 0) {
              if (editor?.selection.list[0].type === "label") {
                setObjx(editor?.selection.transformer.getX());
                setObjy(editor?.selection.transformer.getY() - 75);
              } else {
                setObjx(editor?.selection.transformer.getX());
                setObjy(editor?.selection.transformer.getY());
              }
              console.log(editor?.selection.transformer.getWidth());
              setObjSelection(editor?.selection);
              setIsPopVisible(true);
            } else {
              setIsPopVisible(false);
            }
          });
          ////
        }
      );
    };

    const BoardSetUp = async () => {
      console.log("editor", editor);
      console.log("itemData", itemData);

      let stage_width = 0;
      let satge_height = 0;

      // if (["일반지명함", "고급지명함", "펄지명함"].includes(itemData?.PROD_NM)) {
      //   stage_width = 940;
      //   satge_height = 540;
      //   setMmPixel(10);
      // }
      // if (["전단지", "엽서", "포스터", "리플릿"].includes(itemData?.PROD_NM)) {
      let ZoomSet = 0;
      if (itemData?.ITEM_WIDTH > itemData?.ITEM_HEIGHT) {
        ZoomSet = 1000 / itemData?.ITEM_WIDTH;
      } else {
        ZoomSet = 1000 / itemData?.ITEM_HEIGHT;
      }

      stage_width = (itemData?.ITEM_WIDTH + 4) * ZoomSet;
      satge_height = (itemData?.ITEM_HEIGHT + 4) * ZoomSet;

      setMmPixel(ZoomSet);
      // }

      editor?.board.stage.setAttrs({
        width: stage_width,
        height: satge_height,
      });
      editor?.board.background.fill("#fff");
      if (!editor) return false;

      // editor?.board.rescale();
    };

    const initSetting = async () => {
      editor?.reset();
      editor?.snapGrid.setOptions({
        strokeWidth: 1,
        stroke: "#777",
        dash: [3, 3],
        width: 100,
      });
      editor?.snapGrid.setOffset(10); // default is

      // 배경 설정

      // let imageUrl = await getImgUrl();

      // editor?.board.background.setImageFromUrl(imageUrl);
      // editor?.loadFromUrl(imageUrl);

      BoardSetUp();

      //const imageUrl = "/asserts/editor/770_520_postcard.png";

      // 가이드 라인 도우미

      //createGuideLine_postCard();

      editorOptionInit();

      editor?.board.layer.children.forEach((obj) => {
        if (obj.attrs.name === "guide") {
          obj.setAttrs({
            draggable: false,
            hitStrokeWidth: 0,
          });
        }
      });

      setHistoryStep(editor?.history.step + 4);

      onLoadSavedData();
      createGuideLine();
      editorOptionInit();
    };

    const [ref, editor] = usePikaso({
      selection: {
        interactive: true, // 셀렉트 사용 여부
        keyboard: {
          enabled: true, // 키보드 단축키 사용
          movingSpaces: 10, // 속도
          map: {
            // 단축키 설정
            delete: ["Backsapce", "Delete"],
            deselect: ["Escape"],
          },
        },
        transformer: {
          // 스타일링
          borderStroke: "#A5E6F3",
          borderStrokeWidth: 1.5,
          borderDash: [4, 4],
          anchorSize: 7, // 원 사이즈
          anchorFill: "#90E0EF",
          anchorStroke: "#00B4D8",
          anchorStrokeWidth: 1,
        },
        zone: {
          //드래그
          fill: "rgba(165, 230, 243,0.5)",
          stroke: "rgba(165, 230, 243,0.5)",
        },
      },
    });

    useEffect(() => {
      initSetting();
    }, [editor]);

    const functions = {
      createCircle: () => {
        editor?.shapes.circle.insert({
          type: "shape",
          x: editor?.board.stage.attrs.width / 2,
          y: editor?.board.stage.attrs.height / 2,
          radius: 50,
          fill: "#000",
          draggable: true,
        });
      },
      createRect: () => {
        editor?.shapes.rect.insert({
          type: "shape",
          x: editor?.board.stage.attrs.width / 2 - 50,
          y: editor?.board.stage.attrs.height / 2 - 50,
          width: 100,
          height: 100,
          fill: "#000",
          draggable: true,
        });
      },
      createText: () => {
        editor?.shapes.label.insert({
          container: {
            x: editor?.board.stage.attrs.width / 2 - 40,
            y: editor?.board.stage.attrs.height / 2 - 20,
          },
          text: {
            text: "TEXT",
            fill: "#000",
            fontSize: 40,
            fontFamily: "굴림체",
          },
        });
      },
      createImage: (image, w, h) => {
        if (!w || !h || w === undefined || h === undefined) {
          w = 250;
          h = 250;
        }
        editor?.shapes.image.insert(image, {
          x: editor?.board.stage.attrs.width / 2 - w / 2,
          y: editor?.board.stage.attrs.height / 2 - h / 2,
          width: w,
          height: h,
        });
      },
      createBackgorund: (color) => {
        // alert(color);
        editor?.board.background.fill(color);

        // editor?.board.shapes.forEach((obj) => {
        //   if (obj.node.attrs.name === "bgc") {
        //     console.log(obj);
        //     obj.destroy();
        //   }
        // });
        // const bgc = editor?.shapes.rect.insert({
        //   x: 500,
        //   y: 200,
        //   width: 900,
        //   height: 500,
        //   fill: color,
        //   name: "bgc",
        // });
        // bgc?.node.setZIndex(0);
        // bgc?.node.setDraggable(false);
        // bgc?.node.setStrokeHitEnabled(false);
        // bgc?.node.setPointerCapture(false);
        // bgc?.node.hitStrokeWidth(-1);
      },
      undo: () => {
        if (historyStep < editor?.history.step) editor?.undo();
      },
      redo: () => {
        editor?.redo();
      },
      toggleSnapGrid: () => {
        !editor?.snapGrid.active
          ? editor?.snapGrid.enable()
          : editor?.snapGrid.disable();
      },
      zoomIn: () => {
        if (zoom < 1.5) setZoom((zoom) => zoom + 0.1);
      },
      zoomOut: () => {
        if (zoom >= 0.6) setZoom((zoom) => zoom - 0.1);
      },
      zoomReset: () => {
        editor?.board.rescale();
        // setZoom(editor.board.stage._getContentPosition().scaleX);
      },
      zoomSet: () => {
        if (!editor) return false;
        // editor?.board.stage.setAttrs({
        //   width: editor?.board.stage.attrs.width * zoom,
        //   height: editor?.board.stage.attrs.width * zoom,
        // });
        editor.board.stage.content.style.transform = `translate(-50%, -50%) scale(${zoom})`;
      },
      test: () => {
        console.log(editor);
      },
      handleCopy: () => {
        const copy_type = objSelection?.list[0]?.type;
        const copy_attrs = objSelection?.list[0]?.node.attrs;

        const modifiedAttrs = {
          ...copy_attrs,
          x: copy_attrs.x + 10,
          y: copy_attrs.y + 10,
        };

        let new_obj;

        switch (copy_type) {
          case "rect":
            new_obj = editor?.shapes.rect.insert(modifiedAttrs);
            objSelection.deselectAll();
            objSelection.add(new_obj);
            break;
          case "circle":
            new_obj = editor?.shapes.circle.insert(modifiedAttrs);
            objSelection.deselectAll();
            objSelection.add(new_obj);
            break;
          case "triangle":
            new_obj = editor?.shapes.triangle.insert(modifiedAttrs);
            objSelection.deselectAll();
            objSelection.add(new_obj);
            break;
          case "label":
            console.log(editor);
            const copy_text = objSelection?.list[0].textNode.attrs;
            console.log(objSelection?.list[0].textNode.attrs);
            console.log(copy_text);
            new_obj = editor?.shapes.label.insert({
              container: {
                x: modifiedAttrs.x,
                y: modifiedAttrs.y,
              },
              text: {
                ...copy_text,
              },
            });
            objSelection.deselectAll();
            objSelection.add(new_obj);
            break;
          case "image":
            console.log(objSelection.list[0].node.attrs.image.src);
            new_obj = editor?.shapes.image.insert(
              objSelection.list[0].node.attrs.image.src,
              {
                ...modifiedAttrs,
              }
            );
            break;
          // Add cases for other shapes as needed
          default:
            console.error("Unsupported shape type:", copy_type);
        }
      },
      handleFile: () => {
        console.log(
          editor?.board
            .getNodes()
            .filter((obj) => obj.attrs.name === "guide")
            .forEach((obj) => {
              obj.destroy();
            })
        );
        console.log(
          editor.export.toImage({
            callback: () => {
              // alert("");
            },
          })
        );
        const image = editor.export.toImage({
          callback: () => {
            // alert("");
          },
        });
        createGuideLine();
        return image;
      },
      handleJson: () => {
        const res = editor.export.toJson({
          callback: () => {
            // alert("");
          },
        });

        const filterdShapes = res.shapes.filter(
          (obj) => obj.attrs.name != "guide"
        );

        const updatedData = {
          ...res,
          shapes: filterdShapes,
        };

        console.log(updatedData);

        return updatedData;
      },

      handleJsonLoad: (data) => {
        editor?.load(data);
      },

      handleSave: () => {
        console.log(editor?.export.toJson());
      },
      handleLoad: (templet) => {
        const loadjson = templet;

        initSetting();

        const filltedJson = loadjson.shapes.filter(
          (obj) => obj.attrs.name != "guide" && obj.attrs.name != "bgc"
        );

        const bgcColor = loadjson.shapes.filter(
          (obj) => obj.attrs.name === "bgc"
        )[0].attrs.fill;

        console.log(bgcColor);

        functions.createBackgorund(bgcColor);

        filltedJson.forEach((e, index) => {
          console.log(e);
          switch (e.className) {
            case "Rect":
              editor?.shapes.rect.insert({
                ...e?.attrs,
              });
              break;

            case "Image":
              editor?.shapes.image.insert(e.attrs.url, {
                ...e?.attrs,
              });
              break;

            case "Label":
              editor?.shapes.label.insert({
                container: {
                  ...e?.attrs,
                },
                text: {
                  ...e?.children.filter((obj) => obj.className === "Text")[0]
                    .attrs,
                },
              });
              break;
            case "Circle":
              editor?.shapes.circle.insert({
                ...e?.attrs,
              });
              break;
            default:
              alert(e.className);
              break;
          }
        });
      },
      handleCancle: () => {
        editor.selection.deselectAll();
      },
    };

    // useEffect(() => {
    //   functions.zoomSet();
    // }, [zoom]);

    const [isVisible, setIsVisible] = useState();
    useEffect(() => {
      setIsVisible(selectedEditor !== editorIndex ? false : true);
    }, [selectedEditor]);

    return (
      <TransformWrapper
        ref={transformWrapperRef}
        initialScale={1}
        initialPositionX={0}
        initialPositionY={0}
        minScale={0.8}
        maxScale={4}
        centerZoomedOut={true}
        doubleClick={{ disabled: true }}
        disablePadding={true} // this will stop image from sliding here and there. It helps with crop
        panning={{ activationKeys: ["Alt", "Shift", "Control", "SpaceBar"] }}
        wheel={{ wheelDisabled: true }}
      >
        {({ zoomIn, zoomOut, resetTransform }) => (
          <>
            <div
              style={{
                width: "100%",
                height: "100%",
                opacity: isVisible ? "1" : "0",
                zIndex: isVisible ? "998" : "-1",
                position: "absolute",
              }}
            >
              <div
                style={{
                  marginTop: "4em",
                  marginBottom: "8em",
                }}
              >
                <TransformComponent>
                  <div
                    ref={ref}
                    style={{
                      background: "rgb(238, 238, 238)",
                      width: "1920px",
                      height: "72vh",
                    }}
                    onClick={(e) => {
                      console.log(e);
                      if (e.target.nodeName !== "CANVAS") {
                        editor?.selection.deselectAll();
                      }
                    }}
                  />
                </TransformComponent>
              </div>
            </div>
          </>
        )}
      </TransformWrapper>
    );
  }
);

export default PikasoEditor;

{
  /* <div
style={{
  width: "100%",
  height: "100%",
  opacity: isVisible ? "1" : "0",
  zIndex: isVisible ? "998" : "-1",
  position: "absolute",
  padding: "90px",
  paddingBottom: "120px",
}}
> */
}
