import React from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const MergePdf = ({ img1, img2 }) => {
  const image1Binary = img1;
  // 첫 번째 이미지 바이너리 데이터 (base64 형식)
  const image2Binary = img2;
  // 두 번째 이미지 바이너리 데이터 (base64 형식)

  const createPdf = async () => {
    console.log(img1);
    const doc = new jsPDF("p", "mm", "a4");

    // 첫 번째 이미지 추가
    const img1 = new Image();
    img1.src = image1Binary;
    await new Promise((resolve) => {
      img1.onload = () => {
        doc.addImage(img1, "JPEG", 10, 10, 190, 0);
        resolve();
      };
    });

    // 페이지 추가
    doc.addPage();

    // 두 번째 이미지 추가
    const img2 = new Image();
    img2.src = image2Binary;
    await new Promise((resolve) => {
      img2.onload = () => {
        doc.addImage(img2, "JPEG", 10, 10, 190, 0);
        resolve();
      };
    });

    // PDF 다운로드
    doc.save("images.pdf");
  };

  return (
    <div>
      <button onClick={createPdf}>Download PDF</button>
    </div>
  );
};

export default MergePdf;
