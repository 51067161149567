import React, { useEffect, useRef, useState } from "react";
import EditorHeader from "../components/editor/EditorHeader";
import * as S from "../styles/new_styles";
import EditorSidebar from "../components/editor/EditorSidebar";
import { EditorAddtion } from "../components/editor/EditorAddtion";
import PikasoEditor from "../components/editor/PikasoEditor";
import {
  Alert,
  Box,
  Button,
  Snackbar,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import MergePdf from "../components/editor/MergePdf";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useQuery } from "react-query";
//import "../components/editor/EditorPage.css";

const TestKonva3 = () => {
  const [isAddVisible, setIsAddVisible] = useState(false);
  const [isPopVisible, setIsPopVisible] = useState(false);
  const [popUpdate, setPopUpdate] = useState(0);
  const [objNodes, setObjNodes] = useState([]);
  const [objSelection, setObjSelection] = useState();
  const [objx, setObjx] = useState(0);
  const [objy, setObjy] = useState(0);
  const [historyStep, setHistoryStep] = useState(); //초기 히스토리 설정
  const [zoom, setZoom] = useState(1);
  const [groupIndex, setGroupIndex] = useState(0);
  const myRef = useRef([]);
  const [selectedEditor, setSelectedEditor] = useState(0);
  const [editors, setEditors] = useState(["one", "two"]);
  const [img1, setimg1] = useState();
  const [img2, setimg2] = useState();

  //1mm 픽셀 비율
  const [mmPixel, setMmPixel] = useState(10);

  const handleCopy = () => {
    myRef[selectedEditor].functions.handleCopy();
  };
  const handleBehind = () => {
    myRef[selectedEditor].functions.handleBehind();
  };

  const [selectedEditorNumber, setSelectedEditorNumber] = useState(0);

  const [isAltPressed, setIsAltPressed] = useState(false);

  useEffect(() => {
    // 페이지가 마운트될 때 body의 overflow를 hidden으로 설정
    document.body.style.overflow = "hidden";

    // 페이지가 언마운트될 때 body의 overflow를 원래대로 되돌림
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey || event.ctrlKey || event.shiftKey) {
        setIsAltPressed(true);
      }
    };

    const handleKeyUp = (event) => {
      if (!event.altKey || event.ctrlKey || event.shiftKey) {
        setIsAltPressed(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  useEffect(() => {
    setSelectedEditor(selectedEditorNumber);
    ///////기달

    myRef[selectedEditor]?.functions.handleCancle();
  }, [selectedEditorNumber]);

  const handleFileSave = () => {
    console.log(myRef[0].functions.handleFile());
    console.log(myRef[1].functions.handleFile());
    setimg1(myRef[0].functions.handleFile());
    setimg2(myRef[1].functions.handleFile());
    handlePngSave();
  };

  const handlePngSave = () => {
    const pngData1 = myRef[0].functions.handleFile();
    const pngData2 = myRef[1].functions.handleFile();

    // PNG 데이터를 Blob으로 변환
    const blob1 = dataURLtoBlob(pngData1);
    const blob2 = dataURLtoBlob(pngData2);

    // 파일로 저장
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob1);
    link.download = "이미지1.png";
    link.click();
    URL.revokeObjectURL(link.href);

    const link2 = document.createElement("a");
    link2.href = URL.createObjectURL(blob2);
    link2.download = "이미지2.png";
    link2.click();
    URL.revokeObjectURL(link2.href);

    setSnackbar({
      severity: "success",
      children: "PNG 파일로 저장되었습니다.",
    });
  };

  // Data URL을 Blob으로 변환하는 함수
  const dataURLtoBlob = (dataURL) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  const handleExportJson = async () => {
    console.log(myRef[0].functions.handleJson());
    console.log(myRef[1].functions.handleJson());

    const res = await axios.patch(
      process.env.REACT_APP_DB_HOST + "/api/custom_prod",
      {
        data1: myRef[0].functions.handleJson(),
        data2: myRef[1].functions.handleJson(),
        item_sid: item_sid,
      }
    );

    console.log(res);

    if (res.status === 200) {
      setSnackbar({
        severity: "success",
        children: "저장하였습니다.",
      });
      return true;
    } else {
      setSnackbar({
        severity: "error",
        children: "저장을 실패하였습니다.",
      });
      return false;
    }
  };

  // 상품 정보
  const { item_sid } = useParams();
  const [itemData, setItemData] = useState([]);
  const { data } = useQuery("userinfo", { enabled: false });
  const USER_ID = data?.USER_ID;
  const [snackbar, setSnackbar] = useState(null);

  useEffect(() => {
    initdb();
  }, []);

  const onLoadSavedData = () => {
    if (itemData?.ITEM_DESIGN) {
      if (
        itemData?.ITEM_DESIGN[0] === undefined ||
        itemData?.ITEM_DESIGN[1] === undefined
      ) {
        return;
      }
      console.log(JSON.stringify(itemData?.ITEM_DESIGN[0]));
      myRef[0].functions.handleJsonLoad(
        JSON.stringify(itemData?.ITEM_DESIGN[0])
      );
      myRef[1].functions.handleJsonLoad(
        JSON.stringify(itemData?.ITEM_DESIGN[1])
      );
    }
    if (myRef[0] && myRef[1]) {
      myRef[0].createGuideLine();
      myRef[1].createGuideLine();
      myRef[0].editorOptionInit();
      myRef[1].editorOptionInit();
    }
  };

  const saveFile = () => {
    console.log(myRef[0].export.toImage());
  };

  // useEffect(() => {
  //   if (itemData?.ITEM_DESIGN) {
  //     if (
  //       itemData?.ITEM_DESIGN[0] === undefined ||
  //       itemData?.ITEM_DESIGN[1] === undefined
  //     ) {
  //       return;
  //     }
  //     console.log(JSON.stringify(itemData?.ITEM_DESIGN[0]));
  //     myRef[0].functions.handleJsonLoad(
  //       JSON.stringify(itemData?.ITEM_DESIGN[0])
  //     );
  //     myRef[1].functions.handleJsonLoad(
  //       JSON.stringify(itemData?.ITEM_DESIGN[1])
  //     );
  //   }
  //   if (myRef[0] && myRef[1]) {
  //     myRef[0].editorOptionInit();
  //     myRef[1].editorOptionInit();
  //   }
  // }, [itemData, myRef]);

  const initdb = async () => {
    const res = await axios.get(
      process.env.REACT_APP_DB_HOST + "/api/custom_prod",
      {
        params: {
          item_sid: item_sid,
        },
      }
    );

    setItemData(res.data);

    console.log(res);
  };

  return (
    <Box>
      {["a", "greekr4@naver.com"].includes(USER_ID) && (
        <button onClick={handleFileSave}>저장</button>
      )}

      {/* <button
        onClick={() => {
          myRef.current.map((el) => console.log(el));

          console.log(myRef[0].functions.handleFile());
          console.log(myRef[1].functions.handleFile());
        }}
      >
        테스트
      </button> */}
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => {
            setSnackbar(null);
          }}
          autoHideDuration={3000}
        >
          <Alert
            {...snackbar}
            onClose={() => {
              setSnackbar(null);
            }}
          />
        </Snackbar>
      )}
      <Box
        sx={{
          position: "fixed",
          width: "100%",
          padding: "30px",
          bottom: 0,
          left: 0,
          zIndex: 1000,
          backgroundColor: "rgb(237, 240, 244)",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box padding={"0 10px"}>
          <ToggleButtonGroup
            color="primary"
            value={selectedEditorNumber}
            exclusive
            onChange={(e, newAlignment) => {
              if (newAlignment !== null) {
                setSelectedEditorNumber(newAlignment);
              }
            }}
          >
            <ToggleButton value={0}>앞면</ToggleButton>
            <ToggleButton value={1}>뒷면</ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>
      <EditorHeader
        functions={{ z: "z" }}
        isAddVisible={isAddVisible}
        zoom={zoom}
        editor={myRef[selectedEditor]}
        handleFileSave={handleFileSave}
        handleExportJson={handleExportJson}
        itemData={itemData}
      />
      <S.MainLayout
        style={{
          backgroundColor: "rgb(238, 238, 238)",
        }}
      >
        <S.EditorWrapper>
          <EditorSidebar
            isAddVisible={isAddVisible}
            setIsAddVisible={setIsAddVisible}
            functions={{ z: "z" }}
            editor={myRef[selectedEditor]}
            myRef={myRef}
          />
          <div
            style={{
              width: "100%",
              height: "90%",
              position: "absolute",
              cursor: isAltPressed ? "grab" : "default",
            }}
          >
            {itemData?.PROD_NM !== undefined &&
              editors.map((editor, index) => (
                <PikasoEditor
                  itemData={itemData}
                  ref={(el) => (myRef[index] = el)}
                  functions={{ z: "z" }}
                  setPopUpdate={setPopUpdate}
                  popUpdate={popUpdate}
                  objSelection={objSelection}
                  setObjSelection={setObjSelection}
                  setObjx={setObjx}
                  setObjy={setObjy}
                  setIsPopVisible={setIsPopVisible}
                  setHistoryStep={setHistoryStep}
                  historyStep={historyStep}
                  zoom={zoom}
                  setZoom={setZoom}
                  selectedEditor={selectedEditor}
                  setSelectedEditor={setSelectedEditor}
                  editorIndex={index}
                  setSelectedEditorNumber={setSelectedEditorNumber}
                  mmPixel={mmPixel}
                  setMmPixel={setMmPixel}
                  onLoadSavedData={onLoadSavedData}
                />
              ))}
          </div>
          <S.CanvasPopupBox>
            {isPopVisible && (
              <EditorAddtion
                obj={{ type: "rect" }}
                objnodes={objNodes}
                objx={objx + (objx - objx * zoom)}
                objy={objy + 300}
                objSelection={objSelection}
                setIsPopVisible={setIsPopVisible}
                editor={myRef[selectedEditor]}
                popUpdate={popUpdate}
                setPopUpdate={setPopUpdate}
                groupIndex={groupIndex}
                setGroupIndex={setGroupIndex}
                handleCopy={handleCopy}
                handleBehind={handleBehind}
                mmPixel={mmPixel}
                setMmPixel={setMmPixel}
              />
            )}
          </S.CanvasPopupBox>
        </S.EditorWrapper>
      </S.MainLayout>
    </Box>
  );
};

export default TestKonva3;
