import { Computer, TempleHinduTwoTone } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";

const New_EditorSidePanel = () => {
  return (
    <NavBar>
      <SideTabs>
        <SideInner>
          <PanelItem className="active">
            <IconButton>
              <TempleHinduTwoTone />
              <Typography>템플릿</Typography>
            </IconButton>
          </PanelItem>
          <PanelItem>
            <IconButton>
              <Computer />
              <Typography>텍스트</Typography>
            </IconButton>
          </PanelItem>
          <PanelItem>
            <IconButton>
              <Computer />
              <Typography>사진</Typography>
            </IconButton>
          </PanelItem>
          <PanelItem>
            <IconButton>
              <Computer />
              <Typography>도형</Typography>
            </IconButton>
          </PanelItem>
          <PanelItem>
            <IconButton>
              <Computer />
              <Typography>업로드</Typography>
            </IconButton>
          </PanelItem>
          <PanelItem>
            <IconButton>
              <Computer />
              <Typography>배경</Typography>
            </IconButton>
          </PanelItem>
        </SideInner>
      </SideTabs>
      <SideAddtionContainer>
        <SideAddtionInner>
          <SideAddtionTitle>
            <Typography>템플릿</Typography>
          </SideAddtionTitle>
          <SideAddtionContent></SideAddtionContent>
        </SideAddtionInner>
      </SideAddtionContainer>
    </NavBar>
    // navBar
  );
};

export default New_EditorSidePanel;

const PanelItem = styled.div`
  width: 100%;
  height: 80px;
  padding-top: 15px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  white-space: pre;
  &.active {
    background-color: rgba(19, 124, 189, 0.2);
  }
  &:hover {
    background-color: rgba(19, 124, 189, 0.2);
  }
  & p {
    font-family: "Noto Sans KR", sans-serif !important;
    font-size: 14px !important;
    font-weight: 550;
    width: 55px;
  }
  & button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: none;
    }
  }
`;

const NavBar = styled.div`
  display: flex;
  height: 100% !important;
  padding: 0px !important;
  position: relative;
`;

const SideTabs = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 72px;
`;

const SideInner = styled.div`
  display: flex;
  flex-direction: column;
`;

const SideAddtionContainer = styled.div`
  width: 350px;
  height: 100% !important;
  padding: 10px 10px 0px 10px !important;
  background-color: #ffffff;
  box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.1), 0 1px 1px rgba(17, 20, 24, 0.2);
  position: relative;
  z-index: 10;
`;

const SideAddtionInner = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const SideAddtionTitle = styled.div`
  display: "block";
  position: relative;
`;

const SideAddtionContent = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: auto;
`;
