import PortfolioItem from "../components/main/PortfolioItem";
import * as S from "../styles/new_styles";
import React, { useEffect, useState } from "react";
import BottomBannerImg from "../assets/bottom_banner.png";
import GlobProdItem from "../components/products/GlobProdItem";
import img1 from "../assets/products/product-test1.png";
import img2 from "../assets/products/product-test2.png";
import img3 from "../assets/products/product-test3.png";
import img4 from "../assets/products/product-test4.png";
import img5 from "../assets/products/product-test5.png";
import img6 from "../assets/products/product-test6.png";
import MainSlider from "../components/main/MainSlider";
import axios from "axios";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  SpeedDial,
  SpeedDialIcon,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { CheckBox } from "@mui/icons-material";
import { useQuery } from "react-query";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import PopupSlider from "../components/main/PopupSlider";

const MainPage2 = ({ openPopup }) => {
  const [mainProducts, setMainProducts] = useState([]);
  const isPc = useMediaQuery({ query: "(min-width: 768px)" });
  const [Cate, SetCate] = useState([]);
  const [Cate2, setCate2] = useState([]);

  const navigator = useNavigate();

  useEffect(() => {
    if (!isPc) {
      getCate();
    }
  }, [isPc]);

  useEffect(() => {
    if (Cate.length === 0) return;
    const chunks = [];
    for (let i = 0; i < Cate.length; i += 4) {
      chunks.push(Cate.slice(i, i + 4));
    }

    if (chunks[chunks.length - 1].length < 4) {
      while (chunks[chunks.length - 1].length < 4) {
        chunks[chunks.length - 1].push("");
      }
    }
    setCate2(chunks);
  }, [Cate]);

  const getCate = async () => {
    const cateData = (
      await axios.post(process.env.REACT_APP_DB_HOST + "/api/cate")
    ).data;
    const subcateData = (
      await axios.post(process.env.REACT_APP_DB_HOST + "/api/subcate")
    ).data;
    const groupCate = [];
    cateData.forEach((el) => {
      groupCate.push({ ...el, subCate: [] });
    });

    subcateData.forEach((subel) => {
      groupCate.forEach((groupel, index) => {
        if (groupel.CATE_SID === subel.PROD_CATECODE) {
          groupCate[index].subCate.push({
            CATE_NM: subel.PROD_NM,
            CATE_LINK: `/products/detail/${subel.PROD_SID}`,
          });
        }
      });
    });

    SetCate(groupCate);
  };

  useEffect(() => {
    initdb();
  }, []);

  const initdb = async () => {
    setMainProducts(
      (
        await axios.post(
          process.env.REACT_APP_DB_HOST + "/api/product/thumbnail",
          { main: true }
        )
      ).data
    );
  };

  const imageContext = require.context(
    "../assets/products/pf",
    false,
    /\.(jpg)$/
  );
  const imagePaths = imageContext.keys().map(imageContext);

  const test_pf = [
    { pfImg: imagePaths[0] },
    { pfImg: imagePaths[1] },
    { pfImg: imagePaths[2] },
    { pfImg: imagePaths[3] },
    { pfImg: imagePaths[4] },
    { pfImg: imagePaths[5] },
    { pfImg: imagePaths[6] },
    { pfImg: imagePaths[7] },
    { pfImg: imagePaths[8] },
    { pfImg: imagePaths[9] },
  ];

  const { data, refetch } = useQuery("userinfo", {
    enabled: false, // The query won't run automatically
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [todayClose, setTodayClose] = useState(false);

  useEffect(() => {
    checkDialog();
  }, []);

  const checkDialog = async () => {
    // await refetch();

    const isLogin = data?.USER_ID;

    if (!isLogin) {
      const todayClose = localStorage.getItem("todayClose");
      if (todayClose && new Date(todayClose) > new Date()) {
        setDialogOpen(false);
        return;
      } else {
        setDialogOpen(true);
        return;
      }
    } else {
      setDialogOpen(false);
      return;
    }
  };

  return (
    <>
      {isPc ? (
        <>
          {" "}
          <S.MainLayout>
            <S.MainSection>
              <MainSlider />
            </S.MainSection>
            <S.MainSection bgc="#fff">
              <S.GlobProdWrapper>
                <S.GlobProdList>
                  {mainProducts.map((item, index) => (
                    <GlobProdItem item={item} key={index} />
                  ))}
                </S.GlobProdList>
              </S.GlobProdWrapper>
            </S.MainSection>
            <S.MainSection bgc="#f9fafc">
              <S.MainPortfolioWrapper>
                <S.PortfolioTextBox>
                  <S.PortfolioTitle>스노우플래닛 Portfolio</S.PortfolioTitle>
                </S.PortfolioTextBox>
                <S.PortfolioList>
                  {test_pf.map((item, index) => (
                    <PortfolioItem item={item} key={index} />
                  ))}
                </S.PortfolioList>
              </S.MainPortfolioWrapper>
            </S.MainSection>
            <S.MainSection>
              <S.MainBottomBannerBox img={"/asserts/banner/bottom_banner.png"}>
                {/* <div className="inner">
            <h1>무한대의 명함을 경험하다.</h1>
            <h2>
              한번 보면 빠져들 수밖에 없는 매력적인 명함
              <br />
              <br />
              직접 보고 느껴보세요!
            </h2>
          </div> */}
              </S.MainBottomBannerBox>
            </S.MainSection>
          </S.MainLayout>
          <Dialog
            open={dialogOpen}
            onClose={() => {
              setDialogOpen(false);
            }}
          >
            <DialogContent sx={{ padding: "0", width: "550px" }}>
              {/* <Box
                  sx={{
                    backgroundImage: `url(/event1.jpg)`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    width: "550px",
                    height: "700px",
                  }}
                /> */}
              <PopupSlider
                setDialogOpen={setDialogOpen}
                openPopup={openPopup}
              />
            </DialogContent>
            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <FormControlLabel
                sx={{ ml: "10px" }}
                label={
                  <Typography sx={{ fontSize: "14px", color: "#1976D2" }}>
                    오늘 하루 보지 않기
                  </Typography>
                }
                value={todayClose}
                onChange={(event) => {
                  setTodayClose(event.target.checked);
                }}
                control={<Checkbox size="small" />}
              />
              <Button
                sx={{ mr: "10px" }}
                onClick={() => {
                  if (todayClose) {
                    const date = new Date();
                    date.setDate(date.getDate() + 1);
                    localStorage.setItem("todayClose", date);
                  }
                  setDialogOpen(false);
                }}
              >
                닫기
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <>
          <Box>
            <MainSlider />
          </Box>
          <Box
            sx={{
              mt: "10px",
              mb: "10px",
            }}
          >
            {Cate2?.map((chunk, chunkIndex) => (
              <Tabs key={chunkIndex} variant="fullWidth">
                {chunk.map((cate, index) => (
                  <Tab
                    key={index}
                    label={<TapSpan>{cate.CATE_NM}</TapSpan>}
                    onClick={() => {
                      navigator(`/products/${cate.CATE_SID}`);
                    }}
                  />
                ))}
              </Tabs>
            ))}
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(155px, 1fr))",
              gap: "10px",
              ml: "10px",
              mr: "10px",
            }}
          >
            {mainProducts.map((item, index) => (
              <GlobProdItem item={item} key={index} />
            ))}
          </Box>
        </>
      )}
    </>
  );
};

const TapSpan = styled.span`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  color: #333;
`;
export default MainPage2;
