import React, { useEffect, useState } from "react";
import Slider from "react-slick";

import * as S from "../../styles/new_styles";
import axios from "axios";
import { ArrowLeft, ArrowRight, ForkLeft } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useMediaQuery } from "react-responsive";
const MainSlider = () => {
  const isPc = useMediaQuery({ query: "(min-width: 768px)" });
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    initdb();
  }, []);

  const initdb = async () => {
    if (isPc) {
      setBanners(
        (
          await axios.get(process.env.REACT_APP_DB_HOST + "/api/banner", {
            params: {
              cate: "MAIN",
            },
          })
        ).data
      );
    } else {
      setBanners(
        (
          await axios.get(process.env.REACT_APP_DB_HOST + "/api/banner", {
            params: {
              cate: "MOBILE_MAIN",
            },
          })
        ).data
      );
    }
  };

  const NextArrow = ({ onClick }) => {
    // props로 onClick을 전달해줘야 한다.
    return (
      <Box
        sx={{
          borderRadius: "50%",
          position: "absolute",
          top: "50%",
          right: "10px",
          transform: "translateY(-50%)",
          backgroundColor: "rgba(255,255,255,0.5)",
          padding: "10px",
          cursor: "pointer",
          zIndex: 1000,
          ":hover": { backgroundColor: "rgba(255,255,255,0.8)" },
        }}
        onClick={onClick}
      >
        <ArrowRight />
      </Box>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <Box
        sx={{
          borderRadius: "50%",
          position: "absolute",
          top: "50%",
          left: "10px",
          transform: "translateY(-50%)",
          backgroundColor: "rgba(255,255,255,0.5)",
          padding: "10px",
          cursor: "pointer",
          zIndex: 1000,
          ":hover": { backgroundColor: "rgba(255,255,255,0.8)" },
        }}
        onClick={onClick}
      >
        <ArrowLeft />
      </Box>
    );
  };

  const settings = {
    dots: true, // 슬라이더 아래에 슬라이드 개수를 점 형태로 표시
    infinite: true, // 슬라이드가 맨 끝에 도달했을 때 처음 슬라이드를 보여줄지 여부
    slidesToShow: 1, // 화면에 한번에 표시할 슬라이드 개수 설정
    slidesToScroll: 1, // 옆으로 스크롤할 때 보여줄 슬라이드 수 설정
    speed: 500, // 슬라이드 넘길 때 속도
    autoplay: true, // 슬라이드를 자동으로 넘길지 여부
    autoplaySpeed: 7000, // 자동으로 넘길 시 시간 간격
    nextArrow: <NextArrow />, // 화살표 버튼을 커스텀해서 사용
    prevArrow: <PrevArrow />,
  };

  return (
    <>
      <Slider {...settings}>
        {banners?.map((el, index) => (
          <div key={index}>
            {isPc ? (
              <S.MainBannerBox img={el.BANNER_IMAGE} />
            ) : (
              <S.MainBannerBox_Mob img={el.BANNER_IMAGE} />
            )}
          </div>
        ))}
      </Slider>
    </>
  );
};

export default MainSlider;
