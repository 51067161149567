import React from "react";
import styled from "styled-components";

const GuidePage = () => {
  return (
    <>
      <GuideWrap>
        <GuideContent>
          <GuideContentTitle>주문 방법</GuideContentTitle>
          <GuideContentDesc>
            <GuideImage1 src={"/asserts/guide/guide_img1.png"} />
          </GuideContentDesc>
        </GuideContent>
      </GuideWrap>
    </>
  );
};

export default GuidePage;

const GuideWrap = styled.div`
  width: 100%;
  max-width: 1140px;
  height: 100%;
  margin: 0 auto;
`;

const GuideContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const GuideContentTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 100px;
`;

const GuideContentDesc = styled.div`
  font-size: 16px;
  font-weight: 400;
`;

const GuideImage1 = styled.img`
  width: 100%;
  height: 100%;
`;
