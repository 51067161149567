import React from "react";
import styled from "styled-components";
import New_EditorKonva from "./New_EditorKonva";

const New_EditorWorkSpace = ({ editorRef, selectedNode, setSelectedNode }) => {
  return (
    <WorkSpaceContainer>
      <WorkSpaceInner>
        <CanvasPage>
          <New_EditorKonva
            editorRef={editorRef}
            selectedNode={selectedNode}
            setSelectedNode={setSelectedNode}
          />
        </CanvasPage>
      </WorkSpaceInner>
    </WorkSpaceContainer>
  );
};

export default New_EditorWorkSpace;

const WorkSpaceContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  outline: none;
  flex: 1 1 0%;
  background-color: rgba(232, 232, 232, 0.9);
  overflow: hidden; // 추가: 내부 스크롤 방지
`;

const WorkSpaceInner = styled.div`
  width: 100%;
  height: 100%;
  height: 100vh;
  /* overflow: hidden auto;
  & ::-webkit-scrollbar {
    display: block !important;
  } */
`;

const CanvasPage = styled.div`
  position: relative;
  width: 100%;
  height: 100%; // 추가: 부모 컨테이너의 전체 높이 사용
  overflow-y: auto; // 추가: 세로 스크롤만 허용
  &::-webkit-scrollbar {
    display: block !important;
  }
`;
