// 타공 단가표//
export const PunchingPrice = [
  {
    maesu: 1,
    cnt1: 8400,
    cnt2: 8400,
    cnt3: 8400,
    cnt4: 8400,
  },
  {
    maesu: 2,
    cnt1: 4200,
    cnt2: 4200,
    cnt3: 4200,
    cnt4: 4200,
  },
  {
    maesu: 3,
    cnt1: 2800,
    cnt2: 2800,
    cnt3: 2800,
    cnt4: 2800,
  },
  {
    maesu: 4,
    cnt1: 2100,
    cnt2: 2100,
    cnt3: 2100,
    cnt4: 2100,
  },
  {
    maesu: 5,
    cnt1: 1680,
    cnt2: 1680,
    cnt3: 1680,
    cnt4: 1680,
  },
  {
    maesu: 8,
    cnt1: 1050,
    cnt2: 1050,
    cnt3: 1050,
    cnt4: 1050,
  },
  {
    maesu: 10,
    cnt1: 840,
    cnt2: 840,
    cnt3: 840,
    cnt4: 840,
  },
  {
    maesu: 15,
    cnt1: 560,
    cnt2: 560,
    cnt3: 560,
    cnt4: 560,
  },
  {
    maesu: 20,
    cnt1: 420,
    cnt2: 420,
    cnt3: 420,
    cnt4: 420,
  },
  {
    maesu: 25,
    cnt1: 336,
    cnt2: 336,
    cnt3: 336,
    cnt4: 336,
  },
  {
    maesu: 30,
    cnt1: 280,
    cnt2: 280,
    cnt3: 280,
    cnt4: 280,
  },
  {
    maesu: 35,
    cnt1: 240,
    cnt2: 240,
    cnt3: 240,
    cnt4: 240,
  },
  {
    maesu: 40,
    cnt1: 210,
    cnt2: 210,
    cnt3: 210,
    cnt4: 210,
  },
  {
    maesu: 45,
    cnt1: 186.666667,
    cnt2: 186.666667,
    cnt3: 186.666667,
    cnt4: 186.666667,
  },
  {
    maesu: 50,
    cnt1: 168,
    cnt2: 168,
    cnt3: 168,
    cnt4: 168,
  },
  {
    maesu: 55,
    cnt1: 152.727273,
    cnt2: 152.727273,
    cnt3: 152.727273,
    cnt4: 152.727273,
  },
  {
    maesu: 60,
    cnt1: 140,
    cnt2: 140,
    cnt3: 140,
    cnt4: 140,
  },
  {
    maesu: 65,
    cnt1: 129.230769,
    cnt2: 129.230769,
    cnt3: 129.230769,
    cnt4: 129.230769,
  },
  {
    maesu: 70,
    cnt1: 120,
    cnt2: 120,
    cnt3: 120,
    cnt4: 120,
  },
  {
    maesu: 75,
    cnt1: 112,
    cnt2: 112,
    cnt3: 112,
    cnt4: 112,
  },
  {
    maesu: 80,
    cnt1: 105,
    cnt2: 105,
    cnt3: 105,
    cnt4: 105,
  },
  {
    maesu: 85,
    cnt1: 98.823529,
    cnt2: 98.823529,
    cnt3: 98.823529,
    cnt4: 98.823529,
  },
  {
    maesu: 90,
    cnt1: 93.333333,
    cnt2: 93.333333,
    cnt3: 93.333333,
    cnt4: 93.333333,
  },
  {
    maesu: 95,
    cnt1: 88.421053,
    cnt2: 88.421053,
    cnt3: 88.421053,
    cnt4: 88.421053,
  },
  {
    maesu: 100,
    cnt1: 84,
    cnt2: 84,
    cnt3: 84,
    cnt4: 84,
  },
  {
    maesu: 110,
    cnt1: 76.363636,
    cnt2: 76.363636,
    cnt3: 76.363636,
    cnt4: 76.363636,
  },
  {
    maesu: 120,
    cnt1: 70,
    cnt2: 70,
    cnt3: 70,
    cnt4: 70,
  },
  {
    maesu: 130,
    cnt1: 64.615385,
    cnt2: 64.615385,
    cnt3: 64.615385,
    cnt4: 64.615385,
  },
  {
    maesu: 140,
    cnt1: 60,
    cnt2: 60,
    cnt3: 60,
    cnt4: 60,
  },
  {
    maesu: 150,
    cnt1: 56,
    cnt2: 56,
    cnt3: 56,
    cnt4: 56,
  },
  {
    maesu: 160,
    cnt1: 52.5,
    cnt2: 52.5,
    cnt3: 52.5,
    cnt4: 52.5,
  },
  {
    maesu: 170,
    cnt1: 49.411765,
    cnt2: 49.411765,
    cnt3: 49.411765,
    cnt4: 49.411765,
  },
  {
    maesu: 180,
    cnt1: 46.666667,
    cnt2: 46.666667,
    cnt3: 46.666667,
    cnt4: 46.666667,
  },
  {
    maesu: 190,
    cnt1: 44.210526,
    cnt2: 44.210526,
    cnt3: 44.210526,
    cnt4: 44.210526,
  },
  {
    maesu: 200,
    cnt1: 42,
    cnt2: 42,
    cnt3: 42,
    cnt4: 42,
  },
  {
    maesu: 210,
    cnt1: 40,
    cnt2: 40,
    cnt3: 40,
    cnt4: 40,
  },
  {
    maesu: 220,
    cnt1: 38.181818,
    cnt2: 38.181818,
    cnt3: 38.181818,
    cnt4: 38.181818,
  },
  {
    maesu: 230,
    cnt1: 36.521739,
    cnt2: 36.521739,
    cnt3: 36.521739,
    cnt4: 36.521739,
  },
  {
    maesu: 240,
    cnt1: 35,
    cnt2: 35,
    cnt3: 35,
    cnt4: 35,
  },
  {
    maesu: 250,
    cnt1: 33.6,
    cnt2: 33.6,
    cnt3: 33.6,
    cnt4: 33.6,
  },
  {
    maesu: 260,
    cnt1: 32.307692,
    cnt2: 32.307692,
    cnt3: 32.307692,
    cnt4: 32.307692,
  },
  {
    maesu: 270,
    cnt1: 31.111111,
    cnt2: 31.111111,
    cnt3: 31.111111,
    cnt4: 31.111111,
  },
  {
    maesu: 280,
    cnt1: 30,
    cnt2: 30,
    cnt3: 30,
    cnt4: 30,
  },
  {
    maesu: 290,
    cnt1: 28.965517,
    cnt2: 28.965517,
    cnt3: 28.965517,
    cnt4: 28.965517,
  },
  {
    maesu: 300,
    cnt1: 28,
    cnt2: 28,
    cnt3: 28,
    cnt4: 28,
  },
  {
    maesu: 310,
    cnt1: 27.096774,
    cnt2: 27.096774,
    cnt3: 27.096774,
    cnt4: 27.096774,
  },
  {
    maesu: 320,
    cnt1: 26.25,
    cnt2: 26.25,
    cnt3: 26.25,
    cnt4: 26.25,
  },
  {
    maesu: 330,
    cnt1: 25.454545,
    cnt2: 25.454545,
    cnt3: 25.454545,
    cnt4: 25.454545,
  },
  {
    maesu: 340,
    cnt1: 24.705882,
    cnt2: 24.705882,
    cnt3: 24.705882,
    cnt4: 24.705882,
  },
  {
    maesu: 350,
    cnt1: 24,
    cnt2: 24,
    cnt3: 24,
    cnt4: 24,
  },
  {
    maesu: 360,
    cnt1: 23.333333,
    cnt2: 23.333333,
    cnt3: 23.333333,
    cnt4: 23.333333,
  },
  {
    maesu: 370,
    cnt1: 22.702703,
    cnt2: 22.702703,
    cnt3: 22.702703,
    cnt4: 22.702703,
  },
  {
    maesu: 380,
    cnt1: 22.105263,
    cnt2: 22.105263,
    cnt3: 22.105263,
    cnt4: 22.105263,
  },
  {
    maesu: 390,
    cnt1: 21.538462,
    cnt2: 21.538462,
    cnt3: 21.538462,
    cnt4: 21.538462,
  },
  {
    maesu: 400,
    cnt1: 21,
    cnt2: 21,
    cnt3: 21,
    cnt4: 21,
  },
  {
    maesu: 410,
    cnt1: 20.487805,
    cnt2: 20.487805,
    cnt3: 20.487805,
    cnt4: 20.487805,
  },
  {
    maesu: 420,
    cnt1: 20,
    cnt2: 20,
    cnt3: 20,
    cnt4: 20,
  },
  {
    maesu: 430,
    cnt1: 19.534884,
    cnt2: 19.534884,
    cnt3: 19.534884,
    cnt4: 19.534884,
  },
  {
    maesu: 440,
    cnt1: 19.090909,
    cnt2: 19.090909,
    cnt3: 19.090909,
    cnt4: 19.090909,
  },
  {
    maesu: 450,
    cnt1: 18.666667,
    cnt2: 18.666667,
    cnt3: 18.666667,
    cnt4: 18.666667,
  },
  {
    maesu: 460,
    cnt1: 18.26087,
    cnt2: 18.26087,
    cnt3: 18.26087,
    cnt4: 18.26087,
  },
  {
    maesu: 470,
    cnt1: 17.87234,
    cnt2: 17.87234,
    cnt3: 17.87234,
    cnt4: 17.87234,
  },
  {
    maesu: 480,
    cnt1: 17.5,
    cnt2: 17.5,
    cnt3: 17.5,
    cnt4: 17.5,
  },
  {
    maesu: 490,
    cnt1: 17.142857,
    cnt2: 17.142857,
    cnt3: 17.142857,
    cnt4: 17.142857,
  },
  {
    maesu: 500,
    cnt1: 16.8,
    cnt2: 16.8,
    cnt3: 16.8,
    cnt4: 16.8,
  },
  {
    maesu: 510,
    cnt1: 16.470588,
    cnt2: 16.470588,
    cnt3: 16.470588,
    cnt4: 16.470588,
  },
  {
    maesu: 520,
    cnt1: 16.153846,
    cnt2: 16.153846,
    cnt3: 16.153846,
    cnt4: 16.153846,
  },
  {
    maesu: 530,
    cnt1: 15.849057,
    cnt2: 15.849057,
    cnt3: 15.849057,
    cnt4: 15.849057,
  },
  {
    maesu: 540,
    cnt1: 15.555556,
    cnt2: 15.555556,
    cnt3: 15.555556,
    cnt4: 15.555556,
  },
  {
    maesu: 550,
    cnt1: 15.272727,
    cnt2: 15.272727,
    cnt3: 15.272727,
    cnt4: 15.272727,
  },
  {
    maesu: 560,
    cnt1: 15,
    cnt2: 15,
    cnt3: 15,
    cnt4: 15,
  },
  {
    maesu: 570,
    cnt1: 14.736842,
    cnt2: 14.736842,
    cnt3: 14.736842,
    cnt4: 14.736842,
  },
  {
    maesu: 580,
    cnt1: 14.482759,
    cnt2: 14.482759,
    cnt3: 14.482759,
    cnt4: 14.482759,
  },
  {
    maesu: 590,
    cnt1: 14.237288,
    cnt2: 14.237288,
    cnt3: 14.237288,
    cnt4: 14.237288,
  },
  {
    maesu: 600,
    cnt1: 14,
    cnt2: 14,
    cnt3: 14,
    cnt4: 14,
  },
  {
    maesu: 610,
    cnt1: 13.770492,
    cnt2: 13.770492,
    cnt3: 13.770492,
    cnt4: 13.770492,
  },
  {
    maesu: 620,
    cnt1: 13.548387,
    cnt2: 13.548387,
    cnt3: 13.548387,
    cnt4: 13.548387,
  },
  {
    maesu: 630,
    cnt1: 13.333333,
    cnt2: 13.333333,
    cnt3: 13.333333,
    cnt4: 13.333333,
  },
  {
    maesu: 640,
    cnt1: 13.125,
    cnt2: 13.125,
    cnt3: 13.125,
    cnt4: 13.125,
  },
  {
    maesu: 650,
    cnt1: 12.923077,
    cnt2: 12.923077,
    cnt3: 12.923077,
    cnt4: 12.923077,
  },
  {
    maesu: 660,
    cnt1: 12.727273,
    cnt2: 12.727273,
    cnt3: 12.727273,
    cnt4: 12.727273,
  },
  {
    maesu: 670,
    cnt1: 12.537313,
    cnt2: 12.537313,
    cnt3: 12.537313,
    cnt4: 12.537313,
  },
  {
    maesu: 680,
    cnt1: 12.352941,
    cnt2: 12.352941,
    cnt3: 12.352941,
    cnt4: 12.352941,
  },
  {
    maesu: 690,
    cnt1: 12.173913,
    cnt2: 12.173913,
    cnt3: 12.173913,
    cnt4: 12.173913,
  },
  {
    maesu: 700,
    cnt1: 12,
    cnt2: 12,
    cnt3: 12,
    cnt4: 12,
  },
  {
    maesu: 710,
    cnt1: 11.830986,
    cnt2: 11.830986,
    cnt3: 11.830986,
    cnt4: 11.830986,
  },
  {
    maesu: 720,
    cnt1: 11.666667,
    cnt2: 11.666667,
    cnt3: 11.666667,
    cnt4: 11.666667,
  },
  {
    maesu: 730,
    cnt1: 11.506849,
    cnt2: 11.506849,
    cnt3: 11.506849,
    cnt4: 11.506849,
  },
  {
    maesu: 740,
    cnt1: 11.351351,
    cnt2: 11.351351,
    cnt3: 11.351351,
    cnt4: 11.351351,
  },
  {
    maesu: 750,
    cnt1: 11.2,
    cnt2: 11.2,
    cnt3: 11.2,
    cnt4: 11.2,
  },
  {
    maesu: 760,
    cnt1: 11.052632,
    cnt2: 11.052632,
    cnt3: 11.052632,
    cnt4: 11.052632,
  },
  {
    maesu: 770,
    cnt1: 10.909091,
    cnt2: 10.909091,
    cnt3: 10.909091,
    cnt4: 10.909091,
  },
  {
    maesu: 780,
    cnt1: 10.769231,
    cnt2: 10.769231,
    cnt3: 10.769231,
    cnt4: 10.769231,
  },
  {
    maesu: 790,
    cnt1: 10.632911,
    cnt2: 10.632911,
    cnt3: 10.632911,
    cnt4: 10.632911,
  },
  {
    maesu: 800,
    cnt1: 10.5,
    cnt2: 10.5,
    cnt3: 10.5,
    cnt4: 10.5,
  },
  {
    maesu: 810,
    cnt1: 10.37037,
    cnt2: 10.37037,
    cnt3: 10.37037,
    cnt4: 10.37037,
  },
  {
    maesu: 820,
    cnt1: 10.243902,
    cnt2: 10.243902,
    cnt3: 10.243902,
    cnt4: 10.243902,
  },
  {
    maesu: 830,
    cnt1: 10.120482,
    cnt2: 10.120482,
    cnt3: 10.120482,
    cnt4: 10.120482,
  },
  {
    maesu: 840,
    cnt1: 10,
    cnt2: 10,
    cnt3: 10,
    cnt4: 10.714286,
  },
  {
    maesu: 850,
    cnt1: 9.882353,
    cnt2: 9.882353,
    cnt3: 9.882353,
    cnt4: 10.588235,
  },
  {
    maesu: 860,
    cnt1: 9.767442,
    cnt2: 9.767442,
    cnt3: 9.767442,
    cnt4: 10.465116,
  },
  {
    maesu: 870,
    cnt1: 9.655172,
    cnt2: 9.655172,
    cnt3: 9.655172,
    cnt4: 10.344828,
  },
  {
    maesu: 880,
    cnt1: 9.545455,
    cnt2: 9.545455,
    cnt3: 9.545455,
    cnt4: 10.227273,
  },
  {
    maesu: 890,
    cnt1: 9.438202,
    cnt2: 9.438202,
    cnt3: 9.438202,
    cnt4: 10.11236,
  },
  {
    maesu: 900,
    cnt1: 9.333333,
    cnt2: 9.333333,
    cnt3: 9.333333,
    cnt4: 10,
  },
  {
    maesu: 910,
    cnt1: 9.230769,
    cnt2: 9.230769,
    cnt3: 9.230769,
    cnt4: 9.89011,
  },
  {
    maesu: 920,
    cnt1: 9.130435,
    cnt2: 9.130435,
    cnt3: 9.130435,
    cnt4: 9.782609,
  },
  {
    maesu: 930,
    cnt1: 9.032258,
    cnt2: 9.032258,
    cnt3: 9.032258,
    cnt4: 9.677419,
  },
  {
    maesu: 940,
    cnt1: 8.93617,
    cnt2: 8.93617,
    cnt3: 8.93617,
    cnt4: 9.574468,
  },
  {
    maesu: 950,
    cnt1: 8.842105,
    cnt2: 8.842105,
    cnt3: 8.842105,
    cnt4: 10.526316,
  },
  {
    maesu: 960,
    cnt1: 8.75,
    cnt2: 8.75,
    cnt3: 8.75,
    cnt4: 10.416667,
  },
  {
    maesu: 970,
    cnt1: 8.659794,
    cnt2: 8.659794,
    cnt3: 8.659794,
    cnt4: 10.309278,
  },
  {
    maesu: 980,
    cnt1: 8.571429,
    cnt2: 8.571429,
    cnt3: 8.571429,
    cnt4: 10.204082,
  },
  {
    maesu: 990,
    cnt1: 8.484848,
    cnt2: 8.484848,
    cnt3: 8.484848,
    cnt4: 10.10101,
  },
  {
    maesu: 1000,
    cnt1: 8.4,
    cnt2: 8.4,
    cnt3: 8.4,
    cnt4: 10,
  },
  {
    maesu: 1010,
    cnt1: 8.415842,
    cnt2: 8.415842,
    cnt3: 8.415842,
    cnt4: 8.415842,
  },
  {
    maesu: 1020,
    cnt1: 8.431373,
    cnt2: 8.431373,
    cnt3: 8.431373,
    cnt4: 8.431373,
  },
  {
    maesu: 1030,
    cnt1: 8.446602,
    cnt2: 8.446602,
    cnt3: 8.446602,
    cnt4: 8.446602,
  },
  {
    maesu: 1040,
    cnt1: 8.461538,
    cnt2: 8.461538,
    cnt3: 8.461538,
    cnt4: 8.461538,
  },
  {
    maesu: 1050,
    cnt1: 8.47619,
    cnt2: 8.47619,
    cnt3: 8.47619,
    cnt4: 8.47619,
  },
  {
    maesu: 1060,
    cnt1: 8.490566,
    cnt2: 8.490566,
    cnt3: 8.490566,
    cnt4: 8.490566,
  },
  {
    maesu: 1070,
    cnt1: 8.411215,
    cnt2: 8.411215,
    cnt3: 8.411215,
    cnt4: 8.411215,
  },
  {
    maesu: 1080,
    cnt1: 8.425926,
    cnt2: 8.425926,
    cnt3: 8.425926,
    cnt4: 8.425926,
  },
  {
    maesu: 1090,
    cnt1: 8.440367,
    cnt2: 8.440367,
    cnt3: 8.440367,
    cnt4: 8.440367,
  },
  {
    maesu: 1100,
    cnt1: 8.454545,
    cnt2: 8.454545,
    cnt3: 8.454545,
    cnt4: 8.454545,
  },
  {
    maesu: 1110,
    cnt1: 8.468468,
    cnt2: 8.468468,
    cnt3: 8.468468,
    cnt4: 8.468468,
  },
  {
    maesu: 1120,
    cnt1: 8.482143,
    cnt2: 8.482143,
    cnt3: 8.482143,
    cnt4: 8.482143,
  },
  {
    maesu: 1130,
    cnt1: 8.40708,
    cnt2: 8.40708,
    cnt3: 8.40708,
    cnt4: 8.40708,
  },
  {
    maesu: 1140,
    cnt1: 8.421053,
    cnt2: 8.421053,
    cnt3: 8.421053,
    cnt4: 8.421053,
  },
  {
    maesu: 1150,
    cnt1: 8.434783,
    cnt2: 8.434783,
    cnt3: 8.434783,
    cnt4: 8.434783,
  },
  {
    maesu: 1160,
    cnt1: 8.448276,
    cnt2: 8.448276,
    cnt3: 8.448276,
    cnt4: 8.448276,
  },
  {
    maesu: 1170,
    cnt1: 8.461538,
    cnt2: 8.461538,
    cnt3: 8.461538,
    cnt4: 8.461538,
  },
  {
    maesu: 1180,
    cnt1: 8.474576,
    cnt2: 8.474576,
    cnt3: 8.474576,
    cnt4: 8.474576,
  },
  {
    maesu: 1190,
    cnt1: 8.403361,
    cnt2: 8.403361,
    cnt3: 8.403361,
    cnt4: 8.403361,
  },
  {
    maesu: 1200,
    cnt1: 8.416667,
    cnt2: 8.416667,
    cnt3: 8.416667,
    cnt4: 8.416667,
  },
  {
    maesu: 1210,
    cnt1: 8.429752,
    cnt2: 8.429752,
    cnt3: 8.429752,
    cnt4: 8.429752,
  },
  {
    maesu: 1220,
    cnt1: 8.442623,
    cnt2: 8.442623,
    cnt3: 8.442623,
    cnt4: 8.442623,
  },
  {
    maesu: 1230,
    cnt1: 8.455285,
    cnt2: 8.455285,
    cnt3: 8.455285,
    cnt4: 8.455285,
  },
  {
    maesu: 1240,
    cnt1: 8.467742,
    cnt2: 8.467742,
    cnt3: 8.467742,
    cnt4: 8.467742,
  },
  {
    maesu: 1250,
    cnt1: 8.4,
    cnt2: 8.4,
    cnt3: 8.4,
    cnt4: 8.4,
  },
  {
    maesu: 1260,
    cnt1: 8.412698,
    cnt2: 8.412698,
    cnt3: 8.412698,
    cnt4: 8.412698,
  },
  {
    maesu: 1270,
    cnt1: 8.425197,
    cnt2: 8.425197,
    cnt3: 8.425197,
    cnt4: 8.425197,
  },
  {
    maesu: 1280,
    cnt1: 8.4375,
    cnt2: 8.4375,
    cnt3: 8.4375,
    cnt4: 8.4375,
  },
  {
    maesu: 1290,
    cnt1: 8.449612,
    cnt2: 8.449612,
    cnt3: 8.449612,
    cnt4: 8.449612,
  },
  {
    maesu: 1300,
    cnt1: 8.461538,
    cnt2: 8.461538,
    cnt3: 8.461538,
    cnt4: 8.461538,
  },
  {
    maesu: 1310,
    cnt1: 8.473282,
    cnt2: 8.473282,
    cnt3: 8.473282,
    cnt4: 8.473282,
  },
  {
    maesu: 1320,
    cnt1: 8.409091,
    cnt2: 8.409091,
    cnt3: 8.409091,
    cnt4: 8.409091,
  },
  {
    maesu: 1330,
    cnt1: 8.421053,
    cnt2: 8.421053,
    cnt3: 8.421053,
    cnt4: 8.421053,
  },
  {
    maesu: 1340,
    cnt1: 8.432836,
    cnt2: 8.432836,
    cnt3: 8.432836,
    cnt4: 8.432836,
  },
  {
    maesu: 1350,
    cnt1: 8.444444,
    cnt2: 8.444444,
    cnt3: 8.444444,
    cnt4: 8.444444,
  },
  {
    maesu: 1360,
    cnt1: 8.455882,
    cnt2: 8.455882,
    cnt3: 8.455882,
    cnt4: 8.455882,
  },
  {
    maesu: 1370,
    cnt1: 8.467153,
    cnt2: 8.467153,
    cnt3: 8.467153,
    cnt4: 8.467153,
  },
  {
    maesu: 1380,
    cnt1: 8.405797,
    cnt2: 8.405797,
    cnt3: 8.405797,
    cnt4: 8.405797,
  },
  {
    maesu: 1390,
    cnt1: 8.417266,
    cnt2: 8.417266,
    cnt3: 8.417266,
    cnt4: 8.417266,
  },
  {
    maesu: 1400,
    cnt1: 8.428571,
    cnt2: 8.428571,
    cnt3: 8.428571,
    cnt4: 8.428571,
  },
  {
    maesu: 1410,
    cnt1: 8.439716,
    cnt2: 8.439716,
    cnt3: 8.439716,
    cnt4: 8.439716,
  },
  {
    maesu: 1420,
    cnt1: 8.450704,
    cnt2: 8.450704,
    cnt3: 8.450704,
    cnt4: 8.450704,
  },
  {
    maesu: 1430,
    cnt1: 8.461538,
    cnt2: 8.461538,
    cnt3: 8.461538,
    cnt4: 8.461538,
  },
  {
    maesu: 1440,
    cnt1: 8.402778,
    cnt2: 8.402778,
    cnt3: 8.402778,
    cnt4: 8.402778,
  },
  {
    maesu: 1450,
    cnt1: 8.413793,
    cnt2: 8.413793,
    cnt3: 8.413793,
    cnt4: 8.413793,
  },
  {
    maesu: 1460,
    cnt1: 8.424658,
    cnt2: 8.424658,
    cnt3: 8.424658,
    cnt4: 8.424658,
  },
  {
    maesu: 1470,
    cnt1: 8.435374,
    cnt2: 8.435374,
    cnt3: 8.435374,
    cnt4: 8.435374,
  },
  {
    maesu: 1480,
    cnt1: 8.445946,
    cnt2: 8.445946,
    cnt3: 8.445946,
    cnt4: 8.445946,
  },
  {
    maesu: 1490,
    cnt1: 8.456376,
    cnt2: 8.456376,
    cnt3: 8.456376,
    cnt4: 8.456376,
  },
  {
    maesu: 1500,
    cnt1: 8.4,
    cnt2: 8.4,
    cnt3: 8.4,
    cnt4: 8.4,
  },
  {
    maesu: 1510,
    cnt1: 8.410596,
    cnt2: 8.410596,
    cnt3: 8.410596,
    cnt4: 8.410596,
  },
  {
    maesu: 1520,
    cnt1: 8.421053,
    cnt2: 8.421053,
    cnt3: 8.421053,
    cnt4: 8.421053,
  },
  {
    maesu: 1530,
    cnt1: 8.431373,
    cnt2: 8.431373,
    cnt3: 8.431373,
    cnt4: 8.431373,
  },
  {
    maesu: 1540,
    cnt1: 8.441558,
    cnt2: 8.441558,
    cnt3: 8.441558,
    cnt4: 8.441558,
  },
  {
    maesu: 1550,
    cnt1: 8.451613,
    cnt2: 8.451613,
    cnt3: 8.451613,
    cnt4: 8.451613,
  },
  {
    maesu: 1560,
    cnt1: 8.461538,
    cnt2: 8.461538,
    cnt3: 8.461538,
    cnt4: 8.461538,
  },
  {
    maesu: 1570,
    cnt1: 8.407643,
    cnt2: 8.407643,
    cnt3: 8.407643,
    cnt4: 8.407643,
  },
  {
    maesu: 1580,
    cnt1: 8.417722,
    cnt2: 8.417722,
    cnt3: 8.417722,
    cnt4: 8.417722,
  },
  {
    maesu: 1590,
    cnt1: 8.427673,
    cnt2: 8.427673,
    cnt3: 8.427673,
    cnt4: 8.427673,
  },
  {
    maesu: 1600,
    cnt1: 8.4375,
    cnt2: 8.4375,
    cnt3: 8.4375,
    cnt4: 8.4375,
  },
  {
    maesu: 1610,
    cnt1: 8.447205,
    cnt2: 8.447205,
    cnt3: 8.447205,
    cnt4: 8.447205,
  },
  {
    maesu: 1620,
    cnt1: 8.45679,
    cnt2: 8.45679,
    cnt3: 8.45679,
    cnt4: 8.45679,
  },
  {
    maesu: 1630,
    cnt1: 8.404908,
    cnt2: 8.404908,
    cnt3: 8.404908,
    cnt4: 8.404908,
  },
  {
    maesu: 1640,
    cnt1: 8.414634,
    cnt2: 8.414634,
    cnt3: 8.414634,
    cnt4: 8.414634,
  },
  {
    maesu: 1650,
    cnt1: 8.424242,
    cnt2: 8.424242,
    cnt3: 8.424242,
    cnt4: 8.424242,
  },
  {
    maesu: 1660,
    cnt1: 8.433735,
    cnt2: 8.433735,
    cnt3: 8.433735,
    cnt4: 8.433735,
  },
  {
    maesu: 1670,
    cnt1: 8.443114,
    cnt2: 8.443114,
    cnt3: 8.443114,
    cnt4: 8.443114,
  },
  {
    maesu: 1680,
    cnt1: 8.452381,
    cnt2: 8.452381,
    cnt3: 8.452381,
    cnt4: 8.452381,
  },
  {
    maesu: 1690,
    cnt1: 8.402367,
    cnt2: 8.402367,
    cnt3: 8.402367,
    cnt4: 8.402367,
  },
  {
    maesu: 1700,
    cnt1: 8.411765,
    cnt2: 8.411765,
    cnt3: 8.411765,
    cnt4: 8.411765,
  },
  {
    maesu: 1710,
    cnt1: 8.421053,
    cnt2: 8.421053,
    cnt3: 8.421053,
    cnt4: 8.421053,
  },
  {
    maesu: 1720,
    cnt1: 8.430233,
    cnt2: 8.430233,
    cnt3: 8.430233,
    cnt4: 8.430233,
  },
  {
    maesu: 1730,
    cnt1: 8.439306,
    cnt2: 8.439306,
    cnt3: 8.439306,
    cnt4: 8.439306,
  },
  {
    maesu: 1740,
    cnt1: 8.448276,
    cnt2: 8.448276,
    cnt3: 8.448276,
    cnt4: 8.448276,
  },
  {
    maesu: 1750,
    cnt1: 8.4,
    cnt2: 8.4,
    cnt3: 8.4,
    cnt4: 8.4,
  },
  {
    maesu: 1760,
    cnt1: 8.409091,
    cnt2: 8.409091,
    cnt3: 8.409091,
    cnt4: 8.409091,
  },
  {
    maesu: 1770,
    cnt1: 8.418079,
    cnt2: 8.418079,
    cnt3: 8.418079,
    cnt4: 8.418079,
  },
  {
    maesu: 1780,
    cnt1: 8.426966,
    cnt2: 8.426966,
    cnt3: 8.426966,
    cnt4: 8.426966,
  },
  {
    maesu: 1790,
    cnt1: 8.435754,
    cnt2: 8.435754,
    cnt3: 8.435754,
    cnt4: 8.435754,
  },
  {
    maesu: 1800,
    cnt1: 8.444444,
    cnt2: 8.444444,
    cnt3: 8.444444,
    cnt4: 8.444444,
  },
  {
    maesu: 1810,
    cnt1: 8.453039,
    cnt2: 8.453039,
    cnt3: 8.453039,
    cnt4: 8.453039,
  },
  {
    maesu: 1820,
    cnt1: 8.406593,
    cnt2: 8.406593,
    cnt3: 8.406593,
    cnt4: 8.406593,
  },
  {
    maesu: 1830,
    cnt1: 8.415301,
    cnt2: 8.415301,
    cnt3: 8.415301,
    cnt4: 8.415301,
  },
  {
    maesu: 1840,
    cnt1: 8.423913,
    cnt2: 8.423913,
    cnt3: 8.423913,
    cnt4: 8.423913,
  },
  {
    maesu: 1850,
    cnt1: 8.432432,
    cnt2: 8.432432,
    cnt3: 8.432432,
    cnt4: 8.432432,
  },
  {
    maesu: 1860,
    cnt1: 8.44086,
    cnt2: 8.44086,
    cnt3: 8.44086,
    cnt4: 8.44086,
  },
  {
    maesu: 1870,
    cnt1: 8.449198,
    cnt2: 8.449198,
    cnt3: 8.449198,
    cnt4: 8.449198,
  },
  {
    maesu: 1880,
    cnt1: 8.404255,
    cnt2: 8.404255,
    cnt3: 8.404255,
    cnt4: 8.404255,
  },
  {
    maesu: 1890,
    cnt1: 8.412698,
    cnt2: 8.412698,
    cnt3: 8.412698,
    cnt4: 8.412698,
  },
  {
    maesu: 1900,
    cnt1: 8.421053,
    cnt2: 8.421053,
    cnt3: 8.421053,
    cnt4: 8.421053,
  },
  {
    maesu: 1910,
    cnt1: 8.429319,
    cnt2: 8.429319,
    cnt3: 8.429319,
    cnt4: 8.429319,
  },
  {
    maesu: 1920,
    cnt1: 8.4375,
    cnt2: 8.4375,
    cnt3: 8.4375,
    cnt4: 8.4375,
  },
  {
    maesu: 1930,
    cnt1: 8.445596,
    cnt2: 8.445596,
    cnt3: 8.445596,
    cnt4: 8.445596,
  },
  {
    maesu: 1940,
    cnt1: 8.402062,
    cnt2: 8.402062,
    cnt3: 8.402062,
    cnt4: 8.402062,
  },
  {
    maesu: 1950,
    cnt1: 8.410256,
    cnt2: 8.410256,
    cnt3: 8.410256,
    cnt4: 8.410256,
  },
  {
    maesu: 1960,
    cnt1: 8.418367,
    cnt2: 8.418367,
    cnt3: 8.418367,
    cnt4: 8.418367,
  },
  {
    maesu: 1970,
    cnt1: 8.426396,
    cnt2: 8.426396,
    cnt3: 8.426396,
    cnt4: 8.426396,
  },
  {
    maesu: 1980,
    cnt1: 8.434343,
    cnt2: 8.434343,
    cnt3: 8.434343,
    cnt4: 8.434343,
  },
  {
    maesu: 1990,
    cnt1: 8.442211,
    cnt2: 8.442211,
    cnt3: 8.442211,
    cnt4: 8.442211,
  },
  {
    maesu: 2000,
    cnt1: 8.4,
    cnt2: 8.4,
    cnt3: 8.4,
    cnt4: 8.4,
  },
  {
    maesu: 2010,
    cnt1: 8.40796,
    cnt2: 8.40796,
    cnt3: 8.40796,
    cnt4: 8.40796,
  },
  {
    maesu: 2020,
    cnt1: 8.415842,
    cnt2: 8.415842,
    cnt3: 8.415842,
    cnt4: 8.415842,
  },
  {
    maesu: 2030,
    cnt1: 8.423645,
    cnt2: 8.423645,
    cnt3: 8.423645,
    cnt4: 8.423645,
  },
  {
    maesu: 2040,
    cnt1: 8.431373,
    cnt2: 8.431373,
    cnt3: 8.431373,
    cnt4: 8.431373,
  },
  {
    maesu: 2050,
    cnt1: 8.439024,
    cnt2: 8.439024,
    cnt3: 8.439024,
    cnt4: 8.439024,
  },
  {
    maesu: 2060,
    cnt1: 8.446602,
    cnt2: 8.446602,
    cnt3: 8.446602,
    cnt4: 8.446602,
  },
  {
    maesu: 2070,
    cnt1: 8.405797,
    cnt2: 8.405797,
    cnt3: 8.405797,
    cnt4: 8.405797,
  },
  {
    maesu: 2080,
    cnt1: 8.413462,
    cnt2: 8.413462,
    cnt3: 8.413462,
    cnt4: 8.413462,
  },
  {
    maesu: 2090,
    cnt1: 8.421053,
    cnt2: 8.421053,
    cnt3: 8.421053,
    cnt4: 8.421053,
  },
  {
    maesu: 2100,
    cnt1: 8.428571,
    cnt2: 8.428571,
    cnt3: 8.428571,
    cnt4: 8.428571,
  },
  {
    maesu: 2110,
    cnt1: 8.436019,
    cnt2: 8.436019,
    cnt3: 8.436019,
    cnt4: 8.436019,
  },
  {
    maesu: 2120,
    cnt1: 8.443396,
    cnt2: 8.443396,
    cnt3: 8.443396,
    cnt4: 8.443396,
  },
  {
    maesu: 2130,
    cnt1: 8.403756,
    cnt2: 8.403756,
    cnt3: 8.403756,
    cnt4: 8.403756,
  },
  {
    maesu: 2140,
    cnt1: 8.411215,
    cnt2: 8.411215,
    cnt3: 8.411215,
    cnt4: 8.411215,
  },
  {
    maesu: 2150,
    cnt1: 8.418605,
    cnt2: 8.418605,
    cnt3: 8.418605,
    cnt4: 8.418605,
  },
  {
    maesu: 2160,
    cnt1: 8.425926,
    cnt2: 8.425926,
    cnt3: 8.425926,
    cnt4: 8.425926,
  },
  {
    maesu: 2170,
    cnt1: 8.43318,
    cnt2: 8.43318,
    cnt3: 8.43318,
    cnt4: 8.43318,
  },
  {
    maesu: 2180,
    cnt1: 8.440367,
    cnt2: 8.440367,
    cnt3: 8.440367,
    cnt4: 8.440367,
  },
  {
    maesu: 2190,
    cnt1: 8.401826,
    cnt2: 8.401826,
    cnt3: 8.401826,
    cnt4: 8.401826,
  },
  {
    maesu: 2200,
    cnt1: 8.409091,
    cnt2: 8.409091,
    cnt3: 8.409091,
    cnt4: 8.409091,
  },
  {
    maesu: 2210,
    cnt1: 8.41629,
    cnt2: 8.41629,
    cnt3: 8.41629,
    cnt4: 8.41629,
  },
  {
    maesu: 2220,
    cnt1: 8.423423,
    cnt2: 8.423423,
    cnt3: 8.423423,
    cnt4: 8.423423,
  },
  {
    maesu: 2230,
    cnt1: 8.430493,
    cnt2: 8.430493,
    cnt3: 8.430493,
    cnt4: 8.430493,
  },
  {
    maesu: 2240,
    cnt1: 8.4375,
    cnt2: 8.4375,
    cnt3: 8.4375,
    cnt4: 8.4375,
  },
  {
    maesu: 2250,
    cnt1: 8.4,
    cnt2: 8.4,
    cnt3: 8.4,
    cnt4: 8.4,
  },
  {
    maesu: 2260,
    cnt1: 8.40708,
    cnt2: 8.40708,
    cnt3: 8.40708,
    cnt4: 8.40708,
  },
  {
    maesu: 2270,
    cnt1: 8.414097,
    cnt2: 8.414097,
    cnt3: 8.414097,
    cnt4: 8.414097,
  },
  {
    maesu: 2280,
    cnt1: 8.421053,
    cnt2: 8.421053,
    cnt3: 8.421053,
    cnt4: 8.421053,
  },
  {
    maesu: 2290,
    cnt1: 8.427948,
    cnt2: 8.427948,
    cnt3: 8.427948,
    cnt4: 8.427948,
  },
  {
    maesu: 2300,
    cnt1: 8.434783,
    cnt2: 8.434783,
    cnt3: 8.434783,
    cnt4: 8.434783,
  },
  {
    maesu: 2310,
    cnt1: 8.441558,
    cnt2: 8.441558,
    cnt3: 8.441558,
    cnt4: 8.441558,
  },
  {
    maesu: 2320,
    cnt1: 8.405172,
    cnt2: 8.405172,
    cnt3: 8.405172,
    cnt4: 8.405172,
  },
  {
    maesu: 2330,
    cnt1: 8.412017,
    cnt2: 8.412017,
    cnt3: 8.412017,
    cnt4: 8.412017,
  },
  {
    maesu: 2340,
    cnt1: 8.418803,
    cnt2: 8.418803,
    cnt3: 8.418803,
    cnt4: 8.418803,
  },
  {
    maesu: 2350,
    cnt1: 8.425532,
    cnt2: 8.425532,
    cnt3: 8.425532,
    cnt4: 8.425532,
  },
  {
    maesu: 2360,
    cnt1: 8.432203,
    cnt2: 8.432203,
    cnt3: 8.432203,
    cnt4: 8.432203,
  },
  {
    maesu: 2370,
    cnt1: 8.438819,
    cnt2: 8.438819,
    cnt3: 8.438819,
    cnt4: 8.438819,
  },
  {
    maesu: 2380,
    cnt1: 8.403361,
    cnt2: 8.403361,
    cnt3: 8.403361,
    cnt4: 8.403361,
  },
  {
    maesu: 2390,
    cnt1: 8.410042,
    cnt2: 8.410042,
    cnt3: 8.410042,
    cnt4: 8.410042,
  },
  {
    maesu: 2400,
    cnt1: 8.416667,
    cnt2: 8.416667,
    cnt3: 8.416667,
    cnt4: 8.416667,
  },
  {
    maesu: 2410,
    cnt1: 8.423237,
    cnt2: 8.423237,
    cnt3: 8.423237,
    cnt4: 8.423237,
  },
  {
    maesu: 2420,
    cnt1: 8.429752,
    cnt2: 8.429752,
    cnt3: 8.429752,
    cnt4: 8.429752,
  },
  {
    maesu: 2430,
    cnt1: 8.436214,
    cnt2: 8.436214,
    cnt3: 8.436214,
    cnt4: 8.436214,
  },
  {
    maesu: 2440,
    cnt1: 8.401639,
    cnt2: 8.401639,
    cnt3: 8.401639,
    cnt4: 8.401639,
  },
  {
    maesu: 2450,
    cnt1: 8.408163,
    cnt2: 8.408163,
    cnt3: 8.408163,
    cnt4: 8.408163,
  },
  {
    maesu: 2460,
    cnt1: 8.414634,
    cnt2: 8.414634,
    cnt3: 8.414634,
    cnt4: 8.414634,
  },
  {
    maesu: 2470,
    cnt1: 8.421053,
    cnt2: 8.421053,
    cnt3: 8.421053,
    cnt4: 8.421053,
  },
  {
    maesu: 2480,
    cnt1: 8.427419,
    cnt2: 8.427419,
    cnt3: 8.427419,
    cnt4: 8.427419,
  },
  {
    maesu: 2490,
    cnt1: 8.433735,
    cnt2: 8.433735,
    cnt3: 8.433735,
    cnt4: 8.433735,
  },
  {
    maesu: 2500,
    cnt1: 8.4,
    cnt2: 8.4,
    cnt3: 8.4,
    cnt4: 8.4,
  },
  {
    maesu: 2510,
    cnt1: 8.406375,
    cnt2: 8.406375,
    cnt3: 8.406375,
    cnt4: 8.406375,
  },
  {
    maesu: 2520,
    cnt1: 8.412698,
    cnt2: 8.412698,
    cnt3: 8.412698,
    cnt4: 8.412698,
  },
  {
    maesu: 2530,
    cnt1: 8.418972,
    cnt2: 8.418972,
    cnt3: 8.418972,
    cnt4: 8.418972,
  },
  {
    maesu: 2540,
    cnt1: 8.425197,
    cnt2: 8.425197,
    cnt3: 8.425197,
    cnt4: 8.425197,
  },
  {
    maesu: 2550,
    cnt1: 8.431373,
    cnt2: 8.431373,
    cnt3: 8.431373,
    cnt4: 8.431373,
  },
  {
    maesu: 2560,
    cnt1: 8.4375,
    cnt2: 8.4375,
    cnt3: 8.4375,
    cnt4: 8.4375,
  },
  {
    maesu: 2570,
    cnt1: 8.404669,
    cnt2: 8.404669,
    cnt3: 8.404669,
    cnt4: 8.404669,
  },
  {
    maesu: 2580,
    cnt1: 8.410853,
    cnt2: 8.410853,
    cnt3: 8.410853,
    cnt4: 8.410853,
  },
  {
    maesu: 2590,
    cnt1: 8.416988,
    cnt2: 8.416988,
    cnt3: 8.416988,
    cnt4: 8.416988,
  },
  {
    maesu: 2600,
    cnt1: 8.423077,
    cnt2: 8.423077,
    cnt3: 8.423077,
    cnt4: 8.423077,
  },
  {
    maesu: 2610,
    cnt1: 8.429119,
    cnt2: 8.429119,
    cnt3: 8.429119,
    cnt4: 8.429119,
  },
  {
    maesu: 2620,
    cnt1: 8.435115,
    cnt2: 8.435115,
    cnt3: 8.435115,
    cnt4: 8.435115,
  },
  {
    maesu: 2630,
    cnt1: 8.403042,
    cnt2: 8.403042,
    cnt3: 8.403042,
    cnt4: 8.403042,
  },
  {
    maesu: 2640,
    cnt1: 8.409091,
    cnt2: 8.409091,
    cnt3: 8.409091,
    cnt4: 8.409091,
  },
  {
    maesu: 2650,
    cnt1: 8.415094,
    cnt2: 8.415094,
    cnt3: 8.415094,
    cnt4: 8.415094,
  },
  {
    maesu: 2660,
    cnt1: 8.421053,
    cnt2: 8.421053,
    cnt3: 8.421053,
    cnt4: 8.421053,
  },
  {
    maesu: 2670,
    cnt1: 8.426966,
    cnt2: 8.426966,
    cnt3: 8.426966,
    cnt4: 8.426966,
  },
  {
    maesu: 2680,
    cnt1: 8.432836,
    cnt2: 8.432836,
    cnt3: 8.432836,
    cnt4: 8.432836,
  },
  {
    maesu: 2690,
    cnt1: 8.401487,
    cnt2: 8.401487,
    cnt3: 8.401487,
    cnt4: 8.401487,
  },
  {
    maesu: 2700,
    cnt1: 8.407407,
    cnt2: 8.407407,
    cnt3: 8.407407,
    cnt4: 8.407407,
  },
  {
    maesu: 2710,
    cnt1: 8.413284,
    cnt2: 8.413284,
    cnt3: 8.413284,
    cnt4: 8.413284,
  },
  {
    maesu: 2720,
    cnt1: 8.419118,
    cnt2: 8.419118,
    cnt3: 8.419118,
    cnt4: 8.419118,
  },
  {
    maesu: 2730,
    cnt1: 8.424908,
    cnt2: 8.424908,
    cnt3: 8.424908,
    cnt4: 8.424908,
  },
  {
    maesu: 2740,
    cnt1: 8.430657,
    cnt2: 8.430657,
    cnt3: 8.430657,
    cnt4: 8.430657,
  },
  {
    maesu: 2750,
    cnt1: 8.4,
    cnt2: 8.4,
    cnt3: 8.4,
    cnt4: 8.4,
  },
  {
    maesu: 2760,
    cnt1: 8.405797,
    cnt2: 8.405797,
    cnt3: 8.405797,
    cnt4: 8.405797,
  },
  {
    maesu: 2770,
    cnt1: 8.411552,
    cnt2: 8.411552,
    cnt3: 8.411552,
    cnt4: 8.411552,
  },
  {
    maesu: 2780,
    cnt1: 8.417266,
    cnt2: 8.417266,
    cnt3: 8.417266,
    cnt4: 8.417266,
  },
  {
    maesu: 2790,
    cnt1: 8.422939,
    cnt2: 8.422939,
    cnt3: 8.422939,
    cnt4: 8.422939,
  },
  {
    maesu: 2800,
    cnt1: 8.428571,
    cnt2: 8.428571,
    cnt3: 8.428571,
    cnt4: 8.428571,
  },
  {
    maesu: 2810,
    cnt1: 8.434164,
    cnt2: 8.434164,
    cnt3: 8.434164,
    cnt4: 8.434164,
  },
  {
    maesu: 2820,
    cnt1: 8.404255,
    cnt2: 8.404255,
    cnt3: 8.404255,
    cnt4: 8.404255,
  },
  {
    maesu: 2830,
    cnt1: 8.409894,
    cnt2: 8.409894,
    cnt3: 8.409894,
    cnt4: 8.409894,
  },
  {
    maesu: 2840,
    cnt1: 8.415493,
    cnt2: 8.415493,
    cnt3: 8.415493,
    cnt4: 8.415493,
  },
  {
    maesu: 2850,
    cnt1: 8.421053,
    cnt2: 8.421053,
    cnt3: 8.421053,
    cnt4: 8.421053,
  },
  {
    maesu: 2860,
    cnt1: 8.426573,
    cnt2: 8.426573,
    cnt3: 8.426573,
    cnt4: 8.426573,
  },
  {
    maesu: 2870,
    cnt1: 8.432056,
    cnt2: 8.432056,
    cnt3: 8.432056,
    cnt4: 8.432056,
  },
  {
    maesu: 2880,
    cnt1: 8.402778,
    cnt2: 8.402778,
    cnt3: 8.402778,
    cnt4: 8.402778,
  },
  {
    maesu: 2890,
    cnt1: 8.408304,
    cnt2: 8.408304,
    cnt3: 8.408304,
    cnt4: 8.408304,
  },
  {
    maesu: 2900,
    cnt1: 8.413793,
    cnt2: 8.413793,
    cnt3: 8.413793,
    cnt4: 8.413793,
  },
  {
    maesu: 2910,
    cnt1: 8.419244,
    cnt2: 8.419244,
    cnt3: 8.419244,
    cnt4: 8.419244,
  },
  {
    maesu: 2920,
    cnt1: 8.424658,
    cnt2: 8.424658,
    cnt3: 8.424658,
    cnt4: 8.424658,
  },
  {
    maesu: 2930,
    cnt1: 8.430034,
    cnt2: 8.430034,
    cnt3: 8.430034,
    cnt4: 8.430034,
  },
  {
    maesu: 2940,
    cnt1: 8.401361,
    cnt2: 8.401361,
    cnt3: 8.401361,
    cnt4: 8.401361,
  },
  {
    maesu: 2950,
    cnt1: 8.40678,
    cnt2: 8.40678,
    cnt3: 8.40678,
    cnt4: 8.40678,
  },
  {
    maesu: 2960,
    cnt1: 8.412162,
    cnt2: 8.412162,
    cnt3: 8.412162,
    cnt4: 8.412162,
  },
  {
    maesu: 2970,
    cnt1: 8.417508,
    cnt2: 8.417508,
    cnt3: 8.417508,
    cnt4: 8.417508,
  },
  {
    maesu: 2980,
    cnt1: 8.422819,
    cnt2: 8.422819,
    cnt3: 8.422819,
    cnt4: 8.422819,
  },
  {
    maesu: 2990,
    cnt1: 8.428094,
    cnt2: 8.428094,
    cnt3: 8.428094,
    cnt4: 8.428094,
  },
  {
    maesu: 3000,
    cnt1: 8.4,
    cnt2: 8.4,
    cnt3: 8.4,
    cnt4: 8.4,
  },
  {
    maesu: 3010,
    cnt1: 8.405316,
    cnt2: 8.405316,
    cnt3: 8.405316,
    cnt4: 8.405316,
  },
  {
    maesu: 3020,
    cnt1: 8.410596,
    cnt2: 8.410596,
    cnt3: 8.410596,
    cnt4: 8.410596,
  },
  {
    maesu: 3030,
    cnt1: 8.415842,
    cnt2: 8.415842,
    cnt3: 8.415842,
    cnt4: 8.415842,
  },
  {
    maesu: 3040,
    cnt1: 8.421053,
    cnt2: 8.421053,
    cnt3: 8.421053,
    cnt4: 8.421053,
  },
  {
    maesu: 3050,
    cnt1: 8.42623,
    cnt2: 8.42623,
    cnt3: 8.42623,
    cnt4: 8.42623,
  },
  {
    maesu: 3060,
    cnt1: 8.431373,
    cnt2: 8.431373,
    cnt3: 8.431373,
    cnt4: 8.431373,
  },
  {
    maesu: 3070,
    cnt1: 8.403909,
    cnt2: 8.403909,
    cnt3: 8.403909,
    cnt4: 8.403909,
  },
  {
    maesu: 3080,
    cnt1: 8.409091,
    cnt2: 8.409091,
    cnt3: 8.409091,
    cnt4: 8.409091,
  },
  {
    maesu: 3090,
    cnt1: 8.414239,
    cnt2: 8.414239,
    cnt3: 8.414239,
    cnt4: 8.414239,
  },
  {
    maesu: 3100,
    cnt1: 8.419355,
    cnt2: 8.419355,
    cnt3: 8.419355,
    cnt4: 8.419355,
  },
  {
    maesu: 3110,
    cnt1: 8.424437,
    cnt2: 8.424437,
    cnt3: 8.424437,
    cnt4: 8.424437,
  },
  {
    maesu: 3120,
    cnt1: 8.429487,
    cnt2: 8.429487,
    cnt3: 8.429487,
    cnt4: 8.429487,
  },
  {
    maesu: 3130,
    cnt1: 8.402556,
    cnt2: 8.402556,
    cnt3: 8.402556,
    cnt4: 8.402556,
  },
  {
    maesu: 3140,
    cnt1: 8.407643,
    cnt2: 8.407643,
    cnt3: 8.407643,
    cnt4: 8.407643,
  },
  {
    maesu: 3150,
    cnt1: 8.412698,
    cnt2: 8.412698,
    cnt3: 8.412698,
    cnt4: 8.412698,
  },
  {
    maesu: 3160,
    cnt1: 8.417722,
    cnt2: 8.417722,
    cnt3: 8.417722,
    cnt4: 8.417722,
  },
  {
    maesu: 3170,
    cnt1: 8.422713,
    cnt2: 8.422713,
    cnt3: 8.422713,
    cnt4: 8.422713,
  },
  {
    maesu: 3180,
    cnt1: 8.427673,
    cnt2: 8.427673,
    cnt3: 8.427673,
    cnt4: 8.427673,
  },
  {
    maesu: 3190,
    cnt1: 8.401254,
    cnt2: 8.401254,
    cnt3: 8.401254,
    cnt4: 8.401254,
  },
  {
    maesu: 3200,
    cnt1: 8.40625,
    cnt2: 8.40625,
    cnt3: 8.40625,
    cnt4: 8.40625,
  },
  {
    maesu: 3210,
    cnt1: 8.411215,
    cnt2: 8.411215,
    cnt3: 8.411215,
    cnt4: 8.411215,
  },
  {
    maesu: 3220,
    cnt1: 8.416149,
    cnt2: 8.416149,
    cnt3: 8.416149,
    cnt4: 8.416149,
  },
  {
    maesu: 3230,
    cnt1: 8.421053,
    cnt2: 8.421053,
    cnt3: 8.421053,
    cnt4: 8.421053,
  },
  {
    maesu: 3240,
    cnt1: 8.425926,
    cnt2: 8.425926,
    cnt3: 8.425926,
    cnt4: 8.425926,
  },
  {
    maesu: 3250,
    cnt1: 8.4,
    cnt2: 8.4,
    cnt3: 8.4,
    cnt4: 8.4,
  },
  {
    maesu: 3260,
    cnt1: 8.404908,
    cnt2: 8.404908,
    cnt3: 8.404908,
    cnt4: 8.404908,
  },
  {
    maesu: 3270,
    cnt1: 8.409786,
    cnt2: 8.409786,
    cnt3: 8.409786,
    cnt4: 8.409786,
  },
  {
    maesu: 3280,
    cnt1: 8.414634,
    cnt2: 8.414634,
    cnt3: 8.414634,
    cnt4: 8.414634,
  },
  {
    maesu: 3290,
    cnt1: 8.419453,
    cnt2: 8.419453,
    cnt3: 8.419453,
    cnt4: 8.419453,
  },
  {
    maesu: 3300,
    cnt1: 8.424242,
    cnt2: 8.424242,
    cnt3: 8.424242,
    cnt4: 8.424242,
  },
  {
    maesu: 3310,
    cnt1: 8.429003,
    cnt2: 8.429003,
    cnt3: 8.429003,
    cnt4: 8.429003,
  },
  {
    maesu: 3320,
    cnt1: 8.403614,
    cnt2: 8.403614,
    cnt3: 8.403614,
    cnt4: 8.403614,
  },
  {
    maesu: 3330,
    cnt1: 8.408408,
    cnt2: 8.408408,
    cnt3: 8.408408,
    cnt4: 8.408408,
  },
  {
    maesu: 3340,
    cnt1: 8.413174,
    cnt2: 8.413174,
    cnt3: 8.413174,
    cnt4: 8.413174,
  },
  {
    maesu: 3350,
    cnt1: 8.41791,
    cnt2: 8.41791,
    cnt3: 8.41791,
    cnt4: 8.41791,
  },
  {
    maesu: 3360,
    cnt1: 8.422619,
    cnt2: 8.422619,
    cnt3: 8.422619,
    cnt4: 8.422619,
  },
  {
    maesu: 3370,
    cnt1: 8.4273,
    cnt2: 8.4273,
    cnt3: 8.4273,
    cnt4: 8.4273,
  },
  {
    maesu: 3380,
    cnt1: 8.402367,
    cnt2: 8.402367,
    cnt3: 8.402367,
    cnt4: 8.402367,
  },
  {
    maesu: 3390,
    cnt1: 8.40708,
    cnt2: 8.40708,
    cnt3: 8.40708,
    cnt4: 8.40708,
  },
  {
    maesu: 3400,
    cnt1: 8.411765,
    cnt2: 8.411765,
    cnt3: 8.411765,
    cnt4: 8.411765,
  },
  {
    maesu: 3410,
    cnt1: 8.416422,
    cnt2: 8.416422,
    cnt3: 8.416422,
    cnt4: 8.416422,
  },
  {
    maesu: 3420,
    cnt1: 8.421053,
    cnt2: 8.421053,
    cnt3: 8.421053,
    cnt4: 8.421053,
  },
  {
    maesu: 3430,
    cnt1: 8.425656,
    cnt2: 8.425656,
    cnt3: 8.425656,
    cnt4: 8.425656,
  },
  {
    maesu: 3440,
    cnt1: 8.401163,
    cnt2: 8.401163,
    cnt3: 8.401163,
    cnt4: 8.401163,
  },
  {
    maesu: 3450,
    cnt1: 8.405797,
    cnt2: 8.405797,
    cnt3: 8.405797,
    cnt4: 8.405797,
  },
  {
    maesu: 3460,
    cnt1: 8.410405,
    cnt2: 8.410405,
    cnt3: 8.410405,
    cnt4: 8.410405,
  },
  {
    maesu: 3470,
    cnt1: 8.414986,
    cnt2: 8.414986,
    cnt3: 8.414986,
    cnt4: 8.414986,
  },
  {
    maesu: 3480,
    cnt1: 8.41954,
    cnt2: 8.41954,
    cnt3: 8.41954,
    cnt4: 8.41954,
  },
  {
    maesu: 3490,
    cnt1: 8.424069,
    cnt2: 8.424069,
    cnt3: 8.424069,
    cnt4: 8.424069,
  },
  {
    maesu: 3500,
    cnt1: 8.4,
    cnt2: 8.4,
    cnt3: 8.4,
    cnt4: 8.4,
  },
  {
    maesu: 3510,
    cnt1: 8.404558,
    cnt2: 8.404558,
    cnt3: 8.404558,
    cnt4: 8.404558,
  },
  {
    maesu: 3520,
    cnt1: 8.409091,
    cnt2: 8.409091,
    cnt3: 8.409091,
    cnt4: 8.409091,
  },
  {
    maesu: 3530,
    cnt1: 8.413598,
    cnt2: 8.413598,
    cnt3: 8.413598,
    cnt4: 8.413598,
  },
  {
    maesu: 3540,
    cnt1: 8.418079,
    cnt2: 8.418079,
    cnt3: 8.418079,
    cnt4: 8.418079,
  },
  {
    maesu: 3550,
    cnt1: 8.422535,
    cnt2: 8.422535,
    cnt3: 8.422535,
    cnt4: 8.422535,
  },
  {
    maesu: 3560,
    cnt1: 8.426966,
    cnt2: 8.426966,
    cnt3: 8.426966,
    cnt4: 8.426966,
  },
  {
    maesu: 3570,
    cnt1: 8.403361,
    cnt2: 8.403361,
    cnt3: 8.403361,
    cnt4: 8.403361,
  },
  {
    maesu: 3580,
    cnt1: 8.407821,
    cnt2: 8.407821,
    cnt3: 8.407821,
    cnt4: 8.407821,
  },
  {
    maesu: 3590,
    cnt1: 8.412256,
    cnt2: 8.412256,
    cnt3: 8.412256,
    cnt4: 8.412256,
  },
  {
    maesu: 3600,
    cnt1: 8.416667,
    cnt2: 8.416667,
    cnt3: 8.416667,
    cnt4: 8.416667,
  },
  {
    maesu: 3610,
    cnt1: 8.421053,
    cnt2: 8.421053,
    cnt3: 8.421053,
    cnt4: 8.421053,
  },
  {
    maesu: 3620,
    cnt1: 8.425414,
    cnt2: 8.425414,
    cnt3: 8.425414,
    cnt4: 8.425414,
  },
  {
    maesu: 3630,
    cnt1: 8.402204,
    cnt2: 8.402204,
    cnt3: 8.402204,
    cnt4: 8.402204,
  },
  {
    maesu: 3640,
    cnt1: 8.406593,
    cnt2: 8.406593,
    cnt3: 8.406593,
    cnt4: 8.406593,
  },
  {
    maesu: 3650,
    cnt1: 8.410959,
    cnt2: 8.410959,
    cnt3: 8.410959,
    cnt4: 8.410959,
  },
  {
    maesu: 3660,
    cnt1: 8.415301,
    cnt2: 8.415301,
    cnt3: 8.415301,
    cnt4: 8.415301,
  },
  {
    maesu: 3670,
    cnt1: 8.419619,
    cnt2: 8.419619,
    cnt3: 8.419619,
    cnt4: 8.419619,
  },
  {
    maesu: 3680,
    cnt1: 8.423913,
    cnt2: 8.423913,
    cnt3: 8.423913,
    cnt4: 8.423913,
  },
  {
    maesu: 3690,
    cnt1: 8.401084,
    cnt2: 8.401084,
    cnt3: 8.401084,
    cnt4: 8.401084,
  },
  {
    maesu: 3700,
    cnt1: 8.405405,
    cnt2: 8.405405,
    cnt3: 8.405405,
    cnt4: 8.405405,
  },
  {
    maesu: 3710,
    cnt1: 8.409704,
    cnt2: 8.409704,
    cnt3: 8.409704,
    cnt4: 8.409704,
  },
  {
    maesu: 3720,
    cnt1: 8.413978,
    cnt2: 8.413978,
    cnt3: 8.413978,
    cnt4: 8.413978,
  },
  {
    maesu: 3730,
    cnt1: 8.418231,
    cnt2: 8.418231,
    cnt3: 8.418231,
    cnt4: 8.418231,
  },
  {
    maesu: 3740,
    cnt1: 8.42246,
    cnt2: 8.42246,
    cnt3: 8.42246,
    cnt4: 8.42246,
  },
  {
    maesu: 3750,
    cnt1: 8.4,
    cnt2: 8.4,
    cnt3: 8.4,
    cnt4: 8.4,
  },
  {
    maesu: 3760,
    cnt1: 8.404255,
    cnt2: 8.404255,
    cnt3: 8.404255,
    cnt4: 8.404255,
  },
  {
    maesu: 3770,
    cnt1: 8.408488,
    cnt2: 8.408488,
    cnt3: 8.408488,
    cnt4: 8.408488,
  },
  {
    maesu: 3780,
    cnt1: 8.412698,
    cnt2: 8.412698,
    cnt3: 8.412698,
    cnt4: 8.412698,
  },
  {
    maesu: 3790,
    cnt1: 8.416887,
    cnt2: 8.416887,
    cnt3: 8.416887,
    cnt4: 8.416887,
  },
  {
    maesu: 3800,
    cnt1: 8.421053,
    cnt2: 8.421053,
    cnt3: 8.421053,
    cnt4: 8.421053,
  },
  {
    maesu: 3810,
    cnt1: 8.425197,
    cnt2: 8.425197,
    cnt3: 8.425197,
    cnt4: 8.425197,
  },
  {
    maesu: 3820,
    cnt1: 8.403141,
    cnt2: 8.403141,
    cnt3: 8.403141,
    cnt4: 8.403141,
  },
  {
    maesu: 3830,
    cnt1: 8.407311,
    cnt2: 8.407311,
    cnt3: 8.407311,
    cnt4: 8.407311,
  },
  {
    maesu: 3840,
    cnt1: 8.411458,
    cnt2: 8.411458,
    cnt3: 8.411458,
    cnt4: 8.411458,
  },
  {
    maesu: 3850,
    cnt1: 8.415584,
    cnt2: 8.415584,
    cnt3: 8.415584,
    cnt4: 8.415584,
  },
  {
    maesu: 3860,
    cnt1: 8.419689,
    cnt2: 8.419689,
    cnt3: 8.419689,
    cnt4: 8.419689,
  },
  {
    maesu: 3870,
    cnt1: 8.423773,
    cnt2: 8.423773,
    cnt3: 8.423773,
    cnt4: 8.423773,
  },
  {
    maesu: 3880,
    cnt1: 8.402062,
    cnt2: 8.402062,
    cnt3: 8.402062,
    cnt4: 8.402062,
  },
  {
    maesu: 3890,
    cnt1: 8.40617,
    cnt2: 8.40617,
    cnt3: 8.40617,
    cnt4: 8.40617,
  },
  {
    maesu: 3900,
    cnt1: 8.410256,
    cnt2: 8.410256,
    cnt3: 8.410256,
    cnt4: 8.410256,
  },
  {
    maesu: 3910,
    cnt1: 8.414322,
    cnt2: 8.414322,
    cnt3: 8.414322,
    cnt4: 8.414322,
  },
  {
    maesu: 3920,
    cnt1: 8.418367,
    cnt2: 8.418367,
    cnt3: 8.418367,
    cnt4: 8.418367,
  },
  {
    maesu: 3930,
    cnt1: 8.422392,
    cnt2: 8.422392,
    cnt3: 8.422392,
    cnt4: 8.422392,
  },
  {
    maesu: 3940,
    cnt1: 8.401015,
    cnt2: 8.401015,
    cnt3: 8.401015,
    cnt4: 8.401015,
  },
  {
    maesu: 3950,
    cnt1: 8.405063,
    cnt2: 8.405063,
    cnt3: 8.405063,
    cnt4: 8.405063,
  },
  {
    maesu: 3960,
    cnt1: 8.409091,
    cnt2: 8.409091,
    cnt3: 8.409091,
    cnt4: 8.409091,
  },
  {
    maesu: 3970,
    cnt1: 8.413098,
    cnt2: 8.413098,
    cnt3: 8.413098,
    cnt4: 8.413098,
  },
  {
    maesu: 3980,
    cnt1: 8.417085,
    cnt2: 8.417085,
    cnt3: 8.417085,
    cnt4: 8.417085,
  },
  {
    maesu: 3990,
    cnt1: 8.421053,
    cnt2: 8.421053,
    cnt3: 8.421053,
    cnt4: 8.421053,
  },
  {
    maesu: 4000,
    cnt1: 8.4,
    cnt2: 8.4,
    cnt3: 8.4,
    cnt4: 8.4,
  },
  {
    maesu: 4010,
    cnt1: 8.40399,
    cnt2: 8.40399,
    cnt3: 8.40399,
    cnt4: 8.40399,
  },
  {
    maesu: 4020,
    cnt1: 8.40796,
    cnt2: 8.40796,
    cnt3: 8.40796,
    cnt4: 8.40796,
  },
  {
    maesu: 4030,
    cnt1: 8.411911,
    cnt2: 8.411911,
    cnt3: 8.411911,
    cnt4: 8.411911,
  },
  {
    maesu: 4040,
    cnt1: 8.415842,
    cnt2: 8.415842,
    cnt3: 8.415842,
    cnt4: 8.415842,
  },
  {
    maesu: 4050,
    cnt1: 8.419753,
    cnt2: 8.419753,
    cnt3: 8.419753,
    cnt4: 8.419753,
  },
  {
    maesu: 4060,
    cnt1: 8.423645,
    cnt2: 8.423645,
    cnt3: 8.423645,
    cnt4: 8.423645,
  },
  {
    maesu: 4070,
    cnt1: 8.402948,
    cnt2: 8.402948,
    cnt3: 8.402948,
    cnt4: 8.402948,
  },
  {
    maesu: 4080,
    cnt1: 8.406863,
    cnt2: 8.406863,
    cnt3: 8.406863,
    cnt4: 8.406863,
  },
  {
    maesu: 4090,
    cnt1: 8.410758,
    cnt2: 8.410758,
    cnt3: 8.410758,
    cnt4: 8.410758,
  },
  {
    maesu: 4100,
    cnt1: 8.414634,
    cnt2: 8.414634,
    cnt3: 8.414634,
    cnt4: 8.414634,
  },
  {
    maesu: 4110,
    cnt1: 8.418491,
    cnt2: 8.418491,
    cnt3: 8.418491,
    cnt4: 8.418491,
  },
  {
    maesu: 4120,
    cnt1: 8.42233,
    cnt2: 8.42233,
    cnt3: 8.42233,
    cnt4: 8.42233,
  },
  {
    maesu: 4130,
    cnt1: 8.401937,
    cnt2: 8.401937,
    cnt3: 8.401937,
    cnt4: 8.401937,
  },
  {
    maesu: 4140,
    cnt1: 8.405797,
    cnt2: 8.405797,
    cnt3: 8.405797,
    cnt4: 8.405797,
  },
  {
    maesu: 4150,
    cnt1: 8.409639,
    cnt2: 8.409639,
    cnt3: 8.409639,
    cnt4: 8.409639,
  },
  {
    maesu: 4160,
    cnt1: 8.413462,
    cnt2: 8.413462,
    cnt3: 8.413462,
    cnt4: 8.413462,
  },
  {
    maesu: 4170,
    cnt1: 8.417266,
    cnt2: 8.417266,
    cnt3: 8.417266,
    cnt4: 8.417266,
  },
  {
    maesu: 4180,
    cnt1: 8.421053,
    cnt2: 8.421053,
    cnt3: 8.421053,
    cnt4: 8.421053,
  },
  {
    maesu: 4190,
    cnt1: 8.400955,
    cnt2: 8.400955,
    cnt3: 8.400955,
    cnt4: 8.400955,
  },
  {
    maesu: 4200,
    cnt1: 8.404762,
    cnt2: 8.404762,
    cnt3: 8.404762,
    cnt4: 8.404762,
  },
  {
    maesu: 4210,
    cnt1: 8.408551,
    cnt2: 8.408551,
    cnt3: 8.408551,
    cnt4: 8.408551,
  },
  {
    maesu: 4220,
    cnt1: 8.412322,
    cnt2: 8.412322,
    cnt3: 8.412322,
    cnt4: 8.412322,
  },
  {
    maesu: 4230,
    cnt1: 8.416076,
    cnt2: 8.416076,
    cnt3: 8.416076,
    cnt4: 8.416076,
  },
  {
    maesu: 4240,
    cnt1: 8.419811,
    cnt2: 8.419811,
    cnt3: 8.419811,
    cnt4: 8.419811,
  },
  {
    maesu: 4250,
    cnt1: 8.4,
    cnt2: 8.4,
    cnt3: 8.4,
    cnt4: 8.4,
  },
  {
    maesu: 4260,
    cnt1: 8.403756,
    cnt2: 8.403756,
    cnt3: 8.403756,
    cnt4: 8.403756,
  },
  {
    maesu: 4270,
    cnt1: 8.407494,
    cnt2: 8.407494,
    cnt3: 8.407494,
    cnt4: 8.407494,
  },
  {
    maesu: 4280,
    cnt1: 8.411215,
    cnt2: 8.411215,
    cnt3: 8.411215,
    cnt4: 8.411215,
  },
  {
    maesu: 4290,
    cnt1: 8.414918,
    cnt2: 8.414918,
    cnt3: 8.414918,
    cnt4: 8.414918,
  },
  {
    maesu: 4300,
    cnt1: 8.418605,
    cnt2: 8.418605,
    cnt3: 8.418605,
    cnt4: 8.418605,
  },
  {
    maesu: 4310,
    cnt1: 8.422274,
    cnt2: 8.422274,
    cnt3: 8.422274,
    cnt4: 8.422274,
  },
  {
    maesu: 4320,
    cnt1: 8.402778,
    cnt2: 8.402778,
    cnt3: 8.402778,
    cnt4: 8.402778,
  },
  {
    maesu: 4330,
    cnt1: 8.406467,
    cnt2: 8.406467,
    cnt3: 8.406467,
    cnt4: 8.406467,
  },
  {
    maesu: 4340,
    cnt1: 8.410138,
    cnt2: 8.410138,
    cnt3: 8.410138,
    cnt4: 8.410138,
  },
  {
    maesu: 4350,
    cnt1: 8.413793,
    cnt2: 8.413793,
    cnt3: 8.413793,
    cnt4: 8.413793,
  },
  {
    maesu: 4360,
    cnt1: 8.417431,
    cnt2: 8.417431,
    cnt3: 8.417431,
    cnt4: 8.417431,
  },
  {
    maesu: 4370,
    cnt1: 8.421053,
    cnt2: 8.421053,
    cnt3: 8.421053,
    cnt4: 8.421053,
  },
  {
    maesu: 4380,
    cnt1: 8.401826,
    cnt2: 8.401826,
    cnt3: 8.401826,
    cnt4: 8.401826,
  },
  {
    maesu: 4390,
    cnt1: 8.405467,
    cnt2: 8.405467,
    cnt3: 8.405467,
    cnt4: 8.405467,
  },
  {
    maesu: 4400,
    cnt1: 8.409091,
    cnt2: 8.409091,
    cnt3: 8.409091,
    cnt4: 8.409091,
  },
  {
    maesu: 4410,
    cnt1: 8.412698,
    cnt2: 8.412698,
    cnt3: 8.412698,
    cnt4: 8.412698,
  },
  {
    maesu: 4420,
    cnt1: 8.41629,
    cnt2: 8.41629,
    cnt3: 8.41629,
    cnt4: 8.41629,
  },
  {
    maesu: 4430,
    cnt1: 8.419865,
    cnt2: 8.419865,
    cnt3: 8.419865,
    cnt4: 8.419865,
  },
  {
    maesu: 4440,
    cnt1: 8.400901,
    cnt2: 8.400901,
    cnt3: 8.400901,
    cnt4: 8.400901,
  },
  {
    maesu: 4450,
    cnt1: 8.404494,
    cnt2: 8.404494,
    cnt3: 8.404494,
    cnt4: 8.404494,
  },
  {
    maesu: 4460,
    cnt1: 8.408072,
    cnt2: 8.408072,
    cnt3: 8.408072,
    cnt4: 8.408072,
  },
  {
    maesu: 4470,
    cnt1: 8.411633,
    cnt2: 8.411633,
    cnt3: 8.411633,
    cnt4: 8.411633,
  },
  {
    maesu: 4480,
    cnt1: 8.415179,
    cnt2: 8.415179,
    cnt3: 8.415179,
    cnt4: 8.415179,
  },
  {
    maesu: 4490,
    cnt1: 8.418708,
    cnt2: 8.418708,
    cnt3: 8.418708,
    cnt4: 8.418708,
  },
  {
    maesu: 4500,
    cnt1: 8.4,
    cnt2: 8.4,
    cnt3: 8.4,
    cnt4: 8.4,
  },
  {
    maesu: 4510,
    cnt1: 8.403548,
    cnt2: 8.403548,
    cnt3: 8.403548,
    cnt4: 8.403548,
  },
  {
    maesu: 4520,
    cnt1: 8.40708,
    cnt2: 8.40708,
    cnt3: 8.40708,
    cnt4: 8.40708,
  },
  {
    maesu: 4530,
    cnt1: 8.410596,
    cnt2: 8.410596,
    cnt3: 8.410596,
    cnt4: 8.410596,
  },
  {
    maesu: 4540,
    cnt1: 8.414097,
    cnt2: 8.414097,
    cnt3: 8.414097,
    cnt4: 8.414097,
  },
  {
    maesu: 4550,
    cnt1: 8.417582,
    cnt2: 8.417582,
    cnt3: 8.417582,
    cnt4: 8.417582,
  },
  {
    maesu: 4560,
    cnt1: 8.421053,
    cnt2: 8.421053,
    cnt3: 8.421053,
    cnt4: 8.421053,
  },
  {
    maesu: 4570,
    cnt1: 8.402626,
    cnt2: 8.402626,
    cnt3: 8.402626,
    cnt4: 8.402626,
  },
  {
    maesu: 4580,
    cnt1: 8.406114,
    cnt2: 8.406114,
    cnt3: 8.406114,
    cnt4: 8.406114,
  },
  {
    maesu: 4590,
    cnt1: 8.409586,
    cnt2: 8.409586,
    cnt3: 8.409586,
    cnt4: 8.409586,
  },
  {
    maesu: 4600,
    cnt1: 8.413043,
    cnt2: 8.413043,
    cnt3: 8.413043,
    cnt4: 8.413043,
  },
  {
    maesu: 4610,
    cnt1: 8.416486,
    cnt2: 8.416486,
    cnt3: 8.416486,
    cnt4: 8.416486,
  },
  {
    maesu: 4620,
    cnt1: 8.419913,
    cnt2: 8.419913,
    cnt3: 8.419913,
    cnt4: 8.419913,
  },
  {
    maesu: 4630,
    cnt1: 8.401728,
    cnt2: 8.401728,
    cnt3: 8.401728,
    cnt4: 8.401728,
  },
  {
    maesu: 4640,
    cnt1: 8.405172,
    cnt2: 8.405172,
    cnt3: 8.405172,
    cnt4: 8.405172,
  },
  {
    maesu: 4650,
    cnt1: 8.408602,
    cnt2: 8.408602,
    cnt3: 8.408602,
    cnt4: 8.408602,
  },
  {
    maesu: 4660,
    cnt1: 8.412017,
    cnt2: 8.412017,
    cnt3: 8.412017,
    cnt4: 8.412017,
  },
  {
    maesu: 4670,
    cnt1: 8.415418,
    cnt2: 8.415418,
    cnt3: 8.415418,
    cnt4: 8.415418,
  },
  {
    maesu: 4680,
    cnt1: 8.418803,
    cnt2: 8.418803,
    cnt3: 8.418803,
    cnt4: 8.418803,
  },
  {
    maesu: 4690,
    cnt1: 8.400853,
    cnt2: 8.400853,
    cnt3: 8.400853,
    cnt4: 8.400853,
  },
  {
    maesu: 4700,
    cnt1: 8.404255,
    cnt2: 8.404255,
    cnt3: 8.404255,
    cnt4: 8.404255,
  },
  {
    maesu: 4710,
    cnt1: 8.407643,
    cnt2: 8.407643,
    cnt3: 8.407643,
    cnt4: 8.407643,
  },
  {
    maesu: 4720,
    cnt1: 8.411017,
    cnt2: 8.411017,
    cnt3: 8.411017,
    cnt4: 8.411017,
  },
  {
    maesu: 4730,
    cnt1: 8.414376,
    cnt2: 8.414376,
    cnt3: 8.414376,
    cnt4: 8.414376,
  },
  {
    maesu: 4740,
    cnt1: 8.417722,
    cnt2: 8.417722,
    cnt3: 8.417722,
    cnt4: 8.417722,
  },
  {
    maesu: 4750,
    cnt1: 8.4,
    cnt2: 8.4,
    cnt3: 8.4,
    cnt4: 8.4,
  },
  {
    maesu: 4760,
    cnt1: 8.403361,
    cnt2: 8.403361,
    cnt3: 8.403361,
    cnt4: 8.403361,
  },
  {
    maesu: 4770,
    cnt1: 8.406709,
    cnt2: 8.406709,
    cnt3: 8.406709,
    cnt4: 8.406709,
  },
  {
    maesu: 4780,
    cnt1: 8.410042,
    cnt2: 8.410042,
    cnt3: 8.410042,
    cnt4: 8.410042,
  },
  {
    maesu: 4790,
    cnt1: 8.413361,
    cnt2: 8.413361,
    cnt3: 8.413361,
    cnt4: 8.413361,
  },
  {
    maesu: 4800,
    cnt1: 8.416667,
    cnt2: 8.416667,
    cnt3: 8.416667,
    cnt4: 8.416667,
  },
  {
    maesu: 4810,
    cnt1: 8.419958,
    cnt2: 8.419958,
    cnt3: 8.419958,
    cnt4: 8.419958,
  },
  {
    maesu: 4820,
    cnt1: 8.40249,
    cnt2: 8.40249,
    cnt3: 8.40249,
    cnt4: 8.40249,
  },
  {
    maesu: 4830,
    cnt1: 8.405797,
    cnt2: 8.405797,
    cnt3: 8.405797,
    cnt4: 8.405797,
  },
  {
    maesu: 4840,
    cnt1: 8.409091,
    cnt2: 8.409091,
    cnt3: 8.409091,
    cnt4: 8.409091,
  },
  {
    maesu: 4850,
    cnt1: 8.412371,
    cnt2: 8.412371,
    cnt3: 8.412371,
    cnt4: 8.412371,
  },
  {
    maesu: 4860,
    cnt1: 8.415638,
    cnt2: 8.415638,
    cnt3: 8.415638,
    cnt4: 8.415638,
  },
  {
    maesu: 4870,
    cnt1: 8.418891,
    cnt2: 8.418891,
    cnt3: 8.418891,
    cnt4: 8.418891,
  },
  {
    maesu: 4880,
    cnt1: 8.401639,
    cnt2: 8.401639,
    cnt3: 8.401639,
    cnt4: 8.401639,
  },
  {
    maesu: 4890,
    cnt1: 8.404908,
    cnt2: 8.404908,
    cnt3: 8.404908,
    cnt4: 8.404908,
  },
  {
    maesu: 4900,
    cnt1: 8.408163,
    cnt2: 8.408163,
    cnt3: 8.408163,
    cnt4: 8.408163,
  },
  {
    maesu: 4910,
    cnt1: 8.411405,
    cnt2: 8.411405,
    cnt3: 8.411405,
    cnt4: 8.411405,
  },
  {
    maesu: 4920,
    cnt1: 8.414634,
    cnt2: 8.414634,
    cnt3: 8.414634,
    cnt4: 8.414634,
  },
  {
    maesu: 4930,
    cnt1: 8.41785,
    cnt2: 8.41785,
    cnt3: 8.41785,
    cnt4: 8.41785,
  },
  {
    maesu: 4940,
    cnt1: 8.40081,
    cnt2: 8.40081,
    cnt3: 8.40081,
    cnt4: 8.40081,
  },
  {
    maesu: 4950,
    cnt1: 8.40404,
    cnt2: 8.40404,
    cnt3: 8.40404,
    cnt4: 8.40404,
  },
  {
    maesu: 4960,
    cnt1: 8.407258,
    cnt2: 8.407258,
    cnt3: 8.407258,
    cnt4: 8.407258,
  },
  {
    maesu: 4970,
    cnt1: 8.410463,
    cnt2: 8.410463,
    cnt3: 8.410463,
    cnt4: 8.410463,
  },
  {
    maesu: 4980,
    cnt1: 8.413655,
    cnt2: 8.413655,
    cnt3: 8.413655,
    cnt4: 8.413655,
  },
  {
    maesu: 4990,
    cnt1: 8.416834,
    cnt2: 8.416834,
    cnt3: 8.416834,
    cnt4: 8.416834,
  },
  {
    maesu: 5000,
    cnt1: 8.4,
    cnt2: 8.4,
    cnt3: 8.4,
    cnt4: 8.4,
  },
  {
    maesu: 5010,
    cnt1: 8.403194,
    cnt2: 8.403194,
    cnt3: 8.403194,
    cnt4: 8.403194,
  },
  {
    maesu: 5020,
    cnt1: 8.406375,
    cnt2: 8.406375,
    cnt3: 8.406375,
    cnt4: 8.406375,
  },
  {
    maesu: 5030,
    cnt1: 8.409543,
    cnt2: 8.409543,
    cnt3: 8.409543,
    cnt4: 8.409543,
  },
  {
    maesu: 5040,
    cnt1: 8.412698,
    cnt2: 8.412698,
    cnt3: 8.412698,
    cnt4: 8.412698,
  },
  {
    maesu: 5050,
    cnt1: 8.415842,
    cnt2: 8.415842,
    cnt3: 8.415842,
    cnt4: 8.415842,
  },
  {
    maesu: 5060,
    cnt1: 8.418972,
    cnt2: 8.418972,
    cnt3: 8.418972,
    cnt4: 8.418972,
  },
  {
    maesu: 5070,
    cnt1: 8.402367,
    cnt2: 8.402367,
    cnt3: 8.402367,
    cnt4: 8.402367,
  },
  {
    maesu: 5080,
    cnt1: 8.405512,
    cnt2: 8.405512,
    cnt3: 8.405512,
    cnt4: 8.405512,
  },
  {
    maesu: 5090,
    cnt1: 8.408644,
    cnt2: 8.408644,
    cnt3: 8.408644,
    cnt4: 8.408644,
  },
  {
    maesu: 5100,
    cnt1: 8.411765,
    cnt2: 8.411765,
    cnt3: 8.411765,
    cnt4: 8.411765,
  },
  {
    maesu: 5110,
    cnt1: 8.414873,
    cnt2: 8.414873,
    cnt3: 8.414873,
    cnt4: 8.414873,
  },
  {
    maesu: 5120,
    cnt1: 8.417969,
    cnt2: 8.417969,
    cnt3: 8.417969,
    cnt4: 8.417969,
  },
  {
    maesu: 5130,
    cnt1: 8.401559,
    cnt2: 8.401559,
    cnt3: 8.401559,
    cnt4: 8.401559,
  },
  {
    maesu: 5140,
    cnt1: 8.404669,
    cnt2: 8.404669,
    cnt3: 8.404669,
    cnt4: 8.404669,
  },
  {
    maesu: 5150,
    cnt1: 8.407767,
    cnt2: 8.407767,
    cnt3: 8.407767,
    cnt4: 8.407767,
  },
  {
    maesu: 5160,
    cnt1: 8.410853,
    cnt2: 8.410853,
    cnt3: 8.410853,
    cnt4: 8.410853,
  },
  {
    maesu: 5170,
    cnt1: 8.413926,
    cnt2: 8.413926,
    cnt3: 8.413926,
    cnt4: 8.413926,
  },
  {
    maesu: 5180,
    cnt1: 8.416988,
    cnt2: 8.416988,
    cnt3: 8.416988,
    cnt4: 8.416988,
  },
  {
    maesu: 5190,
    cnt1: 8.400771,
    cnt2: 8.400771,
    cnt3: 8.400771,
    cnt4: 8.400771,
  },
  {
    maesu: 5200,
    cnt1: 8.403846,
    cnt2: 8.403846,
    cnt3: 8.403846,
    cnt4: 8.403846,
  },
  {
    maesu: 5210,
    cnt1: 8.40691,
    cnt2: 8.40691,
    cnt3: 8.40691,
    cnt4: 8.40691,
  },
  {
    maesu: 5220,
    cnt1: 8.409962,
    cnt2: 8.409962,
    cnt3: 8.409962,
    cnt4: 8.409962,
  },
  {
    maesu: 5230,
    cnt1: 8.413002,
    cnt2: 8.413002,
    cnt3: 8.413002,
    cnt4: 8.413002,
  },
  {
    maesu: 5240,
    cnt1: 8.416031,
    cnt2: 8.416031,
    cnt3: 8.416031,
    cnt4: 8.416031,
  },
  {
    maesu: 5250,
    cnt1: 8.4,
    cnt2: 8.4,
    cnt3: 8.4,
    cnt4: 8.4,
  },
  {
    maesu: 5260,
    cnt1: 8.403042,
    cnt2: 8.403042,
    cnt3: 8.403042,
    cnt4: 8.403042,
  },
  {
    maesu: 5270,
    cnt1: 8.406072,
    cnt2: 8.406072,
    cnt3: 8.406072,
    cnt4: 8.406072,
  },
  {
    maesu: 5280,
    cnt1: 8.409091,
    cnt2: 8.409091,
    cnt3: 8.409091,
    cnt4: 8.409091,
  },
  {
    maesu: 5290,
    cnt1: 8.412098,
    cnt2: 8.412098,
    cnt3: 8.412098,
    cnt4: 8.412098,
  },
  {
    maesu: 5300,
    cnt1: 8.415094,
    cnt2: 8.415094,
    cnt3: 8.415094,
    cnt4: 8.415094,
  },
  {
    maesu: 5310,
    cnt1: 8.418079,
    cnt2: 8.418079,
    cnt3: 8.418079,
    cnt4: 8.418079,
  },
  {
    maesu: 5320,
    cnt1: 8.402256,
    cnt2: 8.402256,
    cnt3: 8.402256,
    cnt4: 8.402256,
  },
  {
    maesu: 5330,
    cnt1: 8.405253,
    cnt2: 8.405253,
    cnt3: 8.405253,
    cnt4: 8.405253,
  },
  {
    maesu: 5340,
    cnt1: 8.40824,
    cnt2: 8.40824,
    cnt3: 8.40824,
    cnt4: 8.40824,
  },
  {
    maesu: 5350,
    cnt1: 8.411215,
    cnt2: 8.411215,
    cnt3: 8.411215,
    cnt4: 8.411215,
  },
  {
    maesu: 5360,
    cnt1: 8.414179,
    cnt2: 8.414179,
    cnt3: 8.414179,
    cnt4: 8.414179,
  },
  {
    maesu: 5370,
    cnt1: 8.417132,
    cnt2: 8.417132,
    cnt3: 8.417132,
    cnt4: 8.417132,
  },
  {
    maesu: 5380,
    cnt1: 8.401487,
    cnt2: 8.401487,
    cnt3: 8.401487,
    cnt4: 8.401487,
  },
  {
    maesu: 5390,
    cnt1: 8.404453,
    cnt2: 8.404453,
    cnt3: 8.404453,
    cnt4: 8.404453,
  },
  {
    maesu: 5400,
    cnt1: 8.407407,
    cnt2: 8.407407,
    cnt3: 8.407407,
    cnt4: 8.407407,
  },
  {
    maesu: 5410,
    cnt1: 8.410351,
    cnt2: 8.410351,
    cnt3: 8.410351,
    cnt4: 8.410351,
  },
  {
    maesu: 5420,
    cnt1: 8.413284,
    cnt2: 8.413284,
    cnt3: 8.413284,
    cnt4: 8.413284,
  },
  {
    maesu: 5430,
    cnt1: 8.416206,
    cnt2: 8.416206,
    cnt3: 8.416206,
    cnt4: 8.416206,
  },
  {
    maesu: 5440,
    cnt1: 8.400735,
    cnt2: 8.400735,
    cnt3: 8.400735,
    cnt4: 8.400735,
  },
  {
    maesu: 5450,
    cnt1: 8.40367,
    cnt2: 8.40367,
    cnt3: 8.40367,
    cnt4: 8.40367,
  },
  {
    maesu: 5460,
    cnt1: 8.406593,
    cnt2: 8.406593,
    cnt3: 8.406593,
    cnt4: 8.406593,
  },
  {
    maesu: 5470,
    cnt1: 8.409506,
    cnt2: 8.409506,
    cnt3: 8.409506,
    cnt4: 8.409506,
  },
  {
    maesu: 5480,
    cnt1: 8.412409,
    cnt2: 8.412409,
    cnt3: 8.412409,
    cnt4: 8.412409,
  },
  {
    maesu: 5490,
    cnt1: 8.415301,
    cnt2: 8.415301,
    cnt3: 8.415301,
    cnt4: 8.415301,
  },
  {
    maesu: 5500,
    cnt1: 8.4,
    cnt2: 8.4,
    cnt3: 8.4,
    cnt4: 8.4,
  },
  {
    maesu: 5510,
    cnt1: 8.402904,
    cnt2: 8.402904,
    cnt3: 8.402904,
    cnt4: 8.402904,
  },
  {
    maesu: 5520,
    cnt1: 8.405797,
    cnt2: 8.405797,
    cnt3: 8.405797,
    cnt4: 8.405797,
  },
  {
    maesu: 5530,
    cnt1: 8.40868,
    cnt2: 8.40868,
    cnt3: 8.40868,
    cnt4: 8.40868,
  },
  {
    maesu: 5540,
    cnt1: 8.411552,
    cnt2: 8.411552,
    cnt3: 8.411552,
    cnt4: 8.411552,
  },
  {
    maesu: 5550,
    cnt1: 8.414414,
    cnt2: 8.414414,
    cnt3: 8.414414,
    cnt4: 8.414414,
  },
  {
    maesu: 5560,
    cnt1: 8.417266,
    cnt2: 8.417266,
    cnt3: 8.417266,
    cnt4: 8.417266,
  },
  {
    maesu: 5570,
    cnt1: 8.402154,
    cnt2: 8.402154,
    cnt3: 8.402154,
    cnt4: 8.402154,
  },
  {
    maesu: 5580,
    cnt1: 8.405018,
    cnt2: 8.405018,
    cnt3: 8.405018,
    cnt4: 8.405018,
  },
  {
    maesu: 5590,
    cnt1: 8.407871,
    cnt2: 8.407871,
    cnt3: 8.407871,
    cnt4: 8.407871,
  },
  {
    maesu: 5600,
    cnt1: 8.410714,
    cnt2: 8.410714,
    cnt3: 8.410714,
    cnt4: 8.410714,
  },
  {
    maesu: 5610,
    cnt1: 8.413547,
    cnt2: 8.413547,
    cnt3: 8.413547,
    cnt4: 8.413547,
  },
  {
    maesu: 5620,
    cnt1: 8.41637,
    cnt2: 8.41637,
    cnt3: 8.41637,
    cnt4: 8.41637,
  },
  {
    maesu: 5630,
    cnt1: 8.401421,
    cnt2: 8.401421,
    cnt3: 8.401421,
    cnt4: 8.401421,
  },
  {
    maesu: 5640,
    cnt1: 8.404255,
    cnt2: 8.404255,
    cnt3: 8.404255,
    cnt4: 8.404255,
  },
  {
    maesu: 5650,
    cnt1: 8.40708,
    cnt2: 8.40708,
    cnt3: 8.40708,
    cnt4: 8.40708,
  },
  {
    maesu: 5660,
    cnt1: 8.409894,
    cnt2: 8.409894,
    cnt3: 8.409894,
    cnt4: 8.409894,
  },
  {
    maesu: 5670,
    cnt1: 8.412698,
    cnt2: 8.412698,
    cnt3: 8.412698,
    cnt4: 8.412698,
  },
  {
    maesu: 5680,
    cnt1: 8.415493,
    cnt2: 8.415493,
    cnt3: 8.415493,
    cnt4: 8.415493,
  },
  {
    maesu: 5690,
    cnt1: 8.400703,
    cnt2: 8.400703,
    cnt3: 8.400703,
    cnt4: 8.400703,
  },
  {
    maesu: 5700,
    cnt1: 8.403509,
    cnt2: 8.403509,
    cnt3: 8.403509,
    cnt4: 8.403509,
  },
  {
    maesu: 5710,
    cnt1: 8.406305,
    cnt2: 8.406305,
    cnt3: 8.406305,
    cnt4: 8.406305,
  },
  {
    maesu: 5720,
    cnt1: 8.409091,
    cnt2: 8.409091,
    cnt3: 8.409091,
    cnt4: 8.409091,
  },
  {
    maesu: 5730,
    cnt1: 8.411867,
    cnt2: 8.411867,
    cnt3: 8.411867,
    cnt4: 8.411867,
  },
  {
    maesu: 5740,
    cnt1: 8.414634,
    cnt2: 8.414634,
    cnt3: 8.414634,
    cnt4: 8.414634,
  },
  {
    maesu: 5750,
    cnt1: 8.4,
    cnt2: 8.4,
    cnt3: 8.4,
    cnt4: 8.4,
  },
  {
    maesu: 5760,
    cnt1: 8.402778,
    cnt2: 8.402778,
    cnt3: 8.402778,
    cnt4: 8.402778,
  },
  {
    maesu: 5770,
    cnt1: 8.405546,
    cnt2: 8.405546,
    cnt3: 8.405546,
    cnt4: 8.405546,
  },
  {
    maesu: 5780,
    cnt1: 8.408304,
    cnt2: 8.408304,
    cnt3: 8.408304,
    cnt4: 8.408304,
  },
  {
    maesu: 5790,
    cnt1: 8.411054,
    cnt2: 8.411054,
    cnt3: 8.411054,
    cnt4: 8.411054,
  },
  {
    maesu: 5800,
    cnt1: 8.413793,
    cnt2: 8.413793,
    cnt3: 8.413793,
    cnt4: 8.413793,
  },
  {
    maesu: 5810,
    cnt1: 8.416523,
    cnt2: 8.416523,
    cnt3: 8.416523,
    cnt4: 8.416523,
  },
  {
    maesu: 5820,
    cnt1: 8.402062,
    cnt2: 8.402062,
    cnt3: 8.402062,
    cnt4: 8.402062,
  },
  {
    maesu: 5830,
    cnt1: 8.404803,
    cnt2: 8.404803,
    cnt3: 8.404803,
    cnt4: 8.404803,
  },
  {
    maesu: 5840,
    cnt1: 8.407534,
    cnt2: 8.407534,
    cnt3: 8.407534,
    cnt4: 8.407534,
  },
  {
    maesu: 5850,
    cnt1: 8.410256,
    cnt2: 8.410256,
    cnt3: 8.410256,
    cnt4: 8.410256,
  },
  {
    maesu: 5860,
    cnt1: 8.412969,
    cnt2: 8.412969,
    cnt3: 8.412969,
    cnt4: 8.412969,
  },
  {
    maesu: 5870,
    cnt1: 8.415673,
    cnt2: 8.415673,
    cnt3: 8.415673,
    cnt4: 8.415673,
  },
  {
    maesu: 5880,
    cnt1: 8.401361,
    cnt2: 8.401361,
    cnt3: 8.401361,
    cnt4: 8.401361,
  },
  {
    maesu: 5890,
    cnt1: 8.404075,
    cnt2: 8.404075,
    cnt3: 8.404075,
    cnt4: 8.404075,
  },
  {
    maesu: 5900,
    cnt1: 8.40678,
    cnt2: 8.40678,
    cnt3: 8.40678,
    cnt4: 8.40678,
  },
  {
    maesu: 5910,
    cnt1: 8.409475,
    cnt2: 8.409475,
    cnt3: 8.409475,
    cnt4: 8.409475,
  },
  {
    maesu: 5920,
    cnt1: 8.412162,
    cnt2: 8.412162,
    cnt3: 8.412162,
    cnt4: 8.412162,
  },
  {
    maesu: 5930,
    cnt1: 8.41484,
    cnt2: 8.41484,
    cnt3: 8.41484,
    cnt4: 8.41484,
  },
  {
    maesu: 5940,
    cnt1: 8.400673,
    cnt2: 8.400673,
    cnt3: 8.400673,
    cnt4: 8.400673,
  },
  {
    maesu: 5950,
    cnt1: 8.403361,
    cnt2: 8.403361,
    cnt3: 8.403361,
    cnt4: 8.403361,
  },
  {
    maesu: 5960,
    cnt1: 8.40604,
    cnt2: 8.40604,
    cnt3: 8.40604,
    cnt4: 8.40604,
  },
  {
    maesu: 5970,
    cnt1: 8.40871,
    cnt2: 8.40871,
    cnt3: 8.40871,
    cnt4: 8.40871,
  },
  {
    maesu: 5980,
    cnt1: 8.411371,
    cnt2: 8.411371,
    cnt3: 8.411371,
    cnt4: 8.411371,
  },
  {
    maesu: 5990,
    cnt1: 8.414023,
    cnt2: 8.414023,
    cnt3: 8.414023,
    cnt4: 8.414023,
  },
  {
    maesu: 6000,
    cnt1: 8.4,
    cnt2: 8.4,
    cnt3: 8.4,
    cnt4: 8.4,
  },
  {
    maesu: 6010,
    cnt1: 8.402662,
    cnt2: 8.402662,
    cnt3: 8.402662,
    cnt4: 8.402662,
  },
  {
    maesu: 6020,
    cnt1: 8.405316,
    cnt2: 8.405316,
    cnt3: 8.405316,
    cnt4: 8.405316,
  },
  {
    maesu: 6030,
    cnt1: 8.40796,
    cnt2: 8.40796,
    cnt3: 8.40796,
    cnt4: 8.40796,
  },
  {
    maesu: 6040,
    cnt1: 8.410596,
    cnt2: 8.410596,
    cnt3: 8.410596,
    cnt4: 8.410596,
  },
  {
    maesu: 6050,
    cnt1: 8.413223,
    cnt2: 8.413223,
    cnt3: 8.413223,
    cnt4: 8.413223,
  },
  {
    maesu: 6060,
    cnt1: 8.415842,
    cnt2: 8.415842,
    cnt3: 8.415842,
    cnt4: 8.415842,
  },
  {
    maesu: 6070,
    cnt1: 8.401977,
    cnt2: 8.401977,
    cnt3: 8.401977,
    cnt4: 8.401977,
  },
  {
    maesu: 6080,
    cnt1: 8.404605,
    cnt2: 8.404605,
    cnt3: 8.404605,
    cnt4: 8.404605,
  },
  {
    maesu: 6090,
    cnt1: 8.407225,
    cnt2: 8.407225,
    cnt3: 8.407225,
    cnt4: 8.407225,
  },
  {
    maesu: 6100,
    cnt1: 8.409836,
    cnt2: 8.409836,
    cnt3: 8.409836,
    cnt4: 8.409836,
  },
  {
    maesu: 6110,
    cnt1: 8.412439,
    cnt2: 8.412439,
    cnt3: 8.412439,
    cnt4: 8.412439,
  },
  {
    maesu: 6120,
    cnt1: 8.415033,
    cnt2: 8.415033,
    cnt3: 8.415033,
    cnt4: 8.415033,
  },
  {
    maesu: 6130,
    cnt1: 8.401305,
    cnt2: 8.401305,
    cnt3: 8.401305,
    cnt4: 8.401305,
  },
  {
    maesu: 6140,
    cnt1: 8.403909,
    cnt2: 8.403909,
    cnt3: 8.403909,
    cnt4: 8.403909,
  },
  {
    maesu: 6150,
    cnt1: 8.406504,
    cnt2: 8.406504,
    cnt3: 8.406504,
    cnt4: 8.406504,
  },
  {
    maesu: 6160,
    cnt1: 8.409091,
    cnt2: 8.409091,
    cnt3: 8.409091,
    cnt4: 8.409091,
  },
  {
    maesu: 6170,
    cnt1: 8.411669,
    cnt2: 8.411669,
    cnt3: 8.411669,
    cnt4: 8.411669,
  },
  {
    maesu: 6180,
    cnt1: 8.414239,
    cnt2: 8.414239,
    cnt3: 8.414239,
    cnt4: 8.414239,
  },
  {
    maesu: 6190,
    cnt1: 8.400646,
    cnt2: 8.400646,
    cnt3: 8.400646,
    cnt4: 8.400646,
  },
  {
    maesu: 6200,
    cnt1: 8.403226,
    cnt2: 8.403226,
    cnt3: 8.403226,
    cnt4: 8.403226,
  },
  {
    maesu: 6210,
    cnt1: 8.405797,
    cnt2: 8.405797,
    cnt3: 8.405797,
    cnt4: 8.405797,
  },
  {
    maesu: 6220,
    cnt1: 8.40836,
    cnt2: 8.40836,
    cnt3: 8.40836,
    cnt4: 8.40836,
  },
  {
    maesu: 6230,
    cnt1: 8.410915,
    cnt2: 8.410915,
    cnt3: 8.410915,
    cnt4: 8.410915,
  },
  {
    maesu: 6240,
    cnt1: 8.413462,
    cnt2: 8.413462,
    cnt3: 8.413462,
    cnt4: 8.413462,
  },
  {
    maesu: 6250,
    cnt1: 8.4,
    cnt2: 8.4,
    cnt3: 8.4,
    cnt4: 8.4,
  },
  {
    maesu: 6260,
    cnt1: 8.402556,
    cnt2: 8.402556,
    cnt3: 8.402556,
    cnt4: 8.402556,
  },
  {
    maesu: 6270,
    cnt1: 8.405104,
    cnt2: 8.405104,
    cnt3: 8.405104,
    cnt4: 8.405104,
  },
  {
    maesu: 6280,
    cnt1: 8.407643,
    cnt2: 8.407643,
    cnt3: 8.407643,
    cnt4: 8.407643,
  },
  {
    maesu: 6290,
    cnt1: 8.410175,
    cnt2: 8.410175,
    cnt3: 8.410175,
    cnt4: 8.410175,
  },
  {
    maesu: 6300,
    cnt1: 8.412698,
    cnt2: 8.412698,
    cnt3: 8.412698,
    cnt4: 8.412698,
  },
  {
    maesu: 6310,
    cnt1: 8.415214,
    cnt2: 8.415214,
    cnt3: 8.415214,
    cnt4: 8.415214,
  },
  {
    maesu: 6320,
    cnt1: 8.401899,
    cnt2: 8.401899,
    cnt3: 8.401899,
    cnt4: 8.401899,
  },
  {
    maesu: 6330,
    cnt1: 8.404423,
    cnt2: 8.404423,
    cnt3: 8.404423,
    cnt4: 8.404423,
  },
  {
    maesu: 6340,
    cnt1: 8.40694,
    cnt2: 8.40694,
    cnt3: 8.40694,
    cnt4: 8.40694,
  },
  {
    maesu: 6350,
    cnt1: 8.409449,
    cnt2: 8.409449,
    cnt3: 8.409449,
    cnt4: 8.409449,
  },
  {
    maesu: 6360,
    cnt1: 8.41195,
    cnt2: 8.41195,
    cnt3: 8.41195,
    cnt4: 8.41195,
  },
  {
    maesu: 6370,
    cnt1: 8.414443,
    cnt2: 8.414443,
    cnt3: 8.414443,
    cnt4: 8.414443,
  },
  {
    maesu: 6380,
    cnt1: 8.401254,
    cnt2: 8.401254,
    cnt3: 8.401254,
    cnt4: 8.401254,
  },
  {
    maesu: 6390,
    cnt1: 8.403756,
    cnt2: 8.403756,
    cnt3: 8.403756,
    cnt4: 8.403756,
  },
  {
    maesu: 6400,
    cnt1: 8.40625,
    cnt2: 8.40625,
    cnt3: 8.40625,
    cnt4: 8.40625,
  },
  {
    maesu: 6410,
    cnt1: 8.408736,
    cnt2: 8.408736,
    cnt3: 8.408736,
    cnt4: 8.408736,
  },
  {
    maesu: 6420,
    cnt1: 8.411215,
    cnt2: 8.411215,
    cnt3: 8.411215,
    cnt4: 8.411215,
  },
  {
    maesu: 6430,
    cnt1: 8.413686,
    cnt2: 8.413686,
    cnt3: 8.413686,
    cnt4: 8.413686,
  },
  {
    maesu: 6440,
    cnt1: 8.400621,
    cnt2: 8.400621,
    cnt3: 8.400621,
    cnt4: 8.400621,
  },
  {
    maesu: 6450,
    cnt1: 8.403101,
    cnt2: 8.403101,
    cnt3: 8.403101,
    cnt4: 8.403101,
  },
  {
    maesu: 6460,
    cnt1: 8.405573,
    cnt2: 8.405573,
    cnt3: 8.405573,
    cnt4: 8.405573,
  },
  {
    maesu: 6470,
    cnt1: 8.408037,
    cnt2: 8.408037,
    cnt3: 8.408037,
    cnt4: 8.408037,
  },
  {
    maesu: 6480,
    cnt1: 8.410494,
    cnt2: 8.410494,
    cnt3: 8.410494,
    cnt4: 8.410494,
  },
  {
    maesu: 6490,
    cnt1: 8.412943,
    cnt2: 8.412943,
    cnt3: 8.412943,
    cnt4: 8.412943,
  },
  {
    maesu: 6500,
    cnt1: 8.4,
    cnt2: 8.4,
    cnt3: 8.4,
    cnt4: 8.4,
  },
  {
    maesu: 6510,
    cnt1: 8.402458,
    cnt2: 8.402458,
    cnt3: 8.402458,
    cnt4: 8.402458,
  },
  {
    maesu: 6520,
    cnt1: 8.404908,
    cnt2: 8.404908,
    cnt3: 8.404908,
    cnt4: 8.404908,
  },
  {
    maesu: 6530,
    cnt1: 8.407351,
    cnt2: 8.407351,
    cnt3: 8.407351,
    cnt4: 8.407351,
  },
  {
    maesu: 6540,
    cnt1: 8.409786,
    cnt2: 8.409786,
    cnt3: 8.409786,
    cnt4: 8.409786,
  },
  {
    maesu: 6550,
    cnt1: 8.412214,
    cnt2: 8.412214,
    cnt3: 8.412214,
    cnt4: 8.412214,
  },
  {
    maesu: 6560,
    cnt1: 8.414634,
    cnt2: 8.414634,
    cnt3: 8.414634,
    cnt4: 8.414634,
  },
  {
    maesu: 6570,
    cnt1: 8.401826,
    cnt2: 8.401826,
    cnt3: 8.401826,
    cnt4: 8.401826,
  },
  {
    maesu: 6580,
    cnt1: 8.404255,
    cnt2: 8.404255,
    cnt3: 8.404255,
    cnt4: 8.404255,
  },
  {
    maesu: 6590,
    cnt1: 8.406677,
    cnt2: 8.406677,
    cnt3: 8.406677,
    cnt4: 8.406677,
  },
  {
    maesu: 6600,
    cnt1: 8.409091,
    cnt2: 8.409091,
    cnt3: 8.409091,
    cnt4: 8.409091,
  },
  {
    maesu: 6610,
    cnt1: 8.411498,
    cnt2: 8.411498,
    cnt3: 8.411498,
    cnt4: 8.411498,
  },
  {
    maesu: 6620,
    cnt1: 8.413897,
    cnt2: 8.413897,
    cnt3: 8.413897,
    cnt4: 8.413897,
  },
  {
    maesu: 6630,
    cnt1: 8.401207,
    cnt2: 8.401207,
    cnt3: 8.401207,
    cnt4: 8.401207,
  },
  {
    maesu: 6640,
    cnt1: 8.403614,
    cnt2: 8.403614,
    cnt3: 8.403614,
    cnt4: 8.403614,
  },
  {
    maesu: 6650,
    cnt1: 8.406015,
    cnt2: 8.406015,
    cnt3: 8.406015,
    cnt4: 8.406015,
  },
  {
    maesu: 6660,
    cnt1: 8.408408,
    cnt2: 8.408408,
    cnt3: 8.408408,
    cnt4: 8.408408,
  },
  {
    maesu: 6670,
    cnt1: 8.410795,
    cnt2: 8.410795,
    cnt3: 8.410795,
    cnt4: 8.410795,
  },
  {
    maesu: 6680,
    cnt1: 8.413174,
    cnt2: 8.413174,
    cnt3: 8.413174,
    cnt4: 8.413174,
  },
  {
    maesu: 6690,
    cnt1: 8.400598,
    cnt2: 8.400598,
    cnt3: 8.400598,
    cnt4: 8.400598,
  },
  {
    maesu: 6700,
    cnt1: 8.402985,
    cnt2: 8.402985,
    cnt3: 8.402985,
    cnt4: 8.402985,
  },
  {
    maesu: 6710,
    cnt1: 8.405365,
    cnt2: 8.405365,
    cnt3: 8.405365,
    cnt4: 8.405365,
  },
  {
    maesu: 6720,
    cnt1: 8.407738,
    cnt2: 8.407738,
    cnt3: 8.407738,
    cnt4: 8.407738,
  },
  {
    maesu: 6730,
    cnt1: 8.410104,
    cnt2: 8.410104,
    cnt3: 8.410104,
    cnt4: 8.410104,
  },
  {
    maesu: 6740,
    cnt1: 8.412463,
    cnt2: 8.412463,
    cnt3: 8.412463,
    cnt4: 8.412463,
  },
  {
    maesu: 6750,
    cnt1: 8.4,
    cnt2: 8.4,
    cnt3: 8.4,
    cnt4: 8.4,
  },
  {
    maesu: 6760,
    cnt1: 8.402367,
    cnt2: 8.402367,
    cnt3: 8.402367,
    cnt4: 8.402367,
  },
  {
    maesu: 6770,
    cnt1: 8.404727,
    cnt2: 8.404727,
    cnt3: 8.404727,
    cnt4: 8.404727,
  },
  {
    maesu: 6780,
    cnt1: 8.40708,
    cnt2: 8.40708,
    cnt3: 8.40708,
    cnt4: 8.40708,
  },
  {
    maesu: 6790,
    cnt1: 8.409426,
    cnt2: 8.409426,
    cnt3: 8.409426,
    cnt4: 8.409426,
  },
  {
    maesu: 6800,
    cnt1: 8.411765,
    cnt2: 8.411765,
    cnt3: 8.411765,
    cnt4: 8.411765,
  },
  {
    maesu: 6810,
    cnt1: 8.414097,
    cnt2: 8.414097,
    cnt3: 8.414097,
    cnt4: 8.414097,
  },
  {
    maesu: 6820,
    cnt1: 8.40176,
    cnt2: 8.40176,
    cnt3: 8.40176,
    cnt4: 8.40176,
  },
  {
    maesu: 6830,
    cnt1: 8.4041,
    cnt2: 8.4041,
    cnt3: 8.4041,
    cnt4: 8.4041,
  },
  {
    maesu: 6840,
    cnt1: 8.406433,
    cnt2: 8.406433,
    cnt3: 8.406433,
    cnt4: 8.406433,
  },
  {
    maesu: 6850,
    cnt1: 8.408759,
    cnt2: 8.408759,
    cnt3: 8.408759,
    cnt4: 8.408759,
  },
  {
    maesu: 6860,
    cnt1: 8.411079,
    cnt2: 8.411079,
    cnt3: 8.411079,
    cnt4: 8.411079,
  },
  {
    maesu: 6870,
    cnt1: 8.413392,
    cnt2: 8.413392,
    cnt3: 8.413392,
    cnt4: 8.413392,
  },
  {
    maesu: 6880,
    cnt1: 8.401163,
    cnt2: 8.401163,
    cnt3: 8.401163,
    cnt4: 8.401163,
  },
  {
    maesu: 6890,
    cnt1: 8.403483,
    cnt2: 8.403483,
    cnt3: 8.403483,
    cnt4: 8.403483,
  },
  {
    maesu: 6900,
    cnt1: 8.405797,
    cnt2: 8.405797,
    cnt3: 8.405797,
    cnt4: 8.405797,
  },
  {
    maesu: 6910,
    cnt1: 8.408104,
    cnt2: 8.408104,
    cnt3: 8.408104,
    cnt4: 8.408104,
  },
  {
    maesu: 6920,
    cnt1: 8.410405,
    cnt2: 8.410405,
    cnt3: 8.410405,
    cnt4: 8.410405,
  },
  {
    maesu: 6930,
    cnt1: 8.412698,
    cnt2: 8.412698,
    cnt3: 8.412698,
    cnt4: 8.412698,
  },
  {
    maesu: 6940,
    cnt1: 8.400576,
    cnt2: 8.400576,
    cnt3: 8.400576,
    cnt4: 8.400576,
  },
  {
    maesu: 6950,
    cnt1: 8.402878,
    cnt2: 8.402878,
    cnt3: 8.402878,
    cnt4: 8.402878,
  },
  {
    maesu: 6960,
    cnt1: 8.405172,
    cnt2: 8.405172,
    cnt3: 8.405172,
    cnt4: 8.405172,
  },
  {
    maesu: 6970,
    cnt1: 8.407461,
    cnt2: 8.407461,
    cnt3: 8.407461,
    cnt4: 8.407461,
  },
  {
    maesu: 6980,
    cnt1: 8.409742,
    cnt2: 8.409742,
    cnt3: 8.409742,
    cnt4: 8.409742,
  },
  {
    maesu: 6990,
    cnt1: 8.412017,
    cnt2: 8.412017,
    cnt3: 8.412017,
    cnt4: 8.412017,
  },
  {
    maesu: 7000,
    cnt1: 8.4,
    cnt2: 8.4,
    cnt3: 8.4,
    cnt4: 8.4,
  },
  {
    maesu: 7010,
    cnt1: 8.402282,
    cnt2: 8.402282,
    cnt3: 8.402282,
    cnt4: 8.402282,
  },
  {
    maesu: 7020,
    cnt1: 8.404558,
    cnt2: 8.404558,
    cnt3: 8.404558,
    cnt4: 8.404558,
  },
  {
    maesu: 7030,
    cnt1: 8.406828,
    cnt2: 8.406828,
    cnt3: 8.406828,
    cnt4: 8.406828,
  },
  {
    maesu: 7040,
    cnt1: 8.409091,
    cnt2: 8.409091,
    cnt3: 8.409091,
    cnt4: 8.409091,
  },
  {
    maesu: 7050,
    cnt1: 8.411348,
    cnt2: 8.411348,
    cnt3: 8.411348,
    cnt4: 8.411348,
  },
  {
    maesu: 7060,
    cnt1: 8.413598,
    cnt2: 8.413598,
    cnt3: 8.413598,
    cnt4: 8.413598,
  },
  {
    maesu: 7070,
    cnt1: 8.401697,
    cnt2: 8.401697,
    cnt3: 8.401697,
    cnt4: 8.401697,
  },
  {
    maesu: 7080,
    cnt1: 8.403955,
    cnt2: 8.403955,
    cnt3: 8.403955,
    cnt4: 8.403955,
  },
  {
    maesu: 7090,
    cnt1: 8.406206,
    cnt2: 8.406206,
    cnt3: 8.406206,
    cnt4: 8.406206,
  },
  {
    maesu: 7100,
    cnt1: 8.408451,
    cnt2: 8.408451,
    cnt3: 8.408451,
    cnt4: 8.408451,
  },
  {
    maesu: 7110,
    cnt1: 8.410689,
    cnt2: 8.410689,
    cnt3: 8.410689,
    cnt4: 8.410689,
  },
  {
    maesu: 7120,
    cnt1: 8.412921,
    cnt2: 8.412921,
    cnt3: 8.412921,
    cnt4: 8.412921,
  },
  {
    maesu: 7130,
    cnt1: 8.401122,
    cnt2: 8.401122,
    cnt3: 8.401122,
    cnt4: 8.401122,
  },
  {
    maesu: 7140,
    cnt1: 8.403361,
    cnt2: 8.403361,
    cnt3: 8.403361,
    cnt4: 8.403361,
  },
  {
    maesu: 7150,
    cnt1: 8.405594,
    cnt2: 8.405594,
    cnt3: 8.405594,
    cnt4: 8.405594,
  },
  {
    maesu: 7160,
    cnt1: 8.407821,
    cnt2: 8.407821,
    cnt3: 8.407821,
    cnt4: 8.407821,
  },
  {
    maesu: 7170,
    cnt1: 8.410042,
    cnt2: 8.410042,
    cnt3: 8.410042,
    cnt4: 8.410042,
  },
  {
    maesu: 7180,
    cnt1: 8.412256,
    cnt2: 8.412256,
    cnt3: 8.412256,
    cnt4: 8.412256,
  },
  {
    maesu: 7190,
    cnt1: 8.400556,
    cnt2: 8.400556,
    cnt3: 8.400556,
    cnt4: 8.400556,
  },
  {
    maesu: 7200,
    cnt1: 8.402778,
    cnt2: 8.402778,
    cnt3: 8.402778,
    cnt4: 8.402778,
  },
  {
    maesu: 7210,
    cnt1: 8.404993,
    cnt2: 8.404993,
    cnt3: 8.404993,
    cnt4: 8.404993,
  },
  {
    maesu: 7220,
    cnt1: 8.407202,
    cnt2: 8.407202,
    cnt3: 8.407202,
    cnt4: 8.407202,
  },
  {
    maesu: 7230,
    cnt1: 8.409405,
    cnt2: 8.409405,
    cnt3: 8.409405,
    cnt4: 8.409405,
  },
  {
    maesu: 7240,
    cnt1: 8.411602,
    cnt2: 8.411602,
    cnt3: 8.411602,
    cnt4: 8.411602,
  },
  {
    maesu: 7250,
    cnt1: 8.4,
    cnt2: 8.4,
    cnt3: 8.4,
    cnt4: 8.4,
  },
  {
    maesu: 7260,
    cnt1: 8.402204,
    cnt2: 8.402204,
    cnt3: 8.402204,
    cnt4: 8.402204,
  },
  {
    maesu: 7270,
    cnt1: 8.404402,
    cnt2: 8.404402,
    cnt3: 8.404402,
    cnt4: 8.404402,
  },
  {
    maesu: 7280,
    cnt1: 8.406593,
    cnt2: 8.406593,
    cnt3: 8.406593,
    cnt4: 8.406593,
  },
  {
    maesu: 7290,
    cnt1: 8.408779,
    cnt2: 8.408779,
    cnt3: 8.408779,
    cnt4: 8.408779,
  },
  {
    maesu: 7300,
    cnt1: 8.410959,
    cnt2: 8.410959,
    cnt3: 8.410959,
    cnt4: 8.410959,
  },
  {
    maesu: 7310,
    cnt1: 8.413133,
    cnt2: 8.413133,
    cnt3: 8.413133,
    cnt4: 8.413133,
  },
  {
    maesu: 7320,
    cnt1: 8.401639,
    cnt2: 8.401639,
    cnt3: 8.401639,
    cnt4: 8.401639,
  },
  {
    maesu: 7330,
    cnt1: 8.40382,
    cnt2: 8.40382,
    cnt3: 8.40382,
    cnt4: 8.40382,
  },
  {
    maesu: 7340,
    cnt1: 8.405995,
    cnt2: 8.405995,
    cnt3: 8.405995,
    cnt4: 8.405995,
  },
  {
    maesu: 7350,
    cnt1: 8.408163,
    cnt2: 8.408163,
    cnt3: 8.408163,
    cnt4: 8.408163,
  },
  {
    maesu: 7360,
    cnt1: 8.410326,
    cnt2: 8.410326,
    cnt3: 8.410326,
    cnt4: 8.410326,
  },
  {
    maesu: 7370,
    cnt1: 8.412483,
    cnt2: 8.412483,
    cnt3: 8.412483,
    cnt4: 8.412483,
  },
  {
    maesu: 7380,
    cnt1: 8.401084,
    cnt2: 8.401084,
    cnt3: 8.401084,
    cnt4: 8.401084,
  },
  {
    maesu: 7390,
    cnt1: 8.403248,
    cnt2: 8.403248,
    cnt3: 8.403248,
    cnt4: 8.403248,
  },
  {
    maesu: 7400,
    cnt1: 8.405405,
    cnt2: 8.405405,
    cnt3: 8.405405,
    cnt4: 8.405405,
  },
  {
    maesu: 7410,
    cnt1: 8.407557,
    cnt2: 8.407557,
    cnt3: 8.407557,
    cnt4: 8.407557,
  },
  {
    maesu: 7420,
    cnt1: 8.409704,
    cnt2: 8.409704,
    cnt3: 8.409704,
    cnt4: 8.409704,
  },
  {
    maesu: 7430,
    cnt1: 8.411844,
    cnt2: 8.411844,
    cnt3: 8.411844,
    cnt4: 8.411844,
  },
  {
    maesu: 7440,
    cnt1: 8.400538,
    cnt2: 8.400538,
    cnt3: 8.400538,
    cnt4: 8.400538,
  },
  {
    maesu: 7450,
    cnt1: 8.402685,
    cnt2: 8.402685,
    cnt3: 8.402685,
    cnt4: 8.402685,
  },
  {
    maesu: 7460,
    cnt1: 8.404826,
    cnt2: 8.404826,
    cnt3: 8.404826,
    cnt4: 8.404826,
  },
  {
    maesu: 7470,
    cnt1: 8.406961,
    cnt2: 8.406961,
    cnt3: 8.406961,
    cnt4: 8.406961,
  },
  {
    maesu: 7480,
    cnt1: 8.409091,
    cnt2: 8.409091,
    cnt3: 8.409091,
    cnt4: 8.409091,
  },
  {
    maesu: 7490,
    cnt1: 8.411215,
    cnt2: 8.411215,
    cnt3: 8.411215,
    cnt4: 8.411215,
  },
  {
    maesu: 7500,
    cnt1: 8.4,
    cnt2: 8.4,
    cnt3: 8.4,
    cnt4: 8.4,
  },
  {
    maesu: 7510,
    cnt1: 8.40213,
    cnt2: 8.40213,
    cnt3: 8.40213,
    cnt4: 8.40213,
  },
  {
    maesu: 7520,
    cnt1: 8.404255,
    cnt2: 8.404255,
    cnt3: 8.404255,
    cnt4: 8.404255,
  },
  {
    maesu: 7530,
    cnt1: 8.406375,
    cnt2: 8.406375,
    cnt3: 8.406375,
    cnt4: 8.406375,
  },
  {
    maesu: 7540,
    cnt1: 8.408488,
    cnt2: 8.408488,
    cnt3: 8.408488,
    cnt4: 8.408488,
  },
  {
    maesu: 7550,
    cnt1: 8.410596,
    cnt2: 8.410596,
    cnt3: 8.410596,
    cnt4: 8.410596,
  },
  {
    maesu: 7560,
    cnt1: 8.412698,
    cnt2: 8.412698,
    cnt3: 8.412698,
    cnt4: 8.412698,
  },
  {
    maesu: 7570,
    cnt1: 8.401585,
    cnt2: 8.401585,
    cnt3: 8.401585,
    cnt4: 8.401585,
  },
  {
    maesu: 7580,
    cnt1: 8.403694,
    cnt2: 8.403694,
    cnt3: 8.403694,
    cnt4: 8.403694,
  },
  {
    maesu: 7590,
    cnt1: 8.405797,
    cnt2: 8.405797,
    cnt3: 8.405797,
    cnt4: 8.405797,
  },
  {
    maesu: 7600,
    cnt1: 8.407895,
    cnt2: 8.407895,
    cnt3: 8.407895,
    cnt4: 8.407895,
  },
  {
    maesu: 7610,
    cnt1: 8.409987,
    cnt2: 8.409987,
    cnt3: 8.409987,
    cnt4: 8.409987,
  },
  {
    maesu: 7620,
    cnt1: 8.412073,
    cnt2: 8.412073,
    cnt3: 8.412073,
    cnt4: 8.412073,
  },
  {
    maesu: 7630,
    cnt1: 8.401048,
    cnt2: 8.401048,
    cnt3: 8.401048,
    cnt4: 8.401048,
  },
  {
    maesu: 7640,
    cnt1: 8.403141,
    cnt2: 8.403141,
    cnt3: 8.403141,
    cnt4: 8.403141,
  },
  {
    maesu: 7650,
    cnt1: 8.405229,
    cnt2: 8.405229,
    cnt3: 8.405229,
    cnt4: 8.405229,
  },
  {
    maesu: 7660,
    cnt1: 8.407311,
    cnt2: 8.407311,
    cnt3: 8.407311,
    cnt4: 8.407311,
  },
  {
    maesu: 7670,
    cnt1: 8.409387,
    cnt2: 8.409387,
    cnt3: 8.409387,
    cnt4: 8.409387,
  },
  {
    maesu: 7680,
    cnt1: 8.411458,
    cnt2: 8.411458,
    cnt3: 8.411458,
    cnt4: 8.411458,
  },
  {
    maesu: 7690,
    cnt1: 8.40052,
    cnt2: 8.40052,
    cnt3: 8.40052,
    cnt4: 8.40052,
  },
  {
    maesu: 7700,
    cnt1: 8.402597,
    cnt2: 8.402597,
    cnt3: 8.402597,
    cnt4: 8.402597,
  },
  {
    maesu: 7710,
    cnt1: 8.404669,
    cnt2: 8.404669,
    cnt3: 8.404669,
    cnt4: 8.404669,
  },
  {
    maesu: 7720,
    cnt1: 8.406736,
    cnt2: 8.406736,
    cnt3: 8.406736,
    cnt4: 8.406736,
  },
  {
    maesu: 7730,
    cnt1: 8.408797,
    cnt2: 8.408797,
    cnt3: 8.408797,
    cnt4: 8.408797,
  },
  {
    maesu: 7740,
    cnt1: 8.410853,
    cnt2: 8.410853,
    cnt3: 8.410853,
    cnt4: 8.410853,
  },
  {
    maesu: 7750,
    cnt1: 8.4,
    cnt2: 8.4,
    cnt3: 8.4,
    cnt4: 8.4,
  },
  {
    maesu: 7760,
    cnt1: 8.402062,
    cnt2: 8.402062,
    cnt3: 8.402062,
    cnt4: 8.402062,
  },
  {
    maesu: 7770,
    cnt1: 8.404118,
    cnt2: 8.404118,
    cnt3: 8.404118,
    cnt4: 8.404118,
  },
  {
    maesu: 7780,
    cnt1: 8.40617,
    cnt2: 8.40617,
    cnt3: 8.40617,
    cnt4: 8.40617,
  },
  {
    maesu: 7790,
    cnt1: 8.408216,
    cnt2: 8.408216,
    cnt3: 8.408216,
    cnt4: 8.408216,
  },
  {
    maesu: 7800,
    cnt1: 8.410256,
    cnt2: 8.410256,
    cnt3: 8.410256,
    cnt4: 8.410256,
  },
  {
    maesu: 7810,
    cnt1: 8.412292,
    cnt2: 8.412292,
    cnt3: 8.412292,
    cnt4: 8.412292,
  },
  {
    maesu: 7820,
    cnt1: 8.401535,
    cnt2: 8.401535,
    cnt3: 8.401535,
    cnt4: 8.401535,
  },
  {
    maesu: 7830,
    cnt1: 8.403576,
    cnt2: 8.403576,
    cnt3: 8.403576,
    cnt4: 8.403576,
  },
  {
    maesu: 7840,
    cnt1: 8.405612,
    cnt2: 8.405612,
    cnt3: 8.405612,
    cnt4: 8.405612,
  },
  {
    maesu: 7850,
    cnt1: 8.407643,
    cnt2: 8.407643,
    cnt3: 8.407643,
    cnt4: 8.407643,
  },
  {
    maesu: 7860,
    cnt1: 8.409669,
    cnt2: 8.409669,
    cnt3: 8.409669,
    cnt4: 8.409669,
  },
  {
    maesu: 7870,
    cnt1: 8.41169,
    cnt2: 8.41169,
    cnt3: 8.41169,
    cnt4: 8.41169,
  },
  {
    maesu: 7880,
    cnt1: 8.401015,
    cnt2: 8.401015,
    cnt3: 8.401015,
    cnt4: 8.401015,
  },
  {
    maesu: 7890,
    cnt1: 8.403042,
    cnt2: 8.403042,
    cnt3: 8.403042,
    cnt4: 8.403042,
  },
  {
    maesu: 7900,
    cnt1: 8.405063,
    cnt2: 8.405063,
    cnt3: 8.405063,
    cnt4: 8.405063,
  },
  {
    maesu: 7910,
    cnt1: 8.40708,
    cnt2: 8.40708,
    cnt3: 8.40708,
    cnt4: 8.40708,
  },
  {
    maesu: 7920,
    cnt1: 8.409091,
    cnt2: 8.409091,
    cnt3: 8.409091,
    cnt4: 8.409091,
  },
  {
    maesu: 7930,
    cnt1: 8.411097,
    cnt2: 8.411097,
    cnt3: 8.411097,
    cnt4: 8.411097,
  },
  {
    maesu: 7940,
    cnt1: 8.400504,
    cnt2: 8.400504,
    cnt3: 8.400504,
    cnt4: 8.400504,
  },
  {
    maesu: 7950,
    cnt1: 8.402516,
    cnt2: 8.402516,
    cnt3: 8.402516,
    cnt4: 8.402516,
  },
  {
    maesu: 7960,
    cnt1: 8.404523,
    cnt2: 8.404523,
    cnt3: 8.404523,
    cnt4: 8.404523,
  },
  {
    maesu: 7970,
    cnt1: 8.406524,
    cnt2: 8.406524,
    cnt3: 8.406524,
    cnt4: 8.406524,
  },
  {
    maesu: 7980,
    cnt1: 8.408521,
    cnt2: 8.408521,
    cnt3: 8.408521,
    cnt4: 8.408521,
  },
  {
    maesu: 7990,
    cnt1: 8.410513,
    cnt2: 8.410513,
    cnt3: 8.410513,
    cnt4: 8.410513,
  },
  {
    maesu: 8000,
    cnt1: 8.4,
    cnt2: 8.4,
    cnt3: 8.4,
    cnt4: 8.4,
  },
];
// 귀도리 단가
export const RoundingPrice = [
  {
    maesu: 100,
    price: 2000,
  },
  {
    maesu: 200,
    price: 2000,
  },
  {
    maesu: 300,
    price: 2000,
  },
  {
    maesu: 400,
    price: 2000,
  },
  {
    maesu: 500,
    price: 3000,
  },
  {
    maesu: 600,
    price: 3000,
  },
  {
    maesu: 700,
    price: 4000,
  },
  {
    maesu: 800,
    price: 5000,
  },
  {
    maesu: 900,
    price: 5000,
  },
  {
    maesu: 1000,
    price: 6000,
  },
  {
    maesu: 1200,
    price: 7000,
  },
  {
    maesu: 1400,
    price: 8000,
  },
  {
    maesu: 1600,
    price: 9000,
  },
  {
    maesu: 1800,
    price: 10000,
  },
  {
    maesu: 2000,
    price: 11000,
  },
  {
    maesu: 2200,
    price: 12000,
  },
  {
    maesu: 2400,
    price: 13000,
  },
  {
    maesu: 2600,
    price: 14000,
  },
  {
    maesu: 2800,
    price: 14000,
  },
  {
    maesu: 3000,
    price: 15000,
  },
  {
    maesu: 3200,
    price: 16000,
  },
  {
    maesu: 3400,
    price: 17000,
  },
  {
    maesu: 3600,
    price: 18000,
  },
  {
    maesu: 3800,
    price: 18000,
  },
  {
    maesu: 4000,
    price: 19000,
  },
  {
    maesu: 4200,
    price: 20000,
  },
  {
    maesu: 4400,
    price: 21000,
  },
  {
    maesu: 4600,
    price: 21000,
  },
  {
    maesu: 4800,
    price: 22000,
  },
  {
    maesu: 5000,
    price: 23000,
  },
  {
    maesu: 5200,
    price: 23000,
  },
  {
    maesu: 5400,
    price: 24000,
  },
  {
    maesu: 5600,
    price: 24000,
  },
  {
    maesu: 5800,
    price: 25000,
  },
  {
    maesu: 6000,
    price: 25000,
  },
  {
    maesu: 6200,
    price: 26000,
  },
  {
    maesu: 6400,
    price: 27000,
  },
  {
    maesu: 6600,
    price: 28000,
  },
  {
    maesu: 6800,
    price: 29000,
  },
  {
    maesu: 7000,
    price: 29000,
  },
  {
    maesu: 7200,
    price: 30000,
  },
  {
    maesu: 7400,
    price: 31000,
  },
  {
    maesu: 7600,
    price: 32000,
  },
  {
    maesu: 7800,
    price: 33000,
  },
  {
    maesu: 8000,
    price: 34000,
  },
  {
    maesu: 8200,
    price: 34000,
  },
  {
    maesu: 8400,
    price: 35000,
  },
  {
    maesu: 8600,
    price: 36000,
  },
  {
    maesu: 8800,
    price: 37000,
  },
  {
    maesu: 9000,
    price: 38000,
  },
  {
    maesu: 9200,
    price: 39000,
  },
  {
    maesu: 9400,
    price: 39000,
  },
  {
    maesu: 9600,
    price: 40000,
  },
  {
    maesu: 9800,
    price: 41000,
  },
  {
    maesu: 10000,
    price: 42000,
  },
];

//오시 명함단가
export const OsiNameCardPrice = [
  {
    maesu: 100,
    line1: 3000,
    line2: 3000,
    line3: 8000,
  },
  {
    maesu: 200,
    line1: 3000,
    line2: 3000,
    line3: 8000,
  },
  {
    maesu: 300,
    line1: 3000,
    line2: 3000,
    line3: 8000,
  },
  {
    maesu: 400,
    line1: 3000,
    line2: 3000,
    line3: 9000,
  },
  {
    maesu: 500,
    line1: 4000,
    line2: 4000,
    line3: 10000,
  },
  {
    maesu: 600,
    line1: 5000,
    line2: 5000,
    line3: 11000,
  },
  {
    maesu: 700,
    line1: 6000,
    line2: 6000,
    line3: 13000,
  },
  {
    maesu: 800,
    line1: 8000,
    line2: 8000,
    line3: 15000,
  },
  {
    maesu: 900,
    line1: 10000,
    line2: 10000,
    line3: 17000,
  },
  {
    maesu: 1000,
    line1: 10000,
    line2: 10000,
    line3: 19000,
  },
  {
    maesu: 1200,
    line1: 11000,
    line2: 11000,
    line3: 21000,
  },
  {
    maesu: 1400,
    line1: 12000,
    line2: 12000,
    line3: 22000,
  },
  {
    maesu: 1600,
    line1: 13000,
    line2: 13000,
    line3: 24000,
  },
  {
    maesu: 1800,
    line1: 14000,
    line2: 14000,
    line3: 26000,
  },
  {
    maesu: 2000,
    line1: 15000,
    line2: 15000,
    line3: 27000,
  },
  {
    maesu: 2200,
    line1: 16000,
    line2: 16000,
    line3: 29000,
  },
  {
    maesu: 2400,
    line1: 17000,
    line2: 17000,
    line3: 30000,
  },
  {
    maesu: 2600,
    line1: 18000,
    line2: 18000,
    line3: 32000,
  },
  {
    maesu: 2800,
    line1: 19000,
    line2: 19000,
    line3: 33000,
  },
  {
    maesu: 3000,
    line1: 20000,
    line2: 20000,
    line3: 34000,
  },
  {
    maesu: 3200,
    line1: 21000,
    line2: 21000,
    line3: 36000,
  },
  {
    maesu: 3400,
    line1: 22000,
    line2: 22000,
    line3: 37000,
  },
  {
    maesu: 3600,
    line1: 23000,
    line2: 23000,
    line3: 38000,
  },
  {
    maesu: 3800,
    line1: 24000,
    line2: 24000,
    line3: 39000,
  },
  {
    maesu: 4000,
    line1: 25000,
    line2: 25000,
    line3: 40000,
  },
  {
    maesu: 4200,
    line1: 25000,
    line2: 25000,
    line3: 42000,
  },
  {
    maesu: 4400,
    line1: 26000,
    line2: 26000,
    line3: 43000,
  },
  {
    maesu: 4600,
    line1: 27000,
    line2: 27000,
    line3: 44000,
  },
  {
    maesu: 4800,
    line1: 27000,
    line2: 27000,
    line3: 45000,
  },
  {
    maesu: 5000,
    line1: 28000,
    line2: 28000,
    line3: 46000,
  },
  {
    maesu: 5200,
    line1: 29000,
    line2: 29000,
    line3: 46000,
  },
  {
    maesu: 5400,
    line1: 29000,
    line2: 29000,
    line3: 47000,
  },
  {
    maesu: 5600,
    line1: 30000,
    line2: 30000,
    line3: 48000,
  },
  {
    maesu: 5800,
    line1: 30000,
    line2: 30000,
    line3: 49000,
  },
  {
    maesu: 6000,
    line1: 31000,
    line2: 31000,
    line3: 50000,
  },
  {
    maesu: 6200,
    line1: 31000,
    line2: 31000,
    line3: 50000,
  },
  {
    maesu: 6400,
    line1: 32000,
    line2: 32000,
    line3: 51000,
  },
  {
    maesu: 6600,
    line1: 32000,
    line2: 32000,
    line3: 52000,
  },
  {
    maesu: 6800,
    line1: 33000,
    line2: 33000,
    line3: 52000,
  },
  {
    maesu: 7000,
    line1: 33000,
    line2: 33000,
    line3: 53000,
  },
  {
    maesu: 7200,
    line1: 33000,
    line2: 33000,
    line3: 53000,
  },
  {
    maesu: 7400,
    line1: 34000,
    line2: 34000,
    line3: 54000,
  },
  {
    maesu: 7600,
    line1: 34000,
    line2: 34000,
    line3: 54000,
  },
  {
    maesu: 7800,
    line1: 35000,
    line2: 35000,
    line3: 55000,
  },
  {
    maesu: 8000,
    line1: 36000,
    line2: 36000,
    line3: 56000,
  },
  {
    maesu: 8200,
    line1: 36000,
    line2: 36000,
    line3: 58000,
  },
  {
    maesu: 8400,
    line1: 37000,
    line2: 37000,
    line3: 59000,
  },
  {
    maesu: 8600,
    line1: 37000,
    line2: 37000,
    line3: 60000,
  },
  {
    maesu: 8800,
    line1: 38000,
    line2: 38000,
    line3: 61000,
  },
  {
    maesu: 9000,
    line1: 39000,
    line2: 39000,
    line3: 63000,
  },
  {
    maesu: 9200,
    line1: 40000,
    line2: 40000,
    line3: 64000,
  },
  {
    maesu: 9400,
    line1: 40000,
    line2: 40000,
    line3: 64000,
  },
  {
    maesu: 9600,
    line1: 41000,
    line2: 41000,
    line3: 65000,
  },
  {
    maesu: 9800,
    line1: 41000,
    line2: 41000,
    line3: 65000,
  },
  {
    maesu: 10000,
    line1: 42000,
    line2: 42000,
    line3: 66000,
  },
];

//미싱 단가
export const MissingPrice1 = [
  {
    maesu: 1,
    b2: 10000,
    a2: 9000,
    a3: 5000,
    a4: 3500,
    a5: 2500,
    a6: 2500,
    b3: 7500,
    b4: 5000,
    b5: 3500,
    b6: 2500,
  },
  {
    maesu: 2,
    b2: 5000,
    a2: 4500,
    a3: 2500,
    a4: 1750,
    a5: 1250,
    a6: 1250,
    b3: 3750,
    b4: 2500,
    b5: 1750,
    b6: 1250,
  },
  {
    maesu: 3,
    b2: 3333,
    a2: 2999.7,
    a3: 1666.66667,
    a4: 1166.66667,
    a5: 833.33333,
    a6: 833.33333,
    b3: 2499.75,
    b4: 1666.66667,
    b5: 1166.66667,
    b6: 833.33333,
  },
  {
    maesu: 4,
    b2: 2500,
    a2: 2250,
    a3: 1250,
    a4: 875,
    a5: 625,
    a6: 625,
    b3: 1875,
    b4: 1250,
    b5: 875,
    b6: 625,
  },
  {
    maesu: 5,
    b2: 2000,
    a2: 1800,
    a3: 1000,
    a4: 700,
    a5: 500,
    a6: 500,
    b3: 1500,
    b4: 1000,
    b5: 700,
    b6: 500,
  },
  {
    maesu: 8,
    b2: 1250,
    a2: 1125,
    a3: 625,
    a4: 437.5,
    a5: 312.5,
    a6: 312.5,
    b3: 937.5,
    b4: 625,
    b5: 437.5,
    b6: 312.5,
  },
  {
    maesu: 10,
    b2: 1000,
    a2: 900,
    a3: 500,
    a4: 350,
    a5: 250,
    a6: 250,
    b3: 750,
    b4: 500,
    b5: 350,
    b6: 250,
  },
  {
    maesu: 15,
    b2: 800,
    a2: 720,
    a3: 400,
    a4: 280,
    a5: 200,
    a6: 180,
    b3: 600,
    b4: 400,
    b5: 280,
    b6: 200,
  },
  {
    maesu: 20,
    b2: 600,
    a2: 540,
    a3: 300,
    a4: 210,
    a5: 150,
    a6: 135,
    b3: 450,
    b4: 300,
    b5: 210,
    b6: 150,
  },
  {
    maesu: 25,
    b2: 560,
    a2: 504,
    a3: 280,
    a4: 196,
    a5: 140,
    a6: 128,
    b3: 420,
    b4: 280,
    b5: 196,
    b6: 140,
  },
  {
    maesu: 30,
    b2: 500,
    a2: 450,
    a3: 250,
    a4: 175,
    a5: 125,
    a6: 113.33333,
    b3: 375,
    b4: 250,
    b5: 8,
    b6: 125,
  },
  {
    maesu: 35,
    b2: 486,
    a2: 437.4,
    a3: 228.57143,
    a4: 160,
    a5: 114.28571,
    a6: 102.85714,
    b3: 364.5,
    b4: 228.57143,
    b5: 160,
    b6: 114.28571,
  },
  {
    maesu: 40,
    b2: 450,
    a2: 405,
    a3: 225,
    a4: 157.5,
    a5: 112.5,
    a6: 102.5,
    b3: 337.5,
    b4: 225,
    b5: 157.5,
    b6: 112.5,
  },
  {
    maesu: 45,
    b2: 444,
    a2: 399.6,
    a3: 200,
    a4: 140,
    a5: 100,
    a6: 91.11111,
    b3: 333,
    b4: 200,
    b5: 140,
    b6: 100,
  },
  {
    maesu: 50,
    b2: 420,
    a2: 378,
    a3: 200,
    a4: 140,
    a5: 100,
    a6: 90,
    b3: 315,
    b4: 200,
    b5: 140,
    b6: 100,
  },
  {
    maesu: 55,
    b2: 382,
    a2: 343.8,
    a3: 181.81818,
    a4: 127.27273,
    a5: 90.90909,
    a6: 81.81818,
    b3: 286.5,
    b4: 181.81818,
    b5: 127.27273,
    b6: 90.90909,
  },
  {
    maesu: 60,
    b2: 383,
    a2: 344.7,
    a3: 166.66667,
    a4: 116.66667,
    a5: 83.33333,
    a6: 75,
    b3: 287.25,
    b4: 166.66667,
    b5: 116.66667,
    b6: 83.33333,
  },
  {
    maesu: 65,
    b2: 354,
    a2: 318.6,
    a3: 153.84615,
    a4: 107.69231,
    a5: 76.92308,
    a6: 69.23077,
    b3: 265.5,
    b4: 153.84615,
    b5: 107.69231,
    b6: 76.92308,
  },
  {
    maesu: 70,
    b2: 371,
    a2: 333.9,
    a3: 157.14286,
    a4: 110,
    a5: 78.57143,
    a6: 71.42857,
    b3: 278.25,
    b4: 157.14286,
    b5: 110,
    b6: 78.57143,
  },
  {
    maesu: 75,
    b2: 347,
    a2: 312.3,
    a3: 146.66667,
    a4: 102.66667,
    a5: 73.33333,
    a6: 66.66667,
    b3: 260.25,
    b4: 146.66667,
    b5: 102.66667,
    b6: 73.33333,
  },
  {
    maesu: 80,
    b2: 338,
    a2: 304.2,
    a3: 150,
    a4: 105,
    a5: 75,
    a6: 67.5,
    b3: 253.5,
    b4: 150,
    b5: 105,
    b6: 75,
  },
  {
    maesu: 85,
    b2: 318,
    a2: 286.2,
    a3: 141.17647,
    a4: 98.82353,
    a5: 70.58824,
    a6: 63.52941,
    b3: 238.5,
    b4: 141.17647,
    b5: 98.82353,
    b6: 70.58824,
  },
  {
    maesu: 90,
    b2: 322,
    a2: 289.8,
    a3: 144.44444,
    a4: 101.11111,
    a5: 72.22222,
    a6: 65.55556,
    b3: 241.5,
    b4: 144.44444,
    b5: 101.11111,
    b6: 72.22222,
  },
  {
    maesu: 95,
    b2: 305,
    a2: 274.5,
    a3: 136.84211,
    a4: 95.78947,
    a5: 68.42105,
    a6: 62.10526,
    b3: 228.75,
    b4: 136.84211,
    b5: 95.78947,
    b6: 68.42105,
  },
  {
    maesu: 100,
    b2: 300,
    a2: 270,
    a3: 130,
    a4: 91,
    a5: 65,
    a6: 59,
    b3: 225,
    b4: 130,
    b5: 91,
    b6: 65,
  },
  {
    maesu: 110,
    b2: 282,
    a2: 253.8,
    a3: 118.18182,
    a4: 82.72727,
    a5: 59.09091,
    a6: 53.63636,
    b3: 211.5,
    b4: 118.18182,
    b5: 82.72727,
    b6: 59.09091,
  },
  {
    maesu: 120,
    b2: 267,
    a2: 240.3,
    a3: 108.33333,
    a4: 75.83333,
    a5: 54.16667,
    a6: 49.16667,
    b3: 200.25,
    b4: 108.33333,
    b5: 75.83333,
    b6: 54.16667,
  },
  {
    maesu: 130,
    b2: 254,
    a2: 228.6,
    a3: 100,
    a4: 70,
    a5: 50,
    a6: 45.38462,
    b3: 190.5,
    b4: 100,
    b5: 70,
    b6: 50,
  },
  {
    maesu: 140,
    b2: 236,
    a2: 212.4,
    a3: 92.85714,
    a4: 65,
    a5: 46.42857,
    a6: 42.14286,
    b3: 177,
    b4: 92.85714,
    b5: 65,
    b6: 46.42857,
  },
  {
    maesu: 150,
    b2: 227,
    a2: 204.3,
    a3: 86.66667,
    a4: 60.66667,
    a5: 43.33333,
    a6: 39.33333,
    b3: 170.25,
    b4: 86.66667,
    b5: 60.66667,
    b6: 43.33333,
  },
  {
    maesu: 160,
    b2: 219,
    a2: 197.1,
    a3: 81.25,
    a4: 56.875,
    a5: 40.625,
    a6: 36.875,
    b3: 164.25,
    b4: 81.25,
    b5: 56.875,
    b6: 40.625,
  },
  {
    maesu: 170,
    b2: 212,
    a2: 190.8,
    a3: 76.47059,
    a4: 53.52941,
    a5: 38.23529,
    a6: 34.70588,
    b3: 159,
    b4: 76.47059,
    b5: 53.52941,
    b6: 38.23529,
  },
  {
    maesu: 180,
    b2: 200,
    a2: 180,
    a3: 72.22222,
    a4: 50.55556,
    a5: 36.11111,
    a6: 32.77778,
    b3: 150,
    b4: 72.22222,
    b5: 50.55556,
    b6: 36.11111,
  },
  {
    maesu: 190,
    b2: 195,
    a2: 175.5,
    a3: 68.42105,
    a4: 47.89474,
    a5: 34.21053,
    a6: 31.05263,
    b3: 146.25,
    b4: 68.42105,
    b5: 47.89474,
    b6: 34.21053,
  },
  {
    maesu: 200,
    b2: 190,
    a2: 171,
    a3: 70,
    a4: 49,
    a5: 35,
    a6: 31.5,
    b3: 142.5,
    b4: 70,
    b5: 49,
    b6: 35,
  },
  {
    maesu: 210,
    b2: 186,
    a2: 167.4,
    a3: 66.66667,
    a4: 46.66667,
    a5: 33.33333,
    a6: 30,
    b3: 139.5,
    b4: 66.66667,
    b5: 46.66667,
    b6: 33.33333,
  },
  {
    maesu: 220,
    b2: 177,
    a2: 159.3,
    a3: 63.63636,
    a4: 44.54545,
    a5: 31.81818,
    a6: 28.63636,
    b3: 132.75,
    b4: 63.63636,
    b5: 44.54545,
    b6: 31.81818,
  },
  {
    maesu: 230,
    b2: 174,
    a2: 156.6,
    a3: 60.86957,
    a4: 42.6087,
    a5: 30.43478,
    a6: 27.3913,
    b3: 130.5,
    b4: 60.86957,
    b5: 42.6087,
    b6: 30.43478,
  },
  {
    maesu: 240,
    b2: 171,
    a2: 153.9,
    a3: 58.33333,
    a4: 40.83333,
    a5: 29.16667,
    a6: 26.25,
    b3: 128.25,
    b4: 58.33333,
    b5: 40.83333,
    b6: 29.16667,
  },
  {
    maesu: 250,
    b2: 168,
    a2: 151.2,
    a3: 56,
    a4: 39.2,
    a5: 28,
    a6: 25.2,
    b3: 126,
    b4: 56,
    b5: 39.2,
    b6: 28,
  },
  {
    maesu: 260,
    b2: 162,
    a2: 145.8,
    a3: 53.84615,
    a4: 37.69231,
    a5: 26.92308,
    a6: 24.23077,
    b3: 121.5,
    b4: 53.84615,
    b5: 37.69231,
    b6: 26.92308,
  },
  {
    maesu: 270,
    b2: 159,
    a2: 143.1,
    a3: 51.85185,
    a4: 36.2963,
    a5: 25.92593,
    a6: 23.33333,
    b3: 119.25,
    b4: 51.85185,
    b5: 36.2963,
    b6: 25.92593,
  },
  {
    maesu: 280,
    b2: 157,
    a2: 141.3,
    a3: 50,
    a4: 35,
    a5: 25,
    a6: 22.5,
    b3: 117.75,
    b4: 50,
    b5: 35,
    b6: 25,
  },
  {
    maesu: 290,
    b2: 155,
    a2: 139.5,
    a3: 48.27586,
    a4: 33.7931,
    a5: 24.13793,
    a6: 21.72414,
    b3: 116.25,
    b4: 48.27586,
    b5: 33.7931,
    b6: 24.13793,
  },
  {
    maesu: 300,
    b2: 153,
    a2: 137.7,
    a3: 50,
    a4: 35,
    a5: 25,
    a6: 22.66667,
    b3: 114.75,
    b4: 50,
    b5: 35,
    b6: 25,
  },
  {
    maesu: 310,
    b2: 152,
    a2: 136.8,
    a3: 48.3871,
    a4: 33.87097,
    a5: 24.19355,
    a6: 21.93548,
    b3: 114,
    b4: 48.3871,
    b5: 33.87097,
    b6: 24.19355,
  },
  {
    maesu: 320,
    b2: 150,
    a2: 135,
    a3: 46.875,
    a4: 32.8125,
    a5: 23.4375,
    a6: 21.25,
    b3: 112.5,
    b4: 46.875,
    b5: 32.8125,
    b6: 23.4375,
  },
  {
    maesu: 330,
    b2: 152,
    a2: 136.8,
    a3: 45.45455,
    a4: 31.81818,
    a5: 22.72727,
    a6: 20.60606,
    b3: 114,
    b4: 45.45455,
    b5: 31.81818,
    b6: 22.72727,
  },
  {
    maesu: 340,
    b2: 150,
    a2: 135,
    a3: 44.11765,
    a4: 30.88235,
    a5: 22.05882,
    a6: 20,
    b3: 112.5,
    b4: 44.11765,
    b5: 30.88235,
    b6: 22.05882,
  },
  {
    maesu: 350,
    b2: 151,
    a2: 135.9,
    a3: 42.85714,
    a4: 30,
    a5: 21.42857,
    a6: 19.42857,
    b3: 113.25,
    b4: 42.85714,
    b5: 30,
    b6: 21.42857,
  },
  {
    maesu: 360,
    b2: 150,
    a2: 135,
    a3: 41.66667,
    a4: 29.16667,
    a5: 20.83333,
    a6: 18.88889,
    b3: 112.5,
    b4: 41.66667,
    b5: 29.16667,
    b6: 20.83333,
  },
  {
    maesu: 370,
    b2: 151,
    a2: 135.9,
    a3: 40.54054,
    a4: 28.37838,
    a5: 20.27027,
    a6: 18.37838,
    b3: 113.25,
    b4: 40.54054,
    b5: 28.37838,
    b6: 20.27027,
  },
  {
    maesu: 380,
    b2: 150,
    a2: 135,
    a3: 39.47368,
    a4: 27.63158,
    a5: 19.73684,
    a6: 17.89474,
    b3: 112.5,
    b4: 39.47368,
    b5: 27.63158,
    b6: 19.73684,
  },
  {
    maesu: 390,
    b2: 151,
    a2: 135.9,
    a3: 38.46154,
    a4: 26.92308,
    a5: 19.23077,
    a6: 17.4359,
    b3: 113.25,
    b4: 38.46154,
    b5: 26.92308,
    b6: 19.23077,
  },
  {
    maesu: 400,
    b2: 150,
    a2: 135,
    a3: 37.5,
    a4: 26.25,
    a5: 18.75,
    a6: 17,
    b3: 112.5,
    b4: 37.5,
    b5: 26.25,
    b6: 18.75,
  },
  {
    maesu: 410,
    b2: 151,
    a2: 135.9,
    a3: 39.02439,
    a4: 27.31707,
    a5: 19.5122,
    a6: 17.56098,
    b3: 113.25,
    b4: 39.02439,
    b5: 27.31707,
    b6: 19.5122,
  },
  {
    maesu: 420,
    b2: 150,
    a2: 135,
    a3: 38.09524,
    a4: 26.66667,
    a5: 19.04762,
    a6: 17.14286,
    b3: 112.5,
    b4: 38.09524,
    b5: 26.66667,
    b6: 19.04762,
  },
  {
    maesu: 430,
    b2: 151,
    a2: 135.9,
    a3: 37.2093,
    a4: 26.04651,
    a5: 18.60465,
    a6: 16.74419,
    b3: 113.25,
    b4: 37.2093,
    b5: 26.04651,
    b6: 18.60465,
  },
  {
    maesu: 440,
    b2: 150,
    a2: 135,
    a3: 36.36364,
    a4: 25.45455,
    a5: 18.18182,
    a6: 16.36364,
    b3: 112.5,
    b4: 36.36364,
    b5: 25.45455,
    b6: 18.18182,
  },
  {
    maesu: 450,
    b2: 151,
    a2: 135.9,
    a3: 35.55556,
    a4: 24.88889,
    a5: 17.77778,
    a6: 16,
    b3: 113.25,
    b4: 35.55556,
    b5: 24.88889,
    b6: 17.77778,
  },
  {
    maesu: 460,
    b2: 150,
    a2: 135,
    a3: 34.78261,
    a4: 24.34783,
    a5: 17.3913,
    a6: 15.65217,
    b3: 112.5,
    b4: 34.78261,
    b5: 24.34783,
    b6: 17.3913,
  },
  {
    maesu: 470,
    b2: 151,
    a2: 135.9,
    a3: 34.04255,
    a4: 23.82979,
    a5: 17.02128,
    a6: 15.31915,
    b3: 113.25,
    b4: 34.04255,
    b5: 23.82979,
    b6: 17.02128,
  },
  {
    maesu: 480,
    b2: 150,
    a2: 135,
    a3: 33.33333,
    a4: 23.33333,
    a5: 16.66667,
    a6: 15,
    b3: 112.5,
    b4: 33.33333,
    b5: 23.33333,
    b6: 16.66667,
  },
  {
    maesu: 490,
    b2: 151,
    a2: 135.9,
    a3: 32.65306,
    a4: 22.85714,
    a5: 16.32653,
    a6: 14.69388,
    b3: 113.25,
    b4: 32.65306,
    b5: 22.85714,
    b6: 16.32653,
  },
  {
    maesu: 500,
    b2: 148,
    a2: 133.2,
    a3: 32,
    a4: 22.4,
    a5: 16,
    a6: 14.4,
    b3: 111,
    b4: 32,
    b5: 22.4,
    b6: 16,
  },
  {
    maesu: 510,
    b2: 148,
    a2: 133.2,
    a3: 31.37255,
    a4: 21.96078,
    a5: 15.68627,
    a6: 14.11765,
    b3: 111,
    b4: 31.37255,
    b5: 21.96078,
    b6: 15.68627,
  },
  {
    maesu: 520,
    b2: 148,
    a2: 133.2,
    a3: 30.76923,
    a4: 21.53846,
    a5: 15.38462,
    a6: 13.84615,
    b3: 111,
    b4: 30.76923,
    b5: 21.53846,
    b6: 15.38462,
  },
  {
    maesu: 530,
    b2: 148,
    a2: 133.2,
    a3: 30.18868,
    a4: 21.13208,
    a5: 15.09434,
    a6: 13.58491,
    b3: 111,
    b4: 30.18868,
    b5: 21.13208,
    b6: 15.09434,
  },
  {
    maesu: 540,
    b2: 148,
    a2: 133.2,
    a3: 29.62963,
    a4: 20.74074,
    a5: 14.81481,
    a6: 13.33333,
    b3: 111,
    b4: 29.62963,
    b5: 20.74074,
    b6: 14.81481,
  },
  {
    maesu: 550,
    b2: 148,
    a2: 133.2,
    a3: 29.09091,
    a4: 20.36364,
    a5: 14.54545,
    a6: 13.09091,
    b3: 111,
    b4: 29.09091,
    b5: 20.36364,
    b6: 14.54545,
  },
  {
    maesu: 560,
    b2: 148,
    a2: 133.2,
    a3: 28.57143,
    a4: 20,
    a5: 14.28571,
    a6: 12.85714,
    b3: 111,
    b4: 28.57143,
    b5: 20,
    b6: 14.28571,
  },
  {
    maesu: 570,
    b2: 148,
    a2: 133.2,
    a3: 28.07018,
    a4: 19.64912,
    a5: 14.03509,
    a6: 12.63158,
    b3: 111,
    b4: 28.07018,
    b5: 19.64912,
    b6: 14.03509,
  },
  {
    maesu: 580,
    b2: 148,
    a2: 133.2,
    a3: 27.58621,
    a4: 19.31034,
    a5: 13.7931,
    a6: 12.41379,
    b3: 111,
    b4: 27.58621,
    b5: 19.31034,
    b6: 13.7931,
  },
  {
    maesu: 590,
    b2: 148,
    a2: 133.2,
    a3: 27.11864,
    a4: 18.98305,
    a5: 13.55932,
    a6: 12.20339,
    b3: 111,
    b4: 27.11864,
    b5: 18.98305,
    b6: 13.55932,
  },
  {
    maesu: 600,
    b2: 148,
    a2: 133.2,
    a3: 26.66667,
    a4: 18.66667,
    a5: 13.33333,
    a6: 12,
    b3: 111,
    b4: 26.66667,
    b5: 18.66667,
    b6: 13.33333,
  },
  {
    maesu: 610,
    b2: 148,
    a2: 133.2,
    a3: 29.5082,
    a4: 20.65574,
    a5: 14.7541,
    a6: 13.27869,
    b3: 111,
    b4: 29.5082,
    b5: 20.65574,
    b6: 14.7541,
  },
  {
    maesu: 620,
    b2: 148,
    a2: 133.2,
    a3: 29.03226,
    a4: 20.32258,
    a5: 14.51613,
    a6: 13.06452,
    b3: 111,
    b4: 29.03226,
    b5: 20.32258,
    b6: 14.51613,
  },
  {
    maesu: 630,
    b2: 148,
    a2: 133.2,
    a3: 28.57143,
    a4: 20,
    a5: 14.28571,
    a6: 12.85714,
    b3: 111,
    b4: 28.57143,
    b5: 20,
    b6: 14.28571,
  },
  {
    maesu: 640,
    b2: 148,
    a2: 133.2,
    a3: 28.125,
    a4: 19.6875,
    a5: 14.0625,
    a6: 12.65625,
    b3: 111,
    b4: 28.125,
    b5: 19.6875,
    b6: 14.0625,
  },
  {
    maesu: 650,
    b2: 148,
    a2: 133.2,
    a3: 27.69231,
    a4: 19.38462,
    a5: 13.84615,
    a6: 12.46154,
    b3: 111,
    b4: 27.69231,
    b5: 19.38462,
    b6: 13.84615,
  },
  {
    maesu: 660,
    b2: 148,
    a2: 133.2,
    a3: 27.27273,
    a4: 19.09091,
    a5: 13.63636,
    a6: 12.27273,
    b3: 111,
    b4: 27.27273,
    b5: 19.09091,
    b6: 13.63636,
  },
  {
    maesu: 670,
    b2: 148,
    a2: 133.2,
    a3: 26.86567,
    a4: 18.80597,
    a5: 13.43284,
    a6: 12.08955,
    b3: 111,
    b4: 26.86567,
    b5: 18.80597,
    b6: 13.43284,
  },
  {
    maesu: 680,
    b2: 148,
    a2: 133.2,
    a3: 26.47059,
    a4: 18.52941,
    a5: 13.23529,
    a6: 11.91176,
    b3: 111,
    b4: 26.47059,
    b5: 18.52941,
    b6: 13.23529,
  },
  {
    maesu: 690,
    b2: 148,
    a2: 133.2,
    a3: 26.08696,
    a4: 18.26087,
    a5: 13.04348,
    a6: 11.73913,
    b3: 111,
    b4: 26.08696,
    b5: 18.26087,
    b6: 13.04348,
  },
  {
    maesu: 700,
    b2: 148,
    a2: 133.2,
    a3: 25.71429,
    a4: 18,
    a5: 12.85714,
    a6: 11.57143,
    b3: 111,
    b4: 25.71429,
    b5: 18,
    b6: 12.85714,
  },
  {
    maesu: 710,
    b2: 148,
    a2: 133.2,
    a3: 25.35211,
    a4: 17.74648,
    a5: 12.67606,
    a6: 11.40845,
    b3: 111,
    b4: 25.35211,
    b5: 17.74648,
    b6: 12.67606,
  },
  {
    maesu: 720,
    b2: 148,
    a2: 133.2,
    a3: 25,
    a4: 17.5,
    a5: 12.5,
    a6: 11.25,
    b3: 111,
    b4: 25,
    b5: 17.5,
    b6: 12.5,
  },
  {
    maesu: 730,
    b2: 148,
    a2: 133.2,
    a3: 24.65753,
    a4: 17.26027,
    a5: 12.32877,
    a6: 11.09589,
    b3: 111,
    b4: 24.65753,
    b5: 17.26027,
    b6: 12.32877,
  },
  {
    maesu: 740,
    b2: 148,
    a2: 133.2,
    a3: 24.32432,
    a4: 17.02703,
    a5: 12.16216,
    a6: 10.94595,
    b3: 111,
    b4: 24.32432,
    b5: 17.02703,
    b6: 12.16216,
  },
  {
    maesu: 750,
    b2: 148,
    a2: 133.2,
    a3: 24,
    a4: 16.8,
    a5: 12,
    a6: 10.8,
    b3: 111,
    b4: 24,
    b5: 16.8,
    b6: 12,
  },
  {
    maesu: 760,
    b2: 148,
    a2: 133.2,
    a3: 23.68421,
    a4: 16.57895,
    a5: 11.84211,
    a6: 10.65789,
    b3: 111,
    b4: 23.68421,
    b5: 16.57895,
    b6: 11.84211,
  },
  {
    maesu: 770,
    b2: 148,
    a2: 133.2,
    a3: 23.37662,
    a4: 16.36364,
    a5: 11.68831,
    a6: 10.51948,
    b3: 111,
    b4: 23.37662,
    b5: 16.36364,
    b6: 11.68831,
  },
  {
    maesu: 780,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 16.15385,
    a5: 11.53846,
    a6: 10.38462,
    b3: 111,
    b4: 23.07692,
    b5: 16.15385,
    b6: 11.53846,
  },
  {
    maesu: 790,
    b2: 148,
    a2: 133.2,
    a3: 22.78481,
    a4: 15.94937,
    a5: 11.39241,
    a6: 10.25316,
    b3: 111,
    b4: 22.78481,
    b5: 15.94937,
    b6: 11.39241,
  },
  {
    maesu: 800,
    b2: 148,
    a2: 133.2,
    a3: 23.75,
    a4: 16.625,
    a5: 11.875,
    a6: 10.75,
    b3: 111,
    b4: 23.75,
    b5: 16.625,
    b6: 11.875,
  },
  {
    maesu: 810,
    b2: 148,
    a2: 133.2,
    a3: 24.69136,
    a4: 17.28395,
    a5: 12.34568,
    a6: 11.11111,
    b3: 111,
    b4: 24.69136,
    b5: 17.28395,
    b6: 12.34568,
  },
  {
    maesu: 820,
    b2: 148,
    a2: 133.2,
    a3: 24.39024,
    a4: 17.07317,
    a5: 12.19512,
    a6: 10.97561,
    b3: 111,
    b4: 24.39024,
    b5: 17.07317,
    b6: 12.19512,
  },
  {
    maesu: 830,
    b2: 148,
    a2: 133.2,
    a3: 24.09639,
    a4: 16.86747,
    a5: 12.04819,
    a6: 10.84337,
    b3: 111,
    b4: 24.09639,
    b5: 16.86747,
    b6: 12.04819,
  },
  {
    maesu: 840,
    b2: 148,
    a2: 133.2,
    a3: 23.80952,
    a4: 16.66667,
    a5: 11.90476,
    a6: 10.71429,
    b3: 111,
    b4: 23.80952,
    b5: 16.66667,
    b6: 11.90476,
  },
  {
    maesu: 850,
    b2: 148,
    a2: 133.2,
    a3: 23.52941,
    a4: 16.47059,
    a5: 11.76471,
    a6: 10.58824,
    b3: 111,
    b4: 23.52941,
    b5: 16.47059,
    b6: 11.76471,
  },
  {
    maesu: 860,
    b2: 148,
    a2: 133.2,
    a3: 23.25581,
    a4: 16.27907,
    a5: 11.62791,
    a6: 10.46512,
    b3: 111,
    b4: 23.25581,
    b5: 16.27907,
    b6: 11.62791,
  },
  {
    maesu: 870,
    b2: 148,
    a2: 133.2,
    a3: 22.98851,
    a4: 16.09195,
    a5: 11.49425,
    a6: 10.34483,
    b3: 111,
    b4: 22.98851,
    b5: 16.09195,
    b6: 11.49425,
  },
  {
    maesu: 880,
    b2: 148,
    a2: 133.2,
    a3: 22.72727,
    a4: 15.90909,
    a5: 11.36364,
    a6: 10.22727,
    b3: 111,
    b4: 22.72727,
    b5: 15.90909,
    b6: 11.36364,
  },
  {
    maesu: 890,
    b2: 148,
    a2: 133.2,
    a3: 22.47191,
    a4: 15.73034,
    a5: 11.23596,
    a6: 10.11236,
    b3: 111,
    b4: 22.47191,
    b5: 15.73034,
    b6: 11.23596,
  },
  {
    maesu: 900,
    b2: 148,
    a2: 133.2,
    a3: 23.33333,
    a4: 16.33333,
    a5: 11.66667,
    a6: 10.55556,
    b3: 111,
    b4: 23.33333,
    b5: 16.33333,
    b6: 11.66667,
  },
  {
    maesu: 910,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 16.15385,
    a5: 11.53846,
    a6: 10.43956,
    b3: 111,
    b4: 23.07692,
    b5: 16.15385,
    b6: 11.53846,
  },
  {
    maesu: 920,
    b2: 148,
    a2: 133.2,
    a3: 22.82609,
    a4: 15.97826,
    a5: 11.41304,
    a6: 10.32609,
    b3: 111,
    b4: 22.82609,
    b5: 15.97826,
    b6: 11.41304,
  },
  {
    maesu: 930,
    b2: 148,
    a2: 133.2,
    a3: 22.58065,
    a4: 15.80645,
    a5: 11.29032,
    a6: 10.21505,
    b3: 111,
    b4: 22.58065,
    b5: 15.80645,
    b6: 11.29032,
  },
  {
    maesu: 940,
    b2: 148,
    a2: 133.2,
    a3: 23.40426,
    a4: 16.38298,
    a5: 11.70213,
    a6: 10.53191,
    b3: 111,
    b4: 23.40426,
    b5: 16.38298,
    b6: 11.70213,
  },
  {
    maesu: 950,
    b2: 148,
    a2: 133.2,
    a3: 23.15789,
    a4: 16.21053,
    a5: 11.57895,
    a6: 10.42105,
    b3: 111,
    b4: 23.15789,
    b5: 16.21053,
    b6: 11.57895,
  },
  {
    maesu: 960,
    b2: 148,
    a2: 133.2,
    a3: 22.91667,
    a4: 16.04167,
    a5: 11.45833,
    a6: 10.3125,
    b3: 111,
    b4: 22.91667,
    b5: 16.04167,
    b6: 11.45833,
  },
  {
    maesu: 970,
    b2: 148,
    a2: 133.2,
    a3: 22.68041,
    a4: 15.87629,
    a5: 11.34021,
    a6: 10.20619,
    b3: 111,
    b4: 22.68041,
    b5: 15.87629,
    b6: 11.34021,
  },
  {
    maesu: 980,
    b2: 148,
    a2: 133.2,
    a3: 22.44898,
    a4: 15.71429,
    a5: 11.22449,
    a6: 10.10204,
    b3: 111,
    b4: 22.44898,
    b5: 15.71429,
    b6: 11.22449,
  },
  {
    maesu: 990,
    b2: 148,
    a2: 133.2,
    a3: 23.23232,
    a4: 16.26263,
    a5: 11.61616,
    a6: 10.10101,
    b3: 111,
    b4: 23.23232,
    b5: 16.26263,
    b6: 11.61616,
  },
  {
    maesu: 1000,
    b2: 148,
    a2: 133.2,
    a3: 23,
    a4: 16.1,
    a5: 11.5,
    a6: 10,
    b3: 111,
    b4: 23,
    b5: 16.1,
    b6: 11.5,
  },
  {
    maesu: 1010,
    b2: 148,
    a2: 133.2,
    a3: 22.77228,
    a4: 15.84158,
    a5: 11.38614,
    a6: 9.90099,
    b3: 111,
    b4: 22.77228,
    b5: 15.84158,
    b6: 11.38614,
  },
  {
    maesu: 1020,
    b2: 148,
    a2: 133.2,
    a3: 22.54902,
    a4: 15.68627,
    a5: 11.27451,
    a6: 9.80392,
    b3: 111,
    b4: 22.54902,
    b5: 15.68627,
    b6: 11.27451,
  },
  {
    maesu: 1030,
    b2: 148,
    a2: 133.2,
    a3: 23.30097,
    a4: 16.50485,
    a5: 11.16505,
    a6: 9.70874,
    b3: 111,
    b4: 23.30097,
    b5: 16.50485,
    b6: 11.16505,
  },
  {
    maesu: 1040,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 16.34615,
    a5: 11.53846,
    a6: 9.61538,
    b3: 111,
    b4: 23.07692,
    b5: 16.34615,
    b6: 11.53846,
  },
  {
    maesu: 1050,
    b2: 148,
    a2: 133.2,
    a3: 22.85714,
    a4: 16.19048,
    a5: 11.42857,
    a6: 9.52381,
    b3: 111,
    b4: 22.85714,
    b5: 16.19048,
    b6: 11.42857,
  },
  {
    maesu: 1060,
    b2: 148,
    a2: 133.2,
    a3: 22.64151,
    a4: 16.03774,
    a5: 11.32075,
    a6: 9.43396,
    b3: 111,
    b4: 22.64151,
    b5: 16.03774,
    b6: 11.32075,
  },
  {
    maesu: 1070,
    b2: 148,
    a2: 133.2,
    a3: 23.36449,
    a4: 15.88785,
    a5: 11.21495,
    a6: 9.81308,
    b3: 111,
    b4: 23.36449,
    b5: 15.88785,
    b6: 11.21495,
  },
  {
    maesu: 1080,
    b2: 148,
    a2: 133.2,
    a3: 23.14815,
    a4: 15.74074,
    a5: 11.11111,
    a6: 9.72222,
    b3: 111,
    b4: 23.14815,
    b5: 15.74074,
    b6: 11.11111,
  },
  {
    maesu: 1090,
    b2: 148,
    a2: 133.2,
    a3: 22.93578,
    a4: 16.51376,
    a5: 11.00917,
    a6: 9.63303,
    b3: 111,
    b4: 22.93578,
    b5: 16.51376,
    b6: 11.00917,
  },
  {
    maesu: 1100,
    b2: 148,
    a2: 133.2,
    a3: 22.72727,
    a4: 16.36364,
    a5: 11.81818,
    a6: 9.54545,
    b3: 111,
    b4: 22.72727,
    b5: 16.36364,
    b6: 11.81818,
  },
  {
    maesu: 1110,
    b2: 148,
    a2: 133.2,
    a3: 23.42342,
    a4: 16.21622,
    a5: 11.71171,
    a6: 9.45946,
    b3: 111,
    b4: 23.42342,
    b5: 16.21622,
    b6: 11.71171,
  },
  {
    maesu: 1120,
    b2: 148,
    a2: 133.2,
    a3: 23.21429,
    a4: 16.07143,
    a5: 11.60714,
    a6: 9.375,
    b3: 111,
    b4: 23.21429,
    b5: 16.07143,
    b6: 11.60714,
  },
  {
    maesu: 1130,
    b2: 148,
    a2: 133.2,
    a3: 23.00885,
    a4: 15.9292,
    a5: 11.50442,
    a6: 9.73451,
    b3: 111,
    b4: 23.00885,
    b5: 15.9292,
    b6: 11.50442,
  },
  {
    maesu: 1140,
    b2: 148,
    a2: 133.2,
    a3: 22.80702,
    a4: 15.78947,
    a5: 11.40351,
    a6: 9.64912,
    b3: 111,
    b4: 22.80702,
    b5: 15.78947,
    b6: 11.40351,
  },
  {
    maesu: 1150,
    b2: 148,
    a2: 133.2,
    a3: 22.6087,
    a4: 15.65217,
    a5: 11.30435,
    a6: 9.56522,
    b3: 111,
    b4: 22.6087,
    b5: 15.65217,
    b6: 11.30435,
  },
  {
    maesu: 1160,
    b2: 148,
    a2: 133.2,
    a3: 23.27586,
    a4: 16.37931,
    a5: 11.2069,
    a6: 9.48276,
    b3: 111,
    b4: 23.27586,
    b5: 16.37931,
    b6: 11.2069,
  },
  {
    maesu: 1170,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 16.23932,
    a5: 11.11111,
    a6: 9.40171,
    b3: 111,
    b4: 23.07692,
    b5: 16.23932,
    b6: 11.11111,
  },
  {
    maesu: 1180,
    b2: 148,
    a2: 133.2,
    a3: 22.88136,
    a4: 16.10169,
    a5: 11.01695,
    a6: 9.32203,
    b3: 111,
    b4: 22.88136,
    b5: 16.10169,
    b6: 11.01695,
  },
  {
    maesu: 1190,
    b2: 148,
    a2: 133.2,
    a3: 22.68908,
    a4: 15.96639,
    a5: 10.92437,
    a6: 9.2437,
    b3: 111,
    b4: 22.68908,
    b5: 15.96639,
    b6: 10.92437,
  },
  {
    maesu: 1200,
    b2: 148,
    a2: 133.2,
    a3: 23.33333,
    a4: 15.83333,
    a5: 11.66667,
    a6: 9.16667,
    b3: 111,
    b4: 23.33333,
    b5: 15.83333,
    b6: 11.66667,
  },
  {
    maesu: 1210,
    b2: 148,
    a2: 133.2,
    a3: 23.1405,
    a4: 15.70248,
    a5: 11.57025,
    a6: 9.09091,
    b3: 111,
    b4: 23.1405,
    b5: 15.70248,
    b6: 11.57025,
  },
  {
    maesu: 1220,
    b2: 148,
    a2: 133.2,
    a3: 22.95082,
    a4: 16.39344,
    a5: 11.47541,
    a6: 9.01639,
    b3: 111,
    b4: 22.95082,
    b5: 16.39344,
    b6: 11.47541,
  },
  {
    maesu: 1230,
    b2: 148,
    a2: 133.2,
    a3: 22.76423,
    a4: 16.26016,
    a5: 11.38211,
    a6: 8.94309,
    b3: 111,
    b4: 22.76423,
    b5: 16.26016,
    b6: 11.38211,
  },
  {
    maesu: 1240,
    b2: 148,
    a2: 133.2,
    a3: 23.3871,
    a4: 16.12903,
    a5: 11.29032,
    a6: 9.67742,
    b3: 111,
    b4: 23.3871,
    b5: 16.12903,
    b6: 11.29032,
  },
  {
    maesu: 1250,
    b2: 148,
    a2: 133.2,
    a3: 23.2,
    a4: 16,
    a5: 11.2,
    a6: 9.6,
    b3: 111,
    b4: 23.2,
    b5: 16,
    b6: 11.2,
  },
  {
    maesu: 1260,
    b2: 148,
    a2: 133.2,
    a3: 23.01587,
    a4: 15.87302,
    a5: 11.90476,
    a6: 9.52381,
    b3: 111,
    b4: 23.01587,
    b5: 15.87302,
    b6: 11.90476,
  },
  {
    maesu: 1270,
    b2: 148,
    a2: 133.2,
    a3: 22.83465,
    a4: 15.74803,
    a5: 11.81102,
    a6: 9.44882,
    b3: 111,
    b4: 22.83465,
    b5: 15.74803,
    b6: 11.81102,
  },
  {
    maesu: 1280,
    b2: 148,
    a2: 133.2,
    a3: 22.65625,
    a4: 15.625,
    a5: 11.71875,
    a6: 9.375,
    b3: 111,
    b4: 22.65625,
    b5: 15.625,
    b6: 11.71875,
  },
  {
    maesu: 1290,
    b2: 148,
    a2: 133.2,
    a3: 23.25581,
    a4: 16.27907,
    a5: 11.62791,
    a6: 9.30233,
    b3: 111,
    b4: 23.25581,
    b5: 16.27907,
    b6: 11.62791,
  },
  {
    maesu: 1300,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 16.15385,
    a5: 11.53846,
    a6: 9.23077,
    b3: 111,
    b4: 23.07692,
    b5: 16.15385,
    b6: 11.53846,
  },
  {
    maesu: 1310,
    b2: 148,
    a2: 133.2,
    a3: 22.90076,
    a4: 16.03053,
    a5: 12.21374,
    a6: 9.16031,
    b3: 111,
    b4: 22.90076,
    b5: 16.03053,
    b6: 12.21374,
  },
  {
    maesu: 1320,
    b2: 148,
    a2: 133.2,
    a3: 22.72727,
    a4: 15.90909,
    a5: 12.12121,
    a6: 9.09091,
    b3: 111,
    b4: 22.72727,
    b5: 15.90909,
    b6: 12.12121,
  },
  {
    maesu: 1330,
    b2: 148,
    a2: 133.2,
    a3: 23.30827,
    a4: 15.78947,
    a5: 12.03008,
    a6: 9.02256,
    b3: 111,
    b4: 23.30827,
    b5: 15.78947,
    b6: 12.03008,
  },
  {
    maesu: 1340,
    b2: 148,
    a2: 133.2,
    a3: 23.13433,
    a4: 15.67164,
    a5: 11.9403,
    a6: 8.95522,
    b3: 111,
    b4: 23.13433,
    b5: 15.67164,
    b6: 11.9403,
  },
  {
    maesu: 1350,
    b2: 148,
    a2: 133.2,
    a3: 22.96296,
    a4: 16.2963,
    a5: 11.85185,
    a6: 9.62963,
    b3: 111,
    b4: 22.96296,
    b5: 16.2963,
    b6: 11.85185,
  },
  {
    maesu: 1360,
    b2: 148,
    a2: 133.2,
    a3: 22.79412,
    a4: 16.17647,
    a5: 11.76471,
    a6: 9.55882,
    b3: 111,
    b4: 22.79412,
    b5: 16.17647,
    b6: 11.76471,
  },
  {
    maesu: 1370,
    b2: 148,
    a2: 133.2,
    a3: 23.35766,
    a4: 16.05839,
    a5: 12.40876,
    a6: 9.48905,
    b3: 111,
    b4: 23.35766,
    b5: 16.05839,
    b6: 12.40876,
  },
  {
    maesu: 1380,
    b2: 148,
    a2: 133.2,
    a3: 23.18841,
    a4: 15.94203,
    a5: 12.31884,
    a6: 9.42029,
    b3: 111,
    b4: 23.18841,
    b5: 15.94203,
    b6: 12.31884,
  },
  {
    maesu: 1390,
    b2: 148,
    a2: 133.2,
    a3: 23.02158,
    a4: 15.82734,
    a5: 12.23022,
    a6: 9.35252,
    b3: 111,
    b4: 23.02158,
    b5: 15.82734,
    b6: 12.23022,
  },
  {
    maesu: 1400,
    b2: 148,
    a2: 133.2,
    a3: 22.85714,
    a4: 15.71429,
    a5: 12.14286,
    a6: 9.28571,
    b3: 111,
    b4: 22.85714,
    b5: 15.71429,
    b6: 12.14286,
  },
  {
    maesu: 1410,
    b2: 148,
    a2: 133.2,
    a3: 22.69504,
    a4: 16.31206,
    a5: 12.05674,
    a6: 9.21986,
    b3: 111,
    b4: 22.69504,
    b5: 16.31206,
    b6: 12.05674,
  },
  {
    maesu: 1420,
    b2: 148,
    a2: 133.2,
    a3: 23.23944,
    a4: 16.19718,
    a5: 11.97183,
    a6: 9.15493,
    b3: 111,
    b4: 23.23944,
    b5: 16.19718,
    b6: 11.97183,
  },
  {
    maesu: 1430,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 16.08392,
    a5: 11.88811,
    a6: 9.09091,
    b3: 111,
    b4: 23.07692,
    b5: 16.08392,
    b6: 11.88811,
  },
  {
    maesu: 1440,
    b2: 148,
    a2: 133.2,
    a3: 22.91667,
    a4: 15.97222,
    a5: 11.80556,
    a6: 9.02778,
    b3: 111,
    b4: 22.91667,
    b5: 15.97222,
    b6: 11.80556,
  },
  {
    maesu: 1450,
    b2: 148,
    a2: 133.2,
    a3: 22.75862,
    a4: 15.86207,
    a5: 11.72414,
    a6: 8.96552,
    b3: 111,
    b4: 22.75862,
    b5: 15.86207,
    b6: 11.72414,
  },
  {
    maesu: 1460,
    b2: 148,
    a2: 133.2,
    a3: 23.28767,
    a4: 15.75342,
    a5: 12.32877,
    a6: 9.58904,
    b3: 111,
    b4: 23.28767,
    b5: 15.75342,
    b6: 12.32877,
  },
  {
    maesu: 1470,
    b2: 148,
    a2: 133.2,
    a3: 23.12925,
    a4: 15.64626,
    a5: 12.2449,
    a6: 9.52381,
    b3: 111,
    b4: 23.12925,
    b5: 15.64626,
    b6: 12.2449,
  },
  {
    maesu: 1480,
    b2: 148,
    a2: 133.2,
    a3: 22.97297,
    a4: 16.21622,
    a5: 12.16216,
    a6: 9.45946,
    b3: 111,
    b4: 22.97297,
    b5: 16.21622,
    b6: 12.16216,
  },
  {
    maesu: 1490,
    b2: 148,
    a2: 133.2,
    a3: 22.81879,
    a4: 16.10738,
    a5: 12.08054,
    a6: 9.39597,
    b3: 111,
    b4: 22.81879,
    b5: 16.10738,
    b6: 12.08054,
  },
  {
    maesu: 1500,
    b2: 148,
    a2: 133.2,
    a3: 23.33333,
    a4: 16,
    a5: 12,
    a6: 9.33333,
    b3: 111,
    b4: 23.33333,
    b5: 16,
    b6: 12,
  },
  {
    maesu: 1510,
    b2: 148,
    a2: 133.2,
    a3: 23.17881,
    a4: 15.89404,
    a5: 11.92053,
    a6: 9.27152,
    b3: 111,
    b4: 23.17881,
    b5: 15.89404,
    b6: 11.92053,
  },
  {
    maesu: 1520,
    b2: 148,
    a2: 133.2,
    a3: 23.02632,
    a4: 15.78947,
    a5: 11.84211,
    a6: 9.21053,
    b3: 111,
    b4: 23.02632,
    b5: 15.78947,
    b6: 11.84211,
  },
  {
    maesu: 1530,
    b2: 148,
    a2: 133.2,
    a3: 22.87582,
    a4: 15.68627,
    a5: 11.76471,
    a6: 9.15033,
    b3: 111,
    b4: 22.87582,
    b5: 15.68627,
    b6: 11.76471,
  },
  {
    maesu: 1540,
    b2: 148,
    a2: 133.2,
    a3: 22.72727,
    a4: 16.23377,
    a5: 12.33766,
    a6: 9.09091,
    b3: 111,
    b4: 22.72727,
    b5: 16.23377,
    b6: 12.33766,
  },
  {
    maesu: 1550,
    b2: 148,
    a2: 133.2,
    a3: 23.22581,
    a4: 16.12903,
    a5: 12.25806,
    a6: 9.03226,
    b3: 111,
    b4: 23.22581,
    b5: 16.12903,
    b6: 12.25806,
  },
  {
    maesu: 1560,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 16.02564,
    a5: 12.17949,
    a6: 8.97436,
    b3: 111,
    b4: 23.07692,
    b5: 16.02564,
    b6: 12.17949,
  },
  {
    maesu: 1570,
    b2: 148,
    a2: 133.2,
    a3: 22.92994,
    a4: 15.92357,
    a5: 12.10191,
    a6: 8.9172,
    b3: 111,
    b4: 22.92994,
    b5: 15.92357,
    b6: 12.10191,
  },
  {
    maesu: 1580,
    b2: 148,
    a2: 133.2,
    a3: 22.78481,
    a4: 15.82278,
    a5: 12.02532,
    a6: 9.49367,
    b3: 111,
    b4: 22.78481,
    b5: 15.82278,
    b6: 12.02532,
  },
  {
    maesu: 1590,
    b2: 148,
    a2: 133.2,
    a3: 23.27044,
    a4: 15.72327,
    a5: 11.94969,
    a6: 9.43396,
    b3: 111,
    b4: 23.27044,
    b5: 15.72327,
    b6: 11.94969,
  },
  {
    maesu: 1600,
    b2: 148,
    a2: 133.2,
    a3: 23.125,
    a4: 15.625,
    a5: 11.875,
    a6: 9.375,
    b3: 111,
    b4: 23.125,
    b5: 15.625,
    b6: 11.875,
  },
  {
    maesu: 1610,
    b2: 148,
    a2: 133.2,
    a3: 22.98137,
    a4: 16.14907,
    a5: 11.80124,
    a6: 9.31677,
    b3: 111,
    b4: 22.98137,
    b5: 16.14907,
    b6: 11.80124,
  },
  {
    maesu: 1620,
    b2: 148,
    a2: 133.2,
    a3: 22.83951,
    a4: 16.04938,
    a5: 11.7284,
    a6: 9.25926,
    b3: 111,
    b4: 22.83951,
    b5: 16.04938,
    b6: 11.7284,
  },
  {
    maesu: 1630,
    b2: 148,
    a2: 133.2,
    a3: 22.69939,
    a4: 15.95092,
    a5: 12.26994,
    a6: 9.20245,
    b3: 111,
    b4: 22.69939,
    b5: 15.95092,
    b6: 12.26994,
  },
  {
    maesu: 1640,
    b2: 148,
    a2: 133.2,
    a3: 23.17073,
    a4: 15.85366,
    a5: 12.19512,
    a6: 9.14634,
    b3: 111,
    b4: 23.17073,
    b5: 15.85366,
    b6: 12.19512,
  },
  {
    maesu: 1650,
    b2: 148,
    a2: 133.2,
    a3: 23.0303,
    a4: 15.75758,
    a5: 12.12121,
    a6: 9.09091,
    b3: 111,
    b4: 23.0303,
    b5: 15.75758,
    b6: 12.12121,
  },
  {
    maesu: 1660,
    b2: 148,
    a2: 133.2,
    a3: 22.89157,
    a4: 15.66265,
    a5: 12.04819,
    a6: 9.03614,
    b3: 111,
    b4: 22.89157,
    b5: 15.66265,
    b6: 12.04819,
  },
  {
    maesu: 1670,
    b2: 148,
    a2: 133.2,
    a3: 22.75449,
    a4: 16.16766,
    a5: 11.97605,
    a6: 8.98204,
    b3: 111,
    b4: 22.75449,
    b5: 16.16766,
    b6: 11.97605,
  },
  {
    maesu: 1680,
    b2: 148,
    a2: 133.2,
    a3: 23.21429,
    a4: 16.07143,
    a5: 11.90476,
    a6: 8.92857,
    b3: 111,
    b4: 23.21429,
    b5: 16.07143,
    b6: 11.90476,
  },
  {
    maesu: 1690,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 15.97633,
    a5: 11.83432,
    a6: 9.46746,
    b3: 111,
    b4: 23.07692,
    b5: 15.97633,
    b6: 11.83432,
  },
  {
    maesu: 1700,
    b2: 148,
    a2: 133.2,
    a3: 22.94118,
    a4: 15.88235,
    a5: 11.76471,
    a6: 9.41176,
    b3: 111,
    b4: 22.94118,
    b5: 15.88235,
    b6: 11.76471,
  },
  {
    maesu: 1710,
    b2: 148,
    a2: 133.2,
    a3: 22.80702,
    a4: 15.78947,
    a5: 12.2807,
    a6: 9.35673,
    b3: 111,
    b4: 22.80702,
    b5: 15.78947,
    b6: 12.2807,
  },
  {
    maesu: 1720,
    b2: 148,
    a2: 133.2,
    a3: 23.25581,
    a4: 15.69767,
    a5: 12.2093,
    a6: 9.30233,
    b3: 111,
    b4: 23.25581,
    b5: 15.69767,
    b6: 12.2093,
  },
  {
    maesu: 1730,
    b2: 148,
    a2: 133.2,
    a3: 23.12139,
    a4: 16.18497,
    a5: 12.13873,
    a6: 9.24855,
    b3: 111,
    b4: 23.12139,
    b5: 16.18497,
    b6: 12.13873,
  },
  {
    maesu: 1740,
    b2: 148,
    a2: 133.2,
    a3: 22.98851,
    a4: 16.09195,
    a5: 12.06897,
    a6: 9.1954,
    b3: 111,
    b4: 22.98851,
    b5: 16.09195,
    b6: 12.06897,
  },
  {
    maesu: 1750,
    b2: 148,
    a2: 133.2,
    a3: 22.85714,
    a4: 16,
    a5: 12,
    a6: 9.14286,
    b3: 111,
    b4: 22.85714,
    b5: 16,
    b6: 12,
  },
  {
    maesu: 1760,
    b2: 148,
    a2: 133.2,
    a3: 22.72727,
    a4: 15.90909,
    a5: 11.93182,
    a6: 9.09091,
    b3: 111,
    b4: 22.72727,
    b5: 15.90909,
    b6: 11.93182,
  },
  {
    maesu: 1770,
    b2: 148,
    a2: 133.2,
    a3: 23.16384,
    a4: 15.81921,
    a5: 11.86441,
    a6: 9.03955,
    b3: 111,
    b4: 23.16384,
    b5: 15.81921,
    b6: 11.86441,
  },
  {
    maesu: 1780,
    b2: 148,
    a2: 133.2,
    a3: 23.03371,
    a4: 15.73034,
    a5: 11.79775,
    a6: 8.98876,
    b3: 111,
    b4: 23.03371,
    b5: 15.73034,
    b6: 11.79775,
  },
  {
    maesu: 1790,
    b2: 148,
    a2: 133.2,
    a3: 22.90503,
    a4: 15.64246,
    a5: 11.73184,
    a6: 8.93855,
    b3: 111,
    b4: 22.90503,
    b5: 15.64246,
    b6: 11.73184,
  },
  {
    maesu: 1800,
    b2: 148,
    a2: 133.2,
    a3: 22.77778,
    a4: 16.11111,
    a5: 12.22222,
    a6: 9.44444,
    b3: 111,
    b4: 22.77778,
    b5: 16.11111,
    b6: 12.22222,
  },
  {
    maesu: 1810,
    b2: 148,
    a2: 133.2,
    a3: 23.20442,
    a4: 16.0221,
    a5: 12.1547,
    a6: 9.39227,
    b3: 111,
    b4: 23.20442,
    b5: 16.0221,
    b6: 12.1547,
  },
  {
    maesu: 1820,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 15.93407,
    a5: 12.08791,
    a6: 9.34066,
    b3: 111,
    b4: 23.07692,
    b5: 15.93407,
    b6: 12.08791,
  },
  {
    maesu: 1830,
    b2: 148,
    a2: 133.2,
    a3: 22.95082,
    a4: 15.84699,
    a5: 12.02186,
    a6: 9.28962,
    b3: 111,
    b4: 22.95082,
    b5: 15.84699,
    b6: 12.02186,
  },
  {
    maesu: 1840,
    b2: 148,
    a2: 133.2,
    a3: 22.82609,
    a4: 15.76087,
    a5: 11.95652,
    a6: 9.23913,
    b3: 111,
    b4: 22.82609,
    b5: 15.76087,
    b6: 11.95652,
  },
  {
    maesu: 1850,
    b2: 148,
    a2: 133.2,
    a3: 23.24324,
    a4: 15.67568,
    a5: 11.89189,
    a6: 9.18919,
    b3: 111,
    b4: 23.24324,
    b5: 15.67568,
    b6: 11.89189,
  },
  {
    maesu: 1860,
    b2: 148,
    a2: 133.2,
    a3: 23.11828,
    a4: 16.12903,
    a5: 11.82796,
    a6: 9.13978,
    b3: 111,
    b4: 23.11828,
    b5: 16.12903,
    b6: 11.82796,
  },
  {
    maesu: 1870,
    b2: 148,
    a2: 133.2,
    a3: 22.99465,
    a4: 16.04278,
    a5: 11.76471,
    a6: 9.09091,
    b3: 111,
    b4: 22.99465,
    b5: 16.04278,
    b6: 11.76471,
  },
  {
    maesu: 1880,
    b2: 148,
    a2: 133.2,
    a3: 22.87234,
    a4: 15.95745,
    a5: 11.70213,
    a6: 9.04255,
    b3: 111,
    b4: 22.87234,
    b5: 15.95745,
    b6: 11.70213,
  },
  {
    maesu: 1890,
    b2: 148,
    a2: 133.2,
    a3: 22.75132,
    a4: 15.87302,
    a5: 12.16931,
    a6: 8.99471,
    b3: 111,
    b4: 22.75132,
    b5: 15.87302,
    b6: 12.16931,
  },
  {
    maesu: 1900,
    b2: 148,
    a2: 133.2,
    a3: 23.15789,
    a4: 15.78947,
    a5: 12.10526,
    a6: 8.94737,
    b3: 111,
    b4: 23.15789,
    b5: 15.78947,
    b6: 12.10526,
  },
  {
    maesu: 1910,
    b2: 148,
    a2: 133.2,
    a3: 23.03665,
    a4: 15.70681,
    a5: 12.04188,
    a6: 9.42408,
    b3: 111,
    b4: 23.03665,
    b5: 15.70681,
    b6: 12.04188,
  },
  {
    maesu: 1920,
    b2: 148,
    a2: 133.2,
    a3: 22.91667,
    a4: 15.625,
    a5: 11.97917,
    a6: 9.375,
    b3: 111,
    b4: 22.91667,
    b5: 15.625,
    b6: 11.97917,
  },
  {
    maesu: 1930,
    b2: 148,
    a2: 133.2,
    a3: 22.79793,
    a4: 16.06218,
    a5: 11.9171,
    a6: 9.32642,
    b3: 111,
    b4: 22.79793,
    b5: 16.06218,
    b6: 11.9171,
  },
  {
    maesu: 1940,
    b2: 148,
    a2: 133.2,
    a3: 23.19588,
    a4: 15.97938,
    a5: 11.85567,
    a6: 9.27835,
    b3: 111,
    b4: 23.19588,
    b5: 15.97938,
    b6: 11.85567,
  },
  {
    maesu: 1950,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 15.89744,
    a5: 11.79487,
    a6: 9.23077,
    b3: 111,
    b4: 23.07692,
    b5: 15.89744,
    b6: 11.79487,
  },
  {
    maesu: 1960,
    b2: 148,
    a2: 133.2,
    a3: 22.95918,
    a4: 15.81633,
    a5: 11.73469,
    a6: 9.18367,
    b3: 111,
    b4: 22.95918,
    b5: 15.81633,
    b6: 11.73469,
  },
  {
    maesu: 1970,
    b2: 148,
    a2: 133.2,
    a3: 22.84264,
    a4: 15.73604,
    a5: 12.18274,
    a6: 9.13706,
    b3: 111,
    b4: 22.84264,
    b5: 15.73604,
    b6: 12.18274,
  },
  {
    maesu: 1980,
    b2: 148,
    a2: 133.2,
    a3: 23.23232,
    a4: 15.65657,
    a5: 12.12121,
    a6: 9.09091,
    b3: 111,
    b4: 23.23232,
    b5: 15.65657,
    b6: 12.12121,
  },
  {
    maesu: 1990,
    b2: 148,
    a2: 133.2,
    a3: 23.11558,
    a4: 16.0804,
    a5: 12.0603,
    a6: 9.04523,
    b3: 111,
    b4: 23.11558,
    b5: 16.0804,
    b6: 12.0603,
  },
  {
    maesu: 2000,
    b2: 148,
    a2: 133.2,
    a3: 23,
    a4: 16,
    a5: 12,
    a6: 9,
    b3: 111,
    b4: 23,
    b5: 16,
    b6: 12,
  },
  {
    maesu: 2010,
    b2: 148,
    a2: 133.2,
    a3: 22.88557,
    a4: 15.9204,
    a5: 11.9403,
    a6: 8.95522,
    b3: 111,
    b4: 22.88557,
    b5: 15.9204,
    b6: 11.9403,
  },
  {
    maesu: 2020,
    b2: 148,
    a2: 133.2,
    a3: 22.77228,
    a4: 15.84158,
    a5: 11.88119,
    a6: 8.91089,
    b3: 111,
    b4: 22.77228,
    b5: 15.84158,
    b6: 11.88119,
  },
  {
    maesu: 2030,
    b2: 148,
    a2: 133.2,
    a3: 23.15271,
    a4: 15.76355,
    a5: 11.82266,
    a6: 9.35961,
    b3: 111,
    b4: 23.15271,
    b5: 15.76355,
    b6: 11.82266,
  },
  {
    maesu: 2040,
    b2: 148,
    a2: 133.2,
    a3: 23.03922,
    a4: 16.17647,
    a5: 11.76471,
    a6: 9.31373,
    b3: 111,
    b4: 23.03922,
    b5: 16.17647,
    b6: 11.76471,
  },
  {
    maesu: 2050,
    b2: 148,
    a2: 133.2,
    a3: 22.92683,
    a4: 16.09756,
    a5: 11.70732,
    a6: 9.26829,
    b3: 111,
    b4: 22.92683,
    b5: 16.09756,
    b6: 11.70732,
  },
  {
    maesu: 2060,
    b2: 148,
    a2: 133.2,
    a3: 22.81553,
    a4: 16.01942,
    a5: 11.65049,
    a6: 8.73786,
    b3: 111,
    b4: 22.81553,
    b5: 16.01942,
    b6: 11.65049,
  },
  {
    maesu: 2070,
    b2: 148,
    a2: 133.2,
    a3: 23.18841,
    a4: 15.94203,
    a5: 11.5942,
    a6: 8.69565,
    b3: 111,
    b4: 23.18841,
    b5: 15.94203,
    b6: 11.5942,
  },
  {
    maesu: 2080,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 15.86538,
    a5: 11.53846,
    a6: 8.65385,
    b3: 111,
    b4: 23.07692,
    b5: 15.86538,
    b6: 11.53846,
  },
  {
    maesu: 2090,
    b2: 148,
    a2: 133.2,
    a3: 22.96651,
    a4: 15.78947,
    a5: 11.48325,
    a6: 9.09091,
    b3: 111,
    b4: 22.96651,
    b5: 15.78947,
    b6: 11.48325,
  },
  {
    maesu: 2100,
    b2: 148,
    a2: 133.2,
    a3: 22.85714,
    a4: 16.19048,
    a5: 11.42857,
    a6: 9.04762,
    b3: 111,
    b4: 22.85714,
    b5: 16.19048,
    b6: 11.42857,
  },
  {
    maesu: 2110,
    b2: 148,
    a2: 133.2,
    a3: 23.22275,
    a4: 16.11374,
    a5: 11.37441,
    a6: 9.00474,
    b3: 111,
    b4: 23.22275,
    b5: 16.11374,
    b6: 11.37441,
  },
  {
    maesu: 2120,
    b2: 148,
    a2: 133.2,
    a3: 23.11321,
    a4: 16.03774,
    a5: 11.32075,
    a6: 8.96226,
    b3: 111,
    b4: 23.11321,
    b5: 16.03774,
    b6: 11.32075,
  },
  {
    maesu: 2130,
    b2: 148,
    a2: 133.2,
    a3: 23.00469,
    a4: 15.96244,
    a5: 11.73709,
    a6: 8.92019,
    b3: 111,
    b4: 23.00469,
    b5: 15.96244,
    b6: 11.73709,
  },
  {
    maesu: 2140,
    b2: 148,
    a2: 133.2,
    a3: 22.8972,
    a4: 15.88785,
    a5: 11.68224,
    a6: 8.8785,
    b3: 111,
    b4: 22.8972,
    b5: 15.88785,
    b6: 11.68224,
  },
  {
    maesu: 2150,
    b2: 148,
    a2: 133.2,
    a3: 22.7907,
    a4: 15.81395,
    a5: 11.62791,
    a6: 8.83721,
    b3: 111,
    b4: 22.7907,
    b5: 15.81395,
    b6: 11.62791,
  },
  {
    maesu: 2160,
    b2: 148,
    a2: 133.2,
    a3: 23.14815,
    a4: 16.2037,
    a5: 11.57407,
    a6: 8.7963,
    b3: 111,
    b4: 23.14815,
    b5: 16.2037,
    b6: 11.57407,
  },
  {
    maesu: 2170,
    b2: 148,
    a2: 133.2,
    a3: 23.04147,
    a4: 16.12903,
    a5: 11.52074,
    a6: 8.75576,
    b3: 111,
    b4: 23.04147,
    b5: 16.12903,
    b6: 11.52074,
  },
  {
    maesu: 2180,
    b2: 148,
    a2: 133.2,
    a3: 22.93578,
    a4: 16.05505,
    a5: 11.46789,
    a6: 8.7156,
    b3: 111,
    b4: 22.93578,
    b5: 16.05505,
    b6: 11.46789,
  },
  {
    maesu: 2190,
    b2: 148,
    a2: 133.2,
    a3: 22.83105,
    a4: 15.98174,
    a5: 11.41553,
    a6: 8.6758,
    b3: 111,
    b4: 22.83105,
    b5: 15.98174,
    b6: 11.41553,
  },
  {
    maesu: 2200,
    b2: 148,
    a2: 133.2,
    a3: 23.18182,
    a4: 15.90909,
    a5: 11.36364,
    a6: 8.63636,
    b3: 111,
    b4: 23.18182,
    b5: 15.90909,
    b6: 11.36364,
  },
  {
    maesu: 2210,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 15.8371,
    a5: 11.31222,
    a6: 9.04977,
    b3: 111,
    b4: 23.07692,
    b5: 15.8371,
    b6: 11.31222,
  },
  {
    maesu: 2220,
    b2: 148,
    a2: 133.2,
    a3: 22.97297,
    a4: 16.21622,
    a5: 11.71171,
    a6: 9.00901,
    b3: 111,
    b4: 22.97297,
    b5: 16.21622,
    b6: 11.71171,
  },
  {
    maesu: 2230,
    b2: 148,
    a2: 133.2,
    a3: 22.86996,
    a4: 16.1435,
    a5: 11.65919,
    a6: 8.96861,
    b3: 111,
    b4: 22.86996,
    b5: 16.1435,
    b6: 11.65919,
  },
  {
    maesu: 2240,
    b2: 148,
    a2: 133.2,
    a3: 23.21429,
    a4: 16.07143,
    a5: 11.60714,
    a6: 8.92857,
    b3: 111,
    b4: 23.21429,
    b5: 16.07143,
    b6: 11.60714,
  },
  {
    maesu: 2250,
    b2: 148,
    a2: 133.2,
    a3: 23.11111,
    a4: 16,
    a5: 11.55556,
    a6: 8.88889,
    b3: 111,
    b4: 23.11111,
    b5: 16,
    b6: 11.55556,
  },
  {
    maesu: 2260,
    b2: 148,
    a2: 133.2,
    a3: 23.00885,
    a4: 15.9292,
    a5: 11.50442,
    a6: 8.84956,
    b3: 111,
    b4: 23.00885,
    b5: 15.9292,
    b6: 11.50442,
  },
  {
    maesu: 2270,
    b2: 148,
    a2: 133.2,
    a3: 22.90749,
    a4: 15.85903,
    a5: 11.45374,
    a6: 8.81057,
    b3: 111,
    b4: 22.90749,
    b5: 15.85903,
    b6: 11.45374,
  },
  {
    maesu: 2280,
    b2: 148,
    a2: 133.2,
    a3: 22.80702,
    a4: 15.78947,
    a5: 11.40351,
    a6: 8.77193,
    b3: 111,
    b4: 22.80702,
    b5: 15.78947,
    b6: 11.40351,
  },
  {
    maesu: 2290,
    b2: 148,
    a2: 133.2,
    a3: 23.1441,
    a4: 16.15721,
    a5: 11.35371,
    a6: 8.73362,
    b3: 111,
    b4: 23.1441,
    b5: 16.15721,
    b6: 11.35371,
  },
  {
    maesu: 2300,
    b2: 148,
    a2: 133.2,
    a3: 23.04348,
    a4: 16.08696,
    a5: 11.73913,
    a6: 8.69565,
    b3: 111,
    b4: 23.04348,
    b5: 16.08696,
    b6: 11.73913,
  },
  {
    maesu: 2310,
    b2: 148,
    a2: 133.2,
    a3: 22.94372,
    a4: 16.01732,
    a5: 11.68831,
    a6: 8.65801,
    b3: 111,
    b4: 22.94372,
    b5: 16.01732,
    b6: 11.68831,
  },
  {
    maesu: 2320,
    b2: 148,
    a2: 133.2,
    a3: 22.84483,
    a4: 15.94828,
    a5: 11.63793,
    a6: 8.62069,
    b3: 111,
    b4: 22.84483,
    b5: 15.94828,
    b6: 11.63793,
  },
  {
    maesu: 2330,
    b2: 148,
    a2: 133.2,
    a3: 23.17597,
    a4: 15.87983,
    a5: 11.58798,
    a6: 9.01288,
    b3: 111,
    b4: 23.17597,
    b5: 15.87983,
    b6: 11.58798,
  },
  {
    maesu: 2340,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 15.81197,
    a5: 11.53846,
    a6: 8.97436,
    b3: 111,
    b4: 23.07692,
    b5: 15.81197,
    b6: 11.53846,
  },
  {
    maesu: 2350,
    b2: 148,
    a2: 133.2,
    a3: 22.97872,
    a4: 16.17021,
    a5: 11.48936,
    a6: 8.93617,
    b3: 111,
    b4: 22.97872,
    b5: 16.17021,
    b6: 11.48936,
  },
  {
    maesu: 2360,
    b2: 148,
    a2: 133.2,
    a3: 22.88136,
    a4: 16.10169,
    a5: 11.44068,
    a6: 8.89831,
    b3: 111,
    b4: 22.88136,
    b5: 16.10169,
    b6: 11.44068,
  },
  {
    maesu: 2370,
    b2: 148,
    a2: 133.2,
    a3: 23.20675,
    a4: 16.03376,
    a5: 11.39241,
    a6: 8.86076,
    b3: 111,
    b4: 23.20675,
    b5: 16.03376,
    b6: 11.39241,
  },
  {
    maesu: 2380,
    b2: 148,
    a2: 133.2,
    a3: 23.10924,
    a4: 15.96639,
    a5: 11.34454,
    a6: 8.82353,
    b3: 111,
    b4: 23.10924,
    b5: 15.96639,
    b6: 11.34454,
  },
  {
    maesu: 2390,
    b2: 148,
    a2: 133.2,
    a3: 23.01255,
    a4: 15.89958,
    a5: 11.71548,
    a6: 8.78661,
    b3: 111,
    b4: 23.01255,
    b5: 15.89958,
    b6: 11.71548,
  },
  {
    maesu: 2400,
    b2: 148,
    a2: 133.2,
    a3: 22.91667,
    a4: 15.83333,
    a5: 11.66667,
    a6: 8.75,
    b3: 111,
    b4: 22.91667,
    b5: 15.83333,
    b6: 11.66667,
  },
  {
    maesu: 2410,
    b2: 148,
    a2: 133.2,
    a3: 22.82158,
    a4: 16.18257,
    a5: 11.61826,
    a6: 8.71369,
    b3: 111,
    b4: 22.82158,
    b5: 16.18257,
    b6: 11.61826,
  },
  {
    maesu: 2420,
    b2: 148,
    a2: 133.2,
    a3: 23.1405,
    a4: 16.1157,
    a5: 11.57025,
    a6: 8.67769,
    b3: 111,
    b4: 23.1405,
    b5: 16.1157,
    b6: 11.57025,
  },
  {
    maesu: 2430,
    b2: 148,
    a2: 133.2,
    a3: 23.04527,
    a4: 16.04938,
    a5: 11.52263,
    a6: 8.64198,
    b3: 111,
    b4: 23.04527,
    b5: 16.04938,
    b6: 11.52263,
  },
  {
    maesu: 2440,
    b2: 148,
    a2: 133.2,
    a3: 22.95082,
    a4: 15.98361,
    a5: 11.47541,
    a6: 9.01639,
    b3: 111,
    b4: 22.95082,
    b5: 15.98361,
    b6: 11.47541,
  },
  {
    maesu: 2450,
    b2: 148,
    a2: 133.2,
    a3: 22.85714,
    a4: 15.91837,
    a5: 11.42857,
    a6: 8.97959,
    b3: 111,
    b4: 22.85714,
    b5: 15.91837,
    b6: 11.42857,
  },
  {
    maesu: 2460,
    b2: 148,
    a2: 133.2,
    a3: 23.17073,
    a4: 15.85366,
    a5: 11.38211,
    a6: 8.94309,
    b3: 111,
    b4: 23.17073,
    b5: 15.85366,
    b6: 11.38211,
  },
  {
    maesu: 2470,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 16.19433,
    a5: 11.33603,
    a6: 8.90688,
    b3: 111,
    b4: 23.07692,
    b5: 16.19433,
    b6: 11.33603,
  },
  {
    maesu: 2480,
    b2: 148,
    a2: 133.2,
    a3: 22.98387,
    a4: 16.12903,
    a5: 11.69355,
    a6: 8.87097,
    b3: 111,
    b4: 22.98387,
    b5: 16.12903,
    b6: 11.69355,
  },
  {
    maesu: 2490,
    b2: 148,
    a2: 133.2,
    a3: 22.89157,
    a4: 16.06426,
    a5: 11.64659,
    a6: 8.83534,
    b3: 111,
    b4: 22.89157,
    b5: 16.06426,
    b6: 11.64659,
  },
  {
    maesu: 2500,
    b2: 148,
    a2: 133.2,
    a3: 23.2,
    a4: 16,
    a5: 11.6,
    a6: 8.8,
    b3: 111,
    b4: 23.2,
    b5: 16,
    b6: 11.6,
  },
  {
    maesu: 2510,
    b2: 148,
    a2: 133.2,
    a3: 23.10757,
    a4: 15.93625,
    a5: 11.55378,
    a6: 8.76494,
    b3: 111,
    b4: 23.10757,
    b5: 15.93625,
    b6: 11.55378,
  },
  {
    maesu: 2520,
    b2: 148,
    a2: 133.2,
    a3: 23.01587,
    a4: 15.87302,
    a5: 11.50794,
    a6: 8.73016,
    b3: 111,
    b4: 23.01587,
    b5: 15.87302,
    b6: 11.50794,
  },
  {
    maesu: 2530,
    b2: 148,
    a2: 133.2,
    a3: 22.9249,
    a4: 15.81028,
    a5: 11.46245,
    a6: 8.69565,
    b3: 111,
    b4: 22.9249,
    b5: 15.81028,
    b6: 11.46245,
  },
  {
    maesu: 2540,
    b2: 148,
    a2: 133.2,
    a3: 22.83465,
    a4: 16.14173,
    a5: 11.41732,
    a6: 8.66142,
    b3: 111,
    b4: 22.83465,
    b5: 16.14173,
    b6: 11.41732,
  },
  {
    maesu: 2550,
    b2: 148,
    a2: 133.2,
    a3: 23.13725,
    a4: 16.07843,
    a5: 11.37255,
    a6: 8.62745,
    b3: 111,
    b4: 23.13725,
    b5: 16.07843,
    b6: 11.37255,
  },
  {
    maesu: 2560,
    b2: 148,
    a2: 133.2,
    a3: 23.04688,
    a4: 16.01562,
    a5: 11.32812,
    a6: 8.98438,
    b3: 111,
    b4: 23.04688,
    b5: 16.01562,
    b6: 11.32812,
  },
  {
    maesu: 2570,
    b2: 148,
    a2: 133.2,
    a3: 22.9572,
    a4: 15.95331,
    a5: 11.67315,
    a6: 8.94942,
    b3: 111,
    b4: 22.9572,
    b5: 15.95331,
    b6: 11.67315,
  },
  {
    maesu: 2580,
    b2: 148,
    a2: 133.2,
    a3: 22.86822,
    a4: 15.89147,
    a5: 11.62791,
    a6: 8.91473,
    b3: 111,
    b4: 22.86822,
    b5: 15.89147,
    b6: 11.62791,
  },
  {
    maesu: 2590,
    b2: 148,
    a2: 133.2,
    a3: 23.16602,
    a4: 15.83012,
    a5: 11.58301,
    a6: 8.88031,
    b3: 111,
    b4: 23.16602,
    b5: 15.83012,
    b6: 11.58301,
  },
  {
    maesu: 2600,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 16.15385,
    a5: 11.53846,
    a6: 8.84615,
    b3: 111,
    b4: 23.07692,
    b5: 16.15385,
    b6: 11.53846,
  },
  {
    maesu: 2610,
    b2: 148,
    a2: 133.2,
    a3: 22.98851,
    a4: 16.09195,
    a5: 11.49425,
    a6: 8.81226,
    b3: 111,
    b4: 22.98851,
    b5: 16.09195,
    b6: 11.49425,
  },
  {
    maesu: 2620,
    b2: 148,
    a2: 133.2,
    a3: 22.90076,
    a4: 16.03053,
    a5: 11.45038,
    a6: 8.77863,
    b3: 111,
    b4: 22.90076,
    b5: 16.03053,
    b6: 11.45038,
  },
  {
    maesu: 2630,
    b2: 148,
    a2: 133.2,
    a3: 22.81369,
    a4: 15.96958,
    a5: 11.40684,
    a6: 8.74525,
    b3: 111,
    b4: 22.81369,
    b5: 15.96958,
    b6: 11.40684,
  },
  {
    maesu: 2640,
    b2: 148,
    a2: 133.2,
    a3: 23.10606,
    a4: 15.90909,
    a5: 11.36364,
    a6: 8.71212,
    b3: 111,
    b4: 23.10606,
    b5: 15.90909,
    b6: 11.36364,
  },
  {
    maesu: 2650,
    b2: 148,
    a2: 133.2,
    a3: 23.01887,
    a4: 15.84906,
    a5: 11.32075,
    a6: 8.67925,
    b3: 111,
    b4: 23.01887,
    b5: 15.84906,
    b6: 11.32075,
  },
  {
    maesu: 2660,
    b2: 148,
    a2: 133.2,
    a3: 22.93233,
    a4: 16.16541,
    a5: 11.65414,
    a6: 8.64662,
    b3: 111,
    b4: 22.93233,
    b5: 16.16541,
    b6: 11.65414,
  },
  {
    maesu: 2670,
    b2: 148,
    a2: 133.2,
    a3: 22.84644,
    a4: 16.10487,
    a5: 11.61049,
    a6: 8.61423,
    b3: 111,
    b4: 22.84644,
    b5: 16.10487,
    b6: 11.61049,
  },
  {
    maesu: 2680,
    b2: 148,
    a2: 133.2,
    a3: 23.13433,
    a4: 16.04478,
    a5: 11.56716,
    a6: 8.95522,
    b3: 111,
    b4: 23.13433,
    b5: 16.04478,
    b6: 11.56716,
  },
  {
    maesu: 2690,
    b2: 148,
    a2: 133.2,
    a3: 23.04833,
    a4: 15.98513,
    a5: 11.52416,
    a6: 8.92193,
    b3: 111,
    b4: 23.04833,
    b5: 15.98513,
    b6: 11.52416,
  },
  {
    maesu: 2700,
    b2: 148,
    a2: 133.2,
    a3: 22.96296,
    a4: 15.92593,
    a5: 11.48148,
    a6: 8.88889,
    b3: 111,
    b4: 22.96296,
    b5: 15.92593,
    b6: 11.48148,
  },
  {
    maesu: 2710,
    b2: 148,
    a2: 133.2,
    a3: 22.87823,
    a4: 15.86716,
    a5: 11.43911,
    a6: 8.85609,
    b3: 111,
    b4: 22.87823,
    b5: 15.86716,
    b6: 11.43911,
  },
  {
    maesu: 2720,
    b2: 148,
    a2: 133.2,
    a3: 23.16176,
    a4: 16.17647,
    a5: 11.39706,
    a6: 8.82353,
    b3: 111,
    b4: 23.16176,
    b5: 16.17647,
    b6: 11.39706,
  },
  {
    maesu: 2730,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 16.11722,
    a5: 11.35531,
    a6: 8.79121,
    b3: 111,
    b4: 23.07692,
    b5: 16.11722,
    b6: 11.35531,
  },
  {
    maesu: 2740,
    b2: 148,
    a2: 133.2,
    a3: 22.9927,
    a4: 16.05839,
    a5: 11.31387,
    a6: 8.75912,
    b3: 111,
    b4: 22.9927,
    b5: 16.05839,
    b6: 11.31387,
  },
  {
    maesu: 2750,
    b2: 148,
    a2: 133.2,
    a3: 22.90909,
    a4: 16,
    a5: 11.27273,
    a6: 8.72727,
    b3: 111,
    b4: 22.90909,
    b5: 16,
    b6: 11.27273,
  },
  {
    maesu: 2760,
    b2: 148,
    a2: 133.2,
    a3: 22.82609,
    a4: 15.94203,
    a5: 11.23188,
    a6: 8.69565,
    b3: 111,
    b4: 22.82609,
    b5: 15.94203,
    b6: 11.23188,
  },
  {
    maesu: 2770,
    b2: 148,
    a2: 133.2,
    a3: 23.10469,
    a4: 15.88448,
    a5: 11.19134,
    a6: 8.66426,
    b3: 111,
    b4: 23.10469,
    b5: 15.88448,
    b6: 11.19134,
  },
  {
    maesu: 2780,
    b2: 148,
    a2: 133.2,
    a3: 23.02158,
    a4: 15.82734,
    a5: 11.51079,
    a6: 8.63309,
    b3: 111,
    b4: 23.02158,
    b5: 15.82734,
    b6: 11.51079,
  },
  {
    maesu: 2790,
    b2: 148,
    a2: 133.2,
    a3: 22.93907,
    a4: 16.12903,
    a5: 11.46953,
    a6: 8.60215,
    b3: 111,
    b4: 22.93907,
    b5: 16.12903,
    b6: 11.46953,
  },
  {
    maesu: 2800,
    b2: 148,
    a2: 133.2,
    a3: 22.85714,
    a4: 16.07143,
    a5: 11.42857,
    a6: 8.57143,
    b3: 111,
    b4: 22.85714,
    b5: 16.07143,
    b6: 11.42857,
  },
  {
    maesu: 2810,
    b2: 148,
    a2: 133.2,
    a3: 23.13167,
    a4: 16.01423,
    a5: 11.3879,
    a6: 8.54093,
    b3: 111,
    b4: 23.13167,
    b5: 16.01423,
    b6: 11.3879,
  },
  {
    maesu: 2820,
    b2: 148,
    a2: 133.2,
    a3: 23.04965,
    a4: 15.95745,
    a5: 11.34752,
    a6: 8.86525,
    b3: 111,
    b4: 23.04965,
    b5: 15.95745,
    b6: 11.34752,
  },
  {
    maesu: 2830,
    b2: 148,
    a2: 133.2,
    a3: 22.9682,
    a4: 15.90106,
    a5: 11.30742,
    a6: 8.83392,
    b3: 111,
    b4: 22.9682,
    b5: 15.90106,
    b6: 11.30742,
  },
  {
    maesu: 2840,
    b2: 148,
    a2: 133.2,
    a3: 22.88732,
    a4: 15.84507,
    a5: 11.26761,
    a6: 8.80282,
    b3: 111,
    b4: 22.88732,
    b5: 15.84507,
    b6: 11.26761,
  },
  {
    maesu: 2850,
    b2: 148,
    a2: 133.2,
    a3: 23.15789,
    a4: 16.14035,
    a5: 11.22807,
    a6: 8.77193,
    b3: 111,
    b4: 23.15789,
    b5: 16.14035,
    b6: 11.22807,
  },
  {
    maesu: 2860,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 16.08392,
    a5: 11.18881,
    a6: 8.74126,
    b3: 111,
    b4: 23.07692,
    b5: 16.08392,
    b6: 11.18881,
  },
  {
    maesu: 2870,
    b2: 148,
    a2: 133.2,
    a3: 22.99652,
    a4: 16.02787,
    a5: 11.49826,
    a6: 8.7108,
    b3: 111,
    b4: 22.99652,
    b5: 16.02787,
    b6: 11.49826,
  },
  {
    maesu: 2880,
    b2: 148,
    a2: 133.2,
    a3: 22.91667,
    a4: 15.97222,
    a5: 11.45833,
    a6: 8.68056,
    b3: 111,
    b4: 22.91667,
    b5: 15.97222,
    b6: 11.45833,
  },
  {
    maesu: 2890,
    b2: 148,
    a2: 133.2,
    a3: 22.83737,
    a4: 15.91696,
    a5: 11.41869,
    a6: 8.65052,
    b3: 111,
    b4: 22.83737,
    b5: 15.91696,
    b6: 11.41869,
  },
  {
    maesu: 2900,
    b2: 148,
    a2: 133.2,
    a3: 23.10345,
    a4: 15.86207,
    a5: 11.37931,
    a6: 8.62069,
    b3: 111,
    b4: 23.10345,
    b5: 15.86207,
    b6: 11.37931,
  },
  {
    maesu: 2910,
    b2: 148,
    a2: 133.2,
    a3: 23.02405,
    a4: 16.1512,
    a5: 11.34021,
    a6: 8.59107,
    b3: 111,
    b4: 23.02405,
    b5: 16.1512,
    b6: 11.34021,
  },
  {
    maesu: 2920,
    b2: 148,
    a2: 133.2,
    a3: 22.94521,
    a4: 16.09589,
    a5: 11.30137,
    a6: 8.56164,
    b3: 111,
    b4: 22.94521,
    b5: 16.09589,
    b6: 11.30137,
  },
  {
    maesu: 2930,
    b2: 148,
    a2: 133.2,
    a3: 22.86689,
    a4: 16.04096,
    a5: 11.2628,
    a6: 8.53242,
    b3: 111,
    b4: 22.86689,
    b5: 16.04096,
    b6: 11.2628,
  },
  {
    maesu: 2940,
    b2: 148,
    a2: 133.2,
    a3: 23.12925,
    a4: 15.98639,
    a5: 11.22449,
    a6: 8.84354,
    b3: 111,
    b4: 23.12925,
    b5: 15.98639,
    b6: 11.22449,
  },
  {
    maesu: 2950,
    b2: 148,
    a2: 133.2,
    a3: 23.05085,
    a4: 15.9322,
    a5: 11.18644,
    a6: 8.81356,
    b3: 111,
    b4: 23.05085,
    b5: 15.9322,
    b6: 11.18644,
  },
  {
    maesu: 2960,
    b2: 148,
    a2: 133.2,
    a3: 22.97297,
    a4: 15.87838,
    a5: 11.48649,
    a6: 8.78378,
    b3: 111,
    b4: 22.97297,
    b5: 15.87838,
    b6: 11.48649,
  },
  {
    maesu: 2970,
    b2: 148,
    a2: 133.2,
    a3: 22.89562,
    a4: 16.16162,
    a5: 11.44781,
    a6: 8.75421,
    b3: 111,
    b4: 22.89562,
    b5: 16.16162,
    b6: 11.44781,
  },
  {
    maesu: 2980,
    b2: 148,
    a2: 133.2,
    a3: 23.15436,
    a4: 16.10738,
    a5: 11.4094,
    a6: 8.72483,
    b3: 111,
    b4: 23.15436,
    b5: 16.10738,
    b6: 11.4094,
  },
  {
    maesu: 2990,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 16.05351,
    a5: 11.37124,
    a6: 8.69565,
    b3: 111,
    b4: 23.07692,
    b5: 16.05351,
    b6: 11.37124,
  },
  {
    maesu: 3000,
    b2: 148,
    a2: 133.2,
    a3: 23,
    a4: 16,
    a5: 11.33333,
    a6: 8.66667,
    b3: 111,
    b4: 23,
    b5: 16,
    b6: 11.33333,
  },
  {
    maesu: 3010,
    b2: 148,
    a2: 133.2,
    a3: 22.92359,
    a4: 15.94684,
    a5: 11.29568,
    a6: 8.63787,
    b3: 111,
    b4: 22.92359,
    b5: 15.94684,
    b6: 11.29568,
  },
  {
    maesu: 3020,
    b2: 148,
    a2: 133.2,
    a3: 22.84768,
    a4: 15.89404,
    a5: 11.25828,
    a6: 8.60927,
    b3: 111,
    b4: 22.84768,
    b5: 15.89404,
    b6: 11.25828,
  },
  {
    maesu: 3030,
    b2: 148,
    a2: 133.2,
    a3: 23.10231,
    a4: 15.84158,
    a5: 11.22112,
    a6: 8.58086,
    b3: 111,
    b4: 23.10231,
    b5: 15.84158,
    b6: 11.22112,
  },
  {
    maesu: 3040,
    b2: 148,
    a2: 133.2,
    a3: 23.02632,
    a4: 16.11842,
    a5: 11.18421,
    a6: 8.55263,
    b3: 111,
    b4: 23.02632,
    b5: 16.11842,
    b6: 11.18421,
  },
  {
    maesu: 3050,
    b2: 148,
    a2: 133.2,
    a3: 22.95082,
    a4: 16.06557,
    a5: 11.14754,
    a6: 8.52459,
    b3: 111,
    b4: 22.95082,
    b5: 16.06557,
    b6: 11.14754,
  },
  {
    maesu: 3060,
    b2: 148,
    a2: 133.2,
    a3: 22.87582,
    a4: 16.01307,
    a5: 11.11111,
    a6: 8.49673,
    b3: 111,
    b4: 22.87582,
    b5: 16.01307,
    b6: 11.11111,
  },
  {
    maesu: 3070,
    b2: 148,
    a2: 133.2,
    a3: 23.12704,
    a4: 15.96091,
    a5: 11.07492,
    a6: 8.46906,
    b3: 111,
    b4: 23.12704,
    b5: 15.96091,
    b6: 11.07492,
  },
  {
    maesu: 3080,
    b2: 148,
    a2: 133.2,
    a3: 23.05195,
    a4: 15.90909,
    a5: 11.36364,
    a6: 8.44156,
    b3: 111,
    b4: 23.05195,
    b5: 15.90909,
    b6: 11.36364,
  },
  {
    maesu: 3090,
    b2: 148,
    a2: 133.2,
    a3: 22.97735,
    a4: 15.85761,
    a5: 11.32686,
    a6: 8.73786,
    b3: 111,
    b4: 22.97735,
    b5: 15.85761,
    b6: 11.32686,
  },
  {
    maesu: 3100,
    b2: 148,
    a2: 133.2,
    a3: 22.90323,
    a4: 16.12903,
    a5: 11.29032,
    a6: 8.70968,
    b3: 111,
    b4: 22.90323,
    b5: 16.12903,
    b6: 11.29032,
  },
  {
    maesu: 3110,
    b2: 148,
    a2: 133.2,
    a3: 23.15113,
    a4: 16.07717,
    a5: 11.25402,
    a6: 8.68167,
    b3: 111,
    b4: 23.15113,
    b5: 16.07717,
    b6: 11.25402,
  },
  {
    maesu: 3120,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 16.02564,
    a5: 11.21795,
    a6: 8.65385,
    b3: 111,
    b4: 23.07692,
    b5: 16.02564,
    b6: 11.21795,
  },
  {
    maesu: 3130,
    b2: 148,
    a2: 133.2,
    a3: 23.00319,
    a4: 15.97444,
    a5: 11.18211,
    a6: 8.6262,
    b3: 111,
    b4: 23.00319,
    b5: 15.97444,
    b6: 11.18211,
  },
  {
    maesu: 3140,
    b2: 148,
    a2: 133.2,
    a3: 22.92994,
    a4: 15.92357,
    a5: 11.1465,
    a6: 8.59873,
    b3: 111,
    b4: 22.92994,
    b5: 15.92357,
    b6: 11.1465,
  },
  {
    maesu: 3150,
    b2: 148,
    a2: 133.2,
    a3: 22.85714,
    a4: 15.87302,
    a5: 11.11111,
    a6: 8.57143,
    b3: 111,
    b4: 22.85714,
    b5: 15.87302,
    b6: 11.11111,
  },
  {
    maesu: 3160,
    b2: 148,
    a2: 133.2,
    a3: 23.10127,
    a4: 16.13924,
    a5: 11.07595,
    a6: 8.5443,
    b3: 111,
    b4: 23.10127,
    b5: 16.13924,
    b6: 11.07595,
  },
  {
    maesu: 3170,
    b2: 148,
    a2: 133.2,
    a3: 23.02839,
    a4: 16.08833,
    a5: 11.04101,
    a6: 8.51735,
    b3: 111,
    b4: 23.02839,
    b5: 16.08833,
    b6: 11.04101,
  },
  {
    maesu: 3180,
    b2: 148,
    a2: 133.2,
    a3: 22.95597,
    a4: 16.03774,
    a5: 11.00629,
    a6: 8.49057,
    b3: 111,
    b4: 22.95597,
    b5: 16.03774,
    b6: 11.00629,
  },
  {
    maesu: 3190,
    b2: 148,
    a2: 133.2,
    a3: 22.88401,
    a4: 15.98746,
    a5: 10.97179,
    a6: 8.46395,
    b3: 111,
    b4: 22.88401,
    b5: 15.98746,
    b6: 10.97179,
  },
  {
    maesu: 3200,
    b2: 148,
    a2: 133.2,
    a3: 23.125,
    a4: 15.9375,
    a5: 10.9375,
    a6: 8.4375,
    b3: 111,
    b4: 23.125,
    b5: 15.9375,
    b6: 10.9375,
  },
  {
    maesu: 3210,
    b2: 148,
    a2: 133.2,
    a3: 23.05296,
    a4: 15.88785,
    a5: 11.21495,
    a6: 8.41121,
    b3: 111,
    b4: 23.05296,
    b5: 15.88785,
    b6: 11.21495,
  },
  {
    maesu: 3220,
    b2: 148,
    a2: 133.2,
    a3: 22.98137,
    a4: 16.14907,
    a5: 11.18012,
    a6: 8.38509,
    b3: 111,
    b4: 22.98137,
    b5: 16.14907,
    b6: 11.18012,
  },
  {
    maesu: 3230,
    b2: 148,
    a2: 133.2,
    a3: 22.91022,
    a4: 16.09907,
    a5: 11.14551,
    a6: 8.35913,
    b3: 111,
    b4: 22.91022,
    b5: 16.09907,
    b6: 11.14551,
  },
  {
    maesu: 3240,
    b2: 148,
    a2: 133.2,
    a3: 23.14815,
    a4: 16.04938,
    a5: 11.11111,
    a6: 8.33333,
    b3: 111,
    b4: 23.14815,
    b5: 16.04938,
    b6: 11.11111,
  },
  {
    maesu: 3250,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 16,
    a5: 11.07692,
    a6: 8.61538,
    b3: 111,
    b4: 23.07692,
    b5: 16,
    b6: 11.07692,
  },
  {
    maesu: 3260,
    b2: 148,
    a2: 133.2,
    a3: 23.00613,
    a4: 15.95092,
    a5: 11.04294,
    a6: 8.58896,
    b3: 111,
    b4: 23.00613,
    b5: 15.95092,
    b6: 11.04294,
  },
  {
    maesu: 3270,
    b2: 148,
    a2: 133.2,
    a3: 22.93578,
    a4: 15.90214,
    a5: 11.00917,
    a6: 8.56269,
    b3: 111,
    b4: 22.93578,
    b5: 15.90214,
    b6: 11.00917,
  },
  {
    maesu: 3280,
    b2: 148,
    a2: 133.2,
    a3: 22.86585,
    a4: 15.85366,
    a5: 10.97561,
    a6: 8.53659,
    b3: 111,
    b4: 22.86585,
    b5: 15.85366,
    b6: 10.97561,
  },
  {
    maesu: 3290,
    b2: 148,
    a2: 133.2,
    a3: 23.1003,
    a4: 16.10942,
    a5: 10.94225,
    a6: 8.51064,
    b3: 111,
    b4: 23.1003,
    b5: 16.10942,
    b6: 10.94225,
  },
  {
    maesu: 3300,
    b2: 148,
    a2: 133.2,
    a3: 23.0303,
    a4: 16.06061,
    a5: 11.21212,
    a6: 8.48485,
    b3: 111,
    b4: 23.0303,
    b5: 16.06061,
    b6: 11.21212,
  },
  {
    maesu: 3310,
    b2: 148,
    a2: 133.2,
    a3: 22.96073,
    a4: 16.01208,
    a5: 11.17825,
    a6: 8.45921,
    b3: 111,
    b4: 22.96073,
    b5: 16.01208,
    b6: 11.17825,
  },
  {
    maesu: 3320,
    b2: 148,
    a2: 133.2,
    a3: 22.89157,
    a4: 15.96386,
    a5: 11.14458,
    a6: 8.43373,
    b3: 111,
    b4: 22.89157,
    b5: 15.96386,
    b6: 11.14458,
  },
  {
    maesu: 3330,
    b2: 148,
    a2: 133.2,
    a3: 23.12312,
    a4: 15.91592,
    a5: 11.11111,
    a6: 8.40841,
    b3: 111,
    b4: 23.12312,
    b5: 15.91592,
    b6: 11.11111,
  },
  {
    maesu: 3340,
    b2: 148,
    a2: 133.2,
    a3: 23.05389,
    a4: 15.86826,
    a5: 11.07784,
    a6: 8.38323,
    b3: 111,
    b4: 23.05389,
    b5: 15.86826,
    b6: 11.07784,
  },
  {
    maesu: 3350,
    b2: 148,
    a2: 133.2,
    a3: 22.98507,
    a4: 16.1194,
    a5: 11.04478,
    a6: 8.35821,
    b3: 111,
    b4: 22.98507,
    b5: 16.1194,
    b6: 11.04478,
  },
  {
    maesu: 3360,
    b2: 148,
    a2: 133.2,
    a3: 22.91667,
    a4: 16.07143,
    a5: 11.0119,
    a6: 8.33333,
    b3: 111,
    b4: 22.91667,
    b5: 16.07143,
    b6: 11.0119,
  },
  {
    maesu: 3370,
    b2: 148,
    a2: 133.2,
    a3: 23.1454,
    a4: 16.02374,
    a5: 10.97923,
    a6: 8.60534,
    b3: 111,
    b4: 23.1454,
    b5: 16.02374,
    b6: 10.97923,
  },
  {
    maesu: 3380,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 15.97633,
    a5: 10.94675,
    a6: 8.57988,
    b3: 111,
    b4: 23.07692,
    b5: 15.97633,
    b6: 10.94675,
  },
  {
    maesu: 3390,
    b2: 148,
    a2: 133.2,
    a3: 23.00885,
    a4: 15.9292,
    a5: 10.91445,
    a6: 8.25959,
    b3: 111,
    b4: 23.00885,
    b5: 15.9292,
    b6: 10.91445,
  },
  {
    maesu: 3400,
    b2: 148,
    a2: 133.2,
    a3: 22.94118,
    a4: 15.88235,
    a5: 10.88235,
    a6: 8.23529,
    b3: 111,
    b4: 22.94118,
    b5: 15.88235,
    b6: 10.88235,
  },
  {
    maesu: 3410,
    b2: 148,
    a2: 133.2,
    a3: 22.8739,
    a4: 16.12903,
    a5: 10.85044,
    a6: 8.5044,
    b3: 111,
    b4: 22.8739,
    b5: 16.12903,
    b6: 10.85044,
  },
  {
    maesu: 3420,
    b2: 148,
    a2: 133.2,
    a3: 23.09942,
    a4: 16.08187,
    a5: 10.81871,
    a6: 8.47953,
    b3: 111,
    b4: 23.09942,
    b5: 16.08187,
    b6: 10.81871,
  },
  {
    maesu: 3430,
    b2: 148,
    a2: 133.2,
    a3: 23.03207,
    a4: 16.03499,
    a5: 11.07872,
    a6: 8.45481,
    b3: 111,
    b4: 23.03207,
    b5: 16.03499,
    b6: 11.07872,
  },
  {
    maesu: 3440,
    b2: 148,
    a2: 133.2,
    a3: 22.96512,
    a4: 15.98837,
    a5: 11.04651,
    a6: 8.43023,
    b3: 111,
    b4: 22.96512,
    b5: 15.98837,
    b6: 11.04651,
  },
  {
    maesu: 3450,
    b2: 148,
    a2: 133.2,
    a3: 22.89855,
    a4: 15.94203,
    a5: 11.01449,
    a6: 8.4058,
    b3: 111,
    b4: 22.89855,
    b5: 15.94203,
    b6: 11.01449,
  },
  {
    maesu: 3460,
    b2: 148,
    a2: 133.2,
    a3: 23.12139,
    a4: 15.89595,
    a5: 10.98266,
    a6: 8.3815,
    b3: 111,
    b4: 23.12139,
    b5: 15.89595,
    b6: 10.98266,
  },
  {
    maesu: 3470,
    b2: 148,
    a2: 133.2,
    a3: 23.05476,
    a4: 16.13833,
    a5: 10.95101,
    a6: 8.35735,
    b3: 111,
    b4: 23.05476,
    b5: 16.13833,
    b6: 10.95101,
  },
  {
    maesu: 3480,
    b2: 148,
    a2: 133.2,
    a3: 22.98851,
    a4: 16.09195,
    a5: 10.91954,
    a6: 8.33333,
    b3: 111,
    b4: 22.98851,
    b5: 16.09195,
    b6: 10.91954,
  },
  {
    maesu: 3490,
    b2: 148,
    a2: 133.2,
    a3: 22.92264,
    a4: 16.04585,
    a5: 10.88825,
    a6: 8.30946,
    b3: 111,
    b4: 22.92264,
    b5: 16.04585,
    b6: 10.88825,
  },
  {
    maesu: 3500,
    b2: 148,
    a2: 133.2,
    a3: 23.14286,
    a4: 16,
    a5: 10.85714,
    a6: 8.28571,
    b3: 111,
    b4: 23.14286,
    b5: 16,
    b6: 10.85714,
  },
  {
    maesu: 3510,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 15.95442,
    a5: 10.82621,
    a6: 8.26211,
    b3: 111,
    b4: 23.07692,
    b5: 15.95442,
    b6: 10.82621,
  },
  {
    maesu: 3520,
    b2: 148,
    a2: 133.2,
    a3: 23.01136,
    a4: 15.90909,
    a5: 10.79545,
    a6: 8.23864,
    b3: 111,
    b4: 23.01136,
    b5: 15.90909,
    b6: 10.79545,
  },
  {
    maesu: 3530,
    b2: 148,
    a2: 133.2,
    a3: 22.94618,
    a4: 15.86402,
    a5: 11.04816,
    a6: 8.49858,
    b3: 111,
    b4: 22.94618,
    b5: 15.86402,
    b6: 11.04816,
  },
  {
    maesu: 3540,
    b2: 148,
    a2: 133.2,
    a3: 22.88136,
    a4: 16.10169,
    a5: 11.01695,
    a6: 8.47458,
    b3: 111,
    b4: 22.88136,
    b5: 16.10169,
    b6: 11.01695,
  },
  {
    maesu: 3550,
    b2: 148,
    a2: 133.2,
    a3: 23.09859,
    a4: 16.05634,
    a5: 10.98592,
    a6: 8.4507,
    b3: 111,
    b4: 23.09859,
    b5: 16.05634,
    b6: 10.98592,
  },
  {
    maesu: 3560,
    b2: 148,
    a2: 133.2,
    a3: 23.03371,
    a4: 16.01124,
    a5: 10.95506,
    a6: 8.42697,
    b3: 111,
    b4: 23.03371,
    b5: 16.01124,
    b6: 10.95506,
  },
  {
    maesu: 3570,
    b2: 148,
    a2: 133.2,
    a3: 22.96919,
    a4: 15.96639,
    a5: 10.92437,
    a6: 8.40336,
    b3: 111,
    b4: 22.96919,
    b5: 15.96639,
    b6: 10.92437,
  },
  {
    maesu: 3580,
    b2: 148,
    a2: 133.2,
    a3: 22.90503,
    a4: 15.92179,
    a5: 10.89385,
    a6: 8.37989,
    b3: 111,
    b4: 22.90503,
    b5: 15.92179,
    b6: 10.89385,
  },
  {
    maesu: 3590,
    b2: 148,
    a2: 133.2,
    a3: 23.11978,
    a4: 15.87744,
    a5: 10.86351,
    a6: 8.35655,
    b3: 111,
    b4: 23.11978,
    b5: 15.87744,
    b6: 10.86351,
  },
  {
    maesu: 3600,
    b2: 148,
    a2: 133.2,
    a3: 23.05556,
    a4: 16.11111,
    a5: 10.83333,
    a6: 8.33333,
    b3: 111,
    b4: 23.05556,
    b5: 16.11111,
    b6: 10.83333,
  },
  {
    maesu: 3610,
    b2: 148,
    a2: 133.2,
    a3: 22.99169,
    a4: 16.06648,
    a5: 10.80332,
    a6: 8.31025,
    b3: 111,
    b4: 22.99169,
    b5: 16.06648,
    b6: 10.80332,
  },
  {
    maesu: 3620,
    b2: 148,
    a2: 133.2,
    a3: 22.92818,
    a4: 16.0221,
    a5: 11.04972,
    a6: 8.28729,
    b3: 111,
    b4: 22.92818,
    b5: 16.0221,
    b6: 11.04972,
  },
  {
    maesu: 3630,
    b2: 148,
    a2: 133.2,
    a3: 22.86501,
    a4: 15.97796,
    a5: 11.01928,
    a6: 8.26446,
    b3: 111,
    b4: 22.86501,
    b5: 15.97796,
    b6: 11.01928,
  },
  {
    maesu: 3640,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 15.93407,
    a5: 10.98901,
    a6: 8.24176,
    b3: 111,
    b4: 23.07692,
    b5: 15.93407,
    b6: 10.98901,
  },
  {
    maesu: 3650,
    b2: 148,
    a2: 133.2,
    a3: 23.0137,
    a4: 15.89041,
    a5: 10.9589,
    a6: 8.21918,
    b3: 111,
    b4: 23.0137,
    b5: 15.89041,
    b6: 10.9589,
  },
  {
    maesu: 3660,
    b2: 148,
    a2: 133.2,
    a3: 22.95082,
    a4: 16.12022,
    a5: 10.92896,
    a6: 8.46995,
    b3: 111,
    b4: 22.95082,
    b5: 16.12022,
    b6: 10.92896,
  },
  {
    maesu: 3670,
    b2: 148,
    a2: 133.2,
    a3: 22.88828,
    a4: 16.07629,
    a5: 10.89918,
    a6: 8.44687,
    b3: 111,
    b4: 22.88828,
    b5: 16.07629,
    b6: 10.89918,
  },
  {
    maesu: 3680,
    b2: 148,
    a2: 133.2,
    a3: 23.09783,
    a4: 16.03261,
    a5: 10.86957,
    a6: 8.42391,
    b3: 111,
    b4: 23.09783,
    b5: 16.03261,
    b6: 10.86957,
  },
  {
    maesu: 3690,
    b2: 148,
    a2: 133.2,
    a3: 23.03523,
    a4: 15.98916,
    a5: 10.84011,
    a6: 8.40108,
    b3: 111,
    b4: 23.03523,
    b5: 15.98916,
    b6: 10.84011,
  },
  {
    maesu: 3700,
    b2: 148,
    a2: 133.2,
    a3: 22.97297,
    a4: 15.94595,
    a5: 10.81081,
    a6: 8.37838,
    b3: 111,
    b4: 22.97297,
    b5: 15.94595,
    b6: 10.81081,
  },
  {
    maesu: 3710,
    b2: 148,
    a2: 133.2,
    a3: 22.91105,
    a4: 15.90296,
    a5: 10.78167,
    a6: 8.3558,
    b3: 111,
    b4: 22.91105,
    b5: 15.90296,
    b6: 10.78167,
  },
  {
    maesu: 3720,
    b2: 148,
    a2: 133.2,
    a3: 23.11828,
    a4: 16.12903,
    a5: 10.75269,
    a6: 8.33333,
    b3: 111,
    b4: 23.11828,
    b5: 16.12903,
    b6: 10.75269,
  },
  {
    maesu: 3730,
    b2: 148,
    a2: 133.2,
    a3: 23.0563,
    a4: 16.08579,
    a5: 10.72386,
    a6: 8.31099,
    b3: 111,
    b4: 23.0563,
    b5: 16.08579,
    b6: 10.72386,
  },
  {
    maesu: 3740,
    b2: 148,
    a2: 133.2,
    a3: 22.99465,
    a4: 16.04278,
    a5: 10.69519,
    a6: 8.28877,
    b3: 111,
    b4: 22.99465,
    b5: 16.04278,
    b6: 10.69519,
  },
  {
    maesu: 3750,
    b2: 148,
    a2: 133.2,
    a3: 22.93333,
    a4: 16,
    a5: 10.66667,
    a6: 8.26667,
    b3: 111,
    b4: 22.93333,
    b5: 16,
    b6: 10.66667,
  },
  {
    maesu: 3760,
    b2: 148,
    a2: 133.2,
    a3: 22.87234,
    a4: 15.95745,
    a5: 10.90426,
    a6: 8.24468,
    b3: 111,
    b4: 22.87234,
    b5: 15.95745,
    b6: 10.90426,
  },
  {
    maesu: 3770,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 15.91512,
    a5: 10.87533,
    a6: 8.22281,
    b3: 111,
    b4: 23.07692,
    b5: 15.91512,
    b6: 10.87533,
  },
  {
    maesu: 3780,
    b2: 148,
    a2: 133.2,
    a3: 23.01587,
    a4: 15.87302,
    a5: 10.84656,
    a6: 8.20106,
    b3: 111,
    b4: 23.01587,
    b5: 15.87302,
    b6: 10.84656,
  },
  {
    maesu: 3790,
    b2: 148,
    a2: 133.2,
    a3: 22.95515,
    a4: 16.09499,
    a5: 10.81794,
    a6: 8.17942,
    b3: 111,
    b4: 22.95515,
    b5: 16.09499,
    b6: 10.81794,
  },
  {
    maesu: 3800,
    b2: 148,
    a2: 133.2,
    a3: 22.89474,
    a4: 16.05263,
    a5: 10.78947,
    a6: 8.15789,
    b3: 111,
    b4: 22.89474,
    b5: 16.05263,
    b6: 10.78947,
  },
  {
    maesu: 3810,
    b2: 148,
    a2: 133.2,
    a3: 23.09711,
    a4: 16.0105,
    a5: 10.76115,
    a6: 8.13648,
    b3: 111,
    b4: 23.09711,
    b5: 16.0105,
    b6: 10.76115,
  },
  {
    maesu: 3820,
    b2: 148,
    a2: 133.2,
    a3: 23.03665,
    a4: 15.96859,
    a5: 10.73298,
    a6: 8.37696,
    b3: 111,
    b4: 23.03665,
    b5: 15.96859,
    b6: 10.73298,
  },
  {
    maesu: 3830,
    b2: 148,
    a2: 133.2,
    a3: 22.9765,
    a4: 15.92689,
    a5: 10.70496,
    a6: 8.35509,
    b3: 111,
    b4: 22.9765,
    b5: 15.92689,
    b6: 10.70496,
  },
  {
    maesu: 3840,
    b2: 148,
    a2: 133.2,
    a3: 22.91667,
    a4: 15.88542,
    a5: 10.67708,
    a6: 8.33333,
    b3: 111,
    b4: 22.91667,
    b5: 15.88542,
    b6: 10.67708,
  },
  {
    maesu: 3850,
    b2: 148,
    a2: 133.2,
    a3: 23.11688,
    a4: 16.1039,
    a5: 10.64935,
    a6: 8.05195,
    b3: 111,
    b4: 23.11688,
    b5: 16.1039,
    b6: 10.64935,
  },
  {
    maesu: 3860,
    b2: 148,
    a2: 133.2,
    a3: 23.05699,
    a4: 16.06218,
    a5: 10.62176,
    a6: 8.03109,
    b3: 111,
    b4: 23.05699,
    b5: 16.06218,
    b6: 10.62176,
  },
  {
    maesu: 3870,
    b2: 148,
    a2: 133.2,
    a3: 22.99742,
    a4: 16.02067,
    a5: 10.59432,
    a6: 8.26873,
    b3: 111,
    b4: 22.99742,
    b5: 16.02067,
    b6: 10.59432,
  },
  {
    maesu: 3880,
    b2: 148,
    a2: 133.2,
    a3: 22.93814,
    a4: 15.97938,
    a5: 10.56701,
    a6: 8.24742,
    b3: 111,
    b4: 22.93814,
    b5: 15.97938,
    b6: 10.56701,
  },
  {
    maesu: 3890,
    b2: 148,
    a2: 133.2,
    a3: 22.87918,
    a4: 15.9383,
    a5: 10.53985,
    a6: 8.22622,
    b3: 111,
    b4: 22.87918,
    b5: 15.9383,
    b6: 10.53985,
  },
  {
    maesu: 3900,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 15.89744,
    a5: 10.76923,
    a6: 8.20513,
    b3: 111,
    b4: 23.07692,
    b5: 15.89744,
    b6: 10.76923,
  },
  {
    maesu: 3910,
    b2: 148,
    a2: 133.2,
    a3: 23.0179,
    a4: 16.11253,
    a5: 10.74169,
    a6: 8.18414,
    b3: 111,
    b4: 23.0179,
    b5: 16.11253,
    b6: 10.74169,
  },
  {
    maesu: 3920,
    b2: 148,
    a2: 133.2,
    a3: 22.95918,
    a4: 16.07143,
    a5: 10.71429,
    a6: 8.16327,
    b3: 111,
    b4: 22.95918,
    b5: 16.07143,
    b6: 10.71429,
  },
  {
    maesu: 3930,
    b2: 148,
    a2: 133.2,
    a3: 22.90076,
    a4: 16.03053,
    a5: 10.68702,
    a6: 8.14249,
    b3: 111,
    b4: 22.90076,
    b5: 16.03053,
    b6: 10.68702,
  },
  {
    maesu: 3940,
    b2: 148,
    a2: 133.2,
    a3: 23.09645,
    a4: 15.98985,
    a5: 10.6599,
    a6: 8.12183,
    b3: 111,
    b4: 23.09645,
    b5: 15.98985,
    b6: 10.6599,
  },
  {
    maesu: 3950,
    b2: 148,
    a2: 133.2,
    a3: 23.03797,
    a4: 15.94937,
    a5: 10.63291,
    a6: 8.10127,
    b3: 111,
    b4: 23.03797,
    b5: 15.94937,
    b6: 10.63291,
  },
  {
    maesu: 3960,
    b2: 148,
    a2: 133.2,
    a3: 22.9798,
    a4: 15.90909,
    a5: 10.60606,
    a6: 8.08081,
    b3: 111,
    b4: 22.9798,
    b5: 15.90909,
    b6: 10.60606,
  },
  {
    maesu: 3970,
    b2: 148,
    a2: 133.2,
    a3: 22.92191,
    a4: 16.12091,
    a5: 10.57935,
    a6: 8.06045,
    b3: 111,
    b4: 22.92191,
    b5: 16.12091,
    b6: 10.57935,
  },
  {
    maesu: 3980,
    b2: 148,
    a2: 133.2,
    a3: 23.11558,
    a4: 16.0804,
    a5: 10.55276,
    a6: 8.0402,
    b3: 111,
    b4: 23.11558,
    b5: 16.0804,
    b6: 10.55276,
  },
  {
    maesu: 3990,
    b2: 148,
    a2: 133.2,
    a3: 23.05764,
    a4: 16.0401,
    a5: 10.52632,
    a6: 8.02005,
    b3: 111,
    b4: 23.05764,
    b5: 16.0401,
    b6: 10.52632,
  },
  {
    maesu: 4000,
    b2: 148,
    a2: 133.2,
    a3: 23,
    a4: 16,
    a5: 10.5,
    a6: 8,
    b3: 111,
    b4: 23,
    b5: 16,
    b6: 10.5,
  },
  {
    maesu: 4010,
    b2: 148,
    a2: 133.2,
    a3: 22.94264,
    a4: 15.9601,
    a5: 10.47382,
    a6: 7.98005,
    b3: 111,
    b4: 22.94264,
    b5: 15.9601,
    b6: 10.47382,
  },
  {
    maesu: 4020,
    b2: 148,
    a2: 133.2,
    a3: 22.88557,
    a4: 15.9204,
    a5: 10.44776,
    a6: 7.9602,
    b3: 111,
    b4: 22.88557,
    b5: 15.9204,
    b6: 10.44776,
  },
  {
    maesu: 4030,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 15.88089,
    a5: 10.42184,
    a6: 7.94045,
    b3: 111,
    b4: 23.07692,
    b5: 15.88089,
    b6: 10.42184,
  },
  {
    maesu: 4040,
    b2: 148,
    a2: 133.2,
    a3: 23.0198,
    a4: 16.08911,
    a5: 10.39604,
    a6: 7.92079,
    b3: 111,
    b4: 23.0198,
    b5: 16.08911,
    b6: 10.39604,
  },
  {
    maesu: 4050,
    b2: 148,
    a2: 133.2,
    a3: 22.96296,
    a4: 16.04938,
    a5: 10.61728,
    a6: 7.90123,
    b3: 111,
    b4: 22.96296,
    b5: 16.04938,
    b6: 10.61728,
  },
  {
    maesu: 4060,
    b2: 148,
    a2: 133.2,
    a3: 22.9064,
    a4: 16.00985,
    a5: 10.59113,
    a6: 7.88177,
    b3: 111,
    b4: 22.9064,
    b5: 16.00985,
    b6: 10.59113,
  },
  {
    maesu: 4070,
    b2: 148,
    a2: 133.2,
    a3: 23.09582,
    a4: 15.97052,
    a5: 10.56511,
    a6: 7.86241,
    b3: 111,
    b4: 23.09582,
    b5: 15.97052,
    b6: 10.56511,
  },
  {
    maesu: 4080,
    b2: 148,
    a2: 133.2,
    a3: 23.03922,
    a4: 15.93137,
    a5: 10.53922,
    a6: 7.84314,
    b3: 111,
    b4: 23.03922,
    b5: 15.93137,
    b6: 10.53922,
  },
  {
    maesu: 4090,
    b2: 148,
    a2: 133.2,
    a3: 22.98289,
    a4: 15.89242,
    a5: 10.51345,
    a6: 7.82396,
    b3: 111,
    b4: 22.98289,
    b5: 15.89242,
    b6: 10.51345,
  },
  {
    maesu: 4100,
    b2: 148,
    a2: 133.2,
    a3: 22.92683,
    a4: 16.09756,
    a5: 10.4878,
    a6: 8.04878,
    b3: 111,
    b4: 22.92683,
    b5: 16.09756,
    b6: 10.4878,
  },
  {
    maesu: 4110,
    b2: 148,
    a2: 133.2,
    a3: 23.11436,
    a4: 16.05839,
    a5: 10.46229,
    a6: 8.0292,
    b3: 111,
    b4: 23.11436,
    b5: 16.05839,
    b6: 10.46229,
  },
  {
    maesu: 4120,
    b2: 148,
    a2: 133.2,
    a3: 23.05825,
    a4: 16.01942,
    a5: 10.43689,
    a6: 8.00971,
    b3: 111,
    b4: 23.05825,
    b5: 16.01942,
    b6: 10.43689,
  },
  {
    maesu: 4130,
    b2: 148,
    a2: 133.2,
    a3: 23.00242,
    a4: 15.98063,
    a5: 10.41162,
    a6: 7.99031,
    b3: 111,
    b4: 23.00242,
    b5: 15.98063,
    b6: 10.41162,
  },
  {
    maesu: 4140,
    b2: 148,
    a2: 133.2,
    a3: 22.94686,
    a4: 15.94203,
    a5: 10.38647,
    a6: 7.97101,
    b3: 111,
    b4: 22.94686,
    b5: 15.94203,
    b6: 10.38647,
  },
  {
    maesu: 4150,
    b2: 148,
    a2: 133.2,
    a3: 22.89157,
    a4: 15.90361,
    a5: 10.60241,
    a6: 7.95181,
    b3: 111,
    b4: 22.89157,
    b5: 15.90361,
    b6: 10.60241,
  },
  {
    maesu: 4160,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 16.10577,
    a5: 10.57692,
    a6: 7.93269,
    b3: 111,
    b4: 23.07692,
    b5: 16.10577,
    b6: 10.57692,
  },
  {
    maesu: 4170,
    b2: 148,
    a2: 133.2,
    a3: 23.02158,
    a4: 16.06715,
    a5: 10.55156,
    a6: 7.91367,
    b3: 111,
    b4: 23.02158,
    b5: 16.06715,
    b6: 10.55156,
  },
  {
    maesu: 4180,
    b2: 148,
    a2: 133.2,
    a3: 22.96651,
    a4: 16.02871,
    a5: 10.52632,
    a6: 7.89474,
    b3: 111,
    b4: 22.96651,
    b5: 16.02871,
    b6: 10.52632,
  },
  {
    maesu: 4190,
    b2: 148,
    a2: 133.2,
    a3: 22.91169,
    a4: 15.99045,
    a5: 10.50119,
    a6: 7.87589,
    b3: 111,
    b4: 22.91169,
    b5: 15.99045,
    b6: 10.50119,
  },
  {
    maesu: 4200,
    b2: 148,
    a2: 133.2,
    a3: 23.09524,
    a4: 15.95238,
    a5: 10.47619,
    a6: 7.85714,
    b3: 111,
    b4: 23.09524,
    b5: 15.95238,
    b6: 10.47619,
  },
  {
    maesu: 4210,
    b2: 148,
    a2: 133.2,
    a3: 23.04038,
    a4: 15.91449,
    a5: 10.45131,
    a6: 7.83848,
    b3: 111,
    b4: 23.04038,
    b5: 15.91449,
    b6: 10.45131,
  },
  {
    maesu: 4220,
    b2: 148,
    a2: 133.2,
    a3: 22.98578,
    a4: 16.11374,
    a5: 10.42654,
    a6: 8.05687,
    b3: 111,
    b4: 22.98578,
    b5: 16.11374,
    b6: 10.42654,
  },
  {
    maesu: 4230,
    b2: 148,
    a2: 133.2,
    a3: 22.93144,
    a4: 16.07565,
    a5: 10.40189,
    a6: 8.03783,
    b3: 111,
    b4: 22.93144,
    b5: 16.07565,
    b6: 10.40189,
  },
  {
    maesu: 4240,
    b2: 148,
    a2: 133.2,
    a3: 23.11321,
    a4: 16.03774,
    a5: 10.61321,
    a6: 8.01887,
    b3: 111,
    b4: 23.11321,
    b5: 16.03774,
    b6: 10.61321,
  },
  {
    maesu: 4250,
    b2: 148,
    a2: 133.2,
    a3: 23.05882,
    a4: 16,
    a5: 10.58824,
    a6: 8,
    b3: 111,
    b4: 23.05882,
    b5: 16,
    b6: 10.58824,
  },
  {
    maesu: 4260,
    b2: 148,
    a2: 133.2,
    a3: 23.00469,
    a4: 15.96244,
    a5: 10.56338,
    a6: 7.98122,
    b3: 111,
    b4: 23.00469,
    b5: 15.96244,
    b6: 10.56338,
  },
  {
    maesu: 4270,
    b2: 148,
    a2: 133.2,
    a3: 22.95082,
    a4: 15.92506,
    a5: 10.53864,
    a6: 7.96253,
    b3: 111,
    b4: 22.95082,
    b5: 15.92506,
    b6: 10.53864,
  },
  {
    maesu: 4280,
    b2: 148,
    a2: 133.2,
    a3: 22.8972,
    a4: 15.88785,
    a5: 10.51402,
    a6: 7.94393,
    b3: 111,
    b4: 22.8972,
    b5: 15.88785,
    b6: 10.51402,
  },
  {
    maesu: 4290,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 16.08392,
    a5: 10.48951,
    a6: 7.92541,
    b3: 111,
    b4: 23.07692,
    b5: 16.08392,
    b6: 10.48951,
  },
  {
    maesu: 4300,
    b2: 148,
    a2: 133.2,
    a3: 23.02326,
    a4: 16.04651,
    a5: 10.46512,
    a6: 7.90698,
    b3: 111,
    b4: 23.02326,
    b5: 16.04651,
    b6: 10.46512,
  },
  {
    maesu: 4310,
    b2: 148,
    a2: 133.2,
    a3: 22.96984,
    a4: 16.00928,
    a5: 10.44084,
    a6: 7.88863,
    b3: 111,
    b4: 22.96984,
    b5: 16.00928,
    b6: 10.44084,
  },
  {
    maesu: 4320,
    b2: 148,
    a2: 133.2,
    a3: 22.91667,
    a4: 15.97222,
    a5: 10.41667,
    a6: 7.87037,
    b3: 111,
    b4: 22.91667,
    b5: 15.97222,
    b6: 10.41667,
  },
  {
    maesu: 4330,
    b2: 148,
    a2: 133.2,
    a3: 23.09469,
    a4: 15.93533,
    a5: 10.39261,
    a6: 7.85219,
    b3: 111,
    b4: 23.09469,
    b5: 15.93533,
    b6: 10.39261,
  },
  {
    maesu: 4340,
    b2: 148,
    a2: 133.2,
    a3: 23.04147,
    a4: 15.89862,
    a5: 10.59908,
    a6: 7.8341,
    b3: 111,
    b4: 23.04147,
    b5: 15.89862,
    b6: 10.59908,
  },
  {
    maesu: 4350,
    b2: 148,
    a2: 133.2,
    a3: 22.98851,
    a4: 16.09195,
    a5: 10.57471,
    a6: 8.04598,
    b3: 111,
    b4: 22.98851,
    b5: 16.09195,
    b6: 10.57471,
  },
  {
    maesu: 4360,
    b2: 148,
    a2: 133.2,
    a3: 22.93578,
    a4: 16.05505,
    a5: 10.55046,
    a6: 8.02752,
    b3: 111,
    b4: 22.93578,
    b5: 16.05505,
    b6: 10.55046,
  },
  {
    maesu: 4370,
    b2: 148,
    a2: 133.2,
    a3: 23.11213,
    a4: 16.01831,
    a5: 10.52632,
    a6: 8.00915,
    b3: 111,
    b4: 23.11213,
    b5: 16.01831,
    b6: 10.52632,
  },
  {
    maesu: 4380,
    b2: 148,
    a2: 133.2,
    a3: 23.05936,
    a4: 15.98174,
    a5: 10.50228,
    a6: 7.99087,
    b3: 111,
    b4: 23.05936,
    b5: 15.98174,
    b6: 10.50228,
  },
  {
    maesu: 4390,
    b2: 148,
    a2: 133.2,
    a3: 23.00683,
    a4: 15.94533,
    a5: 10.47836,
    a6: 7.97267,
    b3: 111,
    b4: 23.00683,
    b5: 15.94533,
    b6: 10.47836,
  },
  {
    maesu: 4400,
    b2: 148,
    a2: 133.2,
    a3: 22.95455,
    a4: 15.90909,
    a5: 10.45455,
    a6: 7.95455,
    b3: 111,
    b4: 22.95455,
    b5: 15.90909,
    b6: 10.45455,
  },
  {
    maesu: 4410,
    b2: 148,
    a2: 133.2,
    a3: 22.90249,
    a4: 16.09977,
    a5: 10.43084,
    a6: 7.93651,
    b3: 111,
    b4: 22.90249,
    b5: 16.09977,
    b6: 10.43084,
  },
  {
    maesu: 4420,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 16.06335,
    a5: 10.40724,
    a6: 7.91855,
    b3: 111,
    b4: 23.07692,
    b5: 16.06335,
    b6: 10.40724,
  },
  {
    maesu: 4430,
    b2: 148,
    a2: 133.2,
    a3: 23.02483,
    a4: 16.02709,
    a5: 10.60948,
    a6: 7.90068,
    b3: 111,
    b4: 23.02483,
    b5: 16.02709,
    b6: 10.60948,
  },
  {
    maesu: 4440,
    b2: 148,
    a2: 133.2,
    a3: 22.97297,
    a4: 15.99099,
    a5: 10.58559,
    a6: 7.88288,
    b3: 111,
    b4: 22.97297,
    b5: 15.99099,
    b6: 10.58559,
  },
  {
    maesu: 4450,
    b2: 148,
    a2: 133.2,
    a3: 22.92135,
    a4: 15.95506,
    a5: 10.5618,
    a6: 7.86517,
    b3: 111,
    b4: 22.92135,
    b5: 15.95506,
    b6: 10.5618,
  },
  {
    maesu: 4460,
    b2: 148,
    a2: 133.2,
    a3: 23.09417,
    a4: 15.91928,
    a5: 10.53812,
    a6: 7.84753,
    b3: 111,
    b4: 23.09417,
    b5: 15.91928,
    b6: 10.53812,
  },
  {
    maesu: 4470,
    b2: 148,
    a2: 133.2,
    a3: 23.04251,
    a4: 16.10738,
    a5: 10.51454,
    a6: 7.82998,
    b3: 111,
    b4: 23.04251,
    b5: 16.10738,
    b6: 10.51454,
  },
  {
    maesu: 4480,
    b2: 148,
    a2: 133.2,
    a3: 22.99107,
    a4: 16.07143,
    a5: 10.49107,
    a6: 8.03571,
    b3: 111,
    b4: 22.99107,
    b5: 16.07143,
    b6: 10.49107,
  },
  {
    maesu: 4490,
    b2: 148,
    a2: 133.2,
    a3: 22.93987,
    a4: 16.03563,
    a5: 10.46771,
    a6: 8.01782,
    b3: 111,
    b4: 22.93987,
    b5: 16.03563,
    b6: 10.46771,
  },
  {
    maesu: 4500,
    b2: 148,
    a2: 133.2,
    a3: 23.11111,
    a4: 16,
    a5: 10.44444,
    a6: 8,
    b3: 111,
    b4: 23.11111,
    b5: 16,
    b6: 10.44444,
  },
  {
    maesu: 4510,
    b2: 148,
    a2: 133.2,
    a3: 23.05987,
    a4: 15.96452,
    a5: 10.42129,
    a6: 7.98226,
    b3: 111,
    b4: 23.05987,
    b5: 15.96452,
    b6: 10.42129,
  },
  {
    maesu: 4520,
    b2: 148,
    a2: 133.2,
    a3: 23.00885,
    a4: 15.9292,
    a5: 10.39823,
    a6: 7.9646,
    b3: 111,
    b4: 23.00885,
    b5: 15.9292,
    b6: 10.39823,
  },
  {
    maesu: 4530,
    b2: 148,
    a2: 133.2,
    a3: 22.95806,
    a4: 15.89404,
    a5: 10.59603,
    a6: 7.94702,
    b3: 111,
    b4: 22.95806,
    b5: 15.89404,
    b6: 10.59603,
  },
  {
    maesu: 4540,
    b2: 148,
    a2: 133.2,
    a3: 22.90749,
    a4: 16.0793,
    a5: 10.57269,
    a6: 7.92952,
    b3: 111,
    b4: 22.90749,
    b5: 16.0793,
    b6: 10.57269,
  },
  {
    maesu: 4550,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 16.04396,
    a5: 10.54945,
    a6: 7.91209,
    b3: 111,
    b4: 23.07692,
    b5: 16.04396,
    b6: 10.54945,
  },
  {
    maesu: 4560,
    b2: 148,
    a2: 133.2,
    a3: 23.02632,
    a4: 16.00877,
    a5: 10.52632,
    a6: 7.89474,
    b3: 111,
    b4: 23.02632,
    b5: 16.00877,
    b6: 10.52632,
  },
  {
    maesu: 4570,
    b2: 148,
    a2: 133.2,
    a3: 22.97593,
    a4: 15.97374,
    a5: 10.50328,
    a6: 7.87746,
    b3: 111,
    b4: 22.97593,
    b5: 15.97374,
    b6: 10.50328,
  },
  {
    maesu: 4580,
    b2: 148,
    a2: 133.2,
    a3: 22.92576,
    a4: 15.93886,
    a5: 10.48035,
    a6: 7.86026,
    b3: 111,
    b4: 22.92576,
    b5: 15.93886,
    b6: 10.48035,
  },
  {
    maesu: 4590,
    b2: 148,
    a2: 133.2,
    a3: 23.09368,
    a4: 15.90414,
    a5: 10.45752,
    a6: 7.84314,
    b3: 111,
    b4: 23.09368,
    b5: 15.90414,
    b6: 10.45752,
  },
  {
    maesu: 4600,
    b2: 148,
    a2: 133.2,
    a3: 23.04348,
    a4: 16.08696,
    a5: 10.43478,
    a6: 7.82609,
    b3: 111,
    b4: 23.04348,
    b5: 16.08696,
    b6: 10.43478,
  },
  {
    maesu: 4610,
    b2: 148,
    a2: 133.2,
    a3: 22.99349,
    a4: 16.05206,
    a5: 10.41215,
    a6: 8.02603,
    b3: 111,
    b4: 22.99349,
    b5: 16.05206,
    b6: 10.41215,
  },
  {
    maesu: 4620,
    b2: 148,
    a2: 133.2,
    a3: 22.94372,
    a4: 16.01732,
    a5: 10.60606,
    a6: 8.00866,
    b3: 111,
    b4: 22.94372,
    b5: 16.01732,
    b6: 10.60606,
  },
  {
    maesu: 4630,
    b2: 148,
    a2: 133.2,
    a3: 22.89417,
    a4: 15.98272,
    a5: 10.58315,
    a6: 7.99136,
    b3: 111,
    b4: 22.89417,
    b5: 15.98272,
    b6: 10.58315,
  },
  {
    maesu: 4640,
    b2: 148,
    a2: 133.2,
    a3: 23.06034,
    a4: 15.94828,
    a5: 10.56034,
    a6: 7.97414,
    b3: 111,
    b4: 23.06034,
    b5: 15.94828,
    b6: 10.56034,
  },
  {
    maesu: 4650,
    b2: 148,
    a2: 133.2,
    a3: 23.01075,
    a4: 15.91398,
    a5: 10.53763,
    a6: 7.95699,
    b3: 111,
    b4: 23.01075,
    b5: 15.91398,
    b6: 10.53763,
  },
  {
    maesu: 4660,
    b2: 148,
    a2: 133.2,
    a3: 22.96137,
    a4: 16.09442,
    a5: 10.51502,
    a6: 7.93991,
    b3: 111,
    b4: 22.96137,
    b5: 16.09442,
    b6: 10.51502,
  },
  {
    maesu: 4670,
    b2: 148,
    a2: 133.2,
    a3: 22.91221,
    a4: 16.05996,
    a5: 10.49251,
    a6: 7.92291,
    b3: 111,
    b4: 22.91221,
    b5: 16.05996,
    b6: 10.49251,
  },
  {
    maesu: 4680,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 16.02564,
    a5: 10.47009,
    a6: 7.90598,
    b3: 111,
    b4: 23.07692,
    b5: 16.02564,
    b6: 10.47009,
  },
  {
    maesu: 4690,
    b2: 148,
    a2: 133.2,
    a3: 23.02772,
    a4: 15.99147,
    a5: 10.44776,
    a6: 7.88913,
    b3: 111,
    b4: 23.02772,
    b5: 15.99147,
    b6: 10.44776,
  },
  {
    maesu: 4700,
    b2: 148,
    a2: 133.2,
    a3: 22.97872,
    a4: 15.95745,
    a5: 10.42553,
    a6: 7.87234,
    b3: 111,
    b4: 22.97872,
    b5: 15.95745,
    b6: 10.42553,
  },
  {
    maesu: 4710,
    b2: 148,
    a2: 133.2,
    a3: 22.92994,
    a4: 15.92357,
    a5: 10.4034,
    a6: 7.85563,
    b3: 111,
    b4: 22.92994,
    b5: 15.92357,
    b6: 10.4034,
  },
  {
    maesu: 4720,
    b2: 148,
    a2: 133.2,
    a3: 23.09322,
    a4: 16.10169,
    a5: 10.59322,
    a6: 7.83898,
    b3: 111,
    b4: 23.09322,
    b5: 16.10169,
    b6: 10.59322,
  },
  {
    maesu: 4730,
    b2: 148,
    a2: 133.2,
    a3: 23.0444,
    a4: 16.06765,
    a5: 10.57082,
    a6: 7.82241,
    b3: 111,
    b4: 23.0444,
    b5: 16.06765,
    b6: 10.57082,
  },
  {
    maesu: 4740,
    b2: 148,
    a2: 133.2,
    a3: 22.99578,
    a4: 16.03376,
    a5: 10.54852,
    a6: 8.01688,
    b3: 111,
    b4: 22.99578,
    b5: 16.03376,
    b6: 10.54852,
  },
  {
    maesu: 4750,
    b2: 148,
    a2: 133.2,
    a3: 22.94737,
    a4: 16,
    a5: 10.52632,
    a6: 8,
    b3: 111,
    b4: 22.94737,
    b5: 16,
    b6: 10.52632,
  },
  {
    maesu: 4760,
    b2: 148,
    a2: 133.2,
    a3: 22.89916,
    a4: 15.96639,
    a5: 10.5042,
    a6: 7.98319,
    b3: 111,
    b4: 22.89916,
    b5: 15.96639,
    b6: 10.5042,
  },
  {
    maesu: 4770,
    b2: 148,
    a2: 133.2,
    a3: 23.0608,
    a4: 15.93291,
    a5: 10.48218,
    a6: 7.96646,
    b3: 111,
    b4: 23.0608,
    b5: 15.93291,
    b6: 10.48218,
  },
  {
    maesu: 4780,
    b2: 148,
    a2: 133.2,
    a3: 23.01255,
    a4: 15.89958,
    a5: 10.46025,
    a6: 7.94979,
    b3: 111,
    b4: 23.01255,
    b5: 15.89958,
    b6: 10.46025,
  },
  {
    maesu: 4790,
    b2: 148,
    a2: 133.2,
    a3: 22.96451,
    a4: 16.07516,
    a5: 10.43841,
    a6: 7.93319,
    b3: 111,
    b4: 22.96451,
    b5: 16.07516,
    b6: 10.43841,
  },
  {
    maesu: 4800,
    b2: 148,
    a2: 133.2,
    a3: 22.91667,
    a4: 16.04167,
    a5: 10.41667,
    a6: 7.91667,
    b3: 111,
    b4: 22.91667,
    b5: 16.04167,
    b6: 10.41667,
  },
  {
    maesu: 4810,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 16.00832,
    a5: 10.60291,
    a6: 7.90021,
    b3: 111,
    b4: 23.07692,
    b5: 16.00832,
    b6: 10.60291,
  },
  {
    maesu: 4820,
    b2: 148,
    a2: 133.2,
    a3: 23.02905,
    a4: 15.9751,
    a5: 10.58091,
    a6: 7.88382,
    b3: 111,
    b4: 23.02905,
    b5: 15.9751,
    b6: 10.58091,
  },
  {
    maesu: 4830,
    b2: 148,
    a2: 133.2,
    a3: 22.98137,
    a4: 15.94203,
    a5: 10.55901,
    a6: 7.86749,
    b3: 111,
    b4: 22.98137,
    b5: 15.94203,
    b6: 10.55901,
  },
  {
    maesu: 4840,
    b2: 148,
    a2: 133.2,
    a3: 22.93388,
    a4: 15.90909,
    a5: 10.53719,
    a6: 7.85124,
    b3: 111,
    b4: 22.93388,
    b5: 15.90909,
    b6: 10.53719,
  },
  {
    maesu: 4850,
    b2: 148,
    a2: 133.2,
    a3: 23.09278,
    a4: 16.08247,
    a5: 10.51546,
    a6: 7.83505,
    b3: 111,
    b4: 23.09278,
    b5: 16.08247,
    b6: 10.51546,
  },
  {
    maesu: 4860,
    b2: 148,
    a2: 133.2,
    a3: 23.04527,
    a4: 16.04938,
    a5: 10.49383,
    a6: 8.02469,
    b3: 111,
    b4: 23.04527,
    b5: 16.04938,
    b6: 10.49383,
  },
  {
    maesu: 4870,
    b2: 148,
    a2: 133.2,
    a3: 22.99795,
    a4: 16.01643,
    a5: 10.47228,
    a6: 8.00821,
    b3: 111,
    b4: 22.99795,
    b5: 16.01643,
    b6: 10.47228,
  },
  {
    maesu: 4880,
    b2: 148,
    a2: 133.2,
    a3: 22.95082,
    a4: 15.98361,
    a5: 10.45082,
    a6: 7.9918,
    b3: 111,
    b4: 22.95082,
    b5: 15.98361,
    b6: 10.45082,
  },
  {
    maesu: 4890,
    b2: 148,
    a2: 133.2,
    a3: 22.90389,
    a4: 15.95092,
    a5: 10.42945,
    a6: 7.97546,
    b3: 111,
    b4: 22.90389,
    b5: 15.95092,
    b6: 10.42945,
  },
  {
    maesu: 4900,
    b2: 148,
    a2: 133.2,
    a3: 23.06122,
    a4: 15.91837,
    a5: 10.40816,
    a6: 7.95918,
    b3: 111,
    b4: 23.06122,
    b5: 15.91837,
    b6: 10.40816,
  },
  {
    maesu: 4910,
    b2: 148,
    a2: 133.2,
    a3: 23.01426,
    a4: 16.08961,
    a5: 10.59063,
    a6: 7.94297,
    b3: 111,
    b4: 23.01426,
    b5: 16.08961,
    b6: 10.59063,
  },
  {
    maesu: 4920,
    b2: 148,
    a2: 133.2,
    a3: 22.96748,
    a4: 16.05691,
    a5: 10.56911,
    a6: 7.92683,
    b3: 111,
    b4: 22.96748,
    b5: 16.05691,
    b6: 10.56911,
  },
  {
    maesu: 4930,
    b2: 148,
    a2: 133.2,
    a3: 22.92089,
    a4: 16.02434,
    a5: 10.54767,
    a6: 7.91075,
    b3: 111,
    b4: 22.92089,
    b5: 16.02434,
    b6: 10.54767,
  },
  {
    maesu: 4940,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 15.9919,
    a5: 10.52632,
    a6: 7.89474,
    b3: 111,
    b4: 23.07692,
    b5: 15.9919,
    b6: 10.52632,
  },
  {
    maesu: 4950,
    b2: 148,
    a2: 133.2,
    a3: 23.0303,
    a4: 15.9596,
    a5: 10.50505,
    a6: 7.87879,
    b3: 111,
    b4: 23.0303,
    b5: 15.9596,
    b6: 10.50505,
  },
  {
    maesu: 4960,
    b2: 148,
    a2: 133.2,
    a3: 22.98387,
    a4: 15.92742,
    a5: 10.48387,
    a6: 7.8629,
    b3: 111,
    b4: 22.98387,
    b5: 15.92742,
    b6: 10.48387,
  },
  {
    maesu: 4970,
    b2: 148,
    a2: 133.2,
    a3: 22.93763,
    a4: 16.09658,
    a5: 10.46278,
    a6: 7.84708,
    b3: 111,
    b4: 22.93763,
    b5: 16.09658,
    b6: 10.46278,
  },
  {
    maesu: 4980,
    b2: 148,
    a2: 133.2,
    a3: 23.09237,
    a4: 16.06426,
    a5: 10.44177,
    a6: 7.83133,
    b3: 111,
    b4: 23.09237,
    b5: 16.06426,
    b6: 10.44177,
  },
  {
    maesu: 4990,
    b2: 148,
    a2: 133.2,
    a3: 23.04609,
    a4: 16.03206,
    a5: 10.42084,
    a6: 7.81563,
    b3: 111,
    b4: 23.04609,
    b5: 16.03206,
    b6: 10.42084,
  },
  {
    maesu: 5000,
    b2: 148,
    a2: 133.2,
    a3: 23,
    a4: 16,
    a5: 10.6,
    a6: 7.8,
    b3: 111,
    b4: 23,
    b5: 16,
    b6: 10.6,
  },
  {
    maesu: 5010,
    b2: 148,
    a2: 133.2,
    a3: 22.95409,
    a4: 15.96806,
    a5: 10.57884,
    a6: 7.78443,
    b3: 111,
    b4: 22.95409,
    b5: 15.96806,
    b6: 10.57884,
  },
  {
    maesu: 5020,
    b2: 148,
    a2: 133.2,
    a3: 22.90837,
    a4: 15.93625,
    a5: 10.55777,
    a6: 7.76892,
    b3: 111,
    b4: 22.90837,
    b5: 15.93625,
    b6: 10.55777,
  },
  {
    maesu: 5030,
    b2: 148,
    a2: 133.2,
    a3: 23.06163,
    a4: 15.90457,
    a5: 10.53678,
    a6: 7.75348,
    b3: 111,
    b4: 23.06163,
    b5: 15.90457,
    b6: 10.53678,
  },
  {
    maesu: 5040,
    b2: 148,
    a2: 133.2,
    a3: 23.01587,
    a4: 16.07143,
    a5: 10.51587,
    a6: 7.7381,
    b3: 111,
    b4: 23.01587,
    b5: 16.07143,
    b6: 10.51587,
  },
  {
    maesu: 5050,
    b2: 148,
    a2: 133.2,
    a3: 22.9703,
    a4: 16.0396,
    a5: 10.49505,
    a6: 7.72277,
    b3: 111,
    b4: 22.9703,
    b5: 16.0396,
    b6: 10.49505,
  },
  {
    maesu: 5060,
    b2: 148,
    a2: 133.2,
    a3: 22.9249,
    a4: 16.00791,
    a5: 10.47431,
    a6: 7.70751,
    b3: 111,
    b4: 22.9249,
    b5: 16.00791,
    b6: 10.47431,
  },
  {
    maesu: 5070,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 15.97633,
    a5: 10.45365,
    a6: 7.69231,
    b3: 111,
    b4: 23.07692,
    b5: 15.97633,
    b6: 10.45365,
  },
  {
    maesu: 5080,
    b2: 148,
    a2: 133.2,
    a3: 23.0315,
    a4: 15.94488,
    a5: 10.43307,
    a6: 7.67717,
    b3: 111,
    b4: 23.0315,
    b5: 15.94488,
    b6: 10.43307,
  },
  {
    maesu: 5090,
    b2: 148,
    a2: 133.2,
    a3: 22.98625,
    a4: 15.91356,
    a5: 10.41257,
    a6: 7.66208,
    b3: 111,
    b4: 22.98625,
    b5: 15.91356,
    b6: 10.41257,
  },
  {
    maesu: 5100,
    b2: 148,
    a2: 133.2,
    a3: 22.94118,
    a4: 16.07843,
    a5: 10.58824,
    a6: 7.64706,
    b3: 111,
    b4: 22.94118,
    b5: 16.07843,
    b6: 10.58824,
  },
  {
    maesu: 5110,
    b2: 148,
    a2: 133.2,
    a3: 23.09198,
    a4: 16.04697,
    a5: 10.56751,
    a6: 7.63209,
    b3: 111,
    b4: 23.09198,
    b5: 16.04697,
    b6: 10.56751,
  },
  {
    maesu: 5120,
    b2: 148,
    a2: 133.2,
    a3: 23.04688,
    a4: 16.01562,
    a5: 10.54688,
    a6: 7.8125,
    b3: 111,
    b4: 23.04688,
    b5: 16.01562,
    b6: 10.54688,
  },
  {
    maesu: 5130,
    b2: 148,
    a2: 133.2,
    a3: 23.00195,
    a4: 15.98441,
    a5: 10.52632,
    a6: 7.79727,
    b3: 111,
    b4: 23.00195,
    b5: 15.98441,
    b6: 10.52632,
  },
  {
    maesu: 5140,
    b2: 148,
    a2: 133.2,
    a3: 22.9572,
    a4: 15.95331,
    a5: 10.50584,
    a6: 7.7821,
    b3: 111,
    b4: 22.9572,
    b5: 15.95331,
    b6: 10.50584,
  },
  {
    maesu: 5150,
    b2: 148,
    a2: 133.2,
    a3: 22.91262,
    a4: 15.92233,
    a5: 10.48544,
    a6: 7.76699,
    b3: 111,
    b4: 22.91262,
    b5: 15.92233,
    b6: 10.48544,
  },
  {
    maesu: 5160,
    b2: 148,
    a2: 133.2,
    a3: 23.06202,
    a4: 16.08527,
    a5: 10.46512,
    a6: 7.75194,
    b3: 111,
    b4: 23.06202,
    b5: 16.08527,
    b6: 10.46512,
  },
  {
    maesu: 5170,
    b2: 148,
    a2: 133.2,
    a3: 23.01741,
    a4: 16.05416,
    a5: 10.44487,
    a6: 7.73694,
    b3: 111,
    b4: 23.01741,
    b5: 16.05416,
    b6: 10.44487,
  },
  {
    maesu: 5180,
    b2: 148,
    a2: 133.2,
    a3: 22.97297,
    a4: 16.02317,
    a5: 10.42471,
    a6: 7.72201,
    b3: 111,
    b4: 22.97297,
    b5: 16.02317,
    b6: 10.42471,
  },
  {
    maesu: 5190,
    b2: 148,
    a2: 133.2,
    a3: 22.92871,
    a4: 15.99229,
    a5: 10.40462,
    a6: 7.70713,
    b3: 111,
    b4: 22.92871,
    b5: 15.99229,
    b6: 10.40462,
  },
  {
    maesu: 5200,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 15.96154,
    a5: 10.57692,
    a6: 7.69231,
    b3: 111,
    b4: 23.07692,
    b5: 15.96154,
    b6: 10.57692,
  },
  {
    maesu: 5210,
    b2: 148,
    a2: 133.2,
    a3: 23.03263,
    a4: 15.9309,
    a5: 10.55662,
    a6: 7.67754,
    b3: 111,
    b4: 23.03263,
    b5: 15.9309,
    b6: 10.55662,
  },
  {
    maesu: 5220,
    b2: 148,
    a2: 133.2,
    a3: 22.98851,
    a4: 16.09195,
    a5: 10.5364,
    a6: 7.66284,
    b3: 111,
    b4: 22.98851,
    b5: 16.09195,
    b6: 10.5364,
  },
  {
    maesu: 5230,
    b2: 148,
    a2: 133.2,
    a3: 22.94455,
    a4: 16.06119,
    a5: 10.51625,
    a6: 7.64818,
    b3: 111,
    b4: 22.94455,
    b5: 16.06119,
    b6: 10.51625,
  },
  {
    maesu: 5240,
    b2: 148,
    a2: 133.2,
    a3: 23.0916,
    a4: 16.03053,
    a5: 10.49618,
    a6: 7.63359,
    b3: 111,
    b4: 23.0916,
    b5: 16.03053,
    b6: 10.49618,
  },
  {
    maesu: 5250,
    b2: 148,
    a2: 133.2,
    a3: 23.04762,
    a4: 16,
    a5: 10.47619,
    a6: 7.80952,
    b3: 111,
    b4: 23.04762,
    b5: 16,
    b6: 10.47619,
  },
  {
    maesu: 5260,
    b2: 148,
    a2: 133.2,
    a3: 23.0038,
    a4: 15.96958,
    a5: 10.45627,
    a6: 7.79468,
    b3: 111,
    b4: 23.0038,
    b5: 15.96958,
    b6: 10.45627,
  },
  {
    maesu: 5270,
    b2: 148,
    a2: 133.2,
    a3: 22.96015,
    a4: 15.93928,
    a5: 10.43643,
    a6: 7.77989,
    b3: 111,
    b4: 22.96015,
    b5: 15.93928,
    b6: 10.43643,
  },
  {
    maesu: 5280,
    b2: 148,
    a2: 133.2,
    a3: 22.91667,
    a4: 15.90909,
    a5: 10.41667,
    a6: 7.76515,
    b3: 111,
    b4: 22.91667,
    b5: 15.90909,
    b6: 10.41667,
  },
  {
    maesu: 5290,
    b2: 148,
    a2: 133.2,
    a3: 23.06238,
    a4: 16.06805,
    a5: 10.58601,
    a6: 7.75047,
    b3: 111,
    b4: 23.06238,
    b5: 16.06805,
    b6: 10.58601,
  },
  {
    maesu: 5300,
    b2: 148,
    a2: 133.2,
    a3: 23.01887,
    a4: 16.03774,
    a5: 10.56604,
    a6: 7.73585,
    b3: 111,
    b4: 23.01887,
    b5: 16.03774,
    b6: 10.56604,
  },
  {
    maesu: 5310,
    b2: 148,
    a2: 133.2,
    a3: 22.97552,
    a4: 16.00753,
    a5: 10.54614,
    a6: 7.72128,
    b3: 111,
    b4: 22.97552,
    b5: 16.00753,
    b6: 10.54614,
  },
  {
    maesu: 5320,
    b2: 148,
    a2: 133.2,
    a3: 22.93233,
    a4: 15.97744,
    a5: 10.52632,
    a6: 7.70677,
    b3: 111,
    b4: 22.93233,
    b5: 15.97744,
    b6: 10.52632,
  },
  {
    maesu: 5330,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 15.94747,
    a5: 10.50657,
    a6: 7.69231,
    b3: 111,
    b4: 23.07692,
    b5: 15.94747,
    b6: 10.50657,
  },
  {
    maesu: 5340,
    b2: 148,
    a2: 133.2,
    a3: 23.03371,
    a4: 15.9176,
    a5: 10.48689,
    a6: 7.6779,
    b3: 111,
    b4: 23.03371,
    b5: 15.9176,
    b6: 10.48689,
  },
  {
    maesu: 5350,
    b2: 148,
    a2: 133.2,
    a3: 22.99065,
    a4: 16.07477,
    a5: 10.46729,
    a6: 7.66355,
    b3: 111,
    b4: 22.99065,
    b5: 16.07477,
    b6: 10.46729,
  },
  {
    maesu: 5360,
    b2: 148,
    a2: 133.2,
    a3: 22.94776,
    a4: 16.04478,
    a5: 10.44776,
    a6: 7.64925,
    b3: 111,
    b4: 22.94776,
    b5: 16.04478,
    b6: 10.44776,
  },
  {
    maesu: 5370,
    b2: 148,
    a2: 133.2,
    a3: 23.09125,
    a4: 16.0149,
    a5: 10.42831,
    a6: 7.63501,
    b3: 111,
    b4: 23.09125,
    b5: 16.0149,
    b6: 10.42831,
  },
  {
    maesu: 5380,
    b2: 148,
    a2: 133.2,
    a3: 23.04833,
    a4: 15.98513,
    a5: 10.40892,
    a6: 7.62082,
    b3: 111,
    b4: 23.04833,
    b5: 15.98513,
    b6: 10.40892,
  },
  {
    maesu: 5390,
    b2: 148,
    a2: 133.2,
    a3: 23.00557,
    a4: 15.95547,
    a5: 10.57514,
    a6: 7.60668,
    b3: 111,
    b4: 23.00557,
    b5: 15.95547,
    b6: 10.57514,
  },
  {
    maesu: 5400,
    b2: 148,
    a2: 133.2,
    a3: 22.96296,
    a4: 15.92593,
    a5: 10.55556,
    a6: 7.59259,
    b3: 111,
    b4: 22.96296,
    b5: 15.92593,
    b6: 10.55556,
  },
  {
    maesu: 5410,
    b2: 148,
    a2: 133.2,
    a3: 22.92052,
    a4: 16.08133,
    a5: 10.53604,
    a6: 7.57856,
    b3: 111,
    b4: 22.92052,
    b5: 16.08133,
    b6: 10.53604,
  },
  {
    maesu: 5420,
    b2: 148,
    a2: 133.2,
    a3: 23.06273,
    a4: 16.05166,
    a5: 10.51661,
    a6: 7.56458,
    b3: 111,
    b4: 23.06273,
    b5: 16.05166,
    b6: 10.51661,
  },
  {
    maesu: 5430,
    b2: 148,
    a2: 133.2,
    a3: 23.02026,
    a4: 16.0221,
    a5: 10.49724,
    a6: 7.55064,
    b3: 111,
    b4: 23.02026,
    b5: 16.0221,
    b6: 10.49724,
  },
  {
    maesu: 5440,
    b2: 148,
    a2: 133.2,
    a3: 22.97794,
    a4: 15.99265,
    a5: 10.47794,
    a6: 7.53676,
    b3: 111,
    b4: 22.97794,
    b5: 15.99265,
    b6: 10.47794,
  },
  {
    maesu: 5450,
    b2: 148,
    a2: 133.2,
    a3: 22.93578,
    a4: 15.9633,
    a5: 10.45872,
    a6: 7.52294,
    b3: 111,
    b4: 22.93578,
    b5: 15.9633,
    b6: 10.45872,
  },
  {
    maesu: 5460,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 15.93407,
    a5: 10.43956,
    a6: 7.50916,
    b3: 111,
    b4: 23.07692,
    b5: 15.93407,
    b6: 10.43956,
  },
  {
    maesu: 5470,
    b2: 148,
    a2: 133.2,
    a3: 23.03473,
    a4: 16.08775,
    a5: 10.42048,
    a6: 7.49543,
    b3: 111,
    b4: 23.03473,
    b5: 16.08775,
    b6: 10.42048,
  },
  {
    maesu: 5480,
    b2: 148,
    a2: 133.2,
    a3: 22.9927,
    a4: 16.05839,
    a5: 10.58394,
    a6: 7.48175,
    b3: 111,
    b4: 22.9927,
    b5: 16.05839,
    b6: 10.58394,
  },
  {
    maesu: 5490,
    b2: 148,
    a2: 133.2,
    a3: 22.95082,
    a4: 16.02914,
    a5: 10.56466,
    a6: 7.46812,
    b3: 111,
    b4: 22.95082,
    b5: 16.02914,
    b6: 10.56466,
  },
  {
    maesu: 5500,
    b2: 148,
    a2: 133.2,
    a3: 23.09091,
    a4: 16,
    a5: 10.54545,
    a6: 7.45455,
    b3: 111,
    b4: 23.09091,
    b5: 16,
    b6: 10.54545,
  },
  {
    maesu: 5510,
    b2: 148,
    a2: 133.2,
    a3: 23.049,
    a4: 15.97096,
    a5: 10.52632,
    a6: 7.44102,
    b3: 111,
    b4: 23.049,
    b5: 15.97096,
    b6: 10.52632,
  },
  {
    maesu: 5520,
    b2: 148,
    a2: 133.2,
    a3: 23.00725,
    a4: 15.94203,
    a5: 10.50725,
    a6: 7.42754,
    b3: 111,
    b4: 23.00725,
    b5: 15.94203,
    b6: 10.50725,
  },
  {
    maesu: 5530,
    b2: 148,
    a2: 133.2,
    a3: 22.96564,
    a4: 15.9132,
    a5: 10.48825,
    a6: 7.59494,
    b3: 111,
    b4: 22.96564,
    b5: 15.9132,
    b6: 10.48825,
  },
  {
    maesu: 5540,
    b2: 148,
    a2: 133.2,
    a3: 22.92419,
    a4: 16.06498,
    a5: 10.46931,
    a6: 7.58123,
    b3: 111,
    b4: 22.92419,
    b5: 16.06498,
    b6: 10.46931,
  },
  {
    maesu: 5550,
    b2: 148,
    a2: 133.2,
    a3: 23.06306,
    a4: 16.03604,
    a5: 10.45045,
    a6: 7.56757,
    b3: 111,
    b4: 23.06306,
    b5: 16.03604,
    b6: 10.45045,
  },
  {
    maesu: 5560,
    b2: 148,
    a2: 133.2,
    a3: 23.02158,
    a4: 16.00719,
    a5: 10.43165,
    a6: 7.55396,
    b3: 111,
    b4: 23.02158,
    b5: 16.00719,
    b6: 10.43165,
  },
  {
    maesu: 5570,
    b2: 148,
    a2: 133.2,
    a3: 22.98025,
    a4: 15.97846,
    a5: 10.41293,
    a6: 7.54039,
    b3: 111,
    b4: 22.98025,
    b5: 15.97846,
    b6: 10.41293,
  },
  {
    maesu: 5580,
    b2: 148,
    a2: 133.2,
    a3: 22.93907,
    a4: 15.94982,
    a5: 10.57348,
    a6: 7.52688,
    b3: 111,
    b4: 22.93907,
    b5: 15.94982,
    b6: 10.57348,
  },
  {
    maesu: 5590,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 15.92129,
    a5: 10.55456,
    a6: 7.51342,
    b3: 111,
    b4: 23.07692,
    b5: 15.92129,
    b6: 10.55456,
  },
  {
    maesu: 5600,
    b2: 148,
    a2: 133.2,
    a3: 23.03571,
    a4: 16.07143,
    a5: 10.53571,
    a6: 7.5,
    b3: 111,
    b4: 23.03571,
    b5: 16.07143,
    b6: 10.53571,
  },
  {
    maesu: 5610,
    b2: 148,
    a2: 133.2,
    a3: 22.99465,
    a4: 16.04278,
    a5: 10.51693,
    a6: 7.48663,
    b3: 111,
    b4: 22.99465,
    b5: 16.04278,
    b6: 10.51693,
  },
  {
    maesu: 5620,
    b2: 148,
    a2: 133.2,
    a3: 22.95374,
    a4: 16.01423,
    a5: 10.49822,
    a6: 7.47331,
    b3: 111,
    b4: 22.95374,
    b5: 16.01423,
    b6: 10.49822,
  },
  {
    maesu: 5630,
    b2: 148,
    a2: 133.2,
    a3: 22.91297,
    a4: 15.98579,
    a5: 10.47957,
    a6: 7.46004,
    b3: 111,
    b4: 22.91297,
    b5: 15.98579,
    b6: 10.47957,
  },
  {
    maesu: 5640,
    b2: 148,
    a2: 133.2,
    a3: 23.04965,
    a4: 15.95745,
    a5: 10.46099,
    a6: 7.44681,
    b3: 111,
    b4: 23.04965,
    b5: 15.95745,
    b6: 10.46099,
  },
  {
    maesu: 5650,
    b2: 148,
    a2: 133.2,
    a3: 23.00885,
    a4: 15.9292,
    a5: 10.44248,
    a6: 7.43363,
    b3: 111,
    b4: 23.00885,
    b5: 15.9292,
    b6: 10.44248,
  },
  {
    maesu: 5660,
    b2: 148,
    a2: 133.2,
    a3: 22.9682,
    a4: 16.07774,
    a5: 10.42403,
    a6: 7.42049,
    b3: 111,
    b4: 22.9682,
    b5: 16.07774,
    b6: 10.42403,
  },
  {
    maesu: 5670,
    b2: 148,
    a2: 133.2,
    a3: 22.92769,
    a4: 16.04938,
    a5: 10.58201,
    a6: 7.40741,
    b3: 111,
    b4: 22.92769,
    b5: 16.04938,
    b6: 10.58201,
  },
  {
    maesu: 5680,
    b2: 148,
    a2: 133.2,
    a3: 23.06338,
    a4: 16.02113,
    a5: 10.56338,
    a6: 7.39437,
    b3: 111,
    b4: 23.06338,
    b5: 16.02113,
    b6: 10.56338,
  },
  {
    maesu: 5690,
    b2: 148,
    a2: 133.2,
    a3: 23.02285,
    a4: 15.99297,
    a5: 10.54482,
    a6: 7.38137,
    b3: 111,
    b4: 23.02285,
    b5: 15.99297,
    b6: 10.54482,
  },
  {
    maesu: 5700,
    b2: 148,
    a2: 133.2,
    a3: 22.98246,
    a4: 15.96491,
    a5: 10.52632,
    a6: 7.36842,
    b3: 111,
    b4: 22.98246,
    b5: 15.96491,
    b6: 10.52632,
  },
  {
    maesu: 5710,
    b2: 148,
    a2: 133.2,
    a3: 22.94221,
    a4: 15.93695,
    a5: 10.50788,
    a6: 7.35552,
    b3: 111,
    b4: 22.94221,
    b5: 15.93695,
    b6: 10.50788,
  },
  {
    maesu: 5720,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 16.08392,
    a5: 10.48951,
    a6: 7.34266,
    b3: 111,
    b4: 23.07692,
    b5: 16.08392,
    b6: 10.48951,
  },
  {
    maesu: 5730,
    b2: 148,
    a2: 133.2,
    a3: 23.03665,
    a4: 16.05585,
    a5: 10.4712,
    a6: 7.32984,
    b3: 111,
    b4: 23.03665,
    b5: 16.05585,
    b6: 10.4712,
  },
  {
    maesu: 5740,
    b2: 148,
    a2: 133.2,
    a3: 22.99652,
    a4: 16.02787,
    a5: 10.45296,
    a6: 7.31707,
    b3: 111,
    b4: 22.99652,
    b5: 16.02787,
    b6: 10.45296,
  },
  {
    maesu: 5750,
    b2: 148,
    a2: 133.2,
    a3: 22.95652,
    a4: 16,
    a5: 10.43478,
    a6: 7.30435,
    b3: 111,
    b4: 22.95652,
    b5: 16,
    b6: 10.43478,
  },
  {
    maesu: 5760,
    b2: 148,
    a2: 133.2,
    a3: 22.91667,
    a4: 15.97222,
    a5: 10.41667,
    a6: 7.29167,
    b3: 111,
    b4: 22.91667,
    b5: 15.97222,
    b6: 10.41667,
  },
  {
    maesu: 5770,
    b2: 148,
    a2: 133.2,
    a3: 23.05026,
    a4: 15.94454,
    a5: 10.57192,
    a6: 7.27903,
    b3: 111,
    b4: 23.05026,
    b5: 15.94454,
    b6: 10.57192,
  },
  {
    maesu: 5780,
    b2: 148,
    a2: 133.2,
    a3: 23.01038,
    a4: 15.91696,
    a5: 10.55363,
    a6: 7.26644,
    b3: 111,
    b4: 23.01038,
    b5: 15.91696,
    b6: 10.55363,
  },
  {
    maesu: 5790,
    b2: 148,
    a2: 133.2,
    a3: 22.97064,
    a4: 16.06218,
    a5: 10.53541,
    a6: 7.25389,
    b3: 111,
    b4: 22.97064,
    b5: 16.06218,
    b6: 10.53541,
  },
  {
    maesu: 5800,
    b2: 148,
    a2: 133.2,
    a3: 22.93103,
    a4: 16.03448,
    a5: 10.51724,
    a6: 7.24138,
    b3: 111,
    b4: 22.93103,
    b5: 16.03448,
    b6: 10.51724,
  },
  {
    maesu: 5810,
    b2: 148,
    a2: 133.2,
    a3: 23.06368,
    a4: 16.00688,
    a5: 10.49914,
    a6: 7.22892,
    b3: 111,
    b4: 23.06368,
    b5: 16.00688,
    b6: 10.49914,
  },
  {
    maesu: 5820,
    b2: 148,
    a2: 133.2,
    a3: 23.02405,
    a4: 15.97938,
    a5: 10.4811,
    a6: 7.21649,
    b3: 111,
    b4: 23.02405,
    b5: 15.97938,
    b6: 10.4811,
  },
  {
    maesu: 5830,
    b2: 148,
    a2: 133.2,
    a3: 22.98456,
    a4: 15.95197,
    a5: 10.46312,
    a6: 7.20412,
    b3: 111,
    b4: 22.98456,
    b5: 15.95197,
    b6: 10.46312,
  },
  {
    maesu: 5840,
    b2: 148,
    a2: 133.2,
    a3: 22.94521,
    a4: 15.92466,
    a5: 10.44521,
    a6: 7.19178,
    b3: 111,
    b4: 22.94521,
    b5: 15.92466,
    b6: 10.44521,
  },
  {
    maesu: 5850,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 16.06838,
    a5: 10.42735,
    a6: 7.17949,
    b3: 111,
    b4: 23.07692,
    b5: 16.06838,
    b6: 10.42735,
  },
  {
    maesu: 5860,
    b2: 148,
    a2: 133.2,
    a3: 23.03754,
    a4: 16.04096,
    a5: 10.5802,
    a6: 7.16724,
    b3: 111,
    b4: 23.03754,
    b5: 16.04096,
    b6: 10.5802,
  },
  {
    maesu: 5870,
    b2: 148,
    a2: 133.2,
    a3: 22.9983,
    a4: 16.01363,
    a5: 10.56218,
    a6: 7.15503,
    b3: 111,
    b4: 22.9983,
    b5: 16.01363,
    b6: 10.56218,
  },
  {
    maesu: 5880,
    b2: 148,
    a2: 133.2,
    a3: 22.95918,
    a4: 15.98639,
    a5: 10.54422,
    a6: 7.14286,
    b3: 111,
    b4: 22.95918,
    b5: 15.98639,
    b6: 10.54422,
  },
  {
    maesu: 5890,
    b2: 148,
    a2: 133.2,
    a3: 22.9202,
    a4: 15.95925,
    a5: 10.52632,
    a6: 7.13073,
    b3: 111,
    b4: 22.9202,
    b5: 15.95925,
    b6: 10.52632,
  },
  {
    maesu: 5900,
    b2: 148,
    a2: 133.2,
    a3: 23.05085,
    a4: 15.9322,
    a5: 10.50847,
    a6: 7.11864,
    b3: 111,
    b4: 23.05085,
    b5: 15.9322,
    b6: 10.50847,
  },
  {
    maesu: 5910,
    b2: 148,
    a2: 133.2,
    a3: 23.01184,
    a4: 16.07445,
    a5: 10.49069,
    a6: 7.1066,
    b3: 111,
    b4: 23.01184,
    b5: 16.07445,
    b6: 10.49069,
  },
  {
    maesu: 5920,
    b2: 148,
    a2: 133.2,
    a3: 22.97297,
    a4: 16.0473,
    a5: 10.47297,
    a6: 7.09459,
    b3: 111,
    b4: 22.97297,
    b5: 16.0473,
    b6: 10.47297,
  },
  {
    maesu: 5930,
    b2: 148,
    a2: 133.2,
    a3: 22.93423,
    a4: 16.02024,
    a5: 10.45531,
    a6: 7.08263,
    b3: 111,
    b4: 22.93423,
    b5: 16.02024,
    b6: 10.45531,
  },
  {
    maesu: 5940,
    b2: 148,
    a2: 133.2,
    a3: 23.06397,
    a4: 15.99327,
    a5: 10.43771,
    a6: 7.07071,
    b3: 111,
    b4: 23.06397,
    b5: 15.99327,
    b6: 10.43771,
  },
  {
    maesu: 5950,
    b2: 148,
    a2: 133.2,
    a3: 23.02521,
    a4: 15.96639,
    a5: 10.42017,
    a6: 7.05882,
    b3: 111,
    b4: 23.02521,
    b5: 15.96639,
    b6: 10.42017,
  },
  {
    maesu: 5960,
    b2: 148,
    a2: 133.2,
    a3: 22.98658,
    a4: 15.9396,
    a5: 10.57047,
    a6: 7.04698,
    b3: 111,
    b4: 22.98658,
    b5: 15.9396,
    b6: 10.57047,
  },
  {
    maesu: 5970,
    b2: 148,
    a2: 133.2,
    a3: 22.94807,
    a4: 16.0804,
    a5: 10.55276,
    a6: 7.03518,
    b3: 111,
    b4: 22.94807,
    b5: 16.0804,
    b6: 10.55276,
  },
  {
    maesu: 5980,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 16.05351,
    a5: 10.53512,
    a6: 7.02341,
    b3: 111,
    b4: 23.07692,
    b5: 16.05351,
    b6: 10.53512,
  },
  {
    maesu: 5990,
    b2: 148,
    a2: 133.2,
    a3: 23.0384,
    a4: 16.02671,
    a5: 10.51753,
    a6: 7.01169,
    b3: 111,
    b4: 23.0384,
    b5: 16.02671,
    b6: 10.51753,
  },
  {
    maesu: 6000,
    b2: 148,
    a2: 133.2,
    a3: 23,
    a4: 16,
    a5: 10.5,
    a6: 7,
    b3: 111,
    b4: 23,
    b5: 16,
    b6: 10.5,
  },
  {
    maesu: 6010,
    b2: 148,
    a2: 133.2,
    a3: 22.96173,
    a4: 15.97338,
    a5: 10.48253,
    a6: 6.98835,
    b3: 111,
    b4: 22.96173,
    b5: 15.97338,
    b6: 10.48253,
  },
  {
    maesu: 6020,
    b2: 148,
    a2: 133.2,
    a3: 22.92359,
    a4: 15.94684,
    a5: 10.46512,
    a6: 6.97674,
    b3: 111,
    b4: 22.92359,
    b5: 15.94684,
    b6: 10.46512,
  },
  {
    maesu: 6030,
    b2: 148,
    a2: 133.2,
    a3: 23.05141,
    a4: 15.9204,
    a5: 10.44776,
    a6: 6.96517,
    b3: 111,
    b4: 23.05141,
    b5: 15.9204,
    b6: 10.44776,
  },
  {
    maesu: 6040,
    b2: 148,
    a2: 133.2,
    a3: 23.01325,
    a4: 16.0596,
    a5: 10.43046,
    a6: 6.95364,
    b3: 111,
    b4: 23.01325,
    b5: 16.0596,
    b6: 10.43046,
  },
  {
    maesu: 6050,
    b2: 148,
    a2: 133.2,
    a3: 22.97521,
    a4: 16.03306,
    a5: 10.57851,
    a6: 6.94215,
    b3: 111,
    b4: 22.97521,
    b5: 16.03306,
    b6: 10.57851,
  },
  {
    maesu: 6060,
    b2: 148,
    a2: 133.2,
    a3: 22.93729,
    a4: 16.0066,
    a5: 10.56106,
    a6: 6.93069,
    b3: 111,
    b4: 22.93729,
    b5: 16.0066,
    b6: 10.56106,
  },
  {
    maesu: 6070,
    b2: 148,
    a2: 133.2,
    a3: 23.06425,
    a4: 15.98023,
    a5: 10.54366,
    a6: 7.08402,
    b3: 111,
    b4: 23.06425,
    b5: 15.98023,
    b6: 10.54366,
  },
  {
    maesu: 6080,
    b2: 148,
    a2: 133.2,
    a3: 23.02632,
    a4: 15.95395,
    a5: 10.52632,
    a6: 7.07237,
    b3: 111,
    b4: 23.02632,
    b5: 15.95395,
    b6: 10.52632,
  },
  {
    maesu: 6090,
    b2: 148,
    a2: 133.2,
    a3: 22.98851,
    a4: 15.92775,
    a5: 10.50903,
    a6: 7.06076,
    b3: 111,
    b4: 22.98851,
    b5: 15.92775,
    b6: 10.50903,
  },
  {
    maesu: 6100,
    b2: 148,
    a2: 133.2,
    a3: 22.95082,
    a4: 16.06557,
    a5: 10.4918,
    a6: 7.04918,
    b3: 111,
    b4: 22.95082,
    b5: 16.06557,
    b6: 10.4918,
  },
  {
    maesu: 6110,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 16.03928,
    a5: 10.47463,
    a6: 7.03764,
    b3: 111,
    b4: 23.07692,
    b5: 16.03928,
    b6: 10.47463,
  },
  {
    maesu: 6120,
    b2: 148,
    a2: 133.2,
    a3: 23.03922,
    a4: 16.01307,
    a5: 10.45752,
    a6: 7.02614,
    b3: 111,
    b4: 23.03922,
    b5: 16.01307,
    b6: 10.45752,
  },
  {
    maesu: 6130,
    b2: 148,
    a2: 133.2,
    a3: 23.00163,
    a4: 15.98695,
    a5: 10.44046,
    a6: 7.01468,
    b3: 111,
    b4: 23.00163,
    b5: 15.98695,
    b6: 10.44046,
  },
  {
    maesu: 6140,
    b2: 148,
    a2: 133.2,
    a3: 22.96417,
    a4: 15.96091,
    a5: 10.42345,
    a6: 7.00326,
    b3: 111,
    b4: 22.96417,
    b5: 15.96091,
    b6: 10.42345,
  },
  {
    maesu: 6150,
    b2: 148,
    a2: 133.2,
    a3: 22.92683,
    a4: 15.93496,
    a5: 10.56911,
    a6: 6.99187,
    b3: 111,
    b4: 22.92683,
    b5: 15.93496,
    b6: 10.56911,
  },
  {
    maesu: 6160,
    b2: 148,
    a2: 133.2,
    a3: 23.05195,
    a4: 16.07143,
    a5: 10.55195,
    a6: 6.98052,
    b3: 111,
    b4: 23.05195,
    b5: 16.07143,
    b6: 10.55195,
  },
  {
    maesu: 6170,
    b2: 148,
    a2: 133.2,
    a3: 23.01459,
    a4: 16.04538,
    a5: 10.53485,
    a6: 6.96921,
    b3: 111,
    b4: 23.01459,
    b5: 16.04538,
    b6: 10.53485,
  },
  {
    maesu: 6180,
    b2: 148,
    a2: 133.2,
    a3: 22.97735,
    a4: 16.01942,
    a5: 10.5178,
    a6: 6.95793,
    b3: 111,
    b4: 22.97735,
    b5: 16.01942,
    b6: 10.5178,
  },
  {
    maesu: 6190,
    b2: 148,
    a2: 133.2,
    a3: 22.94023,
    a4: 15.99354,
    a5: 10.50081,
    a6: 6.94669,
    b3: 111,
    b4: 22.94023,
    b5: 15.99354,
    b6: 10.50081,
  },
  {
    maesu: 6200,
    b2: 148,
    a2: 133.2,
    a3: 23.06452,
    a4: 15.96774,
    a5: 10.48387,
    a6: 6.93548,
    b3: 111,
    b4: 23.06452,
    b5: 15.96774,
    b6: 10.48387,
  },
  {
    maesu: 6210,
    b2: 148,
    a2: 133.2,
    a3: 23.02738,
    a4: 15.94203,
    a5: 10.46699,
    a6: 6.92432,
    b3: 111,
    b4: 23.02738,
    b5: 15.94203,
    b6: 10.46699,
  },
  {
    maesu: 6220,
    b2: 148,
    a2: 133.2,
    a3: 22.99035,
    a4: 16.07717,
    a5: 10.45016,
    a6: 6.91318,
    b3: 111,
    b4: 22.99035,
    b5: 16.07717,
    b6: 10.45016,
  },
  {
    maesu: 6230,
    b2: 148,
    a2: 133.2,
    a3: 22.95345,
    a4: 16.05136,
    a5: 10.43339,
    a6: 6.90209,
    b3: 111,
    b4: 22.95345,
    b5: 16.05136,
    b6: 10.43339,
  },
  {
    maesu: 6240,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 16.02564,
    a5: 10.57692,
    a6: 6.89103,
    b3: 111,
    b4: 23.07692,
    b5: 16.02564,
    b6: 10.57692,
  },
  {
    maesu: 6250,
    b2: 148,
    a2: 133.2,
    a3: 23.04,
    a4: 16,
    a5: 10.56,
    a6: 6.88,
    b3: 111,
    b4: 23.04,
    b5: 16,
    b6: 10.56,
  },
  {
    maesu: 6260,
    b2: 148,
    a2: 133.2,
    a3: 23.00319,
    a4: 15.97444,
    a5: 10.54313,
    a6: 6.86901,
    b3: 111,
    b4: 23.00319,
    b5: 15.97444,
    b6: 10.54313,
  },
  {
    maesu: 6270,
    b2: 148,
    a2: 133.2,
    a3: 22.96651,
    a4: 15.94896,
    a5: 10.52632,
    a6: 6.85805,
    b3: 111,
    b4: 22.96651,
    b5: 15.94896,
    b6: 10.52632,
  },
  {
    maesu: 6280,
    b2: 148,
    a2: 133.2,
    a3: 22.92994,
    a4: 15.92357,
    a5: 10.50955,
    a6: 6.84713,
    b3: 111,
    b4: 22.92994,
    b5: 15.92357,
    b6: 10.50955,
  },
  {
    maesu: 6290,
    b2: 148,
    a2: 133.2,
    a3: 23.05246,
    a4: 16.05723,
    a5: 10.49285,
    a6: 6.83625,
    b3: 111,
    b4: 23.05246,
    b5: 16.05723,
    b6: 10.49285,
  },
  {
    maesu: 6300,
    b2: 148,
    a2: 133.2,
    a3: 23.01587,
    a4: 16.03175,
    a5: 10.47619,
    a6: 6.8254,
    b3: 111,
    b4: 23.01587,
    b5: 16.03175,
    b6: 10.47619,
  },
  {
    maesu: 6310,
    b2: 148,
    a2: 133.2,
    a3: 22.9794,
    a4: 16.00634,
    a5: 10.45959,
    a6: 6.81458,
    b3: 111,
    b4: 22.9794,
    b5: 16.00634,
    b6: 10.45959,
  },
  {
    maesu: 6320,
    b2: 148,
    a2: 133.2,
    a3: 22.94304,
    a4: 15.98101,
    a5: 10.44304,
    a6: 6.8038,
    b3: 111,
    b4: 22.94304,
    b5: 15.98101,
    b6: 10.44304,
  },
  {
    maesu: 6330,
    b2: 148,
    a2: 133.2,
    a3: 23.06477,
    a4: 15.95577,
    a5: 10.42654,
    a6: 6.79305,
    b3: 111,
    b4: 23.06477,
    b5: 15.95577,
    b6: 10.42654,
  },
  {
    maesu: 6340,
    b2: 148,
    a2: 133.2,
    a3: 23.02839,
    a4: 15.9306,
    a5: 10.56782,
    a6: 6.78233,
    b3: 111,
    b4: 23.02839,
    b5: 15.9306,
    b6: 10.56782,
  },
  {
    maesu: 6350,
    b2: 148,
    a2: 133.2,
    a3: 22.99213,
    a4: 16.06299,
    a5: 10.55118,
    a6: 6.77165,
    b3: 111,
    b4: 22.99213,
    b5: 16.06299,
    b6: 10.55118,
  },
  {
    maesu: 6360,
    b2: 148,
    a2: 133.2,
    a3: 22.95597,
    a4: 16.03774,
    a5: 10.53459,
    a6: 6.76101,
    b3: 111,
    b4: 22.95597,
    b5: 16.03774,
    b6: 10.53459,
  },
  {
    maesu: 6370,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 16.01256,
    a5: 10.51805,
    a6: 6.75039,
    b3: 111,
    b4: 23.07692,
    b5: 16.01256,
    b6: 10.51805,
  },
  {
    maesu: 6380,
    b2: 148,
    a2: 133.2,
    a3: 23.04075,
    a4: 15.98746,
    a5: 10.50157,
    a6: 6.73981,
    b3: 111,
    b4: 23.04075,
    b5: 15.98746,
    b6: 10.50157,
  },
  {
    maesu: 6390,
    b2: 148,
    a2: 133.2,
    a3: 23.00469,
    a4: 15.96244,
    a5: 10.48513,
    a6: 6.88576,
    b3: 111,
    b4: 23.00469,
    b5: 15.96244,
    b6: 10.48513,
  },
  {
    maesu: 6400,
    b2: 148,
    a2: 133.2,
    a3: 22.96875,
    a4: 15.9375,
    a5: 10.46875,
    a6: 6.875,
    b3: 111,
    b4: 22.96875,
    b5: 15.9375,
    b6: 10.46875,
  },
  {
    maesu: 6410,
    b2: 148,
    a2: 133.2,
    a3: 22.93292,
    a4: 16.06864,
    a5: 10.45242,
    a6: 6.86427,
    b3: 111,
    b4: 22.93292,
    b5: 16.06864,
    b6: 10.45242,
  },
  {
    maesu: 6420,
    b2: 148,
    a2: 133.2,
    a3: 23.05296,
    a4: 16.04361,
    a5: 10.43614,
    a6: 6.85358,
    b3: 111,
    b4: 23.05296,
    b5: 16.04361,
    b6: 10.43614,
  },
  {
    maesu: 6430,
    b2: 148,
    a2: 133.2,
    a3: 23.01711,
    a4: 16.01866,
    a5: 10.57543,
    a6: 6.84292,
    b3: 111,
    b4: 23.01711,
    b5: 16.01866,
    b6: 10.57543,
  },
  {
    maesu: 6440,
    b2: 148,
    a2: 133.2,
    a3: 22.98137,
    a4: 15.99379,
    a5: 10.55901,
    a6: 6.8323,
    b3: 111,
    b4: 22.98137,
    b5: 15.99379,
    b6: 10.55901,
  },
  {
    maesu: 6450,
    b2: 148,
    a2: 133.2,
    a3: 22.94574,
    a4: 15.96899,
    a5: 10.54264,
    a6: 6.82171,
    b3: 111,
    b4: 22.94574,
    b5: 15.96899,
    b6: 10.54264,
  },
  {
    maesu: 6460,
    b2: 148,
    a2: 133.2,
    a3: 23.06502,
    a4: 15.94427,
    a5: 10.52632,
    a6: 6.81115,
    b3: 111,
    b4: 23.06502,
    b5: 15.94427,
    b6: 10.52632,
  },
  {
    maesu: 6470,
    b2: 148,
    a2: 133.2,
    a3: 23.02937,
    a4: 16.07419,
    a5: 10.51005,
    a6: 6.80062,
    b3: 111,
    b4: 23.02937,
    b5: 16.07419,
    b6: 10.51005,
  },
  {
    maesu: 6480,
    b2: 148,
    a2: 133.2,
    a3: 22.99383,
    a4: 16.04938,
    a5: 10.49383,
    a6: 6.79012,
    b3: 111,
    b4: 22.99383,
    b5: 16.04938,
    b6: 10.49383,
  },
  {
    maesu: 6490,
    b2: 148,
    a2: 133.2,
    a3: 22.9584,
    a4: 16.02465,
    a5: 10.47766,
    a6: 6.77966,
    b3: 111,
    b4: 22.9584,
    b5: 16.02465,
    b6: 10.47766,
  },
  {
    maesu: 6500,
    b2: 148,
    a2: 133.2,
    a3: 23.07692,
    a4: 16,
    a5: 10.46154,
    a6: 6.76923,
    b3: 111,
    b4: 23.07692,
    b5: 16,
    b6: 10.46154,
  },
  {
    maesu: 6510,
    b2: 148,
    a2: 133.2,
    a3: 23.04147,
    a4: 15.97542,
    a5: 10.44547,
    a6: 6.75883,
    b3: 111,
    b4: 23.04147,
    b5: 15.97542,
    b6: 10.44547,
  },
  {
    maesu: 6520,
    b2: 148,
    a2: 133.2,
    a3: 23.00613,
    a4: 15.95092,
    a5: 10.42945,
    a6: 6.74847,
    b3: 111,
    b4: 23.00613,
    b5: 15.95092,
    b6: 10.42945,
  },
  {
    maesu: 6530,
    b2: 148,
    a2: 133.2,
    a3: 22.9709,
    a4: 15.92649,
    a5: 10.56662,
    a6: 6.73813,
    b3: 111,
    b4: 22.9709,
    b5: 15.92649,
    b6: 10.56662,
  },
  {
    maesu: 6540,
    b2: 148,
    a2: 133.2,
    a3: 22.93578,
    a4: 16.05505,
    a5: 10.55046,
    a6: 6.72783,
    b3: 111,
    b4: 22.93578,
    b5: 16.05505,
    b6: 10.55046,
  },
  {
    maesu: 6550,
    b2: 148,
    a2: 133.2,
    a3: 23.05344,
    a4: 16.03053,
    a5: 10.53435,
    a6: 6.71756,
    b3: 111,
    b4: 23.05344,
    b5: 16.03053,
    b6: 10.53435,
  },
  {
    maesu: 6560,
    b2: 148,
    a2: 133.2,
    a3: 23.01829,
    a4: 16.0061,
    a5: 10.51829,
    a6: 6.70732,
    b3: 111,
    b4: 23.01829,
    b5: 16.0061,
    b6: 10.51829,
  },
  {
    maesu: 6570,
    b2: 148,
    a2: 133.2,
    a3: 22.98326,
    a4: 15.98174,
    a5: 10.50228,
    a6: 6.69711,
    b3: 111,
    b4: 22.98326,
    b5: 15.98174,
    b6: 10.50228,
  },
  {
    maesu: 6580,
    b2: 148,
    a2: 133.2,
    a3: 22.94833,
    a4: 15.95745,
    a5: 10.48632,
    a6: 6.68693,
    b3: 111,
    b4: 22.94833,
    b5: 15.95745,
    b6: 10.48632,
  },
  {
    maesu: 6590,
    b2: 148,
    a2: 133.2,
    a3: 23.06525,
    a4: 15.93323,
    a5: 10.47041,
    a6: 6.67678,
    b3: 111,
    b4: 23.06525,
    b5: 15.93323,
    b6: 10.47041,
  },
  {
    maesu: 6600,
    b2: 148,
    a2: 133.2,
    a3: 23.0303,
    a4: 16.06061,
    a5: 10.45455,
    a6: 6.66667,
    b3: 111,
    b4: 23.0303,
    b5: 16.06061,
    b6: 10.45455,
  },
  {
    maesu: 6610,
    b2: 148,
    a2: 133.2,
    a3: 22.99546,
    a4: 16.03631,
    a5: 10.43873,
    a6: 6.65658,
    b3: 111,
    b4: 22.99546,
    b5: 16.03631,
    b6: 10.43873,
  },
  {
    maesu: 6620,
    b2: 148,
    a2: 133.2,
    a3: 22.96073,
    a4: 16.01208,
    a5: 10.57402,
    a6: 6.64653,
    b3: 111,
    b4: 22.96073,
    b5: 16.01208,
    b6: 10.57402,
  },
  {
    maesu: 6630,
    b2: 148,
    a2: 133.2,
    a3: 22.92609,
    a4: 15.98793,
    a5: 10.55807,
    a6: 6.6365,
    b3: 111,
    b4: 22.92609,
    b5: 15.98793,
    b6: 10.55807,
  },
  {
    maesu: 6640,
    b2: 148,
    a2: 133.2,
    a3: 23.04217,
    a4: 15.96386,
    a5: 10.54217,
    a6: 6.77711,
    b3: 111,
    b4: 23.04217,
    b5: 15.96386,
    b6: 10.54217,
  },
  {
    maesu: 6650,
    b2: 148,
    a2: 133.2,
    a3: 23.00752,
    a4: 15.93985,
    a5: 10.52632,
    a6: 6.76692,
    b3: 111,
    b4: 23.00752,
    b5: 15.93985,
    b6: 10.52632,
  },
  {
    maesu: 6660,
    b2: 148,
    a2: 133.2,
    a3: 22.97297,
    a4: 16.06607,
    a5: 10.51051,
    a6: 6.75676,
    b3: 111,
    b4: 22.97297,
    b5: 16.06607,
    b6: 10.51051,
  },
  {
    maesu: 6670,
    b2: 148,
    a2: 133.2,
    a3: 22.93853,
    a4: 16.04198,
    a5: 10.49475,
    a6: 6.74663,
    b3: 111,
    b4: 22.93853,
    b5: 16.04198,
    b6: 10.49475,
  },
  {
    maesu: 6680,
    b2: 148,
    a2: 133.2,
    a3: 23.05389,
    a4: 16.01796,
    a5: 10.47904,
    a6: 6.73653,
    b3: 111,
    b4: 23.05389,
    b5: 16.01796,
    b6: 10.47904,
  },
  {
    maesu: 6690,
    b2: 148,
    a2: 133.2,
    a3: 23.01943,
    a4: 15.99402,
    a5: 10.46338,
    a6: 6.72646,
    b3: 111,
    b4: 23.01943,
    b5: 15.99402,
    b6: 10.46338,
  },
  {
    maesu: 6700,
    b2: 148,
    a2: 133.2,
    a3: 22.98507,
    a4: 15.97015,
    a5: 10.44776,
    a6: 6.71642,
    b3: 111,
    b4: 22.98507,
    b5: 15.97015,
    b6: 10.44776,
  },
  {
    maesu: 6710,
    b2: 148,
    a2: 133.2,
    a3: 22.95082,
    a4: 15.94635,
    a5: 10.43219,
    a6: 6.70641,
    b3: 111,
    b4: 22.95082,
    b5: 15.94635,
    b6: 10.43219,
  },
  {
    maesu: 6720,
    b2: 148,
    a2: 133.2,
    a3: 23.06548,
    a4: 16.07143,
    a5: 10.56548,
    a6: 6.69643,
    b3: 111,
    b4: 23.06548,
    b5: 16.07143,
    b6: 10.56548,
  },
  {
    maesu: 6730,
    b2: 148,
    a2: 133.2,
    a3: 23.0312,
    a4: 16.04755,
    a5: 10.54978,
    a6: 6.68648,
    b3: 111,
    b4: 23.0312,
    b5: 16.04755,
    b6: 10.54978,
  },
  {
    maesu: 6740,
    b2: 148,
    a2: 133.2,
    a3: 22.99703,
    a4: 16.02374,
    a5: 10.53412,
    a6: 6.67656,
    b3: 111,
    b4: 22.99703,
    b5: 16.02374,
    b6: 10.53412,
  },
  {
    maesu: 6750,
    b2: 148,
    a2: 133.2,
    a3: 22.96296,
    a4: 16,
    a5: 10.51852,
    a6: 6.66667,
    b3: 111,
    b4: 22.96296,
    b5: 16,
    b6: 10.51852,
  },
  {
    maesu: 6760,
    b2: 148,
    a2: 133.2,
    a3: 22.92899,
    a4: 15.97633,
    a5: 10.50296,
    a6: 6.6568,
    b3: 111,
    b4: 22.92899,
    b5: 15.97633,
    b6: 10.50296,
  },
  {
    maesu: 6770,
    b2: 148,
    a2: 133.2,
    a3: 23.04284,
    a4: 15.95273,
    a5: 10.48744,
    a6: 6.64697,
    b3: 111,
    b4: 23.04284,
    b5: 15.95273,
    b6: 10.48744,
  },
  {
    maesu: 6780,
    b2: 148,
    a2: 133.2,
    a3: 23.00885,
    a4: 15.9292,
    a5: 10.47198,
    a6: 6.63717,
    b3: 111,
    b4: 23.00885,
    b5: 15.9292,
    b6: 10.47198,
  },
  {
    maesu: 6790,
    b2: 148,
    a2: 133.2,
    a3: 22.97496,
    a4: 16.05302,
    a5: 10.45655,
    a6: 6.62739,
    b3: 111,
    b4: 22.97496,
    b5: 16.05302,
    b6: 10.45655,
  },
  {
    maesu: 6800,
    b2: 148,
    a2: 133.2,
    a3: 22.94118,
    a4: 16.02941,
    a5: 10.44118,
    a6: 6.61765,
    b3: 111,
    b4: 22.94118,
    b5: 16.02941,
    b6: 10.44118,
  },
  {
    maesu: 6810,
    b2: 148,
    a2: 133.2,
    a3: 23.05433,
    a4: 16.00587,
    a5: 10.57269,
    a6: 6.60793,
    b3: 111,
    b4: 23.05433,
    b5: 16.00587,
    b6: 10.57269,
  },
  {
    maesu: 6820,
    b2: 148,
    a2: 133.2,
    a3: 23.02053,
    a4: 15.9824,
    a5: 10.55718,
    a6: 6.59824,
    b3: 111,
    b4: 23.02053,
    b5: 15.9824,
    b6: 10.55718,
  },
  {
    maesu: 6830,
    b2: 148,
    a2: 133.2,
    a3: 22.98682,
    a4: 15.959,
    a5: 10.54173,
    a6: 6.58858,
    b3: 111,
    b4: 22.98682,
    b5: 15.959,
    b6: 10.54173,
  },
  {
    maesu: 6840,
    b2: 148,
    a2: 133.2,
    a3: 22.95322,
    a4: 15.93567,
    a5: 10.52632,
    a6: 6.57895,
    b3: 111,
    b4: 22.95322,
    b5: 15.93567,
    b6: 10.52632,
  },
  {
    maesu: 6850,
    b2: 148,
    a2: 133.2,
    a3: 23.06569,
    a4: 16.05839,
    a5: 10.51095,
    a6: 6.56934,
    b3: 111,
    b4: 23.06569,
    b5: 16.05839,
    b6: 10.51095,
  },
  {
    maesu: 6860,
    b2: 148,
    a2: 133.2,
    a3: 23.03207,
    a4: 16.03499,
    a5: 10.49563,
    a6: 6.55977,
    b3: 111,
    b4: 23.03207,
    b5: 16.03499,
    b6: 10.49563,
  },
  {
    maesu: 6870,
    b2: 148,
    a2: 133.2,
    a3: 22.99854,
    a4: 16.01164,
    a5: 10.48035,
    a6: 6.55022,
    b3: 111,
    b4: 22.99854,
    b5: 16.01164,
    b6: 10.48035,
  },
  {
    maesu: 6880,
    b2: 148,
    a2: 133.2,
    a3: 22.96512,
    a4: 15.98837,
    a5: 10.46512,
    a6: 6.5407,
    b3: 111,
    b4: 22.96512,
    b5: 15.98837,
    b6: 10.46512,
  },
  {
    maesu: 6890,
    b2: 148,
    a2: 133.2,
    a3: 22.93179,
    a4: 15.96517,
    a5: 10.44993,
    a6: 6.67634,
    b3: 111,
    b4: 22.93179,
    b5: 15.96517,
    b6: 10.44993,
  },
  {
    maesu: 6900,
    b2: 148,
    a2: 133.2,
    a3: 23.04348,
    a4: 15.94203,
    a5: 10.43478,
    a6: 6.66667,
    b3: 111,
    b4: 23.04348,
    b5: 15.94203,
    b6: 10.43478,
  },
  {
    maesu: 6910,
    b2: 148,
    a2: 133.2,
    a3: 23.01013,
    a4: 16.06368,
    a5: 10.5644,
    a6: 6.65702,
    b3: 111,
    b4: 23.01013,
    b5: 16.06368,
    b6: 10.5644,
  },
  {
    maesu: 6920,
    b2: 148,
    a2: 133.2,
    a3: 22.97688,
    a4: 16.04046,
    a5: 10.54913,
    a6: 6.6474,
    b3: 111,
    b4: 22.97688,
    b5: 16.04046,
    b6: 10.54913,
  },
  {
    maesu: 6930,
    b2: 148,
    a2: 133.2,
    a3: 22.94372,
    a4: 16.01732,
    a5: 10.53391,
    a6: 6.63781,
    b3: 111,
    b4: 22.94372,
    b5: 16.01732,
    b6: 10.53391,
  },
  {
    maesu: 6940,
    b2: 148,
    a2: 133.2,
    a3: 23.05476,
    a4: 15.99424,
    a5: 10.51873,
    a6: 6.62824,
    b3: 111,
    b4: 23.05476,
    b5: 15.99424,
    b6: 10.51873,
  },
  {
    maesu: 6950,
    b2: 148,
    a2: 133.2,
    a3: 23.02158,
    a4: 15.97122,
    a5: 10.5036,
    a6: 6.61871,
    b3: 111,
    b4: 23.02158,
    b5: 15.97122,
    b6: 10.5036,
  },
  {
    maesu: 6960,
    b2: 148,
    a2: 133.2,
    a3: 22.98851,
    a4: 15.94828,
    a5: 10.48851,
    a6: 6.6092,
    b3: 111,
    b4: 22.98851,
    b5: 15.94828,
    b6: 10.48851,
  },
  {
    maesu: 6970,
    b2: 148,
    a2: 133.2,
    a3: 22.95552,
    a4: 16.06887,
    a5: 10.47346,
    a6: 6.59971,
    b3: 111,
    b4: 22.95552,
    b5: 16.06887,
    b6: 10.47346,
  },
  {
    maesu: 6980,
    b2: 148,
    a2: 133.2,
    a3: 23.0659,
    a4: 16.04585,
    a5: 10.45845,
    a6: 6.59026,
    b3: 111,
    b4: 23.0659,
    b5: 16.04585,
    b6: 10.45845,
  },
  {
    maesu: 6990,
    b2: 148,
    a2: 133.2,
    a3: 23.0329,
    a4: 16.02289,
    a5: 10.44349,
    a6: 6.58083,
    b3: 111,
    b4: 23.0329,
    b5: 16.02289,
    b6: 10.44349,
  },
  {
    maesu: 7000,
    b2: 148,
    a2: 133.2,
    a3: 23,
    a4: 16,
    a5: 10.57143,
    a6: 6.57143,
    b3: 111,
    b4: 23,
    b5: 16,
    b6: 10.57143,
  },
  {
    maesu: 7010,
    b2: 148,
    a2: 133.2,
    a3: 22.96719,
    a4: 15.97718,
    a5: 10.55635,
    a6: 6.56205,
    b3: 111,
    b4: 22.96719,
    b5: 15.97718,
    b6: 10.55635,
  },
  {
    maesu: 7020,
    b2: 148,
    a2: 133.2,
    a3: 22.93447,
    a4: 15.95442,
    a5: 10.54131,
    a6: 6.55271,
    b3: 111,
    b4: 22.93447,
    b5: 15.95442,
    b6: 10.54131,
  },
  {
    maesu: 7030,
    b2: 148,
    a2: 133.2,
    a3: 23.0441,
    a4: 15.93172,
    a5: 10.52632,
    a6: 6.54339,
    b3: 111,
    b4: 23.0441,
    b5: 15.93172,
    b6: 10.52632,
  },
  {
    maesu: 7040,
    b2: 148,
    a2: 133.2,
    a3: 23.01136,
    a4: 16.05114,
    a5: 10.51136,
    a6: 6.53409,
    b3: 111,
    b4: 23.01136,
    b5: 16.05114,
    b6: 10.51136,
  },
  {
    maesu: 7050,
    b2: 148,
    a2: 133.2,
    a3: 22.97872,
    a4: 16.02837,
    a5: 10.49645,
    a6: 6.52482,
    b3: 111,
    b4: 22.97872,
    b5: 16.02837,
    b6: 10.49645,
  },
  {
    maesu: 7060,
    b2: 148,
    a2: 133.2,
    a3: 22.94618,
    a4: 16.00567,
    a5: 10.48159,
    a6: 6.51558,
    b3: 111,
    b4: 22.94618,
    b5: 16.00567,
    b6: 10.48159,
  },
  {
    maesu: 7070,
    b2: 148,
    a2: 133.2,
    a3: 23.05516,
    a4: 15.98303,
    a5: 10.46676,
    a6: 6.50636,
    b3: 111,
    b4: 23.05516,
    b5: 15.98303,
    b6: 10.46676,
  },
  {
    maesu: 7080,
    b2: 148,
    a2: 133.2,
    a3: 23.0226,
    a4: 15.96045,
    a5: 10.45198,
    a6: 6.49718,
    b3: 111,
    b4: 23.0226,
    b5: 15.96045,
    b6: 10.45198,
  },
  {
    maesu: 7090,
    b2: 148,
    a2: 133.2,
    a3: 22.99013,
    a4: 15.93794,
    a5: 10.43724,
    a6: 6.48801,
    b3: 111,
    b4: 22.99013,
    b5: 15.93794,
    b6: 10.43724,
  },
  {
    maesu: 7100,
    b2: 148,
    a2: 133.2,
    a3: 22.95775,
    a4: 16.05634,
    a5: 10.56338,
    a6: 6.47887,
    b3: 111,
    b4: 22.95775,
    b5: 16.05634,
    b6: 10.56338,
  },
  {
    maesu: 7110,
    b2: 148,
    a2: 133.2,
    a3: 23.0661,
    a4: 16.03376,
    a5: 10.54852,
    a6: 6.46976,
    b3: 111,
    b4: 23.0661,
    b5: 16.03376,
    b6: 10.54852,
  },
  {
    maesu: 7120,
    b2: 148,
    a2: 133.2,
    a3: 23.03371,
    a4: 16.01124,
    a5: 10.53371,
    a6: 6.46067,
    b3: 111,
    b4: 23.03371,
    b5: 16.01124,
    b6: 10.53371,
  },
  {
    maesu: 7130,
    b2: 148,
    a2: 133.2,
    a3: 23.0014,
    a4: 15.98878,
    a5: 10.51893,
    a6: 6.45161,
    b3: 111,
    b4: 23.0014,
    b5: 15.98878,
    b6: 10.51893,
  },
  {
    maesu: 7140,
    b2: 148,
    a2: 133.2,
    a3: 22.96919,
    a4: 15.96639,
    a5: 10.5042,
    a6: 6.44258,
    b3: 111,
    b4: 22.96919,
    b5: 15.96639,
    b6: 10.5042,
  },
  {
    maesu: 7150,
    b2: 148,
    a2: 133.2,
    a3: 22.93706,
    a4: 15.94406,
    a5: 10.48951,
    a6: 6.43357,
    b3: 111,
    b4: 22.93706,
    b5: 15.94406,
    b6: 10.48951,
  },
  {
    maesu: 7160,
    b2: 148,
    a2: 133.2,
    a3: 23.04469,
    a4: 16.06145,
    a5: 10.47486,
    a6: 6.42458,
    b3: 111,
    b4: 23.04469,
    b5: 16.06145,
    b6: 10.47486,
  },
  {
    maesu: 7170,
    b2: 148,
    a2: 133.2,
    a3: 23.01255,
    a4: 16.03905,
    a5: 10.46025,
    a6: 6.41562,
    b3: 111,
    b4: 23.01255,
    b5: 16.03905,
    b6: 10.46025,
  },
  {
    maesu: 7180,
    b2: 148,
    a2: 133.2,
    a3: 22.9805,
    a4: 16.01671,
    a5: 10.44568,
    a6: 6.40669,
    b3: 111,
    b4: 22.9805,
    b5: 16.01671,
    b6: 10.44568,
  },
  {
    maesu: 7190,
    b2: 148,
    a2: 133.2,
    a3: 22.94854,
    a4: 15.99444,
    a5: 10.43115,
    a6: 6.39777,
    b3: 111,
    b4: 22.94854,
    b5: 15.99444,
    b6: 10.43115,
  },
  {
    maesu: 7200,
    b2: 148,
    a2: 133.2,
    a3: 23.05556,
    a4: 15.97222,
    a5: 10.55556,
    a6: 6.38889,
    b3: 111,
    b4: 23.05556,
    b5: 15.97222,
    b6: 10.55556,
  },
  {
    maesu: 7210,
    b2: 148,
    a2: 133.2,
    a3: 23.02358,
    a4: 15.95007,
    a5: 10.54092,
    a6: 6.38003,
    b3: 111,
    b4: 23.02358,
    b5: 15.95007,
    b6: 10.54092,
  },
  {
    maesu: 7220,
    b2: 148,
    a2: 133.2,
    a3: 22.99169,
    a4: 16.06648,
    a5: 10.52632,
    a6: 6.37119,
    b3: 111,
    b4: 22.99169,
    b5: 16.06648,
    b6: 10.52632,
  },
  {
    maesu: 7230,
    b2: 148,
    a2: 133.2,
    a3: 22.95989,
    a4: 16.04426,
    a5: 10.51176,
    a6: 6.36238,
    b3: 111,
    b4: 22.95989,
    b5: 16.04426,
    b6: 10.51176,
  },
  {
    maesu: 7240,
    b2: 148,
    a2: 133.2,
    a3: 23.0663,
    a4: 16.0221,
    a5: 10.49724,
    a6: 6.35359,
    b3: 111,
    b4: 23.0663,
    b5: 16.0221,
    b6: 10.49724,
  },
  {
    maesu: 7250,
    b2: 148,
    a2: 133.2,
    a3: 23.03448,
    a4: 16,
    a5: 10.48276,
    a6: 6.34483,
    b3: 111,
    b4: 23.03448,
    b5: 16,
    b6: 10.48276,
  },
  {
    maesu: 7260,
    b2: 148,
    a2: 133.2,
    a3: 23.00275,
    a4: 15.97796,
    a5: 10.46832,
    a6: 6.33609,
    b3: 111,
    b4: 23.00275,
    b5: 15.97796,
    b6: 10.46832,
  },
  {
    maesu: 7270,
    b2: 148,
    a2: 133.2,
    a3: 22.97111,
    a4: 15.95598,
    a5: 10.45392,
    a6: 6.32737,
    b3: 111,
    b4: 22.97111,
    b5: 15.95598,
    b6: 10.45392,
  },
  {
    maesu: 7280,
    b2: 148,
    a2: 133.2,
    a3: 22.93956,
    a4: 15.93407,
    a5: 10.43956,
    a6: 6.31868,
    b3: 111,
    b4: 22.93956,
    b5: 15.93407,
    b6: 10.43956,
  },
  {
    maesu: 7290,
    b2: 148,
    a2: 133.2,
    a3: 23.04527,
    a4: 16.04938,
    a5: 10.56241,
    a6: 6.31001,
    b3: 111,
    b4: 23.04527,
    b5: 16.04938,
    b6: 10.56241,
  },
  {
    maesu: 7300,
    b2: 148,
    a2: 133.2,
    a3: 23.0137,
    a4: 16.0274,
    a5: 10.54795,
    a6: 6.30137,
    b3: 111,
    b4: 23.0137,
    b5: 16.0274,
    b6: 10.54795,
  },
  {
    maesu: 7310,
    b2: 148,
    a2: 133.2,
    a3: 22.98222,
    a4: 16.00547,
    a5: 10.53352,
    a6: 6.29275,
    b3: 111,
    b4: 22.98222,
    b5: 16.00547,
    b6: 10.53352,
  },
  {
    maesu: 7320,
    b2: 148,
    a2: 133.2,
    a3: 22.95082,
    a4: 15.98361,
    a5: 10.51913,
    a6: 6.28415,
    b3: 111,
    b4: 22.95082,
    b5: 15.98361,
    b6: 10.51913,
  },
  {
    maesu: 7330,
    b2: 148,
    a2: 133.2,
    a3: 23.05593,
    a4: 15.9618,
    a5: 10.50477,
    a6: 6.27558,
    b3: 111,
    b4: 23.05593,
    b5: 15.9618,
    b6: 10.50477,
  },
  {
    maesu: 7340,
    b2: 148,
    a2: 133.2,
    a3: 23.02452,
    a4: 15.94005,
    a5: 10.49046,
    a6: 6.26703,
    b3: 111,
    b4: 23.02452,
    b5: 15.94005,
    b6: 10.49046,
  },
  {
    maesu: 7350,
    b2: 148,
    a2: 133.2,
    a3: 22.9932,
    a4: 16.05442,
    a5: 10.47619,
    a6: 6.2585,
    b3: 111,
    b4: 22.9932,
    b5: 16.05442,
    b6: 10.47619,
  },
  {
    maesu: 7360,
    b2: 148,
    a2: 133.2,
    a3: 22.96196,
    a4: 16.03261,
    a5: 10.46196,
    a6: 6.25,
    b3: 111,
    b4: 22.96196,
    b5: 16.03261,
    b6: 10.46196,
  },
  {
    maesu: 7370,
    b2: 148,
    a2: 133.2,
    a3: 23.06649,
    a4: 16.01085,
    a5: 10.44776,
    a6: 6.24152,
    b3: 111,
    b4: 23.06649,
    b5: 16.01085,
    b6: 10.44776,
  },
  {
    maesu: 7380,
    b2: 148,
    a2: 133.2,
    a3: 23.03523,
    a4: 15.98916,
    a5: 10.4336,
    a6: 6.23306,
    b3: 111,
    b4: 23.03523,
    b5: 15.98916,
    b6: 10.4336,
  },
  {
    maesu: 7390,
    b2: 148,
    a2: 133.2,
    a3: 23.00406,
    a4: 15.96752,
    a5: 10.5548,
    a6: 6.22463,
    b3: 111,
    b4: 23.00406,
    b5: 15.96752,
    b6: 10.5548,
  },
  {
    maesu: 7400,
    b2: 148,
    a2: 133.2,
    a3: 22.97297,
    a4: 15.94595,
    a5: 10.54054,
    a6: 6.21622,
    b3: 111,
    b4: 22.97297,
    b5: 15.94595,
    b6: 10.54054,
  },
  {
    maesu: 7410,
    b2: 148,
    a2: 133.2,
    a3: 22.94197,
    a4: 16.05938,
    a5: 10.52632,
    a6: 6.20783,
    b3: 111,
    b4: 22.94197,
    b5: 16.05938,
    b6: 10.52632,
  },
  {
    maesu: 7420,
    b2: 148,
    a2: 133.2,
    a3: 23.04582,
    a4: 16.03774,
    a5: 10.51213,
    a6: 6.19946,
    b3: 111,
    b4: 23.04582,
    b5: 16.03774,
    b6: 10.51213,
  },
  {
    maesu: 7430,
    b2: 148,
    a2: 133.2,
    a3: 23.0148,
    a4: 16.01615,
    a5: 10.49798,
    a6: 6.19112,
    b3: 111,
    b4: 23.0148,
    b5: 16.01615,
    b6: 10.49798,
  },
  {
    maesu: 7440,
    b2: 148,
    a2: 133.2,
    a3: 22.98387,
    a4: 15.99462,
    a5: 10.48387,
    a6: 6.1828,
    b3: 111,
    b4: 22.98387,
    b5: 15.99462,
    b6: 10.48387,
  },
  {
    maesu: 7450,
    b2: 148,
    a2: 133.2,
    a3: 22.95302,
    a4: 15.97315,
    a5: 10.4698,
    a6: 6.1745,
    b3: 111,
    b4: 22.95302,
    b5: 15.97315,
    b6: 10.4698,
  },
  {
    maesu: 7460,
    b2: 148,
    a2: 133.2,
    a3: 23.0563,
    a4: 15.95174,
    a5: 10.45576,
    a6: 6.16622,
    b3: 111,
    b4: 23.0563,
    b5: 15.95174,
    b6: 10.45576,
  },
  {
    maesu: 7470,
    b2: 148,
    a2: 133.2,
    a3: 23.02544,
    a4: 16.06426,
    a5: 10.44177,
    a6: 6.15797,
    b3: 111,
    b4: 23.02544,
    b5: 16.06426,
    b6: 10.44177,
  },
  {
    maesu: 7480,
    b2: 148,
    a2: 133.2,
    a3: 22.99465,
    a4: 16.04278,
    a5: 10.5615,
    a6: 6.14973,
    b3: 111,
    b4: 22.99465,
    b5: 16.04278,
    b6: 10.5615,
  },
  {
    maesu: 7490,
    b2: 148,
    a2: 133.2,
    a3: 22.96395,
    a4: 16.02136,
    a5: 10.5474,
    a6: 6.14152,
    b3: 111,
    b4: 22.96395,
    b5: 16.02136,
    b6: 10.5474,
  },
  {
    maesu: 7500,
    b2: 148,
    a2: 133.2,
    a3: 23.06667,
    a4: 16,
    a5: 10.53333,
    a6: 6.26667,
    b3: 111,
    b4: 23.06667,
    b5: 16,
    b6: 10.53333,
  },
  {
    maesu: 7510,
    b2: 148,
    a2: 133.2,
    a3: 23.03595,
    a4: 15.9787,
    a5: 10.51931,
    a6: 6.25832,
    b3: 111,
    b4: 23.03595,
    b5: 15.9787,
    b6: 10.51931,
  },
  {
    maesu: 7520,
    b2: 148,
    a2: 133.2,
    a3: 23.00532,
    a4: 15.95745,
    a5: 10.50532,
    a6: 6.25,
    b3: 111,
    b4: 23.00532,
    b5: 15.95745,
    b6: 10.50532,
  },
  {
    maesu: 7530,
    b2: 148,
    a2: 133.2,
    a3: 22.97477,
    a4: 15.93625,
    a5: 10.49137,
    a6: 6.2417,
    b3: 111,
    b4: 22.97477,
    b5: 15.93625,
    b6: 10.49137,
  },
  {
    maesu: 7540,
    b2: 148,
    a2: 133.2,
    a3: 22.9443,
    a4: 16.04775,
    a5: 10.47745,
    a6: 6.23342,
    b3: 111,
    b4: 22.9443,
    b5: 16.04775,
    b6: 10.47745,
  },
  {
    maesu: 7550,
    b2: 148,
    a2: 133.2,
    a3: 23.04636,
    a4: 16.02649,
    a5: 10.46358,
    a6: 6.22517,
    b3: 111,
    b4: 23.04636,
    b5: 16.02649,
    b6: 10.46358,
  },
  {
    maesu: 7560,
    b2: 148,
    a2: 133.2,
    a3: 23.01587,
    a4: 16.00529,
    a5: 10.44974,
    a6: 6.21693,
    b3: 111,
    b4: 23.01587,
    b5: 16.00529,
    b6: 10.44974,
  },
  {
    maesu: 7570,
    b2: 148,
    a2: 133.2,
    a3: 22.98547,
    a4: 15.98415,
    a5: 10.43593,
    a6: 6.20872,
    b3: 111,
    b4: 22.98547,
    b5: 15.98415,
    b6: 10.43593,
  },
  {
    maesu: 7580,
    b2: 148,
    a2: 133.2,
    a3: 22.95515,
    a4: 15.96306,
    a5: 10.55409,
    a6: 6.20053,
    b3: 111,
    b4: 22.95515,
    b5: 15.96306,
    b6: 10.55409,
  },
  {
    maesu: 7590,
    b2: 148,
    a2: 133.2,
    a3: 23.05665,
    a4: 15.94203,
    a5: 10.54018,
    a6: 6.19236,
    b3: 111,
    b4: 23.05665,
    b5: 15.94203,
    b6: 10.54018,
  },
  {
    maesu: 7600,
    b2: 148,
    a2: 133.2,
    a3: 23.02632,
    a4: 16.05263,
    a5: 10.52632,
    a6: 6.18421,
    b3: 111,
    b4: 23.02632,
    b5: 16.05263,
    b6: 10.52632,
  },
  {
    maesu: 7610,
    b2: 148,
    a2: 133.2,
    a3: 22.99606,
    a4: 16.03154,
    a5: 10.51248,
    a6: 6.17608,
    b3: 111,
    b4: 22.99606,
    b5: 16.03154,
    b6: 10.51248,
  },
  {
    maesu: 7620,
    b2: 148,
    a2: 133.2,
    a3: 22.96588,
    a4: 16.0105,
    a5: 10.49869,
    a6: 6.16798,
    b3: 111,
    b4: 22.96588,
    b5: 16.0105,
    b6: 10.49869,
  },
  {
    maesu: 7630,
    b2: 148,
    a2: 133.2,
    a3: 22.93578,
    a4: 15.98952,
    a5: 10.48493,
    a6: 6.1599,
    b3: 111,
    b4: 22.93578,
    b5: 15.98952,
    b6: 10.48493,
  },
  {
    maesu: 7640,
    b2: 148,
    a2: 133.2,
    a3: 23.03665,
    a4: 15.96859,
    a5: 10.4712,
    a6: 6.15183,
    b3: 111,
    b4: 23.03665,
    b5: 15.96859,
    b6: 10.4712,
  },
  {
    maesu: 7650,
    b2: 148,
    a2: 133.2,
    a3: 23.00654,
    a4: 15.94771,
    a5: 10.45752,
    a6: 6.14379,
    b3: 111,
    b4: 23.00654,
    b5: 15.94771,
    b6: 10.45752,
  },
  {
    maesu: 7660,
    b2: 148,
    a2: 133.2,
    a3: 22.9765,
    a4: 16.05744,
    a5: 10.44386,
    a6: 6.26632,
    b3: 111,
    b4: 22.9765,
    b5: 16.05744,
    b6: 10.44386,
  },
  {
    maesu: 7670,
    b2: 148,
    a2: 133.2,
    a3: 22.94654,
    a4: 16.03651,
    a5: 10.56063,
    a6: 6.25815,
    b3: 111,
    b4: 22.94654,
    b5: 16.03651,
    b6: 10.56063,
  },
  {
    maesu: 7680,
    b2: 148,
    a2: 133.2,
    a3: 23.04688,
    a4: 16.01562,
    a5: 10.54688,
    a6: 6.25,
    b3: 111,
    b4: 23.04688,
    b5: 16.01562,
    b6: 10.54688,
  },
  {
    maesu: 7690,
    b2: 148,
    a2: 133.2,
    a3: 23.01691,
    a4: 15.9948,
    a5: 10.53316,
    a6: 6.24187,
    b3: 111,
    b4: 23.01691,
    b5: 15.9948,
    b6: 10.53316,
  },
  {
    maesu: 7700,
    b2: 148,
    a2: 133.2,
    a3: 22.98701,
    a4: 15.97403,
    a5: 10.51948,
    a6: 6.23377,
    b3: 111,
    b4: 22.98701,
    b5: 15.97403,
    b6: 10.51948,
  },
  {
    maesu: 7710,
    b2: 148,
    a2: 133.2,
    a3: 22.9572,
    a4: 15.95331,
    a5: 10.50584,
    a6: 6.22568,
    b3: 111,
    b4: 22.9572,
    b5: 15.95331,
    b6: 10.50584,
  },
  {
    maesu: 7720,
    b2: 148,
    a2: 133.2,
    a3: 23.05699,
    a4: 16.06218,
    a5: 10.49223,
    a6: 6.21762,
    b3: 111,
    b4: 23.05699,
    b5: 16.06218,
    b6: 10.49223,
  },
  {
    maesu: 7730,
    b2: 148,
    a2: 133.2,
    a3: 23.02717,
    a4: 16.0414,
    a5: 10.47865,
    a6: 6.20957,
    b3: 111,
    b4: 23.02717,
    b5: 16.0414,
    b6: 10.47865,
  },
  {
    maesu: 7740,
    b2: 148,
    a2: 133.2,
    a3: 22.99742,
    a4: 16.02067,
    a5: 10.46512,
    a6: 6.20155,
    b3: 111,
    b4: 22.99742,
    b5: 16.02067,
    b6: 10.46512,
  },
  {
    maesu: 7750,
    b2: 148,
    a2: 133.2,
    a3: 22.96774,
    a4: 16,
    a5: 10.45161,
    a6: 6.19355,
    b3: 111,
    b4: 22.96774,
    b5: 16,
    b6: 10.45161,
  },
  {
    maesu: 7760,
    b2: 148,
    a2: 133.2,
    a3: 22.93814,
    a4: 15.97938,
    a5: 10.43814,
    a6: 6.18557,
    b3: 111,
    b4: 22.93814,
    b5: 15.97938,
    b6: 10.43814,
  },
  {
    maesu: 7770,
    b2: 148,
    a2: 133.2,
    a3: 23.03732,
    a4: 15.95882,
    a5: 10.55341,
    a6: 6.17761,
    b3: 111,
    b4: 23.03732,
    b5: 15.95882,
    b6: 10.55341,
  },
  {
    maesu: 7780,
    b2: 148,
    a2: 133.2,
    a3: 23.00771,
    a4: 15.9383,
    a5: 10.53985,
    a6: 6.16967,
    b3: 111,
    b4: 23.00771,
    b5: 15.9383,
    b6: 10.53985,
  },
  {
    maesu: 7790,
    b2: 148,
    a2: 133.2,
    a3: 22.97818,
    a4: 16.04621,
    a5: 10.52632,
    a6: 6.16175,
    b3: 111,
    b4: 22.97818,
    b5: 16.04621,
    b6: 10.52632,
  },
  {
    maesu: 7800,
    b2: 148,
    a2: 133.2,
    a3: 22.94872,
    a4: 16.02564,
    a5: 10.51282,
    a6: 6.15385,
    b3: 111,
    b4: 22.94872,
    b5: 16.02564,
    b6: 10.51282,
  },
  {
    maesu: 7810,
    b2: 148,
    a2: 133.2,
    a3: 23.04738,
    a4: 16.00512,
    a5: 10.49936,
    a6: 6.14597,
    b3: 111,
    b4: 23.04738,
    b5: 16.00512,
    b6: 10.49936,
  },
  {
    maesu: 7820,
    b2: 148,
    a2: 133.2,
    a3: 23.0179,
    a4: 15.98465,
    a5: 10.48593,
    a6: 6.13811,
    b3: 111,
    b4: 23.0179,
    b5: 15.98465,
    b6: 10.48593,
  },
  {
    maesu: 7830,
    b2: 148,
    a2: 133.2,
    a3: 22.98851,
    a4: 15.96424,
    a5: 10.47254,
    a6: 6.13027,
    b3: 111,
    b4: 22.98851,
    b5: 15.96424,
    b6: 10.47254,
  },
  {
    maesu: 7840,
    b2: 148,
    a2: 133.2,
    a3: 22.95918,
    a4: 15.94388,
    a5: 10.45918,
    a6: 6.12245,
    b3: 111,
    b4: 22.95918,
    b5: 15.94388,
    b6: 10.45918,
  },
  {
    maesu: 7850,
    b2: 148,
    a2: 133.2,
    a3: 23.05732,
    a4: 16.05096,
    a5: 10.44586,
    a6: 6.11465,
    b3: 111,
    b4: 23.05732,
    b5: 16.05096,
    b6: 10.44586,
  },
  {
    maesu: 7860,
    b2: 148,
    a2: 133.2,
    a3: 23.02799,
    a4: 16.03053,
    a5: 10.5598,
    a6: 6.10687,
    b3: 111,
    b4: 23.02799,
    b5: 16.03053,
    b6: 10.5598,
  },
  {
    maesu: 7870,
    b2: 148,
    a2: 133.2,
    a3: 22.99873,
    a4: 16.01017,
    a5: 10.54638,
    a6: 6.09911,
    b3: 111,
    b4: 22.99873,
    b5: 16.01017,
    b6: 10.54638,
  },
  {
    maesu: 7880,
    b2: 148,
    a2: 133.2,
    a3: 22.96954,
    a4: 15.98985,
    a5: 10.53299,
    a6: 6.09137,
    b3: 111,
    b4: 22.96954,
    b5: 15.98985,
    b6: 10.53299,
  },
  {
    maesu: 7890,
    b2: 148,
    a2: 133.2,
    a3: 22.94043,
    a4: 15.96958,
    a5: 10.51965,
    a6: 6.08365,
    b3: 111,
    b4: 22.94043,
    b5: 15.96958,
    b6: 10.51965,
  },
  {
    maesu: 7900,
    b2: 148,
    a2: 133.2,
    a3: 23.03797,
    a4: 15.94937,
    a5: 10.50633,
    a6: 6.07595,
    b3: 111,
    b4: 23.03797,
    b5: 15.94937,
    b6: 10.50633,
  },
  {
    maesu: 7910,
    b2: 148,
    a2: 133.2,
    a3: 23.00885,
    a4: 16.05563,
    a5: 10.49305,
    a6: 6.06827,
    b3: 111,
    b4: 23.00885,
    b5: 16.05563,
    b6: 10.49305,
  },
  {
    maesu: 7920,
    b2: 148,
    a2: 133.2,
    a3: 22.9798,
    a4: 16.03535,
    a5: 10.4798,
    a6: 6.06061,
    b3: 111,
    b4: 22.9798,
    b5: 16.03535,
    b6: 10.4798,
  },
  {
    maesu: 7930,
    b2: 148,
    a2: 133.2,
    a3: 22.95082,
    a4: 16.01513,
    a5: 10.46658,
    a6: 6.05296,
    b3: 111,
    b4: 22.95082,
    b5: 16.01513,
    b6: 10.46658,
  },
  {
    maesu: 7940,
    b2: 148,
    a2: 133.2,
    a3: 23.04786,
    a4: 15.99496,
    a5: 10.4534,
    a6: 6.04534,
    b3: 111,
    b4: 23.04786,
    b5: 15.99496,
    b6: 10.4534,
  },
  {
    maesu: 7950,
    b2: 148,
    a2: 133.2,
    a3: 23.01887,
    a4: 15.97484,
    a5: 10.44025,
    a6: 6.03774,
    b3: 111,
    b4: 23.01887,
    b5: 15.97484,
    b6: 10.44025,
  },
  {
    maesu: 7960,
    b2: 148,
    a2: 133.2,
    a3: 22.98995,
    a4: 15.95477,
    a5: 10.55276,
    a6: 6.03015,
    b3: 111,
    b4: 22.98995,
    b5: 15.95477,
    b6: 10.55276,
  },
  {
    maesu: 7970,
    b2: 148,
    a2: 133.2,
    a3: 22.9611,
    a4: 16.06023,
    a5: 10.53952,
    a6: 6.02258,
    b3: 111,
    b4: 22.9611,
    b5: 16.06023,
    b6: 10.53952,
  },
  {
    maesu: 7980,
    b2: 148,
    a2: 133.2,
    a3: 23.05764,
    a4: 16.0401,
    a5: 10.52632,
    a6: 6.01504,
    b3: 111,
    b4: 23.05764,
    b5: 16.0401,
    b6: 10.52632,
  },
  {
    maesu: 7990,
    b2: 148,
    a2: 133.2,
    a3: 23.02879,
    a4: 16.02003,
    a5: 10.51314,
    a6: 6.00751,
    b3: 111,
    b4: 23.02879,
    b5: 16.02003,
    b6: 10.51314,
  },
  {
    maesu: 8000,
    b2: 148,
    a2: 133.2,
    a3: 23,
    a4: 16,
    a5: 10.5,
    a6: 6,
    b3: 111,
    b4: 23,
    b5: 16,
    b6: 10.5,
  },
];
//미싱 3줄 단가
export const MissingPrice3 = [
  {
    maesu: 1,
    b2: 11000,
    a2: 9900,
    a3: 6000,
    a4: 4200,
    a5: 3000,
    a6: 2700,
    b3: 8250,
    b4: 6000,
    b5: 4200,
    b6: 3000,
  },
  {
    maesu: 2,
    b2: 5500,
    a2: 4950,
    a3: 3000,
    a4: 2100,
    a5: 1500,
    a6: 1350,
    b3: 4125,
    b4: 3000,
    b5: 2100,
    b6: 1500,
  },
  {
    maesu: 3,
    b2: 3666,
    a2: 3299.4,
    a3: 2000,
    a4: 1400,
    a5: 1000,
    a6: 900,
    b3: 2749.5,
    b4: 2000,
    b5: 1400,
    b6: 1000,
  },
  {
    maesu: 4,
    b2: 2750,
    a2: 2475,
    a3: 1500,
    a4: 1050,
    a5: 750,
    a6: 675,
    b3: 2062.5,
    b4: 1500,
    b5: 1050,
    b6: 750,
  },
  {
    maesu: 5,
    b2: 2200,
    a2: 1980,
    a3: 1200,
    a4: 840,
    a5: 600,
    a6: 540,
    b3: 1650,
    b4: 1200,
    b5: 840,
    b6: 600,
  },
  {
    maesu: 8,
    b2: 1375,
    a2: 1237.5,
    a3: 750,
    a4: 525,
    a5: 375,
    a6: 337.5,
    b3: 1031.25,
    b4: 750,
    b5: 525,
    b6: 375,
  },
  {
    maesu: 10,
    b2: 1100,
    a2: 990,
    a3: 600,
    a4: 420,
    a5: 300,
    a6: 270,
    b3: 825,
    b4: 600,
    b5: 420,
    b6: 300,
  },
  {
    maesu: 15,
    b2: 880,
    a2: 792,
    a3: 466.66667,
    a4: 326.66667,
    a5: 233.33333,
    a6: 213.33333,
    b3: 660,
    b4: 466.66667,
    b5: 326.66667,
    b6: 233.33333,
  },
  {
    maesu: 20,
    b2: 660,
    a2: 594,
    a3: 350,
    a4: 245,
    a5: 175,
    a6: 160,
    b3: 495,
    b4: 350,
    b5: 245,
    b6: 175,
  },
  {
    maesu: 25,
    b2: 616,
    a2: 554.4,
    a3: 300,
    a4: 212,
    a5: 152,
    a6: 136,
    b3: 462,
    b4: 300,
    b5: 212,
    b6: 152,
  },
  {
    maesu: 30,
    b2: 550,
    a2: 495,
    a3: 266.66667,
    a4: 186.66667,
    a5: 133.33333,
    a6: 120,
    b3: 412.5,
    b4: 266.66667,
    b5: 186.66667,
    b6: 133.33333,
  },
  {
    maesu: 35,
    b2: 535,
    a2: 481.5,
    a3: 257.14286,
    a4: 180,
    a5: 128.57143,
    a6: 117.14286,
    b3: 401.25,
    b4: 257.14286,
    b5: 180,
    b6: 128.57143,
  },
  {
    maesu: 40,
    b2: 495,
    a2: 445.5,
    a3: 250,
    a4: 175,
    a5: 125,
    a6: 112.5,
    b3: 371.25,
    b4: 250,
    b5: 175,
    b6: 125,
  },
  {
    maesu: 45,
    b2: 488,
    a2: 439.2,
    a3: 222.22222,
    a4: 155.55556,
    a5: 111.11111,
    a6: 100,
    b3: 366,
    b4: 222.22222,
    b5: 155.55556,
    b6: 111.11111,
  },
  {
    maesu: 50,
    b2: 462,
    a2: 415.8,
    a3: 220,
    a4: 154,
    a5: 110,
    a6: 100,
    b3: 346.5,
    b4: 220,
    b5: 154,
    b6: 110,
  },
  {
    maesu: 55,
    b2: 420,
    a2: 378,
    a3: 200,
    a4: 140,
    a5: 100,
    a6: 90.90909,
    b3: 315,
    b4: 200,
    b5: 140,
    b6: 100,
  },
  {
    maesu: 60,
    b2: 421,
    a2: 378.9,
    a3: 183.33333,
    a4: 128.33333,
    a5: 91.66667,
    a6: 83.33333,
    b3: 315.75,
    b4: 183.33333,
    b5: 128.33333,
    b6: 91.66667,
  },
  {
    maesu: 65,
    b2: 389,
    a2: 350.1,
    a3: 169.23077,
    a4: 118.46154,
    a5: 84.61538,
    a6: 76.92308,
    b3: 291.75,
    b4: 169.23077,
    b5: 118.46154,
    b6: 84.61538,
  },
  {
    maesu: 70,
    b2: 408,
    a2: 367.2,
    a3: 171.42857,
    a4: 120,
    a5: 85.71429,
    a6: 77.14286,
    b3: 306,
    b4: 171.42857,
    b5: 120,
    b6: 85.71429,
  },
  {
    maesu: 75,
    b2: 382,
    a2: 343.8,
    a3: 160,
    a4: 112,
    a5: 80,
    a6: 72,
    b3: 286.5,
    b4: 160,
    b5: 112,
    b6: 80,
  },
  {
    maesu: 80,
    b2: 372,
    a2: 334.8,
    a3: 162.5,
    a4: 113.75,
    a5: 81.25,
    a6: 73.75,
    b3: 279,
    b4: 162.5,
    b5: 113.75,
    b6: 81.25,
  },
  {
    maesu: 85,
    b2: 350,
    a2: 315,
    a3: 152.94118,
    a4: 107.05882,
    a5: 76.47059,
    a6: 69.41176,
    b3: 262.5,
    b4: 152.94118,
    b5: 107.05882,
    b6: 76.47059,
  },
  {
    maesu: 90,
    b2: 354,
    a2: 318.6,
    a3: 155.55556,
    a4: 108.88889,
    a5: 77.77778,
    a6: 70,
    b3: 265.5,
    b4: 155.55556,
    b5: 108.88889,
    b6: 77.77778,
  },
  {
    maesu: 95,
    b2: 336,
    a2: 302.4,
    a3: 147.36842,
    a4: 103.15789,
    a5: 73.68421,
    a6: 66.31579,
    b3: 252,
    b4: 147.36842,
    b5: 103.15789,
    b6: 73.68421,
  },
  {
    maesu: 100,
    b2: 330,
    a2: 297,
    a3: 150,
    a4: 105,
    a5: 75,
    a6: 68,
    b3: 247.5,
    b4: 150,
    b5: 105,
    b6: 75,
  },
  {
    maesu: 110,
    b2: 310,
    a2: 279,
    a3: 136.36364,
    a4: 95.45455,
    a5: 68.18182,
    a6: 61.81818,
    b3: 232.5,
    b4: 136.36364,
    b5: 95.45455,
    b6: 68.18182,
  },
  {
    maesu: 120,
    b2: 294,
    a2: 264.6,
    a3: 133.33333,
    a4: 93.33333,
    a5: 66.66667,
    a6: 60,
    b3: 220.5,
    b4: 133.33333,
    b5: 93.33333,
    b6: 66.66667,
  },
  {
    maesu: 130,
    b2: 279,
    a2: 251.1,
    a3: 123.07692,
    a4: 86.15385,
    a5: 61.53846,
    a6: 55.38462,
    b3: 209.25,
    b4: 123.07692,
    b5: 86.15385,
    b6: 61.53846,
  },
  {
    maesu: 140,
    b2: 260,
    a2: 234,
    a3: 114.28571,
    a4: 80,
    a5: 57.14286,
    a6: 51.42857,
    b3: 195,
    b4: 114.28571,
    b5: 80,
    b6: 57.14286,
  },
  {
    maesu: 150,
    b2: 250,
    a2: 225,
    a3: 106.66667,
    a4: 74.66667,
    a5: 53.33333,
    a6: 48,
    b3: 187.5,
    b4: 106.66667,
    b5: 74.66667,
    b6: 53.33333,
  },
  {
    maesu: 160,
    b2: 241,
    a2: 216.9,
    a3: 106.25,
    a4: 74.375,
    a5: 53.125,
    a6: 48.125,
    b3: 180.75,
    b4: 106.25,
    b5: 74.375,
    b6: 53.125,
  },
  {
    maesu: 170,
    b2: 233,
    a2: 209.7,
    a3: 100,
    a4: 70,
    a5: 50,
    a6: 45.29412,
    b3: 174.75,
    b4: 100,
    b5: 70,
    b6: 50,
  },
  {
    maesu: 180,
    b2: 220,
    a2: 198,
    a3: 100,
    a4: 70,
    a5: 50,
    a6: 45,
    b3: 165,
    b4: 100,
    b5: 70,
    b6: 50,
  },
  {
    maesu: 190,
    b2: 215,
    a2: 193.5,
    a3: 94.73684,
    a4: 66.31579,
    a5: 47.36842,
    a6: 42.63158,
    b3: 161.25,
    b4: 94.73684,
    b5: 66.31579,
    b6: 47.36842,
  },
  {
    maesu: 200,
    b2: 209,
    a2: 188.1,
    a3: 90,
    a4: 63,
    a5: 45,
    a6: 40.5,
    b3: 156.75,
    b4: 90,
    b5: 63,
    b6: 45,
  },
  {
    maesu: 210,
    b2: 205,
    a2: 184.5,
    a3: 90.47619,
    a4: 63.33333,
    a5: 45.2381,
    a6: 40.95238,
    b3: 153.75,
    b4: 90.47619,
    b5: 63.33333,
    b6: 45.2381,
  },
  {
    maesu: 220,
    b2: 195,
    a2: 175.5,
    a3: 86.36364,
    a4: 60.45455,
    a5: 43.18182,
    a6: 39.09091,
    b3: 146.25,
    b4: 86.36364,
    b5: 60.45455,
    b6: 43.18182,
  },
  {
    maesu: 230,
    b2: 191,
    a2: 171.9,
    a3: 82.6087,
    a4: 57.82609,
    a5: 41.30435,
    a6: 37.3913,
    b3: 143.25,
    b4: 82.6087,
    b5: 57.82609,
    b6: 41.30435,
  },
  {
    maesu: 240,
    b2: 188,
    a2: 169.2,
    a3: 79.16667,
    a4: 55.41667,
    a5: 39.58333,
    a6: 35.83333,
    b3: 141,
    b4: 79.16667,
    b5: 55.41667,
    b6: 39.58333,
  },
  {
    maesu: 250,
    b2: 185,
    a2: 166.5,
    a3: 76,
    a4: 53.2,
    a5: 38,
    a6: 34.4,
    b3: 138.75,
    b4: 76,
    b5: 53.2,
    b6: 38,
  },
  {
    maesu: 260,
    b2: 178,
    a2: 160.2,
    a3: 73.07692,
    a4: 51.15385,
    a5: 36.53846,
    a6: 33.07692,
    b3: 133.5,
    b4: 73.07692,
    b5: 51.15385,
    b6: 36.53846,
  },
  {
    maesu: 270,
    b2: 175,
    a2: 157.5,
    a3: 70.37037,
    a4: 49.25926,
    a5: 35.18519,
    a6: 31.85185,
    b3: 131.25,
    b4: 70.37037,
    b5: 49.25926,
    b6: 35.18519,
  },
  {
    maesu: 280,
    b2: 173,
    a2: 155.7,
    a3: 67.85714,
    a4: 47.5,
    a5: 33.92857,
    a6: 30.71429,
    b3: 129.75,
    b4: 67.85714,
    b5: 47.5,
    b6: 33.92857,
  },
  {
    maesu: 290,
    b2: 171,
    a2: 153.9,
    a3: 65.51724,
    a4: 45.86207,
    a5: 32.75862,
    a6: 29.65517,
    b3: 128.25,
    b4: 65.51724,
    b5: 45.86207,
    b6: 32.75862,
  },
  {
    maesu: 300,
    b2: 168,
    a2: 151.2,
    a3: 66.66667,
    a4: 46.66667,
    a5: 33.33333,
    a6: 30,
    b3: 126,
    b4: 66.66667,
    b5: 46.66667,
    b6: 33.33333,
  },
  {
    maesu: 310,
    b2: 167,
    a2: 150.3,
    a3: 64.51613,
    a4: 45.16129,
    a5: 32.25806,
    a6: 29.03226,
    b3: 125.25,
    b4: 64.51613,
    b5: 45.16129,
    b6: 32.25806,
  },
  {
    maesu: 320,
    b2: 165,
    a2: 148.5,
    a3: 62.5,
    a4: 43.75,
    a5: 31.25,
    a6: 28.125,
    b3: 123.75,
    b4: 62.5,
    b5: 43.75,
    b6: 31.25,
  },
  {
    maesu: 330,
    b2: 167,
    a2: 150.3,
    a3: 60.60606,
    a4: 42.42424,
    a5: 30.30303,
    a6: 27.27273,
    b3: 125.25,
    b4: 60.60606,
    b5: 42.42424,
    b6: 30.30303,
  },
  {
    maesu: 340,
    b2: 165,
    a2: 148.5,
    a3: 58.82353,
    a4: 41.17647,
    a5: 29.41176,
    a6: 26.47059,
    b3: 123.75,
    b4: 58.82353,
    b5: 41.17647,
    b6: 29.41176,
  },
  {
    maesu: 350,
    b2: 166,
    a2: 149.4,
    a3: 57.14286,
    a4: 40,
    a5: 28.57143,
    a6: 25.71429,
    b3: 124.5,
    b4: 57.14286,
    b5: 40,
    b6: 28.57143,
  },
  {
    maesu: 360,
    b2: 165,
    a2: 148.5,
    a3: 55.55556,
    a4: 38.88889,
    a5: 27.77778,
    a6: 25,
    b3: 123.75,
    b4: 55.55556,
    b5: 38.88889,
    b6: 27.77778,
  },
  {
    maesu: 370,
    b2: 166,
    a2: 149.4,
    a3: 54.05405,
    a4: 37.83784,
    a5: 27.02703,
    a6: 24.32432,
    b3: 124.5,
    b4: 54.05405,
    b5: 37.83784,
    b6: 27.02703,
  },
  {
    maesu: 380,
    b2: 165,
    a2: 148.5,
    a3: 52.63158,
    a4: 36.84211,
    a5: 26.31579,
    a6: 23.68421,
    b3: 123.75,
    b4: 52.63158,
    b5: 36.84211,
    b6: 26.31579,
  },
  {
    maesu: 390,
    b2: 166,
    a2: 149.4,
    a3: 51.28205,
    a4: 35.89744,
    a5: 25.64103,
    a6: 23.07692,
    b3: 124.5,
    b4: 51.28205,
    b5: 35.89744,
    b6: 25.64103,
  },
  {
    maesu: 400,
    b2: 165,
    a2: 148.5,
    a3: 50,
    a4: 35,
    a5: 25,
    a6: 22.5,
    b3: 123.75,
    b4: 50,
    b5: 35,
    b6: 25,
  },
  {
    maesu: 410,
    b2: 166,
    a2: 149.4,
    a3: 51.21951,
    a4: 35.85366,
    a5: 25.60976,
    a6: 23.17073,
    b3: 124.5,
    b4: 51.21951,
    b5: 35.85366,
    b6: 25.60976,
  },
  {
    maesu: 420,
    b2: 165,
    a2: 148.5,
    a3: 50,
    a4: 35,
    a5: 25,
    a6: 22.61905,
    b3: 123.75,
    b4: 50,
    b5: 35,
    b6: 25,
  },
  {
    maesu: 430,
    b2: 166,
    a2: 149.4,
    a3: 48.83721,
    a4: 34.18605,
    a5: 24.4186,
    a6: 22.09302,
    b3: 124.5,
    b4: 48.83721,
    b5: 34.18605,
    b6: 24.4186,
  },
  {
    maesu: 440,
    b2: 165,
    a2: 148.5,
    a3: 47.72727,
    a4: 33.40909,
    a5: 23.86364,
    a6: 21.59091,
    b3: 123.75,
    b4: 47.72727,
    b5: 33.40909,
    b6: 23.86364,
  },
  {
    maesu: 450,
    b2: 166,
    a2: 149.4,
    a3: 46.66667,
    a4: 32.66667,
    a5: 23.33333,
    a6: 21.11111,
    b3: 124.5,
    b4: 46.66667,
    b5: 32.66667,
    b6: 23.33333,
  },
  {
    maesu: 460,
    b2: 165,
    a2: 148.5,
    a3: 45.65217,
    a4: 31.95652,
    a5: 22.82609,
    a6: 20.65217,
    b3: 123.75,
    b4: 45.65217,
    b5: 31.95652,
    b6: 22.82609,
  },
  {
    maesu: 470,
    b2: 166,
    a2: 149.4,
    a3: 46.80851,
    a4: 32.76596,
    a5: 23.40426,
    a6: 21.06383,
    b3: 124.5,
    b4: 46.80851,
    b5: 32.76596,
    b6: 23.40426,
  },
  {
    maesu: 480,
    b2: 165,
    a2: 148.5,
    a3: 45.83333,
    a4: 32.08333,
    a5: 22.91667,
    a6: 20.625,
    b3: 123.75,
    b4: 45.83333,
    b5: 32.08333,
    b6: 22.91667,
  },
  {
    maesu: 490,
    b2: 166,
    a2: 149.4,
    a3: 44.89796,
    a4: 31.42857,
    a5: 22.44898,
    a6: 20.20408,
    b3: 124.5,
    b4: 44.89796,
    b5: 31.42857,
    b6: 22.44898,
  },
  {
    maesu: 500,
    b2: 163,
    a2: 146.7,
    a3: 46,
    a4: 32.2,
    a5: 23,
    a6: 20.8,
    b3: 122.25,
    b4: 46,
    b5: 32.2,
    b6: 23,
  },
  {
    maesu: 510,
    b2: 163,
    a2: 146.7,
    a3: 45.09804,
    a4: 31.56863,
    a5: 22.54902,
    a6: 20.39216,
    b3: 122.25,
    b4: 45.09804,
    b5: 31.56863,
    b6: 22.54902,
  },
  {
    maesu: 520,
    b2: 163,
    a2: 146.7,
    a3: 44.23077,
    a4: 30.96154,
    a5: 22.11538,
    a6: 20,
    b3: 122.25,
    b4: 44.23077,
    b5: 30.96154,
    b6: 22.11538,
  },
  {
    maesu: 530,
    b2: 163,
    a2: 146.7,
    a3: 45.28302,
    a4: 31.69811,
    a5: 22.64151,
    a6: 20.37736,
    b3: 122.25,
    b4: 45.28302,
    b5: 31.69811,
    b6: 22.64151,
  },
  {
    maesu: 540,
    b2: 163,
    a2: 146.7,
    a3: 44.44444,
    a4: 31.11111,
    a5: 22.22222,
    a6: 20,
    b3: 122.25,
    b4: 44.44444,
    b5: 31.11111,
    b6: 22.22222,
  },
  {
    maesu: 550,
    b2: 163,
    a2: 146.7,
    a3: 43.63636,
    a4: 30.54545,
    a5: 21.81818,
    a6: 19.63636,
    b3: 122.25,
    b4: 43.63636,
    b5: 30.54545,
    b6: 21.81818,
  },
  {
    maesu: 560,
    b2: 163,
    a2: 146.7,
    a3: 44.64286,
    a4: 31.25,
    a5: 22.32143,
    a6: 20.17857,
    b3: 122.25,
    b4: 44.64286,
    b5: 31.25,
    b6: 22.32143,
  },
  {
    maesu: 570,
    b2: 163,
    a2: 146.7,
    a3: 43.85965,
    a4: 30.70175,
    a5: 21.92982,
    a6: 19.82456,
    b3: 122.25,
    b4: 43.85965,
    b5: 30.70175,
    b6: 21.92982,
  },
  {
    maesu: 580,
    b2: 163,
    a2: 146.7,
    a3: 43.10345,
    a4: 30.17241,
    a5: 21.55172,
    a6: 19.48276,
    b3: 122.25,
    b4: 43.10345,
    b5: 30.17241,
    b6: 21.55172,
  },
  {
    maesu: 590,
    b2: 163,
    a2: 146.7,
    a3: 44.0678,
    a4: 30.84746,
    a5: 22.0339,
    a6: 19.83051,
    b3: 122.25,
    b4: 44.0678,
    b5: 30.84746,
    b6: 22.0339,
  },
  {
    maesu: 600,
    b2: 163,
    a2: 146.7,
    a3: 43.33333,
    a4: 30.33333,
    a5: 21.66667,
    a6: 19.5,
    b3: 122.25,
    b4: 43.33333,
    b5: 30.33333,
    b6: 21.66667,
  },
  {
    maesu: 610,
    b2: 163,
    a2: 146.7,
    a3: 42.62295,
    a4: 29.83607,
    a5: 21.31148,
    a6: 19.18033,
    b3: 122.25,
    b4: 42.62295,
    b5: 29.83607,
    b6: 21.31148,
  },
  {
    maesu: 620,
    b2: 163,
    a2: 146.7,
    a3: 43.54839,
    a4: 30.48387,
    a5: 21.77419,
    a6: 19.67742,
    b3: 122.25,
    b4: 43.54839,
    b5: 30.48387,
    b6: 21.77419,
  },
  {
    maesu: 630,
    b2: 163,
    a2: 146.7,
    a3: 42.85714,
    a4: 30,
    a5: 21.42857,
    a6: 19.36508,
    b3: 122.25,
    b4: 42.85714,
    b5: 30,
    b6: 21.42857,
  },
  {
    maesu: 640,
    b2: 163,
    a2: 146.7,
    a3: 42.1875,
    a4: 29.53125,
    a5: 21.09375,
    a6: 19.0625,
    b3: 122.25,
    b4: 42.1875,
    b5: 29.53125,
    b6: 21.09375,
  },
  {
    maesu: 650,
    b2: 163,
    a2: 146.7,
    a3: 43.07692,
    a4: 30.15385,
    a5: 21.53846,
    a6: 19.38462,
    b3: 122.25,
    b4: 43.07692,
    b5: 30.15385,
    b6: 21.53846,
  },
  {
    maesu: 660,
    b2: 163,
    a2: 146.7,
    a3: 42.42424,
    a4: 29.69697,
    a5: 21.21212,
    a6: 19.09091,
    b3: 122.25,
    b4: 42.42424,
    b5: 29.69697,
    b6: 21.21212,
  },
  {
    maesu: 670,
    b2: 163,
    a2: 146.7,
    a3: 41.79104,
    a4: 29.25373,
    a5: 20.89552,
    a6: 18.80597,
    b3: 122.25,
    b4: 41.79104,
    b5: 29.25373,
    b6: 20.89552,
  },
  {
    maesu: 680,
    b2: 163,
    a2: 146.7,
    a3: 41.17647,
    a4: 28.82353,
    a5: 20.58824,
    a6: 18.52941,
    b3: 122.25,
    b4: 41.17647,
    b5: 28.82353,
    b6: 20.58824,
  },
  {
    maesu: 690,
    b2: 163,
    a2: 146.7,
    a3: 42.02899,
    a4: 29.42029,
    a5: 21.01449,
    a6: 18.98551,
    b3: 122.25,
    b4: 42.02899,
    b5: 29.42029,
    b6: 21.01449,
  },
  {
    maesu: 700,
    b2: 163,
    a2: 146.7,
    a3: 41.42857,
    a4: 29,
    a5: 20.71429,
    a6: 18.71429,
    b3: 122.25,
    b4: 41.42857,
    b5: 29,
    b6: 20.71429,
  },
  {
    maesu: 710,
    b2: 163,
    a2: 146.7,
    a3: 40.84507,
    a4: 28.59155,
    a5: 20.42254,
    a6: 18.4507,
    b3: 122.25,
    b4: 40.84507,
    b5: 28.59155,
    b6: 20.42254,
  },
  {
    maesu: 720,
    b2: 163,
    a2: 146.7,
    a3: 41.66667,
    a4: 29.16667,
    a5: 20.83333,
    a6: 18.75,
    b3: 122.25,
    b4: 41.66667,
    b5: 29.16667,
    b6: 20.83333,
  },
  {
    maesu: 730,
    b2: 163,
    a2: 146.7,
    a3: 41.09589,
    a4: 28.76712,
    a5: 20.54795,
    a6: 18.49315,
    b3: 122.25,
    b4: 41.09589,
    b5: 28.76712,
    b6: 20.54795,
  },
  {
    maesu: 740,
    b2: 163,
    a2: 146.7,
    a3: 40.54054,
    a4: 28.37838,
    a5: 20.27027,
    a6: 18.24324,
    b3: 122.25,
    b4: 40.54054,
    b5: 28.37838,
    b6: 20.27027,
  },
  {
    maesu: 750,
    b2: 163,
    a2: 146.7,
    a3: 41.33333,
    a4: 28.93333,
    a5: 20.66667,
    a6: 18.66667,
    b3: 122.25,
    b4: 41.33333,
    b5: 28.93333,
    b6: 20.66667,
  },
  {
    maesu: 760,
    b2: 163,
    a2: 146.7,
    a3: 40.78947,
    a4: 28.55263,
    a5: 20.39474,
    a6: 18.42105,
    b3: 122.25,
    b4: 40.78947,
    b5: 28.55263,
    b6: 20.39474,
  },
  {
    maesu: 770,
    b2: 163,
    a2: 146.7,
    a3: 40.25974,
    a4: 28.18182,
    a5: 20.12987,
    a6: 18.18182,
    b3: 122.25,
    b4: 40.25974,
    b5: 28.18182,
    b6: 20.12987,
  },
  {
    maesu: 780,
    b2: 163,
    a2: 146.7,
    a3: 41.02564,
    a4: 28.71795,
    a5: 20.51282,
    a6: 18.46154,
    b3: 122.25,
    b4: 41.02564,
    b5: 28.71795,
    b6: 20.51282,
  },
  {
    maesu: 790,
    b2: 163,
    a2: 146.7,
    a3: 40.50633,
    a4: 28.35443,
    a5: 20.25316,
    a6: 18.22785,
    b3: 122.25,
    b4: 40.50633,
    b5: 28.35443,
    b6: 20.25316,
  },
  {
    maesu: 800,
    b2: 163,
    a2: 146.7,
    a3: 40,
    a4: 28,
    a5: 20,
    a6: 18,
    b3: 122.25,
    b4: 40,
    b5: 28,
    b6: 20,
  },
  {
    maesu: 810,
    b2: 163,
    a2: 146.7,
    a3: 40.74074,
    a4: 28.51852,
    a5: 20.37037,
    a6: 18.39506,
    b3: 122.25,
    b4: 40.74074,
    b5: 28.51852,
    b6: 20.37037,
  },
  {
    maesu: 820,
    b2: 163,
    a2: 146.7,
    a3: 40.2439,
    a4: 28.17073,
    a5: 20.12195,
    a6: 18.17073,
    b3: 122.25,
    b4: 40.2439,
    b5: 28.17073,
    b6: 20.12195,
  },
  {
    maesu: 830,
    b2: 163,
    a2: 146.7,
    a3: 39.75904,
    a4: 27.83133,
    a5: 19.87952,
    a6: 17.95181,
    b3: 122.25,
    b4: 39.75904,
    b5: 27.83133,
    b6: 19.87952,
  },
  {
    maesu: 840,
    b2: 163,
    a2: 146.7,
    a3: 40.47619,
    a4: 28.33333,
    a5: 20.2381,
    a6: 18.21429,
    b3: 122.25,
    b4: 40.47619,
    b5: 28.33333,
    b6: 20.2381,
  },
  {
    maesu: 850,
    b2: 163,
    a2: 146.7,
    a3: 40,
    a4: 28,
    a5: 20,
    a6: 18,
    b3: 122.25,
    b4: 40,
    b5: 28,
    b6: 20,
  },
  {
    maesu: 860,
    b2: 163,
    a2: 146.7,
    a3: 39.53488,
    a4: 27.67442,
    a5: 19.76744,
    a6: 17.7907,
    b3: 122.25,
    b4: 39.53488,
    b5: 27.67442,
    b6: 19.76744,
  },
  {
    maesu: 870,
    b2: 163,
    a2: 146.7,
    a3: 40.22989,
    a4: 28.16092,
    a5: 20.11494,
    a6: 18.16092,
    b3: 122.25,
    b4: 40.22989,
    b5: 28.16092,
    b6: 20.11494,
  },
  {
    maesu: 880,
    b2: 163,
    a2: 146.7,
    a3: 39.77273,
    a4: 27.84091,
    a5: 19.88636,
    a6: 17.95455,
    b3: 122.25,
    b4: 39.77273,
    b5: 27.84091,
    b6: 19.88636,
  },
  {
    maesu: 890,
    b2: 163,
    a2: 146.7,
    a3: 39.32584,
    a4: 27.52809,
    a5: 19.66292,
    a6: 17.75281,
    b3: 122.25,
    b4: 39.32584,
    b5: 27.52809,
    b6: 19.66292,
  },
  {
    maesu: 900,
    b2: 163,
    a2: 146.7,
    a3: 40,
    a4: 28,
    a5: 20,
    a6: 18,
    b3: 122.25,
    b4: 40,
    b5: 28,
    b6: 20,
  },
  {
    maesu: 910,
    b2: 163,
    a2: 146.7,
    a3: 39.56044,
    a4: 27.69231,
    a5: 19.78022,
    a6: 17.8022,
    b3: 122.25,
    b4: 39.56044,
    b5: 27.69231,
    b6: 19.78022,
  },
  {
    maesu: 920,
    b2: 163,
    a2: 146.7,
    a3: 39.13043,
    a4: 27.3913,
    a5: 19.56522,
    a6: 17.6087,
    b3: 122.25,
    b4: 39.13043,
    b5: 27.3913,
    b6: 19.56522,
  },
  {
    maesu: 930,
    b2: 163,
    a2: 146.7,
    a3: 39.78495,
    a4: 27.84946,
    a5: 19.89247,
    a6: 17.95699,
    b3: 122.25,
    b4: 39.78495,
    b5: 27.84946,
    b6: 19.89247,
  },
  {
    maesu: 940,
    b2: 163,
    a2: 146.7,
    a3: 39.3617,
    a4: 27.55319,
    a5: 19.68085,
    a6: 17.76596,
    b3: 122.25,
    b4: 39.3617,
    b5: 27.55319,
    b6: 19.68085,
  },
  {
    maesu: 950,
    b2: 163,
    a2: 146.7,
    a3: 38.94737,
    a4: 27.26316,
    a5: 19.47368,
    a6: 17.57895,
    b3: 122.25,
    b4: 38.94737,
    b5: 27.26316,
    b6: 19.47368,
  },
  {
    maesu: 960,
    b2: 163,
    a2: 146.7,
    a3: 38.54167,
    a4: 26.97917,
    a5: 19.27083,
    a6: 17.39583,
    b3: 122.25,
    b4: 38.54167,
    b5: 26.97917,
    b6: 19.27083,
  },
  {
    maesu: 970,
    b2: 163,
    a2: 146.7,
    a3: 39.17526,
    a4: 27.42268,
    a5: 19.58763,
    a6: 17.62887,
    b3: 122.25,
    b4: 39.17526,
    b5: 27.42268,
    b6: 19.58763,
  },
  {
    maesu: 980,
    b2: 163,
    a2: 146.7,
    a3: 38.77551,
    a4: 27.14286,
    a5: 19.38776,
    a6: 17.44898,
    b3: 122.25,
    b4: 38.77551,
    b5: 27.14286,
    b6: 19.38776,
  },
  {
    maesu: 990,
    b2: 163,
    a2: 146.7,
    a3: 38.38384,
    a4: 26.86869,
    a5: 19.19192,
    a6: 17.27273,
    b3: 122.25,
    b4: 38.38384,
    b5: 26.86869,
    b6: 19.19192,
  },
  {
    maesu: 1000,
    b2: 163,
    a2: 146.7,
    a3: 39,
    a4: 27.3,
    a5: 19.5,
    a6: 17.6,
    b3: 122.25,
    b4: 39,
    b5: 27.3,
    b6: 19.5,
  },
  {
    maesu: 1010,
    b2: 163,
    a2: 146.7,
    a3: 38.61386,
    a4: 30.69307,
    a5: 27.72277,
    a6: 20.79208,
    b3: 122.25,
    b4: 38.61386,
    b5: 30.69307,
    b6: 27.72277,
  },
  {
    maesu: 1020,
    b2: 163,
    a2: 146.7,
    a3: 39.21569,
    a4: 30.39216,
    a5: 27.45098,
    a6: 20.58824,
    b3: 122.25,
    b4: 39.21569,
    b5: 30.39216,
    b6: 27.45098,
  },
  {
    maesu: 1030,
    b2: 163,
    a2: 146.7,
    a3: 38.83495,
    a4: 32.03883,
    a5: 27.18447,
    a6: 20.38835,
    b3: 122.25,
    b4: 38.83495,
    b5: 32.03883,
    b6: 27.18447,
  },
  {
    maesu: 1040,
    b2: 163,
    a2: 146.7,
    a3: 39.42308,
    a4: 31.73077,
    a5: 26.92308,
    a6: 20.19231,
    b3: 122.25,
    b4: 39.42308,
    b5: 31.73077,
    b6: 26.92308,
  },
  {
    maesu: 1050,
    b2: 163,
    a2: 146.7,
    a3: 39.04762,
    a4: 31.42857,
    a5: 26.66667,
    a6: 20,
    b3: 122.25,
    b4: 39.04762,
    b5: 31.42857,
    b6: 26.66667,
  },
  {
    maesu: 1060,
    b2: 163,
    a2: 146.7,
    a3: 38.67925,
    a4: 31.13208,
    a5: 26.41509,
    a6: 19.81132,
    b3: 122.25,
    b4: 38.67925,
    b5: 31.13208,
    b6: 26.41509,
  },
  {
    maesu: 1070,
    b2: 163,
    a2: 146.7,
    a3: 39.25234,
    a4: 30.84112,
    a5: 26.16822,
    a6: 19.62617,
    b3: 122.25,
    b4: 39.25234,
    b5: 30.84112,
    b6: 26.16822,
  },
  {
    maesu: 1080,
    b2: 163,
    a2: 146.7,
    a3: 38.88889,
    a4: 30.55556,
    a5: 25.92593,
    a6: 19.44444,
    b3: 122.25,
    b4: 38.88889,
    b5: 30.55556,
    b6: 25.92593,
  },
  {
    maesu: 1090,
    b2: 163,
    a2: 146.7,
    a3: 39.44954,
    a4: 31.19266,
    a5: 25.68807,
    a6: 19.26606,
    b3: 122.25,
    b4: 39.44954,
    b5: 31.19266,
    b6: 25.68807,
  },
  {
    maesu: 1100,
    b2: 163,
    a2: 146.7,
    a3: 39.09091,
    a4: 30.90909,
    a5: 25.45455,
    a6: 19.09091,
    b3: 122.25,
    b4: 39.09091,
    b5: 30.90909,
    b6: 25.45455,
  },
  {
    maesu: 1110,
    b2: 163,
    a2: 146.7,
    a3: 38.73874,
    a4: 30.63063,
    a5: 25.22523,
    a6: 18.91892,
    b3: 122.25,
    b4: 38.73874,
    b5: 30.63063,
    b6: 25.22523,
  },
  {
    maesu: 1120,
    b2: 163,
    a2: 146.7,
    a3: 39.28571,
    a4: 30.35714,
    a5: 25,
    a6: 18.75,
    b3: 122.25,
    b4: 39.28571,
    b5: 30.35714,
    b6: 25,
  },
  {
    maesu: 1130,
    b2: 163,
    a2: 146.7,
    a3: 38.93805,
    a4: 30.0885,
    a5: 24.77876,
    a6: 20.35398,
    b3: 122.25,
    b4: 38.93805,
    b5: 30.0885,
    b6: 24.77876,
  },
  {
    maesu: 1140,
    b2: 163,
    a2: 146.7,
    a3: 38.59649,
    a4: 29.82456,
    a5: 24.5614,
    a6: 20.17544,
    b3: 122.25,
    b4: 38.59649,
    b5: 29.82456,
    b6: 24.5614,
  },
  {
    maesu: 1150,
    b2: 163,
    a2: 146.7,
    a3: 39.13043,
    a4: 29.56522,
    a5: 24.34783,
    a6: 20,
    b3: 122.25,
    b4: 39.13043,
    b5: 29.56522,
    b6: 24.34783,
  },
  {
    maesu: 1160,
    b2: 163,
    a2: 146.7,
    a3: 38.7931,
    a4: 30.17241,
    a5: 23.27586,
    a6: 19.82759,
    b3: 122.25,
    b4: 38.7931,
    b5: 30.17241,
    b6: 23.27586,
  },
  {
    maesu: 1170,
    b2: 163,
    a2: 146.7,
    a3: 39.31624,
    a4: 29.91453,
    a5: 23.07692,
    a6: 19.65812,
    b3: 122.25,
    b4: 39.31624,
    b5: 29.91453,
    b6: 23.07692,
  },
  {
    maesu: 1180,
    b2: 163,
    a2: 146.7,
    a3: 38.98305,
    a4: 29.66102,
    a5: 22.88136,
    a6: 19.49153,
    b3: 122.25,
    b4: 38.98305,
    b5: 29.66102,
    b6: 22.88136,
  },
  {
    maesu: 1190,
    b2: 163,
    a2: 146.7,
    a3: 38.65546,
    a4: 29.41176,
    a5: 22.68908,
    a6: 19.32773,
    b3: 122.25,
    b4: 38.65546,
    b5: 29.41176,
    b6: 22.68908,
  },
  {
    maesu: 1200,
    b2: 163,
    a2: 146.7,
    a3: 39.16667,
    a4: 29.16667,
    a5: 24.16667,
    a6: 19.16667,
    b3: 122.25,
    b4: 39.16667,
    b5: 29.16667,
    b6: 24.16667,
  },
  {
    maesu: 1210,
    b2: 163,
    a2: 146.7,
    a3: 38.84298,
    a4: 28.92562,
    a5: 23.96694,
    a6: 19.00826,
    b3: 122.25,
    b4: 38.84298,
    b5: 28.92562,
    b6: 23.96694,
  },
  {
    maesu: 1220,
    b2: 163,
    a2: 146.7,
    a3: 39.34426,
    a4: 29.5082,
    a5: 22.95082,
    a6: 18.03279,
    b3: 122.25,
    b4: 39.34426,
    b5: 29.5082,
    b6: 22.95082,
  },
  {
    maesu: 1230,
    b2: 163,
    a2: 146.7,
    a3: 39.02439,
    a4: 29.26829,
    a5: 22.76423,
    a6: 17.88618,
    b3: 122.25,
    b4: 39.02439,
    b5: 29.26829,
    b6: 22.76423,
  },
  {
    maesu: 1240,
    b2: 163,
    a2: 146.7,
    a3: 38.70968,
    a4: 29.03226,
    a5: 22.58065,
    a6: 19.35484,
    b3: 122.25,
    b4: 38.70968,
    b5: 29.03226,
    b6: 22.58065,
  },
  {
    maesu: 1250,
    b2: 163,
    a2: 146.7,
    a3: 39.2,
    a4: 28.8,
    a5: 22.4,
    a6: 19.2,
    b3: 122.25,
    b4: 39.2,
    b5: 28.8,
    b6: 22.4,
  },
  {
    maesu: 1260,
    b2: 163,
    a2: 146.7,
    a3: 38.88889,
    a4: 28.57143,
    a5: 22.22222,
    a6: 19.04762,
    b3: 122.25,
    b4: 38.88889,
    b5: 28.57143,
    b6: 22.22222,
  },
  {
    maesu: 1270,
    b2: 163,
    a2: 146.7,
    a3: 39.37008,
    a4: 28.34646,
    a5: 22.04724,
    a6: 18.89764,
    b3: 122.25,
    b4: 39.37008,
    b5: 28.34646,
    b6: 22.04724,
  },
  {
    maesu: 1280,
    b2: 163,
    a2: 146.7,
    a3: 39.0625,
    a4: 28.125,
    a5: 21.875,
    a6: 18.75,
    b3: 122.25,
    b4: 39.0625,
    b5: 28.125,
    b6: 21.875,
  },
  {
    maesu: 1290,
    b2: 163,
    a2: 146.7,
    a3: 38.75969,
    a4: 28.68217,
    a5: 23.25581,
    a6: 18.60465,
    b3: 122.25,
    b4: 38.75969,
    b5: 28.68217,
    b6: 23.25581,
  },
  {
    maesu: 1300,
    b2: 163,
    a2: 146.7,
    a3: 39.23077,
    a4: 28.46154,
    a5: 23.07692,
    a6: 18.46154,
    b3: 122.25,
    b4: 39.23077,
    b5: 28.46154,
    b6: 23.07692,
  },
  {
    maesu: 1310,
    b2: 163,
    a2: 146.7,
    a3: 38.9313,
    a4: 28.24427,
    a5: 22.90076,
    a6: 18.32061,
    b3: 122.25,
    b4: 38.9313,
    b5: 28.24427,
    b6: 22.90076,
  },
  {
    maesu: 1320,
    b2: 163,
    a2: 146.7,
    a3: 38.63636,
    a4: 28.0303,
    a5: 22.72727,
    a6: 18.18182,
    b3: 122.25,
    b4: 38.63636,
    b5: 28.0303,
    b6: 22.72727,
  },
  {
    maesu: 1330,
    b2: 163,
    a2: 146.7,
    a3: 39.09774,
    a4: 27.81955,
    a5: 22.55639,
    a6: 18.04511,
    b3: 122.25,
    b4: 39.09774,
    b5: 27.81955,
    b6: 22.55639,
  },
  {
    maesu: 1340,
    b2: 163,
    a2: 146.7,
    a3: 38.80597,
    a4: 27.61194,
    a5: 22.38806,
    a6: 17.91045,
    b3: 122.25,
    b4: 38.80597,
    b5: 27.61194,
    b6: 22.38806,
  },
  {
    maesu: 1350,
    b2: 163,
    a2: 146.7,
    a3: 39.25926,
    a4: 28.88889,
    a5: 22.22222,
    a6: 18.51852,
    b3: 122.25,
    b4: 39.25926,
    b5: 28.88889,
    b6: 22.22222,
  },
  {
    maesu: 1360,
    b2: 163,
    a2: 146.7,
    a3: 38.97059,
    a4: 28.67647,
    a5: 22.05882,
    a6: 18.38235,
    b3: 122.25,
    b4: 38.97059,
    b5: 28.67647,
    b6: 22.05882,
  },
  {
    maesu: 1370,
    b2: 163,
    a2: 146.7,
    a3: 38.68613,
    a4: 28.46715,
    a5: 22.62774,
    a6: 18.24818,
    b3: 122.25,
    b4: 38.68613,
    b5: 28.46715,
    b6: 22.62774,
  },
  {
    maesu: 1380,
    b2: 163,
    a2: 146.7,
    a3: 39.13043,
    a4: 28.26087,
    a5: 22.46377,
    a6: 18.11594,
    b3: 122.25,
    b4: 39.13043,
    b5: 28.26087,
    b6: 22.46377,
  },
  {
    maesu: 1390,
    b2: 163,
    a2: 146.7,
    a3: 38.84892,
    a4: 28.05755,
    a5: 22.30216,
    a6: 17.98561,
    b3: 122.25,
    b4: 38.84892,
    b5: 28.05755,
    b6: 22.30216,
  },
  {
    maesu: 1400,
    b2: 163,
    a2: 146.7,
    a3: 39.28571,
    a4: 27.85714,
    a5: 22.14286,
    a6: 17.85714,
    b3: 122.25,
    b4: 39.28571,
    b5: 27.85714,
    b6: 22.14286,
  },
  {
    maesu: 1410,
    b2: 163,
    a2: 146.7,
    a3: 39.00709,
    a4: 27.65957,
    a5: 21.2766,
    a6: 17.7305,
    b3: 122.25,
    b4: 39.00709,
    b5: 27.65957,
    b6: 21.2766,
  },
  {
    maesu: 1420,
    b2: 163,
    a2: 146.7,
    a3: 38.73239,
    a4: 27.46479,
    a5: 21.12676,
    a6: 17.60563,
    b3: 122.25,
    b4: 38.73239,
    b5: 27.46479,
    b6: 21.12676,
  },
  {
    maesu: 1430,
    b2: 163,
    a2: 146.7,
    a3: 39.16084,
    a4: 27.27273,
    a5: 20.97902,
    a6: 17.48252,
    b3: 122.25,
    b4: 39.16084,
    b5: 27.27273,
    b6: 20.97902,
  },
  {
    maesu: 1440,
    b2: 163,
    a2: 146.7,
    a3: 38.88889,
    a4: 27.08333,
    a5: 20.83333,
    a6: 17.36111,
    b3: 122.25,
    b4: 38.88889,
    b5: 27.08333,
    b6: 20.83333,
  },
  {
    maesu: 1450,
    b2: 163,
    a2: 146.7,
    a3: 39.31034,
    a4: 26.89655,
    a5: 20.68966,
    a6: 17.24138,
    b3: 122.25,
    b4: 39.31034,
    b5: 26.89655,
    b6: 20.68966,
  },
  {
    maesu: 1460,
    b2: 163,
    a2: 146.7,
    a3: 39.0411,
    a4: 26.71233,
    a5: 21.91781,
    a6: 17.80822,
    b3: 122.25,
    b4: 39.0411,
    b5: 26.71233,
    b6: 21.91781,
  },
  {
    maesu: 1470,
    b2: 163,
    a2: 146.7,
    a3: 38.77551,
    a4: 26.53061,
    a5: 21.76871,
    a6: 17.68707,
    b3: 122.25,
    b4: 38.77551,
    b5: 26.53061,
    b6: 21.76871,
  },
  {
    maesu: 1480,
    b2: 163,
    a2: 146.7,
    a3: 39.18919,
    a4: 27.02703,
    a5: 21.62162,
    a6: 17.56757,
    b3: 122.25,
    b4: 39.18919,
    b5: 27.02703,
    b6: 21.62162,
  },
  {
    maesu: 1490,
    b2: 163,
    a2: 146.7,
    a3: 38.92617,
    a4: 26.84564,
    a5: 21.47651,
    a6: 17.44966,
    b3: 122.25,
    b4: 38.92617,
    b5: 26.84564,
    b6: 21.47651,
  },
  {
    maesu: 1500,
    b2: 163,
    a2: 146.7,
    a3: 39.33333,
    a4: 27.33333,
    a5: 21.33333,
    a6: 17.33333,
    b3: 122.25,
    b4: 39.33333,
    b5: 27.33333,
    b6: 21.33333,
  },
  {
    maesu: 1510,
    b2: 163,
    a2: 146.7,
    a3: 39.07285,
    a4: 27.15232,
    a5: 21.19205,
    a6: 17.21854,
    b3: 122.25,
    b4: 39.07285,
    b5: 27.15232,
    b6: 21.19205,
  },
  {
    maesu: 1520,
    b2: 163,
    a2: 146.7,
    a3: 38.81579,
    a4: 26.97368,
    a5: 21.05263,
    a6: 17.10526,
    b3: 122.25,
    b4: 38.81579,
    b5: 26.97368,
    b6: 21.05263,
  },
  {
    maesu: 1530,
    b2: 163,
    a2: 146.7,
    a3: 39.21569,
    a4: 26.79739,
    a5: 20.91503,
    a6: 16.99346,
    b3: 122.25,
    b4: 39.21569,
    b5: 26.79739,
    b6: 20.91503,
  },
  {
    maesu: 1540,
    b2: 163,
    a2: 146.7,
    a3: 38.96104,
    a4: 27.27273,
    a5: 21.42857,
    a6: 16.88312,
    b3: 122.25,
    b4: 38.96104,
    b5: 27.27273,
    b6: 21.42857,
  },
  {
    maesu: 1550,
    b2: 163,
    a2: 146.7,
    a3: 38.70968,
    a4: 27.09677,
    a5: 21.29032,
    a6: 16.77419,
    b3: 122.25,
    b4: 38.70968,
    b5: 27.09677,
    b6: 21.29032,
  },
  {
    maesu: 1560,
    b2: 163,
    a2: 146.7,
    a3: 39.10256,
    a4: 26.92308,
    a5: 21.15385,
    a6: 16.66667,
    b3: 122.25,
    b4: 39.10256,
    b5: 26.92308,
    b6: 21.15385,
  },
  {
    maesu: 1570,
    b2: 163,
    a2: 146.7,
    a3: 38.8535,
    a4: 26.75159,
    a5: 21.01911,
    a6: 16.56051,
    b3: 122.25,
    b4: 38.8535,
    b5: 26.75159,
    b6: 21.01911,
  },
  {
    maesu: 1580,
    b2: 163,
    a2: 146.7,
    a3: 39.24051,
    a4: 26.58228,
    a5: 20.88608,
    a6: 17.72152,
    b3: 122.25,
    b4: 39.24051,
    b5: 26.58228,
    b6: 20.88608,
  },
  {
    maesu: 1590,
    b2: 163,
    a2: 146.7,
    a3: 38.99371,
    a4: 26.41509,
    a5: 20.75472,
    a6: 17.61006,
    b3: 122.25,
    b4: 38.99371,
    b5: 26.41509,
    b6: 20.75472,
  },
  {
    maesu: 1600,
    b2: 163,
    a2: 146.7,
    a3: 38.75,
    a4: 26.875,
    a5: 21.25,
    a6: 17.5,
    b3: 122.25,
    b4: 38.75,
    b5: 26.875,
    b6: 21.25,
  },
  {
    maesu: 1610,
    b2: 163,
    a2: 146.7,
    a3: 39.13043,
    a4: 27.32919,
    a5: 21.11801,
    a6: 17.3913,
    b3: 122.25,
    b4: 39.13043,
    b5: 27.32919,
    b6: 21.11801,
  },
  {
    maesu: 1620,
    b2: 163,
    a2: 146.7,
    a3: 38.88889,
    a4: 27.16049,
    a5: 20.98765,
    a6: 17.28395,
    b3: 122.25,
    b4: 38.88889,
    b5: 27.16049,
    b6: 20.98765,
  },
  {
    maesu: 1630,
    b2: 163,
    a2: 146.7,
    a3: 39.2638,
    a4: 26.99387,
    a5: 21.47239,
    a6: 17.17791,
    b3: 122.25,
    b4: 39.2638,
    b5: 26.99387,
    b6: 21.47239,
  },
  {
    maesu: 1640,
    b2: 163,
    a2: 146.7,
    a3: 39.02439,
    a4: 26.82927,
    a5: 21.34146,
    a6: 17.07317,
    b3: 122.25,
    b4: 39.02439,
    b5: 26.82927,
    b6: 21.34146,
  },
  {
    maesu: 1650,
    b2: 163,
    a2: 146.7,
    a3: 38.78788,
    a4: 26.66667,
    a5: 21.21212,
    a6: 16.9697,
    b3: 122.25,
    b4: 38.78788,
    b5: 26.66667,
    b6: 21.21212,
  },
  {
    maesu: 1660,
    b2: 163,
    a2: 146.7,
    a3: 39.15663,
    a4: 26.50602,
    a5: 21.08434,
    a6: 16.86747,
    b3: 122.25,
    b4: 39.15663,
    b5: 26.50602,
    b6: 21.08434,
  },
  {
    maesu: 1670,
    b2: 163,
    a2: 146.7,
    a3: 38.92216,
    a4: 27.54491,
    a5: 20.95808,
    a6: 16.76647,
    b3: 122.25,
    b4: 38.92216,
    b5: 27.54491,
    b6: 20.95808,
  },
  {
    maesu: 1680,
    b2: 163,
    a2: 146.7,
    a3: 39.28571,
    a4: 27.38095,
    a5: 20.83333,
    a6: 16.66667,
    b3: 122.25,
    b4: 39.28571,
    b5: 27.38095,
    b6: 20.83333,
  },
  {
    maesu: 1690,
    b2: 163,
    a2: 146.7,
    a3: 39.05325,
    a4: 27.21893,
    a5: 20.71006,
    a6: 17.15976,
    b3: 122.25,
    b4: 39.05325,
    b5: 27.21893,
    b6: 20.71006,
  },
  {
    maesu: 1700,
    b2: 163,
    a2: 146.7,
    a3: 38.82353,
    a4: 27.05882,
    a5: 20.58824,
    a6: 17.05882,
    b3: 122.25,
    b4: 38.82353,
    b5: 27.05882,
    b6: 20.58824,
  },
  {
    maesu: 1710,
    b2: 163,
    a2: 146.7,
    a3: 39.18129,
    a4: 26.90058,
    a5: 21.63743,
    a6: 16.95906,
    b3: 122.25,
    b4: 39.18129,
    b5: 26.90058,
    b6: 21.63743,
  },
  {
    maesu: 1720,
    b2: 163,
    a2: 146.7,
    a3: 38.95349,
    a4: 26.74419,
    a5: 21.51163,
    a6: 16.86047,
    b3: 122.25,
    b4: 38.95349,
    b5: 26.74419,
    b6: 21.51163,
  },
  {
    maesu: 1730,
    b2: 163,
    a2: 146.7,
    a3: 38.72832,
    a4: 27.16763,
    a5: 21.38728,
    a6: 16.76301,
    b3: 122.25,
    b4: 38.72832,
    b5: 27.16763,
    b6: 21.38728,
  },
  {
    maesu: 1740,
    b2: 163,
    a2: 146.7,
    a3: 39.08046,
    a4: 27.01149,
    a5: 21.26437,
    a6: 16.66667,
    b3: 122.25,
    b4: 39.08046,
    b5: 27.01149,
    b6: 21.26437,
  },
  {
    maesu: 1750,
    b2: 163,
    a2: 146.7,
    a3: 38.85714,
    a4: 26.85714,
    a5: 21.14286,
    a6: 16.57143,
    b3: 122.25,
    b4: 38.85714,
    b5: 26.85714,
    b6: 21.14286,
  },
  {
    maesu: 1760,
    b2: 163,
    a2: 146.7,
    a3: 39.20455,
    a4: 26.70455,
    a5: 21.02273,
    a6: 16.47727,
    b3: 122.25,
    b4: 39.20455,
    b5: 26.70455,
    b6: 21.02273,
  },
  {
    maesu: 1770,
    b2: 163,
    a2: 146.7,
    a3: 38.98305,
    a4: 26.55367,
    a5: 20.90395,
    a6: 16.38418,
    b3: 122.25,
    b4: 38.98305,
    b5: 26.55367,
    b6: 20.90395,
  },
  {
    maesu: 1780,
    b2: 163,
    a2: 146.7,
    a3: 38.76404,
    a4: 26.40449,
    a5: 20.78652,
    a6: 16.29213,
    b3: 122.25,
    b4: 38.76404,
    b5: 26.40449,
    b6: 20.78652,
  },
  {
    maesu: 1790,
    b2: 163,
    a2: 146.7,
    a3: 39.10615,
    a4: 26.25698,
    a5: 20.67039,
    a6: 16.20112,
    b3: 122.25,
    b4: 39.10615,
    b5: 26.25698,
    b6: 20.67039,
  },
  {
    maesu: 1800,
    b2: 163,
    a2: 146.7,
    a3: 38.88889,
    a4: 26.66667,
    a5: 21.11111,
    a6: 17.22222,
    b3: 122.25,
    b4: 38.88889,
    b5: 26.66667,
    b6: 21.11111,
  },
  {
    maesu: 1810,
    b2: 163,
    a2: 146.7,
    a3: 39.22652,
    a4: 26.51934,
    a5: 20.99448,
    a6: 17.12707,
    b3: 122.25,
    b4: 39.22652,
    b5: 26.51934,
    b6: 20.99448,
  },
  {
    maesu: 1820,
    b2: 163,
    a2: 146.7,
    a3: 39.01099,
    a4: 26.37363,
    a5: 20.87912,
    a6: 17.03297,
    b3: 122.25,
    b4: 39.01099,
    b5: 26.37363,
    b6: 20.87912,
  },
  {
    maesu: 1830,
    b2: 163,
    a2: 146.7,
    a3: 38.79781,
    a4: 26.22951,
    a5: 20.76503,
    a6: 16.93989,
    b3: 122.25,
    b4: 38.79781,
    b5: 26.22951,
    b6: 20.76503,
  },
  {
    maesu: 1840,
    b2: 163,
    a2: 146.7,
    a3: 39.13043,
    a4: 26.08696,
    a5: 20.65217,
    a6: 16.84783,
    b3: 122.25,
    b4: 39.13043,
    b5: 26.08696,
    b6: 20.65217,
  },
  {
    maesu: 1850,
    b2: 163,
    a2: 146.7,
    a3: 38.91892,
    a4: 25.94595,
    a5: 20.54054,
    a6: 16.75676,
    b3: 122.25,
    b4: 38.91892,
    b5: 25.94595,
    b6: 20.54054,
  },
  {
    maesu: 1860,
    b2: 163,
    a2: 146.7,
    a3: 39.24731,
    a4: 26.88172,
    a5: 20.43011,
    a6: 16.66667,
    b3: 122.25,
    b4: 39.24731,
    b5: 26.88172,
    b6: 20.43011,
  },
  {
    maesu: 1870,
    b2: 163,
    a2: 146.7,
    a3: 39.03743,
    a4: 26.73797,
    a5: 20.32086,
    a6: 16.57754,
    b3: 122.25,
    b4: 39.03743,
    b5: 26.73797,
    b6: 20.32086,
  },
  {
    maesu: 1880,
    b2: 163,
    a2: 146.7,
    a3: 38.82979,
    a4: 26.59574,
    a5: 20.21277,
    a6: 16.48936,
    b3: 122.25,
    b4: 38.82979,
    b5: 26.59574,
    b6: 20.21277,
  },
  {
    maesu: 1890,
    b2: 163,
    a2: 146.7,
    a3: 39.15344,
    a4: 26.45503,
    a5: 20.63492,
    a6: 16.40212,
    b3: 122.25,
    b4: 39.15344,
    b5: 26.45503,
    b6: 20.63492,
  },
  {
    maesu: 1900,
    b2: 163,
    a2: 146.7,
    a3: 38.94737,
    a4: 26.31579,
    a5: 20.52632,
    a6: 16.31579,
    b3: 122.25,
    b4: 38.94737,
    b5: 26.31579,
    b6: 20.52632,
  },
  {
    maesu: 1910,
    b2: 163,
    a2: 146.7,
    a3: 38.74346,
    a4: 26.17801,
    a5: 20.41885,
    a6: 16.75393,
    b3: 122.25,
    b4: 38.74346,
    b5: 26.17801,
    b6: 20.41885,
  },
  {
    maesu: 1920,
    b2: 163,
    a2: 146.7,
    a3: 39.0625,
    a4: 26.04167,
    a5: 20.3125,
    a6: 16.66667,
    b3: 122.25,
    b4: 39.0625,
    b5: 26.04167,
    b6: 20.3125,
  },
  {
    maesu: 1930,
    b2: 163,
    a2: 146.7,
    a3: 38.8601,
    a4: 26.42487,
    a5: 20.20725,
    a6: 16.58031,
    b3: 122.25,
    b4: 38.8601,
    b5: 26.42487,
    b6: 20.20725,
  },
  {
    maesu: 1940,
    b2: 163,
    a2: 146.7,
    a3: 39.17526,
    a4: 26.28866,
    a5: 20.10309,
    a6: 16.49485,
    b3: 122.25,
    b4: 39.17526,
    b5: 26.28866,
    b6: 20.10309,
  },
  {
    maesu: 1950,
    b2: 163,
    a2: 146.7,
    a3: 38.97436,
    a4: 26.15385,
    a5: 20,
    a6: 16.41026,
    b3: 122.25,
    b4: 38.97436,
    b5: 26.15385,
    b6: 20,
  },
  {
    maesu: 1960,
    b2: 163,
    a2: 146.7,
    a3: 38.77551,
    a4: 26.02041,
    a5: 19.89796,
    a6: 16.32653,
    b3: 122.25,
    b4: 38.77551,
    b5: 26.02041,
    b6: 19.89796,
  },
  {
    maesu: 1970,
    b2: 163,
    a2: 146.7,
    a3: 39.08629,
    a4: 25.88832,
    a5: 20.30457,
    a6: 16.24365,
    b3: 122.25,
    b4: 39.08629,
    b5: 25.88832,
    b6: 20.30457,
  },
  {
    maesu: 1980,
    b2: 163,
    a2: 146.7,
    a3: 38.88889,
    a4: 25.75758,
    a5: 20.20202,
    a6: 16.16162,
    b3: 122.25,
    b4: 38.88889,
    b5: 25.75758,
    b6: 20.20202,
  },
  {
    maesu: 1990,
    b2: 163,
    a2: 146.7,
    a3: 39.19598,
    a4: 26.13065,
    a5: 20.1005,
    a6: 16.0804,
    b3: 122.25,
    b4: 39.19598,
    b5: 26.13065,
    b6: 20.1005,
  },
  {
    maesu: 2000,
    b2: 163,
    a2: 146.7,
    a3: 39,
    a4: 26,
    a5: 20,
    a6: 16,
    b3: 122.25,
    b4: 39,
    b5: 26,
    b6: 20,
  },
  {
    maesu: 2010,
    b2: 163,
    a2: 146.7,
    a3: 38.80597,
    a4: 25.87065,
    a5: 19.9005,
    a6: 15.9204,
    b3: 122.25,
    b4: 38.80597,
    b5: 25.87065,
    b6: 19.9005,
  },
  {
    maesu: 2020,
    b2: 163,
    a2: 146.7,
    a3: 39.10891,
    a4: 26.23762,
    a5: 19.80198,
    a6: 15.84158,
    b3: 122.25,
    b4: 39.10891,
    b5: 26.23762,
    b6: 19.80198,
  },
  {
    maesu: 2030,
    b2: 163,
    a2: 146.7,
    a3: 38.91626,
    a4: 26.10837,
    a5: 19.70443,
    a6: 16.25616,
    b3: 122.25,
    b4: 38.91626,
    b5: 26.10837,
    b6: 19.70443,
  },
  {
    maesu: 2040,
    b2: 163,
    a2: 146.7,
    a3: 39.21569,
    a4: 25.98039,
    a5: 19.60784,
    a6: 16.17647,
    b3: 122.25,
    b4: 39.21569,
    b5: 25.98039,
    b6: 19.60784,
  },
  {
    maesu: 2050,
    b2: 163,
    a2: 146.7,
    a3: 39.02439,
    a4: 25.85366,
    a5: 19.5122,
    a6: 16.09756,
    b3: 122.25,
    b4: 39.02439,
    b5: 25.85366,
    b6: 19.5122,
  },
  {
    maesu: 2060,
    b2: 163,
    a2: 146.7,
    a3: 38.83495,
    a4: 26.21359,
    a5: 19.41748,
    a6: 15.53398,
    b3: 122.25,
    b4: 38.83495,
    b5: 26.21359,
    b6: 19.41748,
  },
  {
    maesu: 2070,
    b2: 163,
    a2: 146.7,
    a3: 39.13043,
    a4: 26.08696,
    a5: 19.32367,
    a6: 15.45894,
    b3: 122.25,
    b4: 39.13043,
    b5: 26.08696,
    b6: 19.32367,
  },
  {
    maesu: 2080,
    b2: 163,
    a2: 146.7,
    a3: 38.94231,
    a4: 25.96154,
    a5: 19.23077,
    a6: 15.38462,
    b3: 122.25,
    b4: 38.94231,
    b5: 25.96154,
    b6: 19.23077,
  },
  {
    maesu: 2090,
    b2: 163,
    a2: 146.7,
    a3: 39.23445,
    a4: 25.83732,
    a5: 19.13876,
    a6: 15.78947,
    b3: 122.25,
    b4: 39.23445,
    b5: 25.83732,
    b6: 19.13876,
  },
  {
    maesu: 2100,
    b2: 163,
    a2: 146.7,
    a3: 39.04762,
    a4: 26.19048,
    a5: 19.04762,
    a6: 15.71429,
    b3: 122.25,
    b4: 39.04762,
    b5: 26.19048,
    b6: 19.04762,
  },
  {
    maesu: 2110,
    b2: 163,
    a2: 146.7,
    a3: 38.86256,
    a4: 26.06635,
    a5: 18.95735,
    a6: 15.63981,
    b3: 122.25,
    b4: 38.86256,
    b5: 26.06635,
    b6: 18.95735,
  },
  {
    maesu: 2120,
    b2: 163,
    a2: 146.7,
    a3: 39.15094,
    a4: 25.9434,
    a5: 18.86792,
    a6: 15.56604,
    b3: 122.25,
    b4: 39.15094,
    b5: 25.9434,
    b6: 18.86792,
  },
  {
    maesu: 2130,
    b2: 163,
    a2: 146.7,
    a3: 38.96714,
    a4: 25.8216,
    a5: 19.71831,
    a6: 15.49296,
    b3: 122.25,
    b4: 38.96714,
    b5: 25.8216,
    b6: 19.71831,
  },
  {
    maesu: 2140,
    b2: 163,
    a2: 146.7,
    a3: 38.78505,
    a4: 26.16822,
    a5: 19.62617,
    a6: 15.42056,
    b3: 122.25,
    b4: 38.78505,
    b5: 26.16822,
    b6: 19.62617,
  },
  {
    maesu: 2150,
    b2: 163,
    a2: 146.7,
    a3: 39.06977,
    a4: 26.04651,
    a5: 19.53488,
    a6: 15.34884,
    b3: 122.25,
    b4: 39.06977,
    b5: 26.04651,
    b6: 19.53488,
  },
  {
    maesu: 2160,
    b2: 163,
    a2: 146.7,
    a3: 38.88889,
    a4: 25.92593,
    a5: 19.44444,
    a6: 15.27778,
    b3: 122.25,
    b4: 38.88889,
    b5: 25.92593,
    b6: 19.44444,
  },
  {
    maesu: 2170,
    b2: 163,
    a2: 146.7,
    a3: 39.17051,
    a4: 25.80645,
    a5: 19.35484,
    a6: 15.20737,
    b3: 122.25,
    b4: 39.17051,
    b5: 25.80645,
    b6: 19.35484,
  },
  {
    maesu: 2180,
    b2: 163,
    a2: 146.7,
    a3: 38.99083,
    a4: 26.14679,
    a5: 19.26606,
    a6: 15.13761,
    b3: 122.25,
    b4: 38.99083,
    b5: 26.14679,
    b6: 19.26606,
  },
  {
    maesu: 2190,
    b2: 163,
    a2: 146.7,
    a3: 38.81279,
    a4: 26.0274,
    a5: 19.17808,
    a6: 15.06849,
    b3: 122.25,
    b4: 38.81279,
    b5: 26.0274,
    b6: 19.17808,
  },
  {
    maesu: 2200,
    b2: 163,
    a2: 146.7,
    a3: 39.09091,
    a4: 25.90909,
    a5: 19.09091,
    a6: 15,
    b3: 122.25,
    b4: 39.09091,
    b5: 25.90909,
    b6: 19.09091,
  },
  {
    maesu: 2210,
    b2: 163,
    a2: 146.7,
    a3: 38.91403,
    a4: 25.79186,
    a5: 19.00452,
    a6: 15.8371,
    b3: 122.25,
    b4: 38.91403,
    b5: 25.79186,
    b6: 19.00452,
  },
  {
    maesu: 2220,
    b2: 163,
    a2: 146.7,
    a3: 39.18919,
    a4: 26.12613,
    a5: 19.36937,
    a6: 15.76577,
    b3: 122.25,
    b4: 39.18919,
    b5: 26.12613,
    b6: 19.36937,
  },
  {
    maesu: 2230,
    b2: 163,
    a2: 146.7,
    a3: 39.01345,
    a4: 26.00897,
    a5: 19.28251,
    a6: 15.69507,
    b3: 122.25,
    b4: 39.01345,
    b5: 26.00897,
    b6: 19.28251,
  },
  {
    maesu: 2240,
    b2: 163,
    a2: 146.7,
    a3: 38.83929,
    a4: 25.89286,
    a5: 19.19643,
    a6: 15.625,
    b3: 122.25,
    b4: 38.83929,
    b5: 25.89286,
    b6: 19.19643,
  },
  {
    maesu: 2250,
    b2: 163,
    a2: 146.7,
    a3: 39.11111,
    a4: 26.22222,
    a5: 19.11111,
    a6: 15.55556,
    b3: 122.25,
    b4: 39.11111,
    b5: 26.22222,
    b6: 19.11111,
  },
  {
    maesu: 2260,
    b2: 163,
    a2: 146.7,
    a3: 38.93805,
    a4: 26.10619,
    a5: 19.02655,
    a6: 15.48673,
    b3: 122.25,
    b4: 38.93805,
    b5: 26.10619,
    b6: 19.02655,
  },
  {
    maesu: 2270,
    b2: 163,
    a2: 146.7,
    a3: 39.20705,
    a4: 25.99119,
    a5: 18.94273,
    a6: 15.4185,
    b3: 122.25,
    b4: 39.20705,
    b5: 25.99119,
    b6: 18.94273,
  },
  {
    maesu: 2280,
    b2: 163,
    a2: 146.7,
    a3: 39.03509,
    a4: 25.87719,
    a5: 18.85965,
    a6: 15.35088,
    b3: 122.25,
    b4: 39.03509,
    b5: 25.87719,
    b6: 18.85965,
  },
  {
    maesu: 2290,
    b2: 163,
    a2: 146.7,
    a3: 38.86463,
    a4: 26.20087,
    a5: 18.77729,
    a6: 15.28384,
    b3: 122.25,
    b4: 38.86463,
    b5: 26.20087,
    b6: 18.77729,
  },
  {
    maesu: 2300,
    b2: 163,
    a2: 146.7,
    a3: 39.13043,
    a4: 26.08696,
    a5: 19.56522,
    a6: 15.21739,
    b3: 122.25,
    b4: 39.13043,
    b5: 26.08696,
    b6: 19.56522,
  },
  {
    maesu: 2310,
    b2: 163,
    a2: 146.7,
    a3: 38.96104,
    a4: 25.97403,
    a5: 19.48052,
    a6: 15.15152,
    b3: 122.25,
    b4: 38.96104,
    b5: 25.97403,
    b6: 19.48052,
  },
  {
    maesu: 2320,
    b2: 163,
    a2: 146.7,
    a3: 38.7931,
    a4: 25.86207,
    a5: 19.39655,
    a6: 15.08621,
    b3: 122.25,
    b4: 38.7931,
    b5: 25.86207,
    b6: 19.39655,
  },
  {
    maesu: 2330,
    b2: 163,
    a2: 146.7,
    a3: 39.05579,
    a4: 26.18026,
    a5: 19.3133,
    a6: 15.45064,
    b3: 122.25,
    b4: 39.05579,
    b5: 26.18026,
    b6: 19.3133,
  },
  {
    maesu: 2340,
    b2: 163,
    a2: 146.7,
    a3: 38.88889,
    a4: 26.06838,
    a5: 19.23077,
    a6: 15.38462,
    b3: 122.25,
    b4: 38.88889,
    b5: 26.06838,
    b6: 19.23077,
  },
  {
    maesu: 2350,
    b2: 163,
    a2: 146.7,
    a3: 39.14894,
    a4: 25.95745,
    a5: 19.14894,
    a6: 15.31915,
    b3: 122.25,
    b4: 39.14894,
    b5: 25.95745,
    b6: 19.14894,
  },
  {
    maesu: 2360,
    b2: 163,
    a2: 146.7,
    a3: 38.98305,
    a4: 25.84746,
    a5: 19.0678,
    a6: 15.25424,
    b3: 122.25,
    b4: 38.98305,
    b5: 25.84746,
    b6: 19.0678,
  },
  {
    maesu: 2370,
    b2: 163,
    a2: 146.7,
    a3: 38.81857,
    a4: 26.16034,
    a5: 18.98734,
    a6: 15.18987,
    b3: 122.25,
    b4: 38.81857,
    b5: 26.16034,
    b6: 18.98734,
  },
  {
    maesu: 2380,
    b2: 163,
    a2: 146.7,
    a3: 39.07563,
    a4: 26.05042,
    a5: 18.90756,
    a6: 15.12605,
    b3: 122.25,
    b4: 39.07563,
    b5: 26.05042,
    b6: 18.90756,
  },
  {
    maesu: 2390,
    b2: 163,
    a2: 146.7,
    a3: 38.91213,
    a4: 25.94142,
    a5: 19.24686,
    a6: 15.06276,
    b3: 122.25,
    b4: 38.91213,
    b5: 25.94142,
    b6: 19.24686,
  },
  {
    maesu: 2400,
    b2: 163,
    a2: 146.7,
    a3: 39.16667,
    a4: 25.83333,
    a5: 19.16667,
    a6: 15,
    b3: 122.25,
    b4: 39.16667,
    b5: 25.83333,
    b6: 19.16667,
  },
  {
    maesu: 2410,
    b2: 163,
    a2: 146.7,
    a3: 39.00415,
    a4: 26.14108,
    a5: 19.08714,
    a6: 14.93776,
    b3: 122.25,
    b4: 39.00415,
    b5: 26.14108,
    b6: 19.08714,
  },
  {
    maesu: 2420,
    b2: 163,
    a2: 146.7,
    a3: 38.84298,
    a4: 26.03306,
    a5: 19.00826,
    a6: 14.87603,
    b3: 122.25,
    b4: 38.84298,
    b5: 26.03306,
    b6: 19.00826,
  },
  {
    maesu: 2430,
    b2: 163,
    a2: 146.7,
    a3: 39.09465,
    a4: 25.92593,
    a5: 18.93004,
    a6: 14.81481,
    b3: 122.25,
    b4: 39.09465,
    b5: 25.92593,
    b6: 18.93004,
  },
  {
    maesu: 2440,
    b2: 163,
    a2: 146.7,
    a3: 38.93443,
    a4: 25.81967,
    a5: 18.85246,
    a6: 15.57377,
    b3: 122.25,
    b4: 38.93443,
    b5: 25.81967,
    b6: 18.85246,
  },
  {
    maesu: 2450,
    b2: 163,
    a2: 146.7,
    a3: 39.18367,
    a4: 26.12245,
    a5: 18.77551,
    a6: 15.5102,
    b3: 122.25,
    b4: 39.18367,
    b5: 26.12245,
    b6: 18.77551,
  },
  {
    maesu: 2460,
    b2: 163,
    a2: 146.7,
    a3: 39.02439,
    a4: 26.01626,
    a5: 18.69919,
    a6: 15.44715,
    b3: 122.25,
    b4: 39.02439,
    b5: 26.01626,
    b6: 18.69919,
  },
  {
    maesu: 2470,
    b2: 163,
    a2: 146.7,
    a3: 38.8664,
    a4: 25.91093,
    a5: 18.62348,
    a6: 15.38462,
    b3: 122.25,
    b4: 38.8664,
    b5: 25.91093,
    b6: 18.62348,
  },
  {
    maesu: 2480,
    b2: 163,
    a2: 146.7,
    a3: 39.1129,
    a4: 25.80645,
    a5: 19.35484,
    a6: 15.32258,
    b3: 122.25,
    b4: 39.1129,
    b5: 25.80645,
    b6: 19.35484,
  },
  {
    maesu: 2490,
    b2: 163,
    a2: 146.7,
    a3: 38.95582,
    a4: 26.10442,
    a5: 19.27711,
    a6: 15.26104,
    b3: 122.25,
    b4: 38.95582,
    b5: 26.10442,
    b6: 19.27711,
  },
  {
    maesu: 2500,
    b2: 163,
    a2: 146.7,
    a3: 39.2,
    a4: 26,
    a5: 19.2,
    a6: 15.2,
    b3: 122.25,
    b4: 39.2,
    b5: 26,
    b6: 19.2,
  },
  {
    maesu: 2510,
    b2: 163,
    a2: 146.7,
    a3: 39.04382,
    a4: 25.89641,
    a5: 19.12351,
    a6: 15.13944,
    b3: 122.25,
    b4: 39.04382,
    b5: 25.89641,
    b6: 19.12351,
  },
  {
    maesu: 2520,
    b2: 163,
    a2: 146.7,
    a3: 38.88889,
    a4: 26.19048,
    a5: 19.04762,
    a6: 15.07937,
    b3: 122.25,
    b4: 38.88889,
    b5: 26.19048,
    b6: 19.04762,
  },
  {
    maesu: 2530,
    b2: 163,
    a2: 146.7,
    a3: 39.13043,
    a4: 26.08696,
    a5: 18.97233,
    a6: 15.01976,
    b3: 122.25,
    b4: 39.13043,
    b5: 26.08696,
    b6: 18.97233,
  },
  {
    maesu: 2540,
    b2: 163,
    a2: 146.7,
    a3: 38.97638,
    a4: 25.98425,
    a5: 18.89764,
    a6: 14.96063,
    b3: 122.25,
    b4: 38.97638,
    b5: 25.98425,
    b6: 18.89764,
  },
  {
    maesu: 2550,
    b2: 163,
    a2: 146.7,
    a3: 38.82353,
    a4: 25.88235,
    a5: 18.82353,
    a6: 14.90196,
    b3: 122.25,
    b4: 38.82353,
    b5: 25.88235,
    b6: 18.82353,
  },
  {
    maesu: 2560,
    b2: 163,
    a2: 146.7,
    a3: 39.0625,
    a4: 26.17188,
    a5: 18.75,
    a6: 15.23438,
    b3: 122.25,
    b4: 39.0625,
    b5: 26.17188,
    b6: 18.75,
  },
  {
    maesu: 2570,
    b2: 163,
    a2: 146.7,
    a3: 38.91051,
    a4: 26.07004,
    a5: 19.06615,
    a6: 15.1751,
    b3: 122.25,
    b4: 38.91051,
    b5: 26.07004,
    b6: 19.06615,
  },
  {
    maesu: 2580,
    b2: 163,
    a2: 146.7,
    a3: 39.14729,
    a4: 25.96899,
    a5: 18.99225,
    a6: 15.11628,
    b3: 122.25,
    b4: 39.14729,
    b5: 25.96899,
    b6: 18.99225,
  },
  {
    maesu: 2590,
    b2: 163,
    a2: 146.7,
    a3: 38.99614,
    a4: 25.86873,
    a5: 18.91892,
    a6: 15.05792,
    b3: 122.25,
    b4: 38.99614,
    b5: 25.86873,
    b6: 18.91892,
  },
  {
    maesu: 2600,
    b2: 163,
    a2: 146.7,
    a3: 38.84615,
    a4: 26.15385,
    a5: 18.84615,
    a6: 15,
    b3: 122.25,
    b4: 38.84615,
    b5: 26.15385,
    b6: 18.84615,
  },
  {
    maesu: 2610,
    b2: 163,
    a2: 146.7,
    a3: 39.08046,
    a4: 26.05364,
    a5: 18.77395,
    a6: 14.94253,
    b3: 122.25,
    b4: 39.08046,
    b5: 26.05364,
    b6: 18.77395,
  },
  {
    maesu: 2620,
    b2: 163,
    a2: 146.7,
    a3: 38.9313,
    a4: 25.9542,
    a5: 18.70229,
    a6: 14.8855,
    b3: 122.25,
    b4: 38.9313,
    b5: 25.9542,
    b6: 18.70229,
  },
  {
    maesu: 2630,
    b2: 163,
    a2: 146.7,
    a3: 39.1635,
    a4: 25.85551,
    a5: 18.63118,
    a6: 14.8289,
    b3: 122.25,
    b4: 39.1635,
    b5: 25.85551,
    b6: 18.63118,
  },
  {
    maesu: 2640,
    b2: 163,
    a2: 146.7,
    a3: 39.01515,
    a4: 26.13636,
    a5: 18.56061,
    a6: 14.77273,
    b3: 122.25,
    b4: 39.01515,
    b5: 26.13636,
    b6: 18.56061,
  },
  {
    maesu: 2650,
    b2: 163,
    a2: 146.7,
    a3: 38.86792,
    a4: 26.03774,
    a5: 18.49057,
    a6: 14.71698,
    b3: 122.25,
    b4: 38.86792,
    b5: 26.03774,
    b6: 18.49057,
  },
  {
    maesu: 2660,
    b2: 163,
    a2: 146.7,
    a3: 39.09774,
    a4: 25.93985,
    a5: 18.79699,
    a6: 14.66165,
    b3: 122.25,
    b4: 39.09774,
    b5: 25.93985,
    b6: 18.79699,
  },
  {
    maesu: 2670,
    b2: 163,
    a2: 146.7,
    a3: 38.95131,
    a4: 25.8427,
    a5: 18.72659,
    a6: 14.60674,
    b3: 122.25,
    b4: 38.95131,
    b5: 25.8427,
    b6: 18.72659,
  },
  {
    maesu: 2680,
    b2: 163,
    a2: 146.7,
    a3: 39.1791,
    a4: 26.1194,
    a5: 18.65672,
    a6: 15.29851,
    b3: 122.25,
    b4: 39.1791,
    b5: 26.1194,
    b6: 18.65672,
  },
  {
    maesu: 2690,
    b2: 163,
    a2: 146.7,
    a3: 39.03346,
    a4: 26.0223,
    a5: 18.58736,
    a6: 15.24164,
    b3: 122.25,
    b4: 39.03346,
    b5: 26.0223,
    b6: 18.58736,
  },
  {
    maesu: 2700,
    b2: 163,
    a2: 146.7,
    a3: 38.88889,
    a4: 25.92593,
    a5: 18.51852,
    a6: 15.18519,
    b3: 122.25,
    b4: 38.88889,
    b5: 25.92593,
    b6: 18.51852,
  },
  {
    maesu: 2710,
    b2: 163,
    a2: 146.7,
    a3: 39.11439,
    a4: 25.83026,
    a5: 18.45018,
    a6: 15.12915,
    b3: 122.25,
    b4: 39.11439,
    b5: 25.83026,
    b6: 18.45018,
  },
  {
    maesu: 2720,
    b2: 163,
    a2: 146.7,
    a3: 38.97059,
    a4: 26.10294,
    a5: 18.38235,
    a6: 15.07353,
    b3: 122.25,
    b4: 38.97059,
    b5: 26.10294,
    b6: 18.38235,
  },
  {
    maesu: 2730,
    b2: 163,
    a2: 146.7,
    a3: 38.82784,
    a4: 26.00733,
    a5: 18.31502,
    a6: 15.01832,
    b3: 122.25,
    b4: 38.82784,
    b5: 26.00733,
    b6: 18.31502,
  },
  {
    maesu: 2740,
    b2: 163,
    a2: 146.7,
    a3: 39.05109,
    a4: 25.91241,
    a5: 18.24818,
    a6: 14.9635,
    b3: 122.25,
    b4: 39.05109,
    b5: 25.91241,
    b6: 18.24818,
  },
  {
    maesu: 2750,
    b2: 163,
    a2: 146.7,
    a3: 38.90909,
    a4: 26.18182,
    a5: 18.18182,
    a6: 14.90909,
    b3: 122.25,
    b4: 38.90909,
    b5: 26.18182,
    b6: 18.18182,
  },
  {
    maesu: 2760,
    b2: 163,
    a2: 146.7,
    a3: 39.13043,
    a4: 26.08696,
    a5: 18.11594,
    a6: 14.85507,
    b3: 122.25,
    b4: 39.13043,
    b5: 26.08696,
    b6: 18.11594,
  },
  {
    maesu: 2770,
    b2: 163,
    a2: 146.7,
    a3: 38.98917,
    a4: 25.99278,
    a5: 18.05054,
    a6: 14.80144,
    b3: 122.25,
    b4: 38.98917,
    b5: 25.99278,
    b6: 18.05054,
  },
  {
    maesu: 2780,
    b2: 163,
    a2: 146.7,
    a3: 38.84892,
    a4: 25.89928,
    a5: 18.70504,
    a6: 14.7482,
    b3: 122.25,
    b4: 38.84892,
    b5: 25.89928,
    b6: 18.70504,
  },
  {
    maesu: 2790,
    b2: 163,
    a2: 146.7,
    a3: 39.0681,
    a4: 26.16487,
    a5: 18.63799,
    a6: 14.69534,
    b3: 122.25,
    b4: 39.0681,
    b5: 26.16487,
    b6: 18.63799,
  },
  {
    maesu: 2800,
    b2: 163,
    a2: 146.7,
    a3: 38.92857,
    a4: 26.07143,
    a5: 18.57143,
    a6: 14.64286,
    b3: 122.25,
    b4: 38.92857,
    b5: 26.07143,
    b6: 18.57143,
  },
  {
    maesu: 2810,
    b2: 163,
    a2: 146.7,
    a3: 39.14591,
    a4: 25.97865,
    a5: 18.50534,
    a6: 14.59075,
    b3: 122.25,
    b4: 39.14591,
    b5: 25.97865,
    b6: 18.50534,
  },
  {
    maesu: 2820,
    b2: 163,
    a2: 146.7,
    a3: 39.00709,
    a4: 25.88652,
    a5: 18.43972,
    a6: 14.89362,
    b3: 122.25,
    b4: 39.00709,
    b5: 25.88652,
    b6: 18.43972,
  },
  {
    maesu: 2830,
    b2: 163,
    a2: 146.7,
    a3: 38.86926,
    a4: 26.14841,
    a5: 18.37456,
    a6: 14.84099,
    b3: 122.25,
    b4: 38.86926,
    b5: 26.14841,
    b6: 18.37456,
  },
  {
    maesu: 2840,
    b2: 163,
    a2: 146.7,
    a3: 39.08451,
    a4: 26.05634,
    a5: 18.30986,
    a6: 14.78873,
    b3: 122.25,
    b4: 39.08451,
    b5: 26.05634,
    b6: 18.30986,
  },
  {
    maesu: 2850,
    b2: 163,
    a2: 146.7,
    a3: 38.94737,
    a4: 25.96491,
    a5: 18.24561,
    a6: 14.73684,
    b3: 122.25,
    b4: 38.94737,
    b5: 25.96491,
    b6: 18.24561,
  },
  {
    maesu: 2860,
    b2: 163,
    a2: 146.7,
    a3: 39.16084,
    a4: 25.87413,
    a5: 18.18182,
    a6: 14.68531,
    b3: 122.25,
    b4: 39.16084,
    b5: 25.87413,
    b6: 18.18182,
  },
  {
    maesu: 2870,
    b2: 163,
    a2: 146.7,
    a3: 39.02439,
    a4: 26.1324,
    a5: 18.4669,
    a6: 14.63415,
    b3: 122.25,
    b4: 39.02439,
    b5: 26.1324,
    b6: 18.4669,
  },
  {
    maesu: 2880,
    b2: 163,
    a2: 146.7,
    a3: 38.88889,
    a4: 26.04167,
    a5: 18.40278,
    a6: 14.58333,
    b3: 122.25,
    b4: 38.88889,
    b5: 26.04167,
    b6: 18.40278,
  },
  {
    maesu: 2890,
    b2: 163,
    a2: 146.7,
    a3: 39.10035,
    a4: 25.95156,
    a5: 18.3391,
    a6: 14.53287,
    b3: 122.25,
    b4: 39.10035,
    b5: 25.95156,
    b6: 18.3391,
  },
  {
    maesu: 2900,
    b2: 163,
    a2: 146.7,
    a3: 38.96552,
    a4: 25.86207,
    a5: 18.27586,
    a6: 14.48276,
    b3: 122.25,
    b4: 38.96552,
    b5: 25.86207,
    b6: 18.27586,
  },
  {
    maesu: 2910,
    b2: 163,
    a2: 146.7,
    a3: 38.83162,
    a4: 26.11684,
    a5: 18.21306,
    a6: 14.43299,
    b3: 122.25,
    b4: 38.83162,
    b5: 26.11684,
    b6: 18.21306,
  },
  {
    maesu: 2920,
    b2: 163,
    a2: 146.7,
    a3: 39.0411,
    a4: 26.0274,
    a5: 18.15068,
    a6: 14.38356,
    b3: 122.25,
    b4: 39.0411,
    b5: 26.0274,
    b6: 18.15068,
  },
  {
    maesu: 2930,
    b2: 163,
    a2: 146.7,
    a3: 38.90785,
    a4: 25.93857,
    a5: 18.08874,
    a6: 14.33447,
    b3: 122.25,
    b4: 38.90785,
    b5: 25.93857,
    b6: 18.08874,
  },
  {
    maesu: 2940,
    b2: 163,
    a2: 146.7,
    a3: 39.11565,
    a4: 25.85034,
    a5: 18.02721,
    a6: 14.62585,
    b3: 122.25,
    b4: 39.11565,
    b5: 25.85034,
    b6: 18.02721,
  },
  {
    maesu: 2950,
    b2: 163,
    a2: 146.7,
    a3: 38.98305,
    a4: 26.10169,
    a5: 17.9661,
    a6: 14.57627,
    b3: 122.25,
    b4: 38.98305,
    b5: 26.10169,
    b6: 17.9661,
  },
  {
    maesu: 2960,
    b2: 163,
    a2: 146.7,
    a3: 38.85135,
    a4: 26.01351,
    a5: 18.24324,
    a6: 14.52703,
    b3: 122.25,
    b4: 38.85135,
    b5: 26.01351,
    b6: 18.24324,
  },
  {
    maesu: 2970,
    b2: 163,
    a2: 146.7,
    a3: 39.05724,
    a4: 25.92593,
    a5: 18.18182,
    a6: 14.47811,
    b3: 122.25,
    b4: 39.05724,
    b5: 25.92593,
    b6: 18.18182,
  },
  {
    maesu: 2980,
    b2: 163,
    a2: 146.7,
    a3: 38.92617,
    a4: 25.83893,
    a5: 18.12081,
    a6: 14.42953,
    b3: 122.25,
    b4: 38.92617,
    b5: 25.83893,
    b6: 18.12081,
  },
  {
    maesu: 2990,
    b2: 163,
    a2: 146.7,
    a3: 39.13043,
    a4: 26.08696,
    a5: 18.0602,
    a6: 14.38127,
    b3: 122.25,
    b4: 39.13043,
    b5: 26.08696,
    b6: 18.0602,
  },
  {
    maesu: 3000,
    b2: 163,
    a2: 146.7,
    a3: 39,
    a4: 26,
    a5: 18,
    a6: 14.33333,
    b3: 122.25,
    b4: 39,
    b5: 26,
    b6: 18,
  },
  {
    maesu: 3010,
    b2: 163,
    a2: 146.7,
    a3: 38.87043,
    a4: 25.91362,
    a5: 17.9402,
    a6: 14.28571,
    b3: 122.25,
    b4: 38.87043,
    b5: 25.91362,
    b6: 17.9402,
  },
  {
    maesu: 3020,
    b2: 163,
    a2: 146.7,
    a3: 39.07285,
    a4: 26.15894,
    a5: 17.88079,
    a6: 14.23841,
    b3: 122.25,
    b4: 39.07285,
    b5: 26.15894,
    b6: 17.88079,
  },
  {
    maesu: 3030,
    b2: 163,
    a2: 146.7,
    a3: 38.94389,
    a4: 26.07261,
    a5: 17.82178,
    a6: 14.19142,
    b3: 122.25,
    b4: 38.94389,
    b5: 26.07261,
    b6: 17.82178,
  },
  {
    maesu: 3040,
    b2: 163,
    a2: 146.7,
    a3: 39.14474,
    a4: 25.98684,
    a5: 17.76316,
    a6: 14.14474,
    b3: 122.25,
    b4: 39.14474,
    b5: 25.98684,
    b6: 17.76316,
  },
  {
    maesu: 3050,
    b2: 163,
    a2: 146.7,
    a3: 39.01639,
    a4: 25.90164,
    a5: 17.70492,
    a6: 14.09836,
    b3: 122.25,
    b4: 39.01639,
    b5: 25.90164,
    b6: 17.70492,
  },
  {
    maesu: 3060,
    b2: 163,
    a2: 146.7,
    a3: 38.88889,
    a4: 26.14379,
    a5: 17.64706,
    a6: 14.05229,
    b3: 122.25,
    b4: 38.88889,
    b5: 26.14379,
    b6: 17.64706,
  },
  {
    maesu: 3070,
    b2: 163,
    a2: 146.7,
    a3: 39.08795,
    a4: 26.05863,
    a5: 17.58958,
    a6: 14.00651,
    b3: 122.25,
    b4: 39.08795,
    b5: 26.05863,
    b6: 17.58958,
  },
  {
    maesu: 3080,
    b2: 163,
    a2: 146.7,
    a3: 38.96104,
    a4: 25.97403,
    a5: 18.18182,
    a6: 13.96104,
    b3: 122.25,
    b4: 38.96104,
    b5: 25.97403,
    b6: 18.18182,
  },
  {
    maesu: 3090,
    b2: 163,
    a2: 146.7,
    a3: 39.15858,
    a4: 25.88997,
    a5: 18.12298,
    a6: 14.56311,
    b3: 122.25,
    b4: 39.15858,
    b5: 25.88997,
    b6: 18.12298,
  },
  {
    maesu: 3100,
    b2: 163,
    a2: 146.7,
    a3: 39.03226,
    a4: 26.12903,
    a5: 18.06452,
    a6: 14.51613,
    b3: 122.25,
    b4: 39.03226,
    b5: 26.12903,
    b6: 18.06452,
  },
  {
    maesu: 3110,
    b2: 163,
    a2: 146.7,
    a3: 38.90675,
    a4: 26.04502,
    a5: 18.00643,
    a6: 14.46945,
    b3: 122.25,
    b4: 38.90675,
    b5: 26.04502,
    b6: 18.00643,
  },
  {
    maesu: 3120,
    b2: 163,
    a2: 146.7,
    a3: 39.10256,
    a4: 25.96154,
    a5: 17.94872,
    a6: 14.42308,
    b3: 122.25,
    b4: 39.10256,
    b5: 25.96154,
    b6: 17.94872,
  },
  {
    maesu: 3130,
    b2: 163,
    a2: 146.7,
    a3: 38.97764,
    a4: 25.87859,
    a5: 17.89137,
    a6: 14.377,
    b3: 122.25,
    b4: 38.97764,
    b5: 25.87859,
    b6: 17.89137,
  },
  {
    maesu: 3140,
    b2: 163,
    a2: 146.7,
    a3: 38.8535,
    a4: 26.11465,
    a5: 17.83439,
    a6: 14.33121,
    b3: 122.25,
    b4: 38.8535,
    b5: 26.11465,
    b6: 17.83439,
  },
  {
    maesu: 3150,
    b2: 163,
    a2: 146.7,
    a3: 39.04762,
    a4: 26.03175,
    a5: 17.77778,
    a6: 14.28571,
    b3: 122.25,
    b4: 39.04762,
    b5: 26.03175,
    b6: 17.77778,
  },
  {
    maesu: 3160,
    b2: 163,
    a2: 146.7,
    a3: 38.92405,
    a4: 25.94937,
    a5: 17.72152,
    a6: 14.24051,
    b3: 122.25,
    b4: 38.92405,
    b5: 25.94937,
    b6: 17.72152,
  },
  {
    maesu: 3170,
    b2: 163,
    a2: 146.7,
    a3: 39.11672,
    a4: 25.86751,
    a5: 17.66562,
    a6: 14.19558,
    b3: 122.25,
    b4: 39.11672,
    b5: 25.86751,
    b6: 17.66562,
  },
  {
    maesu: 3180,
    b2: 163,
    a2: 146.7,
    a3: 38.99371,
    a4: 26.10063,
    a5: 17.61006,
    a6: 14.15094,
    b3: 122.25,
    b4: 38.99371,
    b5: 26.10063,
    b6: 17.61006,
  },
  {
    maesu: 3190,
    b2: 163,
    a2: 146.7,
    a3: 38.87147,
    a4: 26.01881,
    a5: 17.55486,
    a6: 14.10658,
    b3: 122.25,
    b4: 38.87147,
    b5: 26.01881,
    b6: 17.55486,
  },
  {
    maesu: 3200,
    b2: 163,
    a2: 146.7,
    a3: 39.0625,
    a4: 25.9375,
    a5: 17.5,
    a6: 14.0625,
    b3: 122.25,
    b4: 39.0625,
    b5: 25.9375,
    b6: 17.5,
  },
  {
    maesu: 3210,
    b2: 163,
    a2: 146.7,
    a3: 38.94081,
    a4: 25.8567,
    a5: 17.75701,
    a6: 14.01869,
    b3: 122.25,
    b4: 38.94081,
    b5: 25.8567,
    b6: 17.75701,
  },
  {
    maesu: 3220,
    b2: 163,
    a2: 146.7,
    a3: 39.13043,
    a4: 26.08696,
    a5: 17.70186,
    a6: 13.97516,
    b3: 122.25,
    b4: 39.13043,
    b5: 26.08696,
    b6: 17.70186,
  },
  {
    maesu: 3230,
    b2: 163,
    a2: 146.7,
    a3: 39.00929,
    a4: 26.00619,
    a5: 17.64706,
    a6: 13.93189,
    b3: 122.25,
    b4: 39.00929,
    b5: 26.00619,
    b6: 17.64706,
  },
  {
    maesu: 3240,
    b2: 163,
    a2: 146.7,
    a3: 38.88889,
    a4: 25.92593,
    a5: 17.59259,
    a6: 13.88889,
    b3: 122.25,
    b4: 38.88889,
    b5: 25.92593,
    b6: 17.59259,
  },
  {
    maesu: 3250,
    b2: 163,
    a2: 146.7,
    a3: 39.07692,
    a4: 26.15385,
    a5: 17.53846,
    a6: 14.15385,
    b3: 122.25,
    b4: 39.07692,
    b5: 26.15385,
    b6: 17.53846,
  },
  {
    maesu: 3260,
    b2: 163,
    a2: 146.7,
    a3: 38.95706,
    a4: 26.07362,
    a5: 17.48466,
    a6: 14.11043,
    b3: 122.25,
    b4: 38.95706,
    b5: 26.07362,
    b6: 17.48466,
  },
  {
    maesu: 3270,
    b2: 163,
    a2: 146.7,
    a3: 39.14373,
    a4: 25.99388,
    a5: 17.43119,
    a6: 14.06728,
    b3: 122.25,
    b4: 39.14373,
    b5: 25.99388,
    b6: 17.43119,
  },
  {
    maesu: 3280,
    b2: 163,
    a2: 146.7,
    a3: 39.02439,
    a4: 25.91463,
    a5: 17.37805,
    a6: 14.02439,
    b3: 122.25,
    b4: 39.02439,
    b5: 25.91463,
    b6: 17.37805,
  },
  {
    maesu: 3290,
    b2: 163,
    a2: 146.7,
    a3: 38.90578,
    a4: 26.13982,
    a5: 17.32523,
    a6: 13.98176,
    b3: 122.25,
    b4: 38.90578,
    b5: 26.13982,
    b6: 17.32523,
  },
  {
    maesu: 3300,
    b2: 163,
    a2: 146.7,
    a3: 39.09091,
    a4: 26.06061,
    a5: 17.87879,
    a6: 13.93939,
    b3: 122.25,
    b4: 39.09091,
    b5: 26.06061,
    b6: 17.87879,
  },
  {
    maesu: 3310,
    b2: 163,
    a2: 146.7,
    a3: 38.97281,
    a4: 25.98187,
    a5: 17.82477,
    a6: 13.89728,
    b3: 122.25,
    b4: 38.97281,
    b5: 25.98187,
    b6: 17.82477,
  },
  {
    maesu: 3320,
    b2: 163,
    a2: 146.7,
    a3: 38.85542,
    a4: 25.90361,
    a5: 17.77108,
    a6: 13.85542,
    b3: 122.25,
    b4: 38.85542,
    b5: 25.90361,
    b6: 17.77108,
  },
  {
    maesu: 3330,
    b2: 163,
    a2: 146.7,
    a3: 39.03904,
    a4: 26.12613,
    a5: 17.71772,
    a6: 13.81381,
    b3: 122.25,
    b4: 39.03904,
    b5: 26.12613,
    b6: 17.71772,
  },
  {
    maesu: 3340,
    b2: 163,
    a2: 146.7,
    a3: 38.92216,
    a4: 26.0479,
    a5: 17.66467,
    a6: 13.77246,
    b3: 122.25,
    b4: 38.92216,
    b5: 26.0479,
    b6: 17.66467,
  },
  {
    maesu: 3350,
    b2: 163,
    a2: 146.7,
    a3: 39.10448,
    a4: 25.97015,
    a5: 17.61194,
    a6: 13.73134,
    b3: 122.25,
    b4: 39.10448,
    b5: 25.97015,
    b6: 17.61194,
  },
  {
    maesu: 3360,
    b2: 163,
    a2: 146.7,
    a3: 38.9881,
    a4: 25.89286,
    a5: 17.55952,
    a6: 13.69048,
    b3: 122.25,
    b4: 38.9881,
    b5: 25.89286,
    b6: 17.55952,
  },
  {
    maesu: 3370,
    b2: 163,
    a2: 146.7,
    a3: 38.8724,
    a4: 26.11276,
    a5: 17.50742,
    a6: 14.24332,
    b3: 122.25,
    b4: 38.8724,
    b5: 26.11276,
    b6: 17.50742,
  },
  {
    maesu: 3380,
    b2: 163,
    a2: 146.7,
    a3: 39.05325,
    a4: 26.0355,
    a5: 17.45562,
    a6: 14.20118,
    b3: 122.25,
    b4: 39.05325,
    b5: 26.0355,
    b6: 17.45562,
  },
  {
    maesu: 3390,
    b2: 163,
    a2: 146.7,
    a3: 38.93805,
    a4: 25.9587,
    a5: 17.40413,
    a6: 13.56932,
    b3: 122.25,
    b4: 38.93805,
    b5: 25.9587,
    b6: 17.40413,
  },
  {
    maesu: 3400,
    b2: 163,
    a2: 146.7,
    a3: 39.11765,
    a4: 25.88235,
    a5: 17.64706,
    a6: 13.52941,
    b3: 122.25,
    b4: 39.11765,
    b5: 25.88235,
    b6: 17.64706,
  },
  {
    maesu: 3410,
    b2: 163,
    a2: 146.7,
    a3: 39.00293,
    a4: 26.09971,
    a5: 17.59531,
    a6: 14.07625,
    b3: 122.25,
    b4: 39.00293,
    b5: 26.09971,
    b6: 17.59531,
  },
  {
    maesu: 3420,
    b2: 163,
    a2: 146.7,
    a3: 38.88889,
    a4: 26.02339,
    a5: 17.54386,
    a6: 14.03509,
    b3: 122.25,
    b4: 38.88889,
    b5: 26.02339,
    b6: 17.54386,
  },
  {
    maesu: 3430,
    b2: 163,
    a2: 146.7,
    a3: 39.06706,
    a4: 25.94752,
    a5: 17.78426,
    a6: 13.99417,
    b3: 122.25,
    b4: 39.06706,
    b5: 25.94752,
    b6: 17.78426,
  },
  {
    maesu: 3440,
    b2: 163,
    a2: 146.7,
    a3: 38.95349,
    a4: 25.87209,
    a5: 17.73256,
    a6: 13.95349,
    b3: 122.25,
    b4: 38.95349,
    b5: 25.87209,
    b6: 17.73256,
  },
  {
    maesu: 3450,
    b2: 163,
    a2: 146.7,
    a3: 39.13043,
    a4: 26.08696,
    a5: 17.68116,
    a6: 13.91304,
    b3: 122.25,
    b4: 39.13043,
    b5: 26.08696,
    b6: 17.68116,
  },
  {
    maesu: 3460,
    b2: 163,
    a2: 146.7,
    a3: 39.01734,
    a4: 26.01156,
    a5: 17.63006,
    a6: 13.87283,
    b3: 122.25,
    b4: 39.01734,
    b5: 26.01156,
    b6: 17.63006,
  },
  {
    maesu: 3470,
    b2: 163,
    a2: 146.7,
    a3: 38.9049,
    a4: 25.9366,
    a5: 17.57925,
    a6: 13.83285,
    b3: 122.25,
    b4: 38.9049,
    b5: 25.9366,
    b6: 17.57925,
  },
  {
    maesu: 3480,
    b2: 163,
    a2: 146.7,
    a3: 39.08046,
    a4: 25.86207,
    a5: 17.52874,
    a6: 13.7931,
    b3: 122.25,
    b4: 39.08046,
    b5: 25.86207,
    b6: 17.52874,
  },
  {
    maesu: 3490,
    b2: 163,
    a2: 146.7,
    a3: 38.96848,
    a4: 26.0745,
    a5: 17.47851,
    a6: 13.75358,
    b3: 122.25,
    b4: 38.96848,
    b5: 26.0745,
    b6: 17.47851,
  },
  {
    maesu: 3500,
    b2: 163,
    a2: 146.7,
    a3: 39.14286,
    a4: 26,
    a5: 17.42857,
    a6: 13.71429,
    b3: 122.25,
    b4: 39.14286,
    b5: 26,
    b6: 17.42857,
  },
  {
    maesu: 3510,
    b2: 163,
    a2: 146.7,
    a3: 39.03134,
    a4: 25.92593,
    a5: 17.37892,
    a6: 13.67521,
    b3: 122.25,
    b4: 39.03134,
    b5: 25.92593,
    b6: 17.37892,
  },
  {
    maesu: 3520,
    b2: 163,
    a2: 146.7,
    a3: 38.92045,
    a4: 26.13636,
    a5: 17.32955,
    a6: 13.63636,
    b3: 122.25,
    b4: 38.92045,
    b5: 26.13636,
    b6: 17.32955,
  },
  {
    maesu: 3530,
    b2: 163,
    a2: 146.7,
    a3: 39.09348,
    a4: 26.06232,
    a5: 17.56374,
    a6: 13.88102,
    b3: 122.25,
    b4: 39.09348,
    b5: 26.06232,
    b6: 17.56374,
  },
  {
    maesu: 3540,
    b2: 163,
    a2: 146.7,
    a3: 38.98305,
    a4: 25.9887,
    a5: 17.51412,
    a6: 13.84181,
    b3: 122.25,
    b4: 38.98305,
    b5: 25.9887,
    b6: 17.51412,
  },
  {
    maesu: 3550,
    b2: 163,
    a2: 146.7,
    a3: 38.87324,
    a4: 25.91549,
    a5: 17.46479,
    a6: 13.80282,
    b3: 122.25,
    b4: 38.87324,
    b5: 25.91549,
    b6: 17.46479,
  },
  {
    maesu: 3560,
    b2: 163,
    a2: 146.7,
    a3: 39.04494,
    a4: 26.1236,
    a5: 17.41573,
    a6: 13.76404,
    b3: 122.25,
    b4: 39.04494,
    b5: 26.1236,
    b6: 17.41573,
  },
  {
    maesu: 3570,
    b2: 163,
    a2: 146.7,
    a3: 38.93557,
    a4: 26.05042,
    a5: 17.36695,
    a6: 13.72549,
    b3: 122.25,
    b4: 38.93557,
    b5: 26.05042,
    b6: 17.36695,
  },
  {
    maesu: 3580,
    b2: 163,
    a2: 146.7,
    a3: 39.10615,
    a4: 25.97765,
    a5: 17.31844,
    a6: 13.68715,
    b3: 122.25,
    b4: 39.10615,
    b5: 25.97765,
    b6: 17.31844,
  },
  {
    maesu: 3590,
    b2: 163,
    a2: 146.7,
    a3: 38.99721,
    a4: 25.90529,
    a5: 17.27019,
    a6: 13.64903,
    b3: 122.25,
    b4: 38.99721,
    b5: 25.90529,
    b6: 17.27019,
  },
  {
    maesu: 3600,
    b2: 163,
    a2: 146.7,
    a3: 38.88889,
    a4: 26.11111,
    a5: 17.22222,
    a6: 13.61111,
    b3: 122.25,
    b4: 38.88889,
    b5: 26.11111,
    b6: 17.22222,
  },
  {
    maesu: 3610,
    b2: 163,
    a2: 146.7,
    a3: 39.05817,
    a4: 26.03878,
    a5: 17.17452,
    a6: 13.57341,
    b3: 122.25,
    b4: 39.05817,
    b5: 26.03878,
    b6: 17.17452,
  },
  {
    maesu: 3620,
    b2: 163,
    a2: 146.7,
    a3: 38.95028,
    a4: 25.96685,
    a5: 17.67956,
    a6: 13.53591,
    b3: 122.25,
    b4: 38.95028,
    b5: 25.96685,
    b6: 17.67956,
  },
  {
    maesu: 3630,
    b2: 163,
    a2: 146.7,
    a3: 39.11846,
    a4: 25.89532,
    a5: 17.63085,
    a6: 13.49862,
    b3: 122.25,
    b4: 39.11846,
    b5: 25.89532,
    b6: 17.63085,
  },
  {
    maesu: 3640,
    b2: 163,
    a2: 146.7,
    a3: 39.01099,
    a4: 26.0989,
    a5: 17.58242,
    a6: 13.46154,
    b3: 122.25,
    b4: 39.01099,
    b5: 26.0989,
    b6: 17.58242,
  },
  {
    maesu: 3650,
    b2: 163,
    a2: 146.7,
    a3: 38.90411,
    a4: 26.0274,
    a5: 17.53425,
    a6: 13.42466,
    b3: 122.25,
    b4: 38.90411,
    b5: 26.0274,
    b6: 17.53425,
  },
  {
    maesu: 3660,
    b2: 163,
    a2: 146.7,
    a3: 39.07104,
    a4: 25.95628,
    a5: 17.48634,
    a6: 13.93443,
    b3: 122.25,
    b4: 39.07104,
    b5: 25.95628,
    b6: 17.48634,
  },
  {
    maesu: 3670,
    b2: 163,
    a2: 146.7,
    a3: 38.96458,
    a4: 25.88556,
    a5: 17.43869,
    a6: 13.89646,
    b3: 122.25,
    b4: 38.96458,
    b5: 25.88556,
    b6: 17.43869,
  },
  {
    maesu: 3680,
    b2: 163,
    a2: 146.7,
    a3: 39.13043,
    a4: 26.08696,
    a5: 17.3913,
    a6: 13.8587,
    b3: 122.25,
    b4: 39.13043,
    b5: 26.08696,
    b6: 17.3913,
  },
  {
    maesu: 3690,
    b2: 163,
    a2: 146.7,
    a3: 39.02439,
    a4: 26.01626,
    a5: 17.34417,
    a6: 13.82114,
    b3: 122.25,
    b4: 39.02439,
    b5: 26.01626,
    b6: 17.34417,
  },
  {
    maesu: 3700,
    b2: 163,
    a2: 146.7,
    a3: 38.91892,
    a4: 25.94595,
    a5: 17.2973,
    a6: 13.78378,
    b3: 122.25,
    b4: 38.91892,
    b5: 25.94595,
    b6: 17.2973,
  },
  {
    maesu: 3710,
    b2: 163,
    a2: 146.7,
    a3: 39.08356,
    a4: 25.87601,
    a5: 17.25067,
    a6: 13.74663,
    b3: 122.25,
    b4: 39.08356,
    b5: 25.87601,
    b6: 17.25067,
  },
  {
    maesu: 3720,
    b2: 163,
    a2: 146.7,
    a3: 38.97849,
    a4: 26.07527,
    a5: 17.2043,
    a6: 13.70968,
    b3: 122.25,
    b4: 38.97849,
    b5: 26.07527,
    b6: 17.2043,
  },
  {
    maesu: 3730,
    b2: 163,
    a2: 146.7,
    a3: 38.87399,
    a4: 26.00536,
    a5: 17.15818,
    a6: 13.67292,
    b3: 122.25,
    b4: 38.87399,
    b5: 26.00536,
    b6: 17.15818,
  },
  {
    maesu: 3740,
    b2: 163,
    a2: 146.7,
    a3: 39.03743,
    a4: 25.93583,
    a5: 17.1123,
    a6: 13.63636,
    b3: 122.25,
    b4: 39.03743,
    b5: 25.93583,
    b6: 17.1123,
  },
  {
    maesu: 3750,
    b2: 163,
    a2: 146.7,
    a3: 38.93333,
    a4: 26.13333,
    a5: 17.06667,
    a6: 13.6,
    b3: 122.25,
    b4: 38.93333,
    b5: 26.13333,
    b6: 17.06667,
  },
  {
    maesu: 3760,
    b2: 163,
    a2: 146.7,
    a3: 39.09574,
    a4: 26.06383,
    a5: 17.28723,
    a6: 13.56383,
    b3: 122.25,
    b4: 39.09574,
    b5: 26.06383,
    b6: 17.28723,
  },
  {
    maesu: 3770,
    b2: 163,
    a2: 146.7,
    a3: 38.99204,
    a4: 25.99469,
    a5: 17.24138,
    a6: 13.52785,
    b3: 122.25,
    b4: 38.99204,
    b5: 25.99469,
    b6: 17.24138,
  },
  {
    maesu: 3780,
    b2: 163,
    a2: 146.7,
    a3: 38.88889,
    a4: 25.92593,
    a5: 17.19577,
    a6: 13.49206,
    b3: 122.25,
    b4: 38.88889,
    b5: 25.92593,
    b6: 17.19577,
  },
  {
    maesu: 3790,
    b2: 163,
    a2: 146.7,
    a3: 39.05013,
    a4: 26.12137,
    a5: 17.1504,
    a6: 13.45646,
    b3: 122.25,
    b4: 39.05013,
    b5: 26.12137,
    b6: 17.1504,
  },
  {
    maesu: 3800,
    b2: 163,
    a2: 146.7,
    a3: 38.94737,
    a4: 26.05263,
    a5: 17.10526,
    a6: 13.42105,
    b3: 122.25,
    b4: 38.94737,
    b5: 26.05263,
    b6: 17.10526,
  },
  {
    maesu: 3810,
    b2: 163,
    a2: 146.7,
    a3: 39.10761,
    a4: 25.98425,
    a5: 17.06037,
    a6: 13.38583,
    b3: 122.25,
    b4: 39.10761,
    b5: 25.98425,
    b6: 17.06037,
  },
  {
    maesu: 3820,
    b2: 163,
    a2: 146.7,
    a3: 39.00524,
    a4: 25.91623,
    a5: 17.01571,
    a6: 13.61257,
    b3: 122.25,
    b4: 39.00524,
    b5: 25.91623,
    b6: 17.01571,
  },
  {
    maesu: 3830,
    b2: 163,
    a2: 146.7,
    a3: 38.90339,
    a4: 26.10966,
    a5: 16.97128,
    a6: 13.57702,
    b3: 122.25,
    b4: 38.90339,
    b5: 26.10966,
    b6: 16.97128,
  },
  {
    maesu: 3840,
    b2: 163,
    a2: 146.7,
    a3: 39.0625,
    a4: 26.04167,
    a5: 16.92708,
    a6: 13.54167,
    b3: 122.25,
    b4: 39.0625,
    b5: 26.04167,
    b6: 16.92708,
  },
  {
    maesu: 3850,
    b2: 163,
    a2: 146.7,
    a3: 38.96104,
    a4: 25.97403,
    a5: 16.88312,
    a6: 13.24675,
    b3: 122.25,
    b4: 38.96104,
    b5: 25.97403,
    b6: 16.88312,
  },
  {
    maesu: 3860,
    b2: 163,
    a2: 146.7,
    a3: 39.11917,
    a4: 25.90674,
    a5: 16.83938,
    a6: 13.21244,
    b3: 122.25,
    b4: 39.11917,
    b5: 25.90674,
    b6: 16.83938,
  },
  {
    maesu: 3870,
    b2: 163,
    a2: 146.7,
    a3: 39.01809,
    a4: 26.09819,
    a5: 16.79587,
    a6: 13.43669,
    b3: 122.25,
    b4: 39.01809,
    b5: 26.09819,
    b6: 16.79587,
  },
  {
    maesu: 3880,
    b2: 163,
    a2: 146.7,
    a3: 38.91753,
    a4: 26.03093,
    a5: 16.75258,
    a6: 13.40206,
    b3: 122.25,
    b4: 38.91753,
    b5: 26.03093,
    b6: 16.75258,
  },
  {
    maesu: 3890,
    b2: 163,
    a2: 146.7,
    a3: 39.07455,
    a4: 25.96401,
    a5: 16.70951,
    a6: 13.36761,
    b3: 122.25,
    b4: 39.07455,
    b5: 25.96401,
    b6: 16.70951,
  },
  {
    maesu: 3900,
    b2: 163,
    a2: 146.7,
    a3: 38.97436,
    a4: 25.89744,
    a5: 16.92308,
    a6: 13.33333,
    b3: 122.25,
    b4: 38.97436,
    b5: 25.89744,
    b6: 16.92308,
  },
  {
    maesu: 3910,
    b2: 163,
    a2: 146.7,
    a3: 38.87468,
    a4: 26.08696,
    a5: 16.8798,
    a6: 13.29923,
    b3: 122.25,
    b4: 38.87468,
    b5: 26.08696,
    b6: 16.8798,
  },
  {
    maesu: 3920,
    b2: 163,
    a2: 146.7,
    a3: 39.03061,
    a4: 26.02041,
    a5: 16.83673,
    a6: 13.26531,
    b3: 122.25,
    b4: 39.03061,
    b5: 26.02041,
    b6: 16.83673,
  },
  {
    maesu: 3930,
    b2: 163,
    a2: 146.7,
    a3: 38.9313,
    a4: 25.9542,
    a5: 16.79389,
    a6: 13.23155,
    b3: 122.25,
    b4: 38.9313,
    b5: 25.9542,
    b6: 16.79389,
  },
  {
    maesu: 3940,
    b2: 163,
    a2: 146.7,
    a3: 39.08629,
    a4: 25.88832,
    a5: 16.75127,
    a6: 13.19797,
    b3: 122.25,
    b4: 39.08629,
    b5: 25.88832,
    b6: 16.75127,
  },
  {
    maesu: 3950,
    b2: 163,
    a2: 146.7,
    a3: 38.98734,
    a4: 26.07595,
    a5: 16.70886,
    a6: 13.16456,
    b3: 122.25,
    b4: 38.98734,
    b5: 26.07595,
    b6: 16.70886,
  },
  {
    maesu: 3960,
    b2: 163,
    a2: 146.7,
    a3: 38.88889,
    a4: 26.0101,
    a5: 16.66667,
    a6: 13.13131,
    b3: 122.25,
    b4: 38.88889,
    b5: 26.0101,
    b6: 16.66667,
  },
  {
    maesu: 3970,
    b2: 163,
    a2: 146.7,
    a3: 39.04282,
    a4: 25.94458,
    a5: 16.62469,
    a6: 13.09824,
    b3: 122.25,
    b4: 39.04282,
    b5: 25.94458,
    b6: 16.62469,
  },
  {
    maesu: 3980,
    b2: 163,
    a2: 146.7,
    a3: 38.94472,
    a4: 25.8794,
    a5: 16.58291,
    a6: 13.06533,
    b3: 122.25,
    b4: 38.94472,
    b5: 25.8794,
    b6: 16.58291,
  },
  {
    maesu: 3990,
    b2: 163,
    a2: 146.7,
    a3: 39.09774,
    a4: 26.06516,
    a5: 16.54135,
    a6: 13.03258,
    b3: 122.25,
    b4: 39.09774,
    b5: 26.06516,
    b6: 16.54135,
  },
  {
    maesu: 4000,
    b2: 163,
    a2: 146.7,
    a3: 39,
    a4: 26,
    a5: 16.75,
    a6: 13,
    b3: 122.25,
    b4: 39,
    b5: 26,
    b6: 16.75,
  },
  {
    maesu: 4010,
    b2: 163,
    a2: 146.7,
    a3: 38.90274,
    a4: 25.93516,
    a5: 16.70823,
    a6: 12.96758,
    b3: 122.25,
    b4: 38.90274,
    b5: 25.93516,
    b6: 16.70823,
  },
  {
    maesu: 4020,
    b2: 163,
    a2: 146.7,
    a3: 39.05473,
    a4: 26.1194,
    a5: 16.66667,
    a6: 12.93532,
    b3: 122.25,
    b4: 39.05473,
    b5: 26.1194,
    b6: 16.66667,
  },
  {
    maesu: 4030,
    b2: 163,
    a2: 146.7,
    a3: 38.95782,
    a4: 26.05459,
    a5: 16.87345,
    a6: 12.90323,
    b3: 122.25,
    b4: 38.95782,
    b5: 26.05459,
    b6: 16.87345,
  },
  {
    maesu: 4040,
    b2: 163,
    a2: 146.7,
    a3: 39.10891,
    a4: 25.9901,
    a5: 16.83168,
    a6: 12.87129,
    b3: 122.25,
    b4: 39.10891,
    b5: 25.9901,
    b6: 16.83168,
  },
  {
    maesu: 4050,
    b2: 163,
    a2: 146.7,
    a3: 39.01235,
    a4: 25.92593,
    a5: 16.79012,
    a6: 12.83951,
    b3: 122.25,
    b4: 39.01235,
    b5: 25.92593,
    b6: 16.79012,
  },
  {
    maesu: 4060,
    b2: 163,
    a2: 146.7,
    a3: 38.91626,
    a4: 26.10837,
    a5: 16.74877,
    a6: 12.80788,
    b3: 122.25,
    b4: 38.91626,
    b5: 26.10837,
    b6: 16.74877,
  },
  {
    maesu: 4070,
    b2: 163,
    a2: 146.7,
    a3: 39.06634,
    a4: 26.04423,
    a5: 16.70762,
    a6: 12.77641,
    b3: 122.25,
    b4: 39.06634,
    b5: 26.04423,
    b6: 16.70762,
  },
  {
    maesu: 4080,
    b2: 163,
    a2: 146.7,
    a3: 38.97059,
    a4: 25.98039,
    a5: 16.66667,
    a6: 12.7451,
    b3: 122.25,
    b4: 38.97059,
    b5: 25.98039,
    b6: 16.66667,
  },
  {
    maesu: 4090,
    b2: 163,
    a2: 146.7,
    a3: 39.1198,
    a4: 25.91687,
    a5: 16.87042,
    a6: 12.71394,
    b3: 122.25,
    b4: 39.1198,
    b5: 25.91687,
    b6: 16.87042,
  },
  {
    maesu: 4100,
    b2: 163,
    a2: 146.7,
    a3: 39.02439,
    a4: 26.09756,
    a5: 16.82927,
    a6: 12.92683,
    b3: 122.25,
    b4: 39.02439,
    b5: 26.09756,
    b6: 16.82927,
  },
  {
    maesu: 4110,
    b2: 163,
    a2: 146.7,
    a3: 38.92944,
    a4: 26.03406,
    a5: 16.78832,
    a6: 12.89538,
    b3: 122.25,
    b4: 38.92944,
    b5: 26.03406,
    b6: 16.78832,
  },
  {
    maesu: 4120,
    b2: 163,
    a2: 146.7,
    a3: 39.07767,
    a4: 25.97087,
    a5: 16.74757,
    a6: 12.86408,
    b3: 122.25,
    b4: 39.07767,
    b5: 25.97087,
    b6: 16.74757,
  },
  {
    maesu: 4130,
    b2: 163,
    a2: 146.7,
    a3: 38.98305,
    a4: 25.90799,
    a5: 16.70702,
    a6: 12.83293,
    b3: 122.25,
    b4: 38.98305,
    b5: 25.90799,
    b6: 16.70702,
  },
  {
    maesu: 4140,
    b2: 163,
    a2: 146.7,
    a3: 38.88889,
    a4: 26.08696,
    a5: 16.66667,
    a6: 12.80193,
    b3: 122.25,
    b4: 38.88889,
    b5: 26.08696,
    b6: 16.66667,
  },
  {
    maesu: 4150,
    b2: 163,
    a2: 146.7,
    a3: 39.03614,
    a4: 26.0241,
    a5: 16.86747,
    a6: 12.77108,
    b3: 122.25,
    b4: 39.03614,
    b5: 26.0241,
    b6: 16.86747,
  },
  {
    maesu: 4160,
    b2: 163,
    a2: 146.7,
    a3: 38.94231,
    a4: 25.96154,
    a5: 16.82692,
    a6: 12.74038,
    b3: 122.25,
    b4: 38.94231,
    b5: 25.96154,
    b6: 16.82692,
  },
  {
    maesu: 4170,
    b2: 163,
    a2: 146.7,
    a3: 39.08873,
    a4: 25.89928,
    a5: 16.78657,
    a6: 12.70983,
    b3: 122.25,
    b4: 39.08873,
    b5: 25.89928,
    b6: 16.78657,
  },
  {
    maesu: 4180,
    b2: 163,
    a2: 146.7,
    a3: 38.99522,
    a4: 26.07656,
    a5: 16.74641,
    a6: 12.67943,
    b3: 122.25,
    b4: 38.99522,
    b5: 26.07656,
    b6: 16.74641,
  },
  {
    maesu: 4190,
    b2: 163,
    a2: 146.7,
    a3: 38.90215,
    a4: 26.01432,
    a5: 16.70644,
    a6: 12.64916,
    b3: 122.25,
    b4: 38.90215,
    b5: 26.01432,
    b6: 16.70644,
  },
  {
    maesu: 4200,
    b2: 163,
    a2: 146.7,
    a3: 39.04762,
    a4: 25.95238,
    a5: 16.66667,
    a6: 12.61905,
    b3: 122.25,
    b4: 39.04762,
    b5: 25.95238,
    b6: 16.66667,
  },
  {
    maesu: 4210,
    b2: 163,
    a2: 146.7,
    a3: 38.95487,
    a4: 25.89074,
    a5: 16.86461,
    a6: 12.58907,
    b3: 122.25,
    b4: 38.95487,
    b5: 25.89074,
    b6: 16.86461,
  },
  {
    maesu: 4220,
    b2: 163,
    a2: 146.7,
    a3: 39.09953,
    a4: 26.06635,
    a5: 16.82464,
    a6: 12.79621,
    b3: 122.25,
    b4: 39.09953,
    b5: 26.06635,
    b6: 16.82464,
  },
  {
    maesu: 4230,
    b2: 163,
    a2: 146.7,
    a3: 39.00709,
    a4: 26.00473,
    a5: 16.78487,
    a6: 12.76596,
    b3: 122.25,
    b4: 39.00709,
    b5: 26.00473,
    b6: 16.78487,
  },
  {
    maesu: 4240,
    b2: 163,
    a2: 146.7,
    a3: 38.91509,
    a4: 25.9434,
    a5: 16.74528,
    a6: 12.73585,
    b3: 122.25,
    b4: 38.91509,
    b5: 25.9434,
    b6: 16.74528,
  },
  {
    maesu: 4250,
    b2: 163,
    a2: 146.7,
    a3: 39.05882,
    a4: 26.11765,
    a5: 16.70588,
    a6: 12.70588,
    b3: 122.25,
    b4: 39.05882,
    b5: 26.11765,
    b6: 16.70588,
  },
  {
    maesu: 4260,
    b2: 163,
    a2: 146.7,
    a3: 38.96714,
    a4: 26.05634,
    a5: 16.66667,
    a6: 12.67606,
    b3: 122.25,
    b4: 38.96714,
    b5: 26.05634,
    b6: 16.66667,
  },
  {
    maesu: 4270,
    b2: 163,
    a2: 146.7,
    a3: 39.11007,
    a4: 25.99532,
    a5: 16.86183,
    a6: 12.64637,
    b3: 122.25,
    b4: 39.11007,
    b5: 25.99532,
    b6: 16.86183,
  },
  {
    maesu: 4280,
    b2: 163,
    a2: 146.7,
    a3: 39.01869,
    a4: 25.93458,
    a5: 16.82243,
    a6: 12.61682,
    b3: 122.25,
    b4: 39.01869,
    b5: 25.93458,
    b6: 16.82243,
  },
  {
    maesu: 4290,
    b2: 163,
    a2: 146.7,
    a3: 38.92774,
    a4: 26.10723,
    a5: 16.78322,
    a6: 12.58741,
    b3: 122.25,
    b4: 38.92774,
    b5: 26.10723,
    b6: 16.78322,
  },
  {
    maesu: 4300,
    b2: 163,
    a2: 146.7,
    a3: 39.06977,
    a4: 26.04651,
    a5: 16.74419,
    a6: 12.55814,
    b3: 122.25,
    b4: 39.06977,
    b5: 26.04651,
    b6: 16.74419,
  },
  {
    maesu: 4310,
    b2: 163,
    a2: 146.7,
    a3: 38.97912,
    a4: 25.98608,
    a5: 16.70534,
    a6: 12.529,
    b3: 122.25,
    b4: 38.97912,
    b5: 25.98608,
    b6: 16.70534,
  },
  {
    maesu: 4320,
    b2: 163,
    a2: 146.7,
    a3: 38.88889,
    a4: 25.92593,
    a5: 16.66667,
    a6: 12.5,
    b3: 122.25,
    b4: 38.88889,
    b5: 25.92593,
    b6: 16.66667,
  },
  {
    maesu: 4330,
    b2: 163,
    a2: 146.7,
    a3: 39.03002,
    a4: 26.097,
    a5: 16.85912,
    a6: 12.47113,
    b3: 122.25,
    b4: 39.03002,
    b5: 26.097,
    b6: 16.85912,
  },
  {
    maesu: 4340,
    b2: 163,
    a2: 146.7,
    a3: 38.94009,
    a4: 26.03687,
    a5: 16.82028,
    a6: 12.4424,
    b3: 122.25,
    b4: 38.94009,
    b5: 26.03687,
    b6: 16.82028,
  },
  {
    maesu: 4350,
    b2: 163,
    a2: 146.7,
    a3: 39.08046,
    a4: 25.97701,
    a5: 16.78161,
    a6: 12.64368,
    b3: 122.25,
    b4: 39.08046,
    b5: 25.97701,
    b6: 16.78161,
  },
  {
    maesu: 4360,
    b2: 163,
    a2: 146.7,
    a3: 38.99083,
    a4: 25.91743,
    a5: 16.74312,
    a6: 12.61468,
    b3: 122.25,
    b4: 38.99083,
    b5: 25.91743,
    b6: 16.74312,
  },
  {
    maesu: 4370,
    b2: 163,
    a2: 146.7,
    a3: 38.9016,
    a4: 26.08696,
    a5: 16.70481,
    a6: 12.58581,
    b3: 122.25,
    b4: 38.9016,
    b5: 26.08696,
    b6: 16.70481,
  },
  {
    maesu: 4380,
    b2: 163,
    a2: 146.7,
    a3: 39.0411,
    a4: 26.0274,
    a5: 16.66667,
    a6: 12.55708,
    b3: 122.25,
    b4: 39.0411,
    b5: 26.0274,
    b6: 16.66667,
  },
  {
    maesu: 4390,
    b2: 163,
    a2: 146.7,
    a3: 38.95216,
    a4: 25.96811,
    a5: 16.85649,
    a6: 12.52847,
    b3: 122.25,
    b4: 38.95216,
    b5: 25.96811,
    b6: 16.85649,
  },
  {
    maesu: 4400,
    b2: 163,
    a2: 146.7,
    a3: 39.09091,
    a4: 25.90909,
    a5: 16.81818,
    a6: 12.5,
    b3: 122.25,
    b4: 39.09091,
    b5: 25.90909,
    b6: 16.81818,
  },
  {
    maesu: 4410,
    b2: 163,
    a2: 146.7,
    a3: 39.00227,
    a4: 26.0771,
    a5: 16.78005,
    a6: 12.47166,
    b3: 122.25,
    b4: 39.00227,
    b5: 26.0771,
    b6: 16.78005,
  },
  {
    maesu: 4420,
    b2: 163,
    a2: 146.7,
    a3: 38.91403,
    a4: 26.0181,
    a5: 16.74208,
    a6: 12.44344,
    b3: 122.25,
    b4: 38.91403,
    b5: 26.0181,
    b6: 16.74208,
  },
  {
    maesu: 4430,
    b2: 163,
    a2: 146.7,
    a3: 39.05192,
    a4: 25.95937,
    a5: 16.70429,
    a6: 12.41535,
    b3: 122.25,
    b4: 39.05192,
    b5: 25.95937,
    b6: 16.70429,
  },
  {
    maesu: 4440,
    b2: 163,
    a2: 146.7,
    a3: 38.96396,
    a4: 25.9009,
    a5: 16.66667,
    a6: 12.38739,
    b3: 122.25,
    b4: 38.96396,
    b5: 25.9009,
    b6: 16.66667,
  },
  {
    maesu: 4450,
    b2: 163,
    a2: 146.7,
    a3: 39.10112,
    a4: 26.06742,
    a5: 16.85393,
    a6: 12.35955,
    b3: 122.25,
    b4: 39.10112,
    b5: 26.06742,
    b6: 16.85393,
  },
  {
    maesu: 4460,
    b2: 163,
    a2: 146.7,
    a3: 39.01345,
    a4: 26.00897,
    a5: 16.81614,
    a6: 12.33184,
    b3: 122.25,
    b4: 39.01345,
    b5: 26.00897,
    b6: 16.81614,
  },
  {
    maesu: 4470,
    b2: 163,
    a2: 146.7,
    a3: 38.92617,
    a4: 25.95078,
    a5: 16.77852,
    a6: 12.30425,
    b3: 122.25,
    b4: 38.92617,
    b5: 25.95078,
    b6: 16.77852,
  },
  {
    maesu: 4480,
    b2: 163,
    a2: 146.7,
    a3: 39.0625,
    a4: 25.89286,
    a5: 16.74107,
    a6: 12.5,
    b3: 122.25,
    b4: 39.0625,
    b5: 25.89286,
    b6: 16.74107,
  },
  {
    maesu: 4490,
    b2: 163,
    a2: 146.7,
    a3: 38.9755,
    a4: 26.05791,
    a5: 16.70379,
    a6: 12.47216,
    b3: 122.25,
    b4: 38.9755,
    b5: 26.05791,
    b6: 16.70379,
  },
  {
    maesu: 4500,
    b2: 163,
    a2: 146.7,
    a3: 39.11111,
    a4: 26,
    a5: 16.66667,
    a6: 12.44444,
    b3: 122.25,
    b4: 39.11111,
    b5: 26,
    b6: 16.66667,
  },
  {
    maesu: 4510,
    b2: 163,
    a2: 146.7,
    a3: 39.02439,
    a4: 25.94235,
    a5: 16.85144,
    a6: 12.41685,
    b3: 122.25,
    b4: 39.02439,
    b5: 25.94235,
    b6: 16.85144,
  },
  {
    maesu: 4520,
    b2: 163,
    a2: 146.7,
    a3: 38.93805,
    a4: 26.10619,
    a5: 16.81416,
    a6: 12.38938,
    b3: 122.25,
    b4: 38.93805,
    b5: 26.10619,
    b6: 16.81416,
  },
  {
    maesu: 4530,
    b2: 163,
    a2: 146.7,
    a3: 39.07285,
    a4: 26.04857,
    a5: 16.77704,
    a6: 12.36203,
    b3: 122.25,
    b4: 39.07285,
    b5: 26.04857,
    b6: 16.77704,
  },
  {
    maesu: 4540,
    b2: 163,
    a2: 146.7,
    a3: 38.98678,
    a4: 25.99119,
    a5: 16.74009,
    a6: 12.3348,
    b3: 122.25,
    b4: 38.98678,
    b5: 25.99119,
    b6: 16.74009,
  },
  {
    maesu: 4550,
    b2: 163,
    a2: 146.7,
    a3: 38.9011,
    a4: 25.93407,
    a5: 16.7033,
    a6: 12.30769,
    b3: 122.25,
    b4: 38.9011,
    b5: 25.93407,
    b6: 16.7033,
  },
  {
    maesu: 4560,
    b2: 163,
    a2: 146.7,
    a3: 39.03509,
    a4: 26.09649,
    a5: 16.66667,
    a6: 12.2807,
    b3: 122.25,
    b4: 39.03509,
    b5: 26.09649,
    b6: 16.66667,
  },
  {
    maesu: 4570,
    b2: 163,
    a2: 146.7,
    a3: 38.94967,
    a4: 26.03939,
    a5: 16.84902,
    a6: 12.25383,
    b3: 122.25,
    b4: 38.94967,
    b5: 26.03939,
    b6: 16.84902,
  },
  {
    maesu: 4580,
    b2: 163,
    a2: 146.7,
    a3: 39.08297,
    a4: 25.98253,
    a5: 16.81223,
    a6: 12.22707,
    b3: 122.25,
    b4: 39.08297,
    b5: 25.98253,
    b6: 16.81223,
  },
  {
    maesu: 4590,
    b2: 163,
    a2: 146.7,
    a3: 38.99782,
    a4: 25.92593,
    a5: 16.7756,
    a6: 12.20044,
    b3: 122.25,
    b4: 38.99782,
    b5: 25.92593,
    b6: 16.7756,
  },
  {
    maesu: 4600,
    b2: 163,
    a2: 146.7,
    a3: 38.91304,
    a4: 26.08696,
    a5: 16.73913,
    a6: 12.17391,
    b3: 122.25,
    b4: 38.91304,
    b5: 26.08696,
    b6: 16.73913,
  },
  {
    maesu: 4610,
    b2: 163,
    a2: 146.7,
    a3: 39.04555,
    a4: 26.03037,
    a5: 16.70282,
    a6: 12.36443,
    b3: 122.25,
    b4: 39.04555,
    b5: 26.03037,
    b6: 16.70282,
  },
  {
    maesu: 4620,
    b2: 163,
    a2: 146.7,
    a3: 38.96104,
    a4: 25.97403,
    a5: 16.66667,
    a6: 12.33766,
    b3: 122.25,
    b4: 38.96104,
    b5: 25.97403,
    b6: 16.66667,
  },
  {
    maesu: 4630,
    b2: 163,
    a2: 146.7,
    a3: 39.09287,
    a4: 25.91793,
    a5: 16.84665,
    a6: 12.31102,
    b3: 122.25,
    b4: 39.09287,
    b5: 25.91793,
    b6: 16.84665,
  },
  {
    maesu: 4640,
    b2: 163,
    a2: 146.7,
    a3: 39.00862,
    a4: 26.07759,
    a5: 16.81034,
    a6: 12.28448,
    b3: 122.25,
    b4: 39.00862,
    b5: 26.07759,
    b6: 16.81034,
  },
  {
    maesu: 4650,
    b2: 163,
    a2: 146.7,
    a3: 38.92473,
    a4: 26.02151,
    a5: 16.77419,
    a6: 12.25806,
    b3: 122.25,
    b4: 38.92473,
    b5: 26.02151,
    b6: 16.77419,
  },
  {
    maesu: 4660,
    b2: 163,
    a2: 146.7,
    a3: 39.05579,
    a4: 25.96567,
    a5: 16.7382,
    a6: 12.23176,
    b3: 122.25,
    b4: 39.05579,
    b5: 25.96567,
    b6: 16.7382,
  },
  {
    maesu: 4670,
    b2: 163,
    a2: 146.7,
    a3: 38.97216,
    a4: 25.91006,
    a5: 16.70236,
    a6: 12.20557,
    b3: 122.25,
    b4: 38.97216,
    b5: 25.91006,
    b6: 16.70236,
  },
  {
    maesu: 4680,
    b2: 163,
    a2: 146.7,
    a3: 39.10256,
    a4: 26.06838,
    a5: 16.66667,
    a6: 12.17949,
    b3: 122.25,
    b4: 39.10256,
    b5: 26.06838,
    b6: 16.66667,
  },
  {
    maesu: 4690,
    b2: 163,
    a2: 146.7,
    a3: 39.01919,
    a4: 26.01279,
    a5: 16.84435,
    a6: 12.15352,
    b3: 122.25,
    b4: 39.01919,
    b5: 26.01279,
    b6: 16.84435,
  },
  {
    maesu: 4700,
    b2: 163,
    a2: 146.7,
    a3: 38.93617,
    a4: 25.95745,
    a5: 16.80851,
    a6: 12.12766,
    b3: 122.25,
    b4: 38.93617,
    b5: 25.95745,
    b6: 16.80851,
  },
  {
    maesu: 4710,
    b2: 163,
    a2: 146.7,
    a3: 39.06582,
    a4: 25.90234,
    a5: 16.77282,
    a6: 12.10191,
    b3: 122.25,
    b4: 39.06582,
    b5: 25.90234,
    b6: 16.77282,
  },
  {
    maesu: 4720,
    b2: 163,
    a2: 146.7,
    a3: 38.98305,
    a4: 26.05932,
    a5: 16.73729,
    a6: 12.07627,
    b3: 122.25,
    b4: 38.98305,
    b5: 26.05932,
    b6: 16.73729,
  },
  {
    maesu: 4730,
    b2: 163,
    a2: 146.7,
    a3: 38.90063,
    a4: 26.00423,
    a5: 16.7019,
    a6: 12.05074,
    b3: 122.25,
    b4: 38.90063,
    b5: 26.00423,
    b6: 16.7019,
  },
  {
    maesu: 4740,
    b2: 163,
    a2: 146.7,
    a3: 39.02954,
    a4: 25.94937,
    a5: 16.66667,
    a6: 12.23629,
    b3: 122.25,
    b4: 39.02954,
    b5: 25.94937,
    b6: 16.66667,
  },
  {
    maesu: 4750,
    b2: 163,
    a2: 146.7,
    a3: 38.94737,
    a4: 26.10526,
    a5: 16.84211,
    a6: 12.21053,
    b3: 122.25,
    b4: 38.94737,
    b5: 26.10526,
    b6: 16.84211,
  },
  {
    maesu: 4760,
    b2: 163,
    a2: 146.7,
    a3: 39.07563,
    a4: 26.05042,
    a5: 16.80672,
    a6: 12.18487,
    b3: 122.25,
    b4: 39.07563,
    b5: 26.05042,
    b6: 16.80672,
  },
  {
    maesu: 4770,
    b2: 163,
    a2: 146.7,
    a3: 38.99371,
    a4: 25.99581,
    a5: 16.77149,
    a6: 12.15933,
    b3: 122.25,
    b4: 38.99371,
    b5: 25.99581,
    b6: 16.77149,
  },
  {
    maesu: 4780,
    b2: 163,
    a2: 146.7,
    a3: 38.91213,
    a4: 25.94142,
    a5: 16.7364,
    a6: 12.13389,
    b3: 122.25,
    b4: 38.91213,
    b5: 25.94142,
    b6: 16.7364,
  },
  {
    maesu: 4790,
    b2: 163,
    a2: 146.7,
    a3: 39.03967,
    a4: 26.09603,
    a5: 16.70146,
    a6: 12.10856,
    b3: 122.25,
    b4: 39.03967,
    b5: 26.09603,
    b6: 16.70146,
  },
  {
    maesu: 4800,
    b2: 163,
    a2: 146.7,
    a3: 38.95833,
    a4: 26.04167,
    a5: 16.66667,
    a6: 12.08333,
    b3: 122.25,
    b4: 38.95833,
    b5: 26.04167,
    b6: 16.66667,
  },
  {
    maesu: 4810,
    b2: 163,
    a2: 146.7,
    a3: 39.08524,
    a4: 25.98753,
    a5: 16.83992,
    a6: 12.05821,
    b3: 122.25,
    b4: 39.08524,
    b5: 25.98753,
    b6: 16.83992,
  },
  {
    maesu: 4820,
    b2: 163,
    a2: 146.7,
    a3: 39.00415,
    a4: 25.93361,
    a5: 16.80498,
    a6: 12.0332,
    b3: 122.25,
    b4: 39.00415,
    b5: 25.93361,
    b6: 16.80498,
  },
  {
    maesu: 4830,
    b2: 163,
    a2: 146.7,
    a3: 38.9234,
    a4: 26.08696,
    a5: 16.77019,
    a6: 12.00828,
    b3: 122.25,
    b4: 38.9234,
    b5: 26.08696,
    b6: 16.77019,
  },
  {
    maesu: 4840,
    b2: 163,
    a2: 146.7,
    a3: 39.04959,
    a4: 26.03306,
    a5: 16.73554,
    a6: 11.98347,
    b3: 122.25,
    b4: 39.04959,
    b5: 26.03306,
    b6: 16.73554,
  },
  {
    maesu: 4850,
    b2: 163,
    a2: 146.7,
    a3: 38.96907,
    a4: 25.97938,
    a5: 16.70103,
    a6: 11.95876,
    b3: 122.25,
    b4: 38.96907,
    b5: 25.97938,
    b6: 16.70103,
  },
  {
    maesu: 4860,
    b2: 163,
    a2: 146.7,
    a3: 39.09465,
    a4: 25.92593,
    a5: 16.66667,
    a6: 12.13992,
    b3: 122.25,
    b4: 39.09465,
    b5: 25.92593,
    b6: 16.66667,
  },
  {
    maesu: 4870,
    b2: 163,
    a2: 146.7,
    a3: 39.01437,
    a4: 26.07803,
    a5: 16.83778,
    a6: 12.11499,
    b3: 122.25,
    b4: 39.01437,
    b5: 26.07803,
    b6: 16.83778,
  },
  {
    maesu: 4880,
    b2: 163,
    a2: 146.7,
    a3: 38.93443,
    a4: 26.02459,
    a5: 16.80328,
    a6: 12.09016,
    b3: 122.25,
    b4: 38.93443,
    b5: 26.02459,
    b6: 16.80328,
  },
  {
    maesu: 4890,
    b2: 163,
    a2: 146.7,
    a3: 39.0593,
    a4: 25.97137,
    a5: 16.76892,
    a6: 12.06544,
    b3: 122.25,
    b4: 39.0593,
    b5: 25.97137,
    b6: 16.76892,
  },
  {
    maesu: 4900,
    b2: 163,
    a2: 146.7,
    a3: 38.97959,
    a4: 25.91837,
    a5: 16.73469,
    a6: 12.04082,
    b3: 122.25,
    b4: 38.97959,
    b5: 25.91837,
    b6: 16.73469,
  },
  {
    maesu: 4910,
    b2: 163,
    a2: 146.7,
    a3: 38.9002,
    a4: 26.06925,
    a5: 16.70061,
    a6: 12.01629,
    b3: 122.25,
    b4: 38.9002,
    b5: 26.06925,
    b6: 16.70061,
  },
  {
    maesu: 4920,
    b2: 163,
    a2: 146.7,
    a3: 39.02439,
    a4: 26.01626,
    a5: 16.66667,
    a6: 11.99187,
    b3: 122.25,
    b4: 39.02439,
    b5: 26.01626,
    b6: 16.66667,
  },
  {
    maesu: 4930,
    b2: 163,
    a2: 146.7,
    a3: 38.94523,
    a4: 25.96349,
    a5: 16.8357,
    a6: 11.96755,
    b3: 122.25,
    b4: 38.94523,
    b5: 25.96349,
    b6: 16.8357,
  },
  {
    maesu: 4940,
    b2: 163,
    a2: 146.7,
    a3: 39.06883,
    a4: 25.91093,
    a5: 16.80162,
    a6: 11.94332,
    b3: 122.25,
    b4: 39.06883,
    b5: 25.91093,
    b6: 16.80162,
  },
  {
    maesu: 4950,
    b2: 163,
    a2: 146.7,
    a3: 38.9899,
    a4: 26.06061,
    a5: 16.76768,
    a6: 11.91919,
    b3: 122.25,
    b4: 38.9899,
    b5: 26.06061,
    b6: 16.76768,
  },
  {
    maesu: 4960,
    b2: 163,
    a2: 146.7,
    a3: 38.91129,
    a4: 26.00806,
    a5: 16.73387,
    a6: 11.89516,
    b3: 122.25,
    b4: 38.91129,
    b5: 26.00806,
    b6: 16.73387,
  },
  {
    maesu: 4970,
    b2: 163,
    a2: 146.7,
    a3: 39.03421,
    a4: 25.95573,
    a5: 16.7002,
    a6: 11.87123,
    b3: 122.25,
    b4: 39.03421,
    b5: 25.95573,
    b6: 16.7002,
  },
  {
    maesu: 4980,
    b2: 163,
    a2: 146.7,
    a3: 38.95582,
    a4: 25.90361,
    a5: 16.66667,
    a6: 11.84739,
    b3: 122.25,
    b4: 38.95582,
    b5: 25.90361,
    b6: 16.66667,
  },
  {
    maesu: 4990,
    b2: 163,
    a2: 146.7,
    a3: 39.07816,
    a4: 26.0521,
    a5: 16.83367,
    a6: 11.82365,
    b3: 122.25,
    b4: 39.07816,
    b5: 26.0521,
    b6: 16.83367,
  },
  {
    maesu: 5000,
    b2: 163,
    a2: 146.7,
    a3: 39,
    a4: 26,
    a5: 16.8,
    a6: 11.8,
    b3: 122.25,
    b4: 39,
    b5: 26,
    b6: 16.8,
  },
  {
    maesu: 5010,
    b2: 163,
    a2: 146.7,
    a3: 38.92216,
    a4: 25.9481,
    a5: 16.76647,
    a6: 11.77645,
    b3: 122.25,
    b4: 38.92216,
    b5: 25.9481,
    b6: 16.76647,
  },
  {
    maesu: 5020,
    b2: 163,
    a2: 146.7,
    a3: 39.04382,
    a4: 26.09562,
    a5: 16.73307,
    a6: 11.75299,
    b3: 122.25,
    b4: 39.04382,
    b5: 26.09562,
    b6: 16.73307,
  },
  {
    maesu: 5030,
    b2: 163,
    a2: 146.7,
    a3: 38.9662,
    a4: 26.04374,
    a5: 16.6998,
    a6: 11.72962,
    b3: 122.25,
    b4: 38.9662,
    b5: 26.04374,
    b6: 16.6998,
  },
  {
    maesu: 5040,
    b2: 163,
    a2: 146.7,
    a3: 39.0873,
    a4: 25.99206,
    a5: 16.66667,
    a6: 11.70635,
    b3: 122.25,
    b4: 39.0873,
    b5: 25.99206,
    b6: 16.66667,
  },
  {
    maesu: 5050,
    b2: 163,
    a2: 146.7,
    a3: 39.0099,
    a4: 25.94059,
    a5: 16.83168,
    a6: 11.68317,
    b3: 122.25,
    b4: 39.0099,
    b5: 25.94059,
    b6: 16.83168,
  },
  {
    maesu: 5060,
    b2: 163,
    a2: 146.7,
    a3: 38.93281,
    a4: 26.08696,
    a5: 16.79842,
    a6: 11.66008,
    b3: 122.25,
    b4: 38.93281,
    b5: 26.08696,
    b6: 16.79842,
  },
  {
    maesu: 5070,
    b2: 163,
    a2: 146.7,
    a3: 39.05325,
    a4: 26.0355,
    a5: 16.76529,
    a6: 11.63708,
    b3: 122.25,
    b4: 39.05325,
    b5: 26.0355,
    b6: 16.76529,
  },
  {
    maesu: 5080,
    b2: 163,
    a2: 146.7,
    a3: 38.97638,
    a4: 25.98425,
    a5: 16.73228,
    a6: 11.61417,
    b3: 122.25,
    b4: 38.97638,
    b5: 25.98425,
    b6: 16.73228,
  },
  {
    maesu: 5090,
    b2: 163,
    a2: 146.7,
    a3: 39.09627,
    a4: 25.9332,
    a5: 16.69941,
    a6: 11.59136,
    b3: 122.25,
    b4: 39.09627,
    b5: 25.9332,
    b6: 16.69941,
  },
  {
    maesu: 5100,
    b2: 163,
    a2: 146.7,
    a3: 39.01961,
    a4: 26.07843,
    a5: 16.66667,
    a6: 11.56863,
    b3: 122.25,
    b4: 39.01961,
    b5: 26.07843,
    b6: 16.66667,
  },
  {
    maesu: 5110,
    b2: 163,
    a2: 146.7,
    a3: 38.94325,
    a4: 26.0274,
    a5: 16.82975,
    a6: 11.54599,
    b3: 122.25,
    b4: 38.94325,
    b5: 26.0274,
    b6: 16.82975,
  },
  {
    maesu: 5120,
    b2: 163,
    a2: 146.7,
    a3: 39.0625,
    a4: 25.97656,
    a5: 16.79688,
    a6: 11.91406,
    b3: 122.25,
    b4: 39.0625,
    b5: 25.97656,
    b6: 16.79688,
  },
  {
    maesu: 5130,
    b2: 163,
    a2: 146.7,
    a3: 38.98635,
    a4: 25.92593,
    a5: 16.76413,
    a6: 11.89084,
    b3: 122.25,
    b4: 38.98635,
    b5: 25.92593,
    b6: 16.76413,
  },
  {
    maesu: 5140,
    b2: 163,
    a2: 146.7,
    a3: 38.91051,
    a4: 26.07004,
    a5: 16.73152,
    a6: 11.8677,
    b3: 122.25,
    b4: 38.91051,
    b5: 26.07004,
    b6: 16.73152,
  },
  {
    maesu: 5150,
    b2: 163,
    a2: 146.7,
    a3: 39.02913,
    a4: 26.01942,
    a5: 16.69903,
    a6: 11.84466,
    b3: 122.25,
    b4: 39.02913,
    b5: 26.01942,
    b6: 16.69903,
  },
  {
    maesu: 5160,
    b2: 163,
    a2: 146.7,
    a3: 38.95349,
    a4: 25.96899,
    a5: 16.66667,
    a6: 11.82171,
    b3: 122.25,
    b4: 38.95349,
    b5: 25.96899,
    b6: 16.66667,
  },
  {
    maesu: 5170,
    b2: 163,
    a2: 146.7,
    a3: 39.07157,
    a4: 25.91876,
    a5: 16.82785,
    a6: 11.79884,
    b3: 122.25,
    b4: 39.07157,
    b5: 25.91876,
    b6: 16.82785,
  },
  {
    maesu: 5180,
    b2: 163,
    a2: 146.7,
    a3: 38.99614,
    a4: 26.06178,
    a5: 16.79537,
    a6: 11.77606,
    b3: 122.25,
    b4: 38.99614,
    b5: 26.06178,
    b6: 16.79537,
  },
  {
    maesu: 5190,
    b2: 163,
    a2: 146.7,
    a3: 38.921,
    a4: 26.01156,
    a5: 16.76301,
    a6: 11.75337,
    b3: 122.25,
    b4: 38.921,
    b5: 26.01156,
    b6: 16.76301,
  },
  {
    maesu: 5200,
    b2: 163,
    a2: 146.7,
    a3: 39.03846,
    a4: 25.96154,
    a5: 16.73077,
    a6: 11.73077,
    b3: 122.25,
    b4: 39.03846,
    b5: 25.96154,
    b6: 16.73077,
  },
  {
    maesu: 5210,
    b2: 163,
    a2: 146.7,
    a3: 38.96353,
    a4: 25.91171,
    a5: 16.69866,
    a6: 11.70825,
    b3: 122.25,
    b4: 38.96353,
    b5: 25.91171,
    b6: 16.69866,
  },
  {
    maesu: 5220,
    b2: 163,
    a2: 146.7,
    a3: 39.08046,
    a4: 26.05364,
    a5: 16.66667,
    a6: 11.68582,
    b3: 122.25,
    b4: 39.08046,
    b5: 26.05364,
    b6: 16.66667,
  },
  {
    maesu: 5230,
    b2: 163,
    a2: 146.7,
    a3: 39.00574,
    a4: 26.00382,
    a5: 16.826,
    a6: 11.66348,
    b3: 122.25,
    b4: 39.00574,
    b5: 26.00382,
    b6: 16.826,
  },
  {
    maesu: 5240,
    b2: 163,
    a2: 146.7,
    a3: 38.9313,
    a4: 25.9542,
    a5: 16.79389,
    a6: 11.64122,
    b3: 122.25,
    b4: 38.9313,
    b5: 25.9542,
    b6: 16.79389,
  },
  {
    maesu: 5250,
    b2: 163,
    a2: 146.7,
    a3: 39.04762,
    a4: 26.09524,
    a5: 16.7619,
    a6: 11.80952,
    b3: 122.25,
    b4: 39.04762,
    b5: 26.09524,
    b6: 16.7619,
  },
  {
    maesu: 5260,
    b2: 163,
    a2: 146.7,
    a3: 38.97338,
    a4: 26.04563,
    a5: 16.73004,
    a6: 11.78707,
    b3: 122.25,
    b4: 38.97338,
    b5: 26.04563,
    b6: 16.73004,
  },
  {
    maesu: 5270,
    b2: 163,
    a2: 146.7,
    a3: 39.08918,
    a4: 25.9962,
    a5: 16.69829,
    a6: 11.76471,
    b3: 122.25,
    b4: 39.08918,
    b5: 25.9962,
    b6: 16.69829,
  },
  {
    maesu: 5280,
    b2: 163,
    a2: 146.7,
    a3: 39.01515,
    a4: 25.94697,
    a5: 16.66667,
    a6: 11.74242,
    b3: 122.25,
    b4: 39.01515,
    b5: 25.94697,
    b6: 16.66667,
  },
  {
    maesu: 5290,
    b2: 163,
    a2: 146.7,
    a3: 38.9414,
    a4: 26.08696,
    a5: 16.8242,
    a6: 11.72023,
    b3: 122.25,
    b4: 38.9414,
    b5: 26.08696,
    b6: 16.8242,
  },
  {
    maesu: 5300,
    b2: 163,
    a2: 146.7,
    a3: 39.0566,
    a4: 26.03774,
    a5: 16.79245,
    a6: 11.69811,
    b3: 122.25,
    b4: 39.0566,
    b5: 26.03774,
    b6: 16.79245,
  },
  {
    maesu: 5310,
    b2: 163,
    a2: 146.7,
    a3: 38.98305,
    a4: 25.9887,
    a5: 16.76083,
    a6: 11.67608,
    b3: 122.25,
    b4: 38.98305,
    b5: 25.9887,
    b6: 16.76083,
  },
  {
    maesu: 5320,
    b2: 163,
    a2: 146.7,
    a3: 38.90977,
    a4: 25.93985,
    a5: 16.72932,
    a6: 11.65414,
    b3: 122.25,
    b4: 38.90977,
    b5: 25.93985,
    b6: 16.72932,
  },
  {
    maesu: 5330,
    b2: 163,
    a2: 146.7,
    a3: 39.02439,
    a4: 26.0788,
    a5: 16.69794,
    a6: 11.63227,
    b3: 122.25,
    b4: 39.02439,
    b5: 26.0788,
    b6: 16.69794,
  },
  {
    maesu: 5340,
    b2: 163,
    a2: 146.7,
    a3: 38.95131,
    a4: 26.02996,
    a5: 16.66667,
    a6: 11.61049,
    b3: 122.25,
    b4: 38.95131,
    b5: 26.02996,
    b6: 16.66667,
  },
  {
    maesu: 5350,
    b2: 163,
    a2: 146.7,
    a3: 39.06542,
    a4: 25.98131,
    a5: 16.82243,
    a6: 11.58879,
    b3: 122.25,
    b4: 39.06542,
    b5: 25.98131,
    b6: 16.82243,
  },
  {
    maesu: 5360,
    b2: 163,
    a2: 146.7,
    a3: 38.99254,
    a4: 25.93284,
    a5: 16.79104,
    a6: 11.56716,
    b3: 122.25,
    b4: 38.99254,
    b5: 25.93284,
    b6: 16.79104,
  },
  {
    maesu: 5370,
    b2: 163,
    a2: 146.7,
    a3: 38.91993,
    a4: 26.07076,
    a5: 16.75978,
    a6: 11.54562,
    b3: 122.25,
    b4: 38.91993,
    b5: 26.07076,
    b6: 16.75978,
  },
  {
    maesu: 5380,
    b2: 163,
    a2: 146.7,
    a3: 39.03346,
    a4: 26.0223,
    a5: 16.72862,
    a6: 11.52416,
    b3: 122.25,
    b4: 39.03346,
    b5: 26.0223,
    b6: 16.72862,
  },
  {
    maesu: 5390,
    b2: 163,
    a2: 146.7,
    a3: 38.96104,
    a4: 25.97403,
    a5: 16.69759,
    a6: 11.50278,
    b3: 122.25,
    b4: 38.96104,
    b5: 25.97403,
    b6: 16.69759,
  },
  {
    maesu: 5400,
    b2: 163,
    a2: 146.7,
    a3: 39.07407,
    a4: 25.92593,
    a5: 16.66667,
    a6: 11.48148,
    b3: 122.25,
    b4: 39.07407,
    b5: 25.92593,
    b6: 16.66667,
  },
  {
    maesu: 5410,
    b2: 163,
    a2: 146.7,
    a3: 39.00185,
    a4: 26.06285,
    a5: 16.8207,
    a6: 11.46026,
    b3: 122.25,
    b4: 39.00185,
    b5: 26.06285,
    b6: 16.8207,
  },
  {
    maesu: 5420,
    b2: 163,
    a2: 146.7,
    a3: 38.92989,
    a4: 26.01476,
    a5: 16.78967,
    a6: 11.43911,
    b3: 122.25,
    b4: 38.92989,
    b5: 26.01476,
    b6: 16.78967,
  },
  {
    maesu: 5430,
    b2: 163,
    a2: 146.7,
    a3: 39.04236,
    a4: 25.96685,
    a5: 16.75875,
    a6: 11.41805,
    b3: 122.25,
    b4: 39.04236,
    b5: 25.96685,
    b6: 16.75875,
  },
  {
    maesu: 5440,
    b2: 163,
    a2: 146.7,
    a3: 38.97059,
    a4: 25.91912,
    a5: 16.72794,
    a6: 11.39706,
    b3: 122.25,
    b4: 38.97059,
    b5: 25.91912,
    b6: 16.72794,
  },
  {
    maesu: 5450,
    b2: 163,
    a2: 146.7,
    a3: 39.08257,
    a4: 26.05505,
    a5: 16.69725,
    a6: 11.37615,
    b3: 122.25,
    b4: 39.08257,
    b5: 26.05505,
    b6: 16.69725,
  },
  {
    maesu: 5460,
    b2: 163,
    a2: 146.7,
    a3: 39.01099,
    a4: 26.00733,
    a5: 16.66667,
    a6: 11.35531,
    b3: 122.25,
    b4: 39.01099,
    b5: 26.00733,
    b6: 16.66667,
  },
  {
    maesu: 5470,
    b2: 163,
    a2: 146.7,
    a3: 38.93967,
    a4: 25.95978,
    a5: 16.81901,
    a6: 11.33455,
    b3: 122.25,
    b4: 38.93967,
    b5: 25.95978,
    b6: 16.81901,
  },
  {
    maesu: 5480,
    b2: 163,
    a2: 146.7,
    a3: 39.05109,
    a4: 25.91241,
    a5: 16.78832,
    a6: 11.31387,
    b3: 122.25,
    b4: 39.05109,
    b5: 25.91241,
    b6: 16.78832,
  },
  {
    maesu: 5490,
    b2: 163,
    a2: 146.7,
    a3: 38.97996,
    a4: 26.04736,
    a5: 16.75774,
    a6: 11.29326,
    b3: 122.25,
    b4: 38.97996,
    b5: 26.04736,
    b6: 16.75774,
  },
  {
    maesu: 5500,
    b2: 163,
    a2: 146.7,
    a3: 39.09091,
    a4: 26,
    a5: 16.72727,
    a6: 11.27273,
    b3: 122.25,
    b4: 39.09091,
    b5: 26,
    b6: 16.72727,
  },
  {
    maesu: 5510,
    b2: 163,
    a2: 146.7,
    a3: 39.01996,
    a4: 25.95281,
    a5: 16.69691,
    a6: 11.25227,
    b3: 122.25,
    b4: 39.01996,
    b5: 25.95281,
    b6: 16.69691,
  },
  {
    maesu: 5520,
    b2: 163,
    a2: 146.7,
    a3: 38.94928,
    a4: 26.08696,
    a5: 16.66667,
    a6: 11.23188,
    b3: 122.25,
    b4: 38.94928,
    b5: 26.08696,
    b6: 16.66667,
  },
  {
    maesu: 5530,
    b2: 163,
    a2: 146.7,
    a3: 39.05967,
    a4: 26.03978,
    a5: 16.81736,
    a6: 11.39241,
    b3: 122.25,
    b4: 39.05967,
    b5: 26.03978,
    b6: 16.81736,
  },
  {
    maesu: 5540,
    b2: 163,
    a2: 146.7,
    a3: 38.98917,
    a4: 25.99278,
    a5: 16.787,
    a6: 11.37184,
    b3: 122.25,
    b4: 38.98917,
    b5: 25.99278,
    b6: 16.787,
  },
  {
    maesu: 5550,
    b2: 163,
    a2: 146.7,
    a3: 38.91892,
    a4: 25.94595,
    a5: 16.75676,
    a6: 11.35135,
    b3: 122.25,
    b4: 38.91892,
    b5: 25.94595,
    b6: 16.75676,
  },
  {
    maesu: 5560,
    b2: 163,
    a2: 146.7,
    a3: 39.02878,
    a4: 26.07914,
    a5: 16.72662,
    a6: 11.33094,
    b3: 122.25,
    b4: 39.02878,
    b5: 26.07914,
    b6: 16.72662,
  },
  {
    maesu: 5570,
    b2: 163,
    a2: 146.7,
    a3: 38.95871,
    a4: 26.03232,
    a5: 16.69659,
    a6: 11.31059,
    b3: 122.25,
    b4: 38.95871,
    b5: 26.03232,
    b6: 16.69659,
  },
  {
    maesu: 5580,
    b2: 163,
    a2: 146.7,
    a3: 39.0681,
    a4: 25.98566,
    a5: 16.66667,
    a6: 11.29032,
    b3: 122.25,
    b4: 39.0681,
    b5: 25.98566,
    b6: 16.66667,
  },
  {
    maesu: 5590,
    b2: 163,
    a2: 146.7,
    a3: 38.99821,
    a4: 25.93918,
    a5: 16.81574,
    a6: 11.27013,
    b3: 122.25,
    b4: 38.99821,
    b5: 25.93918,
    b6: 16.81574,
  },
  {
    maesu: 5600,
    b2: 163,
    a2: 146.7,
    a3: 38.92857,
    a4: 26.07143,
    a5: 16.78571,
    a6: 11.25,
    b3: 122.25,
    b4: 38.92857,
    b5: 26.07143,
    b6: 16.78571,
  },
  {
    maesu: 5610,
    b2: 163,
    a2: 146.7,
    a3: 39.03743,
    a4: 26.02496,
    a5: 16.75579,
    a6: 11.22995,
    b3: 122.25,
    b4: 39.03743,
    b5: 26.02496,
    b6: 16.75579,
  },
  {
    maesu: 5620,
    b2: 163,
    a2: 146.7,
    a3: 38.96797,
    a4: 25.97865,
    a5: 16.72598,
    a6: 11.20996,
    b3: 122.25,
    b4: 38.96797,
    b5: 25.97865,
    b6: 16.72598,
  },
  {
    maesu: 5630,
    b2: 163,
    a2: 146.7,
    a3: 39.07638,
    a4: 25.9325,
    a5: 16.69627,
    a6: 11.19005,
    b3: 122.25,
    b4: 39.07638,
    b5: 25.9325,
    b6: 16.69627,
  },
  {
    maesu: 5640,
    b2: 163,
    a2: 146.7,
    a3: 39.00709,
    a4: 26.06383,
    a5: 16.66667,
    a6: 11.34752,
    b3: 122.25,
    b4: 39.00709,
    b5: 26.06383,
    b6: 16.66667,
  },
  {
    maesu: 5650,
    b2: 163,
    a2: 146.7,
    a3: 38.93805,
    a4: 26.0177,
    a5: 16.81416,
    a6: 11.32743,
    b3: 122.25,
    b4: 38.93805,
    b5: 26.0177,
    b6: 16.81416,
  },
  {
    maesu: 5660,
    b2: 163,
    a2: 146.7,
    a3: 39.04594,
    a4: 25.97173,
    a5: 16.78445,
    a6: 11.30742,
    b3: 122.25,
    b4: 39.04594,
    b5: 25.97173,
    b6: 16.78445,
  },
  {
    maesu: 5670,
    b2: 163,
    a2: 146.7,
    a3: 38.97707,
    a4: 25.92593,
    a5: 16.75485,
    a6: 11.28748,
    b3: 122.25,
    b4: 38.97707,
    b5: 25.92593,
    b6: 16.75485,
  },
  {
    maesu: 5680,
    b2: 163,
    a2: 146.7,
    a3: 39.08451,
    a4: 26.05634,
    a5: 16.72535,
    a6: 11.26761,
    b3: 122.25,
    b4: 39.08451,
    b5: 26.05634,
    b6: 16.72535,
  },
  {
    maesu: 5690,
    b2: 163,
    a2: 146.7,
    a3: 39.01582,
    a4: 26.01054,
    a5: 16.69596,
    a6: 11.2478,
    b3: 122.25,
    b4: 39.01582,
    b5: 26.01054,
    b6: 16.69596,
  },
  {
    maesu: 5700,
    b2: 163,
    a2: 146.7,
    a3: 38.94737,
    a4: 25.96491,
    a5: 16.66667,
    a6: 11.22807,
    b3: 122.25,
    b4: 38.94737,
    b5: 25.96491,
    b6: 16.66667,
  },
  {
    maesu: 5710,
    b2: 163,
    a2: 146.7,
    a3: 39.05429,
    a4: 25.91944,
    a5: 16.81261,
    a6: 11.20841,
    b3: 122.25,
    b4: 39.05429,
    b5: 25.91944,
    b6: 16.81261,
  },
  {
    maesu: 5720,
    b2: 163,
    a2: 146.7,
    a3: 38.98601,
    a4: 26.04895,
    a5: 16.78322,
    a6: 11.18881,
    b3: 122.25,
    b4: 38.98601,
    b5: 26.04895,
    b6: 16.78322,
  },
  {
    maesu: 5730,
    b2: 163,
    a2: 146.7,
    a3: 38.91798,
    a4: 26.00349,
    a5: 16.75393,
    a6: 11.16928,
    b3: 122.25,
    b4: 38.91798,
    b5: 26.00349,
    b6: 16.75393,
  },
  {
    maesu: 5740,
    b2: 163,
    a2: 146.7,
    a3: 39.02439,
    a4: 25.95819,
    a5: 16.72474,
    a6: 11.14983,
    b3: 122.25,
    b4: 39.02439,
    b5: 25.95819,
    b6: 16.72474,
  },
  {
    maesu: 5750,
    b2: 163,
    a2: 146.7,
    a3: 38.95652,
    a4: 26.08696,
    a5: 16.69565,
    a6: 11.13043,
    b3: 122.25,
    b4: 38.95652,
    b5: 26.08696,
    b6: 16.69565,
  },
  {
    maesu: 5760,
    b2: 163,
    a2: 146.7,
    a3: 39.0625,
    a4: 26.04167,
    a5: 16.66667,
    a6: 11.11111,
    b3: 122.25,
    b4: 39.0625,
    b5: 26.04167,
    b6: 16.66667,
  },
  {
    maesu: 5770,
    b2: 163,
    a2: 146.7,
    a3: 38.9948,
    a4: 25.99653,
    a5: 16.81109,
    a6: 11.09185,
    b3: 122.25,
    b4: 38.9948,
    b5: 25.99653,
    b6: 16.81109,
  },
  {
    maesu: 5780,
    b2: 163,
    a2: 146.7,
    a3: 38.92734,
    a4: 25.95156,
    a5: 16.78201,
    a6: 11.07266,
    b3: 122.25,
    b4: 38.92734,
    b5: 25.95156,
    b6: 16.78201,
  },
  {
    maesu: 5790,
    b2: 163,
    a2: 146.7,
    a3: 39.03282,
    a4: 26.07945,
    a5: 16.75302,
    a6: 11.05354,
    b3: 122.25,
    b4: 39.03282,
    b5: 26.07945,
    b6: 16.75302,
  },
  {
    maesu: 5800,
    b2: 163,
    a2: 146.7,
    a3: 38.96552,
    a4: 26.03448,
    a5: 16.72414,
    a6: 11.03448,
    b3: 122.25,
    b4: 38.96552,
    b5: 26.03448,
    b6: 16.72414,
  },
  {
    maesu: 5810,
    b2: 163,
    a2: 146.7,
    a3: 39.07057,
    a4: 25.98967,
    a5: 16.69535,
    a6: 11.01549,
    b3: 122.25,
    b4: 39.07057,
    b5: 25.98967,
    b6: 16.69535,
  },
  {
    maesu: 5820,
    b2: 163,
    a2: 146.7,
    a3: 39.00344,
    a4: 25.94502,
    a5: 16.66667,
    a6: 10.99656,
    b3: 122.25,
    b4: 39.00344,
    b5: 25.94502,
    b6: 16.66667,
  },
  {
    maesu: 5830,
    b2: 163,
    a2: 146.7,
    a3: 38.93654,
    a4: 26.07204,
    a5: 16.80961,
    a6: 11.14923,
    b3: 122.25,
    b4: 38.93654,
    b5: 26.07204,
    b6: 16.80961,
  },
  {
    maesu: 5840,
    b2: 163,
    a2: 146.7,
    a3: 39.0411,
    a4: 26.0274,
    a5: 16.78082,
    a6: 11.13014,
    b3: 122.25,
    b4: 39.0411,
    b5: 26.0274,
    b6: 16.78082,
  },
  {
    maesu: 5850,
    b2: 163,
    a2: 146.7,
    a3: 38.97436,
    a4: 25.98291,
    a5: 16.75214,
    a6: 11.11111,
    b3: 122.25,
    b4: 38.97436,
    b5: 25.98291,
    b6: 16.75214,
  },
  {
    maesu: 5860,
    b2: 163,
    a2: 146.7,
    a3: 39.0785,
    a4: 25.93857,
    a5: 16.72355,
    a6: 11.09215,
    b3: 122.25,
    b4: 39.0785,
    b5: 25.93857,
    b6: 16.72355,
  },
  {
    maesu: 5870,
    b2: 163,
    a2: 146.7,
    a3: 39.01193,
    a4: 26.06474,
    a5: 16.69506,
    a6: 11.07325,
    b3: 122.25,
    b4: 39.01193,
    b5: 26.06474,
    b6: 16.69506,
  },
  {
    maesu: 5880,
    b2: 163,
    a2: 146.7,
    a3: 38.94558,
    a4: 26.02041,
    a5: 16.66667,
    a6: 11.05442,
    b3: 122.25,
    b4: 38.94558,
    b5: 26.02041,
    b6: 16.66667,
  },
  {
    maesu: 5890,
    b2: 163,
    a2: 146.7,
    a3: 39.04924,
    a4: 25.97623,
    a5: 16.80815,
    a6: 11.03565,
    b3: 122.25,
    b4: 39.04924,
    b5: 25.97623,
    b6: 16.80815,
  },
  {
    maesu: 5900,
    b2: 163,
    a2: 146.7,
    a3: 38.98305,
    a4: 25.9322,
    a5: 16.77966,
    a6: 11.01695,
    b3: 122.25,
    b4: 38.98305,
    b5: 25.9322,
    b6: 16.77966,
  },
  {
    maesu: 5910,
    b2: 163,
    a2: 146.7,
    a3: 38.91709,
    a4: 26.05753,
    a5: 16.75127,
    a6: 10.99831,
    b3: 122.25,
    b4: 38.91709,
    b5: 26.05753,
    b6: 16.75127,
  },
  {
    maesu: 5920,
    b2: 163,
    a2: 146.7,
    a3: 39.02027,
    a4: 26.01351,
    a5: 16.72297,
    a6: 10.97973,
    b3: 122.25,
    b4: 39.02027,
    b5: 26.01351,
    b6: 16.72297,
  },
  {
    maesu: 5930,
    b2: 163,
    a2: 146.7,
    a3: 38.95447,
    a4: 25.96965,
    a5: 16.69477,
    a6: 10.96121,
    b3: 122.25,
    b4: 38.95447,
    b5: 25.96965,
    b6: 16.69477,
  },
  {
    maesu: 5940,
    b2: 163,
    a2: 146.7,
    a3: 39.05724,
    a4: 25.92593,
    a5: 16.66667,
    a6: 10.94276,
    b3: 122.25,
    b4: 39.05724,
    b5: 25.92593,
    b6: 16.66667,
  },
  {
    maesu: 5950,
    b2: 163,
    a2: 146.7,
    a3: 38.9916,
    a4: 26.05042,
    a5: 16.80672,
    a6: 10.92437,
    b3: 122.25,
    b4: 38.9916,
    b5: 26.05042,
    b6: 16.80672,
  },
  {
    maesu: 5960,
    b2: 163,
    a2: 146.7,
    a3: 38.92617,
    a4: 26.00671,
    a5: 16.77852,
    a6: 10.90604,
    b3: 122.25,
    b4: 38.92617,
    b5: 26.00671,
    b6: 16.77852,
  },
  {
    maesu: 5970,
    b2: 163,
    a2: 146.7,
    a3: 39.02848,
    a4: 25.96315,
    a5: 16.75042,
    a6: 10.88777,
    b3: 122.25,
    b4: 39.02848,
    b5: 25.96315,
    b6: 16.75042,
  },
  {
    maesu: 5980,
    b2: 163,
    a2: 146.7,
    a3: 38.96321,
    a4: 25.91973,
    a5: 16.72241,
    a6: 11.03679,
    b3: 122.25,
    b4: 38.96321,
    b5: 25.91973,
    b6: 16.72241,
  },
  {
    maesu: 5990,
    b2: 163,
    a2: 146.7,
    a3: 39.06511,
    a4: 26.04341,
    a5: 16.69449,
    a6: 11.01836,
    b3: 122.25,
    b4: 39.06511,
    b5: 26.04341,
    b6: 16.69449,
  },
  {
    maesu: 6000,
    b2: 163,
    a2: 146.7,
    a3: 39,
    a4: 26,
    a5: 16.83333,
    a6: 11,
    b3: 122.25,
    b4: 39,
    b5: 26,
    b6: 16.83333,
  },
  {
    maesu: 6010,
    b2: 163,
    a2: 146.7,
    a3: 38.93511,
    a4: 25.95674,
    a5: 16.80532,
    a6: 10.9817,
    b3: 122.25,
    b4: 38.93511,
    b5: 25.95674,
    b6: 16.80532,
  },
  {
    maesu: 6020,
    b2: 163,
    a2: 146.7,
    a3: 39.03654,
    a4: 26.07973,
    a5: 16.77741,
    a6: 10.96346,
    b3: 122.25,
    b4: 39.03654,
    b5: 26.07973,
    b6: 16.77741,
  },
  {
    maesu: 6030,
    b2: 163,
    a2: 146.7,
    a3: 38.97181,
    a4: 26.03648,
    a5: 16.74959,
    a6: 10.94527,
    b3: 122.25,
    b4: 38.97181,
    b5: 26.03648,
    b6: 16.74959,
  },
  {
    maesu: 6040,
    b2: 163,
    a2: 146.7,
    a3: 39.07285,
    a4: 25.99338,
    a5: 16.72185,
    a6: 10.92715,
    b3: 122.25,
    b4: 39.07285,
    b5: 25.99338,
    b6: 16.72185,
  },
  {
    maesu: 6050,
    b2: 163,
    a2: 146.7,
    a3: 39.00826,
    a4: 25.95041,
    a5: 16.69421,
    a6: 10.90909,
    b3: 122.25,
    b4: 39.00826,
    b5: 25.95041,
    b6: 16.69421,
  },
  {
    maesu: 6060,
    b2: 163,
    a2: 146.7,
    a3: 38.94389,
    a4: 26.07261,
    a5: 16.83168,
    a6: 10.89109,
    b3: 122.25,
    b4: 38.94389,
    b5: 26.07261,
    b6: 16.83168,
  },
  {
    maesu: 6070,
    b2: 163,
    a2: 146.7,
    a3: 39.04448,
    a4: 26.02965,
    a5: 16.80395,
    a6: 11.03789,
    b3: 122.25,
    b4: 39.04448,
    b5: 26.02965,
    b6: 16.80395,
  },
  {
    maesu: 6080,
    b2: 163,
    a2: 146.7,
    a3: 38.98026,
    a4: 25.98684,
    a5: 16.77632,
    a6: 11.01974,
    b3: 122.25,
    b4: 38.98026,
    b5: 25.98684,
    b6: 16.77632,
  },
  {
    maesu: 6090,
    b2: 163,
    a2: 146.7,
    a3: 39.08046,
    a4: 25.94417,
    a5: 16.74877,
    a6: 11.00164,
    b3: 122.25,
    b4: 39.08046,
    b5: 25.94417,
    b6: 16.74877,
  },
  {
    maesu: 6100,
    b2: 163,
    a2: 146.7,
    a3: 39.01639,
    a4: 26.06557,
    a5: 16.72131,
    a6: 10.98361,
    b3: 122.25,
    b4: 39.01639,
    b5: 26.06557,
    b6: 16.72131,
  },
  {
    maesu: 6110,
    b2: 163,
    a2: 146.7,
    a3: 38.95254,
    a4: 26.02291,
    a5: 16.69394,
    a6: 10.96563,
    b3: 122.25,
    b4: 38.95254,
    b5: 26.02291,
    b6: 16.69394,
  },
  {
    maesu: 6120,
    b2: 163,
    a2: 146.7,
    a3: 39.05229,
    a4: 25.98039,
    a5: 16.83007,
    a6: 10.94771,
    b3: 122.25,
    b4: 39.05229,
    b5: 25.98039,
    b6: 16.83007,
  },
  {
    maesu: 6130,
    b2: 163,
    a2: 146.7,
    a3: 38.98858,
    a4: 25.93801,
    a5: 16.80261,
    a6: 10.92985,
    b3: 122.25,
    b4: 38.98858,
    b5: 25.93801,
    b6: 16.80261,
  },
  {
    maesu: 6140,
    b2: 163,
    a2: 146.7,
    a3: 38.92508,
    a4: 26.05863,
    a5: 16.77524,
    a6: 10.91205,
    b3: 122.25,
    b4: 38.92508,
    b5: 26.05863,
    b6: 16.77524,
  },
  {
    maesu: 6150,
    b2: 163,
    a2: 146.7,
    a3: 39.02439,
    a4: 26.01626,
    a5: 16.74797,
    a6: 10.89431,
    b3: 122.25,
    b4: 39.02439,
    b5: 26.01626,
    b6: 16.74797,
  },
  {
    maesu: 6160,
    b2: 163,
    a2: 146.7,
    a3: 38.96104,
    a4: 25.97403,
    a5: 16.72078,
    a6: 10.87662,
    b3: 122.25,
    b4: 38.96104,
    b5: 25.97403,
    b6: 16.72078,
  },
  {
    maesu: 6170,
    b2: 163,
    a2: 146.7,
    a3: 39.05997,
    a4: 25.93193,
    a5: 16.69368,
    a6: 10.859,
    b3: 122.25,
    b4: 39.05997,
    b5: 25.93193,
    b6: 16.69368,
  },
  {
    maesu: 6180,
    b2: 163,
    a2: 146.7,
    a3: 38.99676,
    a4: 26.05178,
    a5: 16.82848,
    a6: 10.84142,
    b3: 122.25,
    b4: 38.99676,
    b5: 26.05178,
    b6: 16.82848,
  },
  {
    maesu: 6190,
    b2: 163,
    a2: 146.7,
    a3: 38.93376,
    a4: 26.00969,
    a5: 16.80129,
    a6: 10.98546,
    b3: 122.25,
    b4: 38.93376,
    b5: 26.00969,
    b6: 16.80129,
  },
  {
    maesu: 6200,
    b2: 163,
    a2: 146.7,
    a3: 39.03226,
    a4: 25.96774,
    a5: 16.77419,
    a6: 10.96774,
    b3: 122.25,
    b4: 39.03226,
    b5: 25.96774,
    b6: 16.77419,
  },
  {
    maesu: 6210,
    b2: 163,
    a2: 146.7,
    a3: 38.9694,
    a4: 25.92593,
    a5: 16.74718,
    a6: 10.95008,
    b3: 122.25,
    b4: 38.9694,
    b5: 25.92593,
    b6: 16.74718,
  },
  {
    maesu: 6220,
    b2: 163,
    a2: 146.7,
    a3: 39.06752,
    a4: 26.04502,
    a5: 16.72026,
    a6: 10.93248,
    b3: 122.25,
    b4: 39.06752,
    b5: 26.04502,
    b6: 16.72026,
  },
  {
    maesu: 6230,
    b2: 163,
    a2: 146.7,
    a3: 39.00482,
    a4: 26.00321,
    a5: 16.69342,
    a6: 10.91493,
    b3: 122.25,
    b4: 39.00482,
    b5: 26.00321,
    b6: 16.69342,
  },
  {
    maesu: 6240,
    b2: 163,
    a2: 146.7,
    a3: 38.94231,
    a4: 25.96154,
    a5: 16.82692,
    a6: 10.89744,
    b3: 122.25,
    b4: 38.94231,
    b5: 25.96154,
    b6: 16.82692,
  },
  {
    maesu: 6250,
    b2: 163,
    a2: 146.7,
    a3: 39.04,
    a4: 26.08,
    a5: 16.8,
    a6: 10.88,
    b3: 122.25,
    b4: 39.04,
    b5: 26.08,
    b6: 16.8,
  },
  {
    maesu: 6260,
    b2: 163,
    a2: 146.7,
    a3: 38.97764,
    a4: 26.03834,
    a5: 16.77316,
    a6: 10.86262,
    b3: 122.25,
    b4: 38.97764,
    b5: 26.03834,
    b6: 16.77316,
  },
  {
    maesu: 6270,
    b2: 163,
    a2: 146.7,
    a3: 39.07496,
    a4: 25.99681,
    a5: 16.74641,
    a6: 10.8453,
    b3: 122.25,
    b4: 39.07496,
    b5: 25.99681,
    b6: 16.74641,
  },
  {
    maesu: 6280,
    b2: 163,
    a2: 146.7,
    a3: 39.01274,
    a4: 25.95541,
    a5: 16.71975,
    a6: 10.82803,
    b3: 122.25,
    b4: 39.01274,
    b5: 25.95541,
    b6: 16.71975,
  },
  {
    maesu: 6290,
    b2: 163,
    a2: 146.7,
    a3: 38.95072,
    a4: 26.07313,
    a5: 16.69316,
    a6: 10.81081,
    b3: 122.25,
    b4: 38.95072,
    b5: 26.07313,
    b6: 16.69316,
  },
  {
    maesu: 6300,
    b2: 163,
    a2: 146.7,
    a3: 39.04762,
    a4: 26.03175,
    a5: 16.8254,
    a6: 10.79365,
    b3: 122.25,
    b4: 39.04762,
    b5: 26.03175,
    b6: 16.8254,
  },
  {
    maesu: 6310,
    b2: 163,
    a2: 146.7,
    a3: 38.98574,
    a4: 25.99049,
    a5: 16.79873,
    a6: 10.77655,
    b3: 122.25,
    b4: 38.98574,
    b5: 25.99049,
    b6: 16.79873,
  },
  {
    maesu: 6320,
    b2: 163,
    a2: 146.7,
    a3: 38.92405,
    a4: 25.94937,
    a5: 16.77215,
    a6: 10.75949,
    b3: 122.25,
    b4: 38.92405,
    b5: 25.94937,
    b6: 16.77215,
  },
  {
    maesu: 6330,
    b2: 163,
    a2: 146.7,
    a3: 39.02054,
    a4: 26.06635,
    a5: 16.74566,
    a6: 10.7425,
    b3: 122.25,
    b4: 39.02054,
    b5: 26.06635,
    b6: 16.74566,
  },
  {
    maesu: 6340,
    b2: 163,
    a2: 146.7,
    a3: 38.95899,
    a4: 26.02524,
    a5: 16.71924,
    a6: 10.72555,
    b3: 122.25,
    b4: 38.95899,
    b5: 26.02524,
    b6: 16.71924,
  },
  {
    maesu: 6350,
    b2: 163,
    a2: 146.7,
    a3: 39.05512,
    a4: 25.98425,
    a5: 16.69291,
    a6: 10.70866,
    b3: 122.25,
    b4: 39.05512,
    b5: 25.98425,
    b6: 16.69291,
  },
  {
    maesu: 6360,
    b2: 163,
    a2: 146.7,
    a3: 38.99371,
    a4: 25.9434,
    a5: 16.8239,
    a6: 10.69182,
    b3: 122.25,
    b4: 38.99371,
    b5: 25.9434,
    b6: 16.8239,
  },
  {
    maesu: 6370,
    b2: 163,
    a2: 146.7,
    a3: 38.9325,
    a4: 26.05965,
    a5: 16.79749,
    a6: 10.67504,
    b3: 122.25,
    b4: 38.9325,
    b5: 26.05965,
    b6: 16.79749,
  },
  {
    maesu: 6380,
    b2: 163,
    a2: 146.7,
    a3: 39.02821,
    a4: 26.01881,
    a5: 16.77116,
    a6: 10.65831,
    b3: 122.25,
    b4: 39.02821,
    b5: 26.01881,
    b6: 16.77116,
  },
  {
    maesu: 6390,
    b2: 163,
    a2: 146.7,
    a3: 38.96714,
    a4: 25.97809,
    a5: 16.74491,
    a6: 10.79812,
    b3: 122.25,
    b4: 38.96714,
    b5: 25.97809,
    b6: 16.74491,
  },
  {
    maesu: 6400,
    b2: 163,
    a2: 146.7,
    a3: 39.0625,
    a4: 25.9375,
    a5: 16.71875,
    a6: 10.78125,
    b3: 122.25,
    b4: 39.0625,
    b5: 25.9375,
    b6: 16.71875,
  },
  {
    maesu: 6410,
    b2: 163,
    a2: 146.7,
    a3: 39.00156,
    a4: 26.05304,
    a5: 16.69267,
    a6: 10.76443,
    b3: 122.25,
    b4: 39.00156,
    b5: 26.05304,
    b6: 16.69267,
  },
  {
    maesu: 6420,
    b2: 163,
    a2: 146.7,
    a3: 38.94081,
    a4: 26.01246,
    a5: 16.82243,
    a6: 10.74766,
    b3: 122.25,
    b4: 38.94081,
    b5: 26.01246,
    b6: 16.82243,
  },
  {
    maesu: 6430,
    b2: 163,
    a2: 146.7,
    a3: 39.03577,
    a4: 25.97201,
    a5: 16.79627,
    a6: 10.73095,
    b3: 122.25,
    b4: 39.03577,
    b5: 25.97201,
    b6: 16.79627,
  },
  {
    maesu: 6440,
    b2: 163,
    a2: 146.7,
    a3: 38.97516,
    a4: 25.93168,
    a5: 16.77019,
    a6: 10.71429,
    b3: 122.25,
    b4: 38.97516,
    b5: 25.93168,
    b6: 16.77019,
  },
  {
    maesu: 6450,
    b2: 163,
    a2: 146.7,
    a3: 39.06977,
    a4: 26.04651,
    a5: 16.74419,
    a6: 10.69767,
    b3: 122.25,
    b4: 39.06977,
    b5: 26.04651,
    b6: 16.74419,
  },
  {
    maesu: 6460,
    b2: 163,
    a2: 146.7,
    a3: 39.00929,
    a4: 26.00619,
    a5: 16.71827,
    a6: 10.68111,
    b3: 122.25,
    b4: 39.00929,
    b5: 26.00619,
    b6: 16.71827,
  },
  {
    maesu: 6470,
    b2: 163,
    a2: 146.7,
    a3: 38.949,
    a4: 25.966,
    a5: 16.69243,
    a6: 10.66461,
    b3: 122.25,
    b4: 38.949,
    b5: 25.966,
    b6: 16.69243,
  },
  {
    maesu: 6480,
    b2: 163,
    a2: 146.7,
    a3: 39.04321,
    a4: 25.92593,
    a5: 16.82099,
    a6: 10.64815,
    b3: 122.25,
    b4: 39.04321,
    b5: 25.92593,
    b6: 16.82099,
  },
  {
    maesu: 6490,
    b2: 163,
    a2: 146.7,
    a3: 38.98305,
    a4: 26.04006,
    a5: 16.79507,
    a6: 10.63174,
    b3: 122.25,
    b4: 38.98305,
    b5: 26.04006,
    b6: 16.79507,
  },
  {
    maesu: 6500,
    b2: 163,
    a2: 146.7,
    a3: 39.07692,
    a4: 26,
    a5: 16.76923,
    a6: 10.61538,
    b3: 122.25,
    b4: 39.07692,
    b5: 26,
    b6: 16.76923,
  },
  {
    maesu: 6510,
    b2: 163,
    a2: 146.7,
    a3: 39.0169,
    a4: 25.96006,
    a5: 16.74347,
    a6: 10.59908,
    b3: 122.25,
    b4: 39.0169,
    b5: 25.96006,
    b6: 16.74347,
  },
  {
    maesu: 6520,
    b2: 163,
    a2: 146.7,
    a3: 38.95706,
    a4: 26.07362,
    a5: 16.71779,
    a6: 10.58282,
    b3: 122.25,
    b4: 38.95706,
    b5: 26.07362,
    b6: 16.71779,
  },
  {
    maesu: 6530,
    b2: 163,
    a2: 146.7,
    a3: 39.05054,
    a4: 26.03369,
    a5: 16.69219,
    a6: 10.56662,
    b3: 122.25,
    b4: 39.05054,
    b5: 26.03369,
    b6: 16.69219,
  },
  {
    maesu: 6540,
    b2: 163,
    a2: 146.7,
    a3: 38.99083,
    a4: 25.99388,
    a5: 16.81957,
    a6: 10.55046,
    b3: 122.25,
    b4: 38.99083,
    b5: 25.99388,
    b6: 16.81957,
  },
  {
    maesu: 6550,
    b2: 163,
    a2: 146.7,
    a3: 38.9313,
    a4: 25.9542,
    a5: 16.79389,
    a6: 10.53435,
    b3: 122.25,
    b4: 38.9313,
    b5: 25.9542,
    b6: 16.79389,
  },
  {
    maesu: 6560,
    b2: 163,
    a2: 146.7,
    a3: 39.02439,
    a4: 26.06707,
    a5: 16.76829,
    a6: 10.51829,
    b3: 122.25,
    b4: 39.02439,
    b5: 26.06707,
    b6: 16.76829,
  },
  {
    maesu: 6570,
    b2: 163,
    a2: 146.7,
    a3: 38.96499,
    a4: 26.0274,
    a5: 16.74277,
    a6: 10.50228,
    b3: 122.25,
    b4: 38.96499,
    b5: 26.0274,
    b6: 16.74277,
  },
  {
    maesu: 6580,
    b2: 163,
    a2: 146.7,
    a3: 39.05775,
    a4: 25.98784,
    a5: 16.71733,
    a6: 10.48632,
    b3: 122.25,
    b4: 39.05775,
    b5: 25.98784,
    b6: 16.71733,
  },
  {
    maesu: 6590,
    b2: 163,
    a2: 146.7,
    a3: 38.99848,
    a4: 25.94841,
    a5: 16.69196,
    a6: 10.47041,
    b3: 122.25,
    b4: 38.99848,
    b5: 25.94841,
    b6: 16.69196,
  },
  {
    maesu: 6600,
    b2: 163,
    a2: 146.7,
    a3: 38.93939,
    a4: 26.06061,
    a5: 16.81818,
    a6: 10.45455,
    b3: 122.25,
    b4: 38.93939,
    b5: 26.06061,
    b6: 16.81818,
  },
  {
    maesu: 6610,
    b2: 163,
    a2: 146.7,
    a3: 39.03177,
    a4: 26.02118,
    a5: 16.79274,
    a6: 10.43873,
    b3: 122.25,
    b4: 39.03177,
    b5: 26.02118,
    b6: 16.79274,
  },
  {
    maesu: 6620,
    b2: 163,
    a2: 146.7,
    a3: 38.97281,
    a4: 25.98187,
    a5: 16.76737,
    a6: 10.42296,
    b3: 122.25,
    b4: 38.97281,
    b5: 25.98187,
    b6: 16.76737,
  },
  {
    maesu: 6630,
    b2: 163,
    a2: 146.7,
    a3: 39.06486,
    a4: 25.94268,
    a5: 16.74208,
    a6: 10.40724,
    b3: 122.25,
    b4: 39.06486,
    b5: 25.94268,
    b6: 16.74208,
  },
  {
    maesu: 6640,
    b2: 163,
    a2: 146.7,
    a3: 39.00602,
    a4: 26.05422,
    a5: 16.71687,
    a6: 10.69277,
    b3: 122.25,
    b4: 39.00602,
    b5: 26.05422,
    b6: 16.71687,
  },
  {
    maesu: 6650,
    b2: 163,
    a2: 146.7,
    a3: 38.94737,
    a4: 26.01504,
    a5: 16.69173,
    a6: 10.67669,
    b3: 122.25,
    b4: 38.94737,
    b5: 26.01504,
    b6: 16.69173,
  },
  {
    maesu: 6660,
    b2: 163,
    a2: 146.7,
    a3: 39.03904,
    a4: 25.97598,
    a5: 16.81682,
    a6: 10.66066,
    b3: 122.25,
    b4: 39.03904,
    b5: 25.97598,
    b6: 16.81682,
  },
  {
    maesu: 6670,
    b2: 163,
    a2: 146.7,
    a3: 38.98051,
    a4: 25.93703,
    a5: 16.7916,
    a6: 10.64468,
    b3: 122.25,
    b4: 38.98051,
    b5: 25.93703,
    b6: 16.7916,
  },
  {
    maesu: 6680,
    b2: 163,
    a2: 146.7,
    a3: 39.07186,
    a4: 26.0479,
    a5: 16.76647,
    a6: 10.62874,
    b3: 122.25,
    b4: 39.07186,
    b5: 26.0479,
    b6: 16.76647,
  },
  {
    maesu: 6690,
    b2: 163,
    a2: 146.7,
    a3: 39.01345,
    a4: 26.00897,
    a5: 16.74141,
    a6: 10.61286,
    b3: 122.25,
    b4: 39.01345,
    b5: 26.00897,
    b6: 16.74141,
  },
  {
    maesu: 6700,
    b2: 163,
    a2: 146.7,
    a3: 38.95522,
    a4: 25.97015,
    a5: 16.71642,
    a6: 10.59701,
    b3: 122.25,
    b4: 38.95522,
    b5: 25.97015,
    b6: 16.71642,
  },
  {
    maesu: 6710,
    b2: 163,
    a2: 146.7,
    a3: 39.0462,
    a4: 25.93145,
    a5: 16.69151,
    a6: 10.58122,
    b3: 122.25,
    b4: 39.0462,
    b5: 25.93145,
    b6: 16.69151,
  },
  {
    maesu: 6720,
    b2: 163,
    a2: 146.7,
    a3: 38.9881,
    a4: 26.04167,
    a5: 16.81548,
    a6: 10.56548,
    b3: 122.25,
    b4: 38.9881,
    b5: 26.04167,
    b6: 16.81548,
  },
  {
    maesu: 6730,
    b2: 163,
    a2: 146.7,
    a3: 38.93016,
    a4: 26.00297,
    a5: 16.79049,
    a6: 10.54978,
    b3: 122.25,
    b4: 38.93016,
    b5: 26.00297,
    b6: 16.79049,
  },
  {
    maesu: 6740,
    b2: 163,
    a2: 146.7,
    a3: 39.02077,
    a4: 25.96439,
    a5: 16.76558,
    a6: 10.53412,
    b3: 122.25,
    b4: 39.02077,
    b5: 25.96439,
    b6: 16.76558,
  },
  {
    maesu: 6750,
    b2: 163,
    a2: 146.7,
    a3: 38.96296,
    a4: 26.07407,
    a5: 16.74074,
    a6: 10.51852,
    b3: 122.25,
    b4: 38.96296,
    b5: 26.07407,
    b6: 16.74074,
  },
  {
    maesu: 6760,
    b2: 163,
    a2: 146.7,
    a3: 39.05325,
    a4: 26.0355,
    a5: 16.71598,
    a6: 10.50296,
    b3: 122.25,
    b4: 39.05325,
    b5: 26.0355,
    b6: 16.71598,
  },
  {
    maesu: 6770,
    b2: 163,
    a2: 146.7,
    a3: 38.99557,
    a4: 25.99705,
    a5: 16.69129,
    a6: 10.48744,
    b3: 122.25,
    b4: 38.99557,
    b5: 25.99705,
    b6: 16.69129,
  },
  {
    maesu: 6780,
    b2: 163,
    a2: 146.7,
    a3: 38.93805,
    a4: 25.9587,
    a5: 16.81416,
    a6: 10.47198,
    b3: 122.25,
    b4: 38.93805,
    b5: 25.9587,
    b6: 16.81416,
  },
  {
    maesu: 6790,
    b2: 163,
    a2: 146.7,
    a3: 39.02798,
    a4: 26.06775,
    a5: 16.7894,
    a6: 10.45655,
    b3: 122.25,
    b4: 39.02798,
    b5: 26.06775,
    b6: 16.7894,
  },
  {
    maesu: 6800,
    b2: 163,
    a2: 146.7,
    a3: 38.97059,
    a4: 26.02941,
    a5: 16.76471,
    a6: 10.44118,
    b3: 122.25,
    b4: 38.97059,
    b5: 26.02941,
    b6: 16.76471,
  },
  {
    maesu: 6810,
    b2: 163,
    a2: 146.7,
    a3: 39.06021,
    a4: 25.99119,
    a5: 16.74009,
    a6: 10.42584,
    b3: 122.25,
    b4: 39.06021,
    b5: 25.99119,
    b6: 16.74009,
  },
  {
    maesu: 6820,
    b2: 163,
    a2: 146.7,
    a3: 39.00293,
    a4: 25.95308,
    a5: 16.71554,
    a6: 10.41056,
    b3: 122.25,
    b4: 39.00293,
    b5: 25.95308,
    b6: 16.71554,
  },
  {
    maesu: 6830,
    b2: 163,
    a2: 146.7,
    a3: 38.94583,
    a4: 26.06149,
    a5: 16.69107,
    a6: 10.39531,
    b3: 122.25,
    b4: 38.94583,
    b5: 26.06149,
    b6: 16.69107,
  },
  {
    maesu: 6840,
    b2: 163,
    a2: 146.7,
    a3: 39.03509,
    a4: 26.02339,
    a5: 16.81287,
    a6: 10.38012,
    b3: 122.25,
    b4: 39.03509,
    b5: 26.02339,
    b6: 16.81287,
  },
  {
    maesu: 6850,
    b2: 163,
    a2: 146.7,
    a3: 38.9781,
    a4: 25.9854,
    a5: 16.78832,
    a6: 10.36496,
    b3: 122.25,
    b4: 38.9781,
    b5: 25.9854,
    b6: 16.78832,
  },
  {
    maesu: 6860,
    b2: 163,
    a2: 146.7,
    a3: 39.06706,
    a4: 25.94752,
    a5: 16.76385,
    a6: 10.34985,
    b3: 122.25,
    b4: 39.06706,
    b5: 25.94752,
    b6: 16.76385,
  },
  {
    maesu: 6870,
    b2: 163,
    a2: 146.7,
    a3: 39.01019,
    a4: 26.05531,
    a5: 16.73945,
    a6: 10.33479,
    b3: 122.25,
    b4: 39.01019,
    b5: 26.05531,
    b6: 16.73945,
  },
  {
    maesu: 6880,
    b2: 163,
    a2: 146.7,
    a3: 38.95349,
    a4: 26.01744,
    a5: 16.71512,
    a6: 10.31977,
    b3: 122.25,
    b4: 38.95349,
    b5: 26.01744,
    b6: 16.71512,
  },
  {
    maesu: 6890,
    b2: 163,
    a2: 146.7,
    a3: 39.04209,
    a4: 25.97968,
    a5: 16.69086,
    a6: 10.44993,
    b3: 122.25,
    b4: 39.04209,
    b5: 25.97968,
    b6: 16.69086,
  },
  {
    maesu: 6900,
    b2: 163,
    a2: 146.7,
    a3: 38.98551,
    a4: 25.94203,
    a5: 16.81159,
    a6: 10.43478,
    b3: 122.25,
    b4: 38.98551,
    b5: 25.94203,
    b6: 16.81159,
  },
  {
    maesu: 6910,
    b2: 163,
    a2: 146.7,
    a3: 38.92909,
    a4: 26.0492,
    a5: 16.78726,
    a6: 10.41968,
    b3: 122.25,
    b4: 38.92909,
    b5: 26.0492,
    b6: 16.78726,
  },
  {
    maesu: 6920,
    b2: 163,
    a2: 146.7,
    a3: 39.01734,
    a4: 26.01156,
    a5: 16.76301,
    a6: 10.40462,
    b3: 122.25,
    b4: 39.01734,
    b5: 26.01156,
    b6: 16.76301,
  },
  {
    maesu: 6930,
    b2: 163,
    a2: 146.7,
    a3: 38.96104,
    a4: 25.97403,
    a5: 16.73882,
    a6: 10.38961,
    b3: 122.25,
    b4: 38.96104,
    b5: 25.97403,
    b6: 16.73882,
  },
  {
    maesu: 6940,
    b2: 163,
    a2: 146.7,
    a3: 39.04899,
    a4: 25.9366,
    a5: 16.7147,
    a6: 10.37464,
    b3: 122.25,
    b4: 39.04899,
    b5: 25.9366,
    b6: 16.7147,
  },
  {
    maesu: 6950,
    b2: 163,
    a2: 146.7,
    a3: 38.99281,
    a4: 26.04317,
    a5: 16.69065,
    a6: 10.35971,
    b3: 122.25,
    b4: 38.99281,
    b5: 26.04317,
    b6: 16.69065,
  },
  {
    maesu: 6960,
    b2: 163,
    a2: 146.7,
    a3: 38.93678,
    a4: 26.00575,
    a5: 16.81034,
    a6: 10.34483,
    b3: 122.25,
    b4: 38.93678,
    b5: 26.00575,
    b6: 16.81034,
  },
  {
    maesu: 6970,
    b2: 163,
    a2: 146.7,
    a3: 39.02439,
    a4: 25.96844,
    a5: 16.78623,
    a6: 10.32999,
    b3: 122.25,
    b4: 39.02439,
    b5: 25.96844,
    b6: 16.78623,
  },
  {
    maesu: 6980,
    b2: 163,
    a2: 146.7,
    a3: 38.96848,
    a4: 25.93123,
    a5: 16.76218,
    a6: 10.31519,
    b3: 122.25,
    b4: 38.96848,
    b5: 25.93123,
    b6: 16.76218,
  },
  {
    maesu: 6990,
    b2: 163,
    a2: 146.7,
    a3: 39.05579,
    a4: 26.0372,
    a5: 16.7382,
    a6: 10.30043,
    b3: 122.25,
    b4: 39.05579,
    b5: 26.0372,
    b6: 16.7382,
  },
  {
    maesu: 7000,
    b2: 163,
    a2: 146.7,
    a3: 39,
    a4: 26,
    a5: 16.71429,
    a6: 10.28571,
    b3: 122.25,
    b4: 39,
    b5: 26,
    b6: 16.71429,
  },
  {
    maesu: 7010,
    b2: 163,
    a2: 146.7,
    a3: 38.94437,
    a4: 25.96291,
    a5: 16.69044,
    a6: 10.27104,
    b3: 122.25,
    b4: 38.94437,
    b5: 25.96291,
    b6: 16.69044,
  },
  {
    maesu: 7020,
    b2: 163,
    a2: 146.7,
    a3: 39.03134,
    a4: 26.06838,
    a5: 16.80912,
    a6: 10.25641,
    b3: 122.25,
    b4: 39.03134,
    b5: 26.06838,
    b6: 16.80912,
  },
  {
    maesu: 7030,
    b2: 163,
    a2: 146.7,
    a3: 38.97582,
    a4: 26.03129,
    a5: 16.78521,
    a6: 10.24182,
    b3: 122.25,
    b4: 38.97582,
    b5: 26.03129,
    b6: 16.78521,
  },
  {
    maesu: 7040,
    b2: 163,
    a2: 146.7,
    a3: 39.0625,
    a4: 25.99432,
    a5: 16.76136,
    a6: 10.22727,
    b3: 122.25,
    b4: 39.0625,
    b5: 25.99432,
    b6: 16.76136,
  },
  {
    maesu: 7050,
    b2: 163,
    a2: 146.7,
    a3: 39.00709,
    a4: 25.95745,
    a5: 16.73759,
    a6: 10.21277,
    b3: 122.25,
    b4: 39.00709,
    b5: 25.95745,
    b6: 16.73759,
  },
  {
    maesu: 7060,
    b2: 163,
    a2: 146.7,
    a3: 38.95184,
    a4: 26.06232,
    a5: 16.71388,
    a6: 10.1983,
    b3: 122.25,
    b4: 38.95184,
    b5: 26.06232,
    b6: 16.71388,
  },
  {
    maesu: 7070,
    b2: 163,
    a2: 146.7,
    a3: 39.03819,
    a4: 26.02546,
    a5: 16.69024,
    a6: 10.18388,
    b3: 122.25,
    b4: 39.03819,
    b5: 26.02546,
    b6: 16.69024,
  },
  {
    maesu: 7080,
    b2: 163,
    a2: 146.7,
    a3: 38.98305,
    a4: 25.9887,
    a5: 16.80791,
    a6: 10.16949,
    b3: 122.25,
    b4: 38.98305,
    b5: 25.9887,
    b6: 16.80791,
  },
  {
    maesu: 7090,
    b2: 163,
    a2: 146.7,
    a3: 39.06911,
    a4: 25.95205,
    a5: 16.7842,
    a6: 10.15515,
    b3: 122.25,
    b4: 39.06911,
    b5: 25.95205,
    b6: 16.7842,
  },
  {
    maesu: 7100,
    b2: 163,
    a2: 146.7,
    a3: 39.01408,
    a4: 26.05634,
    a5: 16.76056,
    a6: 10.14085,
    b3: 122.25,
    b4: 39.01408,
    b5: 26.05634,
    b6: 16.76056,
  },
  {
    maesu: 7110,
    b2: 163,
    a2: 146.7,
    a3: 38.95921,
    a4: 26.01969,
    a5: 16.73699,
    a6: 10.12658,
    b3: 122.25,
    b4: 38.95921,
    b5: 26.01969,
    b6: 16.73699,
  },
  {
    maesu: 7120,
    b2: 163,
    a2: 146.7,
    a3: 39.04494,
    a4: 25.98315,
    a5: 16.71348,
    a6: 10.11236,
    b3: 122.25,
    b4: 39.04494,
    b5: 25.98315,
    b6: 16.71348,
  },
  {
    maesu: 7130,
    b2: 163,
    a2: 146.7,
    a3: 38.99018,
    a4: 25.9467,
    a5: 16.69004,
    a6: 10.09818,
    b3: 122.25,
    b4: 38.99018,
    b5: 25.9467,
    b6: 16.69004,
  },
  {
    maesu: 7140,
    b2: 163,
    a2: 146.7,
    a3: 38.93557,
    a4: 26.05042,
    a5: 16.80672,
    a6: 10.08403,
    b3: 122.25,
    b4: 38.93557,
    b5: 26.05042,
    b6: 16.80672,
  },
  {
    maesu: 7150,
    b2: 163,
    a2: 146.7,
    a3: 39.02098,
    a4: 26.01399,
    a5: 16.78322,
    a6: 10.06993,
    b3: 122.25,
    b4: 39.02098,
    b5: 26.01399,
    b6: 16.78322,
  },
  {
    maesu: 7160,
    b2: 163,
    a2: 146.7,
    a3: 38.96648,
    a4: 25.97765,
    a5: 16.75978,
    a6: 10.05587,
    b3: 122.25,
    b4: 38.96648,
    b5: 25.97765,
    b6: 16.75978,
  },
  {
    maesu: 7170,
    b2: 163,
    a2: 146.7,
    a3: 39.0516,
    a4: 25.94142,
    a5: 16.7364,
    a6: 10.04184,
    b3: 122.25,
    b4: 39.0516,
    b5: 25.94142,
    b6: 16.7364,
  },
  {
    maesu: 7180,
    b2: 163,
    a2: 146.7,
    a3: 38.99721,
    a4: 26.04457,
    a5: 16.71309,
    a6: 10.02786,
    b3: 122.25,
    b4: 38.99721,
    b5: 26.04457,
    b6: 16.71309,
  },
  {
    maesu: 7190,
    b2: 163,
    a2: 146.7,
    a3: 38.94298,
    a4: 26.00834,
    a5: 16.68985,
    a6: 10.01391,
    b3: 122.25,
    b4: 38.94298,
    b5: 26.00834,
    b6: 16.68985,
  },
  {
    maesu: 7200,
    b2: 163,
    a2: 146.7,
    a3: 39.02778,
    a4: 25.97222,
    a5: 16.80556,
    a6: 10,
    b3: 122.25,
    b4: 39.02778,
    b5: 25.97222,
    b6: 16.80556,
  },
  {
    maesu: 7210,
    b2: 163,
    a2: 146.7,
    a3: 38.97365,
    a4: 25.9362,
    a5: 16.78225,
    a6: 9.98613,
    b3: 122.25,
    b4: 38.97365,
    b5: 25.9362,
    b6: 16.78225,
  },
  {
    maesu: 7220,
    b2: 163,
    a2: 146.7,
    a3: 39.05817,
    a4: 26.03878,
    a5: 16.759,
    a6: 9.9723,
    b3: 122.25,
    b4: 39.05817,
    b5: 26.03878,
    b6: 16.759,
  },
  {
    maesu: 7230,
    b2: 163,
    a2: 146.7,
    a3: 39.00415,
    a4: 26.00277,
    a5: 16.73582,
    a6: 9.95851,
    b3: 122.25,
    b4: 39.00415,
    b5: 26.00277,
    b6: 16.73582,
  },
  {
    maesu: 7240,
    b2: 163,
    a2: 146.7,
    a3: 38.95028,
    a4: 25.96685,
    a5: 16.71271,
    a6: 9.94475,
    b3: 122.25,
    b4: 38.95028,
    b5: 25.96685,
    b6: 16.71271,
  },
  {
    maesu: 7250,
    b2: 163,
    a2: 146.7,
    a3: 39.03448,
    a4: 26.06897,
    a5: 16.68966,
    a6: 9.93103,
    b3: 122.25,
    b4: 39.03448,
    b5: 26.06897,
    b6: 16.68966,
  },
  {
    maesu: 7260,
    b2: 163,
    a2: 146.7,
    a3: 38.98072,
    a4: 26.03306,
    a5: 16.80441,
    a6: 9.91736,
    b3: 122.25,
    b4: 38.98072,
    b5: 26.03306,
    b6: 16.80441,
  },
  {
    maesu: 7270,
    b2: 163,
    a2: 146.7,
    a3: 39.06465,
    a4: 25.99725,
    a5: 16.78129,
    a6: 9.90371,
    b3: 122.25,
    b4: 39.06465,
    b5: 25.99725,
    b6: 16.78129,
  },
  {
    maesu: 7280,
    b2: 163,
    a2: 146.7,
    a3: 39.01099,
    a4: 25.96154,
    a5: 16.75824,
    a6: 9.89011,
    b3: 122.25,
    b4: 39.01099,
    b5: 25.96154,
    b6: 16.75824,
  },
  {
    maesu: 7290,
    b2: 163,
    a2: 146.7,
    a3: 38.95748,
    a4: 26.0631,
    a5: 16.73525,
    a6: 9.87654,
    b3: 122.25,
    b4: 38.95748,
    b5: 26.0631,
    b6: 16.73525,
  },
  {
    maesu: 7300,
    b2: 163,
    a2: 146.7,
    a3: 39.0411,
    a4: 26.0274,
    a5: 16.71233,
    a6: 9.86301,
    b3: 122.25,
    b4: 39.0411,
    b5: 26.0274,
    b6: 16.71233,
  },
  {
    maesu: 7310,
    b2: 163,
    a2: 146.7,
    a3: 38.98769,
    a4: 25.99179,
    a5: 16.68947,
    a6: 9.84952,
    b3: 122.25,
    b4: 38.98769,
    b5: 25.99179,
    b6: 16.68947,
  },
  {
    maesu: 7320,
    b2: 163,
    a2: 146.7,
    a3: 38.93443,
    a4: 25.95628,
    a5: 16.80328,
    a6: 9.83607,
    b3: 122.25,
    b4: 38.93443,
    b5: 25.95628,
    b6: 16.80328,
  },
  {
    maesu: 7330,
    b2: 163,
    a2: 146.7,
    a3: 39.01774,
    a4: 26.0573,
    a5: 16.78035,
    a6: 9.82265,
    b3: 122.25,
    b4: 39.01774,
    b5: 26.0573,
    b6: 16.78035,
  },
  {
    maesu: 7340,
    b2: 163,
    a2: 146.7,
    a3: 38.96458,
    a4: 26.0218,
    a5: 16.75749,
    a6: 9.80926,
    b3: 122.25,
    b4: 38.96458,
    b5: 26.0218,
    b6: 16.75749,
  },
  {
    maesu: 7350,
    b2: 163,
    a2: 146.7,
    a3: 39.04762,
    a4: 25.98639,
    a5: 16.73469,
    a6: 9.79592,
    b3: 122.25,
    b4: 39.04762,
    b5: 25.98639,
    b6: 16.73469,
  },
  {
    maesu: 7360,
    b2: 163,
    a2: 146.7,
    a3: 38.99457,
    a4: 25.95109,
    a5: 16.71196,
    a6: 9.78261,
    b3: 122.25,
    b4: 38.99457,
    b5: 25.95109,
    b6: 16.71196,
  },
  {
    maesu: 7370,
    b2: 163,
    a2: 146.7,
    a3: 38.94166,
    a4: 26.05156,
    a5: 16.68928,
    a6: 9.76934,
    b3: 122.25,
    b4: 38.94166,
    b5: 26.05156,
    b6: 16.68928,
  },
  {
    maesu: 7380,
    b2: 163,
    a2: 146.7,
    a3: 39.02439,
    a4: 26.01626,
    a5: 16.80217,
    a6: 9.7561,
    b3: 122.25,
    b4: 39.02439,
    b5: 26.01626,
    b6: 16.80217,
  },
  {
    maesu: 7390,
    b2: 163,
    a2: 146.7,
    a3: 38.97158,
    a4: 25.98106,
    a5: 16.77943,
    a6: 9.7429,
    b3: 122.25,
    b4: 38.97158,
    b5: 25.98106,
    b6: 16.77943,
  },
  {
    maesu: 7400,
    b2: 163,
    a2: 146.7,
    a3: 39.05405,
    a4: 25.94595,
    a5: 16.75676,
    a6: 9.72973,
    b3: 122.25,
    b4: 39.05405,
    b5: 25.94595,
    b6: 16.75676,
  },
  {
    maesu: 7410,
    b2: 163,
    a2: 146.7,
    a3: 39.00135,
    a4: 26.04588,
    a5: 16.73414,
    a6: 9.7166,
    b3: 122.25,
    b4: 39.00135,
    b5: 26.04588,
    b6: 16.73414,
  },
  {
    maesu: 7420,
    b2: 163,
    a2: 146.7,
    a3: 38.94879,
    a4: 26.01078,
    a5: 16.71159,
    a6: 9.7035,
    b3: 122.25,
    b4: 38.94879,
    b5: 26.01078,
    b6: 16.71159,
  },
  {
    maesu: 7430,
    b2: 163,
    a2: 146.7,
    a3: 39.03096,
    a4: 25.97577,
    a5: 16.6891,
    a6: 9.69044,
    b3: 122.25,
    b4: 39.03096,
    b5: 25.97577,
    b6: 16.6891,
  },
  {
    maesu: 7440,
    b2: 163,
    a2: 146.7,
    a3: 38.97849,
    a4: 25.94086,
    a5: 16.80108,
    a6: 9.67742,
    b3: 122.25,
    b4: 38.97849,
    b5: 25.94086,
    b6: 16.80108,
  },
  {
    maesu: 7450,
    b2: 163,
    a2: 146.7,
    a3: 39.0604,
    a4: 26.04027,
    a5: 16.77852,
    a6: 9.66443,
    b3: 122.25,
    b4: 39.0604,
    b5: 26.04027,
    b6: 16.77852,
  },
  {
    maesu: 7460,
    b2: 163,
    a2: 146.7,
    a3: 39.00804,
    a4: 26.00536,
    a5: 16.75603,
    a6: 9.65147,
    b3: 122.25,
    b4: 39.00804,
    b5: 26.00536,
    b6: 16.75603,
  },
  {
    maesu: 7470,
    b2: 163,
    a2: 146.7,
    a3: 38.95582,
    a4: 25.97055,
    a5: 16.7336,
    a6: 9.63855,
    b3: 122.25,
    b4: 38.95582,
    b5: 25.97055,
    b6: 16.7336,
  },
  {
    maesu: 7480,
    b2: 163,
    a2: 146.7,
    a3: 39.03743,
    a4: 25.93583,
    a5: 16.71123,
    a6: 9.62567,
    b3: 122.25,
    b4: 39.03743,
    b5: 25.93583,
    b6: 16.71123,
  },
  {
    maesu: 7490,
    b2: 163,
    a2: 146.7,
    a3: 38.98531,
    a4: 26.03471,
    a5: 16.68892,
    a6: 9.61282,
    b3: 122.25,
    b4: 38.98531,
    b5: 26.03471,
    b6: 16.68892,
  },
  {
    maesu: 7500,
    b2: 163,
    a2: 146.7,
    a3: 39.06667,
    a4: 26,
    a5: 16.8,
    a6: 9.86667,
    b3: 122.25,
    b4: 39.06667,
    b5: 26,
    b6: 16.8,
  },
  {
    maesu: 7510,
    b2: 163,
    a2: 146.7,
    a3: 39.01465,
    a4: 25.96538,
    a5: 16.77763,
    a6: 9.85353,
    b3: 122.25,
    b4: 39.01465,
    b5: 25.96538,
    b6: 16.77763,
  },
  {
    maesu: 7520,
    b2: 163,
    a2: 146.7,
    a3: 38.96277,
    a4: 26.06383,
    a5: 16.75532,
    a6: 9.84043,
    b3: 122.25,
    b4: 38.96277,
    b5: 26.06383,
    b6: 16.75532,
  },
  {
    maesu: 7530,
    b2: 163,
    a2: 146.7,
    a3: 39.04382,
    a4: 26.02922,
    a5: 16.73307,
    a6: 9.82736,
    b3: 122.25,
    b4: 39.04382,
    b5: 26.02922,
    b6: 16.73307,
  },
  {
    maesu: 7540,
    b2: 163,
    a2: 146.7,
    a3: 38.99204,
    a4: 25.99469,
    a5: 16.71088,
    a6: 9.81432,
    b3: 122.25,
    b4: 38.99204,
    b5: 25.99469,
    b6: 16.71088,
  },
  {
    maesu: 7550,
    b2: 163,
    a2: 146.7,
    a3: 38.9404,
    a4: 25.96026,
    a5: 16.68874,
    a6: 9.80132,
    b3: 122.25,
    b4: 38.9404,
    b5: 25.96026,
    b6: 16.68874,
  },
  {
    maesu: 7560,
    b2: 163,
    a2: 146.7,
    a3: 39.02116,
    a4: 26.0582,
    a5: 16.79894,
    a6: 9.78836,
    b3: 122.25,
    b4: 39.02116,
    b5: 26.0582,
    b6: 16.79894,
  },
  {
    maesu: 7570,
    b2: 163,
    a2: 146.7,
    a3: 38.96962,
    a4: 26.02378,
    a5: 16.77675,
    a6: 9.77543,
    b3: 122.25,
    b4: 38.96962,
    b5: 26.02378,
    b6: 16.77675,
  },
  {
    maesu: 7580,
    b2: 163,
    a2: 146.7,
    a3: 39.05013,
    a4: 25.98945,
    a5: 16.75462,
    a6: 9.76253,
    b3: 122.25,
    b4: 39.05013,
    b5: 25.98945,
    b6: 16.75462,
  },
  {
    maesu: 7590,
    b2: 163,
    a2: 146.7,
    a3: 38.99868,
    a4: 25.9552,
    a5: 16.73254,
    a6: 9.74967,
    b3: 122.25,
    b4: 38.99868,
    b5: 25.9552,
    b6: 16.73254,
  },
  {
    maesu: 7600,
    b2: 163,
    a2: 146.7,
    a3: 38.94737,
    a4: 26.05263,
    a5: 16.71053,
    a6: 9.73684,
    b3: 122.25,
    b4: 38.94737,
    b5: 26.05263,
    b6: 16.71053,
  },
  {
    maesu: 7610,
    b2: 163,
    a2: 146.7,
    a3: 39.0276,
    a4: 26.0184,
    a5: 16.68857,
    a6: 9.72405,
    b3: 122.25,
    b4: 39.0276,
    b5: 26.0184,
    b6: 16.68857,
  },
  {
    maesu: 7620,
    b2: 163,
    a2: 146.7,
    a3: 38.97638,
    a4: 25.98425,
    a5: 16.7979,
    a6: 9.71129,
    b3: 122.25,
    b4: 38.97638,
    b5: 25.98425,
    b6: 16.7979,
  },
  {
    maesu: 7630,
    b2: 163,
    a2: 146.7,
    a3: 39.05636,
    a4: 25.9502,
    a5: 16.77588,
    a6: 9.69856,
    b3: 122.25,
    b4: 39.05636,
    b5: 25.9502,
    b6: 16.77588,
  },
  {
    maesu: 7640,
    b2: 163,
    a2: 146.7,
    a3: 39.00524,
    a4: 26.04712,
    a5: 16.75393,
    a6: 9.68586,
    b3: 122.25,
    b4: 39.00524,
    b5: 26.04712,
    b6: 16.75393,
  },
  {
    maesu: 7650,
    b2: 163,
    a2: 146.7,
    a3: 38.95425,
    a4: 26.01307,
    a5: 16.73203,
    a6: 9.6732,
    b3: 122.25,
    b4: 38.95425,
    b5: 26.01307,
    b6: 16.73203,
  },
  {
    maesu: 7660,
    b2: 163,
    a2: 146.7,
    a3: 39.03394,
    a4: 25.97911,
    a5: 16.71018,
    a6: 9.79112,
    b3: 122.25,
    b4: 39.03394,
    b5: 25.97911,
    b6: 16.71018,
  },
  {
    maesu: 7670,
    b2: 163,
    a2: 146.7,
    a3: 38.98305,
    a4: 25.94524,
    a5: 16.6884,
    a6: 9.77836,
    b3: 122.25,
    b4: 38.98305,
    b5: 25.94524,
    b6: 16.6884,
  },
  {
    maesu: 7680,
    b2: 163,
    a2: 146.7,
    a3: 39.0625,
    a4: 26.04167,
    a5: 16.79688,
    a6: 9.76562,
    b3: 122.25,
    b4: 39.0625,
    b5: 26.04167,
    b6: 16.79688,
  },
  {
    maesu: 7690,
    b2: 163,
    a2: 146.7,
    a3: 39.0117,
    a4: 26.0078,
    a5: 16.77503,
    a6: 9.75293,
    b3: 122.25,
    b4: 39.0117,
    b5: 26.0078,
    b6: 16.77503,
  },
  {
    maesu: 7700,
    b2: 163,
    a2: 146.7,
    a3: 38.96104,
    a4: 25.97403,
    a5: 16.75325,
    a6: 9.74026,
    b3: 122.25,
    b4: 38.96104,
    b5: 25.97403,
    b6: 16.75325,
  },
  {
    maesu: 7710,
    b2: 163,
    a2: 146.7,
    a3: 39.04021,
    a4: 25.94034,
    a5: 16.73152,
    a6: 9.72763,
    b3: 122.25,
    b4: 39.04021,
    b5: 25.94034,
    b6: 16.73152,
  },
  {
    maesu: 7720,
    b2: 163,
    a2: 146.7,
    a3: 38.98964,
    a4: 26.03627,
    a5: 16.70984,
    a6: 9.71503,
    b3: 122.25,
    b4: 38.98964,
    b5: 26.03627,
    b6: 16.70984,
  },
  {
    maesu: 7730,
    b2: 163,
    a2: 146.7,
    a3: 38.9392,
    a4: 26.00259,
    a5: 16.68823,
    a6: 9.70246,
    b3: 122.25,
    b4: 38.9392,
    b5: 26.00259,
    b6: 16.68823,
  },
  {
    maesu: 7740,
    b2: 163,
    a2: 146.7,
    a3: 39.01809,
    a4: 25.96899,
    a5: 16.79587,
    a6: 9.68992,
    b3: 122.25,
    b4: 39.01809,
    b5: 25.96899,
    b6: 16.79587,
  },
  {
    maesu: 7750,
    b2: 163,
    a2: 146.7,
    a3: 38.96774,
    a4: 26.06452,
    a5: 16.77419,
    a6: 9.67742,
    b3: 122.25,
    b4: 38.96774,
    b5: 26.06452,
    b6: 16.77419,
  },
  {
    maesu: 7760,
    b2: 163,
    a2: 146.7,
    a3: 39.04639,
    a4: 26.03093,
    a5: 16.75258,
    a6: 9.66495,
    b3: 122.25,
    b4: 39.04639,
    b5: 26.03093,
    b6: 16.75258,
  },
  {
    maesu: 7770,
    b2: 163,
    a2: 146.7,
    a3: 38.99614,
    a4: 25.99743,
    a5: 16.73102,
    a6: 9.65251,
    b3: 122.25,
    b4: 38.99614,
    b5: 25.99743,
    b6: 16.73102,
  },
  {
    maesu: 7780,
    b2: 163,
    a2: 146.7,
    a3: 38.94602,
    a4: 25.96401,
    a5: 16.70951,
    a6: 9.6401,
    b3: 122.25,
    b4: 38.94602,
    b5: 25.96401,
    b6: 16.70951,
  },
  {
    maesu: 7790,
    b2: 163,
    a2: 146.7,
    a3: 39.02439,
    a4: 26.05905,
    a5: 16.68806,
    a6: 9.62773,
    b3: 122.25,
    b4: 39.02439,
    b5: 26.05905,
    b6: 16.68806,
  },
  {
    maesu: 7800,
    b2: 163,
    a2: 146.7,
    a3: 38.97436,
    a4: 26.02564,
    a5: 16.79487,
    a6: 9.61538,
    b3: 122.25,
    b4: 38.97436,
    b5: 26.02564,
    b6: 16.79487,
  },
  {
    maesu: 7810,
    b2: 163,
    a2: 146.7,
    a3: 39.0525,
    a4: 25.99232,
    a5: 16.77337,
    a6: 9.60307,
    b3: 122.25,
    b4: 39.0525,
    b5: 25.99232,
    b6: 16.77337,
  },
  {
    maesu: 7820,
    b2: 163,
    a2: 146.7,
    a3: 39.00256,
    a4: 25.95908,
    a5: 16.75192,
    a6: 9.59079,
    b3: 122.25,
    b4: 39.00256,
    b5: 25.95908,
    b6: 16.75192,
  },
  {
    maesu: 7830,
    b2: 163,
    a2: 146.7,
    a3: 38.95275,
    a4: 26.05364,
    a5: 16.73052,
    a6: 9.57854,
    b3: 122.25,
    b4: 38.95275,
    b5: 26.05364,
    b6: 16.73052,
  },
  {
    maesu: 7840,
    b2: 163,
    a2: 146.7,
    a3: 39.03061,
    a4: 26.02041,
    a5: 16.70918,
    a6: 9.56633,
    b3: 122.25,
    b4: 39.03061,
    b5: 26.02041,
    b6: 16.70918,
  },
  {
    maesu: 7850,
    b2: 163,
    a2: 146.7,
    a3: 38.98089,
    a4: 25.98726,
    a5: 16.6879,
    a6: 9.55414,
    b3: 122.25,
    b4: 38.98089,
    b5: 25.98726,
    b6: 16.6879,
  },
  {
    maesu: 7860,
    b2: 163,
    a2: 146.7,
    a3: 39.05852,
    a4: 25.9542,
    a5: 16.79389,
    a6: 9.54198,
    b3: 122.25,
    b4: 39.05852,
    b5: 25.9542,
    b6: 16.79389,
  },
  {
    maesu: 7870,
    b2: 163,
    a2: 146.7,
    a3: 39.00889,
    a4: 26.04828,
    a5: 16.77255,
    a6: 9.52986,
    b3: 122.25,
    b4: 39.00889,
    b5: 26.04828,
    b6: 16.77255,
  },
  {
    maesu: 7880,
    b2: 163,
    a2: 146.7,
    a3: 38.95939,
    a4: 26.01523,
    a5: 16.75127,
    a6: 9.51777,
    b3: 122.25,
    b4: 38.95939,
    b5: 26.01523,
    b6: 16.75127,
  },
  {
    maesu: 7890,
    b2: 163,
    a2: 146.7,
    a3: 39.03676,
    a4: 25.98226,
    a5: 16.73004,
    a6: 9.5057,
    b3: 122.25,
    b4: 39.03676,
    b5: 25.98226,
    b6: 16.73004,
  },
  {
    maesu: 7900,
    b2: 163,
    a2: 146.7,
    a3: 38.98734,
    a4: 25.94937,
    a5: 16.70886,
    a6: 9.49367,
    b3: 122.25,
    b4: 38.98734,
    b5: 25.94937,
    b6: 16.70886,
  },
  {
    maesu: 7910,
    b2: 163,
    a2: 146.7,
    a3: 38.93805,
    a4: 26.04298,
    a5: 16.68774,
    a6: 9.48167,
    b3: 122.25,
    b4: 38.93805,
    b5: 26.04298,
    b6: 16.68774,
  },
  {
    maesu: 7920,
    b2: 163,
    a2: 146.7,
    a3: 39.01515,
    a4: 26.0101,
    a5: 16.79293,
    a6: 9.4697,
    b3: 122.25,
    b4: 39.01515,
    b5: 26.0101,
    b6: 16.79293,
  },
  {
    maesu: 7930,
    b2: 163,
    a2: 146.7,
    a3: 38.96595,
    a4: 25.9773,
    a5: 16.77175,
    a6: 9.45776,
    b3: 122.25,
    b4: 38.96595,
    b5: 25.9773,
    b6: 16.77175,
  },
  {
    maesu: 7940,
    b2: 163,
    a2: 146.7,
    a3: 39.04282,
    a4: 25.94458,
    a5: 16.75063,
    a6: 9.44584,
    b3: 122.25,
    b4: 39.04282,
    b5: 25.94458,
    b6: 16.75063,
  },
  {
    maesu: 7950,
    b2: 163,
    a2: 146.7,
    a3: 38.99371,
    a4: 26.03774,
    a5: 16.72956,
    a6: 9.43396,
    b3: 122.25,
    b4: 38.99371,
    b5: 26.03774,
    b6: 16.72956,
  },
  {
    maesu: 7960,
    b2: 163,
    a2: 146.7,
    a3: 38.94472,
    a4: 26.00503,
    a5: 16.70854,
    a6: 9.42211,
    b3: 122.25,
    b4: 38.94472,
    b5: 26.00503,
    b6: 16.70854,
  },
  {
    maesu: 7970,
    b2: 163,
    a2: 146.7,
    a3: 39.02133,
    a4: 25.9724,
    a5: 16.68758,
    a6: 9.41029,
    b3: 122.25,
    b4: 39.02133,
    b5: 25.9724,
    b6: 16.68758,
  },
  {
    maesu: 7980,
    b2: 163,
    a2: 146.7,
    a3: 38.97243,
    a4: 25.93985,
    a5: 16.79198,
    a6: 9.3985,
    b3: 122.25,
    b4: 38.97243,
    b5: 25.93985,
    b6: 16.79198,
  },
  {
    maesu: 7990,
    b2: 163,
    a2: 146.7,
    a3: 39.04881,
    a4: 26.03254,
    a5: 16.77096,
    a6: 9.38673,
    b3: 122.25,
    b4: 39.04881,
    b5: 26.03254,
    b6: 16.77096,
  },
  {
    maesu: 8000,
    b2: 163,
    a2: 146.7,
    a3: 39,
    a4: 26,
    a5: 16.75,
    a6: 9.375,
    b3: 122.25,
    b4: 39,
    b5: 26,
    b6: 16.75,
  },
];

//일반코팅
export const CoatingPrice = [
  {
    maesu: 1,
    dan_no: 3000,
    dan_yes: 3000,
    yang_no: 5000,
    yang_yes: 5000,
  },
  {
    maesu: 2,
    dan_no: 1500,
    dan_yes: 1500,
    yang_no: 2500,
    yang_yes: 2500,
  },
  {
    maesu: 3,
    dan_no: 1000,
    dan_yes: 1000,
    yang_no: 1666.667,
    yang_yes: 1666.667,
  },
  {
    maesu: 4,
    dan_no: 1000,
    dan_yes: 1000,
    yang_no: 1500,
    yang_yes: 1500,
  },
  {
    maesu: 5,
    dan_no: 800,
    dan_yes: 800,
    yang_no: 1400,
    yang_yes: 1400,
  },
  {
    maesu: 8,
    dan_no: 562.5,
    dan_yes: 562.5,
    yang_no: 1062.5,
    yang_yes: 1062.5,
  },
  {
    maesu: 10,
    dan_no: 500,
    dan_yes: 500,
    yang_no: 1000,
    yang_yes: 1000,
  },
  {
    maesu: 15,
    dan_no: 466.667,
    dan_yes: 466.667,
    yang_no: 800,
    yang_yes: 800,
  },
  {
    maesu: 20,
    dan_no: 350,
    dan_yes: 350,
    yang_no: 700,
    yang_yes: 700,
  },
  {
    maesu: 25,
    dan_no: 360,
    dan_yes: 360,
    yang_no: 640,
    yang_yes: 640,
  },
  {
    maesu: 30,
    dan_no: 300,
    dan_yes: 300,
    yang_no: 600,
    yang_yes: 600,
  },
  {
    maesu: 35,
    dan_no: 285.714,
    dan_yes: 285.714,
    yang_no: 542.857,
    yang_yes: 542.857,
  },
  {
    maesu: 40,
    dan_no: 275,
    dan_yes: 275,
    yang_no: 525,
    yang_yes: 525,
  },
  {
    maesu: 45,
    dan_no: 266.667,
    dan_yes: 266.667,
    yang_no: 488.889,
    yang_yes: 488.889,
  },
  {
    maesu: 50,
    dan_no: 240,
    dan_yes: 240,
    yang_no: 460,
    yang_yes: 460,
  },
  {
    maesu: 55,
    dan_no: 218.182,
    dan_yes: 218.182,
    yang_no: 418.182,
    yang_yes: 418.182,
  },
  {
    maesu: 60,
    dan_no: 200,
    dan_yes: 200,
    yang_no: 400,
    yang_yes: 400,
  },
  {
    maesu: 65,
    dan_no: 200,
    dan_yes: 200,
    yang_no: 369.231,
    yang_yes: 369.231,
  },
  {
    maesu: 70,
    dan_no: 185.714,
    dan_yes: 185.714,
    yang_no: 357.143,
    yang_yes: 357.143,
  },
  {
    maesu: 75,
    dan_no: 186.667,
    dan_yes: 186.667,
    yang_no: 360,
    yang_yes: 360,
  },
  {
    maesu: 80,
    dan_no: 175,
    dan_yes: 175,
    yang_no: 350,
    yang_yes: 350,
  },
  {
    maesu: 85,
    dan_no: 176.471,
    dan_yes: 176.471,
    yang_no: 352.941,
    yang_yes: 352.941,
  },
  {
    maesu: 90,
    dan_no: 177.778,
    dan_yes: 177.778,
    yang_no: 344.444,
    yang_yes: 344.444,
  },
  {
    maesu: 95,
    dan_no: 178.947,
    dan_yes: 178.947,
    yang_no: 347.368,
    yang_yes: 347.368,
  },
  {
    maesu: 100,
    dan_no: 17,
    dan_yes: 17,
    yang_no: 34,
    yang_yes: 34,
  },
  {
    maesu: 110,
    dan_no: 172.727,
    dan_yes: 172.727,
    yang_no: 336.364,
    yang_yes: 336.364,
  },
  {
    maesu: 120,
    dan_no: 166.667,
    dan_yes: 166.667,
    yang_no: 333.333,
    yang_yes: 333.333,
  },
  {
    maesu: 130,
    dan_no: 169.231,
    dan_yes: 169.231,
    yang_no: 330.769,
    yang_yes: 330.769,
  },
  {
    maesu: 140,
    dan_no: 164.286,
    dan_yes: 164.286,
    yang_no: 328.571,
    yang_yes: 328.571,
  },
  {
    maesu: 150,
    dan_no: 166.667,
    dan_yes: 166.667,
    yang_no: 326.667,
    yang_yes: 326.667,
  },
  {
    maesu: 160,
    dan_no: 162.5,
    dan_yes: 162.5,
    yang_no: 318.75,
    yang_yes: 318.75,
  },
  {
    maesu: 170,
    dan_no: 158.824,
    dan_yes: 158.824,
    yang_no: 317.647,
    yang_yes: 317.647,
  },
  {
    maesu: 180,
    dan_no: 161.111,
    dan_yes: 161.111,
    yang_no: 316.667,
    yang_yes: 316.667,
  },
  {
    maesu: 190,
    dan_no: 157.895,
    dan_yes: 157.895,
    yang_no: 315.789,
    yang_yes: 315.789,
  },
  {
    maesu: 200,
    dan_no: 155,
    dan_yes: 155,
    yang_no: 310,
    yang_yes: 310,
  },
  {
    maesu: 210,
    dan_no: 157.143,
    dan_yes: 157.143,
    yang_no: 309.524,
    yang_yes: 309.524,
  },
  {
    maesu: 220,
    dan_no: 154.545,
    dan_yes: 154.545,
    yang_no: 309.091,
    yang_yes: 309.091,
  },
  {
    maesu: 230,
    dan_no: 152.174,
    dan_yes: 152.174,
    yang_no: 304.348,
    yang_yes: 304.348,
  },
  {
    maesu: 240,
    dan_no: 154.167,
    dan_yes: 154.167,
    yang_no: 304.167,
    yang_yes: 304.167,
  },
  {
    maesu: 250,
    dan_no: 152,
    dan_yes: 152,
    yang_no: 304,
    yang_yes: 304,
  },
  {
    maesu: 260,
    dan_no: 150,
    dan_yes: 150,
    yang_no: 300,
    yang_yes: 300,
  },
  {
    maesu: 270,
    dan_no: 151.852,
    dan_yes: 151.852,
    yang_no: 300,
    yang_yes: 300,
  },
  {
    maesu: 280,
    dan_no: 150,
    dan_yes: 150,
    yang_no: 296.429,
    yang_yes: 296.429,
  },
  {
    maesu: 290,
    dan_no: 148.276,
    dan_yes: 148.276,
    yang_no: 293.103,
    yang_yes: 293.103,
  },
  {
    maesu: 300,
    dan_no: 146.667,
    dan_yes: 146.667,
    yang_no: 293.333,
    yang_yes: 293.333,
  },
  {
    maesu: 310,
    dan_no: 145.161,
    dan_yes: 145.161,
    yang_no: 290.323,
    yang_yes: 290.323,
  },
  {
    maesu: 320,
    dan_no: 146.875,
    dan_yes: 146.875,
    yang_no: 290.625,
    yang_yes: 290.625,
  },
  {
    maesu: 330,
    dan_no: 145.455,
    dan_yes: 145.455,
    yang_no: 287.879,
    yang_yes: 287.879,
  },
  {
    maesu: 340,
    dan_no: 144.118,
    dan_yes: 144.118,
    yang_no: 285.294,
    yang_yes: 285.294,
  },
  {
    maesu: 350,
    dan_no: 142.857,
    dan_yes: 142.857,
    yang_no: 282.857,
    yang_yes: 282.857,
  },
  {
    maesu: 360,
    dan_no: 141.667,
    dan_yes: 141.667,
    yang_no: 283.333,
    yang_yes: 283.333,
  },
  {
    maesu: 370,
    dan_no: 140.541,
    dan_yes: 140.541,
    yang_no: 281.081,
    yang_yes: 281.081,
  },
  {
    maesu: 380,
    dan_no: 139.474,
    dan_yes: 139.474,
    yang_no: 278.947,
    yang_yes: 278.947,
  },
  {
    maesu: 390,
    dan_no: 138.462,
    dan_yes: 138.462,
    yang_no: 276.923,
    yang_yes: 276.923,
  },
  {
    maesu: 400,
    dan_no: 137.5,
    dan_yes: 137.5,
    yang_no: 275,
    yang_yes: 275,
  },
  {
    maesu: 410,
    dan_no: 136.585,
    dan_yes: 136.585,
    yang_no: 273.171,
    yang_yes: 273.171,
  },
  {
    maesu: 420,
    dan_no: 135.714,
    dan_yes: 135.714,
    yang_no: 271.429,
    yang_yes: 271.429,
  },
  {
    maesu: 430,
    dan_no: 134.884,
    dan_yes: 134.884,
    yang_no: 269.767,
    yang_yes: 269.767,
  },
  {
    maesu: 440,
    dan_no: 134.091,
    dan_yes: 134.091,
    yang_no: 268.182,
    yang_yes: 268.182,
  },
  {
    maesu: 450,
    dan_no: 133.333,
    dan_yes: 133.333,
    yang_no: 266.667,
    yang_yes: 266.667,
  },
  {
    maesu: 460,
    dan_no: 132.609,
    dan_yes: 132.609,
    yang_no: 265.217,
    yang_yes: 265.217,
  },
  {
    maesu: 470,
    dan_no: 131.915,
    dan_yes: 131.915,
    yang_no: 263.83,
    yang_yes: 263.83,
  },
  {
    maesu: 480,
    dan_no: 131.25,
    dan_yes: 131.25,
    yang_no: 262.5,
    yang_yes: 262.5,
  },
  {
    maesu: 490,
    dan_no: 130.612,
    dan_yes: 130.612,
    yang_no: 261.224,
    yang_yes: 261.224,
  },
  {
    maesu: 500,
    dan_no: 130,
    dan_yes: 130,
    yang_no: 260,
    yang_yes: 260,
  },
  {
    maesu: 510,
    dan_no: 129.412,
    dan_yes: 129.412,
    yang_no: 256.863,
    yang_yes: 256.863,
  },
  {
    maesu: 520,
    dan_no: 128.846,
    dan_yes: 128.846,
    yang_no: 255.769,
    yang_yes: 255.769,
  },
  {
    maesu: 530,
    dan_no: 128.302,
    dan_yes: 128.302,
    yang_no: 254.717,
    yang_yes: 254.717,
  },
  {
    maesu: 540,
    dan_no: 127.778,
    dan_yes: 127.778,
    yang_no: 253.704,
    yang_yes: 253.704,
  },
  {
    maesu: 550,
    dan_no: 125.455,
    dan_yes: 125.455,
    yang_no: 250.909,
    yang_yes: 250.909,
  },
  {
    maesu: 560,
    dan_no: 125,
    dan_yes: 125,
    yang_no: 250,
    yang_yes: 250,
  },
  {
    maesu: 570,
    dan_no: 124.561,
    dan_yes: 124.561,
    yang_no: 247.368,
    yang_yes: 247.368,
  },
  {
    maesu: 580,
    dan_no: 124.138,
    dan_yes: 124.138,
    yang_no: 246.552,
    yang_yes: 246.552,
  },
  {
    maesu: 590,
    dan_no: 123.729,
    dan_yes: 123.729,
    yang_no: 245.763,
    yang_yes: 245.763,
  },
  {
    maesu: 600,
    dan_no: 121.667,
    dan_yes: 121.667,
    yang_no: 243.333,
    yang_yes: 243.333,
  },
  {
    maesu: 610,
    dan_no: 121.311,
    dan_yes: 121.311,
    yang_no: 242.623,
    yang_yes: 242.623,
  },
  {
    maesu: 620,
    dan_no: 120.968,
    dan_yes: 120.968,
    yang_no: 240.323,
    yang_yes: 240.323,
  },
  {
    maesu: 630,
    dan_no: 119.048,
    dan_yes: 119.048,
    yang_no: 238.095,
    yang_yes: 238.095,
  },
  {
    maesu: 640,
    dan_no: 118.75,
    dan_yes: 118.75,
    yang_no: 237.5,
    yang_yes: 237.5,
  },
  {
    maesu: 650,
    dan_no: 118.462,
    dan_yes: 118.462,
    yang_no: 235.385,
    yang_yes: 235.385,
  },
  {
    maesu: 660,
    dan_no: 116.667,
    dan_yes: 116.667,
    yang_no: 233.333,
    yang_yes: 233.333,
  },
  {
    maesu: 670,
    dan_no: 116.418,
    dan_yes: 116.418,
    yang_no: 232.836,
    yang_yes: 232.836,
  },
  {
    maesu: 680,
    dan_no: 116.176,
    dan_yes: 116.176,
    yang_no: 230.882,
    yang_yes: 230.882,
  },
  {
    maesu: 690,
    dan_no: 114.493,
    dan_yes: 114.493,
    yang_no: 228.986,
    yang_yes: 228.986,
  },
  {
    maesu: 700,
    dan_no: 114.286,
    dan_yes: 114.286,
    yang_no: 227.143,
    yang_yes: 227.143,
  },
  {
    maesu: 710,
    dan_no: 114.085,
    dan_yes: 114.085,
    yang_no: 226.761,
    yang_yes: 226.761,
  },
  {
    maesu: 720,
    dan_no: 112.5,
    dan_yes: 112.5,
    yang_no: 225,
    yang_yes: 225,
  },
  {
    maesu: 730,
    dan_no: 112.329,
    dan_yes: 112.329,
    yang_no: 223.288,
    yang_yes: 223.288,
  },
  {
    maesu: 740,
    dan_no: 110.811,
    dan_yes: 110.811,
    yang_no: 221.622,
    yang_yes: 221.622,
  },
  {
    maesu: 750,
    dan_no: 110.667,
    dan_yes: 110.667,
    yang_no: 220,
    yang_yes: 220,
  },
  {
    maesu: 760,
    dan_no: 109.211,
    dan_yes: 109.211,
    yang_no: 218.421,
    yang_yes: 218.421,
  },
  {
    maesu: 770,
    dan_no: 109.091,
    dan_yes: 109.091,
    yang_no: 216.883,
    yang_yes: 216.883,
  },
  {
    maesu: 780,
    dan_no: 107.692,
    dan_yes: 107.692,
    yang_no: 215.385,
    yang_yes: 215.385,
  },
  {
    maesu: 790,
    dan_no: 107.595,
    dan_yes: 107.595,
    yang_no: 213.924,
    yang_yes: 213.924,
  },
  {
    maesu: 800,
    dan_no: 106.25,
    dan_yes: 106.25,
    yang_no: 212.5,
    yang_yes: 212.5,
  },
  {
    maesu: 810,
    dan_no: 106.173,
    dan_yes: 106.173,
    yang_no: 211.111,
    yang_yes: 211.111,
  },
  {
    maesu: 820,
    dan_no: 104.878,
    dan_yes: 104.878,
    yang_no: 209.756,
    yang_yes: 209.756,
  },
  {
    maesu: 830,
    dan_no: 103.614,
    dan_yes: 103.614,
    yang_no: 207.229,
    yang_yes: 207.229,
  },
  {
    maesu: 840,
    dan_no: 103.571,
    dan_yes: 103.571,
    yang_no: 205.952,
    yang_yes: 205.952,
  },
  {
    maesu: 850,
    dan_no: 102.353,
    dan_yes: 102.353,
    yang_no: 204.706,
    yang_yes: 204.706,
  },
  {
    maesu: 860,
    dan_no: 102.326,
    dan_yes: 102.326,
    yang_no: 203.488,
    yang_yes: 203.488,
  },
  {
    maesu: 870,
    dan_no: 101.149,
    dan_yes: 101.149,
    yang_no: 201.149,
    yang_yes: 201.149,
  },
  {
    maesu: 880,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 890,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 198.876,
    yang_yes: 198.876,
  },
  {
    maesu: 900,
    dan_no: 98.889,
    dan_yes: 98.889,
    yang_no: 196.667,
    yang_yes: 196.667,
  },
  {
    maesu: 910,
    dan_no: 97.802,
    dan_yes: 97.802,
    yang_no: 195.604,
    yang_yes: 195.604,
  },
  {
    maesu: 920,
    dan_no: 96.739,
    dan_yes: 96.739,
    yang_no: 193.478,
    yang_yes: 193.478,
  },
  {
    maesu: 930,
    dan_no: 96.774,
    dan_yes: 96.774,
    yang_no: 192.473,
    yang_yes: 192.473,
  },
  {
    maesu: 940,
    dan_no: 95.745,
    dan_yes: 95.745,
    yang_no: 190.426,
    yang_yes: 190.426,
  },
  {
    maesu: 950,
    dan_no: 94.737,
    dan_yes: 94.737,
    yang_no: 189.474,
    yang_yes: 189.474,
  },
  {
    maesu: 960,
    dan_no: 93.75,
    dan_yes: 93.75,
    yang_no: 187.5,
    yang_yes: 187.5,
  },
  {
    maesu: 970,
    dan_no: 93.814,
    dan_yes: 93.814,
    yang_no: 186.598,
    yang_yes: 186.598,
  },
  {
    maesu: 980,
    dan_no: 92.857,
    dan_yes: 92.857,
    yang_no: 184.694,
    yang_yes: 184.694,
  },
  {
    maesu: 990,
    dan_no: 91.919,
    dan_yes: 91.919,
    yang_no: 182.828,
    yang_yes: 182.828,
  },
  {
    maesu: 1000,
    dan_no: 91,
    dan_yes: 91,
    yang_no: 182,
    yang_yes: 182,
  },
  {
    maesu: 1010,
    dan_no: 91.089,
    dan_yes: 91.089,
    yang_no: 182.178,
    yang_yes: 182.178,
  },
  {
    maesu: 1020,
    dan_no: 91.176,
    dan_yes: 91.176,
    yang_no: 182.353,
    yang_yes: 182.353,
  },
  {
    maesu: 1030,
    dan_no: 91.262,
    dan_yes: 91.262,
    yang_no: 182.524,
    yang_yes: 182.524,
  },
  {
    maesu: 1040,
    dan_no: 91.346,
    dan_yes: 91.346,
    yang_no: 182.692,
    yang_yes: 182.692,
  },
  {
    maesu: 1050,
    dan_no: 91.429,
    dan_yes: 91.429,
    yang_no: 182.857,
    yang_yes: 182.857,
  },
  {
    maesu: 1060,
    dan_no: 90.566,
    dan_yes: 90.566,
    yang_no: 181.132,
    yang_yes: 181.132,
  },
  {
    maesu: 1070,
    dan_no: 90.654,
    dan_yes: 90.654,
    yang_no: 181.308,
    yang_yes: 181.308,
  },
  {
    maesu: 1080,
    dan_no: 90.741,
    dan_yes: 90.741,
    yang_no: 181.481,
    yang_yes: 181.481,
  },
  {
    maesu: 1090,
    dan_no: 90.826,
    dan_yes: 90.826,
    yang_no: 181.651,
    yang_yes: 181.651,
  },
  {
    maesu: 1100,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 1110,
    dan_no: 90.991,
    dan_yes: 90.991,
    yang_no: 181.982,
    yang_yes: 181.982,
  },
  {
    maesu: 1120,
    dan_no: 91.071,
    dan_yes: 91.071,
    yang_no: 182.143,
    yang_yes: 182.143,
  },
  {
    maesu: 1130,
    dan_no: 91.15,
    dan_yes: 91.15,
    yang_no: 182.301,
    yang_yes: 182.301,
  },
  {
    maesu: 1140,
    dan_no: 91.228,
    dan_yes: 91.228,
    yang_no: 182.456,
    yang_yes: 182.456,
  },
  {
    maesu: 1150,
    dan_no: 91.304,
    dan_yes: 91.304,
    yang_no: 182.609,
    yang_yes: 182.609,
  },
  {
    maesu: 1160,
    dan_no: 91.379,
    dan_yes: 91.379,
    yang_no: 182.759,
    yang_yes: 182.759,
  },
  {
    maesu: 1170,
    dan_no: 90.598,
    dan_yes: 90.598,
    yang_no: 181.197,
    yang_yes: 181.197,
  },
  {
    maesu: 1180,
    dan_no: 90.678,
    dan_yes: 90.678,
    yang_no: 181.356,
    yang_yes: 181.356,
  },
  {
    maesu: 1190,
    dan_no: 90.756,
    dan_yes: 90.756,
    yang_no: 181.513,
    yang_yes: 181.513,
  },
  {
    maesu: 1200,
    dan_no: 90.833,
    dan_yes: 90.833,
    yang_no: 181.667,
    yang_yes: 181.667,
  },
  {
    maesu: 1210,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 1220,
    dan_no: 90.984,
    dan_yes: 90.984,
    yang_no: 181.967,
    yang_yes: 181.967,
  },
  {
    maesu: 1230,
    dan_no: 91.057,
    dan_yes: 91.057,
    yang_no: 182.114,
    yang_yes: 182.114,
  },
  {
    maesu: 1240,
    dan_no: 91.129,
    dan_yes: 91.129,
    yang_no: 182.258,
    yang_yes: 182.258,
  },
  {
    maesu: 1250,
    dan_no: 91.2,
    dan_yes: 91.2,
    yang_no: 182.4,
    yang_yes: 182.4,
  },
  {
    maesu: 1260,
    dan_no: 91.27,
    dan_yes: 91.27,
    yang_no: 182.54,
    yang_yes: 182.54,
  },
  {
    maesu: 1270,
    dan_no: 91.339,
    dan_yes: 91.339,
    yang_no: 182.677,
    yang_yes: 182.677,
  },
  {
    maesu: 1280,
    dan_no: 90.625,
    dan_yes: 90.625,
    yang_no: 181.25,
    yang_yes: 181.25,
  },
  {
    maesu: 1290,
    dan_no: 90.698,
    dan_yes: 90.698,
    yang_no: 181.395,
    yang_yes: 181.395,
  },
  {
    maesu: 1300,
    dan_no: 90.769,
    dan_yes: 90.769,
    yang_no: 181.538,
    yang_yes: 181.538,
  },
  {
    maesu: 1310,
    dan_no: 90.84,
    dan_yes: 90.84,
    yang_no: 181.679,
    yang_yes: 181.679,
  },
  {
    maesu: 1320,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 1330,
    dan_no: 90.977,
    dan_yes: 90.977,
    yang_no: 181.955,
    yang_yes: 181.955,
  },
  {
    maesu: 1340,
    dan_no: 91.045,
    dan_yes: 91.045,
    yang_no: 182.09,
    yang_yes: 182.09,
  },
  {
    maesu: 1350,
    dan_no: 91.111,
    dan_yes: 91.111,
    yang_no: 182.222,
    yang_yes: 182.222,
  },
  {
    maesu: 1360,
    dan_no: 91.176,
    dan_yes: 91.176,
    yang_no: 182.353,
    yang_yes: 182.353,
  },
  {
    maesu: 1370,
    dan_no: 91.241,
    dan_yes: 91.241,
    yang_no: 182.482,
    yang_yes: 182.482,
  },
  {
    maesu: 1380,
    dan_no: 91.304,
    dan_yes: 91.304,
    yang_no: 182.609,
    yang_yes: 182.609,
  },
  {
    maesu: 1390,
    dan_no: 90.647,
    dan_yes: 90.647,
    yang_no: 181.295,
    yang_yes: 181.295,
  },
  {
    maesu: 1400,
    dan_no: 90.714,
    dan_yes: 90.714,
    yang_no: 181.429,
    yang_yes: 181.429,
  },
  {
    maesu: 1410,
    dan_no: 90.78,
    dan_yes: 90.78,
    yang_no: 181.56,
    yang_yes: 181.56,
  },
  {
    maesu: 1420,
    dan_no: 90.845,
    dan_yes: 90.845,
    yang_no: 181.69,
    yang_yes: 181.69,
  },
  {
    maesu: 1430,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 1440,
    dan_no: 90.972,
    dan_yes: 90.972,
    yang_no: 181.944,
    yang_yes: 181.944,
  },
  {
    maesu: 1450,
    dan_no: 91.034,
    dan_yes: 91.034,
    yang_no: 182.069,
    yang_yes: 182.069,
  },
  {
    maesu: 1460,
    dan_no: 91.096,
    dan_yes: 91.096,
    yang_no: 182.192,
    yang_yes: 182.192,
  },
  {
    maesu: 1470,
    dan_no: 91.156,
    dan_yes: 91.156,
    yang_no: 182.313,
    yang_yes: 182.313,
  },
  {
    maesu: 1480,
    dan_no: 91.216,
    dan_yes: 91.216,
    yang_no: 182.432,
    yang_yes: 182.432,
  },
  {
    maesu: 1490,
    dan_no: 91.275,
    dan_yes: 91.275,
    yang_no: 182.55,
    yang_yes: 182.55,
  },
  {
    maesu: 1500,
    dan_no: 91.333,
    dan_yes: 91.333,
    yang_no: 182.667,
    yang_yes: 182.667,
  },
  {
    maesu: 1510,
    dan_no: 90.728,
    dan_yes: 90.728,
    yang_no: 181.457,
    yang_yes: 181.457,
  },
  {
    maesu: 1520,
    dan_no: 90.789,
    dan_yes: 90.789,
    yang_no: 181.579,
    yang_yes: 181.579,
  },
  {
    maesu: 1530,
    dan_no: 90.85,
    dan_yes: 90.85,
    yang_no: 181.699,
    yang_yes: 181.699,
  },
  {
    maesu: 1540,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 1550,
    dan_no: 90.968,
    dan_yes: 90.968,
    yang_no: 181.935,
    yang_yes: 181.935,
  },
  {
    maesu: 1560,
    dan_no: 91.026,
    dan_yes: 91.026,
    yang_no: 182.051,
    yang_yes: 182.051,
  },
  {
    maesu: 1570,
    dan_no: 91.083,
    dan_yes: 91.083,
    yang_no: 182.166,
    yang_yes: 182.166,
  },
  {
    maesu: 1580,
    dan_no: 91.139,
    dan_yes: 91.139,
    yang_no: 182.278,
    yang_yes: 182.278,
  },
  {
    maesu: 1590,
    dan_no: 91.195,
    dan_yes: 91.195,
    yang_no: 182.39,
    yang_yes: 182.39,
  },
  {
    maesu: 1600,
    dan_no: 91.25,
    dan_yes: 91.25,
    yang_no: 182.5,
    yang_yes: 182.5,
  },
  {
    maesu: 1610,
    dan_no: 91.304,
    dan_yes: 91.304,
    yang_no: 182.609,
    yang_yes: 182.609,
  },
  {
    maesu: 1620,
    dan_no: 90.741,
    dan_yes: 90.741,
    yang_no: 181.481,
    yang_yes: 181.481,
  },
  {
    maesu: 1630,
    dan_no: 90.798,
    dan_yes: 90.798,
    yang_no: 181.595,
    yang_yes: 181.595,
  },
  {
    maesu: 1640,
    dan_no: 90.854,
    dan_yes: 90.854,
    yang_no: 181.707,
    yang_yes: 181.707,
  },
  {
    maesu: 1650,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 1660,
    dan_no: 90.964,
    dan_yes: 90.964,
    yang_no: 181.928,
    yang_yes: 181.928,
  },
  {
    maesu: 1670,
    dan_no: 91.018,
    dan_yes: 91.018,
    yang_no: 182.036,
    yang_yes: 182.036,
  },
  {
    maesu: 1680,
    dan_no: 91.071,
    dan_yes: 91.071,
    yang_no: 182.143,
    yang_yes: 182.143,
  },
  {
    maesu: 1690,
    dan_no: 91.124,
    dan_yes: 91.124,
    yang_no: 182.249,
    yang_yes: 182.249,
  },
  {
    maesu: 1700,
    dan_no: 91.176,
    dan_yes: 91.176,
    yang_no: 182.353,
    yang_yes: 182.353,
  },
  {
    maesu: 1710,
    dan_no: 91.228,
    dan_yes: 91.228,
    yang_no: 182.456,
    yang_yes: 182.456,
  },
  {
    maesu: 1720,
    dan_no: 91.279,
    dan_yes: 91.279,
    yang_no: 182.558,
    yang_yes: 182.558,
  },
  {
    maesu: 1730,
    dan_no: 90.751,
    dan_yes: 90.751,
    yang_no: 181.503,
    yang_yes: 181.503,
  },
  {
    maesu: 1740,
    dan_no: 90.805,
    dan_yes: 90.805,
    yang_no: 181.609,
    yang_yes: 181.609,
  },
  {
    maesu: 1750,
    dan_no: 90.857,
    dan_yes: 90.857,
    yang_no: 181.714,
    yang_yes: 181.714,
  },
  {
    maesu: 1760,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 1770,
    dan_no: 90.96,
    dan_yes: 90.96,
    yang_no: 181.921,
    yang_yes: 181.921,
  },
  {
    maesu: 1780,
    dan_no: 91.011,
    dan_yes: 91.011,
    yang_no: 182.022,
    yang_yes: 182.022,
  },
  {
    maesu: 1790,
    dan_no: 91.061,
    dan_yes: 91.061,
    yang_no: 182.123,
    yang_yes: 182.123,
  },
  {
    maesu: 1800,
    dan_no: 91.111,
    dan_yes: 91.111,
    yang_no: 182.222,
    yang_yes: 182.222,
  },
  {
    maesu: 1810,
    dan_no: 91.16,
    dan_yes: 91.16,
    yang_no: 182.32,
    yang_yes: 182.32,
  },
  {
    maesu: 1820,
    dan_no: 91.209,
    dan_yes: 91.209,
    yang_no: 182.418,
    yang_yes: 182.418,
  },
  {
    maesu: 1830,
    dan_no: 91.257,
    dan_yes: 91.257,
    yang_no: 182.514,
    yang_yes: 182.514,
  },
  {
    maesu: 1840,
    dan_no: 90.761,
    dan_yes: 90.761,
    yang_no: 181.522,
    yang_yes: 181.522,
  },
  {
    maesu: 1850,
    dan_no: 90.811,
    dan_yes: 90.811,
    yang_no: 181.622,
    yang_yes: 181.622,
  },
  {
    maesu: 1860,
    dan_no: 90.86,
    dan_yes: 90.86,
    yang_no: 181.72,
    yang_yes: 181.72,
  },
  {
    maesu: 1870,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 1880,
    dan_no: 90.957,
    dan_yes: 90.957,
    yang_no: 181.915,
    yang_yes: 181.915,
  },
  {
    maesu: 1890,
    dan_no: 91.005,
    dan_yes: 91.005,
    yang_no: 182.011,
    yang_yes: 182.011,
  },
  {
    maesu: 1900,
    dan_no: 91.053,
    dan_yes: 91.053,
    yang_no: 182.105,
    yang_yes: 182.105,
  },
  {
    maesu: 1910,
    dan_no: 91.099,
    dan_yes: 91.099,
    yang_no: 182.199,
    yang_yes: 182.199,
  },
  {
    maesu: 1920,
    dan_no: 91.146,
    dan_yes: 91.146,
    yang_no: 182.292,
    yang_yes: 182.292,
  },
  {
    maesu: 1930,
    dan_no: 91.192,
    dan_yes: 91.192,
    yang_no: 182.383,
    yang_yes: 182.383,
  },
  {
    maesu: 1940,
    dan_no: 91.237,
    dan_yes: 91.237,
    yang_no: 182.474,
    yang_yes: 182.474,
  },
  {
    maesu: 1950,
    dan_no: 90.769,
    dan_yes: 90.769,
    yang_no: 181.538,
    yang_yes: 181.538,
  },
  {
    maesu: 1960,
    dan_no: 90.816,
    dan_yes: 90.816,
    yang_no: 181.633,
    yang_yes: 181.633,
  },
  {
    maesu: 1970,
    dan_no: 90.863,
    dan_yes: 90.863,
    yang_no: 181.726,
    yang_yes: 181.726,
  },
  {
    maesu: 1980,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 1990,
    dan_no: 90.955,
    dan_yes: 90.955,
    yang_no: 181.91,
    yang_yes: 181.91,
  },
  {
    maesu: 2000,
    dan_no: 91,
    dan_yes: 91,
    yang_no: 182,
    yang_yes: 182,
  },
  {
    maesu: 2010,
    dan_no: 91.045,
    dan_yes: 91.045,
    yang_no: 182.09,
    yang_yes: 182.09,
  },
  {
    maesu: 2020,
    dan_no: 91.089,
    dan_yes: 91.089,
    yang_no: 182.178,
    yang_yes: 182.178,
  },
  {
    maesu: 2030,
    dan_no: 91.133,
    dan_yes: 91.133,
    yang_no: 182.266,
    yang_yes: 182.266,
  },
  {
    maesu: 2040,
    dan_no: 91.176,
    dan_yes: 91.176,
    yang_no: 182.353,
    yang_yes: 182.353,
  },
  {
    maesu: 2050,
    dan_no: 91.22,
    dan_yes: 91.22,
    yang_no: 182.439,
    yang_yes: 182.439,
  },
  {
    maesu: 2060,
    dan_no: 90.777,
    dan_yes: 90.777,
    yang_no: 181.553,
    yang_yes: 181.553,
  },
  {
    maesu: 2070,
    dan_no: 90.821,
    dan_yes: 90.821,
    yang_no: 181.643,
    yang_yes: 181.643,
  },
  {
    maesu: 2080,
    dan_no: 90.865,
    dan_yes: 90.865,
    yang_no: 181.731,
    yang_yes: 181.731,
  },
  {
    maesu: 2090,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 2100,
    dan_no: 90.952,
    dan_yes: 90.952,
    yang_no: 181.905,
    yang_yes: 181.905,
  },
  {
    maesu: 2110,
    dan_no: 90.995,
    dan_yes: 90.995,
    yang_no: 181.991,
    yang_yes: 181.991,
  },
  {
    maesu: 2120,
    dan_no: 91.038,
    dan_yes: 91.038,
    yang_no: 182.075,
    yang_yes: 182.075,
  },
  {
    maesu: 2130,
    dan_no: 91.08,
    dan_yes: 91.08,
    yang_no: 182.16,
    yang_yes: 182.16,
  },
  {
    maesu: 2140,
    dan_no: 91.121,
    dan_yes: 91.121,
    yang_no: 182.243,
    yang_yes: 182.243,
  },
  {
    maesu: 2150,
    dan_no: 91.163,
    dan_yes: 91.163,
    yang_no: 182.326,
    yang_yes: 182.326,
  },
  {
    maesu: 2160,
    dan_no: 91.204,
    dan_yes: 91.204,
    yang_no: 182.407,
    yang_yes: 182.407,
  },
  {
    maesu: 2170,
    dan_no: 90.783,
    dan_yes: 90.783,
    yang_no: 181.567,
    yang_yes: 181.567,
  },
  {
    maesu: 2180,
    dan_no: 90.826,
    dan_yes: 90.826,
    yang_no: 181.651,
    yang_yes: 181.651,
  },
  {
    maesu: 2190,
    dan_no: 90.868,
    dan_yes: 90.868,
    yang_no: 181.735,
    yang_yes: 181.735,
  },
  {
    maesu: 2200,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 2210,
    dan_no: 90.95,
    dan_yes: 90.95,
    yang_no: 181.9,
    yang_yes: 181.9,
  },
  {
    maesu: 2220,
    dan_no: 90.991,
    dan_yes: 90.991,
    yang_no: 181.982,
    yang_yes: 181.982,
  },
  {
    maesu: 2230,
    dan_no: 91.031,
    dan_yes: 91.031,
    yang_no: 182.063,
    yang_yes: 182.063,
  },
  {
    maesu: 2240,
    dan_no: 91.071,
    dan_yes: 91.071,
    yang_no: 182.143,
    yang_yes: 182.143,
  },
  {
    maesu: 2250,
    dan_no: 91.111,
    dan_yes: 91.111,
    yang_no: 182.222,
    yang_yes: 182.222,
  },
  {
    maesu: 2260,
    dan_no: 91.15,
    dan_yes: 91.15,
    yang_no: 182.301,
    yang_yes: 182.301,
  },
  {
    maesu: 2270,
    dan_no: 91.189,
    dan_yes: 91.189,
    yang_no: 182.379,
    yang_yes: 182.379,
  },
  {
    maesu: 2280,
    dan_no: 90.789,
    dan_yes: 90.789,
    yang_no: 181.579,
    yang_yes: 181.579,
  },
  {
    maesu: 2290,
    dan_no: 90.83,
    dan_yes: 90.83,
    yang_no: 181.659,
    yang_yes: 181.659,
  },
  {
    maesu: 2300,
    dan_no: 90.87,
    dan_yes: 90.87,
    yang_no: 181.739,
    yang_yes: 181.739,
  },
  {
    maesu: 2310,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 2320,
    dan_no: 90.948,
    dan_yes: 90.948,
    yang_no: 181.897,
    yang_yes: 181.897,
  },
  {
    maesu: 2330,
    dan_no: 90.987,
    dan_yes: 90.987,
    yang_no: 181.974,
    yang_yes: 181.974,
  },
  {
    maesu: 2340,
    dan_no: 91.026,
    dan_yes: 91.026,
    yang_no: 182.051,
    yang_yes: 182.051,
  },
  {
    maesu: 2350,
    dan_no: 91.064,
    dan_yes: 91.064,
    yang_no: 182.128,
    yang_yes: 182.128,
  },
  {
    maesu: 2360,
    dan_no: 91.102,
    dan_yes: 91.102,
    yang_no: 182.203,
    yang_yes: 182.203,
  },
  {
    maesu: 2370,
    dan_no: 91.139,
    dan_yes: 91.139,
    yang_no: 182.278,
    yang_yes: 182.278,
  },
  {
    maesu: 2380,
    dan_no: 91.176,
    dan_yes: 91.176,
    yang_no: 182.353,
    yang_yes: 182.353,
  },
  {
    maesu: 2390,
    dan_no: 90.795,
    dan_yes: 90.795,
    yang_no: 181.59,
    yang_yes: 181.59,
  },
  {
    maesu: 2400,
    dan_no: 90.833,
    dan_yes: 90.833,
    yang_no: 181.667,
    yang_yes: 181.667,
  },
  {
    maesu: 2410,
    dan_no: 90.871,
    dan_yes: 90.871,
    yang_no: 181.743,
    yang_yes: 181.743,
  },
  {
    maesu: 2420,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 2430,
    dan_no: 90.947,
    dan_yes: 90.947,
    yang_no: 181.893,
    yang_yes: 181.893,
  },
  {
    maesu: 2440,
    dan_no: 90.984,
    dan_yes: 90.984,
    yang_no: 181.967,
    yang_yes: 181.967,
  },
  {
    maesu: 2450,
    dan_no: 91.02,
    dan_yes: 91.02,
    yang_no: 182.041,
    yang_yes: 182.041,
  },
  {
    maesu: 2460,
    dan_no: 91.057,
    dan_yes: 91.057,
    yang_no: 182.114,
    yang_yes: 182.114,
  },
  {
    maesu: 2470,
    dan_no: 91.093,
    dan_yes: 91.093,
    yang_no: 182.186,
    yang_yes: 182.186,
  },
  {
    maesu: 2480,
    dan_no: 91.129,
    dan_yes: 91.129,
    yang_no: 182.258,
    yang_yes: 182.258,
  },
  {
    maesu: 2490,
    dan_no: 91.165,
    dan_yes: 91.165,
    yang_no: 182.329,
    yang_yes: 182.329,
  },
  {
    maesu: 2500,
    dan_no: 91.2,
    dan_yes: 91.2,
    yang_no: 182.4,
    yang_yes: 182.4,
  },
  {
    maesu: 2510,
    dan_no: 90.837,
    dan_yes: 90.837,
    yang_no: 181.673,
    yang_yes: 181.673,
  },
  {
    maesu: 2520,
    dan_no: 90.873,
    dan_yes: 90.873,
    yang_no: 181.746,
    yang_yes: 181.746,
  },
  {
    maesu: 2530,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 2540,
    dan_no: 90.945,
    dan_yes: 90.945,
    yang_no: 181.89,
    yang_yes: 181.89,
  },
  {
    maesu: 2550,
    dan_no: 90.98,
    dan_yes: 90.98,
    yang_no: 181.961,
    yang_yes: 181.961,
  },
  {
    maesu: 2560,
    dan_no: 91.016,
    dan_yes: 91.016,
    yang_no: 182.031,
    yang_yes: 182.031,
  },
  {
    maesu: 2570,
    dan_no: 91.051,
    dan_yes: 91.051,
    yang_no: 182.101,
    yang_yes: 182.101,
  },
  {
    maesu: 2580,
    dan_no: 91.085,
    dan_yes: 91.085,
    yang_no: 182.171,
    yang_yes: 182.171,
  },
  {
    maesu: 2590,
    dan_no: 91.12,
    dan_yes: 91.12,
    yang_no: 182.239,
    yang_yes: 182.239,
  },
  {
    maesu: 2600,
    dan_no: 91.154,
    dan_yes: 91.154,
    yang_no: 182.308,
    yang_yes: 182.308,
  },
  {
    maesu: 2610,
    dan_no: 91.188,
    dan_yes: 91.188,
    yang_no: 182.375,
    yang_yes: 182.375,
  },
  {
    maesu: 2620,
    dan_no: 90.84,
    dan_yes: 90.84,
    yang_no: 181.679,
    yang_yes: 181.679,
  },
  {
    maesu: 2630,
    dan_no: 90.875,
    dan_yes: 90.875,
    yang_no: 181.749,
    yang_yes: 181.749,
  },
  {
    maesu: 2640,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 2650,
    dan_no: 90.943,
    dan_yes: 90.943,
    yang_no: 181.887,
    yang_yes: 181.887,
  },
  {
    maesu: 2660,
    dan_no: 90.977,
    dan_yes: 90.977,
    yang_no: 181.955,
    yang_yes: 181.955,
  },
  {
    maesu: 2670,
    dan_no: 91.011,
    dan_yes: 91.011,
    yang_no: 182.022,
    yang_yes: 182.022,
  },
  {
    maesu: 2680,
    dan_no: 91.045,
    dan_yes: 91.045,
    yang_no: 182.09,
    yang_yes: 182.09,
  },
  {
    maesu: 2690,
    dan_no: 91.078,
    dan_yes: 91.078,
    yang_no: 182.156,
    yang_yes: 182.156,
  },
  {
    maesu: 2700,
    dan_no: 91.111,
    dan_yes: 91.111,
    yang_no: 182.222,
    yang_yes: 182.222,
  },
  {
    maesu: 2710,
    dan_no: 91.144,
    dan_yes: 91.144,
    yang_no: 182.288,
    yang_yes: 182.288,
  },
  {
    maesu: 2720,
    dan_no: 91.176,
    dan_yes: 91.176,
    yang_no: 182.353,
    yang_yes: 182.353,
  },
  {
    maesu: 2730,
    dan_no: 90.842,
    dan_yes: 90.842,
    yang_no: 181.685,
    yang_yes: 181.685,
  },
  {
    maesu: 2740,
    dan_no: 90.876,
    dan_yes: 90.876,
    yang_no: 181.752,
    yang_yes: 181.752,
  },
  {
    maesu: 2750,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 2760,
    dan_no: 90.942,
    dan_yes: 90.942,
    yang_no: 181.884,
    yang_yes: 181.884,
  },
  {
    maesu: 2770,
    dan_no: 90.975,
    dan_yes: 90.975,
    yang_no: 181.949,
    yang_yes: 181.949,
  },
  {
    maesu: 2780,
    dan_no: 91.007,
    dan_yes: 91.007,
    yang_no: 182.014,
    yang_yes: 182.014,
  },
  {
    maesu: 2790,
    dan_no: 91.039,
    dan_yes: 91.039,
    yang_no: 182.079,
    yang_yes: 182.079,
  },
  {
    maesu: 2800,
    dan_no: 91.071,
    dan_yes: 91.071,
    yang_no: 182.143,
    yang_yes: 182.143,
  },
  {
    maesu: 2810,
    dan_no: 91.103,
    dan_yes: 91.103,
    yang_no: 182.206,
    yang_yes: 182.206,
  },
  {
    maesu: 2820,
    dan_no: 91.135,
    dan_yes: 91.135,
    yang_no: 182.27,
    yang_yes: 182.27,
  },
  {
    maesu: 2830,
    dan_no: 91.166,
    dan_yes: 91.166,
    yang_no: 182.332,
    yang_yes: 182.332,
  },
  {
    maesu: 2840,
    dan_no: 90.845,
    dan_yes: 90.845,
    yang_no: 181.69,
    yang_yes: 181.69,
  },
  {
    maesu: 2850,
    dan_no: 90.877,
    dan_yes: 90.877,
    yang_no: 181.754,
    yang_yes: 181.754,
  },
  {
    maesu: 2860,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 2870,
    dan_no: 90.941,
    dan_yes: 90.941,
    yang_no: 181.882,
    yang_yes: 181.882,
  },
  {
    maesu: 2880,
    dan_no: 90.972,
    dan_yes: 90.972,
    yang_no: 181.944,
    yang_yes: 181.944,
  },
  {
    maesu: 2890,
    dan_no: 91.003,
    dan_yes: 91.003,
    yang_no: 182.007,
    yang_yes: 182.007,
  },
  {
    maesu: 2900,
    dan_no: 91.034,
    dan_yes: 91.034,
    yang_no: 182.069,
    yang_yes: 182.069,
  },
  {
    maesu: 2910,
    dan_no: 91.065,
    dan_yes: 91.065,
    yang_no: 182.131,
    yang_yes: 182.131,
  },
  {
    maesu: 2920,
    dan_no: 91.096,
    dan_yes: 91.096,
    yang_no: 182.192,
    yang_yes: 182.192,
  },
  {
    maesu: 2930,
    dan_no: 91.126,
    dan_yes: 91.126,
    yang_no: 182.253,
    yang_yes: 182.253,
  },
  {
    maesu: 2940,
    dan_no: 91.156,
    dan_yes: 91.156,
    yang_no: 182.313,
    yang_yes: 182.313,
  },
  {
    maesu: 2950,
    dan_no: 90.847,
    dan_yes: 90.847,
    yang_no: 181.695,
    yang_yes: 181.695,
  },
  {
    maesu: 2960,
    dan_no: 90.878,
    dan_yes: 90.878,
    yang_no: 181.757,
    yang_yes: 181.757,
  },
  {
    maesu: 2970,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 2980,
    dan_no: 90.94,
    dan_yes: 90.94,
    yang_no: 181.879,
    yang_yes: 181.879,
  },
  {
    maesu: 2990,
    dan_no: 90.97,
    dan_yes: 90.97,
    yang_no: 181.94,
    yang_yes: 181.94,
  },
  {
    maesu: 3000,
    dan_no: 91,
    dan_yes: 91,
    yang_no: 182,
    yang_yes: 182,
  },
  {
    maesu: 3010,
    dan_no: 91.03,
    dan_yes: 91.03,
    yang_no: 182.06,
    yang_yes: 182.06,
  },
  {
    maesu: 3020,
    dan_no: 91.06,
    dan_yes: 91.06,
    yang_no: 182.119,
    yang_yes: 182.119,
  },
  {
    maesu: 3030,
    dan_no: 91.089,
    dan_yes: 91.089,
    yang_no: 182.178,
    yang_yes: 182.178,
  },
  {
    maesu: 3040,
    dan_no: 91.118,
    dan_yes: 91.118,
    yang_no: 182.237,
    yang_yes: 182.237,
  },
  {
    maesu: 3050,
    dan_no: 91.148,
    dan_yes: 91.148,
    yang_no: 182.295,
    yang_yes: 182.295,
  },
  {
    maesu: 3060,
    dan_no: 90.85,
    dan_yes: 90.85,
    yang_no: 181.699,
    yang_yes: 181.699,
  },
  {
    maesu: 3070,
    dan_no: 90.879,
    dan_yes: 90.879,
    yang_no: 181.759,
    yang_yes: 181.759,
  },
  {
    maesu: 3080,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 3090,
    dan_no: 90.939,
    dan_yes: 90.939,
    yang_no: 181.877,
    yang_yes: 181.877,
  },
  {
    maesu: 3100,
    dan_no: 90.968,
    dan_yes: 90.968,
    yang_no: 181.935,
    yang_yes: 181.935,
  },
  {
    maesu: 3110,
    dan_no: 90.997,
    dan_yes: 90.997,
    yang_no: 181.994,
    yang_yes: 181.994,
  },
  {
    maesu: 3120,
    dan_no: 91.026,
    dan_yes: 91.026,
    yang_no: 182.051,
    yang_yes: 182.051,
  },
  {
    maesu: 3130,
    dan_no: 91.054,
    dan_yes: 91.054,
    yang_no: 182.109,
    yang_yes: 182.109,
  },
  {
    maesu: 3140,
    dan_no: 91.083,
    dan_yes: 91.083,
    yang_no: 182.166,
    yang_yes: 182.166,
  },
  {
    maesu: 3150,
    dan_no: 91.111,
    dan_yes: 91.111,
    yang_no: 182.222,
    yang_yes: 182.222,
  },
  {
    maesu: 3160,
    dan_no: 91.139,
    dan_yes: 91.139,
    yang_no: 182.278,
    yang_yes: 182.278,
  },
  {
    maesu: 3170,
    dan_no: 90.852,
    dan_yes: 90.852,
    yang_no: 181.703,
    yang_yes: 181.703,
  },
  {
    maesu: 3180,
    dan_no: 90.881,
    dan_yes: 90.881,
    yang_no: 181.761,
    yang_yes: 181.761,
  },
  {
    maesu: 3190,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 3200,
    dan_no: 90.938,
    dan_yes: 90.938,
    yang_no: 181.875,
    yang_yes: 181.875,
  },
  {
    maesu: 3210,
    dan_no: 90.966,
    dan_yes: 90.966,
    yang_no: 181.931,
    yang_yes: 181.931,
  },
  {
    maesu: 3220,
    dan_no: 90.994,
    dan_yes: 90.994,
    yang_no: 181.988,
    yang_yes: 181.988,
  },
  {
    maesu: 3230,
    dan_no: 91.022,
    dan_yes: 91.022,
    yang_no: 182.043,
    yang_yes: 182.043,
  },
  {
    maesu: 3240,
    dan_no: 91.049,
    dan_yes: 91.049,
    yang_no: 182.099,
    yang_yes: 182.099,
  },
  {
    maesu: 3250,
    dan_no: 91.077,
    dan_yes: 91.077,
    yang_no: 182.154,
    yang_yes: 182.154,
  },
  {
    maesu: 3260,
    dan_no: 91.104,
    dan_yes: 91.104,
    yang_no: 182.209,
    yang_yes: 182.209,
  },
  {
    maesu: 3270,
    dan_no: 91.131,
    dan_yes: 91.131,
    yang_no: 182.263,
    yang_yes: 182.263,
  },
  {
    maesu: 3280,
    dan_no: 90.854,
    dan_yes: 90.854,
    yang_no: 181.707,
    yang_yes: 181.707,
  },
  {
    maesu: 3290,
    dan_no: 90.881,
    dan_yes: 90.881,
    yang_no: 181.763,
    yang_yes: 181.763,
  },
  {
    maesu: 3300,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 3310,
    dan_no: 90.937,
    dan_yes: 90.937,
    yang_no: 181.873,
    yang_yes: 181.873,
  },
  {
    maesu: 3320,
    dan_no: 90.964,
    dan_yes: 90.964,
    yang_no: 181.928,
    yang_yes: 181.928,
  },
  {
    maesu: 3330,
    dan_no: 90.991,
    dan_yes: 90.991,
    yang_no: 181.982,
    yang_yes: 181.982,
  },
  {
    maesu: 3340,
    dan_no: 91.018,
    dan_yes: 91.018,
    yang_no: 182.036,
    yang_yes: 182.036,
  },
  {
    maesu: 3350,
    dan_no: 91.045,
    dan_yes: 91.045,
    yang_no: 182.09,
    yang_yes: 182.09,
  },
  {
    maesu: 3360,
    dan_no: 91.071,
    dan_yes: 91.071,
    yang_no: 182.143,
    yang_yes: 182.143,
  },
  {
    maesu: 3370,
    dan_no: 91.098,
    dan_yes: 91.098,
    yang_no: 182.196,
    yang_yes: 182.196,
  },
  {
    maesu: 3380,
    dan_no: 91.124,
    dan_yes: 91.124,
    yang_no: 182.249,
    yang_yes: 182.249,
  },
  {
    maesu: 3390,
    dan_no: 90.855,
    dan_yes: 90.855,
    yang_no: 181.711,
    yang_yes: 181.711,
  },
  {
    maesu: 3400,
    dan_no: 90.882,
    dan_yes: 90.882,
    yang_no: 181.765,
    yang_yes: 181.765,
  },
  {
    maesu: 3410,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 3420,
    dan_no: 90.936,
    dan_yes: 90.936,
    yang_no: 181.871,
    yang_yes: 181.871,
  },
  {
    maesu: 3430,
    dan_no: 90.962,
    dan_yes: 90.962,
    yang_no: 181.924,
    yang_yes: 181.924,
  },
  {
    maesu: 3440,
    dan_no: 90.988,
    dan_yes: 90.988,
    yang_no: 181.977,
    yang_yes: 181.977,
  },
  {
    maesu: 3450,
    dan_no: 91.014,
    dan_yes: 91.014,
    yang_no: 182.029,
    yang_yes: 182.029,
  },
  {
    maesu: 3460,
    dan_no: 91.04,
    dan_yes: 91.04,
    yang_no: 182.081,
    yang_yes: 182.081,
  },
  {
    maesu: 3470,
    dan_no: 91.066,
    dan_yes: 91.066,
    yang_no: 182.133,
    yang_yes: 182.133,
  },
  {
    maesu: 3480,
    dan_no: 91.092,
    dan_yes: 91.092,
    yang_no: 182.184,
    yang_yes: 182.184,
  },
  {
    maesu: 3490,
    dan_no: 91.117,
    dan_yes: 91.117,
    yang_no: 182.235,
    yang_yes: 182.235,
  },
  {
    maesu: 3500,
    dan_no: 91.143,
    dan_yes: 91.143,
    yang_no: 182.286,
    yang_yes: 182.286,
  },
  {
    maesu: 3510,
    dan_no: 90.883,
    dan_yes: 90.883,
    yang_no: 181.766,
    yang_yes: 181.766,
  },
  {
    maesu: 3520,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 3530,
    dan_no: 90.935,
    dan_yes: 90.935,
    yang_no: 181.87,
    yang_yes: 181.87,
  },
  {
    maesu: 3540,
    dan_no: 90.96,
    dan_yes: 90.96,
    yang_no: 181.921,
    yang_yes: 181.921,
  },
  {
    maesu: 3550,
    dan_no: 90.986,
    dan_yes: 90.986,
    yang_no: 181.972,
    yang_yes: 181.972,
  },
  {
    maesu: 3560,
    dan_no: 91.011,
    dan_yes: 91.011,
    yang_no: 182.022,
    yang_yes: 182.022,
  },
  {
    maesu: 3570,
    dan_no: 91.036,
    dan_yes: 91.036,
    yang_no: 182.073,
    yang_yes: 182.073,
  },
  {
    maesu: 3580,
    dan_no: 91.061,
    dan_yes: 91.061,
    yang_no: 182.123,
    yang_yes: 182.123,
  },
  {
    maesu: 3590,
    dan_no: 91.086,
    dan_yes: 91.086,
    yang_no: 182.173,
    yang_yes: 182.173,
  },
  {
    maesu: 3600,
    dan_no: 91.111,
    dan_yes: 91.111,
    yang_no: 182.222,
    yang_yes: 182.222,
  },
  {
    maesu: 3610,
    dan_no: 91.136,
    dan_yes: 91.136,
    yang_no: 182.271,
    yang_yes: 182.271,
  },
  {
    maesu: 3620,
    dan_no: 90.884,
    dan_yes: 90.884,
    yang_no: 181.768,
    yang_yes: 181.768,
  },
  {
    maesu: 3630,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 3640,
    dan_no: 90.934,
    dan_yes: 90.934,
    yang_no: 181.868,
    yang_yes: 181.868,
  },
  {
    maesu: 3650,
    dan_no: 90.959,
    dan_yes: 90.959,
    yang_no: 181.918,
    yang_yes: 181.918,
  },
  {
    maesu: 3660,
    dan_no: 90.984,
    dan_yes: 90.984,
    yang_no: 181.967,
    yang_yes: 181.967,
  },
  {
    maesu: 3670,
    dan_no: 91.008,
    dan_yes: 91.008,
    yang_no: 182.016,
    yang_yes: 182.016,
  },
  {
    maesu: 3680,
    dan_no: 91.033,
    dan_yes: 91.033,
    yang_no: 182.065,
    yang_yes: 182.065,
  },
  {
    maesu: 3690,
    dan_no: 91.057,
    dan_yes: 91.057,
    yang_no: 182.114,
    yang_yes: 182.114,
  },
  {
    maesu: 3700,
    dan_no: 91.081,
    dan_yes: 91.081,
    yang_no: 182.162,
    yang_yes: 182.162,
  },
  {
    maesu: 3710,
    dan_no: 91.105,
    dan_yes: 91.105,
    yang_no: 182.21,
    yang_yes: 182.21,
  },
  {
    maesu: 3720,
    dan_no: 91.129,
    dan_yes: 91.129,
    yang_no: 182.258,
    yang_yes: 182.258,
  },
  {
    maesu: 3730,
    dan_no: 90.885,
    dan_yes: 90.885,
    yang_no: 181.769,
    yang_yes: 181.769,
  },
  {
    maesu: 3740,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 3750,
    dan_no: 90.933,
    dan_yes: 90.933,
    yang_no: 181.867,
    yang_yes: 181.867,
  },
  {
    maesu: 3760,
    dan_no: 90.957,
    dan_yes: 90.957,
    yang_no: 181.915,
    yang_yes: 181.915,
  },
  {
    maesu: 3770,
    dan_no: 90.981,
    dan_yes: 90.981,
    yang_no: 181.963,
    yang_yes: 181.963,
  },
  {
    maesu: 3780,
    dan_no: 91.005,
    dan_yes: 91.005,
    yang_no: 182.011,
    yang_yes: 182.011,
  },
  {
    maesu: 3790,
    dan_no: 91.029,
    dan_yes: 91.029,
    yang_no: 182.058,
    yang_yes: 182.058,
  },
  {
    maesu: 3800,
    dan_no: 91.053,
    dan_yes: 91.053,
    yang_no: 182.105,
    yang_yes: 182.105,
  },
  {
    maesu: 3810,
    dan_no: 91.076,
    dan_yes: 91.076,
    yang_no: 182.152,
    yang_yes: 182.152,
  },
  {
    maesu: 3820,
    dan_no: 91.099,
    dan_yes: 91.099,
    yang_no: 182.199,
    yang_yes: 182.199,
  },
  {
    maesu: 3830,
    dan_no: 91.123,
    dan_yes: 91.123,
    yang_no: 182.245,
    yang_yes: 182.245,
  },
  {
    maesu: 3840,
    dan_no: 90.885,
    dan_yes: 90.885,
    yang_no: 181.771,
    yang_yes: 181.771,
  },
  {
    maesu: 3850,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 3860,
    dan_no: 90.933,
    dan_yes: 90.933,
    yang_no: 181.865,
    yang_yes: 181.865,
  },
  {
    maesu: 3870,
    dan_no: 90.956,
    dan_yes: 90.956,
    yang_no: 181.912,
    yang_yes: 181.912,
  },
  {
    maesu: 3880,
    dan_no: 90.979,
    dan_yes: 90.979,
    yang_no: 181.959,
    yang_yes: 181.959,
  },
  {
    maesu: 3890,
    dan_no: 91.003,
    dan_yes: 91.003,
    yang_no: 182.005,
    yang_yes: 182.005,
  },
  {
    maesu: 3900,
    dan_no: 91.026,
    dan_yes: 91.026,
    yang_no: 182.051,
    yang_yes: 182.051,
  },
  {
    maesu: 3910,
    dan_no: 91.049,
    dan_yes: 91.049,
    yang_no: 182.097,
    yang_yes: 182.097,
  },
  {
    maesu: 3920,
    dan_no: 91.071,
    dan_yes: 91.071,
    yang_no: 182.143,
    yang_yes: 182.143,
  },
  {
    maesu: 3930,
    dan_no: 91.094,
    dan_yes: 91.094,
    yang_no: 182.188,
    yang_yes: 182.188,
  },
  {
    maesu: 3940,
    dan_no: 91.117,
    dan_yes: 91.117,
    yang_no: 182.234,
    yang_yes: 182.234,
  },
  {
    maesu: 3950,
    dan_no: 90.886,
    dan_yes: 90.886,
    yang_no: 181.772,
    yang_yes: 181.772,
  },
  {
    maesu: 3960,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 3970,
    dan_no: 90.932,
    dan_yes: 90.932,
    yang_no: 181.864,
    yang_yes: 181.864,
  },
  {
    maesu: 3980,
    dan_no: 90.955,
    dan_yes: 90.955,
    yang_no: 181.91,
    yang_yes: 181.91,
  },
  {
    maesu: 3990,
    dan_no: 90.977,
    dan_yes: 90.977,
    yang_no: 181.955,
    yang_yes: 181.955,
  },
  {
    maesu: 4000,
    dan_no: 91,
    dan_yes: 91,
    yang_no: 182,
    yang_yes: 182,
  },
  {
    maesu: 4010,
    dan_no: 91.022,
    dan_yes: 91.022,
    yang_no: 182.045,
    yang_yes: 182.045,
  },
  {
    maesu: 4020,
    dan_no: 91.045,
    dan_yes: 91.045,
    yang_no: 182.09,
    yang_yes: 182.09,
  },
  {
    maesu: 4030,
    dan_no: 91.067,
    dan_yes: 91.067,
    yang_no: 182.134,
    yang_yes: 182.134,
  },
  {
    maesu: 4040,
    dan_no: 91.089,
    dan_yes: 91.089,
    yang_no: 182.178,
    yang_yes: 182.178,
  },
  {
    maesu: 4050,
    dan_no: 91.111,
    dan_yes: 91.111,
    yang_no: 182.222,
    yang_yes: 182.222,
  },
  {
    maesu: 4060,
    dan_no: 90.887,
    dan_yes: 90.887,
    yang_no: 181.773,
    yang_yes: 181.773,
  },
  {
    maesu: 4070,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 4080,
    dan_no: 90.931,
    dan_yes: 90.931,
    yang_no: 181.863,
    yang_yes: 181.863,
  },
  {
    maesu: 4090,
    dan_no: 90.954,
    dan_yes: 90.954,
    yang_no: 181.907,
    yang_yes: 181.907,
  },
  {
    maesu: 4100,
    dan_no: 90.976,
    dan_yes: 90.976,
    yang_no: 181.951,
    yang_yes: 181.951,
  },
  {
    maesu: 4110,
    dan_no: 90.998,
    dan_yes: 90.998,
    yang_no: 181.995,
    yang_yes: 181.995,
  },
  {
    maesu: 4120,
    dan_no: 91.019,
    dan_yes: 91.019,
    yang_no: 182.039,
    yang_yes: 182.039,
  },
  {
    maesu: 4130,
    dan_no: 91.041,
    dan_yes: 91.041,
    yang_no: 182.082,
    yang_yes: 182.082,
  },
  {
    maesu: 4140,
    dan_no: 91.063,
    dan_yes: 91.063,
    yang_no: 182.126,
    yang_yes: 182.126,
  },
  {
    maesu: 4150,
    dan_no: 91.084,
    dan_yes: 91.084,
    yang_no: 182.169,
    yang_yes: 182.169,
  },
  {
    maesu: 4160,
    dan_no: 91.106,
    dan_yes: 91.106,
    yang_no: 182.212,
    yang_yes: 182.212,
  },
  {
    maesu: 4170,
    dan_no: 90.887,
    dan_yes: 90.887,
    yang_no: 181.775,
    yang_yes: 181.775,
  },
  {
    maesu: 4180,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 4190,
    dan_no: 90.931,
    dan_yes: 90.931,
    yang_no: 181.862,
    yang_yes: 181.862,
  },
  {
    maesu: 4200,
    dan_no: 90.952,
    dan_yes: 90.952,
    yang_no: 181.905,
    yang_yes: 181.905,
  },
  {
    maesu: 4210,
    dan_no: 90.974,
    dan_yes: 90.974,
    yang_no: 181.948,
    yang_yes: 181.948,
  },
  {
    maesu: 4220,
    dan_no: 90.995,
    dan_yes: 90.995,
    yang_no: 181.991,
    yang_yes: 181.991,
  },
  {
    maesu: 4230,
    dan_no: 91.017,
    dan_yes: 91.017,
    yang_no: 182.033,
    yang_yes: 182.033,
  },
  {
    maesu: 4240,
    dan_no: 91.038,
    dan_yes: 91.038,
    yang_no: 182.075,
    yang_yes: 182.075,
  },
  {
    maesu: 4250,
    dan_no: 91.059,
    dan_yes: 91.059,
    yang_no: 182.118,
    yang_yes: 182.118,
  },
  {
    maesu: 4260,
    dan_no: 91.08,
    dan_yes: 91.08,
    yang_no: 182.16,
    yang_yes: 182.16,
  },
  {
    maesu: 4270,
    dan_no: 91.101,
    dan_yes: 91.101,
    yang_no: 182.201,
    yang_yes: 182.201,
  },
  {
    maesu: 4280,
    dan_no: 90.888,
    dan_yes: 90.888,
    yang_no: 181.776,
    yang_yes: 181.776,
  },
  {
    maesu: 4290,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 4300,
    dan_no: 90.93,
    dan_yes: 90.93,
    yang_no: 181.86,
    yang_yes: 181.86,
  },
  {
    maesu: 4310,
    dan_no: 90.951,
    dan_yes: 90.951,
    yang_no: 181.903,
    yang_yes: 181.903,
  },
  {
    maesu: 4320,
    dan_no: 90.972,
    dan_yes: 90.972,
    yang_no: 181.944,
    yang_yes: 181.944,
  },
  {
    maesu: 4330,
    dan_no: 90.993,
    dan_yes: 90.993,
    yang_no: 181.986,
    yang_yes: 181.986,
  },
  {
    maesu: 4340,
    dan_no: 91.014,
    dan_yes: 91.014,
    yang_no: 182.028,
    yang_yes: 182.028,
  },
  {
    maesu: 4350,
    dan_no: 91.034,
    dan_yes: 91.034,
    yang_no: 182.069,
    yang_yes: 182.069,
  },
  {
    maesu: 4360,
    dan_no: 91.055,
    dan_yes: 91.055,
    yang_no: 182.11,
    yang_yes: 182.11,
  },
  {
    maesu: 4370,
    dan_no: 91.076,
    dan_yes: 91.076,
    yang_no: 182.151,
    yang_yes: 182.151,
  },
  {
    maesu: 4380,
    dan_no: 91.096,
    dan_yes: 91.096,
    yang_no: 182.192,
    yang_yes: 182.192,
  },
  {
    maesu: 4390,
    dan_no: 90.888,
    dan_yes: 90.888,
    yang_no: 181.777,
    yang_yes: 181.777,
  },
  {
    maesu: 4400,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 4410,
    dan_no: 90.93,
    dan_yes: 90.93,
    yang_no: 181.859,
    yang_yes: 181.859,
  },
  {
    maesu: 4420,
    dan_no: 90.95,
    dan_yes: 90.95,
    yang_no: 181.9,
    yang_yes: 181.9,
  },
  {
    maesu: 4430,
    dan_no: 90.971,
    dan_yes: 90.971,
    yang_no: 181.941,
    yang_yes: 181.941,
  },
  {
    maesu: 4440,
    dan_no: 90.991,
    dan_yes: 90.991,
    yang_no: 181.982,
    yang_yes: 181.982,
  },
  {
    maesu: 4450,
    dan_no: 91.011,
    dan_yes: 91.011,
    yang_no: 182.022,
    yang_yes: 182.022,
  },
  {
    maesu: 4460,
    dan_no: 91.031,
    dan_yes: 91.031,
    yang_no: 182.063,
    yang_yes: 182.063,
  },
  {
    maesu: 4470,
    dan_no: 91.051,
    dan_yes: 91.051,
    yang_no: 182.103,
    yang_yes: 182.103,
  },
  {
    maesu: 4480,
    dan_no: 91.071,
    dan_yes: 91.071,
    yang_no: 182.143,
    yang_yes: 182.143,
  },
  {
    maesu: 4490,
    dan_no: 91.091,
    dan_yes: 91.091,
    yang_no: 182.183,
    yang_yes: 182.183,
  },
  {
    maesu: 4500,
    dan_no: 91.111,
    dan_yes: 91.111,
    yang_no: 182.222,
    yang_yes: 182.222,
  },
  {
    maesu: 4510,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 4520,
    dan_no: 90.929,
    dan_yes: 90.929,
    yang_no: 181.858,
    yang_yes: 181.858,
  },
  {
    maesu: 4530,
    dan_no: 90.949,
    dan_yes: 90.949,
    yang_no: 181.898,
    yang_yes: 181.898,
  },
  {
    maesu: 4540,
    dan_no: 90.969,
    dan_yes: 90.969,
    yang_no: 181.938,
    yang_yes: 181.938,
  },
  {
    maesu: 4550,
    dan_no: 90.989,
    dan_yes: 90.989,
    yang_no: 181.978,
    yang_yes: 181.978,
  },
  {
    maesu: 4560,
    dan_no: 91.009,
    dan_yes: 91.009,
    yang_no: 182.018,
    yang_yes: 182.018,
  },
  {
    maesu: 4570,
    dan_no: 91.028,
    dan_yes: 91.028,
    yang_no: 182.057,
    yang_yes: 182.057,
  },
  {
    maesu: 4580,
    dan_no: 91.048,
    dan_yes: 91.048,
    yang_no: 182.096,
    yang_yes: 182.096,
  },
  {
    maesu: 4590,
    dan_no: 91.068,
    dan_yes: 91.068,
    yang_no: 182.135,
    yang_yes: 182.135,
  },
  {
    maesu: 4600,
    dan_no: 91.087,
    dan_yes: 91.087,
    yang_no: 182.174,
    yang_yes: 182.174,
  },
  {
    maesu: 4610,
    dan_no: 91.106,
    dan_yes: 91.106,
    yang_no: 182.213,
    yang_yes: 182.213,
  },
  {
    maesu: 4620,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 4630,
    dan_no: 90.929,
    dan_yes: 90.929,
    yang_no: 181.857,
    yang_yes: 181.857,
  },
  {
    maesu: 4640,
    dan_no: 90.948,
    dan_yes: 90.948,
    yang_no: 181.897,
    yang_yes: 181.897,
  },
  {
    maesu: 4650,
    dan_no: 90.968,
    dan_yes: 90.968,
    yang_no: 181.935,
    yang_yes: 181.935,
  },
  {
    maesu: 4660,
    dan_no: 90.987,
    dan_yes: 90.987,
    yang_no: 181.974,
    yang_yes: 181.974,
  },
  {
    maesu: 4670,
    dan_no: 91.006,
    dan_yes: 91.006,
    yang_no: 182.013,
    yang_yes: 182.013,
  },
  {
    maesu: 4680,
    dan_no: 91.026,
    dan_yes: 91.026,
    yang_no: 182.051,
    yang_yes: 182.051,
  },
  {
    maesu: 4690,
    dan_no: 91.045,
    dan_yes: 91.045,
    yang_no: 182.09,
    yang_yes: 182.09,
  },
  {
    maesu: 4700,
    dan_no: 91.064,
    dan_yes: 91.064,
    yang_no: 182.128,
    yang_yes: 182.128,
  },
  {
    maesu: 4710,
    dan_no: 91.083,
    dan_yes: 91.083,
    yang_no: 182.166,
    yang_yes: 182.166,
  },
  {
    maesu: 4720,
    dan_no: 91.102,
    dan_yes: 91.102,
    yang_no: 182.203,
    yang_yes: 182.203,
  },
  {
    maesu: 4730,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 4740,
    dan_no: 90.928,
    dan_yes: 90.928,
    yang_no: 181.857,
    yang_yes: 181.857,
  },
  {
    maesu: 4750,
    dan_no: 90.947,
    dan_yes: 90.947,
    yang_no: 181.895,
    yang_yes: 181.895,
  },
  {
    maesu: 4760,
    dan_no: 90.966,
    dan_yes: 90.966,
    yang_no: 181.933,
    yang_yes: 181.933,
  },
  {
    maesu: 4770,
    dan_no: 90.985,
    dan_yes: 90.985,
    yang_no: 181.971,
    yang_yes: 181.971,
  },
  {
    maesu: 4780,
    dan_no: 91.004,
    dan_yes: 91.004,
    yang_no: 182.008,
    yang_yes: 182.008,
  },
  {
    maesu: 4790,
    dan_no: 91.023,
    dan_yes: 91.023,
    yang_no: 182.046,
    yang_yes: 182.046,
  },
  {
    maesu: 4800,
    dan_no: 91.042,
    dan_yes: 91.042,
    yang_no: 182.083,
    yang_yes: 182.083,
  },
  {
    maesu: 4810,
    dan_no: 91.06,
    dan_yes: 91.06,
    yang_no: 182.121,
    yang_yes: 182.121,
  },
  {
    maesu: 4820,
    dan_no: 91.079,
    dan_yes: 91.079,
    yang_no: 182.158,
    yang_yes: 182.158,
  },
  {
    maesu: 4830,
    dan_no: 91.097,
    dan_yes: 91.097,
    yang_no: 182.195,
    yang_yes: 182.195,
  },
  {
    maesu: 4840,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 4850,
    dan_no: 90.928,
    dan_yes: 90.928,
    yang_no: 181.856,
    yang_yes: 181.856,
  },
  {
    maesu: 4860,
    dan_no: 90.947,
    dan_yes: 90.947,
    yang_no: 181.893,
    yang_yes: 181.893,
  },
  {
    maesu: 4870,
    dan_no: 90.965,
    dan_yes: 90.965,
    yang_no: 181.93,
    yang_yes: 181.93,
  },
  {
    maesu: 4880,
    dan_no: 90.984,
    dan_yes: 90.984,
    yang_no: 181.967,
    yang_yes: 181.967,
  },
  {
    maesu: 4890,
    dan_no: 91.002,
    dan_yes: 91.002,
    yang_no: 182.004,
    yang_yes: 182.004,
  },
  {
    maesu: 4900,
    dan_no: 91.02,
    dan_yes: 91.02,
    yang_no: 182.041,
    yang_yes: 182.041,
  },
  {
    maesu: 4910,
    dan_no: 91.039,
    dan_yes: 91.039,
    yang_no: 182.077,
    yang_yes: 182.077,
  },
  {
    maesu: 4920,
    dan_no: 91.057,
    dan_yes: 91.057,
    yang_no: 182.114,
    yang_yes: 182.114,
  },
  {
    maesu: 4930,
    dan_no: 91.075,
    dan_yes: 91.075,
    yang_no: 182.15,
    yang_yes: 182.15,
  },
  {
    maesu: 4940,
    dan_no: 91.093,
    dan_yes: 91.093,
    yang_no: 182.186,
    yang_yes: 182.186,
  },
  {
    maesu: 4950,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 4960,
    dan_no: 90.927,
    dan_yes: 90.927,
    yang_no: 181.855,
    yang_yes: 181.855,
  },
  {
    maesu: 4970,
    dan_no: 90.946,
    dan_yes: 90.946,
    yang_no: 181.891,
    yang_yes: 181.891,
  },
  {
    maesu: 4980,
    dan_no: 90.964,
    dan_yes: 90.964,
    yang_no: 181.928,
    yang_yes: 181.928,
  },
  {
    maesu: 4990,
    dan_no: 90.982,
    dan_yes: 90.982,
    yang_no: 181.964,
    yang_yes: 181.964,
  },
  {
    maesu: 5000,
    dan_no: 91,
    dan_yes: 91,
    yang_no: 182,
    yang_yes: 182,
  },
  {
    maesu: 5010,
    dan_no: 91.018,
    dan_yes: 91.018,
    yang_no: 182.036,
    yang_yes: 182.036,
  },
  {
    maesu: 5020,
    dan_no: 91.036,
    dan_yes: 91.036,
    yang_no: 182.072,
    yang_yes: 182.072,
  },
  {
    maesu: 5030,
    dan_no: 91.054,
    dan_yes: 91.054,
    yang_no: 182.107,
    yang_yes: 182.107,
  },
  {
    maesu: 5040,
    dan_no: 91.071,
    dan_yes: 91.071,
    yang_no: 182.143,
    yang_yes: 182.143,
  },
  {
    maesu: 5050,
    dan_no: 91.089,
    dan_yes: 91.089,
    yang_no: 182.178,
    yang_yes: 182.178,
  },
  {
    maesu: 5060,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 5070,
    dan_no: 90.927,
    dan_yes: 90.927,
    yang_no: 181.854,
    yang_yes: 181.854,
  },
  {
    maesu: 5080,
    dan_no: 90.945,
    dan_yes: 90.945,
    yang_no: 181.89,
    yang_yes: 181.89,
  },
  {
    maesu: 5090,
    dan_no: 90.963,
    dan_yes: 90.963,
    yang_no: 181.925,
    yang_yes: 181.925,
  },
  {
    maesu: 5100,
    dan_no: 90.98,
    dan_yes: 90.98,
    yang_no: 181.961,
    yang_yes: 181.961,
  },
  {
    maesu: 5110,
    dan_no: 90.998,
    dan_yes: 90.998,
    yang_no: 181.996,
    yang_yes: 181.996,
  },
  {
    maesu: 5120,
    dan_no: 91.016,
    dan_yes: 91.016,
    yang_no: 182.031,
    yang_yes: 182.031,
  },
  {
    maesu: 5130,
    dan_no: 91.033,
    dan_yes: 91.033,
    yang_no: 182.066,
    yang_yes: 182.066,
  },
  {
    maesu: 5140,
    dan_no: 91.051,
    dan_yes: 91.051,
    yang_no: 182.101,
    yang_yes: 182.101,
  },
  {
    maesu: 5150,
    dan_no: 91.068,
    dan_yes: 91.068,
    yang_no: 182.136,
    yang_yes: 182.136,
  },
  {
    maesu: 5160,
    dan_no: 91.085,
    dan_yes: 91.085,
    yang_no: 182.171,
    yang_yes: 182.171,
  },
  {
    maesu: 5170,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 5180,
    dan_no: 90.927,
    dan_yes: 90.927,
    yang_no: 181.853,
    yang_yes: 181.853,
  },
  {
    maesu: 5190,
    dan_no: 90.944,
    dan_yes: 90.944,
    yang_no: 181.888,
    yang_yes: 181.888,
  },
  {
    maesu: 5200,
    dan_no: 90.962,
    dan_yes: 90.962,
    yang_no: 181.923,
    yang_yes: 181.923,
  },
  {
    maesu: 5210,
    dan_no: 90.979,
    dan_yes: 90.979,
    yang_no: 181.958,
    yang_yes: 181.958,
  },
  {
    maesu: 5220,
    dan_no: 90.996,
    dan_yes: 90.996,
    yang_no: 181.992,
    yang_yes: 181.992,
  },
  {
    maesu: 5230,
    dan_no: 91.013,
    dan_yes: 91.013,
    yang_no: 182.027,
    yang_yes: 182.027,
  },
  {
    maesu: 5240,
    dan_no: 91.031,
    dan_yes: 91.031,
    yang_no: 182.061,
    yang_yes: 182.061,
  },
  {
    maesu: 5250,
    dan_no: 91.048,
    dan_yes: 91.048,
    yang_no: 182.095,
    yang_yes: 182.095,
  },
  {
    maesu: 5260,
    dan_no: 91.065,
    dan_yes: 91.065,
    yang_no: 182.129,
    yang_yes: 182.129,
  },
  {
    maesu: 5270,
    dan_no: 91.082,
    dan_yes: 91.082,
    yang_no: 182.163,
    yang_yes: 182.163,
  },
  {
    maesu: 5280,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 5290,
    dan_no: 90.926,
    dan_yes: 90.926,
    yang_no: 181.853,
    yang_yes: 181.853,
  },
  {
    maesu: 5300,
    dan_no: 90.943,
    dan_yes: 90.943,
    yang_no: 181.887,
    yang_yes: 181.887,
  },
  {
    maesu: 5310,
    dan_no: 90.96,
    dan_yes: 90.96,
    yang_no: 181.921,
    yang_yes: 181.921,
  },
  {
    maesu: 5320,
    dan_no: 90.977,
    dan_yes: 90.977,
    yang_no: 181.955,
    yang_yes: 181.955,
  },
  {
    maesu: 5330,
    dan_no: 90.994,
    dan_yes: 90.994,
    yang_no: 181.989,
    yang_yes: 181.989,
  },
  {
    maesu: 5340,
    dan_no: 91.011,
    dan_yes: 91.011,
    yang_no: 182.022,
    yang_yes: 182.022,
  },
  {
    maesu: 5350,
    dan_no: 91.028,
    dan_yes: 91.028,
    yang_no: 182.056,
    yang_yes: 182.056,
  },
  {
    maesu: 5360,
    dan_no: 91.045,
    dan_yes: 91.045,
    yang_no: 182.09,
    yang_yes: 182.09,
  },
  {
    maesu: 5370,
    dan_no: 91.061,
    dan_yes: 91.061,
    yang_no: 182.123,
    yang_yes: 182.123,
  },
  {
    maesu: 5380,
    dan_no: 91.078,
    dan_yes: 91.078,
    yang_no: 182.156,
    yang_yes: 182.156,
  },
  {
    maesu: 5390,
    dan_no: 90.909,
    dan_yes: 90.909,
    yang_no: 181.818,
    yang_yes: 181.818,
  },
  {
    maesu: 5400,
    dan_no: 90.926,
    dan_yes: 90.926,
    yang_no: 181.852,
    yang_yes: 181.852,
  },
  {
    maesu: 5410,
    dan_no: 90.943,
    dan_yes: 90.943,
    yang_no: 181.885,
    yang_yes: 181.885,
  },
  {
    maesu: 5420,
    dan_no: 90.959,
    dan_yes: 90.959,
    yang_no: 181.919,
    yang_yes: 181.919,
  },
  {
    maesu: 5430,
    dan_no: 90.976,
    dan_yes: 90.976,
    yang_no: 181.952,
    yang_yes: 181.952,
  },
  {
    maesu: 5440,
    dan_no: 90.993,
    dan_yes: 90.993,
    yang_no: 181.985,
    yang_yes: 181.985,
  },
  {
    maesu: 5450,
    dan_no: 91.009,
    dan_yes: 91.009,
    yang_no: 182.018,
    yang_yes: 182.018,
  },
  {
    maesu: 5460,
    dan_no: 91.026,
    dan_yes: 91.026,
    yang_no: 182.051,
    yang_yes: 182.051,
  },
  {
    maesu: 5470,
    dan_no: 91.042,
    dan_yes: 91.042,
    yang_no: 182.084,
    yang_yes: 182.084,
  },
  {
    maesu: 5480,
    dan_no: 91.058,
    dan_yes: 91.058,
    yang_no: 182.117,
    yang_yes: 182.117,
  },
  {
    maesu: 5490,
    dan_no: 91.075,
    dan_yes: 91.075,
    yang_no: 182.149,
    yang_yes: 182.149,
  },
  {
    maesu: 5500,
    dan_no: 91.091,
    dan_yes: 91.091,
    yang_no: 182.182,
    yang_yes: 182.182,
  },
  {
    maesu: 5510,
    dan_no: 90.926,
    dan_yes: 90.926,
    yang_no: 181.851,
    yang_yes: 181.851,
  },
  {
    maesu: 5520,
    dan_no: 90.942,
    dan_yes: 90.942,
    yang_no: 181.884,
    yang_yes: 181.884,
  },
  {
    maesu: 5530,
    dan_no: 90.958,
    dan_yes: 90.958,
    yang_no: 181.917,
    yang_yes: 181.917,
  },
  {
    maesu: 5540,
    dan_no: 90.975,
    dan_yes: 90.975,
    yang_no: 181.949,
    yang_yes: 181.949,
  },
  {
    maesu: 5550,
    dan_no: 90.991,
    dan_yes: 90.991,
    yang_no: 181.982,
    yang_yes: 181.982,
  },
  {
    maesu: 5560,
    dan_no: 91.007,
    dan_yes: 91.007,
    yang_no: 182.014,
    yang_yes: 182.014,
  },
  {
    maesu: 5570,
    dan_no: 91.023,
    dan_yes: 91.023,
    yang_no: 182.047,
    yang_yes: 182.047,
  },
  {
    maesu: 5580,
    dan_no: 91.039,
    dan_yes: 91.039,
    yang_no: 182.079,
    yang_yes: 182.079,
  },
  {
    maesu: 5590,
    dan_no: 91.055,
    dan_yes: 91.055,
    yang_no: 182.111,
    yang_yes: 182.111,
  },
  {
    maesu: 5600,
    dan_no: 91.071,
    dan_yes: 91.071,
    yang_no: 182.143,
    yang_yes: 182.143,
  },
  {
    maesu: 5610,
    dan_no: 91.087,
    dan_yes: 91.087,
    yang_no: 182.175,
    yang_yes: 182.175,
  },
  {
    maesu: 5620,
    dan_no: 90.925,
    dan_yes: 90.925,
    yang_no: 181.851,
    yang_yes: 181.851,
  },
  {
    maesu: 5630,
    dan_no: 90.941,
    dan_yes: 90.941,
    yang_no: 181.883,
    yang_yes: 181.883,
  },
  {
    maesu: 5640,
    dan_no: 90.957,
    dan_yes: 90.957,
    yang_no: 181.915,
    yang_yes: 181.915,
  },
  {
    maesu: 5650,
    dan_no: 90.973,
    dan_yes: 90.973,
    yang_no: 181.947,
    yang_yes: 181.947,
  },
  {
    maesu: 5660,
    dan_no: 90.989,
    dan_yes: 90.989,
    yang_no: 181.979,
    yang_yes: 181.979,
  },
  {
    maesu: 5670,
    dan_no: 91.005,
    dan_yes: 91.005,
    yang_no: 182.011,
    yang_yes: 182.011,
  },
  {
    maesu: 5680,
    dan_no: 91.021,
    dan_yes: 91.021,
    yang_no: 182.042,
    yang_yes: 182.042,
  },
  {
    maesu: 5690,
    dan_no: 91.037,
    dan_yes: 91.037,
    yang_no: 182.074,
    yang_yes: 182.074,
  },
  {
    maesu: 5700,
    dan_no: 91.053,
    dan_yes: 91.053,
    yang_no: 182.105,
    yang_yes: 182.105,
  },
  {
    maesu: 5710,
    dan_no: 91.068,
    dan_yes: 91.068,
    yang_no: 182.137,
    yang_yes: 182.137,
  },
  {
    maesu: 5720,
    dan_no: 91.084,
    dan_yes: 91.084,
    yang_no: 182.168,
    yang_yes: 182.168,
  },
  {
    maesu: 5730,
    dan_no: 90.925,
    dan_yes: 90.925,
    yang_no: 181.85,
    yang_yes: 181.85,
  },
  {
    maesu: 5740,
    dan_no: 90.941,
    dan_yes: 90.941,
    yang_no: 181.882,
    yang_yes: 181.882,
  },
  {
    maesu: 5750,
    dan_no: 90.957,
    dan_yes: 90.957,
    yang_no: 181.913,
    yang_yes: 181.913,
  },
  {
    maesu: 5760,
    dan_no: 90.972,
    dan_yes: 90.972,
    yang_no: 181.944,
    yang_yes: 181.944,
  },
  {
    maesu: 5770,
    dan_no: 90.988,
    dan_yes: 90.988,
    yang_no: 181.976,
    yang_yes: 181.976,
  },
  {
    maesu: 5780,
    dan_no: 91.003,
    dan_yes: 91.003,
    yang_no: 182.007,
    yang_yes: 182.007,
  },
  {
    maesu: 5790,
    dan_no: 91.019,
    dan_yes: 91.019,
    yang_no: 182.038,
    yang_yes: 182.038,
  },
  {
    maesu: 5800,
    dan_no: 91.034,
    dan_yes: 91.034,
    yang_no: 182.069,
    yang_yes: 182.069,
  },
  {
    maesu: 5810,
    dan_no: 91.05,
    dan_yes: 91.05,
    yang_no: 182.1,
    yang_yes: 182.1,
  },
  {
    maesu: 5820,
    dan_no: 91.065,
    dan_yes: 91.065,
    yang_no: 182.131,
    yang_yes: 182.131,
  },
  {
    maesu: 5830,
    dan_no: 91.081,
    dan_yes: 91.081,
    yang_no: 182.161,
    yang_yes: 182.161,
  },
  {
    maesu: 5840,
    dan_no: 90.925,
    dan_yes: 90.925,
    yang_no: 181.849,
    yang_yes: 181.849,
  },
  {
    maesu: 5850,
    dan_no: 90.94,
    dan_yes: 90.94,
    yang_no: 181.88,
    yang_yes: 181.88,
  },
  {
    maesu: 5860,
    dan_no: 90.956,
    dan_yes: 90.956,
    yang_no: 181.911,
    yang_yes: 181.911,
  },
  {
    maesu: 5870,
    dan_no: 90.971,
    dan_yes: 90.971,
    yang_no: 181.942,
    yang_yes: 181.942,
  },
  {
    maesu: 5880,
    dan_no: 90.986,
    dan_yes: 90.986,
    yang_no: 181.973,
    yang_yes: 181.973,
  },
  {
    maesu: 5890,
    dan_no: 91.002,
    dan_yes: 91.002,
    yang_no: 182.003,
    yang_yes: 182.003,
  },
  {
    maesu: 5900,
    dan_no: 91.017,
    dan_yes: 91.017,
    yang_no: 182.034,
    yang_yes: 182.034,
  },
  {
    maesu: 5910,
    dan_no: 91.032,
    dan_yes: 91.032,
    yang_no: 182.064,
    yang_yes: 182.064,
  },
  {
    maesu: 5920,
    dan_no: 91.047,
    dan_yes: 91.047,
    yang_no: 182.095,
    yang_yes: 182.095,
  },
  {
    maesu: 5930,
    dan_no: 91.062,
    dan_yes: 91.062,
    yang_no: 182.125,
    yang_yes: 182.125,
  },
  {
    maesu: 5940,
    dan_no: 91.077,
    dan_yes: 91.077,
    yang_no: 182.155,
    yang_yes: 182.155,
  },
  {
    maesu: 5950,
    dan_no: 90.924,
    dan_yes: 90.924,
    yang_no: 181.849,
    yang_yes: 181.849,
  },
  {
    maesu: 5960,
    dan_no: 90.94,
    dan_yes: 90.94,
    yang_no: 181.879,
    yang_yes: 181.879,
  },
  {
    maesu: 5970,
    dan_no: 90.955,
    dan_yes: 90.955,
    yang_no: 181.91,
    yang_yes: 181.91,
  },
  {
    maesu: 5980,
    dan_no: 90.97,
    dan_yes: 90.97,
    yang_no: 181.94,
    yang_yes: 181.94,
  },
  {
    maesu: 5990,
    dan_no: 90.985,
    dan_yes: 90.985,
    yang_no: 181.97,
    yang_yes: 181.97,
  },
  {
    maesu: 6000,
    dan_no: 91,
    dan_yes: 91,
    yang_no: 182,
    yang_yes: 182,
  },
  {
    maesu: 6010,
    dan_no: 91.015,
    dan_yes: 91.015,
    yang_no: 182.03,
    yang_yes: 182.03,
  },
  {
    maesu: 6020,
    dan_no: 91.03,
    dan_yes: 91.03,
    yang_no: 182.06,
    yang_yes: 182.06,
  },
  {
    maesu: 6030,
    dan_no: 91.045,
    dan_yes: 91.045,
    yang_no: 182.09,
    yang_yes: 182.09,
  },
  {
    maesu: 6040,
    dan_no: 91.06,
    dan_yes: 91.06,
    yang_no: 182.119,
    yang_yes: 182.119,
  },
  {
    maesu: 6050,
    dan_no: 91.074,
    dan_yes: 91.074,
    yang_no: 182.149,
    yang_yes: 182.149,
  },
  {
    maesu: 6060,
    dan_no: 90.924,
    dan_yes: 90.924,
    yang_no: 181.848,
    yang_yes: 181.848,
  },
  {
    maesu: 6070,
    dan_no: 90.939,
    dan_yes: 90.939,
    yang_no: 181.878,
    yang_yes: 181.878,
  },
  {
    maesu: 6080,
    dan_no: 90.954,
    dan_yes: 90.954,
    yang_no: 181.908,
    yang_yes: 181.908,
  },
  {
    maesu: 6090,
    dan_no: 90.969,
    dan_yes: 90.969,
    yang_no: 181.938,
    yang_yes: 181.938,
  },
  {
    maesu: 6100,
    dan_no: 90.984,
    dan_yes: 90.984,
    yang_no: 181.967,
    yang_yes: 181.967,
  },
  {
    maesu: 6110,
    dan_no: 90.998,
    dan_yes: 90.998,
    yang_no: 181.997,
    yang_yes: 181.997,
  },
  {
    maesu: 6120,
    dan_no: 91.013,
    dan_yes: 91.013,
    yang_no: 182.026,
    yang_yes: 182.026,
  },
  {
    maesu: 6130,
    dan_no: 91.028,
    dan_yes: 91.028,
    yang_no: 182.055,
    yang_yes: 182.055,
  },
  {
    maesu: 6140,
    dan_no: 91.042,
    dan_yes: 91.042,
    yang_no: 182.085,
    yang_yes: 182.085,
  },
  {
    maesu: 6150,
    dan_no: 91.057,
    dan_yes: 91.057,
    yang_no: 182.114,
    yang_yes: 182.114,
  },
  {
    maesu: 6160,
    dan_no: 91.071,
    dan_yes: 91.071,
    yang_no: 182.143,
    yang_yes: 182.143,
  },
  {
    maesu: 6170,
    dan_no: 90.924,
    dan_yes: 90.924,
    yang_no: 181.848,
    yang_yes: 181.848,
  },
  {
    maesu: 6180,
    dan_no: 90.939,
    dan_yes: 90.939,
    yang_no: 181.877,
    yang_yes: 181.877,
  },
  {
    maesu: 6190,
    dan_no: 90.953,
    dan_yes: 90.953,
    yang_no: 181.906,
    yang_yes: 181.906,
  },
  {
    maesu: 6200,
    dan_no: 90.968,
    dan_yes: 90.968,
    yang_no: 181.935,
    yang_yes: 181.935,
  },
  {
    maesu: 6210,
    dan_no: 90.982,
    dan_yes: 90.982,
    yang_no: 181.965,
    yang_yes: 181.965,
  },
  {
    maesu: 6220,
    dan_no: 90.997,
    dan_yes: 90.997,
    yang_no: 181.994,
    yang_yes: 181.994,
  },
  {
    maesu: 6230,
    dan_no: 91.011,
    dan_yes: 91.011,
    yang_no: 182.022,
    yang_yes: 182.022,
  },
  {
    maesu: 6240,
    dan_no: 91.026,
    dan_yes: 91.026,
    yang_no: 182.051,
    yang_yes: 182.051,
  },
  {
    maesu: 6250,
    dan_no: 91.04,
    dan_yes: 91.04,
    yang_no: 182.08,
    yang_yes: 182.08,
  },
  {
    maesu: 6260,
    dan_no: 91.054,
    dan_yes: 91.054,
    yang_no: 182.109,
    yang_yes: 182.109,
  },
  {
    maesu: 6270,
    dan_no: 91.069,
    dan_yes: 91.069,
    yang_no: 182.137,
    yang_yes: 182.137,
  },
  {
    maesu: 6280,
    dan_no: 90.924,
    dan_yes: 90.924,
    yang_no: 181.847,
    yang_yes: 181.847,
  },
  {
    maesu: 6290,
    dan_no: 90.938,
    dan_yes: 90.938,
    yang_no: 181.876,
    yang_yes: 181.876,
  },
  {
    maesu: 6300,
    dan_no: 90.952,
    dan_yes: 90.952,
    yang_no: 181.905,
    yang_yes: 181.905,
  },
  {
    maesu: 6310,
    dan_no: 90.967,
    dan_yes: 90.967,
    yang_no: 181.933,
    yang_yes: 181.933,
  },
  {
    maesu: 6320,
    dan_no: 90.981,
    dan_yes: 90.981,
    yang_no: 181.962,
    yang_yes: 181.962,
  },
  {
    maesu: 6330,
    dan_no: 90.995,
    dan_yes: 90.995,
    yang_no: 181.991,
    yang_yes: 181.991,
  },
  {
    maesu: 6340,
    dan_no: 91.009,
    dan_yes: 91.009,
    yang_no: 182.019,
    yang_yes: 182.019,
  },
  {
    maesu: 6350,
    dan_no: 91.024,
    dan_yes: 91.024,
    yang_no: 182.047,
    yang_yes: 182.047,
  },
  {
    maesu: 6360,
    dan_no: 91.038,
    dan_yes: 91.038,
    yang_no: 182.075,
    yang_yes: 182.075,
  },
  {
    maesu: 6370,
    dan_no: 91.052,
    dan_yes: 91.052,
    yang_no: 182.104,
    yang_yes: 182.104,
  },
  {
    maesu: 6380,
    dan_no: 91.066,
    dan_yes: 91.066,
    yang_no: 182.132,
    yang_yes: 182.132,
  },
  {
    maesu: 6390,
    dan_no: 90.923,
    dan_yes: 90.923,
    yang_no: 181.847,
    yang_yes: 181.847,
  },
  {
    maesu: 6400,
    dan_no: 90.938,
    dan_yes: 90.938,
    yang_no: 181.875,
    yang_yes: 181.875,
  },
  {
    maesu: 6410,
    dan_no: 90.952,
    dan_yes: 90.952,
    yang_no: 181.903,
    yang_yes: 181.903,
  },
  {
    maesu: 6420,
    dan_no: 90.966,
    dan_yes: 90.966,
    yang_no: 181.931,
    yang_yes: 181.931,
  },
  {
    maesu: 6430,
    dan_no: 90.98,
    dan_yes: 90.98,
    yang_no: 181.96,
    yang_yes: 181.96,
  },
  {
    maesu: 6440,
    dan_no: 90.994,
    dan_yes: 90.994,
    yang_no: 181.988,
    yang_yes: 181.988,
  },
  {
    maesu: 6450,
    dan_no: 91.008,
    dan_yes: 91.008,
    yang_no: 182.016,
    yang_yes: 182.016,
  },
  {
    maesu: 6460,
    dan_no: 91.022,
    dan_yes: 91.022,
    yang_no: 182.043,
    yang_yes: 182.043,
  },
  {
    maesu: 6470,
    dan_no: 91.036,
    dan_yes: 91.036,
    yang_no: 182.071,
    yang_yes: 182.071,
  },
  {
    maesu: 6480,
    dan_no: 91.049,
    dan_yes: 91.049,
    yang_no: 182.099,
    yang_yes: 182.099,
  },
  {
    maesu: 6490,
    dan_no: 91.063,
    dan_yes: 91.063,
    yang_no: 182.126,
    yang_yes: 182.126,
  },
  {
    maesu: 6500,
    dan_no: 91.077,
    dan_yes: 91.077,
    yang_no: 182.154,
    yang_yes: 182.154,
  },
  {
    maesu: 6510,
    dan_no: 90.937,
    dan_yes: 90.937,
    yang_no: 181.874,
    yang_yes: 181.874,
  },
  {
    maesu: 6520,
    dan_no: 90.951,
    dan_yes: 90.951,
    yang_no: 181.902,
    yang_yes: 181.902,
  },
  {
    maesu: 6530,
    dan_no: 90.965,
    dan_yes: 90.965,
    yang_no: 181.93,
    yang_yes: 181.93,
  },
  {
    maesu: 6540,
    dan_no: 90.979,
    dan_yes: 90.979,
    yang_no: 181.957,
    yang_yes: 181.957,
  },
  {
    maesu: 6550,
    dan_no: 90.992,
    dan_yes: 90.992,
    yang_no: 181.985,
    yang_yes: 181.985,
  },
  {
    maesu: 6560,
    dan_no: 91.006,
    dan_yes: 91.006,
    yang_no: 182.012,
    yang_yes: 182.012,
  },
  {
    maesu: 6570,
    dan_no: 91.02,
    dan_yes: 91.02,
    yang_no: 182.04,
    yang_yes: 182.04,
  },
  {
    maesu: 6580,
    dan_no: 91.033,
    dan_yes: 91.033,
    yang_no: 182.067,
    yang_yes: 182.067,
  },
  {
    maesu: 6590,
    dan_no: 91.047,
    dan_yes: 91.047,
    yang_no: 182.094,
    yang_yes: 182.094,
  },
  {
    maesu: 6600,
    dan_no: 91.061,
    dan_yes: 91.061,
    yang_no: 182.121,
    yang_yes: 182.121,
  },
  {
    maesu: 6610,
    dan_no: 91.074,
    dan_yes: 91.074,
    yang_no: 182.148,
    yang_yes: 182.148,
  },
  {
    maesu: 6620,
    dan_no: 90.937,
    dan_yes: 90.937,
    yang_no: 181.873,
    yang_yes: 181.873,
  },
  {
    maesu: 6630,
    dan_no: 90.95,
    dan_yes: 90.95,
    yang_no: 181.9,
    yang_yes: 181.9,
  },
  {
    maesu: 6640,
    dan_no: 90.964,
    dan_yes: 90.964,
    yang_no: 181.928,
    yang_yes: 181.928,
  },
  {
    maesu: 6650,
    dan_no: 90.977,
    dan_yes: 90.977,
    yang_no: 181.955,
    yang_yes: 181.955,
  },
  {
    maesu: 6660,
    dan_no: 90.991,
    dan_yes: 90.991,
    yang_no: 181.982,
    yang_yes: 181.982,
  },
  {
    maesu: 6670,
    dan_no: 91.004,
    dan_yes: 91.004,
    yang_no: 182.009,
    yang_yes: 182.009,
  },
  {
    maesu: 6680,
    dan_no: 91.018,
    dan_yes: 91.018,
    yang_no: 182.036,
    yang_yes: 182.036,
  },
  {
    maesu: 6690,
    dan_no: 91.031,
    dan_yes: 91.031,
    yang_no: 182.063,
    yang_yes: 182.063,
  },
  {
    maesu: 6700,
    dan_no: 91.045,
    dan_yes: 91.045,
    yang_no: 182.09,
    yang_yes: 182.09,
  },
  {
    maesu: 6710,
    dan_no: 91.058,
    dan_yes: 91.058,
    yang_no: 182.116,
    yang_yes: 182.116,
  },
  {
    maesu: 6720,
    dan_no: 91.071,
    dan_yes: 91.071,
    yang_no: 182.143,
    yang_yes: 182.143,
  },
  {
    maesu: 6730,
    dan_no: 90.936,
    dan_yes: 90.936,
    yang_no: 181.872,
    yang_yes: 181.872,
  },
  {
    maesu: 6740,
    dan_no: 90.95,
    dan_yes: 90.95,
    yang_no: 181.899,
    yang_yes: 181.899,
  },
  {
    maesu: 6750,
    dan_no: 90.963,
    dan_yes: 90.963,
    yang_no: 181.926,
    yang_yes: 181.926,
  },
  {
    maesu: 6760,
    dan_no: 90.976,
    dan_yes: 90.976,
    yang_no: 181.953,
    yang_yes: 181.953,
  },
  {
    maesu: 6770,
    dan_no: 90.99,
    dan_yes: 90.99,
    yang_no: 181.979,
    yang_yes: 181.979,
  },
  {
    maesu: 6780,
    dan_no: 91.003,
    dan_yes: 91.003,
    yang_no: 182.006,
    yang_yes: 182.006,
  },
  {
    maesu: 6790,
    dan_no: 91.016,
    dan_yes: 91.016,
    yang_no: 182.032,
    yang_yes: 182.032,
  },
  {
    maesu: 6800,
    dan_no: 91.029,
    dan_yes: 91.029,
    yang_no: 182.059,
    yang_yes: 182.059,
  },
  {
    maesu: 6810,
    dan_no: 91.043,
    dan_yes: 91.043,
    yang_no: 182.085,
    yang_yes: 182.085,
  },
  {
    maesu: 6820,
    dan_no: 91.056,
    dan_yes: 91.056,
    yang_no: 182.111,
    yang_yes: 182.111,
  },
  {
    maesu: 6830,
    dan_no: 91.069,
    dan_yes: 91.069,
    yang_no: 182.138,
    yang_yes: 182.138,
  },
  {
    maesu: 6840,
    dan_no: 90.936,
    dan_yes: 90.936,
    yang_no: 181.871,
    yang_yes: 181.871,
  },
  {
    maesu: 6850,
    dan_no: 90.949,
    dan_yes: 90.949,
    yang_no: 181.898,
    yang_yes: 181.898,
  },
  {
    maesu: 6860,
    dan_no: 90.962,
    dan_yes: 90.962,
    yang_no: 181.924,
    yang_yes: 181.924,
  },
  {
    maesu: 6870,
    dan_no: 90.975,
    dan_yes: 90.975,
    yang_no: 181.951,
    yang_yes: 181.951,
  },
  {
    maesu: 6880,
    dan_no: 90.988,
    dan_yes: 90.988,
    yang_no: 181.977,
    yang_yes: 181.977,
  },
  {
    maesu: 6890,
    dan_no: 91.001,
    dan_yes: 91.001,
    yang_no: 182.003,
    yang_yes: 182.003,
  },
  {
    maesu: 6900,
    dan_no: 91.014,
    dan_yes: 91.014,
    yang_no: 182.029,
    yang_yes: 182.029,
  },
  {
    maesu: 6910,
    dan_no: 91.027,
    dan_yes: 91.027,
    yang_no: 182.055,
    yang_yes: 182.055,
  },
  {
    maesu: 6920,
    dan_no: 91.04,
    dan_yes: 91.04,
    yang_no: 182.081,
    yang_yes: 182.081,
  },
  {
    maesu: 6930,
    dan_no: 91.053,
    dan_yes: 91.053,
    yang_no: 182.107,
    yang_yes: 182.107,
  },
  {
    maesu: 6940,
    dan_no: 91.066,
    dan_yes: 91.066,
    yang_no: 182.133,
    yang_yes: 182.133,
  },
  {
    maesu: 6950,
    dan_no: 90.935,
    dan_yes: 90.935,
    yang_no: 181.871,
    yang_yes: 181.871,
  },
  {
    maesu: 6960,
    dan_no: 90.948,
    dan_yes: 90.948,
    yang_no: 181.897,
    yang_yes: 181.897,
  },
  {
    maesu: 6970,
    dan_no: 90.961,
    dan_yes: 90.961,
    yang_no: 181.923,
    yang_yes: 181.923,
  },
  {
    maesu: 6980,
    dan_no: 90.974,
    dan_yes: 90.974,
    yang_no: 181.948,
    yang_yes: 181.948,
  },
  {
    maesu: 6990,
    dan_no: 90.987,
    dan_yes: 90.987,
    yang_no: 181.974,
    yang_yes: 181.974,
  },
  {
    maesu: 7000,
    dan_no: 91,
    dan_yes: 91,
    yang_no: 182,
    yang_yes: 182,
  },
  {
    maesu: 7010,
    dan_no: 91.013,
    dan_yes: 91.013,
    yang_no: 182.026,
    yang_yes: 182.026,
  },
  {
    maesu: 7020,
    dan_no: 91.026,
    dan_yes: 91.026,
    yang_no: 182.051,
    yang_yes: 182.051,
  },
  {
    maesu: 7030,
    dan_no: 91.038,
    dan_yes: 91.038,
    yang_no: 182.077,
    yang_yes: 182.077,
  },
  {
    maesu: 7040,
    dan_no: 91.051,
    dan_yes: 91.051,
    yang_no: 182.102,
    yang_yes: 182.102,
  },
  {
    maesu: 7050,
    dan_no: 91.064,
    dan_yes: 91.064,
    yang_no: 182.128,
    yang_yes: 182.128,
  },
  {
    maesu: 7060,
    dan_no: 90.935,
    dan_yes: 90.935,
    yang_no: 181.87,
    yang_yes: 181.87,
  },
  {
    maesu: 7070,
    dan_no: 90.948,
    dan_yes: 90.948,
    yang_no: 181.895,
    yang_yes: 181.895,
  },
  {
    maesu: 7080,
    dan_no: 90.96,
    dan_yes: 90.96,
    yang_no: 181.921,
    yang_yes: 181.921,
  },
  {
    maesu: 7090,
    dan_no: 90.973,
    dan_yes: 90.973,
    yang_no: 181.946,
    yang_yes: 181.946,
  },
  {
    maesu: 7100,
    dan_no: 90.986,
    dan_yes: 90.986,
    yang_no: 181.972,
    yang_yes: 181.972,
  },
  {
    maesu: 7110,
    dan_no: 90.999,
    dan_yes: 90.999,
    yang_no: 181.997,
    yang_yes: 181.997,
  },
  {
    maesu: 7120,
    dan_no: 91.011,
    dan_yes: 91.011,
    yang_no: 182.022,
    yang_yes: 182.022,
  },
  {
    maesu: 7130,
    dan_no: 91.024,
    dan_yes: 91.024,
    yang_no: 182.048,
    yang_yes: 182.048,
  },
  {
    maesu: 7140,
    dan_no: 91.036,
    dan_yes: 91.036,
    yang_no: 182.073,
    yang_yes: 182.073,
  },
  {
    maesu: 7150,
    dan_no: 91.049,
    dan_yes: 91.049,
    yang_no: 182.098,
    yang_yes: 182.098,
  },
  {
    maesu: 7160,
    dan_no: 91.061,
    dan_yes: 91.061,
    yang_no: 182.123,
    yang_yes: 182.123,
  },
  {
    maesu: 7170,
    dan_no: 90.934,
    dan_yes: 90.934,
    yang_no: 181.869,
    yang_yes: 181.869,
  },
  {
    maesu: 7180,
    dan_no: 90.947,
    dan_yes: 90.947,
    yang_no: 181.894,
    yang_yes: 181.894,
  },
  {
    maesu: 7190,
    dan_no: 90.96,
    dan_yes: 90.96,
    yang_no: 181.919,
    yang_yes: 181.919,
  },
  {
    maesu: 7200,
    dan_no: 90.972,
    dan_yes: 90.972,
    yang_no: 181.944,
    yang_yes: 181.944,
  },
  {
    maesu: 7210,
    dan_no: 90.985,
    dan_yes: 90.985,
    yang_no: 181.969,
    yang_yes: 181.969,
  },
  {
    maesu: 7220,
    dan_no: 90.997,
    dan_yes: 90.997,
    yang_no: 181.994,
    yang_yes: 181.994,
  },
  {
    maesu: 7230,
    dan_no: 91.01,
    dan_yes: 91.01,
    yang_no: 182.019,
    yang_yes: 182.019,
  },
  {
    maesu: 7240,
    dan_no: 91.022,
    dan_yes: 91.022,
    yang_no: 182.044,
    yang_yes: 182.044,
  },
  {
    maesu: 7250,
    dan_no: 91.034,
    dan_yes: 91.034,
    yang_no: 182.069,
    yang_yes: 182.069,
  },
  {
    maesu: 7260,
    dan_no: 91.047,
    dan_yes: 91.047,
    yang_no: 182.094,
    yang_yes: 182.094,
  },
  {
    maesu: 7270,
    dan_no: 91.059,
    dan_yes: 91.059,
    yang_no: 182.118,
    yang_yes: 182.118,
  },
  {
    maesu: 7280,
    dan_no: 90.934,
    dan_yes: 90.934,
    yang_no: 181.868,
    yang_yes: 181.868,
  },
  {
    maesu: 7290,
    dan_no: 90.947,
    dan_yes: 90.947,
    yang_no: 181.893,
    yang_yes: 181.893,
  },
  {
    maesu: 7300,
    dan_no: 90.959,
    dan_yes: 90.959,
    yang_no: 181.918,
    yang_yes: 181.918,
  },
  {
    maesu: 7310,
    dan_no: 90.971,
    dan_yes: 90.971,
    yang_no: 181.943,
    yang_yes: 181.943,
  },
  {
    maesu: 7320,
    dan_no: 90.984,
    dan_yes: 90.984,
    yang_no: 181.967,
    yang_yes: 181.967,
  },
  {
    maesu: 7330,
    dan_no: 90.996,
    dan_yes: 90.996,
    yang_no: 181.992,
    yang_yes: 181.992,
  },
  {
    maesu: 7340,
    dan_no: 91.008,
    dan_yes: 91.008,
    yang_no: 182.016,
    yang_yes: 182.016,
  },
  {
    maesu: 7350,
    dan_no: 91.02,
    dan_yes: 91.02,
    yang_no: 182.041,
    yang_yes: 182.041,
  },
  {
    maesu: 7360,
    dan_no: 91.033,
    dan_yes: 91.033,
    yang_no: 182.065,
    yang_yes: 182.065,
  },
  {
    maesu: 7370,
    dan_no: 91.045,
    dan_yes: 91.045,
    yang_no: 182.09,
    yang_yes: 182.09,
  },
  {
    maesu: 7380,
    dan_no: 91.057,
    dan_yes: 91.057,
    yang_no: 182.114,
    yang_yes: 182.114,
  },
  {
    maesu: 7390,
    dan_no: 90.934,
    dan_yes: 90.934,
    yang_no: 181.867,
    yang_yes: 181.867,
  },
  {
    maesu: 7400,
    dan_no: 90.946,
    dan_yes: 90.946,
    yang_no: 181.892,
    yang_yes: 181.892,
  },
  {
    maesu: 7410,
    dan_no: 90.958,
    dan_yes: 90.958,
    yang_no: 181.916,
    yang_yes: 181.916,
  },
  {
    maesu: 7420,
    dan_no: 90.97,
    dan_yes: 90.97,
    yang_no: 181.941,
    yang_yes: 181.941,
  },
  {
    maesu: 7430,
    dan_no: 90.983,
    dan_yes: 90.983,
    yang_no: 181.965,
    yang_yes: 181.965,
  },
  {
    maesu: 7440,
    dan_no: 90.995,
    dan_yes: 90.995,
    yang_no: 181.989,
    yang_yes: 181.989,
  },
  {
    maesu: 7450,
    dan_no: 91.007,
    dan_yes: 91.007,
    yang_no: 182.013,
    yang_yes: 182.013,
  },
  {
    maesu: 7460,
    dan_no: 91.019,
    dan_yes: 91.019,
    yang_no: 182.038,
    yang_yes: 182.038,
  },
  {
    maesu: 7470,
    dan_no: 91.031,
    dan_yes: 91.031,
    yang_no: 182.062,
    yang_yes: 182.062,
  },
  {
    maesu: 7480,
    dan_no: 91.043,
    dan_yes: 91.043,
    yang_no: 182.086,
    yang_yes: 182.086,
  },
  {
    maesu: 7490,
    dan_no: 91.055,
    dan_yes: 91.055,
    yang_no: 182.109,
    yang_yes: 182.109,
  },
  {
    maesu: 7500,
    dan_no: 91.067,
    dan_yes: 91.067,
    yang_no: 182.133,
    yang_yes: 182.133,
  },
  {
    maesu: 7510,
    dan_no: 90.945,
    dan_yes: 90.945,
    yang_no: 181.891,
    yang_yes: 181.891,
  },
  {
    maesu: 7520,
    dan_no: 90.957,
    dan_yes: 90.957,
    yang_no: 181.915,
    yang_yes: 181.915,
  },
  {
    maesu: 7530,
    dan_no: 90.969,
    dan_yes: 90.969,
    yang_no: 181.939,
    yang_yes: 181.939,
  },
  {
    maesu: 7540,
    dan_no: 90.981,
    dan_yes: 90.981,
    yang_no: 181.963,
    yang_yes: 181.963,
  },
  {
    maesu: 7550,
    dan_no: 90.993,
    dan_yes: 90.993,
    yang_no: 181.987,
    yang_yes: 181.987,
  },
  {
    maesu: 7560,
    dan_no: 91.005,
    dan_yes: 91.005,
    yang_no: 182.011,
    yang_yes: 182.011,
  },
  {
    maesu: 7570,
    dan_no: 91.017,
    dan_yes: 91.017,
    yang_no: 182.034,
    yang_yes: 182.034,
  },
  {
    maesu: 7580,
    dan_no: 91.029,
    dan_yes: 91.029,
    yang_no: 182.058,
    yang_yes: 182.058,
  },
  {
    maesu: 7590,
    dan_no: 91.041,
    dan_yes: 91.041,
    yang_no: 182.082,
    yang_yes: 182.082,
  },
  {
    maesu: 7600,
    dan_no: 91.053,
    dan_yes: 91.053,
    yang_no: 182.105,
    yang_yes: 182.105,
  },
  {
    maesu: 7610,
    dan_no: 91.064,
    dan_yes: 91.064,
    yang_no: 182.129,
    yang_yes: 182.129,
  },
  {
    maesu: 7620,
    dan_no: 90.945,
    dan_yes: 90.945,
    yang_no: 181.89,
    yang_yes: 181.89,
  },
  {
    maesu: 7630,
    dan_no: 90.957,
    dan_yes: 90.957,
    yang_no: 181.913,
    yang_yes: 181.913,
  },
  {
    maesu: 7640,
    dan_no: 90.969,
    dan_yes: 90.969,
    yang_no: 181.937,
    yang_yes: 181.937,
  },
  {
    maesu: 7650,
    dan_no: 90.98,
    dan_yes: 90.98,
    yang_no: 181.961,
    yang_yes: 181.961,
  },
  {
    maesu: 7660,
    dan_no: 90.992,
    dan_yes: 90.992,
    yang_no: 181.984,
    yang_yes: 181.984,
  },
  {
    maesu: 7670,
    dan_no: 91.004,
    dan_yes: 91.004,
    yang_no: 182.008,
    yang_yes: 182.008,
  },
  {
    maesu: 7680,
    dan_no: 91.016,
    dan_yes: 91.016,
    yang_no: 182.031,
    yang_yes: 182.031,
  },
  {
    maesu: 7690,
    dan_no: 91.027,
    dan_yes: 91.027,
    yang_no: 182.055,
    yang_yes: 182.055,
  },
  {
    maesu: 7700,
    dan_no: 91.039,
    dan_yes: 91.039,
    yang_no: 182.078,
    yang_yes: 182.078,
  },
  {
    maesu: 7710,
    dan_no: 91.051,
    dan_yes: 91.051,
    yang_no: 182.101,
    yang_yes: 182.101,
  },
  {
    maesu: 7720,
    dan_no: 91.062,
    dan_yes: 91.062,
    yang_no: 182.124,
    yang_yes: 182.124,
  },
  {
    maesu: 7730,
    dan_no: 90.944,
    dan_yes: 90.944,
    yang_no: 181.889,
    yang_yes: 181.889,
  },
  {
    maesu: 7740,
    dan_no: 90.956,
    dan_yes: 90.956,
    yang_no: 181.912,
    yang_yes: 181.912,
  },
  {
    maesu: 7750,
    dan_no: 90.968,
    dan_yes: 90.968,
    yang_no: 181.935,
    yang_yes: 181.935,
  },
  {
    maesu: 7760,
    dan_no: 90.979,
    dan_yes: 90.979,
    yang_no: 181.959,
    yang_yes: 181.959,
  },
  {
    maesu: 7770,
    dan_no: 90.991,
    dan_yes: 90.991,
    yang_no: 181.982,
    yang_yes: 181.982,
  },
  {
    maesu: 7780,
    dan_no: 91.003,
    dan_yes: 91.003,
    yang_no: 182.005,
    yang_yes: 182.005,
  },
  {
    maesu: 7790,
    dan_no: 91.014,
    dan_yes: 91.014,
    yang_no: 182.028,
    yang_yes: 182.028,
  },
  {
    maesu: 7800,
    dan_no: 91.026,
    dan_yes: 91.026,
    yang_no: 182.051,
    yang_yes: 182.051,
  },
  {
    maesu: 7810,
    dan_no: 91.037,
    dan_yes: 91.037,
    yang_no: 182.074,
    yang_yes: 182.074,
  },
  {
    maesu: 7820,
    dan_no: 91.049,
    dan_yes: 91.049,
    yang_no: 182.097,
    yang_yes: 182.097,
  },
  {
    maesu: 7830,
    dan_no: 91.06,
    dan_yes: 91.06,
    yang_no: 182.12,
    yang_yes: 182.12,
  },
  {
    maesu: 7840,
    dan_no: 90.944,
    dan_yes: 90.944,
    yang_no: 181.888,
    yang_yes: 181.888,
  },
  {
    maesu: 7850,
    dan_no: 90.955,
    dan_yes: 90.955,
    yang_no: 181.911,
    yang_yes: 181.911,
  },
  {
    maesu: 7860,
    dan_no: 90.967,
    dan_yes: 90.967,
    yang_no: 181.934,
    yang_yes: 181.934,
  },
  {
    maesu: 7870,
    dan_no: 90.978,
    dan_yes: 90.978,
    yang_no: 181.957,
    yang_yes: 181.957,
  },
  {
    maesu: 7880,
    dan_no: 90.99,
    dan_yes: 90.99,
    yang_no: 181.98,
    yang_yes: 181.98,
  },
  {
    maesu: 7890,
    dan_no: 91.001,
    dan_yes: 91.001,
    yang_no: 182.003,
    yang_yes: 182.003,
  },
  {
    maesu: 7900,
    dan_no: 91.013,
    dan_yes: 91.013,
    yang_no: 182.025,
    yang_yes: 182.025,
  },
  {
    maesu: 7910,
    dan_no: 91.024,
    dan_yes: 91.024,
    yang_no: 182.048,
    yang_yes: 182.048,
  },
  {
    maesu: 7920,
    dan_no: 91.035,
    dan_yes: 91.035,
    yang_no: 182.071,
    yang_yes: 182.071,
  },
  {
    maesu: 7930,
    dan_no: 91.047,
    dan_yes: 91.047,
    yang_no: 182.093,
    yang_yes: 182.093,
  },
  {
    maesu: 7940,
    dan_no: 91.058,
    dan_yes: 91.058,
    yang_no: 182.116,
    yang_yes: 182.116,
  },
  {
    maesu: 7950,
    dan_no: 90.943,
    dan_yes: 90.943,
    yang_no: 181.887,
    yang_yes: 181.887,
  },
  {
    maesu: 7960,
    dan_no: 90.955,
    dan_yes: 90.955,
    yang_no: 181.91,
    yang_yes: 181.91,
  },
  {
    maesu: 7970,
    dan_no: 90.966,
    dan_yes: 90.966,
    yang_no: 181.932,
    yang_yes: 181.932,
  },
  {
    maesu: 7980,
    dan_no: 90.977,
    dan_yes: 90.977,
    yang_no: 181.955,
    yang_yes: 181.955,
  },
  {
    maesu: 7990,
    dan_no: 90.989,
    dan_yes: 90.989,
    yang_no: 181.977,
    yang_yes: 181.977,
  },
  {
    maesu: 8000,
    dan_no: 91,
    dan_yes: 91,
    yang_no: 182,
    yang_yes: 182,
  },
];

//벨벳
export const CoatingPrice_velvet = [
  {
    maesu: 1,
    dan_no: 3000,
    dan_yes: 3000,
    yang_no: 6000,
    yang_yes: 6000,
  },
  {
    maesu: 2,
    dan_no: 2500,
    dan_yes: 2500,
    yang_no: 5000,
    yang_yes: 5000,
  },
  {
    maesu: 3,
    dan_no: 2000,
    dan_yes: 2000,
    yang_no: 4000,
    yang_yes: 4000,
  },
  {
    maesu: 4,
    dan_no: 1750,
    dan_yes: 1750,
    yang_no: 3500,
    yang_yes: 3500,
  },
  {
    maesu: 5,
    dan_no: 1600,
    dan_yes: 1600,
    yang_no: 3200,
    yang_yes: 3200,
  },
  {
    maesu: 8,
    dan_no: 1125,
    dan_yes: 1125,
    yang_no: 2250,
    yang_yes: 2250,
  },
  {
    maesu: 10,
    dan_no: 1000,
    dan_yes: 1000,
    yang_no: 2000,
    yang_yes: 2000,
  },
  {
    maesu: 15,
    dan_no: 800,
    dan_yes: 800,
    yang_no: 1600,
    yang_yes: 1600,
  },
  {
    maesu: 20,
    dan_no: 650,
    dan_yes: 650,
    yang_no: 1300,
    yang_yes: 1300,
  },
  {
    maesu: 25,
    dan_no: 560,
    dan_yes: 560,
    yang_no: 1120,
    yang_yes: 1120,
  },
  {
    maesu: 30,
    dan_no: 533.333,
    dan_yes: 533.333,
    yang_no: 1066.667,
    yang_yes: 1066.667,
  },
  {
    maesu: 35,
    dan_no: 485.714,
    dan_yes: 485.714,
    yang_no: 971.429,
    yang_yes: 971.429,
  },
  {
    maesu: 40,
    dan_no: 450,
    dan_yes: 450,
    yang_no: 900,
    yang_yes: 900,
  },
  {
    maesu: 45,
    dan_no: 422.222,
    dan_yes: 422.222,
    yang_no: 844.444,
    yang_yes: 844.444,
  },
  {
    maesu: 50,
    dan_no: 400,
    dan_yes: 400,
    yang_no: 800,
    yang_yes: 800,
  },
  {
    maesu: 55,
    dan_no: 381.818,
    dan_yes: 381.818,
    yang_no: 763.636,
    yang_yes: 763.636,
  },
  {
    maesu: 60,
    dan_no: 366.667,
    dan_yes: 366.667,
    yang_no: 733.333,
    yang_yes: 733.333,
  },
  {
    maesu: 65,
    dan_no: 353.846,
    dan_yes: 353.846,
    yang_no: 707.692,
    yang_yes: 707.692,
  },
  {
    maesu: 70,
    dan_no: 342.857,
    dan_yes: 342.857,
    yang_no: 685.714,
    yang_yes: 685.714,
  },
  {
    maesu: 75,
    dan_no: 333.333,
    dan_yes: 333.333,
    yang_no: 666.667,
    yang_yes: 666.667,
  },
  {
    maesu: 80,
    dan_no: 325,
    dan_yes: 325,
    yang_no: 650,
    yang_yes: 650,
  },
  {
    maesu: 85,
    dan_no: 317.647,
    dan_yes: 317.647,
    yang_no: 635.294,
    yang_yes: 635.294,
  },
  {
    maesu: 90,
    dan_no: 311.111,
    dan_yes: 311.111,
    yang_no: 622.222,
    yang_yes: 622.222,
  },
  {
    maesu: 95,
    dan_no: 305.263,
    dan_yes: 305.263,
    yang_no: 610.526,
    yang_yes: 610.526,
  },
  {
    maesu: 100,
    dan_no: 300,
    dan_yes: 300,
    yang_no: 600,
    yang_yes: 600,
  },
  {
    maesu: 110,
    dan_no: 281.818,
    dan_yes: 281.818,
    yang_no: 563.636,
    yang_yes: 563.636,
  },
  {
    maesu: 120,
    dan_no: 266.667,
    dan_yes: 266.667,
    yang_no: 533.333,
    yang_yes: 533.333,
  },
  {
    maesu: 130,
    dan_no: 253.846,
    dan_yes: 253.846,
    yang_no: 507.692,
    yang_yes: 507.692,
  },
  {
    maesu: 140,
    dan_no: 242.857,
    dan_yes: 242.857,
    yang_no: 485.714,
    yang_yes: 485.714,
  },
  {
    maesu: 150,
    dan_no: 233.333,
    dan_yes: 233.333,
    yang_no: 466.667,
    yang_yes: 466.667,
  },
  {
    maesu: 160,
    dan_no: 225,
    dan_yes: 225,
    yang_no: 450,
    yang_yes: 450,
  },
  {
    maesu: 170,
    dan_no: 217.647,
    dan_yes: 217.647,
    yang_no: 435.294,
    yang_yes: 435.294,
  },
  {
    maesu: 180,
    dan_no: 211.111,
    dan_yes: 211.111,
    yang_no: 422.222,
    yang_yes: 422.222,
  },
  {
    maesu: 190,
    dan_no: 205.263,
    dan_yes: 205.263,
    yang_no: 410.526,
    yang_yes: 410.526,
  },
  {
    maesu: 200,
    dan_no: 200,
    dan_yes: 200,
    yang_no: 400,
    yang_yes: 400,
  },
  {
    maesu: 210,
    dan_no: 195.238,
    dan_yes: 195.238,
    yang_no: 390.476,
    yang_yes: 390.476,
  },
  {
    maesu: 220,
    dan_no: 190.909,
    dan_yes: 190.909,
    yang_no: 381.818,
    yang_yes: 381.818,
  },
  {
    maesu: 230,
    dan_no: 186.957,
    dan_yes: 186.957,
    yang_no: 373.913,
    yang_yes: 373.913,
  },
  {
    maesu: 240,
    dan_no: 183.333,
    dan_yes: 183.333,
    yang_no: 366.667,
    yang_yes: 366.667,
  },
  {
    maesu: 250,
    dan_no: 180,
    dan_yes: 180,
    yang_no: 360,
    yang_yes: 360,
  },
  {
    maesu: 260,
    dan_no: 176.923,
    dan_yes: 176.923,
    yang_no: 353.846,
    yang_yes: 353.846,
  },
  {
    maesu: 270,
    dan_no: 174.074,
    dan_yes: 174.074,
    yang_no: 348.148,
    yang_yes: 348.148,
  },
  {
    maesu: 280,
    dan_no: 171.429,
    dan_yes: 171.429,
    yang_no: 342.857,
    yang_yes: 342.857,
  },
  {
    maesu: 290,
    dan_no: 168.966,
    dan_yes: 168.966,
    yang_no: 337.931,
    yang_yes: 337.931,
  },
  {
    maesu: 300,
    dan_no: 166.667,
    dan_yes: 166.667,
    yang_no: 333.333,
    yang_yes: 333.333,
  },
  {
    maesu: 310,
    dan_no: 164.516,
    dan_yes: 164.516,
    yang_no: 329.032,
    yang_yes: 329.032,
  },
  {
    maesu: 320,
    dan_no: 162.5,
    dan_yes: 162.5,
    yang_no: 325,
    yang_yes: 325,
  },
  {
    maesu: 330,
    dan_no: 160.606,
    dan_yes: 160.606,
    yang_no: 321.212,
    yang_yes: 321.212,
  },
  {
    maesu: 340,
    dan_no: 158.824,
    dan_yes: 158.824,
    yang_no: 317.647,
    yang_yes: 317.647,
  },
  {
    maesu: 350,
    dan_no: 157.143,
    dan_yes: 157.143,
    yang_no: 314.286,
    yang_yes: 314.286,
  },
  {
    maesu: 360,
    dan_no: 155.556,
    dan_yes: 155.556,
    yang_no: 311.111,
    yang_yes: 311.111,
  },
  {
    maesu: 370,
    dan_no: 154.054,
    dan_yes: 154.054,
    yang_no: 308.108,
    yang_yes: 308.108,
  },
  {
    maesu: 380,
    dan_no: 152.632,
    dan_yes: 152.632,
    yang_no: 305.263,
    yang_yes: 305.263,
  },
  {
    maesu: 390,
    dan_no: 151.282,
    dan_yes: 151.282,
    yang_no: 302.564,
    yang_yes: 302.564,
  },
  {
    maesu: 400,
    dan_no: 150,
    dan_yes: 150,
    yang_no: 300,
    yang_yes: 300,
  },
  {
    maesu: 410,
    dan_no: 148.78,
    dan_yes: 148.78,
    yang_no: 297.561,
    yang_yes: 297.561,
  },
  {
    maesu: 420,
    dan_no: 147.619,
    dan_yes: 147.619,
    yang_no: 295.238,
    yang_yes: 295.238,
  },
  {
    maesu: 430,
    dan_no: 146.512,
    dan_yes: 146.512,
    yang_no: 293.023,
    yang_yes: 293.023,
  },
  {
    maesu: 440,
    dan_no: 145.455,
    dan_yes: 145.455,
    yang_no: 290.909,
    yang_yes: 290.909,
  },
  {
    maesu: 450,
    dan_no: 144.444,
    dan_yes: 144.444,
    yang_no: 288.889,
    yang_yes: 288.889,
  },
  {
    maesu: 460,
    dan_no: 143.478,
    dan_yes: 143.478,
    yang_no: 286.957,
    yang_yes: 286.957,
  },
  {
    maesu: 470,
    dan_no: 142.553,
    dan_yes: 142.553,
    yang_no: 285.106,
    yang_yes: 285.106,
  },
  {
    maesu: 480,
    dan_no: 141.667,
    dan_yes: 141.667,
    yang_no: 283.333,
    yang_yes: 283.333,
  },
  {
    maesu: 490,
    dan_no: 140.816,
    dan_yes: 140.816,
    yang_no: 281.633,
    yang_yes: 281.633,
  },
  {
    maesu: 500,
    dan_no: 140,
    dan_yes: 140,
    yang_no: 280,
    yang_yes: 280,
  },
  {
    maesu: 510,
    dan_no: 138.235,
    dan_yes: 138.235,
    yang_no: 276.471,
    yang_yes: 276.471,
  },
  {
    maesu: 520,
    dan_no: 136.538,
    dan_yes: 136.538,
    yang_no: 273.077,
    yang_yes: 273.077,
  },
  {
    maesu: 530,
    dan_no: 134.906,
    dan_yes: 134.906,
    yang_no: 269.811,
    yang_yes: 269.811,
  },
  {
    maesu: 540,
    dan_no: 133.333,
    dan_yes: 133.333,
    yang_no: 266.667,
    yang_yes: 266.667,
  },
  {
    maesu: 550,
    dan_no: 131.818,
    dan_yes: 131.818,
    yang_no: 263.636,
    yang_yes: 263.636,
  },
  {
    maesu: 560,
    dan_no: 130.357,
    dan_yes: 130.357,
    yang_no: 260.714,
    yang_yes: 260.714,
  },
  {
    maesu: 570,
    dan_no: 128.947,
    dan_yes: 128.947,
    yang_no: 257.895,
    yang_yes: 257.895,
  },
  {
    maesu: 580,
    dan_no: 127.586,
    dan_yes: 127.586,
    yang_no: 255.172,
    yang_yes: 255.172,
  },
  {
    maesu: 590,
    dan_no: 126.271,
    dan_yes: 126.271,
    yang_no: 252.542,
    yang_yes: 252.542,
  },
  {
    maesu: 600,
    dan_no: 125,
    dan_yes: 125,
    yang_no: 250,
    yang_yes: 250,
  },
  {
    maesu: 610,
    dan_no: 123.77,
    dan_yes: 123.77,
    yang_no: 247.541,
    yang_yes: 247.541,
  },
  {
    maesu: 620,
    dan_no: 122.581,
    dan_yes: 122.581,
    yang_no: 245.161,
    yang_yes: 245.161,
  },
  {
    maesu: 630,
    dan_no: 121.429,
    dan_yes: 121.429,
    yang_no: 242.857,
    yang_yes: 242.857,
  },
  {
    maesu: 640,
    dan_no: 120.312,
    dan_yes: 120.312,
    yang_no: 240.625,
    yang_yes: 240.625,
  },
  {
    maesu: 650,
    dan_no: 119.231,
    dan_yes: 119.231,
    yang_no: 238.462,
    yang_yes: 238.462,
  },
  {
    maesu: 660,
    dan_no: 118.182,
    dan_yes: 118.182,
    yang_no: 236.364,
    yang_yes: 236.364,
  },
  {
    maesu: 670,
    dan_no: 117.164,
    dan_yes: 117.164,
    yang_no: 234.328,
    yang_yes: 234.328,
  },
  {
    maesu: 680,
    dan_no: 116.176,
    dan_yes: 116.176,
    yang_no: 232.353,
    yang_yes: 232.353,
  },
  {
    maesu: 690,
    dan_no: 115.217,
    dan_yes: 115.217,
    yang_no: 230.435,
    yang_yes: 230.435,
  },
  {
    maesu: 700,
    dan_no: 114.286,
    dan_yes: 114.286,
    yang_no: 228.571,
    yang_yes: 228.571,
  },
  {
    maesu: 710,
    dan_no: 113.38,
    dan_yes: 113.38,
    yang_no: 226.761,
    yang_yes: 226.761,
  },
  {
    maesu: 720,
    dan_no: 112.5,
    dan_yes: 112.5,
    yang_no: 225,
    yang_yes: 225,
  },
  {
    maesu: 730,
    dan_no: 111.644,
    dan_yes: 111.644,
    yang_no: 223.288,
    yang_yes: 223.288,
  },
  {
    maesu: 740,
    dan_no: 110.811,
    dan_yes: 110.811,
    yang_no: 221.622,
    yang_yes: 221.622,
  },
  {
    maesu: 750,
    dan_no: 110,
    dan_yes: 110,
    yang_no: 220,
    yang_yes: 220,
  },
  {
    maesu: 760,
    dan_no: 109.211,
    dan_yes: 109.211,
    yang_no: 218.421,
    yang_yes: 218.421,
  },
  {
    maesu: 770,
    dan_no: 108.442,
    dan_yes: 108.442,
    yang_no: 216.883,
    yang_yes: 216.883,
  },
  {
    maesu: 780,
    dan_no: 107.692,
    dan_yes: 107.692,
    yang_no: 215.385,
    yang_yes: 215.385,
  },
  {
    maesu: 790,
    dan_no: 106.962,
    dan_yes: 106.962,
    yang_no: 213.924,
    yang_yes: 213.924,
  },
  {
    maesu: 800,
    dan_no: 106.25,
    dan_yes: 106.25,
    yang_no: 212.5,
    yang_yes: 212.5,
  },
  {
    maesu: 810,
    dan_no: 104.938,
    dan_yes: 104.938,
    yang_no: 209.877,
    yang_yes: 209.877,
  },
  {
    maesu: 820,
    dan_no: 104.878,
    dan_yes: 104.878,
    yang_no: 209.756,
    yang_yes: 209.756,
  },
  {
    maesu: 830,
    dan_no: 104.217,
    dan_yes: 104.217,
    yang_no: 208.434,
    yang_yes: 208.434,
  },
  {
    maesu: 840,
    dan_no: 103.571,
    dan_yes: 103.571,
    yang_no: 207.143,
    yang_yes: 207.143,
  },
  {
    maesu: 850,
    dan_no: 102.941,
    dan_yes: 102.941,
    yang_no: 205.882,
    yang_yes: 205.882,
  },
  {
    maesu: 860,
    dan_no: 102.326,
    dan_yes: 102.326,
    yang_no: 204.651,
    yang_yes: 204.651,
  },
  {
    maesu: 870,
    dan_no: 101.724,
    dan_yes: 101.724,
    yang_no: 203.448,
    yang_yes: 203.448,
  },
  {
    maesu: 880,
    dan_no: 101.136,
    dan_yes: 101.136,
    yang_no: 202.273,
    yang_yes: 202.273,
  },
  {
    maesu: 890,
    dan_no: 100.562,
    dan_yes: 100.562,
    yang_no: 201.124,
    yang_yes: 201.124,
  },
  {
    maesu: 900,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 910,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 920,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 930,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 940,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 950,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 960,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 970,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 980,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 990,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1000,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1010,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1020,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1030,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1040,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1050,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1060,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1070,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1080,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1090,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1100,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1110,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1120,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1130,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1140,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1150,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1160,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1170,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1180,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1190,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1200,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1210,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1220,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1230,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1240,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1250,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1260,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1270,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1280,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1290,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1300,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1310,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1320,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1330,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1340,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1350,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1360,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1370,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1380,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1390,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1400,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1410,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1420,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1430,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1440,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1450,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1460,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1470,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1480,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1490,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1500,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1510,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1520,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1530,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1540,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1550,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1560,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1570,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1580,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1590,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1600,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1610,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1620,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1630,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1640,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1650,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1660,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1670,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1680,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1690,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1700,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1710,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1720,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1730,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1740,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1750,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1760,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1770,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1780,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1790,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1800,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1810,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1820,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1830,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1840,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1850,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1860,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1870,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1880,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1890,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1900,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1910,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1920,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1930,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1940,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1950,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1960,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1970,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1980,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 1990,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2000,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2010,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2020,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2030,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2040,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2050,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2060,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2070,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2080,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2090,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2100,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2110,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2120,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2130,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2140,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2150,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2160,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2170,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2180,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2190,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2200,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2210,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2220,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2230,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2240,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2250,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2260,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2270,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2280,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2290,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2300,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2310,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2320,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2330,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2340,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2350,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2360,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2370,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2380,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2390,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2400,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2410,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2420,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2430,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2440,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2450,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2460,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2470,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2480,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2490,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2500,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2510,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2520,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2530,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2540,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2550,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2560,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2570,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2580,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2590,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2600,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2610,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2620,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2630,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2640,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2650,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2660,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2670,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2680,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2690,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2700,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2710,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2720,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2730,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2740,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2750,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2760,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2770,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2780,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2790,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2800,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2810,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2820,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2830,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2840,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2850,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2860,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2870,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2880,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2890,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2900,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2910,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2920,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2930,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2940,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2950,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2960,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2970,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2980,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 2990,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3000,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3010,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3020,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3030,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3040,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3050,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3060,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3070,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3080,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3090,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3100,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3110,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3120,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3130,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3140,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3150,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3160,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3170,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3180,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3190,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3200,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3210,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3220,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3230,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3240,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3250,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3260,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3270,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3280,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3290,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3300,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3310,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3320,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3330,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3340,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3350,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3360,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3370,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3380,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3390,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3400,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3410,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3420,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3430,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3440,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3450,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3460,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3470,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3480,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3490,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3500,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3510,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3520,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3530,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3540,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3550,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3560,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3570,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3580,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3590,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3600,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3610,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3620,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3630,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3640,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3650,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3660,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3670,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3680,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3690,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3700,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3710,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3720,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3730,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3740,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3750,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3760,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3770,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3780,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3790,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3800,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3810,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3820,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3830,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3840,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3850,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3860,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3870,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3880,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3890,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3900,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3910,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3920,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3930,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3940,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3950,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3960,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3970,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3980,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 3990,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4000,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4010,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4020,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4030,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4040,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4050,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4060,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4070,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4080,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4090,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4100,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4110,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4120,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4130,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4140,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4150,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4160,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4170,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4180,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4190,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4200,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4210,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4220,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4230,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4240,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4250,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4260,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4270,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4280,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4290,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4300,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4310,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4320,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4330,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4340,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4350,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4360,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4370,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4380,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4390,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4400,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4410,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4420,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4430,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4440,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4450,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4460,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4470,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4480,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4490,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4500,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4510,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4520,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4530,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4540,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4550,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4560,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4570,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4580,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4590,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4600,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4610,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4620,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4630,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4640,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4650,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4660,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4670,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4680,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4690,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4700,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4710,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4720,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4730,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4740,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4750,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4760,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4770,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4780,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4790,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4800,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4810,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4820,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4830,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4840,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4850,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4860,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4870,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4880,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4890,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4900,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4910,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4920,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4930,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4940,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4950,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4960,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4970,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4980,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 4990,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5000,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5010,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5020,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5030,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5040,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5050,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5060,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5070,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5080,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5090,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5100,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5110,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5120,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5130,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5140,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5150,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5160,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5170,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5180,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5190,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5200,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5210,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5220,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5230,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5240,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5250,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5260,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5270,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5280,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5290,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5300,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5310,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5320,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5330,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5340,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5350,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5360,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5370,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5380,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5390,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5400,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5410,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5420,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5430,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5440,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5450,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5460,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5470,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5480,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5490,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5500,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5510,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5520,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5530,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5540,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5550,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5560,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5570,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5580,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5590,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5600,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5610,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5620,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5630,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5640,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5650,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5660,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5670,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5680,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5690,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5700,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5710,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5720,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5730,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5740,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5750,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5760,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5770,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5780,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5790,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5800,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5810,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5820,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5830,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5840,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5850,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5860,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5870,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5880,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5890,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5900,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5910,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5920,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5930,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5940,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5950,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5960,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5970,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5980,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 5990,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6000,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6010,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6020,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6030,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6040,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6050,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6060,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6070,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6080,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6090,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6100,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6110,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6120,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6130,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6140,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6150,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6160,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6170,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6180,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6190,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6200,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6210,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6220,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6230,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6240,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6250,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6260,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6270,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6280,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6290,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6300,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6310,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6320,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6330,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6340,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6350,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6360,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6370,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6380,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6390,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6400,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6410,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6420,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6430,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6440,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6450,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6460,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6470,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6480,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6490,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6500,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6510,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6520,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6530,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6540,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6550,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6560,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6570,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6580,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6590,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6600,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6610,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6620,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6630,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6640,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6650,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6660,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6670,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6680,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6690,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6700,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6710,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6720,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6730,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6740,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6750,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6760,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6770,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6780,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6790,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6800,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6810,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6820,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6830,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6840,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6850,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6860,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6870,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6880,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6890,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6900,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6910,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6920,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6930,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6940,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6950,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6960,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6970,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6980,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 6990,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7000,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7010,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7020,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7030,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7040,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7050,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7060,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7070,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7080,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7090,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7100,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7110,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7120,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7130,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7140,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7150,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7160,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7170,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7180,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7190,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7200,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7210,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7220,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7230,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7240,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7250,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7260,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7270,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7280,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7290,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7300,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7310,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7320,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7330,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7340,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7350,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7360,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7370,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7380,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7390,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7400,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7410,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7420,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7430,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7440,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7450,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7460,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7470,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7480,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7490,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7500,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7510,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7520,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7530,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7540,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7550,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7560,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7570,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7580,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7590,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7600,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7610,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7620,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7630,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7640,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7650,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7660,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7670,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7680,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7690,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7700,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7710,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7720,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7730,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7740,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7750,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7760,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7770,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7780,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7790,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7800,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7810,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7820,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7830,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7840,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7850,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7860,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7870,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7880,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7890,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7900,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7910,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7920,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7930,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7940,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7950,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7960,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7970,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7980,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 7990,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
  {
    maesu: 8000,
    dan_no: 100,
    dan_yes: 100,
    yang_no: 200,
    yang_yes: 200,
  },
];
