import React, { useState } from "react";
import * as S from "../../styles/new_styles";
import logo_sample from "../../assets/logo.png";
import {
  ResetTv,
  SaveAlt,
  SaveAs,
  SaveOutlined,
  SaveTwoTone,
  ShoppingCart,
  TwentyZeroMpOutlined,
  ZoomOutMap,
  ZoomOutTwoTone,
} from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

//이미지로드
const imagesContext = require.context(
  "../../assets/editor/icon",
  false,
  /\.(png|jpg|jpeg|gif)$/
);
const imageNames = imagesContext.keys();
//

const EditorHeader = ({
  functions,
  zoom,
  setZoom,
  isAddVisible,
  editor,
  handleFileSave,
  handleExportJson,
  itemData,
}) => {
  const [gridCheck, setGridCheck] = useState(false);

  const navigator = useNavigate();

  return (
    <S.EHLayout>
      <S.EHWrapper>
        <S.EHTopBox>
          <S.HeaderLogoBox
            img={logo_sample}
            height="60px"
            onClick={() => {
              window.location.href = "/";
            }}
          />
          <span className="title">직접 디자인하기 [{itemData?.PROD_NM}]</span>
          <Box ml="auto" mr="100px">
            <Button
              style={{
                cursor: "pointer",
              }}
              variant="contained"
              color="primary"
              startIcon={<SaveOutlined />}
              onClick={() => {
                handleExportJson();
              }}
            >
              저장하기
            </Button>
            <Button
              style={{
                cursor: "pointer",
                marginLeft: "10px",
              }}
              variant="contained"
              color="primary"
              startIcon={<ShoppingCart />}
              onClick={() => {
                const confirm =
                  window.confirm("저장 후 장바구니로 이동합니다.");

                if (!confirm) return;
                if (handleExportJson()) {
                  navigator("/cart");
                }
              }}
            >
              장바구니
            </Button>
          </Box>
        </S.EHTopBox>
        <S.EHBotBox className={isAddVisible ? "side-open" : "side-close"}>
          <S.EHBtnBox>
            <S.Glob_Icon
              icon={imagesContext("./undo.png")}
              width="25px"
              height="25px"
              margin="0 0 0 1rem"
              cursor="pointer"
              onClick={editor?.functions.undo}
            />
            <S.Glob_Icon
              icon={imagesContext("./redo.png")}
              width="25px"
              height="25px"
              margin="0 0 0 1rem"
              cursor="pointer"
              onClick={editor?.functions.redo}
            />
            <S.V_Bar />
            <S.Glob_Icon
              style={gridCheck ? { opacity: 1 } : { opacity: 0.5 }}
              icon={imagesContext("./grid.png")}
              width="25px"
              height="25px"
              cursor="pointer"
              onClick={() => {
                editor?.functions.toggleSnapGrid();
                setGridCheck(!gridCheck);
              }}
            />
            <S.V_Bar />
            <S.Glob_Icon
              icon={imagesContext("./zoom_out.png")}
              width="20px"
              height="20px"
              cursor="pointer"
              onClick={editor?.handleZoomOut}
            />
            <S.Glob_Icon
              width="20px"
              height="20px"
              cursor="pointer"
              onClick={editor?.handleResetTransform}
              style={{
                margin: "0 2px 0 2px",
              }}
            >
              <ZoomOutMap
                sx={{
                  width: "20px",
                  height: "20px",
                }}
              />
            </S.Glob_Icon>
            <S.Glob_Icon
              icon={imagesContext("./zoom_in.png")}
              width="20px"
              height="20px"
              cursor="pointer"
              onClick={editor?.handleZoomIn}
            />
            <S.V_Bar />
            {/* <S.Glob_Icon
              onClick={editor?.functions.handleSave}
              icon={imagesContext("./file.png")}
              width="25px"
              height="25px"
              cursor="pointer"
            /> */}
            {/* <S.Glob_Icon
              onClick={() => {
                handleFileSave();
              }}
              icon={imagesContext("./file.png")}
              width="25px"
              height="25px"
              cursor="pointer"
            /> */}
          </S.EHBtnBox>
        </S.EHBotBox>
      </S.EHWrapper>
    </S.EHLayout>
  );
};

export default EditorHeader;
