import AdminBanner_MainSlider from "./AdminBanner_MainSlider";

import * as S from "../../styles/new_styles";
import AdminBanner_PopupSlider from "./AdminBanner_PopupSlider";

const AdminPopupBanner = () => {
  return (
    <>
      <S.MainLayout>
        <S.AdminWrapper>
          <AdminBanner_PopupSlider />
        </S.AdminWrapper>
      </S.MainLayout>
    </>
  );
};

export default AdminPopupBanner;
