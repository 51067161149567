import React, { useEffect, useRef, useState } from "react";
import * as S from "../../styles/new_styles";
import { useSpring } from "react-spring";
import { SketchPicker } from "react-color";
import { tem1_1, tem1_2 } from "./tem";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import styled from "styled-components";
import axios from "axios";
import { GridCloseIcon } from "@mui/x-data-grid";
import {
  Computer,
  ComputerTwoTone,
  QrCode,
  ViewWeek,
  ViewWeekOutlined,
} from "@mui/icons-material";
import { QRCodeSVG } from "qrcode.react";
import { toPng } from "html-to-image";
import JsBarcode from "jsbarcode";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const EditorSideAddtion = ({ functions, type, editor, myRef }) => {
  const [subMenu, setSubMenu] = useState({
    menu1: true,
    menu2: false,
  });

  const [maxHeight, setMaxHeight] = useState(0);
  const [pickColor, setPickColor] = useState("#fff");
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const ref_menu1 = useRef(null);
  const ref_menu2 = useRef(null);
  const [snackbar, setSnackbar] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [InputAiPrompt, setInputAiPrompt] = useState("");

  //최근 검색어
  const [recentKeywords, setRecentKeywords] = useState([]);
  const [showKeywords, setShowKeywords] = useState(false);

  //큐알코드
  const [qrValue, setQrValue] = useState("");
  const [showQrDialog, setShowQrDialog] = useState(false);

  //바코드
  const [barcodeValue, setBarcodeValue] = useState("");
  const [showBarcodeDialog, setShowBarcodeDialog] = useState(false);

  const [progress, setProgress] = useState(false);

  const handleCreateBarcode = () => {
    setShowBarcodeDialog(true);
  };
  const handleBarcodeGenerate = async () => {
    if (barcodeValue) {
      try {
        setProgress(true);
        const barcodeElement = document.getElementById("barcode");
        JsBarcode("#barcode", barcodeValue, {
          format: "CODE128",
          width: 2,
          height: 100,
          displayValue: true,
        });

        const pngUrl = await toPng(barcodeElement);
        handleCreateUploadImage(pngUrl, 250, 100);
        setSnackbar({
          children: "바코드가 생성되었습니다.",
          severity: "success",
        });
      } catch (error) {
        console.error("바코드 생성 실패:", error);
        setSnackbar({
          children: "바코드 생성에 실패했습니다.",
          severity: "error",
        });
      }

      setShowBarcodeDialog(false);
      setBarcodeValue("");
      setProgress(false);
    }
  };

  const handleCreateQrCode = () => {
    setShowQrDialog(true);
  };

  const handleQrCodeGenerate = async () => {
    if (qrValue) {
      try {
        setProgress(true);
        const qrCodeElement = document.getElementById("qrCode");
        const pngUrl = await toPng(qrCodeElement);
        console.log(pngUrl);
        handleCreateUploadImage(pngUrl, 150, 150);
        setSnackbar({
          children: "QR코드가 생성되었습니다.",
          severity: "success",
        });
      } catch (error) {
        console.error("QR코드 생성 실패:", error);
        setSnackbar({
          children: "QR코드 생성에 실패했습니다.",
          severity: "error",
        });
      }

      setShowQrDialog(false);
      setQrValue("");
      setProgress(false);
    }
  };

  useEffect(() => {
    try {
      setSubMenu({
        menu1: true,
        menu2: false,
      });
      setMaxHeight(ref_menu1.current.offsetHeight);
    } catch (e) {
      setMaxHeight(132);
    }
  }, [type]);

  const handleClick = (value, event) => {
    if (value === "menu1") {
      setMaxHeight(ref_menu1.current.offsetHeight);
    } else if (value === "menu2") {
      setMaxHeight(ref_menu2.current.offsetHeight);
    }

    setSubMenu((prevSubMenu) => {
      const updatedSubMenu = { ...prevSubMenu };

      // 특정 키가 이미 true인 경우, 해당 키를 false로 바꾸고 나머지는 false로 설정
      Object.keys(updatedSubMenu).forEach((key) => {
        if (key === value) {
          updatedSubMenu[key] = !updatedSubMenu[key];
        } else {
          updatedSubMenu[key] = false;
        }
      });
      return updatedSubMenu;
    });
  };

  const handleBgcClick = (event) => {
    // console.log(event.currentTarget.getAttribute("color"));
    const color_val = event.currentTarget.getAttribute("color");
    console.log(color_val);
    editor.functions.createBackgorund(color_val);
  };

  const handleBgcPick = (color) => {
    setPickColor(
      `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
    );
  };

  const handlePickerOpen = () => {
    setIsPickerOpen(true);
  };

  const handlePickerClose = () => {
    setIsPickerOpen(false);
    editor.functions.createBackgorund(pickColor);
  };

  const SlideDown1 = useSpring({
    height: subMenu.menu1 ? maxHeight + "px" : 0 + "px",
  });

  const SlideDown2 = useSpring({
    height: subMenu.menu2 ? maxHeight + "px" : 0 + "px",
  });

  //이미지로드
  const imagesContext = require.context(
    "../../assets/editor/icon",
    false,
    /\.(png|jpg|jpeg)$/
  );
  const imageNames = imagesContext.keys();
  //

  const handleCreateImage = (e) => {
    const url = e.currentTarget.getAttribute("icon");
    editor.functions.createImage(url);
  };

  const handleCreateUploadImage = async (fileUrl, w, h) => {
    await editor.functions.createImage(fileUrl, w, h);
  };

  const handleUploadImage = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", ".png,.jpg");
    input.click();
    input.addEventListener("change", async () => {
      const file = input.files[0];

      //체크
      const allowedExtensions = [".png", ".jpg"];
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes("." + fileExtension)) {
        setSnackbar({
          children: "올바른 파일 형식을 선택해주세요. (png 또는 jpg)",
          severity: "error",
        });
        return;
      }

      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", "design");
      formData.append("dir", "editor");
      formData.append("userid", "tk");
      try {
        const result = await axios.post(
          process.env.REACT_APP_DB_HOST + "/api/upload_global",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        const fileUrl = result.data;
        setSnackbar({
          children: "파일이 업로드 되었습니다.",
          severity: "success",
        });
        handleCreateUploadImage(fileUrl);
        // setDesignFile(designUrl);
        // setDesignCheck(true);
      } catch (error) {
        console.log(error);
        console.log("실패");
      }
    });
  };

  const handleMakeAiImage = async () => {
    // handleCreateUploadImage(
    //   "https://oaidalleapiprodscus.blob.core.windows.net/private/org-AT1CDyy8w9YZFWZ3Bdgar8Xv/user-rmMahpS9rDtepfkSkmEaHVtR/img-e8R8LyZ4HEsT4CTvKAGTjMcR.png?st=2024-06-28T06%3A19%3A43Z&se=2024-06-28T08%3A19%3A43Z&sp=r&sv=2023-11-03&sr=b&rscd=inline&rsct=image/png&skoid=6aaadede-4fb3-4698-a8f6-684d7786b067&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2024-06-28T01%3A41%3A35Z&ske=2024-06-29T01%3A41%3A35Z&sks=b&skv=2023-11-03&sig=neoKQp6gHLJsY%2BDsFofk8UZnbvYl%2BEPYzMH5DXKzURE%3D"
    // );

    setProgress(true);
    const res = await axios.post(
      process.env.REACT_APP_DB_HOST + "/api/openai/image",
      {
        prompt: InputAiPrompt,
      }
    );

    if (res.status === 200) {
      setSnackbar({
        children: "이미지가 생성되었습니다.",
        severity: "success",
      });
      console.log("AI생성한거 res임", res);
      console.log("경로임", res.data.FIleUrl);
      const updatedKeywords = [InputAiPrompt, ...recentKeywords.slice(0, 4)];
      setRecentKeywords(updatedKeywords);
      localStorage.setItem("recentAIKeywords", JSON.stringify(updatedKeywords));
      handleCreateUploadImage(res.data.FIleUrl);
    } else {
      setSnackbar({
        children: "이미지 생성에 실패했습니다.",
        severity: "error",
      });
    }
    setInputAiPrompt("");
    setDialogOpen(false);
    setProgress(false);
  };

  return (
    <>
      <S.ESideAddWrapper>
        {type === "shapes" ? (
          <S.ESideAddBox>
            <h1>도형</h1>
            <S.ESideAddShapesBox>
              <S.ESideAddShapesItem>
                <S.Glob_Icon
                  icon={imagesContext("./rectangle.png")}
                  width="75px"
                  height="75px"
                  onClick={editor.functions.createRect}
                />
              </S.ESideAddShapesItem>
              <S.ESideAddShapesItem>
                <S.Glob_Icon
                  icon={imagesContext("./cricle.png")}
                  width="75px"
                  height="75px"
                  onClick={editor.functions.createCircle}
                />
              </S.ESideAddShapesItem>
            </S.ESideAddShapesBox>
          </S.ESideAddBox>
        ) : type === "background" ? (
          <S.ESideAddBox>
            <h1>배경</h1>
            <S.ESideAddBgBox>
              <S.ESideAddBgItem_first>
                <p>직접 선택</p>
                <S.ESideAddBgColors>
                  <S.BgColorBtn_plus onClick={handlePickerOpen}>
                    <S.Glob_Icon
                      style={{
                        width: "100%",
                        height: "12px",
                        position: "relative",
                        top: "11px",
                      }}
                      icon={imagesContext("./plus.png")}
                      width="12px"
                      height="12px"
                    />
                  </S.BgColorBtn_plus>
                </S.ESideAddBgColors>
              </S.ESideAddBgItem_first>
              <div style={{ position: "absolute" }}>
                {isPickerOpen ? (
                  <>
                    <S.ColorPickerOverlay onClick={handlePickerClose} />
                    <S.ColorPickerBox left="12px" top="36px">
                      <SketchPicker
                        color={{ hex: pickColor }}
                        onChange={handleBgcPick}
                      />
                    </S.ColorPickerBox>
                  </>
                ) : null}
              </div>
              <S.ESideAddBgItem show={subMenu.menu1}>
                <p
                  onClick={() => {
                    handleClick("menu1");
                  }}
                >
                  기본 색상
                </p>
                <S.ESideAddBgColorsBox style={SlideDown1}>
                  <S.ESideAddBgColors ref={ref_menu1}>
                    <S.BgColorBtn onClick={handleBgcClick} color="#000" />
                    <S.BgColorBtn onClick={handleBgcClick} color="#333" />
                    <S.BgColorBtn onClick={handleBgcClick} color="#777" />
                    <S.BgColorBtn onClick={handleBgcClick} color="#ccc" />
                    <S.BgColorBtn onClick={handleBgcClick} color="#fff" />
                    <S.BgColorBtn onClick={handleBgcClick} color="red" />
                    <S.BgColorBtn onClick={handleBgcClick} color="orange" />
                    <S.BgColorBtn onClick={handleBgcClick} color="yellow" />
                    <S.BgColorBtn onClick={handleBgcClick} color="green" />
                    <S.BgColorBtn onClick={handleBgcClick} color="blue" />
                    <S.BgColorBtn onClick={handleBgcClick} color="navy" />
                    <S.BgColorBtn onClick={handleBgcClick} color="purple" />
                    <S.BgColorBtn onClick={handleBgcClick} color="#499700" />
                    <S.BgColorBtn onClick={handleBgcClick} color="#2b59bb" />
                    <S.BgColorBtn onClick={handleBgcClick} color="#f3128e" />
                  </S.ESideAddBgColors>
                </S.ESideAddBgColorsBox>
              </S.ESideAddBgItem>
              <S.ESideAddBgItem show={subMenu.menu2}>
                <p
                  onClick={() => {
                    handleClick("menu2");
                  }}
                >
                  파스텔 색상
                </p>
                <S.ESideAddBgColorsBox style={SlideDown2}>
                  <S.ESideAddBgColors ref={ref_menu2}>
                    <S.BgColorBtn onClick={handleBgcClick} color="#ff0000" />
                    <S.BgColorBtn onClick={handleBgcClick} color="#ff3c3c" />
                    <S.BgColorBtn onClick={handleBgcClick} color="#ff6868" />
                    <S.BgColorBtn onClick={handleBgcClick} color="#ffa1a1" />
                    <S.BgColorBtn onClick={handleBgcClick} color="#ffe2e2" />
                    <S.BgColorBtn onClick={handleBgcClick} color="#1900ff" />
                    <S.BgColorBtn onClick={handleBgcClick} color="#4f3bff" />
                    <S.BgColorBtn onClick={handleBgcClick} color="#8679ff" />
                    <S.BgColorBtn onClick={handleBgcClick} color="#b5adff" />
                    <S.BgColorBtn onClick={handleBgcClick} color="#e9e6ff" />
                    <S.BgColorBtn onClick={handleBgcClick} color="#fff000" />
                    <S.BgColorBtn
                      onClick={handleBgcClick}
                      color="rgb(255, 242, 65)"
                    />
                    <S.BgColorBtn onClick={handleBgcClick} color="#fff678" />
                    <S.BgColorBtn onClick={handleBgcClick} color="#fffaad" />
                    <S.BgColorBtn onClick={handleBgcClick} color="#fffcd0" />
                    <S.BgColorBtn onClick={handleBgcClick} color="#15ff00" />
                    <S.BgColorBtn onClick={handleBgcClick} color="#56ff47" />
                    <S.BgColorBtn onClick={handleBgcClick} color="#88ff7d" />
                    <S.BgColorBtn onClick={handleBgcClick} color="#c0ffba" />
                    <S.BgColorBtn onClick={handleBgcClick} color="#edffeb" />
                  </S.ESideAddBgColors>
                </S.ESideAddBgColorsBox>
              </S.ESideAddBgItem>
            </S.ESideAddBgBox>
          </S.ESideAddBox>
        ) : type === "template" ? (
          <S.ESideAddBox>
            <h1>템플릿</h1>
            <S.ESTempateBox>
              <S.ESTemplateItem>
                <S.Glob_Icon
                  onClick={() => {
                    myRef[0]?.functions.handleLoad(tem1_1);
                    myRef[1]?.functions.handleLoad(tem1_2);
                  }}
                  icon={imagesContext("./tem1.png")}
                  width="100%"
                  height="100%"
                />
              </S.ESTemplateItem>
            </S.ESTempateBox>
          </S.ESideAddBox>
        ) : type === "clipart" ? (
          <S.ESideAddBox>
            <h1>클립아트</h1>
            <S.ESideAddShapesBox>
              {Array.from({ length: 14 }).map((item, index) => (
                <S.ESideAddShapesItem>
                  <S.Glob_Icon
                    onClick={handleCreateImage}
                    icon={`/asserts/editor/clipart/animal/${index + 1}.png`}
                    width="75px"
                    height="75px"
                  />
                </S.ESideAddShapesItem>
              ))}
              <S.ESideAddShapesItem>
                <S.Glob_Icon
                  onClick={handleCreateImage}
                  icon={"/asserts/editor/clipart/clipart1.png"}
                  width="75px"
                  height="75px"
                />
              </S.ESideAddShapesItem>
            </S.ESideAddShapesBox>
          </S.ESideAddBox>
        ) : type === "picture" ? (
          <S.ESideAddBox>
            <h1>사진</h1>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box>
                <Button
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                  onClick={handleUploadImage}
                  sx={{ margin: "5px", width: "170px" }}
                >
                  파일 업로드
                </Button>
              </Box>

              <Box>
                <Button
                  variant="contained"
                  startIcon={<QrCode />}
                  onClick={handleCreateQrCode}
                  sx={{ margin: "5px", width: "170px" }}
                >
                  QR코드 생성
                </Button>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  startIcon={<ViewWeekOutlined />}
                  onClick={handleCreateBarcode}
                  sx={{ margin: "5px", width: "170px" }}
                >
                  바코드 생성
                </Button>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  startIcon={<ComputerTwoTone />}
                  onClick={() => {
                    setDialogOpen(true);
                  }}
                  sx={{ margin: "5px", width: "170px" }}
                >
                  AI 이미지 생성
                </Button>
              </Box>
            </Box>
          </S.ESideAddBox>
        ) : null}
      </S.ESideAddWrapper>
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => {
            setSnackbar(null);
          }}
          autoHideDuration={3000}
        >
          <Alert
            {...snackbar}
            onClose={() => {
              setSnackbar(null);
            }}
          />
        </Snackbar>
      )}

      <Dialog
        onClose={() => {
          setDialogOpen(false);
        }}
        open={dialogOpen}
      >
        {/* <DialogTitle id="alert-dialog-title">알림</DialogTitle> */}
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          AI 이미지 생성
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setDialogOpen(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <GridCloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box sx={{ mb: 2 }}>
              <Button
                variant="outlined"
                fullWidth
                color="info"
                onClick={() => {
                  // 최근 키워드 검색 기록을 보여주는 함수 호출
                  setShowKeywords(!showKeywords);
                }}
              >
                최근 키워드 검색 보기
              </Button>
              <Box
                sx={{ position: "relative" }}
                display={showKeywords ? "block" : "none"}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "100%",
                    left: 0,
                    width: "100%",
                    maxHeight: "200px",
                    overflowY: "auto",
                    backgroundColor: "#fff",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    color: "#0288d1",
                    zIndex: 1000,
                  }}
                >
                  <List>
                    {/* 최근 검색어 목록을 여기에 매핑 */}
                    {recentKeywords.map((keyword, index) => (
                      <ListItemButton
                        key={index}
                        onClick={() => {
                          setInputAiPrompt(keyword);
                          setShowKeywords(false);
                        }}
                      >
                        <ListItemText primary={keyword} />
                      </ListItemButton>
                    ))}
                  </List>
                </Box>
              </Box>
            </Box>
            <TextField
              fullWidth
              label="키워드"
              name="Aikeyword"
              value={InputAiPrompt}
              onChange={(e) => {
                setInputAiPrompt(e.target.value);
              }}
            />
          </DialogContentText>
          <Typography
            variant="body2"
            sx={{ mt: 2, mb: 0, fontWeight: "bold", color: "#333" }}
          >
            프롬프트 사용법
          </Typography>
          <ul
            style={{
              paddingLeft: "20px",
              fontSize: "14px",
              listStyleType: "none",
              backgroundColor: "#eee",
              borderRadius: "8px",
              padding: "15px",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            }}
          >
            <li
              style={{
                marginBottom: "8px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ color: "#007bff", marginRight: "8px" }}>•</span>
              구체적인 설명을 사용하세요
            </li>
            <li
              style={{
                marginBottom: "8px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ color: "#007bff", marginRight: "8px" }}>•</span>
              "귀여운 팬더"보다는 "대나무 숲에서 앉아있는 검은 눈 주위와 귀를
              가진 흑백의 푹신한 팬더, 입에는 대나무 잎을 물고 있음"
            </li>
            <li
              style={{
                marginBottom: "8px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ color: "#007bff", marginRight: "8px" }}>•</span>
              원하는 스타일을 명시하세요 (예: "수채화 스타일의", "팝아트
              스타일의")
            </li>
            <li
              style={{
                marginBottom: "8px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ color: "#007bff", marginRight: "8px" }}>•</span>
              색상, 구도, 조명 등 세부 사항을 포함하세요
            </li>
            <li
              style={{
                marginBottom: "8px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ color: "#007bff", marginRight: "8px" }}>•</span>
              원하지 않는 요소는 "~없이"로 표현하세요
            </li>
            <li style={{ display: "flex", alignItems: "center" }}>
              <span style={{ color: "#007bff", marginRight: "8px" }}>•</span>
              여러 개념을 결합할 때는 쉼표를 사용하세요
            </li>
          </ul>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleMakeAiImage}>생성</Button>
        </DialogActions>
      </Dialog>

      {/* QR코드 다이얼로그 */}
      <Dialog open={showQrDialog} onClose={() => setShowQrDialog(false)}>
        <DialogTitle>QR 코드 생성</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="QR 코드 내용"
            value={qrValue}
            onChange={(e) => setQrValue(e.target.value)}
            margin="normal"
          />
          {qrValue && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <div id="qrCode">
                <QRCodeSVG value={qrValue} />
              </div>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowQrDialog(false)}>취소</Button>
          <Button onClick={handleQrCodeGenerate} disabled={!qrValue}>
            생성
          </Button>
        </DialogActions>
      </Dialog>

      {/* 바코드 다이얼로그 */}
      <Dialog
        open={showBarcodeDialog}
        onClose={() => setShowBarcodeDialog(false)}
      >
        <DialogTitle>바코드 생성</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="바코드 내용"
            value={barcodeValue}
            onChange={(e) => {
              setBarcodeValue(e.target.value);
              if (barcodeValue !== "" && e.target.value !== "") {
                const barcodeElement = document.getElementById("barcode");
                JsBarcode("#barcode", e.target.value, {
                  format: "CODE128",
                  width: 2,
                  height: 100,
                  displayValue: true,
                });
              }
            }}
            margin="normal"
          />
          {barcodeValue && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <svg id="barcode"></svg>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowBarcodeDialog(false)}>취소</Button>
          <Button onClick={handleBarcodeGenerate} disabled={!barcodeValue}>
            생성
          </Button>
        </DialogActions>
      </Dialog>

      {/* <Dialog
        onClose={() => {
          setDialogOpen(false);
        }}
        open={true}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          알림
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setDialogOpen(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <GridCloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <h1>디자인 저장이 완료되었습니다! 😀</h1>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {}}>확인</Button>
        </DialogActions>
      </Dialog> */}

      {progress && (
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100vh",
            textAlign: "center",
            lineHeight: "800px",
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: 2000,
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default EditorSideAddtion;
