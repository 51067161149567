import axios from "axios";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import * as S from "../../styles/new_styles";
import GlobProdItem from "../products/GlobProdItem";
import CustomQuill from "../global/CustomQuill";
import {
  Box,
  Button,
  MenuItem,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import styled from "styled-components";
import { Height } from "@mui/icons-material";

const AdminProdDetail = () => {
  const { prod_sid } = useParams();
  const [prod, setProd] = useState();
  const [cate, setCate] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);

  const [images, setImages] = useState([]);
  const quillRef = useRef(null);

  const findChildCate = (CATE_SID) => {
    console.log(cate.filter((el) => el.CATE_PID === CATE_SID));
    return cate.filter((el) => el.CATE_PID === CATE_SID);
  };

  const handleSeleted = (index) => {
    const updated = [...selectedOption];
    updated[index] = !updated[index];
    setSelectedOption(updated);
    console.log(updated);
  };

  const handleAllSeleted = (e) => {
    const updated = [...selectedOption];
    updated.map((el, index) => {
      el = updated[index] = e.target.checked;
    });
    console.log(updated);
    setSelectedOption(updated);
  };

  const findIndexByOptionSid = (array1, optionSid) => {
    for (let i = 0; i < array1.length; i++) {
      if (array1[i].OPTION_SID === optionSid) {
        return i;
      }
    }
    return -1; // 찾지 못한 경우
  };

  const initdb = async () => {
    axios
      .post(process.env.REACT_APP_DB_HOST + "/api/admin/prods/detail", {
        prod_sid: prod_sid,
      })
      .then((res) => {
        console.log(res.data);
        setProd(res.data);
      })
      .catch((e) => {
        console.log(e);
      });

    axios
      .post(process.env.REACT_APP_DB_HOST + "/api/cate")
      .then((res) => {
        setCate(res.data);
      })
      .catch((e) => {
        console.log(e);
      });

    axios
      .post(process.env.REACT_APP_DB_HOST + "/api/admin/options")
      .then((res) => {
        setOptions(res.data);
      })
      .catch((e) => {
        console.log(e);
      });

    axios
      .post(process.env.REACT_APP_DB_HOST + "/api/product/images", {
        prod_sid: prod_sid,
      })
      .then((res) => {
        setImages(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    initdb();
  }, []);

  useEffect(() => {
    initState();
  }, [prod]);

  useEffect(() => {
    initOption();
    // const initialSelectedOptions = Array.from(
    //   { length: options?.length },
    //   () => false
    // );
    // console.log("z", initialSelectedOptions);

    // setSelectedOption(initialSelectedOptions);

    // axios
    //   .post(process.env.REACT_APP_DB_HOST + "/api/admin/prodoptions", { prod_sid: prod_sid })
    //   .then((res) => {
    //     const initChecked = [...selectedOption];

    //     options.forEach((e) => {
    //       initChecked[findIndexByOptionSid(res.data, e.OPTION_SID)] = true;
    //       setSelectedOption(initChecked);
    //     });
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
  }, [options]);

  const initOption = async () => {
    const initialSelectedOptions = Array.from(
      { length: options?.length },
      () => false
    );

    axios
      .post(process.env.REACT_APP_DB_HOST + "/api/admin/prodoptions", {
        prod_sid: prod_sid,
      })
      .then((res) => {
        const initChecked = [...initialSelectedOptions];

        options.forEach((e) => {
          initChecked[findIndexByOptionSid(res.data, e.OPTION_SID)] = true;
          setSelectedOption(initChecked);
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  //////////////함수들

  const [inputCate, setInputCate] = useState();
  const [inputPriority, setInputPriorty] = useState();
  const [inputProdNm, setInputProdNm] = useState();
  const [inputProdDesc, setInputProdDesc] = useState();
  const [inputProdPrice, setInputProdPrice] = useState();
  const [inputProdUnit, setInputProdUnit] = useState();
  const [inputProdStandard, setInputProdStandard] = useState();
  const [inputProdQuantity, setInputProdQuantity] = useState();
  const [inputProdDetail, setInputProdDetail] = useState("");
  const ProdDetailRefs = useRef([null]);
  const [inputProdNoti, setInputProdNoti] = useState("");
  const ProdNotiRefs = useRef([null]);
  const [prodContent, setProdContent] = useState();

  const initState = () => {
    // State초기화
    setInputCate(prod?.CATE_SID);
    setInputPriorty(prod?.PROD_PRIORITY);
    setInputProdNm(prod?.PROD_NM);
    setInputProdDesc(prod?.PROD_DESC);
    setInputProdPrice(prod?.PROD_PRICE);
    setInputProdUnit(prod?.PROD_UNIT);
    setInputProdStandard(prod?.PROD_STANDARD);
    setInputProdQuantity(prod?.PROD_QUANTITY);
    setInputProdDetail(prod?.PROD_DETAIL);
    setInputProdNoti(prod?.PROD_NOTI);
    setProdContent(prod?.PROD_CONTENT);
  };

  const handleSetCate = async () => {
    try {
      const res = await axios.post(
        process.env.REACT_APP_DB_HOST + "/api/admin/prod/update_cate",
        {
          prod_sid: prod?.PROD_SID,
          prod_catecode: parseInt(inputCate),
        }
      );
      alert(res.data);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSetPriority = async () => {
    try {
      const res = await axios.post(
        process.env.REACT_APP_DB_HOST + "/api/admin/prod/update_priority",
        {
          prod_sid: prod?.PROD_SID,
          prod_priority: parseInt(inputPriority),
        }
      );
      alert(res.data);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSetProdNm = async () => {
    try {
      const res = await axios.post(
        process.env.REACT_APP_DB_HOST + "/api/admin/prod/update_nm",
        {
          prod_sid: prod?.PROD_SID,
          prod_nm: inputProdNm,
        }
      );
      alert(res.data);
      initdb();
    } catch (e) {
      console.log(e);
    }
  };

  const handleSetThumbnail = async () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.addEventListener("change", async () => {
      const file = input.files[0];
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", "admin");
      formData.append("userid", "admin");
      try {
        const result = await axios.post(
          process.env.REACT_APP_DB_HOST + "/api/upload_global",
          formData
        );
        const IMG_URL = result.data;

        const res = await axios.post(
          process.env.REACT_APP_DB_HOST + "/api/admin/prod/update_thumnail",
          {
            prod_sid: prod_sid,
            image_location: IMG_URL,
          }
        );

        alert(res.data);
        initdb();
      } catch (error) {
        console.log("실패");
      }
    });
  };

  const handleSetProdDesc = async () => {
    try {
      const res = await axios.post(
        process.env.REACT_APP_DB_HOST + "/api/admin/prod/update_desc",
        {
          prod_sid: prod?.PROD_SID,
          prod_desc: inputProdDesc,
        }
      );
      alert(res.data);
      initdb();
    } catch (e) {
      console.log(e);
    }
  };

  const handleSetProdDetail = async () => {
    try {
      const res = await axios.post(
        process.env.REACT_APP_DB_HOST + "/api/admin/prod/update_detail",
        {
          prod_sid: prod?.PROD_SID,
          prod_detail: inputProdDetail,
        }
      );
      alert(res.data);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSetProdNoti = async () => {
    try {
      const res = await axios.post(
        process.env.REACT_APP_DB_HOST + "/api/admin/prod/update_noti",
        {
          prod_sid: prod?.PROD_SID,
          prod_noti: inputProdNoti,
        }
      );
      alert(res.data);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSetPrice = async () => {
    if (!inputProdPrice || !inputProdUnit || !inputProdQuantity) {
      alert("상품 가격, 판매 단위, 구매 단위를 모두 입력해주세요.");
      return false;
    }

    try {
      const res = await axios.post(
        process.env.REACT_APP_DB_HOST + "/api/admin/prod/update_price",
        {
          prod_sid: prod?.PROD_SID,
          prod_price: inputProdPrice,
          prod_unit: inputProdUnit,
          prod_quantity: inputProdQuantity,
        }
      );
      alert(res.data);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSetContent = async () => {
    console.log(prodContent);
    try {
      const res = await axios.post(
        process.env.REACT_APP_DB_HOST + "/api/admin/prod/update_content",
        {
          prod_sid: prod?.PROD_SID,
          prod_content: prodContent,
        }
      );
      alert(res.data);
    } catch (e) {
      console.log(e);
    }
  };

  ///////////////////////

  /////////////////////

  const handleSetShow = async (value) => {
    try {
      const res = await axios.post(
        process.env.REACT_APP_DB_HOST + "/api/admin/prod/prod_show",
        { prod_sid: prod_sid, prod_show: value }
      );

      alert(res.data);
      initdb();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <S.MainLayout>
      <S.AdminWrapper>
        <Box
          sx={{
            display: "flex",
            height: "40px",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "2rem",
            fontSize: "16px",
            backgroundColor: "#d9e9ff",
          }}
        >
          <Box
            className="left"
            sx={{
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            {prod?.PROD_NM} (상품코드 : {prod?.PROD_SID})
          </Box>
          <Box
            className="right"
            sx={{
              fontSize: "16px",
            }}
          >
            등록일 : {formatDate(prod?.PROD_REGDATE)}
            <br />
            수정일 : {formatDate(prod?.PROD_MODIDATE)}
          </Box>
        </Box>
        <S.AdminSection>
          <SubTitle>진열</SubTitle>
          <div className="content">
            <table>
              <tr>
                <th>카테고리</th>
                <td>
                  {prod && cate.length > 0 && (
                    <Select
                      size="small"
                      value={inputCate}
                      defaultValue={prod?.CATE_SID}
                      onChange={(e) => {
                        setInputCate(e.target.value);
                      }}
                      sx={{ width: "200px" }}
                    >
                      {cate
                        .filter((el) => el.CATE_PID === null)
                        .map((el, index) => (
                          <MenuItem value={el.CATE_SID} key={index}>
                            {el.CATE_NM}
                          </MenuItem>
                        ))}
                    </Select>
                  )}

                  <Button
                    variant="outlined"
                    onClick={handleSetCate}
                    sx={{ marginLeft: "0.5rem", height: "40px" }}
                  >
                    카테고리 설정
                  </Button>
                </td>
              </tr>
              <tr>
                <th>상품 순서</th>
                <td>
                  <TextField
                    size="small"
                    value={inputPriority}
                    onChange={(e) => {
                      setInputPriorty(e.target.value);
                    }}
                    sx={{ width: "200px" }}
                  />
                  <Button
                    variant="outlined"
                    onClick={handleSetPriority}
                    sx={{ marginLeft: "0.5rem", height: "40px" }}
                  >
                    순서 설정
                  </Button>
                </td>
              </tr>
              <tr>
                <th>메인 화면</th>
                <td>
                  <Button
                    variant={prod?.PROD_SHOW === 1 ? "contained" : "outlined"}
                    onClick={() => {
                      handleSetShow(1);
                    }}
                  >
                    진열
                  </Button>
                  <Button
                    variant={prod?.PROD_SHOW === 0 ? "contained" : "outlined"}
                    sx={{ marginLeft: "0.5rem" }}
                    onClick={() => {
                      handleSetShow(0);
                    }}
                  >
                    해제
                  </Button>
                </td>
              </tr>
            </table>
          </div>
        </S.AdminSection>
        <S.AdminSection>
          <SubTitle>썸네일</SubTitle>
          <div className="content">
            <table>
              <tr>
                <th>상품명</th>
                <td>
                  <TextField
                    size="small"
                    sx={{ width: "200px" }}
                    value={inputProdNm}
                    onChange={(e) => setInputProdNm(e.target.value)}
                  />
                  <Button
                    variant="outlined"
                    sx={{ marginLeft: "0.5rem", height: "40px" }}
                    onClick={handleSetProdNm}
                  >
                    상품명 설정
                  </Button>
                </td>
              </tr>
              <tr>
                <th>썸네일</th>
                <td>
                  <img
                    className="small"
                    src={prod?.IMAGE_LOCATION}
                    alt="썸네일"
                    style={{ cursor: "pointer" }}
                    onClick={handleSetThumbnail}
                  />
                </td>
              </tr>
              <tr>
                <th>썸네일 설명</th>
                <td>
                  <TextField
                    size="small"
                    multiline
                    minRows={2}
                    maxRows={2}
                    sx={{ width: "308px", height: "80px" }}
                    value={inputProdDesc}
                    onChange={(e) => {
                      setInputProdDesc(e.target.value);
                    }}
                  />
                  <Button
                    variant="outlined"
                    sx={{
                      marginLeft: "0.5rem",
                      height: "63px",
                    }}
                    onClick={handleSetProdDesc}
                  >
                    썸네일 설명 설정
                  </Button>
                </td>
              </tr>
              <tr>
                <th>미리보기</th>
                <td>
                  <S.GlobProdList>
                    <GlobProdItem item={prod} />
                  </S.GlobProdList>
                </td>
              </tr>
            </table>
          </div>
        </S.AdminSection>
        <S.AdminSection>
          <SubTitle>상품</SubTitle>
          <div className="content">
            <table>
              {/* <tr>
                <th>상품가격</th>
                <td>
                  <input
                    value={inputProdPrice}
                    onChange={(e) => {
                      setInputProdPrice(e.target.value);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th>판매 단위</th>
                <td>
                  <input
                    value={inputProdUnit}
                    onChange={(e) => {
                      setInputProdUnit(e.target.value);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th>
                  상품 구매 단위
                  <br />
                  (, 로 구분)
                </th>
                <td>
                  <input
                    value={inputProdQuantity}
                    onChange={(e) => {
                      setInputProdQuantity(e.target.value);
                    }}
                  />
                  <br />
                  <S.Btn margin="0.5rem 0" onClick={handleSetPrice}>
                    가격 설정
                  </S.Btn>
                </td>
              </tr>
              <tr>
                <th>상품 규격</th>
                <td>
                  <input
                    value={inputProdStandard}
                    onChange={(e) => {
                      setInputProdStandard(e.target.value);
                    }}
                  />
                </td>
              </tr> */}
              <tr>
                <th>상품 기본 설명</th>
                <td>
                  <TextField
                    sx={{
                      width: "400px",
                    }}
                    size="small"
                    value={inputProdDetail}
                    onChange={(e) => {
                      setInputProdDetail(e.target.value);
                    }}
                  />
                  <Box
                    sx={{
                      marginTop: "0.5rem",
                    }}
                  >
                    dd
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      marginTop: "0.5rem",
                    }}
                  >
                    <Button
                      variant="outlined"
                      sx={{ marginLeft: "", height: "40px" }}
                      onClick={() => {
                        if (inputProdDetail === null) {
                          setInputProdDetail("");
                        } else {
                          setInputProdDetail(inputProdDetail + "|");
                        }
                      }}
                    >
                      구분자 추가
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={handleSetProdDetail}
                      sx={{ marginLeft: "0.5rem", height: "40px" }}
                    >
                      적용
                    </Button>
                  </Box>
                </td>
              </tr>
              <tr>
                <th>상품 주의사항</th>
                <td>
                  {inputProdNoti?.split("|")?.map((el, index) => (
                    <Box
                      sx={{
                        mb: "0.5rem",
                      }}
                    >
                      <TextField
                        sx={{
                          width: "400px",
                        }}
                        size="small"
                        key={index}
                        ref={(el) => (ProdNotiRefs.current[index] = el)}
                        value={inputProdNoti?.split("|")[index]}
                        onChange={(e) => {
                          const filteredRefs = ProdNotiRefs.current.filter(
                            (ref) => ref !== null
                          );
                          let updated = [];
                          let final_str = "";
                          console.log(filteredRefs);
                          filteredRefs.forEach((el, elindex) => {
                            if (index === elindex) {
                              updated.push(filteredRefs[index].value);
                            } else {
                              updated.push(filteredRefs[elindex].value);
                            }
                          });

                          updated.forEach((el, index) => {
                            if (index != updated.length - 1) {
                              final_str += el + "|";
                            } else {
                              final_str += el;
                            }
                          });
                          console.log("final_str", final_str);

                          setInputProdNoti(final_str);
                        }}
                      />
                      <Button
                        variant="outlined"
                        color="error"
                        sx={{ marginLeft: "0.5rem", height: "40px" }}
                        onClick={() => {
                          let updated = inputProdNoti;
                          let first = "";
                          let last = "";
                          if (inputProdNoti?.split("|")?.length === 1) {
                            setInputProdNoti("");
                            return false;
                          }
                          if (index === inputProdNoti?.split("|")?.length - 1) {
                            console.log("zz");
                            const lastindex = inputProdNoti.indexOf(
                              ProdNotiRefs.current[index].value
                            );

                            updated = updated.slice(0, lastindex - 1);
                          } else {
                            first = updated.slice(
                              0,
                              inputProdNoti.indexOf(
                                ProdNotiRefs.current[index].value
                              )
                            );
                            last = updated.slice(
                              inputProdNoti.indexOf(
                                ProdNotiRefs.current[index].value
                              ) +
                                ProdNotiRefs.current[index].value.length +
                                1
                            );
                            updated = first + last;
                          }

                          setInputProdNoti(updated);
                        }}
                      >
                        삭제
                      </Button>
                      <br />
                    </Box>
                  ))}
                  <Button
                    variant="outlined"
                    sx={{ marginLeft: "", height: "40px" }}
                    onClick={() => {
                      if (inputProdNoti === null) {
                        setInputProdNoti("");
                      } else {
                        setInputProdNoti(inputProdNoti + "|");
                      }
                    }}
                  >
                    추가
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ marginLeft: "0.5rem", height: "40px" }}
                    onClick={handleSetProdNoti}
                  >
                    적용
                  </Button>
                </td>
              </tr>
            </table>
          </div>
        </S.AdminSection>
        {/* <S.AdminSection>
          <div className="title">옵션</div>
          <div className="content">
            <table>
              <tr>
                <th>용지</th>
                <td>
                  <Button
                    variant="outlined"
                    onClick={async () => {
                      const PAPER_SIDS = [];
                      Array.from(
                        Paper_apiRef.current.getSelectedRows().values()
                      ).map((el, index) => {
                        PAPER_SIDS.push(el.PAPER_SID);
                      });

                      console.log(PAPER_SIDS);
                      const result = await axios.post(
                        process.env.REACT_APP_DB_HOST + "/api/admin/prod_paper",
                        { PAPER_SIDS: PAPER_SIDS, PROD_SID: prod_sid }
                      );

                      if (result.status === 200) {
                        alert("적용되었습니다.");
                        initdb();
                      } else {
                        alert("적용 실패");
                      }
                    }}
                  >
                    적용
                  </Button>
                  <Box sx={{ height: 500, width: "100%", display: "grid" }}>
                    <DataGrid
                      apiRef={Paper_apiRef}
                      rows={Paper_dataRows}
                      columns={Paper_columns}
                      // processRowUpdate={(updatedRow, originalRow) => {
                      //   handleClickOpen(updatedRow);
                      //   return updatedRow;
                      // }}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 10,
                          },
                        },
                      }}
                      pageSizeOptions={[5]}
                      checkboxSelection
                      disableRowSelectionOnClick
                    />
                  </Box>
                </td>
              </tr>
              <tr>
                <th>후가공</th>
                <td>
                  <Button
                    variant="outlined"
                    onClick={async () => {
                      const OPTION_SIDS = [];
                      Array.from(apiRef.current.getSelectedRows().values()).map(
                        (el, index) => {
                          OPTION_SIDS.push(el.OPTION_SID);
                        }
                      );
                      const result = await axios.post(
                        process.env.REACT_APP_DB_HOST +
                          "/api/admin/prod_option",
                        { OPTION_SIDS: OPTION_SIDS, PROD_SID: prod_sid }
                      );

                      if (result.status === 200) {
                        alert("적용되었습니다.");
                        initdb();
                      } else {
                        alert("적용 실패");
                      }
                    }}
                  >
                    적용
                  </Button>

                  <Box sx={{ height: 500, width: "100%", display: "grid" }}>
                    <DataGrid
                      apiRef={apiRef}
                      rows={Options_dataRows}
                      columns={options_columns}
                      // processRowUpdate={(updatedRow, originalRow) => {
                      //   handleClickOpen(updatedRow);
                      //   return updatedRow;
                      // }}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 10,
                          },
                        },
                      }}
                      pageSizeOptions={[5]}
                      checkboxSelection
                      disableRowSelectionOnClick
                    />
                  </Box>
                </td>
              </tr>
            </table>
          </div>
        </S.AdminSection> */}
        <S.AdminSection>
          <SubTitle>상품 상세</SubTitle>
          <div className="content">
            <table>
              <tr>
                <th>이미지</th>
                <td>
                  <S.AdminProdImgBox>
                    {images.map((el, index) => (
                      <div className="item">
                        <div className="btnbox">
                          <Button
                            variant="outlined"
                            size="small"
                            color="error"
                            sx={{ height: "30px" }}
                            onClick={() => {
                              axios
                                .post(
                                  process.env.REACT_APP_DB_HOST +
                                    "/api/admin/prodimages/delete",
                                  {
                                    prod_sid: prod_sid,
                                    image_location: el.IMAGE_LOCATION,
                                    image_priority: el.IMAGE_PRIORITY,
                                    image_cate: "detail",
                                  }
                                )
                                .then((res) => {
                                  alert("삭제 완료");
                                  initdb();
                                })
                                .catch((e) => {
                                  console.log(e);
                                });
                            }}
                          >
                            삭제
                          </Button>
                        </div>
                        <img src={el.IMAGE_LOCATION} alt="이미지" key={index} />
                      </div>
                    ))}
                    <div
                      className="item plus"
                      onClick={() => {
                        const input = document.createElement("input");
                        input.setAttribute("type", "file");
                        input.setAttribute("accept", "image/*");
                        input.click();
                        input.addEventListener("change", async () => {
                          const file = input.files[0];
                          const formData = new FormData();
                          formData.append("file", file);
                          formData.append("type", "admin");
                          formData.append("userid", "admin");
                          try {
                            const result = await axios.post(
                              process.env.REACT_APP_DB_HOST +
                                "/api/upload_design",
                              formData
                            );
                            const IMG_URL = result.data;

                            axios
                              .post(
                                process.env.REACT_APP_DB_HOST +
                                  "/api/admin/prodimages/add",
                                {
                                  prod_sid: prod_sid,
                                  image_location: IMG_URL,
                                }
                              )
                              .then((res) => {
                                console.log(res);
                                alert("업로드 완료");
                                initdb();
                              })
                              .catch((e) => {
                                console.log(e);
                              });
                          } catch (error) {
                            console.log("실패");
                          }
                        });
                      }}
                    />
                  </S.AdminProdImgBox>
                </td>
              </tr>
              <tr>
                <th>상세설명</th>
                <td>
                  <CustomQuill
                    setContent={setProdContent}
                    initContent={prodContent}
                  />
                  <Button
                    variant="outlined"
                    sx={{ marginTop: "0.5rem", height: "40px" }}
                    onClick={handleSetContent}
                  >
                    상세설명 저장
                  </Button>
                </td>
              </tr>
            </table>
          </div>
        </S.AdminSection>
      </S.AdminWrapper>
    </S.MainLayout>
  );
};

export default AdminProdDetail;

const SubTitle = styled.div`
  font-size: 16px;
  font-weight: 550;
  padding-bottom: 0.5em;

  &::before {
    content: "•";
    margin-right: 0.5rem;
    color: #478cec;
  }
`;
