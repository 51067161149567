import { ArrowBack, ArrowDownward, ArrowDropDown } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import React from "react";
import NameCardOptions from "./Prods/NameCard/NameCard_Premium_detail";
import NameCard_detail from "./Prods/NameCard/NameCard_detail";
import NameCard_Premium_detail from "./Prods/NameCard/NameCard_Premium_detail";
import Flyer_detail from "./Prods/Flyer/Flyer_detail";
import PostCard_detail from "./Prods/PostCard/PostCard_detail";
import Leaflet_detail from "./Prods/Leaflet/Leaflet_detail";
import Booklet_detail from "./Prods/Booklet/Booklet_detail";
import NameCard_Fleece_detail from "./Prods/NameCard/NameCard_Fleece_detail";
import Poster_detail from "./Prods/Poster/Poster_detail";
import Sticker_single from "./Prods/Sticker/Sticker_single";
import Sticker_single_detail from "./Prods/Sticker/Sticker_single_detail";
import Sticker_DIY_detail from "./Prods/Sticker/Sticker_DIY_detail";

const OptionDetail = ({
  prod,
  SelectOptions,
  setSelectOptions,
  optionList,
  setOptionList,
}) => {
  return (
    <>
      {prod === "일반지명함" && (
        <NameCard_detail
          SelectOptions={SelectOptions}
          setSelectOptions={setSelectOptions}
          optionList={optionList}
          setOptionList={setOptionList}
        />
      )}
      {prod === "고급지명함" && (
        <NameCard_Premium_detail
          SelectOptions={SelectOptions}
          setSelectOptions={setSelectOptions}
          optionList={optionList}
          setOptionList={setOptionList}
        />
      )}
      {prod === "펄지명함" && (
        <NameCard_Fleece_detail
          SelectOptions={SelectOptions}
          setSelectOptions={setSelectOptions}
          optionList={optionList}
          setOptionList={setOptionList}
        />
      )}
      {prod === "전단지" && (
        <Flyer_detail
          SelectOptions={SelectOptions}
          setSelectOptions={setSelectOptions}
          optionList={optionList}
          setOptionList={setOptionList}
        />
      )}
      {prod === "엽서" && (
        <PostCard_detail
          SelectOptions={SelectOptions}
          setSelectOptions={setSelectOptions}
          optionList={optionList}
          setOptionList={setOptionList}
        />
      )}
      {prod === "포스터" && (
        <Poster_detail
          SelectOptions={SelectOptions}
          setSelectOptions={setSelectOptions}
          optionList={optionList}
          setOptionList={setOptionList}
        />
      )}
      {prod === "리플릿" && (
        <Leaflet_detail
          SelectOptions={SelectOptions}
          setSelectOptions={setSelectOptions}
          optionList={optionList}
          setOptionList={setOptionList}
        />
      )}
      {["단행본", "브로슈어", "스프링노트", "제안서", "노트"].includes(
        prod
      ) && (
        <Booklet_detail
          SelectOptions={SelectOptions}
          setSelectOptions={setSelectOptions}
          optionList={optionList}
          setOptionList={setOptionList}
        />
      )}
      {prod === "낱장스티커" && (
        <Sticker_single_detail
          SelectOptions={SelectOptions}
          setSelectOptions={setSelectOptions}
          optionList={optionList}
          setOptionList={setOptionList}
        />
      )}
      {prod === "DIY스티커" && (
        <Sticker_DIY_detail
          SelectOptions={SelectOptions}
          setSelectOptions={setSelectOptions}
          optionList={optionList}
          setOptionList={setOptionList}
        />
      )}
    </>
  );
};

export default OptionDetail;
