import React, { useRef, useState } from "react";
import styled from "styled-components";
import New_EditorSidePanel from "./New_EditorSidePanel";
import New_EditorToolbar from "./New_EditorToolbar";
import New_EditorWorkSpace from "./New_EditorWorkSpace";
import { Button } from "@mui/material";
const New_EditorPage = () => {
  const editorRef = useRef([]);
  const [selectedNode, setSelectedNode] = useState(null);
  return (
    <EditorContainer>
      <SidePanelWrap>
        <New_EditorSidePanel />
      </SidePanelWrap>
      <WorkSpaceWrap>
        <New_EditorToolbar
          editorRef={editorRef}
          selectedNode={selectedNode}
          setSelectedNode={setSelectedNode}
        />
        <Button
          onClick={() => {
            editorRef.current.functions.addStar();
          }}
        >
          별추가
        </Button>
        <New_EditorWorkSpace
          editorRef={editorRef}
          selectedNode={selectedNode}
          setSelectedNode={setSelectedNode}
        />
      </WorkSpaceWrap>
    </EditorContainer>
  );
};

export default New_EditorPage;

const EditorContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
`;

const SidePanelWrap = styled.div`
  height: 100%;
  width: auto;
  max-height: 100vh;
`;

const WorkSpaceWrap = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex: 1;
  flex-direction: column;
  position: relative;
`;
