import { useEffect, useRef, useState } from "react";
import * as S from "../../styles/new_styles";
import { useSpring, animated } from "react-spring";
import axios from "axios";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  formatDateAndTime,
  formatNumber,
  formatORDER_PAYMENT_TYPE,
} from "../../hooks/Utill";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

const renderOrderStatus = (status) => {
  switch (status) {
    case 1:
      return "결제 대기";
    case 2:
      return "결제 완료";
    case 3:
      return "배송 준비 중";
    case 4:
      return "배송 중";
    case 5:
      return "배송 완료";
    case 9:
      return "취소";
    default:
      return "Code error";
  }
};

const PopOrderDetail = ({ openPopup, closePopup, popupData }) => {
  const [items, setItems] = useState([]);
  const fadeInAnimation = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    config: { tension: 200, friction: 20 },
  });

  const isPc = useMediaQuery({ query: "(min-width: 768px)" });

  useEffect(() => {
    initdb();
  }, [popupData]);

  const HeadCell = styled(TableCell)`
    font-weight: 550;
  `;

  const [orderData, setOrderData] = useState();
  const [customProdData, setCustomProdData] = useState();

  const initdb = async () => {
    const res_order = await axios.get(
      process.env.REACT_APP_DB_HOST + "/api/order_detail",
      {
        params: {
          order_sid: popupData.order_sid,
        },
      }
    );
    const item_sids = res_order.data.ITEM_SIDS.split(",");
    console.log(res_order.data.ITEM_SIDS.split(","));

    const res_custom_prod = await axios.post(
      process.env.REACT_APP_DB_HOST + "/api/orderlist/item",
      {
        item_sids: item_sids,
      }
    );

    setCustomProdData(res_custom_prod.data);
    setOrderData(res_order.data);
  };

  return (
    <>
      {isPc ? (
        <S.Pop_overlay>
          <animated.div style={fadeInAnimation}>
            <S.Pop_Container
              widthValue="1400"
              heightValue="300"
              style={{ overflow: "auto", height: "650px" }}
            >
              <S.Pop_Close_btn onClick={closePopup}>
                <S.Pop_Close_span>×</S.Pop_Close_span>
              </S.Pop_Close_btn>
              <S.Pop_form>
                <Box
                  sx={{
                    textAlign: "center",
                    backgroundColor: "#fff",
                    color: "#333",
                    padding: "24px",
                    fontSize: "24px",
                  }}
                >
                  주문 상세
                </Box>

                {orderData !== undefined && customProdData !== undefined ? (
                  <>
                    <Box sx={{}}>
                      <TableContainer>
                        <Table>
                          <TableHead sx={{ backgroundColor: "#fafafa" }}>
                            <TableRow>
                              <HeadCell>주문일</HeadCell>
                              <HeadCell>결제일</HeadCell>
                              <HeadCell>주문번호</HeadCell>
                              <HeadCell>주문자</HeadCell>
                              <HeadCell>상품명</HeadCell>
                              <HeadCell>결제금액</HeadCell>
                              <HeadCell>결제수단</HeadCell>
                              <HeadCell>결제상태</HeadCell>
                              <HeadCell>택배사</HeadCell>
                              <HeadCell>요청사항</HeadCell>
                              <HeadCell>현금 영수증</HeadCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                {formatDateAndTime(orderData?.ORDER_DATE)}
                              </TableCell>
                              <TableCell>
                                {formatDateAndTime(
                                  orderData?.ORDER_PAYMENT_DATE
                                )}
                              </TableCell>
                              <TableCell>{orderData?.ORDER_SID}</TableCell>
                              <TableCell>{orderData?.ORDER_NM}</TableCell>
                              <TableCell>
                                {orderData?.ORDER_CORE_PROD}{" "}
                                {customProdData?.length > 1
                                  ? `외 ${customProdData?.length - 1}건`
                                  : ""}
                              </TableCell>
                              <TableCell>
                                {formatNumber(orderData?.ORDER_AMOUNT)}
                              </TableCell>
                              <TableCell>
                                <p>
                                  {formatORDER_PAYMENT_TYPE(
                                    orderData?.ORDER_PAYMENT_TYPE
                                  )}
                                </p>
                                <p style={{ color: "#777", fontSize: "12px" }}>
                                  {orderData?.CASH_DEPOSITOR_NAME &&
                                    "(" + orderData?.CASH_DEPOSITOR_NAME + ")"}
                                </p>
                              </TableCell>
                              <TableCell>
                                {renderOrderStatus(orderData?.ORDER_STATUS)}
                              </TableCell>
                              <TableCell>
                                <p>{orderData?.ORDER_LOGIS_NM}</p>
                                <p>{orderData?.ORDER_LOGIS_NO}</p>
                              </TableCell>
                              <TableCell>{orderData?.ORDER_REQ}</TableCell>
                              <TableCell>
                                <p>{orderData?.CASH_RECEIPT_TYPE}</p>
                                <p>{orderData?.CASH_RECEIPT_NO}</p>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                    <Box
                      sx={{
                        textAlign: "center",
                        backgroundColor: "#fff",
                        color: "#333",
                        padding: "24px",
                        fontSize: "24px",
                      }}
                    >
                      상품 상세
                    </Box>
                    <Box sx={{}}>
                      <TableContainer>
                        <Table>
                          <TableHead sx={{ backgroundColor: "#fafafa" }}>
                            <TableRow>
                              <HeadCell>주문상품</HeadCell>
                              <HeadCell>옵션</HeadCell>
                              <HeadCell>디자인</HeadCell>
                              <HeadCell>수량</HeadCell>
                              <HeadCell>금액</HeadCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {customProdData?.map((item, index) => (
                              <TableRow key={index}>
                                <TableCell>{item.PROD_NM}</TableCell>
                                <TableCell>{item.ITEM_OPTION}</TableCell>
                                <TableCell>
                                  <span
                                    style={{
                                      color: "#007bff",
                                      paddingRight: "10px",
                                    }}
                                  >
                                    {item.ITEM_FILE_NAME}
                                  </span>
                                  <Button
                                    variant="contained"
                                    onClick={() => {
                                      window.open(item.ITEM_FILE_LOCATION);
                                    }}
                                    size="small"
                                  >
                                    시안 확인
                                  </Button>
                                </TableCell>
                                <TableCell>
                                  {formatNumber(item.ITEM_QUANTITY)}
                                </TableCell>
                                <TableCell>
                                  {formatNumber(item.ITEM_AMOUNT)}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                    <Box
                      sx={{
                        textAlign: "center",
                        backgroundColor: "#fff",
                        color: "#333",
                        padding: "24px",
                        fontSize: "24px",
                      }}
                    >
                      배송지 상세
                    </Box>
                    <Box sx={{}}>
                      <TableContainer>
                        <Table>
                          <TableHead sx={{ backgroundColor: "#fafafa" }}>
                            <TableRow>
                              <HeadCell>배송지 주소</HeadCell>
                              <HeadCell>받는분</HeadCell>
                              <HeadCell>받는분 전화번호</HeadCell>
                              <HeadCell>요청사항</HeadCell>
                              <HeadCell>택배사</HeadCell>
                              <HeadCell>송장번호</HeadCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                {orderData?.ORDER_ADDRESS}{" "}
                                {orderData?.ORDER_ADD_ADDRESS} (
                                {orderData?.ORDER_POSTCODE})
                              </TableCell>
                              <TableCell>{orderData?.ORDER_REC}</TableCell>
                              <TableCell>{orderData?.REC_TEL}</TableCell>
                              <TableCell>{orderData?.ORDER_REQ}</TableCell>
                              <TableCell>{orderData?.ORDER_LOGIS_NM}</TableCell>
                              <TableCell>{orderData?.ORDER_LOGIS_NO}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box
                      sx={{
                        width: "100%",
                        textAlign: "center",
                        padding: "42px",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  </>
                )}

                <S.Pop_Button_Wrap>
                  <S.Global_Button onClick={closePopup}>닫기</S.Global_Button>
                </S.Pop_Button_Wrap>
              </S.Pop_form>
            </S.Pop_Container>
          </animated.div>
        </S.Pop_overlay>
      ) : (
        <S.Pop_overlay>
          <Box
            sx={{
              position: "fixed",
              width: "90%",
              height: "90%",
              left: "5%",
              right: "5%",
              top: "5%",
              backgroundColor: "#ffffff",
              overflow: "auto",
            }}
          >
            <S.Pop_Close_btn onClick={closePopup}>
              <S.Pop_Close_span>×</S.Pop_Close_span>
            </S.Pop_Close_btn>
            <Box
              sx={{
                textAlign: "center",
                padding: "20px",
                fontSize: "20px",
              }}
            >
              주문 상세
            </Box>
            <Divider
              sx={{
                borderColor: "#333",
              }}
            />
            {orderData !== undefined && customProdData !== undefined ? (
              <>
                <Box className="tableWrap">
                  <Table>
                    <TableRows
                      name="주문일"
                      value={formatDateAndTime(orderData?.ORDER_DATE)}
                    />
                    <TableRows
                      name="결제일"
                      value={formatDateAndTime(orderData?.ORDER_PAYMENT_DATE)}
                    />
                    <TableRows name="주문번호" value={orderData?.ORDER_SID} />
                    <TableRows name="주문자" value={orderData?.ORDER_NM} />
                    <TableRows
                      name="상품명"
                      value={
                        orderData?.ORDER_CORE_PROD +
                        (customProdData?.length > 1
                          ? `외 ${customProdData?.length - 1}건`
                          : "")
                      }
                    />
                    <TableRows
                      name="결제금액"
                      value={formatNumber(orderData?.ORDER_AMOUNT)}
                    />
                    <TableRows
                      name="결제수단"
                      value={formatORDER_PAYMENT_TYPE(
                        orderData?.ORDER_PAYMENT_TYPE
                      )}
                    />
                    <TableRows
                      name="결제상태"
                      value={renderOrderStatus(orderData?.ORDER_STATUS)}
                    />
                    <TableRows
                      name="택배사"
                      value={
                        orderData?.ORDER_LOGIS_NM &&
                        orderData?.ORDER_LOGIS_NO &&
                        `${orderData?.ORDER_LOGIS_NM} ${orderData?.ORDER_LOGIS_NO}`
                      }
                    />
                    <TableRows name="요청사항" value={orderData?.ORDER_REQ} />
                    <TableRows
                      name="현금 영수증"
                      value={`${orderData?.CASH_RECEIPT_TYPE} ${orderData?.CASH_RECEIPT_NO}`}
                    />
                  </Table>
                </Box>
                <Divider
                  sx={{
                    borderColor: "#333",
                  }}
                />
                <Box
                  sx={{
                    textAlign: "center",
                    padding: "20px",
                    fontSize: "20px",
                  }}
                >
                  상품상세
                </Box>

                {customProdData?.map((item, index) => (
                  <>
                    <Divider
                      sx={{
                        borderColor: "#333",
                      }}
                    />
                    <Box className="tableWrap">
                      <Table>
                        <TableRows
                          key={index}
                          name="주문상품"
                          value={item.PROD_NM}
                        />
                        <TableRows name="옵션" value={item.ITEM_OPTION} />
                        <TableRows
                          name="디자인"
                          value={
                            <span>
                              <span
                                style={{
                                  color: "#007bff",
                                  paddingRight: "10px",
                                }}
                              >
                                {item.ITEM_FILE_NAME}
                              </span>
                              <Button
                                variant="contained"
                                onClick={() => {
                                  window.open(item.ITEM_FILE_LOCATION);
                                }}
                                size="small"
                              >
                                시안 확인
                              </Button>
                            </span>
                          }
                        />
                        <TableRows
                          name="수량"
                          value={formatNumber(item.ITEM_QUANTITY)}
                        />
                        <TableRows
                          name="금액"
                          value={formatNumber(item.ITEM_AMOUNT)}
                        />
                      </Table>
                    </Box>
                  </>
                ))}
                <Divider
                  sx={{
                    borderColor: "#333",
                  }}
                />
                <Box
                  sx={{
                    textAlign: "center",
                    padding: "20px",
                    fontSize: "20px",
                  }}
                >
                  배송지 상세
                </Box>
                <Divider
                  sx={{
                    borderColor: "#333",
                  }}
                />
                <Box className="tableWrap">
                  <Table>
                    <TableRows
                      name="배송지 주소"
                      value={`${orderData?.ORDER_ADDRESS} ${orderData?.ORDER_ADD_ADDRESS} (${orderData?.ORDER_POSTCODE})`}
                    />
                    <TableRows name="받는분" value={orderData?.ORDER_REC} />
                    <TableRows
                      name="받는분 전화번호"
                      value={orderData?.REC_TEL}
                    />
                    <TableRows name="요청사항" value={orderData?.ORDER_REQ} />
                    <TableRows
                      name="택배사"
                      value={orderData?.ORDER_LOGIS_NM}
                    />
                    <TableRows
                      name="송장번호"
                      value={orderData?.ORDER_LOGIS_NO}
                    />
                  </Table>
                </Box>
                <Box
                  sx={{
                    padding: "20px",
                  }}
                >
                  <S.Pop_Button_Wrap_Mob>
                    <S.Global_Button onClick={closePopup}>닫기</S.Global_Button>
                  </S.Pop_Button_Wrap_Mob>
                </Box>
              </>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingBottom: "60px",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Box>
        </S.Pop_overlay>
      )}
    </>
  );
};

export default PopOrderDetail;

const TableRows = ({ name, value }) => {
  return (
    <TableRow>
      <TableCell
        sx={{
          width: "30%",
        }}
      >
        {name}
      </TableCell>
      <TableCell
        sx={{
          width: "70%",
        }}
      >
        {value}
      </TableCell>
    </TableRow>
  );
};
