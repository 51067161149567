import React, { useEffect, useRef, useState } from "react";
import * as S from "../../styles/new_styles";
import {
  formatDate,
  formatDateAndTime,
  formatORDER_PAYMENT_TYPE,
  formatTime,
} from "../../hooks/Utill";
import axios from "axios";
import Pagination from "react-js-pagination";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import {
  DataGrid,
  GridAddIcon,
  GridDeleteIcon,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  useGridApiRef,
} from "@mui/x-data-grid";

const AdminPrice = ({ openPopup }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogOpen2, setDialogOpen2] = useState(false);
  const [snackbar, setSnackbar] = useState(false);

  useEffect(() => {
    initdb();
  }, []);

  //MUI DATA TABLE
  const columns = [
    {
      field: "PRICE_PROD_CATE",
      headerName: "상품 카테고리",
      width: 200,
      editable: true,
    },
    {
      field: "PRICE_OPTION_CATE",
      headerName: "옵션 카테고리",
      width: 200,
      editable: true,
    },
    {
      field: "PRICE_OPTION_NM",
      headerName: "옵션명",
      width: 250,
      editable: true,
    },
    {
      field: "PRICE_QTY",
      headerName: "기준수량(이상)",
      width: 200,
      type: "number",
      editable: true,
    },
    {
      field: "PRICE_PRICE",
      headerName: "기준가격",
      width: 200,
      type: "number",
      editable: true,
    },
    {
      field: "PRICE_REGDATE",
      headerName: "등록일",
      width: 170,
      editable: false,
      renderCell: (params) => {
        if (params.value === null) return "";
        return `${formatDate(params.value)} / ${formatTime(params.value)}`;
      },
    },
    {
      field: "PRICE_MODIDATE",
      headerName: "수정일",
      width: 170,
      editable: false,
      renderCell: (params) => {
        if (params.value === null) return "";
        return `${formatDate(params.value)} / ${formatTime(params.value)}`;
      },
    },
  ];

  const initdb = async () => {
    const res = (
      await axios.get(process.env.REACT_APP_DB_HOST + "/api/admin/glob_price")
    ).data;

    const res_copy = res.map((el, index) => ({ ...el, id: index + 1 }));

    console.log(res_copy);
    setDataRows(res_copy);
  };

  const [dataRows, setDataRows] = useState([]);
  const apiRef = useGridApiRef();

  const [selectedRow, setSelectedRow] = useState(null);

  const handleClickOpen = (row) => {
    setDialogOpen(true);
    setSelectedRow(row);
  };

  //단가생성

  const [inputPRICE_PROD_CATE, setInputPRICE_PROD_CATE] = useState("");
  const [inputPRICE_OPTION_CATE, setInputPRICE_OPTION_CATE] = useState("");
  const [inputPRICE_OPTION_NM, setInputPRICE_OPTION_NM] = useState("");
  const [inputPRICE_QTY, setInputPRICE_QTY] = useState("");
  const [inputPRICE_PRICE, setInputPRICE_PRICE] = useState("");

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Box
          sx={{
            ml: 1,
          }}
        >
          <Button
            startIcon={<GridAddIcon />}
            onClick={() => {
              setDialogOpen2(true);
            }}
          >
            추가
          </Button>
          <Button
            startIcon={<GridDeleteIcon />}
            onClick={async () => {
              const cnt = apiRef.current.getSelectedRows().size;
              const sids = [];
              apiRef.current.getSelectedRows().forEach((row) => {
                sids.push(row.PRICE_SID);
              });

              const conf = window.confirm(
                "선택한 " + cnt + "개의 데이터를 삭제하시겠습니까?"
              );

              if (conf) {
                const res = await axios.delete(
                  process.env.REACT_APP_DB_HOST + "/api/admin/glob_price",
                  { data: { PRICE_SIDS: sids } }
                );

                if (res.status === 200) {
                  setSnackbar({
                    severity: "success",
                    children: "삭제가 완료되었습니다.",
                  });
                  apiRef.current.selectRow();
                  initdb();
                }
              }
            }}
          >
            삭제
          </Button>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  };

  return (
    <>
      <S.MainLayout>
        <S.AdminWrapper>
          <Box sx={{ height: 680, width: "100%" }}>
            <DataGrid
              apiRef={apiRef}
              rows={dataRows}
              columns={columns}
              // onCellEditStop={(params) => {
              //   console.log(params);
              //   handleEditUser(params);
              // }}
              processRowUpdate={(updatedRow, originalRow) => {
                handleClickOpen(updatedRow);
                return updatedRow;
              }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
                filter: {
                  filterModel: {
                    items: [],
                    quickFilterValues: [""],
                  },
                },
              }}
              slots={{ toolbar: CustomToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
              pageSizeOptions={[5]}
              checkboxSelection
              disableRowSelectionOnClick
            />
          </Box>
        </S.AdminWrapper>
      </S.MainLayout>
      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
          setSnackbar({
            severity: "info",
            children: "변경이 취소되었습니다.",
          });
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
          알림
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            [
            {selectedRow?.PRICE_PROD_CATE +
              " > " +
              selectedRow?.PRICE_OPTION_CATE +
              " > " +
              selectedRow?.PRICE_OPTION_NM}
            ] 의 정보를 변경하시겠습니까?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDialogOpen(false);
              setSnackbar({
                severity: "info",
                children: "변경이 취소되었습니다.",
              });
            }}
          >
            취소
          </Button>
          <Button
            onClick={async () => {
              axios.put(
                process.env.REACT_APP_DB_HOST + "/api/admin/glob_price",
                {
                  row: selectedRow,
                }
              );
              setDialogOpen(false);
              setSnackbar({
                severity: "success",
                children: "변경이 완료되었습니다.",
              });
              initdb();
            }}
            autoFocus
          >
            확인
          </Button>
        </DialogActions>
      </Dialog>
      {/* 다이얼로그2 */}
      <Dialog
        open={dialogOpen2}
        onClose={() => {
          setDialogOpen2(false);
          setSnackbar({
            severity: "info",
            children: "단가생성이 취소되었습니다.",
          });
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
          단가생성
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TableContainer component={Paper}>
              <Table>
                <TableRow>
                  <TableCell>상품 카테고리</TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      value={inputPRICE_PROD_CATE}
                      onChange={(e) => setInputPRICE_PROD_CATE(e.target.value)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>옵션 카테고리</TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      value={inputPRICE_OPTION_CATE}
                      onChange={(e) =>
                        setInputPRICE_OPTION_CATE(e.target.value)
                      }
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>옵션명</TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      value={inputPRICE_OPTION_NM}
                      onChange={(e) => setInputPRICE_OPTION_NM(e.target.value)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>기준수량(이상)</TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      value={inputPRICE_QTY}
                      onChange={(e) => setInputPRICE_QTY(e.target.value)}
                      type="number"
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>기준가격</TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      value={inputPRICE_PRICE}
                      onChange={(e) => setInputPRICE_PRICE(e.target.value)}
                      type="number"
                    />
                  </TableCell>
                </TableRow>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDialogOpen2(false);
              setSnackbar({
                severity: "info",
                children: "단가생성이 취소되었습니다.",
              });
            }}
          >
            취소
          </Button>
          <Button
            onClick={async () => {
              const res = await axios.post(
                process.env.REACT_APP_DB_HOST + "/api/admin/glob_price",
                {
                  PRICE_PROD_CATE: inputPRICE_PROD_CATE,
                  PRICE_OPTION_CATE: inputPRICE_OPTION_CATE,
                  PRICE_OPTION_NM: inputPRICE_OPTION_NM,
                  PRICE_QTY: inputPRICE_QTY,
                  PRICE_PRICE: inputPRICE_PRICE,
                }
              );

              if (res.status === 200) {
                setSnackbar({
                  severity: "success",
                  children: "단가생성이 완료되었습니다.",
                });
                setDialogOpen2(false);
                initdb();
              } else {
                setSnackbar({
                  severity: "error",
                  children: "단가생성에 실패하였습니다.",
                });
              }
            }}
            autoFocus
          >
            확인
          </Button>
        </DialogActions>
      </Dialog>
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setSnackbar(false)}
          autoHideDuration={3000}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(false)} />
        </Snackbar>
      )}
    </>
  );
};

export default AdminPrice;
