import { Box, Skeleton, Typography } from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import {
  formatDate,
  formatORDER_PAYMENT_TYPE,
  formatTime,
} from "../../hooks/Utill";
import axios from "axios";

const AdminDashBoard = () => {
  const apiRef = useGridApiRef();
  const [OrderDataRows, setOrderDataRows] = useState([]);
  const [UserDataRows, setUserDataRows] = useState([]);

  useEffect(() => {
    initdb();
  }, []);

  const initdb = async () => {
    const res = (
      await axios.post(process.env.REACT_APP_DB_HOST + "/api/admin/orderlist")
    ).data;
    const res_copy = res.map((el, index) => ({ ...el, id: index + 1 }));
    setOrderDataRows(res_copy);

    const UserRes = (
      await axios.get(process.env.REACT_APP_DB_HOST + "/api/admin/user")
    ).data;
    setUserDataRows(UserRes);
  };
  const renderOrderStatus = (status) => {
    switch (status) {
      case 1:
        return "결제 대기";
      case 2:
        return "결제 완료";
      case 3:
        return "배송 준비 중";
      case 4:
        return "배송 중";
      case 5:
        return "배송 완료";
      case 9:
        return "취소";
      default:
        return "Code error";
    }
  };

  const OrderColumns = [
    {
      field: "ORDER_DATE",
      headerName: "주문일",
      width: 170,
      editable: false,
      renderCell: (params) => {
        if (params.value === null) return "";
        return `${formatDate(params.value)} / ${formatTime(params.value)}`;
      },
    },
    {
      field: "ORDER_PAYMENT_DATE",
      headerName: "결제일",
      width: 170,
      editable: false,
      renderCell: (params) => {
        if (params.value === null) return "";
        return `${formatDate(params.value)} / ${formatTime(params.value)}`;
      },
    },
    {
      field: "ORDER_SID",
      headerName: "주문번호",
      width: 160,
      editable: false,
    },
    {
      field: "ORDER_REC",
      headerName: "주문자",
      width: 110,
      editable: false,
    },
    {
      field: "ORDER_CORE_PROD",
      headerName: "상품명",
      width: 130,
      editable: false,
      renderCell: (params) => {
        const items = params.row.ITEM_SIDS.split(",");
        if (items.length > 1) {
          return `${params.row.ORDER_CORE_PROD} 외 ${items.length - 1}건`;
        }
      },
    },
    {
      field: "ORDER_AMOUNT",
      headerName: "결제금액",
      width: 100,
      editable: false,
      type: "number",
    },
    {
      field: "ORDER_PAYMENT_TYPE",
      headerName: "결제수단",
      width: 150,
      editable: false,
      renderCell: (params) => {
        let res = formatORDER_PAYMENT_TYPE(params.value);

        if (params.row.CASH_DEPOSITOR_NAME) {
          res += " (" + params.row.CASH_DEPOSITOR_NAME + ")";
        }
        return res;
      },
    },
    {
      field: "ORDER_STATUS",
      headerName: "결제상태",
      width: 120,
      editable: false,
      renderCell: (params) => {
        return renderOrderStatus(params.value);
      },
    },
    {
      field: "ORDER_LOGIS_NM",
      headerName: "택배사",
      width: 110,
      editable: true,
    },
    {
      field: "ORDER_LOGIS_NO",
      headerName: "송장번호",
      width: 120,
      editable: true,
    },
    {
      field: "ORDER_REQ",
      headerName: "요청사항",
      width: 120,
      editable: false,
    },
    {
      field: "CASH_RECEIPT_TYPE",
      headerName: "현금영수증",
      width: 120,
      editable: false,
    },
    {
      field: "CASH_RECEIPT_NO",
      headerName: "고객번호",
      width: 120,
      editable: false,
    },
    {
      field: "4",
      headerName: "비고",
      width: 200,
      editable: false,
    },
  ];

  const UserColumns = [
    // { field: "id", headerName: "순번", width: 150 },
    {
      field: "USER_NM",
      headerName: "이름",
      width: 100,
      editable: true,
    },
    {
      field: "USER_ID",
      headerName: "아이디",
      width: 150,
      editable: false,
    },
    {
      field: "USER_TEL0",
      headerName: "전화번호",
      width: 150,
      editable: true,
    },
    {
      field: "USER_POINT",
      headerName: "포인트",
      // type: "number",
      width: 150,
      editable: true,
      renderCell: (params) => (
        <>{new Intl.NumberFormat("ko-KR").format(params.value)}</>
      ),
    },
    {
      field: "USER_GRADE",
      headerName: "회원등급",
      width: 150,
      editable: true,
      type: "singleSelect",
      valueOptions: [1, 9],
      renderCell: (params) => <>{params.value === 9 ? "관리자" : "일반회원"}</>,
    },
    {
      field: "ORDER_CNT",
      headerName: "주문횟수",
      // type: "number",
      width: 150,
      editable: false,
      renderCell: (params) => (
        <>{new Intl.NumberFormat("ko-KR").format(params.value)}</>
      ),
    },
    {
      field: "ORDER_AMT",
      headerName: "주문금액",
      // type: "number",
      width: 150,
      editable: false,
      renderCell: (params) => (
        <>{new Intl.NumberFormat("ko-KR").format(params.value)}</>
      ),
    },
    {
      field: "LOGIN_CNT",
      headerName: "방문수",
      // type: "number",
      width: 100,
      editable: false,
    },
    {
      field: "USER_LAST_LOGIN",
      headerName: "최근 접속일",
      // type: "number",
      width: 200,
      editable: false,
      renderCell: (params) => {
        return `${formatDate(params.value)} / ${formatTime(params.value)}`;
      },
    },
    {
      field: "USER_REGDATE",
      headerName: "가입일",
      // type: "number",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return formatDate(params.value);
      },
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexWrap: "wrap",
        justifyContent: "space-evenly",
      }}
    >
      <Box sx={{ height: 500, width: "48%" }}>
        <Typography
          variant="h5"
          style={{ marginBottom: "10px", textAlign: "center" }}
        >
          주문관리
        </Typography>
        <DataGrid
          apiRef={apiRef}
          rows={OrderDataRows}
          columns={OrderColumns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[5]}
          onRowDoubleClick={(param) => {
            console.log(param);
            window.open(
              `/admin/order/${param.row.ORDER_SID}`,
              "주문 상세",
              "width=1250,height=700,top=100,left=200"
            );
          }}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </Box>
      {/* ///////////////// */}
      {/* ///////////////// */}
      {/* ///////////////// */}
      <Box sx={{ height: 500, width: "48%" }}>
        <Typography
          variant="h5"
          style={{ marginBottom: "10px", textAlign: "center" }}
        >
          회원관리
        </Typography>
        <DataGrid
          // apiRef={apiRef}
          rows={UserDataRows}
          columns={UserColumns}
          processRowUpdate={(updatedRow, originalRow) => {
            return updatedRow;
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[5]}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </Box>
      {/* <Skeleton
        variant="rectangular"
        width={"24%"}
        height={250}
        style={{ margin: "5px" }}
      />
      <Skeleton
        variant="rectangular"
        width={"24%"}
        height={250}
        style={{ margin: "5px" }}
      />
      <Skeleton
        variant="rectangular"
        width={"24%"}
        height={250}
        style={{ margin: "5px" }}
      />
      <Skeleton
        variant="rectangular"
        width={"24%"}
        height={250}
        style={{ margin: "5px" }}
      /> */}
    </div>
  );
};

export default AdminDashBoard;
