import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";

const BigEnvelope = ({ prod, SelectOptions, setSelectOptions }) => {
  const [DefaultPaper, setDefaultPaper] = useState("모조지백색");
  const [DefaultGram, setDefaultGram] = useState("120g");
  const [Tape, setTape] = useState("선택안함"); // [양면테이프, 선택안함
  const [DefaultQuantity, setDefaultQuantity] = useState("1000");

  const [papers, setPapers] = useState({});

  const getPriceData = async () => {
    const res = await axios.get(
      process.env.REACT_APP_DB_HOST + "/api/global/prices",
      {
        params: {
          PRICE_PROD_CATE: prod,
          PRICE_OPTION_CATE: "용지",
        },
      }
    );

    const papers_copy = {};

    res.data.forEach((item) => {
      const name = item.PRICE_OPTION_NM.match(/[가-힣a-zA-Z]+/)[0]; // 용지 이름 추출
      const weight = parseInt(item.PRICE_OPTION_NM.match(/\d+/)[0], 10); // 그람수 추출
      console.log(name);
      console.log(weight);

      if (!papers_copy[name]) {
        papers_copy[name] = [];
      }

      if (!papers_copy[name].includes(weight)) {
        papers_copy[name].push(weight);
      }
    });

    setPapers(papers_copy);
    setDefaultPaper(Object.keys(papers_copy)[0]);
  };

  useEffect(() => {
    getPriceData();
  }, []);

  const menuItems_papers = Object.keys(papers).map((key) => (
    <MenuItem sx={{ fontSize: "14px" }} value={key} key={key}>
      {key}
    </MenuItem>
  ));

  const qtys = [
    1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 11000, 12000,
    13000, 14000, 15000, 16000, 17000, 18000, 19000, 20000,
  ];

  const menuItems_qtys = qtys.map((value) => (
    <MenuItem sx={{ fontSize: "14px" }} value={value.toString()} key={value}>
      {value}
    </MenuItem>
  ));

  useEffect(() => {
    console.log("확인", papers[DefaultPaper]);
    if (papers[DefaultPaper]) {
      setDefaultGram(papers[DefaultPaper][0] + "g");
    }
  }, [DefaultPaper]);

  useEffect(() => {
    const copyOptions = { ...SelectOptions };
    copyOptions.대봉투.용지 = DefaultPaper + DefaultGram;
    setSelectOptions(copyOptions);
  }, [DefaultPaper, DefaultGram]);

  useEffect(() => {
    const copyOptions = { ...SelectOptions };
    copyOptions.대봉투.수량 = DefaultQuantity;
    setSelectOptions(copyOptions);
  }, [DefaultQuantity]);

  useEffect(() => {
    const copyOptions = { ...SelectOptions };
    copyOptions.대봉투.양면테이프 = Tape;
    setSelectOptions(copyOptions);
  }, [Tape]);

  const [DefaultCount, setDefaultCount] = useState(1);
  useEffect(() => {
    const copyOptions = { ...SelectOptions };
    copyOptions.대봉투.건 = DefaultCount;
    setSelectOptions(copyOptions);
  }, [DefaultCount]);

  return (
    <Box>
      <Box sx={{}}>
        <InputLabel
          sx={{
            fontSize: "0.8em",
            fontWeight: "500",
            color: "#000",
            marginBottom: "6px",
          }}
        >
          용지
        </InputLabel>
        <Box sx={{ display: "flex" }}>
          <Select
            sx={{
              width: "48%",
              marginRight: "2%",
              height: "40px",
              fontSize: "14px",
            }}
            fullWidth
            value={DefaultPaper}
            onChange={(e) => {
              setDefaultPaper(e.target.value);
            }}
          >
            {menuItems_papers}
          </Select>
          <Select
            sx={{
              width: "48%",
              marginRight: "2%",
              height: "40px",
              fontSize: "14px",
            }}
            fullWidth
            value={DefaultGram}
            onChange={(e) => {
              setDefaultGram(e.target.value);
            }}
          >
            {papers[DefaultPaper]?.map((gram) => (
              <MenuItem sx={{ fontSize: "14px" }} value={gram + "g"}>
                {gram + "g"}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <Divider sx={{ margin: "12px 0" }} />
      <Box sx={{ marginTop: "12px" }}>
        <InputLabel
          sx={{
            fontSize: "0.8em",
            fontWeight: "500",
            color: "#000",
            marginBottom: "6px",
          }}
        >
          양면테이프
        </InputLabel>
        <Box sx={{ display: "flex" }}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            sx={{
              width: "48%",
              marginRight: "2%",
              height: "40px",
              fontSize: "14px",
            }}
            fullWidth
            value={Tape}
            onChange={(e) => {
              setTape(e.target.value);
            }}
          >
            <MenuItem sx={{ fontSize: "14px" }} value="양면테이프">
              양면테이프
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value="선택안함">
              선택안함
            </MenuItem>
          </Select>
        </Box>
      </Box>
      <Divider sx={{ margin: "12px 0" }} />
      <Box sx={{ marginTop: "12px" }}>
        <InputLabel
          sx={{
            fontSize: "0.8em",
            fontWeight: "500",
            color: "#000",
            marginBottom: "6px",
          }}
        >
          수량
        </InputLabel>
        <Box sx={{ display: "flex" }}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            sx={{
              width: "48%",
              marginRight: "2%",
              height: "40px",
              fontSize: "14px",
            }}
            fullWidth
            value={DefaultQuantity}
            onChange={(e) => {
              setDefaultQuantity(e.target.value);
            }}
          >
            {menuItems_qtys}
          </Select>
          <Select
            sx={{
              width: "48%",
              marginRight: "2%",
              height: "40px",
              fontSize: "14px",
            }}
            fullWidth
            value={DefaultCount}
            onChange={(e) => {
              setDefaultCount(e.target.value);
            }}
          >
            <MenuItem sx={{ fontSize: "14px" }} value={1}>
              1건
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={2}>
              2건
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={3}>
              3건
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={4}>
              4건
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={5}>
              5건
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={6}>
              6건
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={7}>
              7건
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={8}>
              8건
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={9}>
              9건
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={10}>
              10건
            </MenuItem>
          </Select>
        </Box>
      </Box>
    </Box>
  );
};

export default BigEnvelope;
