import React, { useEffect, useRef, useState } from "react";
import * as S from "../../styles/new_styles";
import {
  formatDate,
  formatDateAndTime,
  formatORDER_PAYMENT_TYPE,
  formatTime,
} from "../../hooks/Utill";
import axios from "axios";
import AdminOrderDetail from "./AdminOrderDetail";
import Pagination from "react-js-pagination";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import { DataGrid, GridToolbar, useGridApiRef } from "@mui/x-data-grid";

const AdminOrder = ({ openPopup }) => {
  const [initOrderlist_frist, setInitOrderlist_frist] = useState([]);
  const [initOrderlist, setInitOrderlist] = useState([]);
  const [orderlist, setOrderlist] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [orderDetail, setOrderDetail] = useState([]);
  const [orderDetailVisible, setOrderDetailVisible] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(10);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [logisNm, setLogisNm] = useState("CJ택배");
  const [logisNo, setLogisNo] = useState("");

  const [snackbar, setSnackbar] = useState(false);

  const handlePageChange = (e) => {
    setCurrentPage(e);
    const startIndex = (e - 1) * countPerPage;
    const endIndex = startIndex + countPerPage;
    const pageItems = initOrderlist.slice(startIndex, endIndex);
    setOrderlist(pageItems);
  };

  const allCheckbox = useRef(null);

  useEffect(() => {
    initdb();
  }, []);

  const renderOrderStatus = (status) => {
    switch (status) {
      case 1:
        return "결제 대기";
      case 2:
        return "결제 완료";
      case 3:
        return "배송 준비 중";
      case 4:
        return "배송 중";
      case 5:
        return "배송 완료";
      case 9:
        return "취소";
      default:
        return "Code error";
    }
  };

  const statusFillter = (status) => {
    const fillerdata = initOrderlist_frist.filter(
      (el) => el.ORDER_STATUS === status
    );
    const initSelectedItem = Array.from(
      { length: fillerdata?.length },
      () => false
    );
    console.log(initSelectedItem);
    setSelectedItem(initSelectedItem);
    setInitOrderlist(fillerdata);
    setOrderlist(fillerdata.slice(0, countPerPage));
    setCurrentPage(1);
  };

  const statusFillter_cancle = () => {
    const initSelectedItem = Array.from(
      { length: initOrderlist?.length },
      () => false
    );
    console.log(initSelectedItem);
    setSelectedItem(initSelectedItem);
    setInitOrderlist(initOrderlist_frist);
    setOrderlist(initOrderlist_frist.slice(0, countPerPage));
    setCurrentPage(1);
  };

  const updateStatus = async (value) => {
    console.log(apiRef.current.getSelectedRows());

    const row_cnt = apiRef.current.getSelectedRows().size;

    const order_sids = [];

    if (row_cnt === 0) {
      setSnackbar({
        severity: "info",
        children: "주문을 선택해주세요.",
      });
      return false;
    }

    if (value === 4) {
      if (row_cnt !== 1) {
        setSnackbar({
          severity: "info",
          children: "배송중 처리는 1개만 선택해주세요",
        });
        return false;
      }
      setDialogOpen(true);
      setLogisNo("");
      return false;
    }

    const confirm = window.confirm(
      `선택한 주문 ${row_cnt}건을 처리하시겠습니까?`
    );
    if (!confirm) return false;

    apiRef.current.getSelectedRows().forEach((el) => {
      order_sids.push(el.ORDER_SID);
    });

    const res = await axios.put(
      process.env.REACT_APP_DB_HOST + "/api/admin/order",
      {
        order_sid: order_sids,
        field: "ORDER_STATUS",
        order_status: value,
      }
    );

    initdb();

    return;
  };

  //MUI DATA TABLE
  const columns = [
    {
      field: "ORDER_DATE",
      headerName: "주문일",
      width: 170,
      editable: false,
      renderCell: (params) => {
        if (params.value === null) return "";
        return `${formatDate(params.value)} / ${formatTime(params.value)}`;
      },
    },
    {
      field: "ORDER_PAYMENT_DATE",
      headerName: "결제일",
      width: 170,
      editable: false,
      renderCell: (params) => {
        if (params.value === null) return "";
        return `${formatDate(params.value)} / ${formatTime(params.value)}`;
      },
    },
    {
      field: "ORDER_SID",
      headerName: "주문번호",
      width: 160,
      editable: false,
    },
    {
      field: "ORDER_REC",
      headerName: "주문자",
      width: 110,
      editable: false,
    },
    {
      field: "ORDER_CORE_PROD",
      headerName: "상품명",
      width: 130,
      editable: false,
      renderCell: (params) => {
        const items = params.row.ITEM_SIDS.split(",");
        if (items.length > 1) {
          return `${params.row.ORDER_CORE_PROD} 외 ${items.length - 1}건`;
        }
      },
    },
    {
      field: "ORDER_AMOUNT",
      headerName: "결제금액",
      width: 100,
      editable: false,
      type: "number",
    },
    {
      field: "ORDER_PAYMENT_TYPE",
      headerName: "결제수단",
      width: 150,
      editable: false,
      renderCell: (params) => {
        let res = formatORDER_PAYMENT_TYPE(params.value);

        if (params.row.CASH_DEPOSITOR_NAME) {
          res += " (" + params.row.CASH_DEPOSITOR_NAME + ")";
        }
        return res;
      },
    },
    {
      field: "ORDER_STATUS",
      headerName: "결제상태",
      width: 120,
      editable: false,
      renderCell: (params) => {
        return renderOrderStatus(params.value);
      },
    },
    {
      field: "ORDER_LOGIS_NM",
      headerName: "택배사",
      width: 110,
      editable: true,
    },
    {
      field: "ORDER_LOGIS_NO",
      headerName: "송장번호",
      width: 120,
      editable: true,
    },
    {
      field: "ORDER_REQ",
      headerName: "요청사항",
      width: 120,
      editable: false,
    },
    {
      field: "CASH_RECEIPT_TYPE",
      headerName: "현금영수증",
      width: 120,
      editable: false,
    },
    {
      field: "CASH_RECEIPT_NO",
      headerName: "고객번호",
      width: 120,
      editable: false,
    },
    {
      field: "4",
      headerName: "비고",
      width: 200,
      editable: false,
    },
  ];

  const initdb = async () => {
    const res = (
      await axios.post(process.env.REACT_APP_DB_HOST + "/api/admin/orderlist")
    ).data;

    const initSelectedItem = Array.from({ length: res?.length }, () => false);
    setSelectedItem(initSelectedItem);

    setInitOrderlist_frist(res);
    setInitOrderlist(res);
    setOrderlist(res.slice(0, countPerPage));

    const res_copy = res.map((el, index) => ({ ...el, id: index + 1 }));

    setDataRows(res_copy);
  };

  // const initdb = async () => {
  //   const res = (
  //     await axios.post(process.env.REACT_APP_DB_HOST + "/api/admin/orderlist")
  //   ).data;

  //   const initSelectedItem = Array.from({ length: res?.length }, () => false);
  //   setSelectedItem(initSelectedItem);

  //   setInitOrderlist_frist(res);
  //   setInitOrderlist(res);
  //   setOrderlist(res.slice(0, countPerPage));
  // };

  const [dataRows, setDataRows] = useState([]);
  const apiRef = useGridApiRef();

  const [filt, setFilt] = useState({
    items: [{}],
  });

  const filtOrderCanscle = () => {
    setFilt({
      items: [{ field: "ORDER_STATUS", operator: "equals", value: `` }],
    });
  };

  const filtOrderState = (value) => {
    setFilt({
      items: [{ field: "ORDER_STATUS", operator: "equals", value: `${value}` }],
    });
  };

  return (
    <>
      <S.MainLayout>
        <S.AdminWrapper>
          <Box mb={1}>
            <Button
              variant="contained"
              color="info"
              size="small"
              sx={{ marginRight: "8px", width: "100px", fontSize: "12px" }}
              onClick={() => {
                updateStatus(1);
              }}
            >
              결제대기 처리
            </Button>
            <Button
              variant="contained"
              color="info"
              size="small"
              sx={{ marginRight: "8px", width: "100px", fontSize: "12px" }}
              onClick={() => {
                updateStatus(2);
              }}
            >
              결제완료 처리
            </Button>
            <Button
              variant="contained"
              color="info"
              size="small"
              sx={{ marginRight: "8px", width: "100px", fontSize: "12px" }}
              onClick={() => {
                updateStatus(3);
              }}
            >
              배송준비 처리
            </Button>
            <Button
              variant="contained"
              color="info"
              size="small"
              sx={{ marginRight: "8px", width: "100px", fontSize: "12px" }}
              onClick={() => {
                updateStatus(4);
              }}
            >
              배송중 처리
            </Button>
            <Button
              variant="contained"
              color="info"
              size="small"
              sx={{ marginRight: "8px", width: "100px", fontSize: "12px" }}
              onClick={() => {
                updateStatus(5);
              }}
            >
              배송완료 처리
            </Button>
            <Button
              variant="contained"
              color="info"
              size="small"
              sx={{ marginRight: "8px", width: "100px", fontSize: "12px" }}
              onClick={() => {
                updateStatus(9);
              }}
            >
              취소 처리
            </Button>
          </Box>
          <Box mb={1}>
            <Button
              variant="outlined"
              size="small"
              sx={{ marginRight: "8px", width: "100px" }}
              onClick={() => {
                filtOrderCanscle();
              }}
            >
              전체주문 {dataRows.length}
            </Button>
            <Button
              variant="outlined"
              size="small"
              sx={{ marginRight: "8px", width: "100px" }}
              onClick={() => {
                filtOrderState(1);
              }}
            >
              결제대기 {dataRows.filter((el) => el.ORDER_STATUS === 1).length}
            </Button>
            <Button
              variant="outlined"
              size="small"
              sx={{ marginRight: "8px", width: "100px" }}
              onClick={() => {
                filtOrderState(2);
              }}
            >
              결제완료 {dataRows.filter((el) => el.ORDER_STATUS === 2).length}
            </Button>
            <Button
              variant="outlined"
              size="small"
              sx={{ marginRight: "8px", width: "100px" }}
              onClick={() => {
                filtOrderState(3);
              }}
            >
              배송준비 {dataRows.filter((el) => el.ORDER_STATUS === 3).length}
            </Button>
            <Button
              variant="outlined"
              size="small"
              sx={{ marginRight: "8px", width: "100px" }}
              onClick={() => {
                filtOrderState(4);
              }}
            >
              배송중 {dataRows.filter((el) => el.ORDER_STATUS === 4).length}
            </Button>
            <Button
              variant="outlined"
              size="small"
              sx={{ marginRight: "8px", width: "100px" }}
              onClick={() => {
                filtOrderState(5);
              }}
            >
              배송완료 {dataRows.filter((el) => el.ORDER_STATUS === 5).length}
            </Button>
            <Button
              variant="outlined"
              size="small"
              sx={{ marginRight: "8px", width: "100px" }}
              onClick={() => {
                filtOrderState(9);
              }}
            >
              취소 {dataRows.filter((el) => el.ORDER_STATUS === 9).length}
            </Button>
          </Box>
          <Box sx={{ height: 680, width: "100%" }}>
            <DataGrid
              // filterModel={{
              //   items: [{ field: "ORDER_AMOUNT", operator: ">", value: "1" }],
              // }}
              filterModel={filt}
              // onFilterModelChange={(model) => {
              //   if (model != filt) setFilt(model);
              // }}
              apiRef={apiRef}
              rows={dataRows}
              columns={columns}
              // onCellEditStop={(params) => {
              //   console.log(params);
              //   handleEditUser(params);
              // }}
              processRowUpdate={(updatedRow, originalRow) => {
                // handleClickOpen(updatedRow);
                return updatedRow;
              }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[5]}
              checkboxSelection
              disableRowSelectionOnClick
              onRowDoubleClick={(param) => {
                console.log(param);
                window.open(
                  `/admin/order/${param.row.ORDER_SID}`,
                  "주문 상세",
                  "width=1250,height=700,top=100,left=200"
                );
              }}
            />
          </Box>
        </S.AdminWrapper>
      </S.MainLayout>
      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
          alert("변경이 취소되었습니다.");
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ textAlign: "center", width: "350px" }}
        >
          운송장 번호 입력
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ textAlign: "left", padding: "10px 0" }}
          >
            <Box sx={{ width: "80%", margin: "0 auto", marginBottom: "16px" }}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={logisNm}
                onChange={(e) => {
                  setLogisNm(e.target.value);
                }}
                fullWidth
              >
                <MenuItem value={"CJ택배"}>CJ택배</MenuItem>
                <MenuItem value={"롯데택배"}>롯데택배</MenuItem>
                <MenuItem value={"로젠택배"}>로젠택배</MenuItem>
              </Select>
            </Box>
            <Box sx={{ width: "80%", margin: "0 auto" }}>
              <TextField
                label="운송장 번호"
                fullWidth
                value={logisNo}
                onChange={(e) => {
                  setLogisNo(e.target.value);
                }}
              />
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              if (logisNo === "") {
                alert("운송장 번호를 입력해주세요.");
                return false;
              }

              const order_sids = [];

              apiRef.current.getSelectedRows().forEach((el) => {
                order_sids.push(el.ORDER_SID);
              });

              const res = await axios.put(
                process.env.REACT_APP_DB_HOST + "/api/admin/order",
                {
                  order_sid: order_sids,
                  field: "ORDER_STATUS",
                  order_status: 4,
                  logis_nm: logisNm,
                  logis_no: logisNo,
                }
              );

              if (res.status === 200) {
                initdb();
              }

              setDialogOpen(false);
            }}
            autoFocus
          >
            확인
          </Button>
          <Button
            onClick={() => {
              setDialogOpen(false);
              alert("변경이 취소되었습니다.");
            }}
          >
            취소
          </Button>
        </DialogActions>
      </Dialog>
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setSnackbar(false)}
          autoHideDuration={3000}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(false)} />
        </Snackbar>
      )}
    </>
  );
};

export default AdminOrder;
