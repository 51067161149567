import { Link } from "react-router-dom";
import * as S from "../../styles/new_styles";
import React from "react";
import noimg from "../../assets/products/product_noimg.png";
import { useMediaQuery } from "react-responsive";
import { Box } from "@mui/material";

const GlobProdItem = ({ item }) => {
  const isPc = useMediaQuery({ query: "(min-width: 768px)" });

  return (
    <>
      {isPc ? (
        <Link
          to={"/products/detail/" + item?.PROD_SID}
          spy
          smooth
          duration={500}
        >
          <S.GlobProdItemBox>
            <S.GlobProdItemImgBox
              img={item?.IMAGE_LOCATION ? item?.IMAGE_LOCATION : noimg}
            />
            <S.GlobProdItemTextBox>
              <S.GlobProdItemTitle>{item?.PROD_NM}</S.GlobProdItemTitle>
              <S.GlobProdItemDesc>{item?.PROD_DESC}</S.GlobProdItemDesc>
              <S.GlobProdItemBtn>자세히 보기</S.GlobProdItemBtn>
            </S.GlobProdItemTextBox>
          </S.GlobProdItemBox>
        </Link>
      ) : (
        <>
          <Link
            to={"/products/detail/" + item?.PROD_SID}
            spy
            smooth
            duration={500}
          >
            <S.GlobProdItemBox_Mob>
              <S.GlobProdItemImgBox_Mob
                img={item?.IMAGE_LOCATION ? item?.IMAGE_LOCATION : noimg}
              />
              <S.GlobProdItemTextBox_Mob>
                <S.GlobProdItemTitle>{item?.PROD_NM}</S.GlobProdItemTitle>
                <S.GlobProdItemDesc_Mob>
                  {item?.PROD_DESC}
                </S.GlobProdItemDesc_Mob>
                <S.GlobProdItemBtn>자세히 보기</S.GlobProdItemBtn>
              </S.GlobProdItemTextBox_Mob>
            </S.GlobProdItemBox_Mob>
          </Link>
        </>
      )}
    </>
  );
};

export default GlobProdItem;
