import {
  Stage,
  Layer,
  Rect,
  Circle,
  Group,
  Star,
  RegularPolygon,
  Transformer,
  Text,
  Image,
} from "react-konva";
import {
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
  useCallback,
} from "react";
import Konva from "konva";
import styled from "styled-components";
import { TextField } from "@mui/material";
import image1 from "../../assets/snow0.png";

const New_EditorKonva = ({ editorRef, selectedNode, setSelectedNode }) => {
  useImperativeHandle(editorRef, () => ({
    functions,
  }));
  const stageWidth = window.innerWidth - 72 - 350; // 72는 사이드바 350은 에디션
  const stageHeight = window.innerHeight - 50; //50은 툴바 26은 임시 버튼
  const rectWidth = 1062 / 1.5;
  const rectHeight = 591 / 1.5;
  const rectX = stageWidth / 2 - rectWidth / 2;
  const rectY = stageHeight / 2 - rectHeight / 2;

  const [objects, setObjects] = useState([
    {
      id: 1,
      type: "Circle",
      width: 100,
      height: 100,
      x: 500,
      y: 300,
      radius: 50,
      fill: "red",
      scaleX: 1,
      scaleY: 1,
      skewX: 0,
      skewY: 0,
      rotation: 0,
      zIndex: 1,
    },
    {
      id: 2,
      type: "Circle",
      width: 100,
      height: 100,
      x: 500,
      y: 500,
      radius: 50,
      fill: "blue",
      scaleX: 1,
      scaleY: 1,
      skewX: 0,
      skewY: 0,
      rotation: 0,
      zIndex: 2,
    },
    {
      id: 3,
      type: "Text",
      text: "텍스트2",
      width: 100,
      height: 100,
      x: 700,
      y: 500,
      scaleX: 1,
      scaleY: 1,
      skewX: 0,
      skewY: 0,
      rotation: 0,
      zIndex: 3,
    },
    {
      id: 4,
      type: "Image",
      x: 500,
      y: 500,
      width: 100,
      height: 100,
      image: image1,
      zIndex: 1,
    },
  ]);

  const [textEditMode, setTextEditMode] = useState(false);
  const [textEditObj, setTextEditObj] = useState(null);
  const [textEditInput, setTextEditInput] = useState("");

  const [selectedIds, setSelectedIds] = useState([]);
  const [hoveredId, setHoveredId] = useState(null);
  const [selectionRect, setSelectionRect] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const StageRef = useRef(null);
  const groupRef = useRef(null);
  const trRef = useRef(null);
  const layerRef = useRef(null);
  const textEditRef = useRef(null);

  const [groups, setGroups] = useState([]);

  const [images, setImages] = useState({});

  useEffect(() => {
    objects.forEach((obj) => {
      if (obj.type === "Image" && !images[obj.id]) {
        const img = new window.Image();
        img.src = obj.image;
        img.onload = () => {
          setImages((prev) => ({ ...prev, [obj.id]: img }));
        };
      }
    });
  }, [objects]);

  useEffect(() => {
    if (selectedIds) {
      // 선택된 요소 찾기

      if (selectedIds.length > 0 && selectedIds.length === 1) {
        //하나만 선택 됐을 때
        const selectedNode = StageRef.current.findOne(`#${selectedIds[0]}`);
        setSelectedNode(selectedNode);
      } else {
        setSelectedNode("그룹");
      }

      // 셀렉션 ..
      console.log("selectedIds", selectedIds);
      console.log("selectedNode", selectedNode);
    }
  }, [selectedIds]);

  useEffect(() => {
    if (selectedIds.length > 0) {
      console.log("StageRef.current", StageRef.current);
      const selectedNodes = selectedIds
        .map((id) => StageRef.current.findOne("#" + id))
        .filter((node) => node !== undefined);
      trRef.current.nodes(selectedNodes);
      trRef.current.getLayer().batchDraw();
    } else {
      trRef.current.nodes([]);
    }
  }, [selectedIds]);

  const handleDragMove = (e, id) => {
    console.log(e);
    const { x, y } = e.target.position();
    setObjects(
      objects.map((shape) => {
        if (shape.id === id) {
          const newX = Math.max(
            rectX,
            Math.min(x, rectX + rectWidth - shape.radius * 2)
          );
          const newY = Math.max(
            rectY,
            Math.min(y, rectY + rectHeight - shape.radius * 2)
          );
          return { ...shape, x: newX, y: newY };
        }
        return shape;
      })
    );
  };

  const functions = {
    addCircle: () => {
      const newShape = {
        id: objects.length + 1,
        type: "Circle",
        x: rectX + Math.random() * rectWidth,
        y: rectY + Math.random() * rectHeight,
        radius: 30 + Math.random() * 20,
        fill: `rgb(${Math.random() * 255}, ${Math.random() * 255}, ${
          Math.random() * 255
        })`,
      };
      setObjects([...objects, newShape]);
    },
    addStar: () => {
      const newShape = {
        id: objects.length + 1,
        type: "Star",
        x: rectX + Math.random() * rectWidth,
        y: rectY + Math.random() * rectHeight,
        numPoints: 5,
        innerRadius: 20,
        outerRadius: 40,
        fill: `rgb(${Math.random() * 255}, ${Math.random() * 255}, ${
          Math.random() * 255
        })`,
      };
      setObjects([...objects, newShape]);
    },
    addTriangle: () => {
      const newShape = {
        id: objects.length + 1,
        type: "Triangle",
        x: rectX + Math.random() * rectWidth,
        y: rectY + Math.random() * rectHeight,
        sides: 3,
        radius: 30 + Math.random() * 20,
        fill: `rgb(${Math.random() * 255}, ${Math.random() * 255}, ${
          Math.random() * 255
        })`,
      };
      setObjects([...objects, newShape]);
    },
    editText: (id, content) => {
      setObjects(
        objects.map((shape) => {
          if (shape.id === id) {
            return { ...shape, text: content };
          }
          return shape;
        })
      );
    },
    addClone: (id) => {
      console.log("selectedNode", selectedNode);
      if (selectedNode && selectedNode !== "그룹") {
        const objs = [...objects];
        const className = selectedNode.className;
        if (className === "Circle") {
          const clonedObj = {
            id: objs.length + 1,
            type: "Circle",
            x: selectedNode.x() + 30,
            y: selectedNode.y() + 30,
            radius: selectedNode.radius(),
            fill: selectedNode.fill(),
            scaleX: selectedNode.scaleX(),
            scaleY: selectedNode.scaleY(),
            skewX: selectedNode.skewX(),
            skewY: selectedNode.skewY(),
            rotation: selectedNode.rotation(),
            opacity: selectedNode.opacity(),
          };
          objs.push(clonedObj);
          setObjects(objs);
          setSelectedIds([clonedObj.id]);
        } else if (className === "Text") {
          const clonedObj = {
            id: objs.length + 1,
            type: "Text",
            text: selectedNode.text(),
            x: selectedNode.x() + 30,
            y: selectedNode.y() + 30,
            width: selectedNode.width(),
            height: selectedNode.height(),
            fill: selectedNode.fill(),
            scaleX: selectedNode.scaleX(),
            scaleY: selectedNode.scaleY(),
            skewX: selectedNode.skewX(),
            skewY: selectedNode.skewY(),
            rotation: selectedNode.rotation(),
            fontFamily: selectedNode.fontFamily(),
            fontSize: selectedNode.fontSize(),
            fontStyle: selectedNode.fontStyle(),
            textDecoration: selectedNode.textDecoration(),
            align: selectedNode.align(),
            letterSpacing: selectedNode.letterSpacing(),
            lineHeight: selectedNode.lineHeight(),
            opacity: selectedNode.opacity(),
          };
          objs.push(clonedObj);
          setObjects(objs);
          setSelectedIds([clonedObj.id]);
        } else if (className === "Star") {
          const clonedObj = {
            id: objs.length + 1,
            type: "Star",
            x: selectedNode.x() + 30,
            y: selectedNode.y() + 30,
            width: selectedNode.width(),
            height: selectedNode.height(),
            opacity: selectedNode.opacity(),
            scaleX: selectedNode.scaleX(),
            scaleY: selectedNode.scaleY(),
            skewX: selectedNode.skewX(),
            skewY: selectedNode.skewY(),
            rotation: selectedNode.rotation(),
            fill: selectedNode.fill(),
            innerRadius: selectedNode.innerRadius(),
            numPoints: selectedNode.numPoints(),
            outerRadius: selectedNode.outerRadius(),
          };
          objs.push(clonedObj);

          setObjects(objs);
          setSelectedIds([clonedObj.id]);
        }
      }
    },
    deleteObject: () => {
      if (selectedIds.length > 0) {
        const updatedObjects = objects.filter(
          (obj) => !selectedIds.includes(obj.id)
        );
        setObjects(updatedObjects);
        setSelectedIds([]);
      }
    },
    groupObjects: () => {
      if (selectedIds.length > 1) {
        const children = selectedIds.map((id) =>
          StageRef.current.findOne(`#${id}`)
        );

        children.map((child) => {
          if (child.nodeType === "Group") {
            return;
          }
        });
        console.log("children", children);

        const newGroup = {
          id: `group_${Date.now()}`,
          type: "group",
          children: children,
        };

        setGroups([...groups, newGroup]);
        // 선택된 객체들을 objects 배열에서 제거
        setObjects(objects.filter((obj) => !selectedIds.includes(obj.id)));
        setSelectedIds([]);
      }
    },
    unGroupObjects: () => {
      if (selectedIds.length === 1) {
        const selectedGroup = StageRef.current.findOne(`#${selectedIds[0]}`);
        console.log("selectedGroup", selectedGroup);
        if (selectedGroup && selectedGroup.nodeType === "Group") {
          const children = selectedGroup.getChildren();
          const absolutePosition = selectedGroup.absolutePosition();
          const ungroupedObjects = children.map((child) => ({
            id: `ungrouped_${Date.now()}_${Math.random()
              .toString(36)
              .substr(2, 9)}`,
            ...child.attrs,
            x: child.absolutePosition().x,
            y: child.absolutePosition().y,
            width: child.width(),
            height: child.height(),
            scaleX: child.scaleX() * selectedGroup.scaleX(),
            scaleY: child.scaleY() * selectedGroup.scaleY(),
            skewX: child.skewX() + selectedGroup.skewX(),
            skewY: child.skewY() + selectedGroup.skewY(),
            rotation: child.rotation() + selectedGroup.rotation(),
            draggable: true,
            type: child.getClassName(),
          }));

          console.log("ungroupedObjects", ungroupedObjects);

          setObjects([...objects, ...ungroupedObjects]);
          setGroups(groups.filter((group) => group.id !== selectedGroup.id()));
          selectedGroup.destroy();
          setSelectedIds([]);
        }
      }
    },
  };

  const handleDblClick = (e, obj) => {
    console.log(obj);
    if (obj.type === "Text") {
      setTextEditMode(true);
      setTextEditObj(obj);
      setTextEditInput(obj.content);
      textEditRef?.current?.focus();
    }
  };

  const handleTransform = (e, obj) => {
    console.log("트랜스폼", e);
    console.log(e.target.className);
    const node = e.target;
    console.log(node?.attrs);

    if (node?.className === "Text") {
      node.setAttrs({
        width: node.width() * node.scaleX(),
        height: node.height() * node.scaleY(),
        scaleX: 1,
        scaleY: 1,
      });
    }
  };

  const handleDragBound = (pos, obj) => {
    let boundX, boundY;

    boundX = pos.x;
    boundY = pos.y;

    return { x: boundX, y: boundY };
  };

  const renderGroup = (group) => {
    console.log("group.children", group.children);
    console.log("group.children[0].attrs", {
      ...group.children[0].attrs,
    });
    console.log("type?? ", group.children[0].className);
    return (
      <Group
        key={group.id}
        id={group.id}
        draggable
        onClick={(e) => handleShapeClick(e, group.id)}
        onDragMove={handleDragMove2}
        onDragEnd={(e) => handleDragEnd(e, group.id)}
      >
        {group.children.map((child) =>
          renderObject({
            ...child.attrs,
            draggable: false,
            type: child.className,
          })
        )}
      </Group>
    );
  };

  const renderObject = (obj) => {
    console.log("rednerObject", obj);
    console.log("rednerObject,type", obj.type);
    const commonProps = {
      key: obj.id,
      id: obj.id.toString(),
      x: obj.x,
      y: obj.y,
      width: obj.width,
      height: obj.height,
      fill: obj.fill,
      scaleX: obj.scaleX,
      scaleY: obj.scaleY,
      skewX: obj.skewX,
      skewY: obj.skewY,
      rotation: obj.rotation,
      fontFamily: obj.fontFamily,
      fontSize: obj.fontSize,
      fontStyle: obj.fontStyle,
      textDecoration: obj.textDecoration,
      align: obj.align,
      letterSpacing: obj.letterSpacing,
      lineHeight: obj.lineHeight,
      opacity: obj.opacity,
      innerRadius: obj.innerRadius,
      numPoints: obj.numPoints,
      outerRadius: obj.outerRadius,
      draggable: obj.draggable === undefined ? true : obj.draggable,
      zIndex: obj.zIndex,

      // onDragMove: (e) => {
      //   //handleDragMove(e, obj.id);
      // },
      // ondragend: (e) => {
      //   handleobjDragTransfom(e, obj.id);
      // },
      onDragMove: (e) => {
        handleDragMove2(e);
      },
      onDragEnd: (e) => handleDragEnd(e, obj.id),
      onClick: (e) => handleShapeClick(e, obj.id),
      onDblClick: (e) => handleDblClick(e, obj),
      // onMouseEnter: () => setHoveredId(obj.id),
      // onMouseLeave: () => setHoveredId(null),
      dragBoundFunc: (pos) => handleDragBound(pos, obj.id),
      onTransform: (e) => handleTransform(e, obj),
    };

    switch (obj.type) {
      case "Circle":
        return <Circle {...commonProps} radius={obj.radius} />;
      case "Star":
        return (
          <Star
            {...commonProps}
            numPoints={obj.numPoints}
            innerRadius={obj.innerRadius}
            outerRadius={obj.outerRadius}
          />
        );
      case "Triangle":
        return (
          <RegularPolygon
            {...commonProps}
            sides={obj.sides}
            radius={obj.radius}
          />
        );
      case "Text":
        return <Text {...commonProps} text={obj.text} />;
      case "Image":
        return images[obj.id] ? (
          <Image
            {...commonProps}
            image={images[obj.id]}
            width={obj.width}
            height={obj.height}
          />
        ) : null;
      default:
        return null;
    }
  };

  const handleShapeClick = (e, id) => {
    const metaPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey;

    if (!metaPressed) {
      setSelectedIds([id]);
    } else {
      const index = selectedIds.indexOf(id);
      if (index === -1) {
        setSelectedIds([...selectedIds, id]);
      } else {
        const newSelectedIds = [...selectedIds];
        newSelectedIds.splice(index, 1);
        setSelectedIds(newSelectedIds);
      }
    }
  };
  const handleShapeDragTransfom = (e, id) => {
    const metaPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey;

    if (selectedIds.length > 1) {
      return;
    }
    if (!metaPressed) {
      setSelectedIds([id]);
    } else {
      const index = selectedIds.indexOf(id);
      if (index === -1) {
        setSelectedIds([...selectedIds, id]);
      } else {
        const newSelectedIds = [...selectedIds];
        newSelectedIds.splice(index, 1);
        setSelectedIds(newSelectedIds);
      }
    }
  };

  const handleStageMouseDown = (e) => {
    // 여기가 컬렉션 인식

    console.log(e.target);
    // setSelectedNode(e.target);

    if (!e.target._partialText) {
      setTextEditMode(false);
    }

    if (
      e.target === e.target.getStage() ||
      e.target.attrs.className === "Background" ||
      e.target.attrs.className === "OutOfBackground"
    ) {
      setSelectedIds([]);
      const pos = e.target.getStage().getPointerPosition();
      setSelectionRect({
        x: pos.x,
        y: pos.y,
        width: 0,
        height: 0,
      });
      setIsDragging(true);
    }
  };

  const handleStageMouseMove = (e) => {
    if (isDragging) {
      const stage = e.target.getStage();
      const pos = stage.getPointerPosition();

      setSelectionRect({
        ...selectionRect,
        width: pos.x - selectionRect.x,
        height: pos.y - selectionRect.y,
      });
    }
  };

  const handleStageMouseUp = () => {
    if (isDragging) {
      const selectedobjects = objects.filter((shape) => {
        const shapeNode = layerRef.current.findOne(`#${shape.id}`);
        const shapeRect = shapeNode.getClientRect();
        return (
          Math.abs(shapeRect.x - selectionRect.x) <
            Math.abs(selectionRect.width) &&
          Math.abs(shapeRect.y - selectionRect.y) <
            Math.abs(selectionRect.height) &&
          Math.abs(shapeRect.x + shapeRect.width - selectionRect.x) <
            Math.abs(selectionRect.width) &&
          Math.abs(shapeRect.y + shapeRect.height - selectionRect.y) <
            Math.abs(selectionRect.height)
        );
      });

      setSelectedIds(selectedobjects.map((shape) => shape.id));
      setSelectionRect(null);
      setIsDragging(false);
    }
  };

  /////////// 스냅 그리드

  const GUIDELINE_OFFSET = 5;

  const getLineGuideStops = useCallback(
    (skipShape) => {
      const vertical = [0, rectX, rectX + rectWidth / 2, rectX + rectWidth];
      const horizontal = [0, rectY, rectY + rectHeight / 2, rectY + rectHeight];

      objects.forEach((shape) => {
        if (shape.id === skipShape) return;
        vertical.push(shape.x, shape.x + shape.radius * 2);
        horizontal.push(shape.y, shape.y + shape.radius * 2);
      });

      return { vertical, horizontal };
    },
    [objects, rectX, rectY, rectWidth, rectHeight]
  );

  const getObjectSnappingEdges = useCallback((node) => {
    const box = node.getClientRect();
    const absPos = node.absolutePosition();

    return {
      vertical: [
        { guide: box.x, offset: absPos.x - box.x, snap: "start" },
        {
          guide: box.x + box.width / 2,
          offset: absPos.x - box.x - box.width / 2,
          snap: "center",
        },
        {
          guide: box.x + box.width,
          offset: absPos.x - box.x - box.width,
          snap: "end",
        },
      ],
      horizontal: [
        { guide: box.y, offset: absPos.y - box.y, snap: "start" },
        {
          guide: box.y + box.height / 2,
          offset: absPos.y - box.y - box.height / 2,
          snap: "center",
        },
        {
          guide: box.y + box.height,
          offset: absPos.y - box.y - box.height,
          snap: "end",
        },
      ],
    };
  }, []);

  const getGuides = useCallback((lineGuideStops, itemBounds) => {
    const resultV = [];
    const resultH = [];

    lineGuideStops.vertical.forEach((lineGuide) => {
      itemBounds.vertical.forEach((itemBound) => {
        const diff = Math.abs(lineGuide - itemBound.guide);
        if (diff < GUIDELINE_OFFSET) {
          resultV.push({
            lineGuide: lineGuide,
            diff: diff,
            snap: itemBound.snap,
            offset: itemBound.offset,
          });
        }
      });
    });

    lineGuideStops.horizontal.forEach((lineGuide) => {
      itemBounds.horizontal.forEach((itemBound) => {
        const diff = Math.abs(lineGuide - itemBound.guide);
        if (diff < GUIDELINE_OFFSET) {
          resultH.push({
            lineGuide: lineGuide,
            diff: diff,
            snap: itemBound.snap,
            offset: itemBound.offset,
          });
        }
      });
    });
    const guides = [];

    const minV = resultV.sort((a, b) => a.diff - b.diff)[0];
    const minH = resultH.sort((a, b) => a.diff - b.diff)[0];

    if (minV) {
      guides.push({
        lineGuide: minV.lineGuide,
        offset: minV.offset,
        orientation: "V",
        snap: minV.snap,
      });
    }

    if (minH) {
      guides.push({
        lineGuide: minH.lineGuide,
        offset: minH.offset,
        orientation: "H",
        snap: minH.snap,
      });
    }

    return guides;
  }, []);

  const drawGuides = useCallback((guides) => {
    guides.forEach((lg) => {
      if (lg.orientation === "H") {
        const line = new Konva.Line({
          points: [-6000, 0, 6000, 0],
          stroke: "rgb(0, 161, 255)",
          strokeWidth: 1,
          name: "guid-line",
          dash: [4, 6],
        });
        layerRef.current.add(line);
        line.absolutePosition({
          x: 0,
          y: lg.lineGuide,
        });
      } else if (lg.orientation === "V") {
        const line = new Konva.Line({
          points: [0, -6000, 0, 6000],
          stroke: "rgb(0, 161, 255)",
          strokeWidth: 1,
          name: "guid-line",
          dash: [4, 6],
        });
        layerRef.current.add(line);
        line.absolutePosition({
          x: lg.lineGuide,
          y: 0,
        });
      }
    });
  }, []);

  const handleDragMove2 = useCallback(
    (e) => {
      layerRef.current.find(".guid-line").forEach((l) => l.destroy());

      const lineGuideStops = getLineGuideStops(e.target.id());
      const itemBounds = getObjectSnappingEdges(e.target);
      const guides = getGuides(lineGuideStops, itemBounds);

      if (!guides.length) {
        return;
      }

      drawGuides(guides);

      const absPos = e.target.absolutePosition();
      guides.forEach((lg) => {
        switch (lg.orientation) {
          case "V":
            absPos.x = lg.lineGuide + lg.offset;
            break;
          case "H":
            absPos.y = lg.lineGuide + lg.offset;
            break;
        }
      });
      e.target.absolutePosition(absPos);
    },
    [getLineGuideStops, getObjectSnappingEdges, getGuides, drawGuides]
  );

  const handleDragEnd = useCallback((e, obj) => {
    console.log(selectedIds);
    //handleShapeDragTransfom(e, obj);
    layerRef.current.find(".guid-line").forEach((l) => l.destroy());
  }, []);

  return (
    <>
      <Stage
        ref={StageRef}
        width={stageWidth}
        height={stageHeight}
        onMouseDown={handleStageMouseDown}
        onMouseMove={handleStageMouseMove}
        onMouseUp={handleStageMouseUp}
      >
        <Layer ref={layerRef}>
          <Rect
            className="Background"
            x={rectX}
            y={rectY}
            width={rectWidth}
            height={rectHeight}
            fill="white"
            stroke="#777"
            strokeWidth={1}
          />

          {objects.map(renderObject)}
          {groups.map(renderGroup)}
        </Layer>
        <Layer listening={false}>
          <Group>
            <Rect
              className="OutOfBackground"
              x={0}
              y={0}
              width={stageWidth}
              height={rectY}
              fill="rgba(232, 232, 232, 0.9)"
              strokeWidth={1}
              stroke="rgba(232, 232, 232, 1)"
            />
            <Rect
              className="OutOfBackground"
              x={0}
              y={rectY}
              width={rectX}
              height={rectHeight}
              fill="rgba(232, 232, 232, 0.9)"
              strokeWidth={1}
              stroke="rgba(232, 232, 232, 1)"
            />
            <Rect
              className="OutOfBackground"
              x={rectX + rectWidth}
              y={rectY}
              width={stageWidth - (rectX + rectWidth)}
              height={rectHeight}
              fill="rgba(232, 232, 232, 0.9)"
              draggable={false}
              strokeWidth={1}
              stroke="rgba(232, 232, 232, 1)"
            />
            <Rect
              className="OutOfBackground"
              x={0}
              y={rectY + rectHeight}
              width={stageWidth}
              height={stageHeight - (rectY + rectHeight)}
              fill="rgba(232, 232, 232, 0.9)"
              strokeWidth={1}
              stroke="rgba(232, 232, 232, 1)"
            />
          </Group>
        </Layer>
        <Layer>
          <Transformer ref={trRef} />
          {selectionRect && (
            <Rect
              x={selectionRect.x}
              y={selectionRect.y}
              width={selectionRect.width}
              height={selectionRect.height}
              fill="rgba(0,0,255,0.2)"
            />
          )}
        </Layer>
      </Stage>
      {textEditMode && (
        <TextEditFormWrapper>
          <TextEditForm>
            <TextField
              ref={textEditRef}
              variant="outlined"
              fullWidth
              sx={{ backgroundColor: "white" }}
              value={textEditInput}
              autoFocus
              onChange={(e) => setTextEditInput(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setTextEditMode(false);
                  console.log(textEditObj.id);
                  functions.editText(textEditObj.id, textEditInput);
                }
              }}
            />
          </TextEditForm>
        </TextEditFormWrapper>
      )}
    </>
  );
};

export default New_EditorKonva;

const TextEditFormWrapper = styled.div`
  position: absolute;
  left: calc(50% - 200px);
  top: calc(15%);
  width: 400px;
  height: 56px;
`;

const TextEditForm = styled.div`
  position: relative;
`;
